import { DateField, RefreshButton, Show } from '@refinedev/antd'
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Space,
  Tag,
  Typography,
} from 'antd'
import { BaseKey, useCreate, useOne, useShow } from '@refinedev/core'
import { CheckAccess } from 'components/checkAccess'
import { PriceField } from 'components/field'
import { REJECTED } from 'consts'
import { IPayoutDetailed } from 'interfaces/payout'
import ReactJson from 'react-json-view'
import { PAYOUT_URL } from 'urls'
import {
  convertStatusName,
  getPayoutStatusColor,
  getUserName,
} from 'utils/common'
import TDS_Amount from './TDS_Amount'

const { Title, Text, Link } = Typography

export const PayoutShow: React.FC = () => {
  const { queryResult, showId } = useShow<IPayoutDetailed>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { refetch: refetchRawDetails } = useOne({
    resource: 'package_tool/payouts',
    id: `${showId}/refetch`,
    queryOptions: {
      enabled: false,
      onSuccess: () => {
        queryResult.refetch()
      },
    },
  })

  const { mutate: payoutPost } = useCreate()

  const handleStatusChange = (status: 'approve' | 'reject', id: BaseKey) => {
    payoutPost(
      {
        resource: `${PAYOUT_URL}/${showId}/${status}`,
        values: { effected_rule_id: id },
      },
      { onSettled: () => queryResult.refetch() },
    )
  }

  return (
    <CheckAccess resource="access_payouts">
      <Show isLoading={isLoading}>
        {!!queryResult.data?.data.effected_rules.length && (
          <Space direction="vertical" style={{ width: '100%' }}>
            {queryResult.data?.data.effected_rules.map((rule) => (
              <div key={rule.id}>
                <Alert
                  message={
                    !rule.approved && !rule.rejected
                      ? 'Pending Approval'
                      : rule.approved
                      ? 'Approved'
                      : 'Rejected'
                  }
                  description={
                    <>
                      <>
                        {!!rule.user && (
                          <>
                            By {getUserName(rule.user)} at{' '}
                            <DateField value={rule.updated_at} format="LLL" />
                            <br />
                          </>
                        )}
                      </>
                      <>
                        {rule.rule.for_under_collected ? (
                          <>
                            Payment exceeds collection by{' '}
                            <PriceField value={rule.excess_amount as number} />
                          </>
                        ) : (
                          'Over the allowed limit'
                        )}
                      </>
                      <>
                        {' '}
                        <br />
                        Payout Rule Type: {rule.rule?.rule_for}
                      </>
                    </>
                  }
                  type={
                    !rule.approved && !rule.rejected
                      ? 'warning'
                      : rule.approved
                      ? 'success'
                      : 'error'
                  }
                  showIcon
                  action={
                    !rule.approved &&
                    !rule.rejected && (
                      <Space size="large">
                        <Button
                          type="primary"
                          onClick={() =>
                            handleStatusChange('approve', rule.id)
                          }>
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => handleStatusChange('reject', rule.id)}>
                          Reject
                        </Button>
                      </Space>
                    )
                  }
                />
              </div>
            ))}
            <Divider />
          </Space>
        )}
        <Space>
          {showId && (
            <TDS_Amount
              tds_amount={queryResult.data?.data.tds_amount ?? 0}
              payout_id={showId}
            />
          )}
        </Space>
        <Space direction="horizontal">
          <Space direction="vertical">
            <Descriptions column={1}>
              <Descriptions.Item label={'Project ID'}>
                {record?.event ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Package'}>
                <Link
                  href={`/package_tool/shortlist_packages/edit/${record?.package}`}
                  target="_blank">
                  {record?.package ?? ''}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label={'Transfer ID'}>
                {record?.id ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Event Dates'}>
                {record?.event_dates?.map((value, idx) => (
                  <span key={idx}>
                    <DateField value={value} format="LLL" />{' '}
                  </span>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label={'Remarks'}>
                {record?.remarks ?? ''}
              </Descriptions.Item>
            </Descriptions>
          </Space>
          <div>
            <Tag color={getPayoutStatusColor(record?.status ?? '')}>
              {convertStatusName(record?.status ?? '')}
            </Tag>
            <br />
            <PriceField value={record?.amount ?? 0} />
          </div>
        </Space>

        <Divider />
        <div className="row">
          <Space direction="vertical">
            <Title level={4}>Vendor Details</Title>
            <Text>Name: {record?.vendor.name}</Text>
          </Space>
        </div>
        <Divider />
        <Space direction="vertical">
          <Title level={4}>Date and Time</Title>
          <Text>
            Created At: <DateField value={record?.created_at} format="LLL" />
          </Text>
          <Text>
            Updated At: <DateField value={record?.updated_at} format="LLL" />
          </Text>
          {record?.status_change_at && (
            <>
              <Text>
                Approved/Rejected By:{' '}
                <>
                  {record?.status_change_by.first_name}{' '}
                  {record?.status_change_by.last_name}
                </>
              </Text>
              <Text>
                Approved/Rejected At:{' '}
                <DateField value={record?.status_change_at} format="LLL" />
              </Text>
            </>
          )}
        </Space>
        <Divider />
        <Space>
          <Title level={4}>Raw Details</Title>{' '}
          <RefreshButton
            size="small"
            onClick={() => {
              refetchRawDetails()
            }}
            disabled={
              queryResult.data?.data.approval_required ||
              queryResult.data?.data.status === REJECTED
            }
          />
        </Space>
        <ReactJson
          src={record?.raw_response ?? {}}
          collapsed
          displayDataTypes={false}
        />
      </Show>
    </CheckAccess>
  )
}
