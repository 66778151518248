import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Button,
  Collapse,
  Space,
  Typography,
  Row,
  Select,
  Checkbox,
  Radio,
  Slider,
  Col,
  GetProp,
  CheckboxProps,
} from 'antd'
import { useForm as useFormSF } from 'sunflower-antd'
import * as Icons from '@ant-design/icons'
import { createOptionsFromEnum } from 'utils/common'
import { VenueType } from 'interfaces/crm'

import { logEvent } from 'firebase/analytics'
import { analytics } from 'services/firebase'

const { Title } = Typography
type CheckboxValue = GetProp<CheckboxProps, 'value'>

interface VenueFilterProps {
  setFilters: (newFilters: any) => void
  setSorter: (sorter: any) => void
  setCurrent: (page: number) => void
  onFinish?: (values: any) => void
  onUndo?: () => void
}

const logFilterEvent = (
  filterName: string,
  filterValue: number | CheckboxValue[] | string,
) => {
  logEvent(analytics, 'filter_interaction', {
    filter_name: filterName,
    filter_value: filterValue,
  })
}

const logPageEntry = (pageName: string) => {
  logEvent(analytics, 'page_entry', {
    page_name: pageName,
    entry_time: new Date().getTime(),
  })
}

const logPageExit = (pageName: string, entryTime: number) => {
  const exitTime = new Date().getTime()
  const timeSpent = (exitTime - entryTime) / 1000 // time spent in seconds
  logEvent(analytics, 'page_exit', {
    page_name: pageName,
    exit_time: exitTime,
    time_spent: timeSpent,
  })
}

const AccommodationRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="hasAccommodation">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Accommodation', e.target.value)
        }}>
        <Radio value={true}>Has Accommodation</Radio>
        <Radio value={false}>No Accommodation</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const VenueTypeSelect: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="venueType">
      <Select
        allowClear
        mode="multiple"
        popupMatchSelectWidth={false}
        onChange={(value) => {
          saveAndSubmit()
          logFilterEvent('Venue Type', value)
        }}
        options={createOptionsFromEnum(VenueType)}
      />
    </Form.Item>
  )
}

const CateringRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="hasCatering">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Catering', e.target.value)
        }}>
        <Radio value={true}>Has Catering</Radio>
        <Radio value={false}>No Catering</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const SpacesRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="spaces">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Spaces', e.target.value)
        }}>
        <Radio value={true}>Has Spaces</Radio>
        <Radio value={false}>No Spaces</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const ValetRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="valetAvailable">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Valet', e.target.value)
        }}>
        <Radio value={true}>Has Valet</Radio>
        <Radio value={false}>No Valet</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const ExecutedRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="executed">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Executed', e.target.value)
        }}>
        <Radio value={true}>Executed</Radio>
        <Radio value={false}>Not Executed</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const CateringPolicyCheckboxGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="cateringPolicy">
      <Checkbox.Group
        onChange={(value) => {
          saveAndSubmit()
          logFilterEvent('Catering Policy', value)
        }}>
        <Checkbox value="inHouse">In House</Checkbox>
        <Checkbox value="outHouse">Out House</Checkbox>
      </Checkbox.Group>
    </Form.Item>
  )
}

const AlcoholPolicyRadioGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="alcoholPolicy">
      <Radio.Group
        onChange={(e) => {
          saveAndSubmit()
          logFilterEvent('Alcohol Policy', e.target.value)
        }}>
        <Radio value={true}>Allowed</Radio>
        <Radio value={false}>Not Allowed</Radio>
        <Radio value={null}>No Preference</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

const areaOptions = [
  { label: 'Central', value: 'Central' },
  { label: 'East', value: 'East' },
  { label: 'North', value: 'North' },
  { label: 'South', value: 'South' },
  { label: 'West', value: 'West' },
]

const AreaCheckboxGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="area">
      <Checkbox.Group
        options={areaOptions}
        onChange={(value) => {
          saveAndSubmit()
          logFilterEvent('Area', value)
        }}
      />
    </Form.Item>
  )
}

const categoryOptions = [
  { label: 'Delight', value: 'Delight' },
  { label: 'Luxury', value: 'Luxury' },
  { label: 'Prime', value: 'Prime' },
  { label: 'Value', value: 'Value' },
]

const CategoryCheckboxGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  return (
    <Form.Item name="category">
      <Checkbox.Group
        options={categoryOptions}
        onChange={(value) => {
          saveAndSubmit()
          logFilterEvent('Category', value)
        }}
      />
    </Form.Item>
  )
}

const PaxRangeSlider: React.FC<{ saveAndSubmit: () => void; form: any }> = ({
  saveAndSubmit,
  form,
}) => {
  const [range, setRange] = useState<number[]>([0, 1000])

  useEffect(() => {
    form.setFieldsValue({ floatingCapacity: range })
  }, [range, form])

  const onSliderChange = (value: number[]) => {
    setRange(value)
  }

  const onSliderAfterChange = (value: number[]) => {
    saveAndSubmit()
    logFilterEvent('Floating Capacity', value)
  }

  const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([value, range[1]])
  }

  const onMinAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Floating Capacity Min', range[0])
  }

  const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([range[0], value])
  }

  const onMaxAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Floating Capacity Max', range[1])
  }

  return (
    <Form.Item name="floatingCapacity">
      <Row gutter={16} align="middle" justify="space-between">
        <Col span={8}>
          <Input
            value={range[0]}
            onChange={onMinChange}
            onBlur={onMinAfterChange}
            type="number"
            min={0}
            max={range[1]}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Input
            value={range[1]}
            onChange={onMaxChange}
            onBlur={onMaxAfterChange}
            type="number"
            min={range[0]}
            max={1000}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Slider
        range
        value={range}
        defaultValue={[0, 1000]}
        max={1000}
        onChange={onSliderChange}
        onChangeComplete={onSliderAfterChange}
        style={{ marginTop: 16 }}
      />
    </Form.Item>
  )
}

const SeatingCapacitySlider: React.FC<{
  saveAndSubmit: () => void
  form: any
}> = ({ saveAndSubmit, form }) => {
  const [range, setRange] = useState<number[]>([0, 1000])

  useEffect(() => {
    form.setFieldsValue({ seatingCapacity: range })
  }, [range, form])

  const onSliderChange = (value: number[]) => {
    setRange(value)
  }

  const onSliderAfterChange = (value: number[]) => {
    saveAndSubmit()
    logFilterEvent('Seating Capacity', value)
  }

  const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([value, range[1]])
  }

  const onMinAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Seating Capacity Min', range[0])
  }

  const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([range[0], value])
  }

  const onMaxAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Seating Capacity Max', range[1])
  }

  return (
    <Form.Item name="seatingCapacity">
      <Row gutter={16} align="middle" justify="space-between">
        <Col span={8}>
          <Input
            value={range[0]}
            onChange={onMinChange}
            onBlur={onMinAfterChange}
            type="number"
            min={0}
            max={range[1]}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Input
            value={range[1]}
            onChange={onMaxChange}
            onBlur={onMaxAfterChange}
            type="number"
            min={range[0]}
            max={1000}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Slider
        range
        value={range}
        defaultValue={[0, 1000]}
        max={1000}
        onChange={onSliderChange}
        onAfterChange={onSliderAfterChange}
        style={{ marginTop: 16 }}
      />
    </Form.Item>
  )
}

const ParkingRangeSlider: React.FC<{
  saveAndSubmit: () => void
  form: any
}> = ({ saveAndSubmit, form }) => {
  const [range, setRange] = useState<number[]>([0, 100])

  useEffect(() => {
    form.setFieldsValue({ carParkingCount: range })
  }, [range, form])

  const onSliderChange = (value: number[]) => {
    setRange(value)
  }

  const onSliderAfterChange = (value: number[]) => {
    saveAndSubmit()
    logFilterEvent('Seating Capacity', value)
  }

  const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([value, range[1]])
  }

  const onMinAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Car Parking Capacity Min', range[0])
  }

  const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setRange([range[0], value])
  }

  const onMaxAfterChange = () => {
    saveAndSubmit()
    logFilterEvent('Car Parking Capacity Max', range[1])
  }

  return (
    <Form.Item name="carParkingCount">
      <Row gutter={16} align="middle" justify="space-between">
        <Col span={8}>
          <Input
            value={range[0]}
            onChange={onMinChange}
            onBlur={onMinAfterChange}
            type="number"
            min={0}
            max={range[1]}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Input
            value={range[1]}
            onChange={onMaxChange}
            onBlur={onMaxAfterChange}
            type="number"
            min={range[0]}
            max={100}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Slider
        range
        value={range}
        defaultValue={[0, 100]}
        max={100}
        onChange={onSliderChange}
        onAfterChange={onSliderAfterChange}
        style={{ marginTop: 16 }}
      />
    </Form.Item>
  )
}

export const VenueFilter: React.FC<VenueFilterProps> = ({
  setFilters,
  setSorter,
  setCurrent,
  onFinish,
  onUndo,
}) => {
  const [previousFilters, setPreviousFilters] = useState<any[]>([])
  const [showSearch, setShowSearch] = useState(true)
  const [showResetButton, setShowResetButton] = useState(false)
  const [activePanel, setActivePanel] = useState<string | string[]>()
  const [form] = Form.useForm()
  const formSF = useFormSF({ form })

  const [entryTime, setEntryTime] = useState<number>(0)

  useEffect(() => {
    resetAll()
    const pageName = 'VenueFilter'
    const time = new Date().getTime()
    setEntryTime(time)
    logPageEntry(pageName)

    return () => {
      logPageExit(pageName, time)
    }
  }, [])

  const saveAndSubmit = async () => {
    setPreviousFilters([...previousFilters, form.getFieldsValue(true)])
    setShowResetButton(true)
    form.submit()
  }

  const handleUndo = async () => {
    const newFilters = previousFilters.slice(0, -1)
    setPreviousFilters(newFilters)
    onUndo && onUndo()
    form.setFieldsValue(newFilters[newFilters.length - 1])
    form.submit()
  }

  const resetAll = () => {
    setPreviousFilters([])
    setSorter([])
    setCurrent(1)
    setShowResetButton(false)
    form.resetFields()
    form.submit()
  }

  const toggleShowSearch = () => {
    setShowSearch(!showSearch)
  }

  const onSubmit = async () => {
    const filterValue = { ...previousFilters[previousFilters.length - 1] }
    setFilters([
      { field: 'search', operator: 'eq', value: filterValue.search },
      {
        field: 'has_accommodation',
        operator: 'eq',
        value: filterValue.hasAccommodation,
      },
      { field: 'venue_type', operator: 'in', value: filterValue.venueType },
      {
        field: 'is_empanelled',
        operator: 'eq',
        value: filterValue.isEmpanelled,
      },
      {
        field: 'is_partnership',
        operator: 'eq',
        value: filterValue.isPartnership,
      },
      { field: 'is_enrolled', operator: 'eq', value: filterValue.isEnrolled },
      {
        field: 'has_catering',
        operator: 'eq',
        value: filterValue.hasCatering,
      },
      {
        field: 'spaces__isnull',
        operator: 'eq',
        value: filterValue.spaces,
      },
      {
        field: 'valet_available',
        operator: 'eq',
        value: filterValue.valetAvailable,
      },
      {
        field: 'executed__isnull',
        operator: 'eq',
        value: filterValue.executed,
      },
      {
        field: 'catering_policy',
        operator: 'eq',
        value: filterValue.cateringPolicy,
      },
      {
        field: 'alcohol_allowed',
        operator: 'eq',
        value: filterValue.alcoholPolicy,
      },
      { field: 'area', operator: 'in', value: filterValue.area },
      { field: 'category', operator: 'in', value: filterValue.category },
      {
        field: 'floating_capacity__range',
        operator: 'eq',
        value: filterValue.floatingCapacity?.join(','),
      },
      {
        field: 'parking_car_count__range',
        operator: 'eq',
        value: filterValue.carParkingCount?.join(','),
      },
      {
        field: 'seating_capacity__range',
        operator: 'eq',
        value: filterValue.seatingCapacity?.join(','),
      },
      {
        field: 'catering_in_house',
        operator: 'eq',
        value: filterValue.catering == 'inHouse' ? true : null,
      },
      {
        field: 'catering_out_house',
        operator: 'eq',
        value: filterValue.catering == 'outHouse' ? true : null,
      },
    ])
    // setSorter([
    //   {
    //     field: values.sort,
    //     order: values.sort.startsWith('-') ? 'desc' : 'asc',
    //   },
    // ])
    onFinish && onFinish(filterValue)
    setCurrent(1)
  }

  return (
    <Form layout="vertical" {...formSF.formProps} onFinish={onSubmit}>
      <Row justify="space-between" align="middle">
        <Title level={4} style={{ fontFamily: 'Gilroy', fontWeight: '500' }}>
          Filters
        </Title>
        <Row justify="space-between" style={{ padding: '20px 0' }}>
          <Space direction="horizontal">
            {!!previousFilters.length && (
              <Button
                onClick={handleUndo}
                icon={<Icons.ArrowLeftOutlined />}
                shape="circle"
                style={{
                  background: '#FEF8E9',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              />
            )}
            {showResetButton && (
              <Button
                onClick={resetAll}
                icon={<Icons.SyncOutlined />}
                style={{
                  background: '#FEF8E9',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              />
            )}
            <Button
              onClick={toggleShowSearch}
              icon={
                showSearch ? <Icons.SearchOutlined /> : <Icons.CloseOutlined />
              }
              shape="circle"
              style={{
                background: '#F0EAF9',
                borderRadius: '50%',
                overflow: 'hidden',
                border: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                display: 'inline-flex',
              }}
            />
          </Space>
        </Row>
      </Row>
      <Form.Item name="search" hidden={showSearch}>
        <Input.Search
          hidden={showSearch}
          placeholder="Name"
          onSearch={(value) => {
            saveAndSubmit()
            logFilterEvent('Search', value)
          }}
          style={{ fontFamily: 'Gilroy' }}
          enterButton
        />
      </Form.Item>
      <Collapse
        defaultActiveKey={['areaPreference']}
        onChange={(value) => setActivePanel(value)}
        bordered={false}
        accordion={true}
        expandIconPosition="right"
        style={{
          border: 'none',
          backgroundColor: 'white',
          marginTop: '10px',
        }}>
        <Collapse.Panel header="Area Preference" key="areaPreference">
          <AreaCheckboxGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Venue Type" key="venueType">
          <VenueTypeSelect saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Category" key="categoryPreference">
          <CategoryCheckboxGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel
          header="Pax Capacity"
          key="paxCapacity"
          extra={
            <Typography.Text italic strong>
              (Floating)
            </Typography.Text>
          }>
          <PaxRangeSlider saveAndSubmit={saveAndSubmit} form={form} />
        </Collapse.Panel>
        <Collapse.Panel header="Seating Capacity" key="seatingCapacity">
          <SeatingCapacitySlider saveAndSubmit={saveAndSubmit} form={form} />
        </Collapse.Panel>
        <Collapse.Panel header="Accommodation" key="accommodation">
          <AccommodationRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Catering" key="catering">
          <CateringRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Catering Policy" key="cateringPolicy">
          <CateringPolicyCheckboxGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Car Parking Capacity" key="carParkingCapacity">
          <ParkingRangeSlider saveAndSubmit={saveAndSubmit} form={form} />
        </Collapse.Panel>
        <Collapse.Panel header="Alcohol Policy" key="alcoholPolicy">
          <AlcoholPolicyRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Spaces" key="spaces">
          <SpacesRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Valet Facility" key="valet">
          <ValetRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Executed" key="executed">
          <ExecutedRadioGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}
