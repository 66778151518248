import { Show } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Badge,
  Button,
  Col,
  Descriptions,
  FloatButton,
  Input,
  Modal,
  Row,
  Segmented as SegmentedTabs,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
} from 'antd'

import {
  LayoutWrapper,
  useApiUrl,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useModal,
  useNavigation,
  useRouterContext,
  useShow,
} from '@refinedev/core'

import { PriceField } from 'components/field'
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'
import {
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAt,
} from 'firebase/database'
import { IChatMessage } from 'interfaces/chat'
import { IEvent } from 'interfaces/customer'
import { IPaymentWithBreakdown } from 'interfaces/payment'
import { IStaff } from 'interfaces/staff'
import { AlwaysScrollToBottom } from 'pages/package_tool/rfq/components/Chat'
import { useEffect, useState } from 'react'
import { firebaseDb } from 'services/firebase'
import { ContributorsList } from '../contributor/List'
import { TaskList } from '../tasks/List'
import { TimelineShow } from '../timeline'
import { ProjectPayment } from './ProjectPayment'
import { Services } from './Services'

export const EventShow: React.FC = () => {
  const { useParams } = useRouterContext()

  const { id } = useParams<any>()
  const { goBack, push } = useNavigation()
  const [tabValue, setTabValue] = useState<string | number>('Packages')
  const apiUrl = useApiUrl()
  // use to refresh the shortlist packages page if that is selected.
  const [refreshPackages, setRefreshPackages] = useState<Boolean>(false)

  const [unReadCount, setUnReadCount] = useState(0)

  const { show, visible, close } = useModal()

  const { showId, queryResult } = useShow<IEvent>({
    resource: 'package_tool/shortlist_package/projects',
    id: `${id}/?expand=user&`,
  })

  const {
    data: payments,
    refetch: refetchPayments,
    isLoading: paymentLoading,
  } = useCustom<IPaymentWithBreakdown>({
    url: `${apiUrl}/package_tool/payments/get_from_project/`,
    method: 'get',
    config: {
      filters: [
        {
          field: 'project_id',
          operator: 'eq',
          value: id,
        },
      ],
    },
  })

  const { mutate } = useCustomMutation()

  const { data: user } = useGetIdentity<IStaff>()

  useEffect(() => {
    if (queryResult.data?.data) {
      const userRef = ref(
        firebaseDb,
        `chat/project/${queryResult.data?.data.id}/users/${user?.id}`,
      )
      onValue(userRef, (snapshot) => {
        const data = snapshot.val()
        if (data) {
          const messageRef = ref(
            firebaseDb,
            `chat/project/${queryResult.data?.data.id}/messages`,
          )
          const q = query(
            messageRef,
            orderByChild('timestamp'),
            startAt(data.last_read_timestamp, 'timestamp'),
          )
          onValue(q, (snapshot) => {
            const data = snapshot.val()
            let obtained: any = []
            let messages: any = []
            if (data) {
              Object.values(data).map((m) => obtained.push(m))
              messages = Object.values(data).map((m: any) => m)
            }
            setUnReadCount(messages.length)
          })
        }
      })
    }
  }, [])

  const pageHeaderProps = {
    onBack: () => {
      goBack()
    },
    breadcrumb: <></>,
    extra: [
      <Button
        key="edit"
        icon={<Icons.EditOutlined />}
        onClick={() => push(`/package_tool/events/edit/${showId}`)}>
        Edit
      </Button>,
      <Switch
        key="toggle executed"
        checked={queryResult.data?.data.is_executed}
        checkedChildren="executed"
        unCheckedChildren="not executed"
        onChange={() => {
          mutate(
            {
              url: `${apiUrl}/package_tool/shortlist_package/projects/${id}/toggle_executed/`,
              method: 'post',
              values: {
                is_executed: !queryResult.data?.data.is_executed,
              },
            },
            {
              onError: (error, variables, context) => {
                // An error occurred!
              },
              onSuccess: (data, variables, context) => {
                queryResult.refetch()
                if (tabValue === 'Packages') {
                  setRefreshPackages(true)
                }
              },
            },
          )
        }}
      />,
    ],
  }

  const ProjectInfo = () => {
    return (
      <div style={{ padding: '10px', margin: '20px' }}>
        <Descriptions title="Customer Details">
          <Descriptions.Item label="Name">
            {queryResult.data?.data.user.first_name}{' '}
            {queryResult.data?.data.user.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone No">
            {queryResult.data?.data.user.username}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={<>Payment Details {paymentLoading && <Spin size="small" />}</>}
          column={5}>
          <Descriptions.Item label="Price">
            <PriceField value={payments?.data.breakdown.total ?? 0} />
          </Descriptions.Item>
          <Descriptions.Item label="Discount">
            <PriceField value={payments?.data.breakdown.discount ?? 0} />
          </Descriptions.Item>
          <Descriptions.Item label="Effective Price">
            <PriceField value={payments?.data.breakdown.effective ?? 0} />
          </Descriptions.Item>
          <Descriptions.Item label="Paid">
            <PriceField value={payments?.data.breakdown.paid ?? 0} />
          </Descriptions.Item>
          <Descriptions.Item label="Pending">
            <PriceField value={payments?.data.breakdown.pending ?? 0} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  return (
    <LayoutWrapper>
      <Show
        canEdit={true}
        isLoading={queryResult.isLoading}
        title={`Project - ${
          queryResult.data?.data.id ? queryResult.data?.data.id : 'ID not set'
        }`}
        headerProps={pageHeaderProps}>
        <ProjectInfo />
        <Row style={{ backgroundColor: '#F5F5F5', borderRadius: '6px' }}>
          <Col span={12}>
            <SegmentedTabs
              options={[
                'Packages',
                'Payments',
                'Timeline',
                'Tasks',
                'Contributors',
              ]}
              block={true}
              value={tabValue}
              onChange={setTabValue}
            />
          </Col>
        </Row>
        {tabValue === 'Packages' && (
          <Services
            project_id={id}
            refreshPackages={refreshPackages}
            setRefreshPackages={setRefreshPackages}
          />
        )}
        {tabValue === 'Payments' && queryResult.data && (
          <ProjectPayment
            project_id={id}
            event={queryResult.data?.data}
            refetchPayments={refetchPayments}
          />
        )}
        {tabValue === 'Timeline' && queryResult.data && (
          <div style={{ marginTop: 30 }}>
            <TimelineShow pid={queryResult.data?.data.id} />
          </div>
        )}
        {tabValue === 'Tasks' && queryResult.data && (
          <div style={{ marginTop: 30 }}>
            <TaskList pid={queryResult.data?.data.id} />
          </div>
        )}
        {tabValue === 'Contributors' && queryResult.data && (
          <div style={{ marginTop: 30 }}>
            <ContributorsList projectId={queryResult.data?.data.id} />
          </div>
        )}
      </Show>

      <FloatButton
        icon={<Icons.MessageOutlined />}
        type="default"
        description={<Badge count={unReadCount} /> || null}
        onClick={() => {
          show()
          const usersRef = ref(
            firebaseDb,
            `chat/project/${queryResult.data?.data.id}/users/${user?.id}`,
          )
          set(usersRef, {
            last_read_timestamp: Date.now(),
          })
        }}
      />

      {queryResult.data?.data && (
        <ChatModal
          project={queryResult.data.data}
          visible={visible}
          close={close}
        />
      )}
    </LayoutWrapper>
  )
}

export function ChatModal({
  close,
  visible,
  project,
}: {
  close: CallableFunction
  visible: boolean
  project: IEvent
}) {
  const [inputText, setInputText] = useState('')
  const [messages, setMessages] = useState<IChatMessage[]>([])
  const [imageUploading, setImageUploading] = useState(false)
  const [imageError, setImageError] = useState(false)

  const { data: user } = useGetIdentity<IStaff>()

  const sendMessage = (uri?: string) => {
    if (inputText.length || uri) {
      const messagesRef = ref(firebaseDb, `chat/project/${project.id}/messages`)
      const newMessageRef = push(messagesRef)
      set(newMessageRef, {
        message: inputText,
        timestamp: Date.now(),
        uri: uri ? uri : null,
        user: {
          id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          username: user?.username,
        },
      })

      const usersRef = ref(
        firebaseDb,
        `chat/project/${project.id}/users/${user?.id}`,
      )
      set(usersRef, {
        last_read_timestamp: Date.now(),
      })

      setInputText('')
    }
  }

  useEffect(() => {
    const messageRef = ref(firebaseDb, `chat/project/${project.id}/messages`)
    onValue(messageRef, (snapshot) => {
      const data = snapshot.val()
      let obtained: any = []
      if (data) {
        Object.values(data).map((m) => obtained.push(m))
        setMessages(Object.values(data).map((m: any) => m))
      }
    })
  }, [])

  const isCurrentUser = (message: IChatMessage): boolean =>
    user?.id === message?.user?.id

  const isSameUser = (message: IChatMessage, index: number): boolean => {
    if (index) {
      return message?.user?.id === messages[index - 1]?.user?.id
    }
    return false
  }

  return (
    <Modal
      onCancel={() => {
        close()
        const usersRef = ref(
          firebaseDb,
          `chat/project/${project.id}/users/${user?.id}`,
        )
        set(usersRef, {
          last_read_timestamp: Date.now(),
        })
      }}
      open={visible}
      closable={false}
      footer={null}
      destroyOnClose
      bodyStyle={{ maxHeight: '75vh', overflowY: 'hidden' }}>
      <div className="chat-list">
        {messages.map((message, index) => (
          <div key={index}>
            <div
              className={`chat-card ${
                isCurrentUser(message) ? 'chat-right' : ''
              }`}>
              {!isCurrentUser(message) && !isSameUser(message, index) && (
                <Typography.Text className="chat-user">
                  {message.user.first_name} {message.user.last_name}
                </Typography.Text>
              )}
              <div>
                <span className="chat-message">{message?.message}</span>
                <div>
                  <Typography.Text
                    style={{
                      fontSize: 10,
                      color: isCurrentUser(message) ? 'white' : 'black',
                    }}>
                    <DateFieldFromNow value={message.timestamp} />
                  </Typography.Text>
                </div>
              </div>
            </div>
          </div>
        ))}
        <AlwaysScrollToBottom />
      </div>
      <div className="row">
        <Input
          addonBefore={
            <Space>
              <Upload
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    setImageUploading(true)
                    setImageError(false)
                  }
                  if (info.file.status === 'done') {
                    sendMessage(info.file.response.uri)
                    setImageUploading(false)
                    setImageError(false)
                  } else if (info.file.status === 'error') {
                    setImageUploading(false)
                    setImageError(true)
                  }
                }}
                itemRender={() => null}>
                <Button
                  type="text"
                  icon={
                    imageUploading ? (
                      <Icons.LoadingOutlined />
                    ) : (
                      <Icons.FileImageOutlined
                        style={{ color: imageError ? 'red' : undefined }}
                      />
                    )
                  }
                />
              </Upload>
            </Space>
          }
          multiple
          size="large"
          addonAfter={<Icons.SendOutlined onClick={() => sendMessage()} />}
          onPressEnter={(e) => {
            if (!e.shiftKey) sendMessage()
            else setInputText(inputText + '\r\n')
          }}
          placeholder="Type here..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
      </div>
    </Modal>
  )
}
