import { ImageField } from "@refinedev/antd";
import { Card, Form, InputNumber, Switch, Tabs, Typography } from "antd";
import { useOne } from "@refinedev/core";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import parse from 'html-react-parser'
import { IQuote } from 'interfaces/quote'
import { UseFormReturnType } from 'interfaces/refine'
import { IItemDetailed } from 'interfaces/shortlistPackage'
import React from 'react'
import 'react-quill/dist/quill.snow.css'

const ProductDetails: React.FC<{ value?: string }> = ({ value }) => {
  const { data } = useOne<IItemDetailed>({
    resource: 'package_tool/shortlist_package/line_items',
    id: value as string,
    queryOptions: {
      enabled: !!value,
    },
  })
  return (
    <>
      {data?.data.product?.images.map((image) => (
        <ImageField
          key={image.id}
          value={image.image}
          height={250}
          width={200}
        />
      ))}
      <br />
      <Typography.Text strong>Name: </Typography.Text>
      <Typography.Text>{data?.data.product?.name}</Typography.Text>
      <br />
      <Typography.Text strong>Description: </Typography.Text>
      <Typography.Text>{parse(data?.data.description ?? '')}</Typography.Text>
      <br />
      <Typography.Text strong>Quantity: </Typography.Text>
      <Typography.Text>{data?.data.quantity}</Typography.Text>
    </>
  )
}

export const QuoteForm: React.FC<{
  useFormProps: UseFormReturnType<IQuote>
  eventId?: string
}> = ({ useFormProps, eventId }) => {
  const { formProps } = useFormProps

  const onFinish = (values: any) => {
    if (eventId) values['event'] = eventId
    useFormProps.formProps.onFinish &&
      useFormProps.formProps.onFinish({ ...values })
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic" key="basic">
          <Form.Item label="Vendor" name="vendor" rules={[{ required: true }]}>
            <SelectWithDefault
              useSelectProps={{
                resource: 'core/vendors',
                optionValue: 'id',
                optionLabel: 'name',
              }}
            />
          </Form.Item>
          <Form.Item
            label="Is Accepted"
            name="is_accepted"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Items" key="items" forceRender>
          <Form.List name="items">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Card key={`${field.name}-${field.key}`}>
                      <Form.Item
                        {...field}
                        key={`${field.name}-${field.key}`}
                        name={[field.name, 'parent']}
                        label="Product Details">
                        <ProductDetails />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        key={`${field.name}-${field.key}`}
                        name={[field.name, 'price']}
                        label="Price"
                        rules={[
                          {
                            required: true,
                            message: 'This is required',
                          },
                        ]}>
                        <InputNumber />
                      </Form.Item>
                    </Card>
                  ))}
                  {/* <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Item
                      </Button>
                    </Form.Item> */}
                </>
              )
            }}
          </Form.List>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
