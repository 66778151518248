import { Typography } from "antd";
import { PriceField } from 'components/field'

export const TotalComponent = ({
  value,
  onChange,
}: {
  value?: any
  onChange?: any
}) => {
  return (
    <Typography.Title level={5}>
      Total: <PriceField value={value} />
    </Typography.Title>
  )
}
