import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ITag } from 'interfaces/attribute'
import { TagForm } from './Form'

export const TagCreate: React.FC = () => {
  const useFormProps = useForm<ITag>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <TagForm useFormProps={useFormProps} />
    </Create>
  )
}
