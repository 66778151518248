import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber, Space, Switch } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { VENUE_URL } from 'urls'

export const NearbyAccommodationForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={(values) =>
        props.formProps.onFinish!({
          ...values,
          venue: props.venueId,
        })
      }>
      <Form.Item name="venue_option" label="Venue">
        <SelectWithDefault
          useSelectProps={{ resource: VENUE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="venue_option_text" label="Option Text">
        <Input />
      </Form.Item>
      <Space>
        <Form.Item name="price" label="Price">
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="price_on_enquiry"
          label="Price on Enquiry"
          valuePropName="checked">
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item name="time" label="Time Away (min)">
        <InputNumber />
      </Form.Item>
      <Form.Item name="distance" label="Distance Away (m)">
        <InputNumber />
      </Form.Item>
    </Form>
  )
}
