import { List as AntdList, Button, Collapse, Descriptions } from 'antd'
import { useDelete } from '@refinedev/core'
import { BANGALORE_REGION_ID, MERAGI_VENDOR_ID } from 'consts'
import { IInventory } from 'interfaces/product'
import { IRegion } from 'interfaces/region'
import { IVendor } from 'interfaces/vendor'
import { INVENTORY_URL, PRICING_MAIN_URL } from 'urls'
import parse from 'html-react-parser'

const { Panel } = Collapse

export const InventoryCard: React.FC<{
  item: IInventory
  showEdit: CallableFunction
  refetch: CallableFunction
}> = ({ item, showEdit, refetch }) => {
  const { mutate: destroy } = useDelete()

  return (
    <>
      <AntdList.Item
        key={item.id}
        actions={[
          <Button
            size="small"
            type="text"
            key="edit"
            onClick={() => showEdit(item.id)}>
            edit
          </Button>,
          <Button
            size="small"
            type="text"
            key="edit"
            disabled={
              ((item.vendor as IVendor).id as any) !== MERAGI_VENDOR_ID ||
              ((item.region as IRegion).id as any) !== BANGALORE_REGION_ID
            }
            onClick={() =>
              window.open(`/${PRICING_MAIN_URL}/?productId=${item.product}`)
            }>
            calculate price
          </Button>,
          <Button
            size="small"
            type="text"
            key="edit"
            disabled={
              ((item.vendor as IVendor).id as any) !== MERAGI_VENDOR_ID ||
              ((item.region as IRegion).id as any) !== BANGALORE_REGION_ID
            }
            onClick={() =>
              window.open(`/${PRICING_MAIN_URL}_v2/?productId=${item.product}`)
            }>
            calculate price v2
          </Button>,
          <Button
            size="small"
            danger
            type="text"
            key="delete"
            onClick={() =>
              destroy(
                {
                  resource: INVENTORY_URL,
                  id: item.id,
                },
                { onSuccess: () => refetch() },
              )
            }>
            delete
          </Button>,
        ]}>
        <AntdList.Item.Meta
          title={(item.region as IRegion).name}
          description={
            <Descriptions>
              <Descriptions.Item label="Vendor">
                {(item.vendor as IVendor).name}
              </Descriptions.Item>
              <Descriptions.Item label="Price">{item.price}</Descriptions.Item>
            </Descriptions>
          }
        />
      </AntdList.Item>

      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Description" key="1">
          <p>{parse(item.description ?? '')}</p>
        </Collapse.Panel>
      </Collapse>
    </>
  )
}
