import { CreateButton, ImageField, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { IFlower } from 'interfaces/pricing'
import { useState } from 'react'
import { FLOWER_URL } from 'urls'

export const FlowerList: React.FC = () => {
  const tProps = useTable<IFlower>({
    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: FLOWER_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => edit(FLOWER_URL, record.id),
        })}>
        <Table.Column
          dataIndex={['image']}
          title=""
          render={(image) => (
            <ImageField value={image} width={75} height={75} />
          )}
        />
        <Table.Column dataIndex={['flower_type', 'name']} title="Type" />
        <Table.Column dataIndex={['color', 'name']} title="Color" />
      </Table>
    </List>
  );
}
