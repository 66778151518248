import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IBestFor } from 'interfaces/bestFor'
import { BestForForm } from './Form'

export const BestForCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IBestFor>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <BestForForm formProps={formProps} />
    </Create>
  )
}
