import { CreateButton, List, useTable } from '@refinedev/antd'
import { useDeleteMany, useNavigation } from '@refinedev/core'
import { Table } from 'antd'
import { DeleteButton } from 'components/button'
import { ITag } from 'interfaces/attribute'
import { useState } from 'react'
import { TAG_URL } from 'urls'

export const TagList: React.FC = () => {
  const tProps = useTable<ITag>({
    syncWithLocation: true,
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: TAG_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => edit(TAG_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
      </Table>
    </List>
  )
}
