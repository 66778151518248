import { useTableReturnType } from '@refinedev/antd'
import { useApiUrl, useCustom, useList, useTable } from '@refinedev/core'
import { Col, Grid, Row, TreeDataNode } from 'antd'
import { ProductFilter } from 'components/filters/productFilter'
import { IFilter } from 'interfaces/attribute'
import { IStructuredCategory } from 'interfaces/category'
import { IProduct, IProductOption } from 'interfaces/product'
import { IVenue } from 'interfaces/venue'
import { useEffect, useState } from 'react'
import { buildNodes, generateFilterQuery } from 'utils/filter'
import { ProductTable } from '.'

export type useProductTableReturnType = Omit<
  useTableReturnType<IProduct, any>,
  'searchFormProps'
>

export const ProductList: React.FC = () => {
  const apiUrl = useApiUrl()
  const [categoryNodes, setCategoryNodes] = useState<TreeDataNode[]>([])

  const coreTableProps = useTable<IProduct>({
    filters: {
      defaultBehavior: 'replace',
    },
  })

  const breakpoint = Grid.useBreakpoint()

  /**
   * handle the pagination changes from table
   * @param pagination
   */
  const onChange = (pagination: any) => {
    coreTableProps.setCurrent(pagination.current)
    coreTableProps.setPageSize(pagination.pageSize)
  }

  const tProps: useProductTableReturnType = {
    ...coreTableProps,
    tableProps: {
      dataSource: coreTableProps.tableQueryResult.data?.data,
      loading: !coreTableProps.tableQueryResult.isFetched,
      onChange,
      pagination: {
        pageSize: coreTableProps.pageSize,
        current: coreTableProps.current,
        simple: !breakpoint.sm,
        position: !breakpoint.sm ? ['bottomCenter'] : ['bottomRight'],
        total: coreTableProps.tableQueryResult.data?.total,
        showSizeChanger:
          (coreTableProps.tableQueryResult.data?.total ?? 10) > 10,
        onShowSizeChange: (_, pageSize) => coreTableProps.setPageSize(pageSize),
      },
    },
  }

  const { data: categoriesData, isLoading: isFilterCategoryLoading } =
    useList<IStructuredCategory>({
      resource: 'cms/category/structured/',
    })

  const { data: venueData, isLoading: isVenueLoading } = useList<IVenue>({
    resource: 'package_tool/venues/',

    pagination: { mode: 'off' },

    filters: [
      {
        field: 'spaces__isnull',
        operator: 'eq',
        value: false,
      },
    ],
  })

  const { data: executedVenueData, isLoading: isExecutedVenueLoading } =
    useList<IVenue>({
      resource: 'package_tool/venues/',

      pagination: { mode: 'off' },

      filters: [
        {
          field: 'executed__isnull',
          operator: 'eq',
          value: false,
        },
      ],
    })

  const { data: filterData, isLoading: isFilterLoading } = useCustom<IFilter[]>(
    generateFilterQuery(
      `${apiUrl}/cms/product/get_filters/`,
      tProps.filters,
      !!tProps?.tableQueryResult.data?.data.length,
    ),
  )

  const { data: priceData, isLoading: isPriceRangeLoading } = useCustom<{
    min: number
    max: number
  }>({
    url: `${apiUrl}/cms/product/get_price_min_max/`,
    method: 'get',
    config: {
      filters: [{ field: 'is_internal', operator: 'eq', value: false }],
    },
  })

  const { data: stageData, isLoading: stageDataLoading } =
    useCustom<IProductOption>({
      url: `${apiUrl}/cms/product/`,
      method: 'options',
    })

  useEffect(() => {
    setCategoryNodes(buildNodes(categoriesData?.data ?? []))
  }, [categoriesData, venueData])

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <ProductFilter
          categoryNodes={categoryNodes}
          categoryLoading={isFilterCategoryLoading}
          venues={venueData?.data}
          venuesLoading={isVenueLoading}
          executedVenues={executedVenueData?.data}
          executedVenuesLoading={isExecutedVenueLoading}
          productData={tProps.tableQueryResult.data?.data}
          filterData={filterData?.data}
          filterLoading={isFilterLoading}
          setFilters={coreTableProps.setFilters}
          setSorter={coreTableProps.setSorter}
          setCurrent={coreTableProps.setCurrent}
          priceRange={priceData?.data}
          stageOptions={stageData?.data.actions?.POST?.stage.choices.map(
            (choice: any, i: any) => ({
              key: i,
              label: choice.display_name,
              value: choice.value,
            }),
          )}
          stageLoading={stageDataLoading}
          showInternalUseFilters
        />
      </Col>
      <Col lg={18}>
        <ProductTable tProps={tProps} />
      </Col>
    </Row>
  )
}
