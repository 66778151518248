import { Button, InputNumber, Space, Typography } from "antd";
import { useEffect, useState } from 'react'

export const PriceRange: React.FC<{
  max?: number
  min?: number
  value?: [number, number]
  onChange?: (value: [number, number]) => void
  onSubmit?: () => void
}> = ({ max, min, value, onChange, onSubmit }) => {
  const [minValue, setMinValue] = useState<number>(value ? value[0] : min ?? 0)
  const [maxValue, setMaxValue] = useState<number>(
    value ? value[1] : max ?? 100,
  )

  const handleChange = (type: 'min' | 'max', value: number) => {
    const final: [number, number] =
      type === 'min' ? [value, maxValue] : [minValue, value]
    if (type === 'min') setMinValue(value)
    else setMaxValue(value)
    if (onChange) onChange(final)
  }

  useEffect(() => {
    if (value) {
      setMinValue(value[0])
      setMaxValue(value[1])
    }
  }, [value])

  return (
    <Space size={[8, 16]} wrap>
      <InputNumber
        placeholder="Min"
        prefix="₹"
        value={minValue}
        onChange={(value: any) => handleChange('min', value)}
      />
      <Typography.Text> - </Typography.Text>
      <InputNumber
        placeholder="Max"
        prefix="₹"
        value={maxValue}
        onChange={(value: any) => handleChange('max', value)}
      />
      <Button onClick={onSubmit}>Go</Button>
    </Space>
  )
}
