import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ISurcharge } from 'interfaces/catering'
import { SurchargeForm } from './Form'

export const SurchargeCreate: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ISurcharge>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <SurchargeForm formProps={formProps} />
    </Create>
  )
}
