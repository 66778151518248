import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { IPaymentType } from 'interfaces/payment'
import { useState } from 'react'
import { PAYMENT_TYPE_URL } from 'urls'

export const PaymentTypeList: React.FC = () => {
  const tProps = useTable<IPaymentType>({
    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'cms/paymentTypes',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: (event) => edit(PAYMENT_TYPE_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis/>
        <Table.Column dataIndex="flat_price" title="Flat Price"/>
        <Table.Column dataIndex="percentage" title="Percentage" ellipsis/>
        <Table.Column dataIndex="order" title="Order" ellipsis/>
      </Table>
    </List>
  );
}
