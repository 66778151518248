import { useState } from "react";
import { PhotoshopPicker } from "react-color";

export const ColorPicker: React.FC<{
  value?: string;
  onChange?: (color: string) => void;
}> = ({ value, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);

  const styles = {
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
      background: value,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <>
      <div style={styles.swatch} onClick={() => setShowPicker(true)}>
        <div style={styles.color} />
      </div>
      {showPicker && (
        <div
          style={{
            position: "fixed",
            zIndex: 999,
            transform: "translate(50px, -150px)"
          }}
        >
          <PhotoshopPicker
            color={value ?? "#fff"}
            onChangeComplete={(color) => onChange && onChange(color?.hex)}
            onAccept={() => {
              setShowPicker(false);
            }}
            onCancel={() => setShowPicker(false)}
          />
        </div>
      )}
    </>
  );
};
