import { Card, Form, InputNumber } from "antd";
import { useApiUrl, useCustom } from "@refinedev/core";
import { GridSelect } from 'components/input/GridSelect'
import { useEffect } from 'react'
import { COMBINATION_URL, PRICING_ITEM_URL } from 'urls'
import { PriceComponent } from './PriceComponent'
import { TotalComponent } from './TotalComponent'

export const StructureCombo = ({ handleTotal, form, field, listName }: any) => {
  const apiUrl = useApiUrl()

  const itemId = Form.useWatch([listName, field.name, 'item'], form)

  const qty = Form.useWatch([listName, field.name, 'quantity'], form)

  const { data: comboData, refetch: getCombo } = useCustom({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: itemId,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const getTotal = () => {
    const price = comboData?.data.price ?? 0
    return price * qty
  }

  useEffect(() => {
    getCombo()
  }, [itemId])

  useEffect(() => {
    const values = form.getFieldsValue(true)[listName]
    values[field.name].total = getTotal()
    values[field.name].combination = comboData?.data.id
    form.setFieldsValue({ ...values })
    form.submit()
  }, [comboData?.data.id, qty])

  return (
    <Card size="small">
      <Form.Item {...field} label="Item" name={[field.name, 'item']}>
        <GridSelect
          useTableProps={{
            resource: PRICING_ITEM_URL,
            permanentFilter: [
              { field: 'category', operator: 'eq', value: 'STRUCTURE' },
              { field: 'is_visible', operator: 'eq', value: true },
            ],
          }}
          title="Structure Items"
        />
      </Form.Item>

      <PriceComponent value={comboData?.data?.price ?? 0} />

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Form.Item
          {...field}
          name={[field.name, 'quantity']}
          label={`QTY ${
            comboData?.data.uom ? '(' + comboData?.data.uom + ')' : ''
            }`}
          initialValue={1}>
          <InputNumber placeholder="QTY" />
        </Form.Item>

        <Form.Item {...field} name={[field.name, 'total']} initialValue={0}>
          <TotalComponent />
        </Form.Item>
      </div>
    </Card>
  )
}
