import { useSelect } from '@refinedev/antd'
import { BaseRecord, HttpError } from '@refinedev/core'

export const useSelectWithSearch = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TQueryFnData extends BaseRecord = BaseRecord,
>(
  props: any,
) =>
  useSelect<TData>({
    ...props,
    onSearch: (value) => [
      {
        field: 'search',
        operator: 'eq',
        value,
      },
    ],
  })
