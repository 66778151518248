import { Edit, ListButton, PageHeaderProps, RefreshButton } from "@refinedev/antd";
import { Spin } from "antd";
import { useCan } from "@refinedev/core";
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import { ICategory } from 'interfaces/category'
import { CategoryForm } from './Form'

export const CategoryEdit: React.FC = () => {
  const useFormProps = useForm<ICategory>()

  const { data: writeCatalog } = useCan({
    resource: 'write_catalog',
    action: '',
  })

  const pageHeaderProps: PageHeaderProps = {
    extra: [
      <LogButton key="log" />,
      <ListButton key="list" />,
      <RefreshButton key="refresh" />,
    ],
  }

  return (
    <Edit
      saveButtonProps={useFormProps.saveButtonProps}
      headerProps={pageHeaderProps}
      canDelete={writeCatalog?.can}>
      {useFormProps.formLoading ? (
        <Spin />
      ) : (
        <CategoryForm useFormProps={useFormProps} />
      )}
    </Edit>
  );
}
