import { Create } from "@refinedev/antd";
import { LayoutWrapper, useNavigation } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { IPackageSection } from 'interfaces/package'
import { useParams } from 'react-router-dom'
import { PackageSectionForm } from './Form'

export const PackageSectionCreate: React.FC = () => {
  //@ts-ignore
  const { package_id } = useParams()
  const { edit, goBack } = useNavigation()
  const useFormProps = useForm<IPackageSection>({
    action: 'create',
    resource: `package_tool/packages/${package_id}/sections`,
    onMutationSuccess: () => {
      edit('package_tool/packages', package_id as string)
    },
    redirect: false,
  })

  return (
    <LayoutWrapper>
      <Create
        saveButtonProps={useFormProps.saveButtonProps}
        headerProps={{
          onBack: () => goBack(),
          breadcrumb: <></>,
          extra: null,
        }}>
        <PackageSectionForm useFormProps={useFormProps} />
      </Create>
    </LayoutWrapper>
  );
}
