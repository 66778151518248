import { file2Base64, useOne } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber, Select, Transfer } from 'antd'
import { useTransfer } from 'components/hooks'
import { SingleImageUpload } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICourse, ICuisine } from 'interfaces/catering'
import { COURSE_URL, CUISINE_URL, DISH_URL } from 'urls'

export const DishForm: React.FC<{
  formProps: FormProps
  queryResult: any
}> = ({ formProps, queryResult }) => {
  const { transferProps } = useTransfer<ICuisine>({
    resource: CUISINE_URL,
    pagination: {
      mode: 'off',
    },
  })

  const { data: dietary_preferences } = useOne<any>({
    resource: DISH_URL,
    id: 'dietary_preferences',
  })

  const onFinish = async (values: any) => {
    let coverImage = ''
    const { image } = values

    // remove the image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.image

    if (image) {
      const file = image[image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values, parent: values.parent ?? null }
    if (coverImage.length) finalResponse['image'] = coverImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <SingleImageUpload
        label="Image"
        name="image"
        initialImageUrl={queryResult?.data?.data?.image}
      />
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Price" name="price">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Course" name="course">
        <SelectWithDefault<ICourse>
          mode="multiple"
          useSelectProps={{ resource: COURSE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="dietary_preferences" label="Dietary Preference">
        <Select
          allowClear
          dropdownMatchSelectWidth={false}
          options={dietary_preferences?.data.results.map((t: any) => ({
            label: t,
            value: t,
          }))}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item label="Cuisines" name="cuisines" valuePropName="targetKeys">
        <Transfer
          {...transferProps}
          showSearch
          listStyle={{ width: '50%', height: 400 }}
        />
      </Form.Item>
    </Form>
  )
}
