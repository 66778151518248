import { List as AntdList, Button, InputNumber } from 'antd'
import { useApiUrl, useCustom, useList } from '@refinedev/core'
import { GridSelect } from 'components/input/GridSelect'
import { useState } from 'react'
import { MOODBOARDIMAGES_URL } from 'urls'

export const CreatePackage: React.FC = () => {
  const [selectedItems, setSelectedItems] = useState<any>([])
  const [budget, setBudget] = useState<number>(100000)
  const apiUrl = useApiUrl()

  const {
    data: packageData,
    isFetching,
    refetch,
  } = useCustom<any>({
    url: `${apiUrl}/package_tool/shortlist_packages/generate_package/`,
    method: 'post',
    config: {
      payload: {
        imageType: 'Moodboard',
        products: selectedItems.map((item: any) => {
          return item.id
        }),
        budget: budget,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  return (
    <div>
      Package Creation Page with MoodBoard
      <br />
      Budget:{' '}
      <InputNumber
        value={budget}
        onChange={(value: any) => {
          setBudget(value)
        }}
      />
      <br />
      {selectedItems.length > 0 && (
        <Button
          type="primary"
          onClick={() => {
            refetch()
          }}>
          {' '}
          Generate Package
        </Button>
      )}
      <GridSelect<any>
        onOk={(selectedItem: any) => {
          setSelectedItems(selectedItem)
        }}
        multi={true}
        useTableProps={{
          resource: MOODBOARDIMAGES_URL,
        }}
        title={'Moodboard Images (select 8 images)'}
      />
      <AntdList
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={selectedItems}
        renderItem={(item: any) => (
          <img width={150} key={item.id} alt="logo" src={item.image} />
        )}
      />
      {isFetching && '....Generating'}
      {packageData && (
        <a
          href={
            'https://www.meragi.studio/package_tool/shortlist_packages/edit/' +
            packageData.data.package_id
          }
          target="_blank">
          New Package Url
        </a>
      )}
      <br />
      {packageData && (
        <pre>
          {JSON.stringify(packageData.data.required_attributes, null, '\t')}
        </pre>
      )}
    </div>
  )
}
