import { getValueFromEvent } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Upload } from "antd";
import { useEffect, useState } from 'react'

export const SingleImageUpload = ({
  label,
  initialImageUrl,
  name = 'cover_image',
  buttonText = 'Cover Image',
}: {
  label?: string
  initialImageUrl?: string
  name?: string | number | (string | number)[]
  buttonText?: string
}) => {
  const [imageUrl, setImageUrl] = useState<string>(initialImageUrl ?? '')

  // if the initialImageUrl changes from the parent side, set the new image url here
  useEffect(() => {
    setImageUrl(initialImageUrl ?? '')
  }, [initialImageUrl])

  const uploadButton = (
    <div>
      {<Icons.PlusOutlined />}
      <div style={{ marginTop: 8 }}>{buttonText}</div>
    </div>
  )

  const handleChange = async (info: any) => {
    setImageUrl(URL.createObjectURL(info.file))
  }

  return (
    <Form.Item name={name} getValueFromEvent={getValueFromEvent} label={label}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleChange}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </Form.Item>
  )
}
