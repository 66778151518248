import { Card, Select, Space } from 'antd'
import {
  BaseKey,
  useApiUrl,
  useCreateMany,
  useCustom,
  useCustomMutation,
  useUpdate,
} from '@refinedev/core'
import { GridSelect } from 'components/input/GridSelect'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IColor, ICombinationList, IFabricType } from 'interfaces/pricing'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  BREAKDOWNVALUES_URL,
  COLOR_URL,
  COMBINATION_URL,
  FABRIC_TYPE_URL,
} from 'urls'
import { PricingContext } from '.'
import { IFabricColorValue } from './pricingToolTree'
import { debounce } from 'lodash'

export const FabricItem = ({}) => {
  const { item, setItem, setPrice } = useContext(PricingContext)

  const { mutate: patch } = useUpdate()
  const apiUrl = useApiUrl()

  const [fabric, setFabric] = useState<BaseKey>()
  const [colors, setColors] = useState<any>([])
  const [openFabricGrid, setOpenFabricGrid] = useState<boolean>(false)

  const { data: comboData, refetch: getCombo } = useCustom<ICombinationList>({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: item?.info?.item,
        fabric_id: fabric,
      },
    },
    queryOptions: {
      enabled: !!fabric,
    },
  })

  useEffect(() => {
    if (comboData?.data.id) {
      let tempItem = item?.clone()
      if (tempItem.info) {
        tempItem.info.price = comboData.data.price
        setItem(tempItem)
      }
    }
    setPrice()
  }, [comboData])

  useEffect(() => {
    let colors = item?.info?.values?.map((fabricItem: any) => {
      return fabricItem.color
    })
    setColors(colors)
  }, [item])

  useEffect(() => {
    if (item?.is_new) {
      setOpenFabricGrid(true)
      item.is_new = false
    }
  }, [item])

  const { mutate: createMany } = useCreateMany()
  const { mutate: updateBreakdown } = useCustomMutation()

  const updatefabricColors = debounce((fabric_to_add) => {
    updateBreakdown(
      {
        url: `${apiUrl}/${BREAKDOWNVALUES_URL}/${item?.id}/create_update_delete/`,
        // resource: BREAKDOWNVALUES_URL,
        // id: `${item?.id}/create_update_delete`,
        values: {
          colors: fabric_to_add,
          fabric: item?.info?.fabric,
        },
        method: 'post',
      },
      {
        onSuccess: (data) => {
          if (item && item.info) {
            item.info.values = []
          }
          data.data.map((fabricColorValue: any) => {
            const value: IFabricColorValue = {
              id: fabricColorValue.id,
              fabric: fabricColorValue.fabric,
              color: fabricColorValue.color,
            }
            item?.info?.values?.push(fabricColorValue)
          })
        },
      },
    )
  }, 1000)

  const deleteFabricColors = debounce((fabric_to_remove) => {
    // pass
  }, 1000)

  const handleColors = useCallback(
    (value) => {
      updatefabricColors(value)
    },
    [item],
  )

  return (
    <Card>
      <GridSelect<IFabricType>
        onModalCancel={() => {
          setOpenFabricGrid(false)
        }}
        openModal={openFabricGrid}
        onOk={(selectedFabric: any) => {
          if (item?.info) {
            item.info.fabric = selectedFabric.id
            item.info.fabric_image = selectedFabric.image
            patch(
              {
                resource: BREAKDOWNVALUES_URL,
                id: item.id,
                values: { fabric: selectedFabric.id },
                successNotification: false,
              },
              {
                onSuccess: (data, variables, context) => {
                  setFabric(selectedFabric.id)
                  getCombo()
                },
              },
            )
          }
          setOpenFabricGrid(false)
        }}
        useTableProps={{
          resource: FABRIC_TYPE_URL,
          initialFilter: [
            {
              field: 'item_id',
              operator: 'eq',
              value: item?.info?.item,
            },
          ],
        }}
        value={item?.info?.fabric}
        title={'Fabric'}
      />
      {item?.info?.itemDetails?.fabric_color_count && (
        <>
          Color:
          <SelectWithDefault<IColor>
            mode="multiple"
            value={colors}
            onChange={(value) => {
              handleColors(value)
              setColors(value)
            }}
            onDeselect={(value) => {}}
            {...(colors.length >=
              item?.info?.itemDetails?.fabric_color_count && {
              open: false,
            })}
            dropdownMatchSelectWidth={false}
            useSelectProps={{ resource: COLOR_URL }}
            renderOptions={(colors) =>
              colors?.map((color) => (
                <Select.Option key={color.id} value={color.id}>
                  <Space>
                    <div
                      style={{
                        height: 25,
                        width: 25,
                        backgroundColor: color.hex,
                      }}
                    />
                    {color.name}
                  </Space>
                </Select.Option>
              ))
            }
          />
        </>
      )}
    </Card>
  )
}
