// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { CheckCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Card,
  Divider,
  Image,
  Input,
  Modal,
  Result,
  Spin,
} from 'antd'

import {
  BaseKey,
  BaseRecord,
  GetListResponse,
  HttpError,
  useModal,
  useOne,
  useTable,
  useTableProps,
} from '@refinedev/core'

import { PriceField } from 'components/field'
import { useStateWithCallbackLazy } from 'components/hooks'
import parse from 'html-react-parser'
import update from 'immutability-helper'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import './index.less'

/**
 * A custom grid select component that displays a modal with a searchable list
 * of items and allows single or multiple selection. The selected item(s) are
 * displayed in a card. The component receives an object with several optional
 * properties and a required `useTableProps` property, which is an object
 * containing options to fetch the data to populate the list. The `value` and
 * `onChange` properties handle the selected item(s). The `title` property sets
 * the title of the modal. The `getName`, `getImageUrl`, and `getPrice`
 * properties handle displaying the item name, image, and price, respectively.
 * The `defaultQueryParam` property sets a default query parameter to use with
 * the resource endpoint. The `renderTrigger` property sets a custom trigger to
 * display the modal. The `multi` property allows multiple selection. The `onOk`
 * property handles actions to take when the user confirms the selection(s).
 *
 * @param {Object} props - an object with several optional properties and a
 *   required `useTableProps` property, which is an object containing options
 *   to fetch the data to populate the list.
 * @param {BaseKey|BaseKey[]} props.value - the selected item(s).
 * @param {(selectedValue: TData) => void} props.onChange - a function that
 *   handles the selected item(s).
 * @param {useTableProps<any, any>} props.useTableProps - an object containing
 *   options to fetch the data to populate the list.
 * @param {string} [props.title] - the title of the modal.
 * @param {(item?: TData) => string} [props.getName] - a function that returns
 *   the name of the item.
 * @param {(item?: TData) => string} [props.getImageUrl] - a function that
 *   returns the URL of the item image.
 * @param {(item?: TData) => number} [props.getPrice] - a function that returns
 *   the price of the item.
 * @param {string} [props.defaultQueryParam] - a default query parameter to use
 *   with the resource endpoint.
 * @param {(show: CallableFunction) => React.ReactNode} [props.renderTrigger] -
 *   a custom trigger to display the modal.
 * @param {boolean} [props.multi] - allows multiple selection.
 * @param {(selectedItems: TData|TData[]|undefined) => void} [props.onOk] - a
 *   function that handles actions to take when the user confirms the
 *   selection(s).
 * @return {JSX.Element} - returns a JSX element that renders the custom grid
 *   select component.
 */
export const GridSelect = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>({
  useTableProps,
  value: valueFromProp,
  onChange: onChangeFromProp,
  title,
  getName,
  getImageUrl,
  getPrice,
  defaultQueryParam,
  renderTrigger,
  multi,
  onOk,
  openModal,
  onModalCancel,
}: {
  value?: BaseKey | BaseKey[]
  onChange?: (selectedValue: TData) => void
  useTableProps: useTableProps<any, any, any>
  title?: string
  getName?: (item?: TData) => string
  getImageUrl?: (item?: TData) => string
  getPrice?: (item?: TData) => number
  defaultQueryParam?: string
  renderTrigger?: (show: CallableFunction) => React.ReactNode
  multi?: boolean
  onOk?: (selectedItems: TData | TData[] | undefined) => void
  openModal?: boolean
  onModalCancel?: CallableFunction
}) => {
  const [itemList, setItemList] = useState<TData[]>([])
  const [value, setValue] = useStateWithCallbackLazy<
    BaseKey | BaseKey[] | undefined
  >(valueFromProp)
  const [selectedItem, setSelectedItem] = useState<TData | TData[]>()
  const [query, setQuery] = useState('')

  // change the value when valueFromProp changes
  useEffect(() => {
    setValue(valueFromProp, () => {})
  }, [valueFromProp])

  const onChange = (selectedValue: TData) => {
    const curValue = (value as BaseKey[]) ?? []
    const { id } = selectedValue
    let newValue: BaseKey[] | BaseKey | undefined = id
    if (multi) {
      if (curValue.includes(id as BaseKey)) {
        newValue = curValue.filter((curId) => curId !== id)
      } else {
        newValue = [...curValue, id as BaseKey]
      }
    }
    setValue(newValue, (curSelected: any) => onChangeFromProp?.(curSelected))
  }

  /**
   * Update selected item(s) based on user input.
   */
  const handleSelected = (item: TData) => {
    if (!multi) {
      setSelectedItem(item)
      return
    }

    const valueArr = (value as BaseKey[]) ?? []
    const itemIndex = valueArr.indexOf(item.id!)
    const newSelectedItems = [...((selectedItem as TData[]) ?? [])]

    if (itemIndex !== -1) {
      newSelectedItems.splice(itemIndex, 1)
    } else {
      newSelectedItems.push(item)
    }

    setSelectedItem(newSelectedItems)
  }

  const { visible, close, show } = useModal()

  const {
    tableQueryResult: queryResult,
    current,
    pageSize,
    setCurrent,
    setFilters,
  } = useTable<TData, TError>({
    ...useTableProps,
    initialPageSize: 20,
    hasPagination: true,
    queryOptions: {
      enabled: visible || !!openModal,
      onSuccess: (data: GetListResponse<TData>) => {
        setItemList(update(itemList, { $push: data.data ?? [] }))
      },
    },
  })

  const info = (title: String, content: string) => {
    Modal.info({
      centered: true,
      title: title,
      content: parse(content ?? ''),
      width: '70vh !important',
      onOk() {},
    })
  }

  useOne<TData>({
    resource: useTableProps.resource as string,
    // @ts-ignore
    id: !!defaultQueryParam
      ? `${valueFromProp}/${defaultQueryParam}`
      : valueFromProp!,
    queryOptions: {
      enabled: !!valueFromProp && !Array.isArray(valueFromProp),
      onSuccess: (data) => {
        setValue(data.data.id, () => {})
        setSelectedItem(data.data)
      },
    },
  })

  const renderSelected = () => {
    return (
      <Card
        hoverable
        onClick={show}
        size="small"
        cover={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
            }}>
            {(selectedItem as TData)?.image ||
            (getImageUrl && getImageUrl(selectedItem as TData)) ? (
              <Image
                src={
                  getImageUrl
                    ? getImageUrl(selectedItem as TData)
                    : (selectedItem as TData)?.image
                }
                style={{ maxHeight: '100px' }}
                preview={false}
              />
            ) : (
              <Icons.FileImageOutlined />
            )}
          </div>
        }>
        <Card.Meta
          description={
            getName
              ? getName(selectedItem as TData)
              : (selectedItem as TData)?.name
          }
        />
      </Card>
    )
  }

  const renderSelectedMulti = () => {
    // TODO: render multiple cards when multi selected
  }

  /**
   * Determines if the given item is selected based on the current value.
   *
   * @param {TData} item - The item to check.
   * @return {boolean} Whether or not the item is selected.
   */
  const isSelected = (item: TData): boolean => {
    const valueArr = Array.isArray(value) ? value : [value]
    return valueArr.includes(item.id)
  }

  return (
    <div>
      {renderTrigger ? (
        renderTrigger(show)
      ) : !!value ? (
        Array.isArray(selectedItem) ? (
          renderSelectedMulti()
        ) : (
          renderSelected()
        )
      ) : (
        <Card hoverable onClick={() => show()}>
          Select
        </Card>
      )}
      <Modal
        open={visible || !!openModal}
        onCancel={() => {
          setItemList([])
          setCurrent(1)
          close()
          onModalCancel && onModalCancel()
        }}
        onOk={() => {
          onOk?.(selectedItem)
          setValue(undefined, () => {})
          setSelectedItem(undefined)
          close()
        }}
        title={
          <div className="itemModalHeader">
            {title}
            <Input.Search
              className="itemModalSearchBar"
              autoFocus
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
                setFilters([
                  { field: 'search', operator: 'eq', value: e.target.value },
                ])
                setCurrent(1)
                setItemList([])
              }}
            />
          </div>
        }
        bodyStyle={{ maxHeight: '65vh' }}
        destroyOnClose>
        <div className="scroll-content scroll" id="infinite-scroll-content">
          <InfiniteScroll
            hasMore={current * pageSize < (queryResult.data?.total ?? 0)}
            next={() => setCurrent(current + 1)}
            dataLength={itemList.length}
            scrollThreshold={0.7}
            height={'60vh'}
            loader={
              <div className="row h-center">
                <Spin />
              </div>
            }
            endMessage={
              queryResult.data?.total ? (
                <Divider plain />
              ) : queryResult.isFetched ? (
                <Result
                  status="404"
                  title="Oops!"
                  subTitle="We could not find any products with these combinations"
                />
              ) : (
                <div className="row h-center">
                  <Spin />
                </div>
              )
            }>
            <AntdList
              grid={{ gutter: 16, column: 6 }}
              dataSource={itemList}
              style={{ overflow: 'hidden' }}
              renderItem={(item) => (
                <AntdList.Item key={item.id}>
                  <Card
                    onClick={() => {
                      onChange(item)
                      handleSelected(item)
                    }}
                    actions={[
                      <InfoCircleTwoTone
                        onClick={() =>
                          info(
                            getName ? getName(item) : item.name,
                            item.help_text,
                          )
                        }
                        key="info"
                      />,
                      isSelected(item) && (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ),
                    ]}
                    cover={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={(e) => e.stopPropagation()}>
                        {item.image || (getImageUrl && getImageUrl(item)) ? (
                          <Image
                            src={getImageUrl ? getImageUrl(item) : item.image}
                            height={100}
                            width={100}
                          />
                        ) : (
                          <Icons.FileImageOutlined />
                        )}{' '}
                      </div>
                    }>
                    <Card.Meta
                      description={getName ? getName(item) : item.name}
                    />
                    {getPrice && (
                      <PriceField value={getPrice ? getPrice(item) : 0} />
                    )}
                  </Card>
                </AntdList.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Modal>
    </div>
  )
}
