import { Create } from "@refinedev/antd";
import { LayoutWrapper, useNavigation } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { IShortlistPackageSection } from 'interfaces/shortlistPackage'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShortlistPackageSectionForm } from '.'

export const ShortlistPackageSectionCreate: React.FC = () => {
  //@ts-ignore
  const { package_id } = useParams()
  const { edit, goBack } = useNavigation()
  const [total, setTotal] = useState(0)
  const useFormProps = useForm<IShortlistPackageSection>({
    action: 'create',
    resource: `package_tool/shortlist_packages/${package_id}/sections`,
    onMutationSuccess: () => {
      edit('package_tool/shortlist_packages', package_id as string)
    },
    redirect: false,
  })

  return (
    <LayoutWrapper>
      <Create
        saveButtonProps={useFormProps.saveButtonProps}
        headerProps={{
          onBack: () => goBack(),
          extra: null,
        }}>
        <ShortlistPackageSectionForm useFormProps={useFormProps} />
      </Create>
    </LayoutWrapper>
  );
}
