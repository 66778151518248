import {
  useCreate,
  useDelete,
  useRouterContext,
  useTableProps,
} from '@refinedev/core'
import { Button, Space } from 'antd'
import axios from 'axios'
import { GridSelect } from 'components/input/GridSelect'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import {
  BREAKDOWNVALUES_URL,
  COMBINATION_URL,
  PRICING_ITEM_URL,
  PRODUCT_URL,
} from 'urls'
import { Breakdown, Component, Info, ItemType } from './pricingToolTree'
import './styles.less'
export const ItemTypeNav = ({
  breakdown,
  setItem,
}: {
  breakdown: Breakdown
  setItem: any
}) => {
  const [itemTypes, setItemTypes] = useState<string[]>([])
  const { mutate: create } = useCreate()
  const { mutate: destroy } = useDelete()

  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { productId, lineItemId, alternateItemId } = queryString.parse(
    location.search,
  )

  useEffect(() => {
    let itemTypes = []
    if (breakdown.info)
      for (const [key, value] of Object.entries(breakdown.info)) {
        if (value) {
          itemTypes.push(key.toUpperCase())
        }
      }
    setItemTypes(itemTypes)
  }, [breakdown])

  interface Dictionary {
    [key: string]: {
      tableProps: useTableProps<any, any, any>
      onOk?: any
      getImageUrl?: (item: any) => string
    }
  }

  const axiosInstance = axios.create()
  const token = localStorage.getItem('token')

  const createStructure = (structure: Component) => {
    create(
      {
        resource: BREAKDOWNVALUES_URL,
        values: {
          product: productId,
          line_item: lineItemId,
          alternate_item: alternateItemId,
          breakdown: breakdown.id,
          item: structure.info?.item,
          quantity: structure.info?.quantity,
          sub_product: structure.info?.sub_product,
        },
      },
      {
        onError: (error, variables, context) => {
          // An error occurred!
        },
        onSuccess: (data, variables, context) => {
          structure.id = data.data.id as number

          // get combo:- set combination id and price
        },
      },
    )
  }

  const createFloralFabric = (floralFabric: Component) => {
    create(
      {
        resource: BREAKDOWNVALUES_URL,
        values: {
          product: productId,
          line_item: lineItemId,
          alternate_item: alternateItemId,
          breakdown: breakdown.id,
          item: floralFabric.info?.item,
          quantity: floralFabric.info?.quantity,
          sub_product: floralFabric.info?.sub_product,
          fabric: floralFabric.info?.fabric,
        },
      },
      {
        onError: (error, variables, context) => {
          // An error occurred!
        },
        onSuccess: (data, variables, context) => {
          floralFabric.id = data.data.id as number
        },
      },
    )
  }

  const categoryWiseProps: Dictionary = {
    STRUCTURE: {
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'STRUCTURE' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
      onOk: (props: any) => {
        // create a copy of selected item
        // let temp_item = Object.assign({}, value.item)
        let random_number = Math.floor(Math.random() * 100)
        let temp_struct = new Component(
          'STRUCTURE' + random_number.toString(),
          random_number,
        )
        temp_struct.create = create
        let info = new Info(props.name, props.id)
        info.item = props.id
        info.item_image_url = props.image
        temp_struct.info = info

        let req_item_type = breakdown.children.find(
          (itemType: ItemType) => itemType.name === 'STRUCTURE',
        )
        if (req_item_type === undefined) {
          req_item_type = new ItemType('STRUCTURE', 37373)
          breakdown.addChild(req_item_type)
        }

        if (req_item_type) {
          req_item_type.addChild(temp_struct)
          temp_struct.setParent(req_item_type)
        }
        const API_URL = process.env.REACT_APP_API_URL
        let stuct_url = `${API_URL}/${COMBINATION_URL}/get_combo`
        axiosInstance
          .get(stuct_url, {
            params: {
              item_id: props.id,
            },
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            info.price = response.data.price as number
            setItem(temp_struct)
          })
        temp_struct.delete = destroy
        // setItem(temp_struct)
        createStructure(temp_struct)
      },
    },
    PRODUCT: {
      tableProps: {
        resource: PRODUCT_URL,
        permanentFilter: [
          { field: 'is_published', operator: 'eq', value: true },
          { field: 'is_internal', operator: 'eq', value: false },
          { field: 'is_deleted', operator: 'eq', value: false },
          // {
          //   field: 'category',
          //   operator: 'in',
          //   value: allowedCategories?.join(','),
          // },
        ],
      },
      getImageUrl: (item: any) => {
        const imageUrl =
          item && item.images && item.images.length !== 0
            ? item.images[0].image
            : ''
        return imageUrl
      },
      onOk: (props: any) => {
        // create a copy of selected item
        // let temp_item = Object.assign({}, value.item)
        let random_number = Math.floor(Math.random() * 100)
        let temp_struct = new Component(
          'PRODUCT' + random_number.toString(),
          random_number,
        )
        temp_struct.create = create
        let info = new Info(props.name, props.id)
        info.sub_product = props.id
        info.price = props.inventory[0].price
        info.item_image_url =
          props.images && props.images.length !== 0 ? props.images[0].image : ''
        temp_struct.info = info

        let req_item_type = breakdown.children.find(
          (itemType: ItemType) => itemType.name === 'PRODUCT',
        )
        if (req_item_type === undefined) {
          req_item_type = new ItemType('PRODUCT', 37373)
          breakdown.addChild(req_item_type)
        }

        if (req_item_type) {
          req_item_type.addChild(temp_struct)
          temp_struct.setParent(req_item_type)
        }
        temp_struct.delete = destroy
        setItem(temp_struct)
        createStructure(temp_struct)
      },
    },
    FABRIC: {
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'FABRIC' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
      onOk: (selectedItem: any) => {
        // create a copy of selected item
        // let temp_item = Object.assign({}, value.item)
        let random_number = Math.floor(Math.random() * 100)
        let tempFabric = new Component(
          'FABRIC' + random_number.toString(),
          random_number,
        )
        let info = new Info(selectedItem.name, selectedItem.id, selectedItem)
        info.item = selectedItem.id
        info.item_image_url = selectedItem.image
        tempFabric.info = info

        let reqItemType = breakdown.children.find(
          (itemType: ItemType) => itemType.name === 'FABRIC',
        )
        if (reqItemType === undefined) {
          reqItemType = new ItemType('FABRIC', 37373)
          breakdown.addChild(reqItemType)
        }

        if (reqItemType) {
          reqItemType.addChild(tempFabric)
          tempFabric.setParent(reqItemType)
        }
        tempFabric.delete = destroy
        tempFabric.is_new = true
        setItem(tempFabric)
        createFloralFabric(tempFabric)
        setItem(tempFabric)
      },
    },
    FLORAL: {
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'FLORAL' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
      onOk: (props: any) => {
        // create a copy of selected item
        // let temp_item = Object.assign({}, value.item)
        let random_number = Math.floor(Math.random() * 100)
        let temp_floral = new Component(
          'FLORAL' + random_number.toString(),
          random_number,
        )
        let info = new Info(props.name, props.id)
        info.item = props.id
        info.item_image_url = props.images
        temp_floral.info = info

        let req_item_type = breakdown.children.find(
          (itemType: ItemType) => itemType.name === 'FLORAL',
        )
        if (req_item_type === undefined) {
          req_item_type = new ItemType('FLORAL', 37373)
          breakdown.addChild(req_item_type)
        }
        if (req_item_type) {
          req_item_type.addChild(temp_floral)
          temp_floral.setParent(req_item_type)
        }
        temp_floral.delete = destroy
        temp_floral.is_new = true
        setItem(temp_floral)
        createFloralFabric(temp_floral)
      },
    },
    default: {
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'STRUCTURE' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
    },
  }

  return (
    <div className="item-type-nav">
      <Space
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {itemTypes.map((itemType) => {
          return (
            <div key={itemType}>
              <GridSelect
                useTableProps={categoryWiseProps[itemType].tableProps}
                onOk={categoryWiseProps[itemType].onOk}
                title={itemType}
                renderTrigger={(show) => (
                  <Button type="primary" ghost onClick={() => show()}>
                    {itemType}
                  </Button>
                )}
                getImageUrl={categoryWiseProps[itemType].getImageUrl}
              />
            </div>
          )
        })}
      </Space>
    </div>
  )
}
