import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IFabricType } from 'interfaces/pricing'
import { FabricTypeForm } from './Form'

export const FabricTypeEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IFabricType>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <FabricTypeForm formProps={formProps} queryResult={queryResult} />
    </Edit>
  )
}
