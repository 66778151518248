import React, { useEffect } from 'react'
import { showFlutter, hideFlutterApp } from 'FlutterWrapper'

export const FlutterView: React.FC<any> = ({}) => {
  useEffect(() => {
    showFlutter()

    return hideFlutterApp
  }, [])

  return <></>
}
