import { Form, FormProps, Input } from "antd";

export const TableSearchInput: React.FC<{
  searchFormProps: FormProps
  name?: string
  placeholder?: string
  key?: string
  intialValues?: { [key: string]: any }
}> = ({
  searchFormProps,
  name = 'search',
  placeholder = 'Search by Name',
  intialValues,
}) => {
  return (
    <Form {...searchFormProps} key="search" initialValues={intialValues}>
      <Form.Item name={name} noStyle>
        <Input.Search
          placeholder={placeholder}
          enterButton
          onSearch={searchFormProps.form?.submit}
        />
      </Form.Item>
    </Form>
  )
}
