import { Edit } from "@refinedev/antd";
import { Spin } from "antd";
import { Authenticated, LayoutWrapper, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { IItem } from 'interfaces/smartPhoto'
import { cleanPathName, getBasePathName } from 'utils/common'
import { ItemForm } from './Form'

export const ItemEdit: React.FC = () => {
  const { useLocation, useParams } = useRouterContext()
  const { pathname } = useLocation()
  const resource = cleanPathName(getBasePathName(pathname))
  const { id, venue_id, smart_photo_id, layer_id } = useParams<any>()

  const useFormProps = useForm<IItem>({
    action: 'edit',
    resource: resource,
    id,
  })

  return (
    <Authenticated>
      <LayoutWrapper>
        <Edit
          saveButtonProps={useFormProps.saveButtonProps}
          title="Edit Item"
          headerProps={{
            breadcrumb: (
              <NestedBreadcrumb
                baseUrl="/package_tool"
                routes={[
                  { name: 'venues', id: venue_id },
                  { name: 'smart_photos', id: smart_photo_id },
                  { name: 'layers', id: layer_id },
                  { name: 'items' },
                ]}
              />
            ),
          }}>
          {useFormProps.formLoading ? (
            <Spin />
          ) : (
            <ItemForm useFormProps={useFormProps} />
          )}
        </Edit>
      </LayoutWrapper>
    </Authenticated>
  );
}
