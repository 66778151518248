import { DateField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { useApiUrl, useGetIdentity, useModal } from '@refinedev/core'
import { Button, Image, Input, Modal, Space, Typography, Upload } from 'antd'
import { USER_KEY } from 'authProvider'
import { onValue, push, ref, set } from 'firebase/database'
import { IChatMessage } from 'interfaces/chat'
import { IItemDetailed } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import { useEffect, useRef, useState } from 'react'
import { firebaseDb } from 'services/firebase'

const USER_ID = localStorage.getItem(USER_KEY) ?? 'random'

export const Chat = ({ item }: { item: IItemDetailed }) => {
  const { show, visible, close } = useModal()

  return (
    <>
      <Button
        icon={<Icons.MessageOutlined />}
        onClick={() => {
          show()
        }}
      />
      {!!visible && <ChatModal visible={visible} close={close} item={item} />}
    </>
  )
}

export const AlwaysScrollToBottom = () => {
  const elementRef = useRef() as any
  useEffect(() => elementRef?.current?.scrollIntoView())
  return <div ref={elementRef} />
}

export function ChatModal({
  close,
  visible,
  item,
}: {
  close: CallableFunction
  visible: boolean
  item: IItemDetailed
}) {
  const [inputText, setInputText] = useState('')
  const [messages, setMessages] = useState<IChatMessage[]>([])
  const [imageUploading, setImageUploading] = useState(false)
  const [imageError, setImageError] = useState(false)

  const API_URL = useApiUrl()

  const { data: user } = useGetIdentity<IStaff>()

  const sendMessage = (uri?: string) => {
    if (inputText.length || uri) {
      const messagesRef = ref(firebaseDb, `chat/item/${item.id}/messages`)
      const newMessageRef = push(messagesRef)
      set(newMessageRef, {
        message: inputText,
        timestamp: Date.now(),
        uri: uri ? uri : null,
        user,
      })
      setInputText('')
    }
  }

  useEffect(() => {
    const messageRef = ref(firebaseDb, `chat/item/${item.id}/messages`)
    onValue(messageRef, (snapshot) => {
      const data = snapshot.val()
      let obtained: any = []
      if (data) {
        Object.values(data).map((m) => obtained.push(m))
        setMessages(Object.values(data).map((m: any) => m))
      }
    })
  }, [])

  const isCurrentUser = (message: IChatMessage): boolean =>
    user?.id === message?.user?.id

  return (
    <Modal
      onCancel={() => close()}
      visible={visible}
      closable={false}
      footer={null}
      destroyOnClose
      bodyStyle={{ maxHeight: '75vh', overflowY: 'hidden' }}>
      <div className="chat-list">
        {messages.map((message, index) => (
          <div key={index}>
            <div
              className={`chat-card ${
                isCurrentUser(message) ? 'chat-right' : ''
              }`}>
              {!isCurrentUser(message) && (
                <Typography.Text className="chat-user">
                  {message.user.first_name} {message.user.last_name}
                </Typography.Text>
              )}
              <div>
                <p>{message?.message}</p>
                {message.uri && (
                  <Image src={message?.uri} className="chat-image" />
                )}
                <div className="chat-right">
                  <Typography.Text style={{ fontSize: 10 }}>
                    <DateField value={message.timestamp} format="LLL" />
                  </Typography.Text>
                </div>
              </div>
            </div>
            <br />
          </div>
        ))}
        <AlwaysScrollToBottom />
      </div>
      <div className="row">
        <Input
          addonBefore={
            <Space>
              <Upload
                action={`${API_URL}/package_tool/upload_chat_image/`}
                data={(file) => ({
                  image: file,
                  item_id: item.id,
                })}
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    setImageUploading(true)
                    setImageError(false)
                  }
                  if (info.file.status === 'done') {
                    sendMessage(info.file.response.uri)
                    setImageUploading(false)
                    setImageError(false)
                  } else if (info.file.status === 'error') {
                    setImageUploading(false)
                    setImageError(true)
                  }
                }}
                itemRender={() => null}>
                <Button
                  type="text"
                  icon={
                    imageUploading ? (
                      <Icons.LoadingOutlined />
                    ) : (
                      <Icons.FileImageOutlined
                        style={{ color: imageError ? 'red' : undefined }}
                      />
                    )
                  }
                />
              </Upload>
            </Space>
          }
          multiple
          size="large"
          addonAfter={<Icons.SendOutlined onClick={() => sendMessage()} />}
          onPressEnter={() => sendMessage()}
          placeholder="Type here..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
      </div>
    </Modal>
  )
}
