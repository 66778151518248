import { useModalForm } from '@refinedev/antd'
import {
  parseTableParams,
  useDelete,
  useGetIdentity,
  useRouterContext,
} from '@refinedev/core'
import { Button, Form, Input, Modal, Select } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IReportSavedFilter } from 'interfaces/crm'
import { REPORT_SAVED_FILTER_URL } from 'urls'
import * as Icons from '@ant-design/icons'
import { IStaff } from 'interfaces/staff'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { getSearchFormInitialValue } from 'utils/filter'
import { cleanBool } from 'utils/common'

export const reportFilterFieldFormatters = {
  relationship_manager: (value: any) =>
    !!value
      ? value
          .toString()
          .split(',')
          .map((val: string) => Number(val))
      : undefined,
  venue: (value: any) => cleanBool(value),
  created_at: (value: any) => value.map((date: string) => dayjs(date)),
}

const SaveFilterButton = ({
  report_name,
  report_type,
  searchFormProps,
  setFilters,
  report_url,
}: {
  report_name: string
  report_type?: string
  searchFormProps: any
  setFilters: any
  report_url: string
}) => {
  const { formProps, modalProps, show } = useModalForm({
    resource: REPORT_SAVED_FILTER_URL,
    action: 'create',
    redirect: false,
  })
  const { data: user } = useGetIdentity<IStaff>()
  const { useLocation } = useRouterContext()

  const currentLocation = useLocation()

  const SavedFilterOption = ({ filter }: { filter: IReportSavedFilter }) => {
    const { mutate: destroy, isLoading } = useDelete()

    return (
      <div className="row">
        <span>{filter.name}</span>{' '}
        <Button
          type="text"
          size="small"
          danger
          loading={isLoading}
          icon={<Icons.DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation()
            destroy({ resource: REPORT_SAVED_FILTER_URL, id: filter.id })
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <SelectWithDefault<IReportSavedFilter>
        placeholder="Saved Filters"
        size="small"
        useSelectProps={{
          resource: REPORT_SAVED_FILTER_URL,
          filters: [
            { field: 'user', operator: 'eq', value: user?.id },
            { field: 'report', operator: 'eq', value: report_name },
            { field: 'type', operator: 'eq', value: report_type },
          ],
          optionLabel: 'name',
          optionValue: 'value',
        }}
        onSelect={(value) => {
          searchFormProps.form?.resetFields()
          const parsedParams = parseTableParams(value.replace(report_url, ''))
          searchFormProps.form.setFieldsValue(
            getSearchFormInitialValue(
              parsedParams.parsedFilters,
              reportFilterFieldFormatters,
            ),
          )
          setFilters(parsedParams.parsedFilters, 'replace')
        }}
        renderOptions={(options) =>
          options?.map((option) => (
            <Select.Option key={option.id} value={option.value}>
              <SavedFilterOption filter={option} />
            </Select.Option>
          ))
        }
      />
      <Button onClick={() => searchFormProps.form?.submit()}>Apply</Button>
      <Button
        onClick={() => {
          searchFormProps.form?.submit()
          show()
        }}>
        Apply and Save
      </Button>
      <Modal {...modalProps} title="Save filter">
        <Form
          {...formProps}
          onFinish={(values) =>
            formProps.onFinish!({
              ...values,
              report: report_name,
              type: report_type,
              value: currentLocation.pathname + currentLocation.search,
            })
          }>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default SaveFilterButton
