import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  Select,
  Space,
  Table,
} from 'antd'
import React, { useState } from 'react'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { useApiUrl, useCustom } from '@refinedev/core'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IMaterial } from 'interfaces/material'
import { MATERIAL_URL } from 'urls'

const MaterialAtp = () => {
  const [material, setMaterial] = useState<any>(undefined)
  const [startTime, setStartTime] = useState<any>(undefined)
  const [endTime, setEndTime] = useState<any>(undefined)
  const [pid, setPid] = useState<any>(null)
  const apiUrl = useApiUrl()
  const {
    data: atp_data,
    isFetching,
    refetch,
  } = useCustom<any>({
    url: `${apiUrl}/cms/materials/${material?.id}/material_in_use/`,
    method: 'post',
    config: {
      payload: {
        start_date_time: startTime,
        end_date_time: endTime,
        pid: pid,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const columns = [
    {
      title: 'Pid',
      dataIndex: 'pid',
      key: 'pid',
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Event',
      dataIndex: [
        'package_detail_material',
        'package_detail',
        'section',
        'name',
      ],
    },

    {
      title: 'Date Time',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (text: any) =>
        new Date(text).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }),
    },
    {
      title: 'Return Date Time',
      dataIndex: 'return_date_time',
      key: 'return_date_time',
      render: (text: any) =>
        new Date(text).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      render: (text: any, record: any) => {
        return (
          <>
            {text} {record.uom}
          </>
        )
      },
    },
  ]

  return (
    <div>
      Material
      <Space>
        <SelectWithDefault<IMaterial>
          popupMatchSelectWidth={false}
          size="small"
          useSelectProps={{
            resource: MATERIAL_URL,
            optionLabel: 'name',
            pagination: {
              pageSize: 30,
            },
          }}
          renderOptions={(items) =>
            items?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                <div
                  onClick={() => {
                    setMaterial(item)
                  }}>
                  {item.name}
                </div>
              </Select.Option>
            ))
          }
        />
        <DatePicker.RangePicker
          showTime={{ use12Hours: true }}
          value={[startTime, endTime]}
          ranges={{
            Today: [dayjs(), dayjs().add(1, 'day')],
            'This Month': [
              dayjs().startOf('month'),
              dayjs().endOf('month').add(1, 'day'),
            ],
            'This Week': [
              dayjs().startOf('week'),
              dayjs().endOf('week').add(1, 'day'),
            ],
          }}
          onChange={(dates) => {
            if (dates) {
              setStartTime(dates[0])
              setEndTime(dates[1])
            } else {
              setStartTime(undefined)
              setEndTime(undefined)
            }
          }}
        />
        <Input
          placeholder="PID"
          allowClear
          onChange={(event) => {
            setPid(event.target.value)
          }}
        />
        <Button
          onClick={() => {
            refetch()
          }}>
          Get ATP
        </Button>
      </Space>
      {atp_data?.data && (
        <div>
          <Descriptions>
            <Descriptions.Item label="Quantity in Hand">
              {atp_data?.data?.qty_in_hand}
            </Descriptions.Item>
            <Descriptions.Item label="Available Qty">
              {atp_data?.data?.available_qty}
            </Descriptions.Item>
          </Descriptions>
          <Table
            dataSource={atp_data.data.material_sources}
            columns={columns}
          />
          ;
        </div>
      )}
    </div>
  )
}

export default MaterialAtp
