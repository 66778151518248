import { Authenticated } from "@refinedev/core";
import { MoodBoard } from 'components/layout/moodBoard'
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'

export const MoodBoardView: React.FC = ({}) => {
  // @ts-ignore
  const { id } = useParams()
  const location = useLocation()
  const { shortlisted } = queryString.parse(location.search)
  return <MoodBoard id={id as string} shortlisted={!!shortlisted} />
}

export const AuthenticatedMoodBoardView = () => {
  return (
    <Authenticated>
      <MoodBoardView />
    </Authenticated>
  )
}
