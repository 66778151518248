import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BooleanField,
  CreateButton,
  EditButton,
  List,
  useModal,
  useTable,
} from '@refinedev/antd'
import {
  useCan,
  useDeleteMany,
  useNavigation,
  useUpdate,
} from '@refinedev/core'
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd'
import { DeleteButton } from 'components/button'
import { CheckAccess } from 'components/checkAccess'
import { AutoGeneratedPasswordInput } from 'components/input'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import { generatePassword } from 'utils/common'
import { getSearchFormInitialValue } from 'utils/filter'

export const StaffList: React.FC = () => {
  const tProps = useTable<IStaff, any, { search: string }>({
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    syncWithLocation: true,
  })
  const { modalProps, show, close } = useModal()
  const { mutate } = useDeleteMany()
  const { mutate: changePassword, isLoading: changePasswordIsLoading } =
    useUpdate<IStaff>()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [generatedPassword, setGeneratedPassword] = useState<string>('')
  const [selectedStaff, setSelectedStaff] = useState<IStaff>()

  const { edit } = useNavigation()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'core/staff',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const { data: canWriteStaff } = useCan({
    resource: 'write_staff',
    action: '',
  })

  const pageHeaderProps = {
    extra: canWriteStaff?.can && [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <TableSearchInput
        searchFormProps={tProps.searchFormProps}
        placeholder="Name, Phone Number"
        key="search"
        intialValues={getSearchFormInitialValue(tProps.filters, {})}
      />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <CheckAccess resource="read_staff">
      <List headerProps={pageHeaderProps}>
        <Table
          {...tProps.tableProps}
          rowKey="id"
          rowSelection={rowSelection}
          size="small"
          onRow={(record) => ({
            onClick: (event) => edit('core/staff', record.id),
          })}>
          <Table.Column dataIndex="email" title="Email" ellipsis />
          <Table.Column dataIndex="phone" title="Phone Number" ellipsis />
          <Table.Column
            dataIndex="is_external"
            title="External"
            render={(value) => <BooleanField value={value} />}
          />
          {canWriteStaff?.can && (
            <Table.Column<IStaff>
              title="Actions"
              dataIndex="actions"
              ellipsis
              render={(_text, record): React.ReactNode => {
                return (
                  <span onClick={(e) => e.stopPropagation()}>
                    <Space>
                      <Tooltip title="Change Password">
                        <Button
                          icon={<FontAwesomeIcon icon={['fas', 'key']} />}
                          size="small"
                          onClick={() => {
                            show()
                            setSelectedStaff(record)
                          }}
                        />
                      </Tooltip>
                      <EditButton
                        size="small"
                        recordItemId={record.id}
                        hideText
                      />
                    </Space>
                  </span>
                )
              }}
            />
          )}
        </Table>
      </List>
      <Modal
        onOk={() => {
          changePassword(
            {
              resource: 'core/staff',
              id: `${selectedStaff?.id}/change_password`,
              values: { password: generatedPassword },
            },
            {
              onSuccess: () => {
                setSelectedStaff(undefined)
                setGeneratedPassword('')
                close()
              },
            },
          )
        }}
        {...modalProps}
        confirmLoading={changePasswordIsLoading}>
        <Space direction="vertical">
          <Typography.Title level={5}>
            Generating password for {selectedStaff?.email}
          </Typography.Title>
          <AutoGeneratedPasswordInput
            value={generatedPassword}
            onChange={setGeneratedPassword}
            onClick={() => setGeneratedPassword(generatePassword())}
          />
        </Space>
      </Modal>
    </CheckAccess>
  )
}
