import { List, useTable } from '@refinedev/antd'

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from 'antd'

import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import { PIPELINE_DETAILED_REPORT_URL, STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'
import SaveFilterButton, { reportFilterFieldFormatters } from './SaveFilter'
import { ReportNames } from 'interfaces/crm'
import { getSearchFormInitialValue } from 'utils/filter'

export const PipelineDetailedReport = () => {
  const [cohort, setCohort] = useState(true)

  const { tableProps, searchFormProps, setFilters, filters } = useTable<any>({
    resource: `${PIPELINE_DETAILED_REPORT_URL}${cohort ? '' : '_effort'}`,
    syncWithLocation: true,
    onSearch: (data: any) => [
      {
        field: 'relationship_manager',
        operator: 'eq',
        value: data.relationship_manager
          ? data.relationship_manager.join(',')
          : undefined,
      },
      {
        field: 'created_at',
        operator: 'between',
        value: data.created_at
          ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
          .join(','),
      },
      { field: 'venue', operator: 'eq', value: data.venue },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        {
          field: 'created_at',
          operator: 'between',
          value: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD'),
          ].join(','),
        },
        { field: 'venue', operator: 'eq', value: null },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={'Pipeline Detailed View'}>
            <Space>
              Effort
              <Switch checked={cohort} onChange={() => setCohort(!cohort)} />
              Cohort
            </Space>
            <Divider />
            <Form
              {...searchFormProps}
              initialValues={getSearchFormInitialValue(
                filters,
                reportFilterFieldFormatters,
              )}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name="relationship_manager">
                    <SelectWithDefault<IStaff>
                      mode="multiple"
                      useSelectProps={{ resource: STAFF_URL }}
                      placeholder="Select RMs"
                      renderOptions={(items) =>
                        items?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {getUserName(item)}
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="created_at" label="Created At">
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="venue">
                    <Radio.Group
                      defaultValue={null}
                      buttonStyle="solid"
                      size="small">
                      <Radio.Button value={null}>All</Radio.Button>
                      <Radio.Button value={true}>Venue Decided</Radio.Button>
                      <Radio.Button value={false}>
                        Venue Not Decided
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SaveFilterButton
                    report_name={ReportNames.PIPELINE_DETAILED}
                    report_type={cohort ? 'Cohort' : 'Effort'}
                    searchFormProps={searchFormProps}
                    setFilters={setFilters}
                    report_url={'/' + PIPELINE_DETAILED_REPORT_URL}
                  />
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                bordered
                scroll={{ y: '64vh' }}>
                <Table.Column
                  dataIndex="service"
                  title="Service"
                  fixed
                  width={200}
                  render={(value) =>
                    value === null
                      ? 'No service'
                      : value === undefined
                      ? 'Total'
                      : value
                  }
                />
                <Table.Column
                  dataIndex="total_qualified"
                  title="Qualified"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_inactive"
                  title="Inactive"
                  width={100}
                />
                <Table.Column
                  title="Inactive %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.total_inactive ?? 0) /
                          (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="total_pip"
                  title="Prospect"
                  width={100}
                />
                <Table.Column
                  title="Prospect %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.total_pip ?? 0) /
                          (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />

                <Table.Column dataIndex="total_pp" title="PP" width={100} />
                <Table.Column
                  title="PP %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.total_pp ?? 0) /
                          (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="total_bookings"
                  title="Bookings"
                  width={100}
                />
                <Table.Column
                  title="Booking/QL %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.total_bookings ?? 0) /
                          (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  title="Booking/PP %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.total_bookings ?? 0) /
                          (record.total_pp ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />

                <Table.Column
                  dataIndex="venue_bgmv"
                  title="BGMV"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
