import { List, useTable } from '@refinedev/antd'

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd'

import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { PriceField } from 'components/field'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { Cities, ReportNames } from 'interfaces/crm'
import { useState } from 'react'
import { SOURCE_REPORT_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import SaveFilterButton, { reportFilterFieldFormatters } from './SaveFilter'
import { getSearchFormInitialValue } from 'utils/filter'

export const SourceReport = () => {
  const [cohort, setCohort] = useState(true)

  const { tableProps, searchFormProps, setFilters, filters } = useTable<any>({
    resource: `${SOURCE_REPORT_URL}${cohort ? '' : '_effort'}`,
    syncWithLocation: true,

    onSearch: (data: any) => [
      {
        field: 'created_at',
        operator: 'between',
        value: data.created_at
          ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
          .join(','),
      },
      { field: 'venue', operator: 'eq', value: data.venue },
      { field: 'city', operator: 'in', value: data.city?.join(',') },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        {
          field: 'created_at',
          operator: 'between',
          value: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD'),
          ].join(','),
        },
        { field: 'venue', operator: 'eq', value: null },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={'Source Analysis'}>
            <Space>
              Effort
              <Switch checked={cohort} onChange={() => setCohort(!cohort)} />
              Cohort
            </Space>
            <Divider />
            <Form
              {...searchFormProps}
              initialValues={getSearchFormInitialValue(
                filters,
                reportFilterFieldFormatters,
              )}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name="created_at" label="Created At">
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="city">
                    <Select
                      placeholder="city"
                      mode="tags"
                      style={{ width: 200 }}
                      options={createOptionsFromEnum(Cities)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="venue">
                    <Radio.Group
                      defaultValue={null}
                      buttonStyle="solid"
                      size="small">
                      <Radio.Button value={null}>All</Radio.Button>
                      <Radio.Button value={true}>Venue Decided</Radio.Button>
                      <Radio.Button value={false}>
                        Venue Not Decided
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SaveFilterButton
                    report_name={ReportNames.SOURCE}
                    report_type={cohort ? 'Cohort' : 'Effort'}
                    searchFormProps={searchFormProps}
                    setFilters={setFilters}
                    report_url={'/' + SOURCE_REPORT_URL}
                  />
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                bordered
                scroll={{ y: '64vh' }}
                summary={(pageData) => {
                  let total_leads = 0
                  let totalQualified = 0
                  let totalCallAttempts = 0
                  let totalInactive = 0
                  let totalProposalPresented = 0
                  let totalPPValue = 0
                  let totalPip = 0
                  let totalPipValue = 0
                  let totalBookings = 0
                  let totalBgmv = 0
                  let totalActivePackageValue = 0
                  let totalPPCount = 0

                  pageData.forEach(
                    ({
                      total_count,
                      qualified,
                      total_call_attempts,
                      inactive,
                      proposal_presented,
                      proposal_presented_value,
                      pip,
                      pip_value,
                      bookings,
                      bgmv,
                      total_active_package_value,
                      active_pp_count,
                    }) => {
                      total_leads += total_count ?? 0
                      totalQualified += qualified ?? 0
                      totalCallAttempts += total_call_attempts ?? 0
                      totalInactive += inactive ?? 0
                      totalProposalPresented += proposal_presented ?? 0
                      totalPPValue += proposal_presented_value ?? 0
                      totalPip += pip ?? 0
                      totalPipValue += pip_value ?? 0
                      totalBookings += bookings ?? 0
                      totalBgmv += bgmv ?? 0
                      totalActivePackageValue += total_active_package_value ?? 0
                      totalPPCount += active_pp_count ?? 0
                    },
                  )
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <Typography.Title level={4}>Total</Typography.Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {total_leads}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {totalQualified}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {totalCallAttempts}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {(totalCallAttempts / totalQualified).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text strong>
                            {totalInactive}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {((totalInactive / totalQualified) * 100).toFixed(
                              2,
                            )}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>{totalPip}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {((totalPip / totalQualified) * 100).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalPipValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text strong>
                            {totalProposalPresented}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {(
                              (totalProposalPresented / totalQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalPPValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Typography.Text strong>
                            {totalBookings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {((totalBookings / totalQualified) * 100).toFixed(
                              2,
                            )}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {(
                              (totalBookings / totalProposalPresented) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalBgmv} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalActivePackageValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalPPCount}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}>
                <Table.Column
                  dataIndex="source"
                  title="Source"
                  fixed
                  width={200}
                />
                <Table.Column
                  dataIndex="total_count"
                  title="Total Leads"
                  fixed
                  width={100}
                />
                <Table.Column
                  dataIndex="qualified"
                  title="Qualified"
                  fixed
                  width={100}
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Call Attempts"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Avg Call Attempts"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        (record.total_call_attempts ?? 0) /
                        (record.qualified ?? 1)
                      ).toFixed(2)}
                    </>
                  )}
                />

                <Table.Column
                  dataIndex="inactive"
                  title="Inactive"
                  width={100}
                />
                <Table.Column
                  dataIndex="inactive"
                  title="Inactive %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.inactive ?? 0) / (record.qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column dataIndex="pip" title="Prospect" width={100} />

                <Table.Column
                  dataIndex="pip"
                  title="Prospect %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.pip ?? 0) / (record.qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />

                <Table.Column
                  dataIndex="pip_value"
                  title="Prospect Value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />

                <Table.Column
                  dataIndex="proposal_presented"
                  title="Proposal Presented"
                  width={100}
                />
                <Table.Column
                  dataIndex="proposal_presented"
                  title="PP %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.proposal_presented ?? 0) /
                          (record.qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="proposal_presented_value"
                  title="PP Value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="bookings"
                  title="Bookings"
                  width={100}
                />
                <Table.Column
                  title="Booking/QL %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.bookings ?? 0) / (record.qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  title="Booking/PP %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.bookings ?? 0) /
                          (record.proposal_presented ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="bgmv"
                  title="BGMV"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="total_active_package_value"
                  title="Total active package value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="active_pp_count"
                  title="Total active package count"
                  width={100}
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
