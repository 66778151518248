import { useTable } from "@refinedev/antd";
import { Grid } from "antd";
import { useApiUrl } from "@refinedev/core";
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import { ShortlistPackageTable } from '.'

export const ShortlistPackageList: React.FC = () => {
  const apiUrl = useApiUrl()
  const tProps = useTable<IShortlistPackage, any, { search: string }>({
    syncWithLocation: true,

    onSearch: ({ search }) => {
      return [
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
      ]
    }
  })

  const breakpoint = Grid.useBreakpoint()

  return <ShortlistPackageTable tProps={tProps} />
}
