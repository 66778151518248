import { List, useTable } from '@refinedev/antd'
import { useNavigation } from '@refinedev/core'
import { Table } from 'antd'
import { IWarehouse } from 'interfaces/wms'
import { WAREHOUSE_URL } from 'urls'

export const WarehouseList: React.FC = () => {
  const { tableProps } = useTable<IWarehouse>({
    resource: WAREHOUSE_URL,
  })

  const { edit } = useNavigation()

  return (
    <List>
      <Table
        {...tableProps}
        onRow={(record) => ({
          onClick: (_) => edit(WAREHOUSE_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" />
      </Table>
    </List>
  )
}
