import { Edit } from "@refinedev/antd";
import { LayoutWrapper, useNavigation, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { IPackageSection } from 'interfaces/package'
import { useEffect } from 'react'
import { PackageSectionForm } from './Form'

export const PackageSectionEdit: React.FC = () => {
  const { useParams } = useRouterContext()
  const { package_id, id } = useParams<any>()
  const { edit, goBack } = useNavigation()
  const useFormProps = useForm<IPackageSection>({
    action: 'edit',
    resource: `package_tool/packages/${package_id}/sections`,
    onMutationSuccess: () => {
      edit('package_tool/packages', package_id as string)
    },
    redirect: false,
  })

  // set the id for the item to be fetched
  useEffect(() => {
    useFormProps.setId(id)
  }, [])

  return (
    <LayoutWrapper>
      <Edit
        saveButtonProps={useFormProps.saveButtonProps}
        title="Edit Items"
        headerProps={{
          onBack: () => {
            goBack()
          },
          breadcrumb: <></>,
          extra: null,
        }}>
        <PackageSectionForm useFormProps={useFormProps} />
      </Edit>
    </LayoutWrapper>
  );
}
