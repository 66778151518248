import { Edit, ListButton, RefreshButton, useTable } from "@refinedev/antd";
import { Spin, Typography } from "antd";
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import { IBasicSection, IPackage } from 'interfaces/package'
import { PackageForm } from './Form'
import { PackageSectionList } from './section'

export const PackageEdit: React.FC = () => {
  const useFormProps = useForm<IPackage>()
  const sectionTableProps = useTable<IBasicSection>({
    resource: `package_tool/packages/${useFormProps.id}/sections/`
  })

  return <>
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Typography.Title level={4}>
        Total: ₹{useFormProps.queryResult?.data?.data.price}
      </Typography.Title>
    </div>
    <Edit
      saveButtonProps={useFormProps.saveButtonProps}
      canDelete
      headerProps={{
        extra: [
          <LogButton key="log" />,
          <ListButton key="list" />,
          <RefreshButton key="refresh" />,
        ],
      }}>
      {useFormProps.formLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spin />
        </div>
      ) : (
        <>
          <PackageForm useFormProps={useFormProps} />
        </>
      )}
    </Edit>
    <PackageSectionList
      packageId={useFormProps.id}
      tableProps={sectionTableProps.tableProps}
    />
  </>;
}
