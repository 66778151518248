import { Create } from "@refinedev/antd";
import { Authenticated, LayoutWrapper, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { IItem } from 'interfaces/smartPhoto'
import { cleanPathName, getBasePathName } from 'utils/common'
import { ItemForm } from './Form'

export const ItemCreate: React.FC = () => {
  const { useLocation, useParams } = useRouterContext()
  const { pathname } = useLocation()
  const resource = cleanPathName(getBasePathName(pathname))

  const { id, venue_id, smart_photo_id, layer_id } = useParams<any>()

  const useFormProps = useForm<IItem>({
    action: 'create',
    resource: resource,
  })

  return (
    <Authenticated>
      <LayoutWrapper>
        <Create
          title="Create Item"
          saveButtonProps={useFormProps.saveButtonProps}
          resource={resource}
          headerProps={{
            breadcrumb: (
              <NestedBreadcrumb
                baseUrl="/package_tool"
                routes={[
                  { name: 'venues', id: venue_id },
                  { name: 'smart_photos', id: smart_photo_id },
                  { name: 'layers', id: layer_id },
                  { name: 'items' },
                ]}
              />
            ),
          }}>
          <ItemForm useFormProps={useFormProps} />
        </Create>
      </LayoutWrapper>
    </Authenticated>
  );
}
