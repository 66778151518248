import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IVenue } from 'interfaces/venue'
import { VenueForm } from './Form'

export const VenueCreate: React.FC = () => {
  const useFormProps = useForm<IVenue>({
    redirect: 'edit',
  })

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <VenueForm useFormProps={useFormProps} />
    </Create>
  )
}
