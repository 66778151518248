import { IAlternateItem } from 'interfaces/shortlistPackage'
import React, { useContext, useState } from 'react'

import { ImageField, useForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Button,
  Descriptions,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { useDelete } from '@refinedev/core'
import { PriceField } from 'components/field'
import parse from 'html-react-parser'
import { EditPackageContext, EditPackageContextType } from '../Edit'
import { EditShortlistLineItem } from '../shortlistLineItem'
import { PRICING_MAIN_URL } from 'urls'
import { ReloadOutlined } from '@ant-design/icons'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'

const { Title, Text } = Typography
type Props = {}

const ShortlistPriceField: React.FC<{
  column: string
  value: string | number
}> = ({ column, value }) => {
  return (
    <Space direction="vertical">
      <Title level={5}>{column}</Title>
      <PriceField value={value} />
    </Space>
  )
}

const ShortlistItemField: React.FC<{
  column: string
  value: string | number
}> = ({ column, value }) => {
  return (
    <Space direction="vertical">
      <Title level={5}>{column}</Title>
      <Text>{value}</Text>
    </Space>
  )
}

export const AlternateItem: React.FC<{
  alternateItem?: IAlternateItem
  refetch: any
  refetchPackage: any
}> = ({ alternateItem, refetch, refetchPackage }) => {
  const [edit, setEdit] = useState(false)
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false)
  const { mutate } = useDelete()
  const { editPackageStatus, setEditPackageStatus } = useContext(
    EditPackageContext,
  ) as EditPackageContextType
  const _package = useContext(ShortlistPackageContext)

  const {
    formProps: editFormProps,
    saveButtonProps: editSaveButttonProps,
    form: editForm,
  } = useForm<IAlternateItem>({
    action: 'edit',
    resource: 'package_tool/shortlist_package/alternate_items',
    redirect: false,
    id: alternateItem?.id,
    onMutationSuccess: () => {
      refetch()
      editForm.resetFields()
      setEdit(false)
      setEditPackageStatus(true)
    },
  })
  const deleteAlternateItem = (id: string) => {
    alternateItem?.id &&
      mutate(
        {
          resource: 'package_tool/shortlist_package/alternate_items',
          id: id,
        },
        {
          onError: (error, variables, context) => {
            // An error occurred!
          },
          onSuccess: (data, variables, context) => {
            refetch()
          },
        },
      )
  }

  return (
    <div>
      {alternateItem && (
        <>
          {!edit && (
            <AntdList.Item
              actions={
                editPackageStatus && !edit && !_package?.is_cancelled
                  ? [
                      <Button
                        onClick={() => {
                          setEdit(!edit)
                          setEditPackageStatus(false)
                        }}
                        size="small"
                        type="text"
                        icon={<Icons.EditOutlined />}
                        key="edit">
                        Edit
                      </Button>,
                      <Popconfirm
                        title="Do you Want to delete this Shortlist Item ?"
                        open={openDeleteItemModal}
                        onConfirm={() => {
                          deleteAlternateItem(alternateItem?.id)
                          refetch()
                        }}
                        key="delete"
                        onCancel={() => setOpenDeleteItemModal(false)}
                        okText="Yes"
                        cancelText="No">
                        <Button
                          size="small"
                          danger
                          type="text"
                          onClick={() => setOpenDeleteItemModal(true)}
                          icon={<Icons.DeleteOutlined />}>
                          Delete
                        </Button>
                      </Popconfirm>,
                    ]
                  : undefined
              }
              extra={
                <ImageField
                  value={alternateItem?.product.images[0]?.image}
                  height={150}
                  width={150}
                  style={{ borderRadius: '20px' }}
                />
              }>
              <AntdList.Item.Meta
                title={
                  <Space direction="vertical">
                    {alternateItem.product.name}
                  </Space>
                }
              />
              <Descriptions>
                <Descriptions.Item label="Quantity">
                  {alternateItem.quantity}
                </Descriptions.Item>
                <Descriptions.Item label="Price">
                  <Space>
                    <PriceField value={alternateItem.price ?? 0.0} />
                    {!!alternateItem.product?.has_breakdown && (
                      <Space>
                        <Tooltip key="calculate" overlay="Calculate Price">
                          <Button
                            size="small"
                            type="primary"
                            onClick={() =>
                              window.open(
                                `/${PRICING_MAIN_URL}_v2/?productId=${alternateItem.product?.id}&alternateItemId=${alternateItem.id}`,
                              )
                            }>
                            ₹
                          </Button>
                        </Tooltip>
                        <Tooltip overlay="Refresh Price">
                          <Button
                            size="small"
                            type="dashed"
                            key="refresh"
                            icon={<ReloadOutlined />}
                            onClick={() => {
                              refetch()
                              refetchPackage()
                            }}
                          />
                        </Tooltip>
                      </Space>
                    )}
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  <PriceField
                    value={alternateItem?.quantity * alternateItem.price}
                  />
                </Descriptions.Item>
              </Descriptions>
              {parse(alternateItem.description ?? '')}
            </AntdList.Item>
          )}
          {edit && (
            <EditShortlistLineItem
              form={editForm}
              formProps={editFormProps}
              saveButtonProps={editSaveButttonProps}
              cancelEdit={() => {
                editForm.resetFields()
                setEdit(false)
              }}
              isAlternate={true}
            />
          )}
        </>
      )}
    </div>
  )
}
