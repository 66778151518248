import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageField } from '@refinedev/antd'

import {
  Layout as AntdLayout,
  Button,
  Carousel,
  Col,
  Collapse,
  Image,
  Popover,
  Result,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd'

import {
  Authenticated,
  BaseKey,
  useApiUrl,
  useCustom,
  useGetIdentity,
  useList,
  useModal,
  useTable,
} from '@refinedev/core'

import {
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { Divider, Modal, Table, TreeDataNode, Typography } from 'antd'
import { EXTERNAL_USER_KEY } from 'authProvider'
import { PriceField } from 'components/field/PriceField'
import { ProductFilter } from 'components/filters/productFilter'
import { MERAGI_GOLD, MERAGI_PURPLE } from 'consts'
import parse from 'html-react-parser'
import update from 'immutability-helper'
import { IAttributeValueDetail, IFilter } from 'interfaces/attribute'
import { IStructuredCategory } from 'interfaces/category'
import { IProductShowcase } from 'interfaces/product'
import { IExecutedImage } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import { IVenue } from 'interfaces/venue'
import { ReactElement, useEffect, useState } from 'react'
import { SplitPane } from 'react-collapse-pane'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import ReactPlayer from 'react-player/youtube'
import { EXECUTED_IMAGE_URL, PRODUCT_URL } from 'urls'
import { buildNodes, generateFilterQuery } from 'utils/filter'
import './styles.less'

const { Text, Title } = Typography

const HIDE_PRICE_KEY = 'hidePrice'
const isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

export const ImageCarousel = ({
  images,
  width,
  height,
  style,
  badges,
  className,
}: {
  images: { id: BaseKey; image: string }[]
  width?: number
  height?: number
  style?: any
  className?: string
  badges?: { visible?: boolean; icon: ReactElement }[]
}) => {
  const [visible, setVisible] = useState(false)

  if (images.length)
    return (
      <>
        <div className="image-container">
          <Image
            preview={{ visible: false }}
            width={width}
            height={height}
            wrapperStyle={style}
            className={className}
            src={images[0].image}
            onClick={() => setVisible(true)}
          />
          <Space className="badge-container">
            {badges?.map((badge) => {
              return (
                badge.visible && (
                  <div className="gallery-badge">{badge.icon}</div>
                )
              )
            })}
            {images.length > 1 && (
              <div className="gallery-badge">
                <FontAwesomeIcon icon={['fas', 'layer-group']} />
              </div>
            )}
          </Space>
        </div>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
            {images.map((image) => (
              <Image key={image.id} src={image.image} />
            ))}
          </Image.PreviewGroup>
        </div>
      </>
    )
  return <ImageField value={'/product_paleceholder.png'} />
}

const ProductCard: React.FC<{ item: IProductShowcase }> = ({ item }) => {
  const { show, visible, close } = useModal()
  const { data: attributes } = useList<IAttributeValueDetail>({
    resource: `cms/product/${item.id}/get_attribute_values/`,
    queryOptions: {
      enabled: visible,
    },
  })

  const [leftPanelData, setLeftPanelData] = useState<IAttributeValueDetail[]>(
    [],
  )
  const [rightTableData, setRightTableData] = useState<IAttributeValueDetail[]>(
    [],
  )

  useEffect(() => {
    if (attributes) {
      let leftData = attributes.data?.filter((value: IAttributeValueDetail) => {
        if (
          value.attribute.show_left &&
          value.attribute.attribute_type === 'Image'
        )
          return value
      })
      let rightData = attributes.data?.filter(
        (value: IAttributeValueDetail) => {
          if (!value.attribute.show_left) return value
        },
      )

      setLeftPanelData(leftData)
      setRightTableData(rightData)
    }
  }, [attributes])

  const { data: executedImages } = useList<IExecutedImage>({
    resource: EXECUTED_IMAGE_URL,
    queryOptions: {
      enabled: !!item.space_in_venue,
    },
    filters: [
      {
        field: 'venue',
        operator: 'in',
        value: item.space_in_venue?.id,
      },
    ],
  })

  const hidePrice = localStorage.getItem(HIDE_PRICE_KEY) === 'true'

  return (
    <div className="showcase-card">
      <div className="card-cover">
        <ImageCarousel
          images={item.images}
          badges={[
            {
              visible: !!item.media.length,
              icon: <VideoCameraOutlined />,
            },
            {
              visible: !!item.has_breakdown,
              icon: <EditOutlined />,
            },
          ]}
        />
      </div>
      <div className="card-content" onClick={show}>
        <div className="card-title-container">
          <Title
            level={5}
            style={{
              color: item.is_space
                ? MERAGI_GOLD
                : item.is_executed
                ? MERAGI_PURPLE
                : undefined,
            }}>
            {item.name}
          </Title>
        </div>
        {!hidePrice && (
          <div className="card-description-container">
            <Text type="secondary">
              <PriceField value={item.price} />
            </Text>
          </div>
        )}
      </div>
      <Modal
        title={
          <>
            <Typography.Title
              level={4}
              style={{
                color: item.is_space
                  ? MERAGI_GOLD
                  : item.is_executed
                  ? MERAGI_PURPLE
                  : undefined,
              }}>
              {item.name}
            </Typography.Title>
            {!hidePrice && (
              <>
                <Typography.Text strong>
                  <PriceField value={item.price} />
                </Typography.Text>
                <br />
              </>
            )}

            <Typography.Text style={{ fontSize: 12 }}>
              SKU: {item.sku}
            </Typography.Text>
          </>
        }
        className="showcase-modal"
        bodyStyle={{ height: '75vh', overflow: 'hidden' }}
        visible={visible}
        footer={null}
        onCancel={close}
        destroyOnClose>
        <Row>
          <Col span={12}>
            <div className="modal-carousel-container">
              <Collapse accordion defaultActiveKey="images">
                <Collapse.Panel header="Image" key="images">
                  <div className="detail-image-cont">
                    <ImageCarousel
                      images={item.images}
                      className="detail-image"
                    />
                  </div>
                </Collapse.Panel>
                {item.space_in_venue && executedImages?.total && (
                  <Collapse.Panel header="Executed Images" key="executedImages">
                    <div className="detail-image-cont">
                      <ImageCarousel
                        images={executedImages?.data}
                        className="detail-image"
                      />
                    </div>
                  </Collapse.Panel>
                )}
                {item.media.length && (
                  <Collapse.Panel header="Video" key="videos">
                    <Carousel
                      arrows
                      prevArrow={<LeftOutlined />}
                      nextArrow={<RightOutlined />}>
                      {item.media.map((media) => (
                        <ReactPlayer
                          url={media.url}
                          key={media.id}
                          width={'100%'}
                          pip={false}
                          config={{
                            playerVars: {
                              rel: 0,
                              modestbranding: 1,
                            },
                          }}
                          controls
                        />
                      ))}
                    </Carousel>
                  </Collapse.Panel>
                )}
                {leftPanelData.length &&
                  leftPanelData.map((value) => (
                    <Collapse.Panel
                      header={value.attribute.name}
                      key={value.attribute.id}>
                      <div className="detail-image-cont">
                        <ImageCarousel
                          images={value.images}
                          className="detail-image"
                        />
                      </div>
                    </Collapse.Panel>
                  ))}
              </Collapse>
            </div>
          </Col>
          <Col span={12}>
            <div className="details-container">
              <Typography.Title level={5}>Description</Typography.Title>
              {parse(item.description ?? '')}
              {item.is_space && (
                <Space align="baseline">
                  <Typography.Title level={5}>Venue:</Typography.Title>
                  {item.space_in_venue?.name}
                </Space>
              )}
              {item.is_executed && (
                <>
                  <Space align="baseline">
                    <Typography.Title level={5}>Venue:</Typography.Title>
                    {item.executed_in_venue?.name}
                  </Space>
                  {item.executed_in_space && (
                    <Space align="baseline">
                      <Typography.Title level={5}>Space:</Typography.Title>
                      {item.executed_in_venue?.name}
                    </Space>
                  )}
                </>
              )}
              <Table
                dataSource={rightTableData?.sort((a, b) => a.order - b.order)}
                size="small"
                showHeader={false}
                pagination={false}>
                <Table.Column dataIndex={['attribute', 'name']} />
                <Table.Column<IAttributeValueDetail>
                  render={(_, record) => {
                    if (record.images.length)
                      return (
                        <ImageCarousel images={record.images} height={100} />
                      )
                    else if (record.value_options.length)
                      return record.value_options.map((option, index) => (
                        <Typography.Text key={option.id}>
                          {option.option.name}
                          {!!(index + 1 < record.value_options.length) && ', '}
                        </Typography.Text>
                      ))
                    else
                      return <Typography.Text>{record.value}</Typography.Text>
                  }}
                />
              </Table>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export const CardList: React.FC = () => {
  const [categoryNodes, setCategoryNodes] = useState<TreeDataNode[]>([])
  const apiUrl = useApiUrl()
  const [productList, setProductList] = useState<IProductShowcase[]>([])
  const [showFilterPane, setShowFiltersPane] = useState(true)

  const { data: user } = useGetIdentity<IStaff>()

  const {
    tableQueryResult: queryResult,
    current,
    setCurrent,
    setFilters,
    setSorter,
    filters,
    pageSize,
  } = useTable<IProductShowcase>({
    resource: 'cms/product/',

    queryOptions: {
      enabled: !!user,
      onSuccess: (data: any) => {
        setProductList(update(productList, { $push: data.data ?? [] }))
      },
    },

    pagination: {
      pageSize: 16,
    },

    filters: {
      permanent: [
        {
          field: 'is_internal',
          operator: 'eq',
          value: false,
        },
        {
          field: 'is_deleted',
          operator: 'eq',
          value: false,
        },
        {
          field: 'showcase',
          operator: 'eq',
          value: true,
        },
        {
          field: 'is_published',
          operator: 'eq',
          value: true,
        },
      ],

      defaultBehavior: 'replace',
    },
  })

  const { data: categoriesData, isLoading: isFilterCategoryLoading } =
    useList<IStructuredCategory>({
      resource: 'cms/category/structured/',
    })

  const { data: venueData, isLoading: isVenueLoading } = useList<IVenue>({
    resource: 'package_tool/venues/',

    pagination: { mode: 'off' },

    filters: [
      {
        field: 'spaces__isnull',
        operator: 'eq',
        value: false,
      },
    ],
  })

  const { data: executedVenueData, isLoading: isExecutedVenueLoading } =
    useList<IVenue>({
      resource: 'package_tool/venues/',

      pagination: { mode: 'off' },

      filters: [
        {
          field: 'executed__isnull',
          operator: 'eq',
          value: false,
        },
      ],
    })

  const { data: filterData, isLoading: isFilterLoading } = useCustom<IFilter[]>(
    generateFilterQuery(
      `${apiUrl}/cms/product/get_filters/`,
      filters,
      !!queryResult.data?.data?.length,
    ),
  )

  const { data: priceData, isLoading: isPriceRangeLoading } = useCustom<{
    min: number
    max: number
  }>({
    url: `${apiUrl}/${PRODUCT_URL}/get_price_min_max/`,
    method: 'get',
    config: {
      filters: [{ field: 'is_internal', operator: 'eq', value: false }],
    },
  })

  const onFinish = () => {
    // in case of filter the product list should be reset
    // and the product should not be appended
    setProductList([])
  }

  useEffect(() => {
    window.document.title = 'Meragi | Showcase'
    // @ts-ignore
    window.document.querySelector('body').style.overflow = 'hidden'
  }, [])

  useEffect(() => {
    setCategoryNodes(buildNodes(categoriesData?.data ?? []))
  }, [categoriesData])

  const toggleFilterPane = () => {
    setShowFiltersPane(!showFilterPane)
  }

  return (
    <AntdLayout className="showcase">
      <AntdLayout.Header className="showcase-header">
        {isExternal === 'true' ? (
          <Typography.Title level={1}>Catalog</Typography.Title>
        ) : (
          <img src={'/meragi-logo.svg'} alt="Meragi" />
        )}
        <Popover content={<SettingsPopover />} trigger="click">
          <Button
            icon={<SettingOutlined />}
            type="text"
            shape="circle"
            style={{ backgroundColor: 'white', alignContent: 'center' }}
          />
        </Popover>
      </AntdLayout.Header>

      <AntdLayout.Content className="showcase-content">
        <SplitPane
          split="vertical"
          collapse={{ buttonTransition: 'none', collapsedSize: 10 }}
          initialSizes={[20, 80]}>
          <div className="scroll sider">
            <ProductFilter
              categoryNodes={categoryNodes}
              categoryLoading={isFilterCategoryLoading}
              venues={venueData?.data}
              venuesLoading={isVenueLoading}
              executedVenues={executedVenueData?.data}
              executedVenuesLoading={isExecutedVenueLoading}
              productData={queryResult.data?.data}
              filterData={filterData?.data}
              filterLoading={isFilterLoading}
              setFilters={setFilters}
              setSorter={setSorter}
              setCurrent={setCurrent}
              onFinish={onFinish}
              onUndo={onFinish}
              priceRange={priceData?.data}
            />
          </div>

          <div
            className="scroll-content-card-list scroll"
            id="infinite-scroll-content-card-list">
            <InfiniteScroll
              hasMore={current * pageSize < (queryResult.data?.total ?? 0)}
              next={() => setCurrent(current + 1)}
              dataLength={productList.length}
              scrollThreshold={0.7}
              scrollableTarget="infinite-scroll-content-card-list"
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
              }}
              loader={
                <div className="row h-center">
                  <Spin />
                </div>
              }
              endMessage={
                queryResult.data?.total ? (
                  <Divider plain>You have reached the end 🤐</Divider>
                ) : queryResult.isFetched ? (
                  <Result
                    status="404"
                    title="Oops!"
                    subTitle="We could not find any products with these combinations"
                  />
                ) : (
                  <div className="row h-center">
                    <Spin />
                  </div>
                )
              }>
              <Masonry
                breakpointCols={{
                  default: 4,
                  1100: 3,
                  700: 2,
                  500: 1,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {productList?.map((product) => (
                  <ProductCard key={product.id} item={product} />
                ))}
              </Masonry>
            </InfiniteScroll>
          </div>
        </SplitPane>
      </AntdLayout.Content>
    </AntdLayout>
  )
}

export const AuthenticatedCardList = () => {
  return (
    <Authenticated>
      <CardList />
    </Authenticated>
  )
}

const SettingsPopover = () => {
  const isPriceHidden = localStorage.getItem(HIDE_PRICE_KEY) === 'true'
  return (
    <>
      <Space>
        Hide Price
        <Switch
          defaultChecked={isPriceHidden}
          onChange={() => {
            localStorage.setItem(HIDE_PRICE_KEY, String(!isPriceHidden))
            window.location.reload()
          }}
        />
      </Space>
    </>
  )
}
