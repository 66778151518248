export const LeadRequirementCateringOption = [
  'Bengali Buffet Non Veg - Budget',
  'Bengali Buffet Non Veg - Medium',
  'Bengali Buffet Non Veg - Premium',
  'Breakfast North South Mix - Budget',
  'Breakfast North South Mix - Medium',
  'Breakfast North South Mix - Premium',
  'Breakfast South Indian - Budget',
  'Breakfast South Indian - Medium',
  'Breakfast South Indian - Premium',
  'High Tea - Budget',
  'High Tea - Medium',
  'High Tea - Premium',
  'Kannada Buffet Non Veg - Budget',
  'Kannada Buffet Non Veg - Medium',
  'Kannada Buffet Non Veg - Premium',
  'Kannada Buffet Veg - Budget',
  'Kannada Buffet Veg - Medium',
  'Kannada Buffet Veg - Premium',
  'Kannada Sit Down Veg - Budget',
  'Kannada Sit Down Veg - Medium',
  'Kannada Sit Down Veg - Premium',
  'Malayali Buffet Non Veg - Budget',
  'Malayali Buffet Non Veg - Medium',
  'Malayali Buffet Non Veg - Premium',
  'Malayali Sit Down Veg - Budget',
  'Malayali Sit Down Veg - Medium',
  'Malayali Sit Down Veg - Premium',
  'Marwari Buffet - Budget',
  'Marwari Buffet - Medium',
  'Marwari Buffet - Premium',
  'North Indian Buffet Non Veg - Budget',
  'North Indian Buffet Non Veg - Medium',
  'North Indian Buffet Non Veg - Premium',
  'North Indian Buffet Veg - Budget',
  'North Indian Buffet Veg - Medium',
  'North Indian Buffet Veg - Premium',
  'Tamil Brahmin Buffet Veg - Budget',
  'Tamil Brahmin Buffet Veg - Medium',
  'Tamil Brahmin Buffet Veg - Premium',
  'Tamil Brahmin Sit Down Veg - Budget',
  'Tamil Brahmin Sit Down Veg - Medium',
  'Tamil Brahmin Sit Down Veg - Premium',
  'Telugu Buffet Non Veg - Budget',
  'Telugu Buffet Non Veg - Medium',
  'Telugu Buffet Non Veg - Premium',
  'Telugu Buffet Veg - Budget',
  'Telugu Buffet Veg - Medium',
  'Telugu Buffet Veg - Premium',
  'Telugu Sit Down Veg - Budget',
  'Telugu Sit Down Veg - Medium',
  'Telugu Sit Down Veg - Premium',

  'Muslim Halal Dinner Budget',
  'Muslim Halal Dinner Medium',
  'Muslim Halal Dinner Premium',
]
