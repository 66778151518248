import { Edit } from "@refinedev/antd";
import { Button } from "antd";
import { useCreate } from "@refinedev/core";
import { CheckAccess } from 'components/checkAccess'
import { useForm } from 'components/hooks/useForm'
import { IStaff } from 'interfaces/staff'
import { STAFF_URL } from 'urls'
import { StaffForm } from './Form'

export const StaffEdit: React.FC = () => {
  const useFormProps = useForm<IStaff>({ redirect: 'edit' })

  const { mutate } = useCreate()

  const handleLogout = () => {
    mutate({
      resource: `${STAFF_URL}/${useFormProps.id}/logout_user`,
      values: {},
    })
  }

  return (
    <CheckAccess resource="write_staff">
      <Edit
        saveButtonProps={useFormProps.saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <Button type="primary" danger onClick={handleLogout}>
              Logout User
            </Button>
          </>
        )}>
        <StaffForm useFormProps={useFormProps} />
      </Edit>
    </CheckAccess>
  )
}
