import { BaseKey } from '@refinedev/core'
import { Button, Form, Modal } from 'antd'
import groupBy from 'lodash/groupBy'
import { useEffect, useState } from 'react'
import { usePrevious } from 'utils/common'

export const ComboWrapper = ({
  listName,
  FormComponent,
  handleOverallTotal,
  handleOverallResponse,
  breakdownId,
  initialValue = [{}],
}: {
  listName: string
  FormComponent: any
  handleOverallTotal: any
  handleOverallResponse?: any
  breakdownId: BaseKey
  initialValue?: any
}) => {
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [response, setResponse] = useState({})

  const previousTotal = usePrevious(total)

  const handleSplit = (res: any) => {
    if (res?.values?.length) {
      const grps = groupBy(res.values, (f) => !!f.flower)
      res.flowers = grps.true
      res.foliages = grps.false
    }
    return res
  }

  useEffect(() => {
    handleOverallTotal((previousOverallTotal: any) => {
      return isNaN(previousOverallTotal)
        ? 0
        : previousOverallTotal - (previousTotal ?? 0) + total ?? 0
    })
  }, [total])

  useEffect(() => {
    handleOverallResponse((overallResponse: any) => ({
      ...overallResponse,
      [breakdownId]: {
        ...overallResponse[breakdownId],
        [listName]: response,
      },
    }))
  }, [response])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        setTotal(
          (values[listName] ?? []).reduce((prev: any, cur: any) => {
            return prev + (isNaN(cur.total) ? 0 : cur?.total ?? 0)
          }, 0),
        )
        setResponse(
          values[listName].filter(
            (obj: any) => !!obj.item || !!obj.sub_product,
          ),
        )
      }}>
      <Form.List
        name={listName}
        initialValue={initialValue.map((value: any) => ({
          ...handleSplit(value),
          total: 0,
        }))}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <div key={field.key}>
                <FormComponent
                  key={field.key}
                  handleTotal={(total: number) => console.log(total)}
                  form={form}
                  field={field}
                  listName={listName}
                />
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to remove this item?',
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk: () => {
                          remove(field.name)
                          form.submit()
                        },
                      })
                    }}
                    block
                    danger>
                    Remove
                  </Button>
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                Add More
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}
