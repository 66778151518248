import { Authenticated } from "@refinedev/core";
import { PackageSectionCreate } from './Create'
import { PackageSectionEdit } from './Edit'

export { PackageSectionCreate } from './Create'
export { PackageSectionEdit } from './Edit'
export { PackageSectionForm } from './Form'
export { PackageSectionList } from './List'

export const AuthPackageSectionCreate = () => (
  <Authenticated>
    <PackageSectionCreate />
  </Authenticated>
)

export const AuthPackageSectionEdit = () => (
  <Authenticated>
    <PackageSectionEdit />
  </Authenticated>
)
