import { Comment } from '@ant-design/compatible'
import { CheckCircleOutlined, CommentOutlined } from '@ant-design/icons'
import { DateField, useModal } from '@refinedev/antd'
import { BaseKey, useCreate, useList } from '@refinedev/core'
import { List as AList, Badge, Button, Modal, Space, Spin } from 'antd'
import { DateFieldFromNow } from 'components/field'
import parse from 'html-react-parser'
import { IItem, IValidationComment } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { VALIDATION_COMMENT_URL } from 'urls'
import { getUserName } from 'utils/common'

export const CommentsModal = ({
  lineItem,
  refetchLineItem,
}: {
  lineItem: IItem
  refetchLineItem: CallableFunction
}) => {
  const [comment, setComment] = useState('')

  const { modalProps, show } = useModal()

  const {
    data: comments,
    isLoading: commentsLoading,
    refetch: refetchComments,
    isRefetching: refetchingComments,
  } = useList<IValidationComment>({
    resource: VALIDATION_COMMENT_URL,
    pagination: { mode: 'off' },
    filters: [
      { field: 'line_item', operator: 'eq', value: lineItem.id },
      { field: 'expand', operator: 'eq', value: 'comment_by,resolved_by' },
    ],
    queryOptions: { enabled: modalProps.open },
  })

  const { mutate: post } = useCreate()
  const { mutate: resolve, isLoading: resolving } = useCreate()

  const handleCreate = () => {
    post(
      {
        resource: VALIDATION_COMMENT_URL,
        values: { line_item: lineItem.id, comment: comment },
      },
      {
        onSuccess: () => {
          setComment('')
          refetchComments()
        },
      },
    )
  }

  const handleResolve = (comment_id: BaseKey) => {
    resolve(
      {
        resource: `${VALIDATION_COMMENT_URL}/${comment_id}/mark_resolved`,
        values: {},
      },
      {
        onSuccess: () => {
          refetchComments()
        },
      },
    )
  }

  return (
    <>
      <Badge count={lineItem.comments_count?.count} size="small">
        <Button
          type="text"
          icon={<CommentOutlined />}
          size="small"
          onClick={() => show()}>
          Comment
        </Button>
      </Badge>
      <Modal
        {...modalProps}
        footer={null}
        onCancel={(e) => {
          refetchLineItem()
          modalProps.onCancel?.(e)
        }}
        title={
          <Spin spinning={refetchingComments}>
            Comment ({comments?.data?.length})
          </Spin>
        }>
        <AList
          dataSource={comments?.data}
          loading={commentsLoading}
          renderItem={(item) => (
            <li>
              <Comment
                author={
                  !!item.comment_by
                    ? getUserName(item.comment_by as IStaff, false)
                    : ''
                }
                content={parse(item.comment || '')}
                datetime={<DateFieldFromNow value={item.created_at} />}
                actions={[
                  item.resolved_by ? (
                    <span style={{ color: 'green' }}>
                      resolved by{' '}
                      {getUserName(item.resolved_by as IStaff, false)} at{' '}
                      {<DateField value={item.resolved_at} format="LLL" />}
                    </span>
                  ) : (
                    <Button
                      key="resolved"
                      type="text"
                      size="small"
                      loading={resolving}
                      icon={<CheckCircleOutlined />}
                      onClick={() => handleResolve(item.id)}>
                      mark resolved
                    </Button>
                  ),
                ]}
              />
            </li>
          )}
        />
        <Space direction="vertical" style={{ width: '100%' }}>
          <ReactQuill
            theme="snow"
            value={comment}
            onChange={(content) => setComment(content)}
          />
          <Button disabled={!comment.length} onClick={() => handleCreate()}>
            Add comment
          </Button>
        </Space>
      </Modal>
    </>
  )
}
