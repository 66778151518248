import { Typography } from "antd";
import { PriceField } from 'components/field'

export const PriceComponent = ({
  value,
  onChange,
}: {
  value?: any
  onChange?: any
}) => {
  return (
    <Typography.Text strong>
      Price: <PriceField value={value} />
    </Typography.Text>
  )
}
