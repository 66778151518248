import { UseFormReturnType } from '@refinedev/antd'
import { BaseKey } from '@refinedev/core'
import React from 'react'
import { useForm } from '@refinedev/antd'
import { IContributor } from 'interfaces/customer'
import { CONTRIBUTOR_URL } from 'urls'
import { useHotkeys } from 'react-hotkeys-hook'
import { Card, notification } from 'antd'
import { ContributorForm } from './Form'

export const ContributorCreate: React.FC<{ projectId: BaseKey }> = ({
  projectId,
}) => {
  let contributorCreateForm: UseFormReturnType<IContributor> = useForm({
    action: 'create',
    resource: CONTRIBUTOR_URL,
    redirect: false,
  })

  useHotkeys('ctrl+s, cmd+s', (e) => {
    e.preventDefault()
    notification.info({
      message: 'Saving',
      duration: 1,
      description: 'Save in progress',
    })
    contributorCreateForm.formProps?.form?.submit()
  })

  return (
    <Card title="Add new Contributor" size="small">
      <ContributorForm
        useFormProps={contributorCreateForm}
        projectId={projectId}
      />
    </Card>
  )
}
