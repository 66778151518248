import { checkNumber } from 'utils/common'
import { Component, Info } from './pricingToolTree'

export interface ValidatorFunctionInterface {
  (item: Info): boolean
}

export type ValidatorObject = {
  checkType: string
  errorMessage: string
  func: ValidatorFunctionInterface
}

type ValidatorObjects = {
  FABRIC?: ValidatorObject[]
  FLORAL?: ValidatorObject[]
  STRUCTURE?: ValidatorObject[]
  PRODUCT?: ValidatorObject[]
}

const QuantityCheckValidator: ValidatorObject = {
  checkType: 'QuantityCountCheck',
  errorMessage: 'Quantity must be greater than 0',
  func: (item: Info) => {
    if (item.quantity <= 0) {
      return false
    }
    return true
  },
}

export const PricingToolValidators: ValidatorObjects = {
  FABRIC: [
    {
      checkType: 'MissingColors',
      errorMessage: 'Colors are missing',
      func: (item: Info) => {
        if (item.values?.length === 0) return false
        return true
      },
    },
    {
      checkType: 'IsFabricSelected',
      errorMessage: 'Fabric is missing for a Item',
      func: (item: Info) => {
        if (!item.fabric) {
          return false
        }
        return true
      },
    },
    QuantityCheckValidator,
  ],
  FLORAL: [
    {
      checkType: 'TotalSplitCheck',
      errorMessage: 'Split ratio is not perfect',
      func: (item: Info) => {
        let total_split =
          item?.values?.reduce(
            (prev: any, cur: any) => checkNumber(prev) + checkNumber(cur.split),
            0,
          ) ?? 0
        if (total_split !== 100) {
          return false
        }
        return true
      },
    },
    QuantityCheckValidator,
  ],
  STRUCTURE: [QuantityCheckValidator],
  PRODUCT: [QuantityCheckValidator],
}
