import * as Icons from '@ant-design/icons'
import { ImageField } from '@refinedev/antd'
import {
  Authenticated,
  BaseKey,
  useApiUrl,
  useCreate,
  useCustomMutation,
  useDelete,
  useList,
  useOne,
  useRouterContext,
  useUpdate,
} from '@refinedev/core'
import {
  Layout as AntdLayout,
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  MenuProps,
  Popover,
  Select,
  Space,
  Typography,
} from 'antd'
import { PriceField } from 'components/field'
import { IInterfaceDetailed } from 'interfaces/pricing'
import { IProduct } from 'interfaces/product'
import queryString from 'query-string'
import { createContext, useEffect, useState } from 'react'
import { SplitPane } from 'react-collapse-pane'
import {
  ALTERNATE_ITME_URL,
  INTERFACE_URL,
  PRODUCT_URL,
  SHORTLIST_LINE_ITEM_URL,
} from 'urls'
import { BreakdownList } from './Breakdown'
import { Item } from './Item'
import { ItemInfoCanvas } from './ItemInfoCanvas'
import { ItemTypeList } from './ItemTypeList'
import { ItemTypeNav } from './ItemTypeNav'
import { Breakdown, Component, Root } from './pricingToolTree'
import create_pricing_tool_tree from './treeGenerator'

type PricingContextContent = {
  item: Component | undefined
  setItem: any
  setPrice: any
  itemPrice: number
  copiedItem: null
  setCopiedItem: any
}
export const PricingContext = createContext<PricingContextContent>({
  item: undefined,
  setItem: null,
  setPrice: null,
  itemPrice: 0,
  copiedItem: null,
  setCopiedItem: null,
})

export const PricingToolPage: React.FC = () => {
  const [pricingRootNode, setPricingRootNode] = useState<Root>()
  const [selectedBreakdown, setSelectedBreakdown] = useState<Breakdown>()
  const [selectedItem, setSelectedItem] = useState<Component>()
  const [itemPrice, setItemPrice] = useState<number>(0)
  const [productPrice, setProductPrice] = useState<number>(0)
  const [copiedItem, setCopiedItem] = useState(null)
  const [errors, setErrors] = useState<any>([])
  const [showFetchPrice, setShowFetchPrice] = useState<boolean>(false)
  const [selectedInterface, setSelectedInterface] =
    useState<IInterfaceDetailed>()

  const [isUpdating, setUpdating] = useState<boolean>(false)
  const { mutate: patch, isLoading } = useUpdate()
  const { mutate: create } = useCreate()
  const { mutate: destroy } = useDelete()
  const { mutate: custom_post } = useCustomMutation()
  const setPrice = () => {
    if (pricingRootNode) setProductPrice(pricingRootNode?.getPrice())
    if (selectedItem) setItemPrice(selectedItem.getPrice())
  }
  const apiUrl = useApiUrl()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { productId, lineItemId, alternateItemId } = queryString.parse(
    location.search,
  )

  const {
    data: productBreakdownData,
    isLoading: productBreakdownLoading,
    refetch,
  } = useOne<any>({
    resource: PRODUCT_URL,
    id: `${productId}/get_breakdown_new?line_item=${
      lineItemId ? lineItemId : ''
    }&alternate_item=${alternateItemId ? alternateItemId : ''}`,

    queryOptions: {
      enabled: !!productId,
    },
  })
  const { data: productData, isLoading: productIsLoading } = useOne<IProduct>({
    resource: PRODUCT_URL,
    id: productId as BaseKey,
    queryOptions: {
      enabled: !!productId,
    },
  })

  const { data: interfaceData, isLoading: interfaceLoading } =
    useList<IInterfaceDetailed>({
      resource: `${INTERFACE_URL}/detailed`,
      queryOptions: {
        onSuccess: (data) => {
          data.total && setSelectedInterface(data.data[0])
        },
      },
    })

  useEffect(() => {
    window.document.title = 'Meragi | Pricing'
    // @ts-ignore
    window.document.querySelector('body').style.overflow = 'hidden'
  }, [])

  useEffect(() => {
    setPrice()
    validateChecks()
    if (selectedItem) {
      selectedItem.mutate = patch
    }
  }, [pricingRootNode, selectedItem, Breakdown])

  useEffect(() => {
    if (
      productBreakdownData?.data.count === 0 &&
      productBreakdownData?.data.product_has_breakdown
    ) {
      setShowFetchPrice(true)
    }
    if (productId) {
      const rootNode = create_pricing_tool_tree(
        Number(productId),
        productBreakdownData?.data?.data,
        destroy,
        create,
        Number(lineItemId),
        Number(alternateItemId),
      )
      setPricingRootNode(rootNode)
      let breakdowns = rootNode.getChildren()
      if (breakdowns.length > 0) setSelectedBreakdown(breakdowns[0])
    }
  }, [productBreakdownData])

  const generate_breakdown = () => {
    if (lineItemId) {
      custom_post(
        {
          url: `${apiUrl}/${SHORTLIST_LINE_ITEM_URL}/${lineItemId}/generate_breakdown_values/`,
          values: {},
          method: 'post',
        },
        {
          onSuccess: () => {
            refetch()
            setShowFetchPrice(false)
          },
        },
      )
    }
    if (alternateItemId) {
      custom_post(
        {
          url: `${apiUrl}/${ALTERNATE_ITME_URL}/${alternateItemId}/generate_breakdown_values/`,
          values: {},
          method: 'post',
        },
        {
          onSuccess: () => {
            refetch()
            setShowFetchPrice(false)
          },
        },
      )
    }
  }

  const validateChecks = () => {
    const errors = pricingRootNode?.validateChecks()
    if (!!errors?.length) {
      setErrors(errors)
    } else {
      setErrors([])
    }
    return errors
  }
  const validate_and_save = () => {
    const errors = validateChecks()
    if (errors?.length === 0) {
      let id, resource
      if (lineItemId) {
        id = lineItemId
        resource = SHORTLIST_LINE_ITEM_URL
      } else if (alternateItemId) {
        id = alternateItemId
        resource = ALTERNATE_ITME_URL
      } else {
        id = productId
        resource = PRODUCT_URL
      }

      patch({
        resource: resource,
        id: `${id}/save_product_price`,
        values: {},
      })
    } else {
      // TODO: Show a error notification display
      console.log('oops, please resolve the checks')
    }
  }

  const generateErrorMenu = () => {
    const errorMenu = []
    let init = 1
    for (const breakdown_error of errors) {
      for (const itemType_error of breakdown_error.errors) {
        for (const comp_error of itemType_error.errors) {
          const comp = (
            <div>
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: breakdown_error.breakdown.name,
                  },
                  {
                    title: itemType_error.itemType.name,
                  },
                  {
                    title: comp_error.component.info.name,
                    onClick: () => {
                      setSelectedItem(comp_error.component)
                      setSelectedBreakdown(breakdown_error.breakdown)
                    },
                  },
                ]}
              />
              <ul></ul>
              {comp_error.errors.map((error: string) => {
                return (
                  <li key={error} style={{ color: '#cf1322' }}>
                    {error}
                  </li>
                )
              })}
            </div>
          )
          errorMenu.push({
            key: init,
            label: comp,
            component: comp_error.component,
            breakdown: breakdown_error.breakdown,
          })
          init += 1
        }
      }
    }
    return errorMenu
  }

  const errorItems: MenuProps['items'] = generateErrorMenu().map(
    (item: any) => {
      return {
        key: item.key,
        label: item.label,
        onClick: () => {
          setSelectedItem(item.component)
          setSelectedBreakdown(item.breakdown)
        },
      }
    },
  )

  return (
    <div>
      <PricingContext.Provider
        value={{
          item: selectedItem,
          setItem: setSelectedItem,
          setPrice: setPrice,
          itemPrice: itemPrice,
          copiedItem: copiedItem,
          setCopiedItem: setCopiedItem,
        }}>
        <Authenticated>
          <AntdLayout>
            <AntdLayout.Header className="pricing-v2-header">
              <img src={'/meragi-logo.svg'} alt="Meragi" />
              <Space align="baseline">
                {(lineItemId || alternateItemId) &&
                  productBreakdownData?.data.product_has_breakdown && (
                    <Popover placement="leftTop" content="Product is priced">
                      <Icons.StarFilled style={{ color: 'purple' }} />
                    </Popover>
                  )}
                <Typography.Title level={5}>Interface</Typography.Title>
                <Select
                  options={interfaceData?.data.map((int) => ({
                    label: int.name,
                    value: int.id,
                  }))}
                  value={selectedInterface?.id}
                  disabled={!!productId}
                  onChange={(value: BaseKey) =>
                    setSelectedInterface(
                      interfaceData?.data.find((int) => int.id === value),
                    )
                  }
                />
                {!!errors.length && (
                  <Dropdown menu={{ items: errorItems }} trigger={['click']}>
                    <Button danger icon={<Icons.DownOutlined />}>
                      Errors
                    </Button>
                  </Dropdown>
                )}
                {showFetchPrice && (
                  <Button
                    // type="primary"
                    onClick={() => {
                      generate_breakdown()
                    }}>
                    Fetch Product Price
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    validate_and_save()
                  }}>
                  Validate & Save
                </Button>
              </Space>
            </AntdLayout.Header>
            <AntdLayout.Content className="pricing-v2-content">
              <SplitPane split="vertical" initialSizes={[15, 55, 30]}>
                <div className="left-pane">
                  {pricingRootNode && selectedBreakdown && (
                    <BreakdownList
                      breakdowns={pricingRootNode?.getChildren()}
                      selectedBreakdown={selectedBreakdown}
                      setSelectedBreakdown={setSelectedBreakdown}
                    />
                  )}
                  <Divider>Items</Divider>
                  {selectedBreakdown && (
                    <ItemTypeList
                      breakdown={selectedBreakdown}
                      setBreakdown={setSelectedBreakdown}
                    />
                  )}
                </div>
                <div className="canvas">
                  {selectedBreakdown && (
                    <ItemTypeNav
                      breakdown={selectedBreakdown}
                      setItem={setSelectedItem}
                    />
                  )}
                  <ItemInfoCanvas />
                  <div className="canvas-main">
                    <Item />
                  </div>
                </div>
                <div className="right-pane">
                  <div className="product-info">
                    {!!productData?.data.images.length && (
                      <ImageField
                        height={300}
                        width={300}
                        value={
                          !!productData?.data.images.length
                            ? productData?.data.images[0].image
                            : ''
                        }
                      />
                    )}
                    <div className="total-price-container">
                      <span className="total-price">
                        Total:{' '}
                        <PriceField
                          className="total-price"
                          value={productPrice}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </SplitPane>
            </AntdLayout.Content>
          </AntdLayout>
        </Authenticated>
      </PricingContext.Provider>
    </div>
  )
}
