import {
  useApiUrl,
  useCan,
  useCustomMutation,
  useRouterContext,
} from '@refinedev/core'
import { DeleteButton, List, useTable, useSelect } from '@refinedev/antd'
import { Form, Input, Radio, Select, Switch, Table } from 'antd'
import { IMaterial, IMaterialCategoryOption } from 'interfaces/material'
import { useState } from 'react'
import { MATERIAL_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import { title } from 'process'

export const MaterialsList: React.FC = () => {
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const tProps = useTable<
    IMaterial,
    any,
    {
      is_deleted: boolean
      search: string
      category__in: string[]
      has_stock_items: string
    },
    any
  >({
    syncWithLocation: true,
    filters: {
      initial: [{ field: 'is_deleted', operator: 'eq', value: isDeleted }],
    },
    onSearch: ({ search, category__in, has_stock_items }) => [
      { field: 'is_deleted', operator: 'eq', value: isDeleted },
      { field: 'search', operator: 'eq', value: search },
      {
        field: 'category__in',
        operator: 'eq',
        value: category__in?.join(',') as string,
      },
      {
        field: 'has_stock_items',
        operator: 'eq',
        value: has_stock_items,
      },
    ],
    pagination: {
      pageSize: 50,
    },
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const apiUrl = useApiUrl()
  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const { mutate: destroy } = useCustomMutation<any>()
  const deleteMany = async () => {
    const deletePromises = selectedRowKeys.map((materialId) => {
      return destroy({
        url: `${apiUrl}/${MATERIAL_URL}/${materialId}/delete/`,
        values: {},
        method: 'post',
      })
    })

    await Promise.all(deletePromises)
    setSelectedRowKeys([])
    tProps.tableQueryResult.refetch()
  }

  const { Link } = useRouterContext()

  const { selectProps: materialCategorySelectProps } =
    useSelect<IMaterialCategoryOption>({
      resource: `${MATERIAL_URL}/material_category_options`,
      optionLabel: 'name',
      optionValue: 'name',
    })

  const { data: deleteMaterialPerm } = useCan({
    resource: 'delete_material',
    action: '',
  })

  const StockItemoptions = [
    { label: 'All', value: 'undefined' },
    { label: 'In Stock', value: 'true' },
    { label: 'No Stock', value: 'false', title: 'No Stock' },
  ]
  return (
    <List
      title="Materials"
      headerButtons={({ defaultButtons }) => (
        <>
          {!!selectedRowKeys.length && (
            <DeleteButton
              onClick={deleteMany}
              disabled={!deleteMaterialPerm?.can}
            />
          )}
          <Switch
            checkedChildren="Deleted"
            unCheckedChildren="Not Deleted"
            defaultChecked={isDeleted}
            onChange={() => {
              setIsDeleted(!isDeleted)
              tProps.searchFormProps?.form?.submit()
            }}
          />
          <Form
            layout="inline"
            size="small"
            {...tProps.searchFormProps}
            initialValues={getSearchFormInitialValue(tProps.filters, {
              category__in: (value: string) => value.split(','),
              has_stock_items: (value: boolean | undefined) => value,
            })}>
            <Form.Item name="has_stock_items" initialValue={'undefined'}>
              <Radio.Group
                onChange={tProps.searchFormProps?.form?.submit}
                options={StockItemoptions}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item name="search">
              <Input.Search
                placeholder="Search Material Name..."
                enterButton
                onSearch={tProps.searchFormProps?.form?.submit}
              />
            </Form.Item>
            <Form.Item name="category__in" label="Material Category">
              <Select
                {...materialCategorySelectProps}
                allowClear
                showSearch={false}
                mode="multiple"
                style={{
                  width: '200px',
                }}
                onChange={tProps.searchFormProps?.form?.submit}
              />
            </Form.Item>
          </Form>
          {defaultButtons}
        </>
      )}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}>
        <Table.Column
          dataIndex={'name'}
          title="Name"
          render={(value, record: IMaterial) => (
            <Link to={`edit/${record.id}`}>{value}</Link>
          )}
        />
      </Table>
    </List>
  )
}
