import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber } from 'antd'

export const VenueRentalForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={(values) =>
        props.formProps.onFinish!({
          ...values,
          venue: props.venueId,
        })
      }>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="from_hr" label="From (hr)">
        <InputNumber />
      </Form.Item>
      <Form.Item name="to_hr" label="To (hr)">
        <InputNumber />
      </Form.Item>
      <Form.Item name="price" label="Price">
        <InputNumber />
      </Form.Item>
    </Form>
  )
}
