import { DateField, List, useTable } from '@refinedev/antd'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
} from 'antd'
import { CONTENT_TYPE } from 'consts'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { ILogDetailed } from 'interfaces/base'
import { IStaff } from 'interfaces/staff'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import ReactJson from 'react-json-view'
import { getSearchFormInitialValue } from 'utils/filter'

export const LogsList: React.FC = () => {
  const { tableProps, searchFormProps, filters } = useTable<
    ILogDetailed,
    any,
    { created_at: any[]; object_id: string; content_type__model: string }
  >({
    onSearch: ({ created_at, object_id, content_type__model }) => [
      {
        field: 'content_type__model',
        operator: 'eq',
        value: content_type__model,
      },
      {
        field: 'object_id',
        operator: 'eq',
        value: object_id,
      },
      {
        field: 'created_at',
        operator: 'between',
        value: created_at?.map((value) => value.format('YYYY-MM-DD')).join(','),
      },
    ],

    syncWithLocation: true,

    pagination: {
      pageSize: 20,
    },
  })
  return (
    <Row gutter={[4, 4]}>
      <Col lg={6} xs={24}>
        <Card>
          <Form
            {...searchFormProps}
            key="search"
            layout="vertical"
            initialValues={getSearchFormInitialValue(filters, {
              created_at: (value: any) =>
                value?.map((date: any) => dayjs(date)),
            })}>
            <Form.Item name="created_at" label="Date Time">
              <DatePicker.RangePicker
                ranges={{
                  Today: [dayjs(), dayjs()],
                  'This Month': [
                    dayjs().startOf('month'),
                    dayjs().endOf('month'),
                  ],
                }}
              />
            </Form.Item>
            <Form.Item name="object_id" label="Object Id">
              <Input allowClear />
            </Form.Item>
            <Form.Item name="content_type__model" label="Content Type">
              <Select allowClear>
                {Object.values(CONTENT_TYPE).map((content) => (
                  <Select.Option value={content.model} key={content.model}>
                    {content.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Go
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col lg={18} xs={24}>
        <List>
          <Table
            {...tableProps}
            rowKey="id"
            size="small"
            expandable={{
              expandedRowRender: (record) => (
                <ReactJson src={record.content} collapsed />
              ),
            }}>
            <Table.Column
              dataIndex={['user', 'username']}
              title="Phone"
              ellipsis
            />
            <Table.Column<ILogDetailed>
              dataIndex="user"
              title="Name"
              ellipsis
              render={(value: IStaff) =>
                `${value?.first_name} ${value?.last_name}`
              }
            />
            <Table.Column
              dataIndex="action"
              title="Action"
              ellipsis
              render={(value) => capitalize(value)}
            />
            <Table.Column dataIndex="object_id" title="Object ID" ellipsis />
            <Table.Column
              dataIndex={['content_type', 'name']}
              title="Content Type"
              ellipsis
              render={(value) => startCase(value)}
            />
            <Table.Column<ILogDetailed>
              dataIndex="created_at"
              title="Date Time"
              ellipsis
              render={(value) => <DateField value={value} format="LLL" />}
            />
          </Table>
        </List>
      </Col>
    </Row>
  )
}
