import { DeleteButton, EditButton, ImageField, List, SaveButton, useEditableTable } from "@refinedev/antd";
import { Button, Form, Input, Space, Table } from "antd";
import { useRouterContext } from "@refinedev/core";
import { IFoliageType } from 'interfaces/pricing'
import { FOLIAGE_TYPE_URL } from 'urls'

export const FoliageTypeList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable<IFoliageType>({ syncWithLocation: true })

  const { Link } = useRouterContext()

  return (
    <List>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column
            dataIndex={['image']}
            title=""
            render={(image) => (
              <ImageField value={image} width={75} height={75} />
            )}
          />
          <Table.Column key="id" dataIndex="id" title="ID" />
          <Table.Column<IFoliageType>
            key="title"
            dataIndex="name"
            title="Name"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                )
              }
              return (
                <Link
                  style={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    textOverflow: 'ellipsis',
                  }}
                  to={`/${FOLIAGE_TYPE_URL}/edit/${record.id}`}>
                  {value}
                </Link>
              )
            }}
          />
          <Table.Column<IFoliageType>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_text, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record.id)} size="small" />
                  <DeleteButton size="small" recordItemId={record.id} />
                </Space>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
