import { ConsoleSqlOutlined } from '@ant-design/icons'
import { BaseKey } from '@refinedev/core'
import { IPricingItem } from 'interfaces/pricing'
import { BREAKDOWNVALUES_URL, FLORALVALUE_URL } from 'urls'
import axios from 'axios'
import {
  PricingToolValidators,
  ValidatorFunctionInterface,
  ValidatorObject,
} from './ErrorValidator'
import { itemTypeValues } from './treeGenerator'
function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const axiosInstance = axios.create()
const API_URL = process.env.REACT_APP_API_URL
const floral_url = `${API_URL}/${FLORALVALUE_URL}/`
const breakdown_value_url = `${API_URL}/${BREAKDOWNVALUES_URL}/`

const token = localStorage.getItem('token')

type NodeType =
  | 'Parent'
  | 'Breakdown'
  | 'ItemType'
  | 'Item'
  | 'Combination'
  | 'Component'

export interface IFloralValue {
  id: number
  flower?: number
  foliage?: number
  foliage_ratio?: number
  artificial_ratio: number
  split: number
  image?: string
  combination: number
  price: number
}

export interface IFabricColorValue {
  id: BaseKey
  fabric: number
  color: number
}

export class Info {
  name: string
  id: number
  breakdown_id?: number
  item?: number
  itemDetails?: IPricingItem
  fabric?: BaseKey
  fabric_image?: string
  item_image_url?: string
  quantity: number
  sub_product?: number
  values?: (IFabricColorValue | IFloralValue)[]
  lineItem?: number
  alternateItem?: number
  price?: number
  create: any

  constructor(name: string, id: number, itemDetails?: IPricingItem) {
    this.name = name
    this.values = []
    this.quantity = 1
    this.itemDetails = itemDetails
    this.id = id
  }

  getPrice(): number {
    if (this.values && !!this.values?.length) {
      const isFloralArray = this.values.every((value) => {
        return 'price' in value
      })
      if (isFloralArray) {
        let total = 0
        this.values.forEach((value: any) => {
          total = total + value.price
        })
        return total
      }
    }
    return this.price ? this.price : 0
  }
  setCreate(create: any): void {
    this.create = create
  }

  createItems(breakdownvalue_id: number): void {
    // function will be used to create floral values and fabric values
    if (this.values && !!this.values?.length) {
      const isFloralArray = this.values.every((value) => {
        return 'price' in value
      })
      // if isFloralArray create FloralValue
      // else create Fabric Values

      if (isFloralArray) {
        this.values.forEach((item: any) => {
          console.log('creation initiated')
          axiosInstance
            .post(
              floral_url,
              {
                split: item.split,
                flower: item.flower,
                foliage: item.foliage,
                artificial_ratio: item.artificial_ratio,
                breakdown_value: breakdownvalue_id,
                combination: item.combination,
              },
              {
                headers: {
                  Authorization: `Token ${token}`,
                },
              },
            )
            .then((response) => {
              item.id = response.data.id as number
            })
        })
      } else {
        const fabric_to_add = this.values.map((fabricColorItem: any) => {
          return fabricColorItem.color
        })

        axiosInstance
          .post(
            `${API_URL}/${BREAKDOWNVALUES_URL}/${breakdownvalue_id}/create_update_delete/`,
            {
              colors: fabric_to_add,
              fabric: this.fabric,
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            },
          )
          .then((response) => {
            // pass
          })
      }
    }
  }

  copy(): Info {
    const copy_info_node = Object.assign({}, this)
    copy_info_node.breakdown_id = undefined
    copy_info_node.getPrice = this.getPrice
    copy_info_node.setCreate = this.setCreate
    copy_info_node.createItems = this.createItems
    copy_info_node.copy = this.copy
    copy_info_node.create = this.create
    const copy_values: (IFabricColorValue | IFloralValue)[] = []
    this.values?.forEach((item: any) => {
      let copy_value = Object.assign({}, item)
      // copy_value.id = 0
      copy_values.push(copy_value)
    })
    copy_info_node.values = copy_values
    return copy_info_node
  }
}

export interface IAllowed_Item_Type {
  structure: true
  floral: false
  fabric: false
  product: true
}

export abstract class TreeNode {
  name: string
  id: number
  type: NodeType
  parent: TreeNode | null
  children: Array<TreeNode | Component | ItemType | Breakdown>
  info?: Info | IAllowed_Item_Type
  isUpdating: boolean
  mutate: any
  create: any
  delete: any
  errors: string[]
  validators: ValidatorObject[]
  constructor(name: string, type: NodeType, id: number) {
    this.name = name
    this.type = type
    this.children = []
    this.parent = null
    this.id = id
    this.isUpdating = false
    this.validators = []
    this.errors = []
  }

  display(): void {
    console.log(this.name, this.type)
  }

  addChild(node: TreeNode): TreeNode {
    // override this when there is a need for validation
    node.parent = this
    this.children.push(node)
    return node
  }

  hasChild(node: TreeNode): boolean {
    // return this.children.includes(node)

    const found = this.children.find((obj: TreeNode) => {
      return obj === node
    })
    if (found) return true
    return false
  }

  hasChildren(): boolean {
    // check if it contains any children or not
    if (!!this.children.length) return true
    else return false
  }

  getFirstChild(): TreeNode {
    return this.children[0]
  }

  // need to create a deep copy logic
  // abstract copy(node: Node):Node;

  getParent(): TreeNode | null {
    return this.parent
  }

  setParent(node: TreeNode) {
    this.parent = node
  }

  getChildren(): Array<TreeNode> {
    return this.children
  }

  getInfo(): Info | undefined | IAllowed_Item_Type {
    return this.info
  }

  setInfo(info: Info) {
    this.info = info
  }

  getPrice(): number {
    return (this.info as Info).getPrice()
  }

  setMutate(mutate: any): void {
    this.mutate = mutate
  }

  setCreate(create: any): void {
    this.create = create
  }

  getRootNode(): TreeNode {
    let root: TreeNode = this
    let parent = this.getParent()
    while (parent) {
      root = parent
      parent = parent.getParent()
    }
    return root
  }

  update(): void {}

  createItem(): void {}

  destroy(): void {}

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
  }

  copy(): TreeNode {
    // update the copy logic
    const copy_node = Object.assign({}, this)
    return copy_node
  }

  paste(node: TreeNode): TreeNode | void {}

  removeChild(childNode: TreeNode): void {
    if (this.hasChild(childNode)) {
      this.children = this.children.filter((item) => item !== childNode)
    } else {
      throw new Error('Child node does not exist')
    }
  }

  validateChecks(): void {}
}

export class Root extends TreeNode {
  children: Array<Breakdown>
  constructor(name: string) {
    super(name, 'Parent', 1)
    this.children = []
  }

  getChildren(): Array<Breakdown> {
    return this.children
  }

  getPrice(): number {
    let total = 0
    this.children.forEach((child: Breakdown) => {
      total = total + child.getPrice()
    })
    return total
  }

  destroy(): void {
    throw new Error('Root node can not be deleted')
  }

  validateChecks() {
    let errors = []
    for (const breakdown of this.getChildren()) {
      let breakdown_error = breakdown.validateChecks()
      if (!!breakdown_error.length) {
        errors.push({ breakdown: breakdown, errors: breakdown_error })
      }
    }
    return errors
  }
}

export class Breakdown extends TreeNode {
  children: Array<ItemType>
  info?: IAllowed_Item_Type
  constructor(name: string, id: number) {
    super(name, 'Breakdown', id)
    this.children = []
  }

  addChild(node: TreeNode): TreeNode {
    if (node.type == 'ItemType') super.addChild(node)
    else
      throw new TypeError('only ItemType type node can be added as child node')

    return node
  }
  getChildren(): Array<ItemType> {
    return this.children
  }

  setParent(node: TreeNode): void {
    if (node.type == 'Parent') super.setParent(node)
    else throw new Error('Can only assign to Parent type Node')
  }

  getPrice(): number {
    let total = 0
    this.children.forEach((child: ItemType) => {
      total = total + child.getPrice()
    })
    return total
  }

  destroy(): void {
    throw new Error('Breakdown cannot be deleted')
  }

  validateChecks() {
    let errors = []
    for (const itemType of this.getChildren()) {
      let item_type_error = itemType.validateChecks()
      if (!!item_type_error.length) {
        errors.push({ itemType: itemType, errors: item_type_error })
      }
    }
    return errors
  }
}

export class ItemType extends TreeNode {
  children: Array<Component>
  constructor(name: string, id: number) {
    super(name, 'ItemType', id)
    this.children = []
  }

  addChild(node: TreeNode): TreeNode {
    if (node.type == 'Component') super.addChild(node)
    else
      throw new TypeError('only Component type node can be added as child node')

    return node
  }

  getChildren(): Array<Component> {
    return this.children
  }

  setParent(node: TreeNode): void {
    if (node.type == 'Breakdown') super.setParent(node)
    else throw new Error('Can only assign to Breakdown type Node')
  }

  getPrice(): number {
    let total = 0
    this.children.forEach((child: Component) => {
      total = total + child.getPrice()
    })
    return total
  }

  destroy(): void {
    throw new Error('ItemType cannot be deleted')
  }

  createItem(): void {
    this.children.forEach((child: Component) => {
      child.create = this.create
      child.createItem()
    })
  }

  paste(node: Component): TreeNode | void {
    if (node.parent && node.parent.name === this.name) {
      let new_node = node.copy()
      this.children.push(new_node)
      new_node.parent = this
      new_node.createItem()
      return new_node
    } else {
      // TODO:- notification for error
      console.log('lol, nahi ho sakta')
    }

    // node.create() // IMP call node.create to create data in backend.
    // next create the data in backend and update ids
  }

  validateChecks() {
    let errors = []
    for (const comp of this.getChildren()) {
      let comp_error = comp.validateChecks()
      if (!!comp_error.length) {
        errors.push({ component: comp, errors: comp_error })
      }
    }
    return errors
  }
}

export class Component extends TreeNode {
  info?: Info
  is_new?: boolean // indicated if a new comp is added.

  constructor(name: string, id: number) {
    super(name, 'Component', id)
    this.is_new = false
    if (this.parent && itemTypeValues.includes(this.parent.name)) {
      console.log('yeak')
      this.validators =
        PricingToolValidators[
          this.parent.name as 'FABRIC' | 'FLORAL' | 'STRUCTURE' | 'PRODUCT'
        ] ?? []
    }
  }

  setParent(node: ItemType): void {
    if (node.type === 'ItemType') super.setParent(node)
    else throw new Error('Can only assign to ItemType type Node')
  }

  getPrice(): number {
    return this.info ? this.info.getPrice() * this.info.quantity : 0
  }

  update(): void {
    let id = this.id
    let root = this.getRootNode()
    this.mutate({
      resource: BREAKDOWNVALUES_URL,
      id,
      values: {
        quantity: this.info?.quantity,
        item: this.info?.item,
        line_item: (root.info as Info).lineItem,
      },
      successNotification: false,
    })
  }

  destroy(): void {
    this.parent?.removeChild(this)
    this.delete({
      resource: BREAKDOWNVALUES_URL,
      id: this.id,
      successNotification: false,
    })
    this.parent = null
  }

  createItem(): void {
    // creation of Component depends of its type
    // if it is of Product or Structure type
    // or it is of Floral and Fabric Type

    // creation of Item should only be done if id is null or 0
    // TODO:- check if id is null
    // Need to make id=null or 0 while making a copy of object.

    let rootNode = this.getRootNode()

    if (
      this.parent &&
      this.parent.parent &&
      (this.parent.name === 'FLORAL' || this.parent.name === 'FABRIC')
    ) {
      axiosInstance
        .post(
          breakdown_value_url,
          {
            product: rootNode.id,
            line_item: (rootNode.info as Info).lineItem,
            alternate_item: (rootNode.info as Info).alternateItem,
            breakdown: this.parent.parent.id,
            item: this.info?.item,
            quantity: this.info?.quantity,
            sub_product: this.info?.sub_product,
            fabric: this.info?.fabric,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          this.id = response.data.id as number
          this.info?.createItems(this.id)
        })
    } else if (
      this.parent &&
      this.parent.parent &&
      (this.parent.name === 'STRUCTURE' || this.parent.name === 'PRODUCT')
    ) {
      axiosInstance
        .post(
          breakdown_value_url,
          {
            product: rootNode.id,
            line_item: (rootNode.info as Info).lineItem,
            alternate_item: (rootNode.info as Info).alternateItem,
            breakdown: this.parent.parent.id,
            item: this.info?.item,
            quantity: this.info?.quantity,
            sub_product: this.info?.sub_product,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          console.log(response.status, response.data)
          this.id = response.data.id as number
        })
    }
  }

  addValue(value: IFloralValue | IFabricColorValue) {
    if (this.info?.values) {
      this.info?.values.push(value)
    }
  }

  removeValue(valueId: BaseKey) {
    this.info!.values = this.info?.values?.filter((value: any) => {
      return value.id !== valueId
    })

    // if the last value got deleted, set the price to 0
    if (!this.info?.values?.length) this.info!.price = 0
  }

  copy() {
    let random_id = randomIntFromInterval(1, 100)
    const copy_node = new Component(this.name + random_id.toString(), 0)
    copy_node.setParent = this.setParent
    copy_node.getPrice = this.getPrice
    copy_node.update = this.update
    copy_node.destroy = this.destroy
    copy_node.createItem = this.createItem
    copy_node.copy = this.copy
    copy_node.parent = this.parent
    copy_node.create = this.create
    copy_node.delete = this.delete
    copy_node.mutate = this.mutate
    if (this.info) {
      copy_node.info = this.info.copy()
    }
    return copy_node
  }

  validateChecks() {
    const validators =
      PricingToolValidators[
        this.parent?.name as 'FABRIC' | 'FLORAL' | 'STRUCTURE' | 'PRODUCT'
      ] ?? []
    this.errors = []
    for (const validator of validators) {
      if (this.info && !validator.func(this.info)) {
        this.errors.push(validator.errorMessage)
      }
    }
    return this.errors
  }
}

export {}
