import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { IBreakdown } from 'interfaces/pricing'
import { useState } from 'react'
import { BREAKDOWN_URL } from 'urls'

export const BreakdownList: React.FC = () => {
  const tProps = useTable<IBreakdown>({
    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: BREAKDOWN_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: (event) => edit(BREAKDOWN_URL, record.id),
        })}>
        <Table.Column dataIndex={['name']} title="Name" />
      </Table>
    </List>
  );
}
