import { Card, Col, Row, Spin, Tag } from "antd";
import { useApiUrl, useCustom, useRouterContext } from "@refinedev/core";
import { PriceField } from 'components/field'
import {
  IProjectServicePackage,
  IShortlistPackage,
} from 'interfaces/shortlistPackage'
import React, { useEffect } from 'react'
import { CreatePackageModal } from './CreatePackageModal'

export const Services: React.FC<{
  project_id: number
  refreshPackages: Boolean
  setRefreshPackages: any
}> = ({ project_id, refreshPackages, setRefreshPackages }) => {
  const apiUrl = useApiUrl()

  const {
    data: service_packages,
    isLoading,
    refetch,
  } = useCustom<IProjectServicePackage[]>({
    url: `${apiUrl}/package_tool/shortlist_package/projects/${project_id}/get_packages_by_service`,
    method: 'get',
  })

  useEffect(() => {
    refetch()
    setRefreshPackages(false)
  }, [refreshPackages])

  return (
    <div>
      <CreatePackageModal projectId={project_id} refetch={refetch} />
      {isLoading && <Spin size="small" />}
      {service_packages?.data.map((servicePackage: IProjectServicePackage) => {
        return (
          <ServiceCard
            key={servicePackage.service.id}
            servicePackage={servicePackage}
          />
        )
      })}
    </div>
  )
}

const ServicePackages: React.FC<{ packages: IShortlistPackage[] }> = ({
  packages,
}) => {
  const { Link } = useRouterContext()

  const package_states = {
    draft: <Tag color="orange">{'Draft'}</Tag>,
    selected: <Tag color="pink">{'Selected'}</Tag>,
    finalized: <Tag color="blue">{'Finalized'}</Tag>,
    executed: <Tag color="green">{'Executed'}</Tag>,
    cancelled: <Tag color="red">{'Cancelled'}</Tag>,
  }

  const render_package_states = (spackage: IShortlistPackage) => {
    if (spackage.is_cancelled){
      return package_states['cancelled']
    } else if (spackage.is_executed) {
      return package_states['executed']
    } else if (spackage.is_finalized) {
      return package_states['finalized']
    } else if (spackage.is_selected) {
      return package_states['selected']
    } else if (
      !spackage.is_finalized &&
      !spackage.is_executed &&
      !spackage.is_selected
    ) {
      return package_states['draft']
    } else {
      return <></>
    }
  }

  return (
    <div>
      {packages?.map((spackage: IShortlistPackage) => {
        return (
          <div key={spackage.id} style={{ marginTop: '5px' }}>
            <Row gutter={24}>
              <Col span={18}>
                <Link
                  to={`/package_tool/shortlist_packages/edit/${spackage.id}`}>
                  {spackage.name}
                </Link>
              </Col>
              <Col span={3}>
                <PriceField value={spackage.price} />
              </Col>
              <Col span={3}>{render_package_states(spackage)}</Col>
            </Row>
          </div>
        )
      })}
    </div>
  )
}

const ServiceCard = ({
  servicePackage,
}: {
  servicePackage: IProjectServicePackage
}) => {
  return (
    <Card
      key={servicePackage.service.name}
      title={servicePackage.service.name}
      style={{ marginTop: '30px' }}
      headStyle={{ backgroundColor: '#E8E8E8' }}>
      <ServicePackages packages={servicePackage.packages} />
    </Card>
  )
}
