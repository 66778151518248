import React from 'react'
import { useModalForm } from '@refinedev/antd'
import { BaseKey, useInvalidate } from '@refinedev/core'
import { Form, Input, InputNumber, Modal, Select, Space, Switch } from 'antd'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { MaterialAvailability, MaterialSourceType } from 'interfaces/quote'
import {
  MATERIAL_SOURCE_URL,
  MATERIAL_URL,
  RFQ_URL,
  SECTION_URL,
  VENDOR_URL,
  WAREHOUSE_URL,
} from 'urls'
import { createOptionsFromEnum } from 'utils/common'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

export const checkNullOrUndefined = (value: any) => {
  if (value === undefined) return true
  if (value === null) return true
  return false
}

export const MaterialSourceModalForm: React.FC<{
  packageId: BaseKey
  isEditing?: boolean
  recordId?: BaseKey
  setIsEditing?: (state: boolean) => void
  setIsCreating?: (state: boolean) => void
  refetchSources: () => void
  materialAvailablity?: string
  pendingMaterial?: any
}> = ({
  isEditing = false,
  recordId,
  setIsCreating,
  setIsEditing,
  packageId,
  refetchSources,
  materialAvailablity,
  pendingMaterial,
}) => {
  const invalidate = useInvalidate()
  const { formProps, modalProps } = useModalForm({
    resource: MATERIAL_SOURCE_URL,
    action: isEditing ? 'edit' : 'create',
    id: isEditing ? recordId : undefined,
    defaultVisible: true,
    redirect: false,
    onMutationSuccess(data, variables, context, isAutoSave) {
      refetchSources()
      setIsCreating?.(false)
      setIsEditing?.(false)
      if (isEditing) {
        invalidate({
          resource: `${RFQ_URL}/${packageId}/get_pending_table`,
          invalidates: ['list'],
        })
      } else {
        invalidate({
          resource: `${MATERIAL_SOURCE_URL}/all_events/`,
          invalidates: ['list'],
        })
      }
    },
  })

  const handleSubmit = (values: any) => {
    if (values['warehouse'] === undefined) {
      values.warehouse = null
    }
    if (values['vendor'] === undefined) {
      values.vendor = null
    }
    let finalResponse = { ...values }
    if (!isEditing) {
      finalResponse = { ...values, package: packageId }
    }
    formProps.onFinish?.(finalResponse)
  }

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        setIsCreating?.(false)
        setIsEditing?.(false)
      }}>
      <Form
        {...formProps}
        onFinish={(values) => {
          handleSubmit(values)
        }}
        initialValues={{
          material: pendingMaterial?.material?.id,
          section: pendingMaterial?.package_detail?.section?.id,
          uom: pendingMaterial?.uom,
          date_time: dayjs(
            pendingMaterial?.package_detail?.decor_date_time,
          ).subtract(2.5, 'hour'),
          availability: materialAvailablity && materialAvailablity,
          type: MaterialSourceType.TO_EVENT,
          is_real: pendingMaterial?.is_real,
          ...formProps.initialValues,
        }}>
        <Form.Item
          name="material"
          label="Material"
          rules={[{ required: true }]}>
          <SelectWithDefault
            useSelectProps={{
              resource: MATERIAL_URL,
              optionLabel: 'name',
            }}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          name="is_real"
          label="Is Real"
          rules={[{ required: true }]}
          valuePropName="checked">
          <Switch disabled={true} />
        </Form.Item>
        <Form.Item name="section" label="Event">
          <SelectWithDefault
            useSelectProps={{
              resource: SECTION_URL,
              optionLabel: 'name',
            }}
            placeholder="Event"
            disabled={true}
            dropdownMatchSelectWidth={false}
          />
        </Form.Item>
        <Space
          direction="horizontal"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name="qty" label="Quantity" rules={[{ required: true }]}>
            <InputNumber precision={2} />
          </Form.Item>
          <Form.Item name="uom" label="Unit" rules={[{ required: true }]}>
            <Input disabled={true} />
          </Form.Item>
        </Space>
        <Space
          direction="horizontal"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Form.Item
            name="date_time"
            label="Date"
            rules={[
              {
                required: true,
              },
            ]}>
            <DatePicker
              showTime={{ use12Hours: true }}
              format="YYYY-MM-DD hh:mm a"
              disabled={!!isEditing}
            />
          </Form.Item>
          <Form.Item
            name="availability"
            label="Source"
            rules={[{ required: true }]}>
            <Select
              style={{ width: '200px' }}
              options={createOptionsFromEnum(MaterialAvailability)}
              disabled={!!isEditing}
            />
          </Form.Item>
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select
              style={{
                width: '200px',
              }}
              options={createOptionsFromEnum(MaterialSourceType)}
              disabled={!!isEditing}
            />
          </Form.Item>
        </Space>
        <Space
          direction="horizontal"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name={'warehouse'} label={'Warehouse'}>
            <SelectWithDefault
              style={{
                width: '400px',
              }}
              useSelectProps={{
                resource: WAREHOUSE_URL,
                optionLabel: 'name',
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item name={'vendor'} label={'Vendor'}>
            <SelectWithDefault
              style={{
                width: '400px',
              }}
              useSelectProps={{
                resource: VENDOR_URL,
                optionLabel: 'name',
              }}
              allowClear
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  )
}
