import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IFoliageType } from 'interfaces/pricing'
import { FoliageTypeForm } from './Form'

export const FoliageTypeCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IFoliageType>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <FoliageTypeForm formProps={formProps} />
    </Create>
  )
}
