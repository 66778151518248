// @ts-nocheck
import { LayoutProps, useNavigation } from '@refinedev/core'
import { FlutterWrapper } from 'FlutterWrapper'
import { Layout as AntdLayout, Grid, FloatButton } from 'antd'
import * as Icons from '@ant-design/icons'
import React from 'react'
import { CHATS_URL } from 'urls'

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint()
  var marginLeftValue = '60px'

  const { replace } = useNavigation()

  if (breakpoint.lg || breakpoint.md) {
    marginLeftValue = '58px'
  } else if (breakpoint.sm || breakpoint.xs) {
    marginLeftValue = '33px'
  }

  return (
    <AntdLayout
      style={{
        minHeight: '100vh',
        flexDirection: 'row',
        backgroundColor: '#f9f5ff',
      }}>
      <Header />
      <Sider />
      <AntdLayout.Content>
        <div
          style={{
            marginTop: '56px',
            marginLeft: marginLeftValue,
            padding: breakpoint.sm ? 12 : 12,
            minHeight: 360,
          }}>
          {children}
          <FlutterWrapper />
        </div>
        <OffLayoutArea />
      </AntdLayout.Content>
      <Footer />
    </AntdLayout>
  )
}
