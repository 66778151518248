import {
  BaseRecord,
  CrudFilters,
  HttpError,
  Pagination,
  useList,
} from '@refinedev/core'
import { ReactElement, useState } from 'react'

export const useTransfer = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>({
  resource,
  onSearch,
  optionValue = 'name',
  optionId = 'id',
  fetchSize = 10,
  renderOption,
  pagination,
  filters = [],
}: {
  resource: string
  onSearch?: (value: string) => CrudFilters
  optionId?: string
  optionValue?: string
  fetchSize?: number
  renderOption?: (item: TData) => ReactElement
  filters?: CrudFilters
  pagination?: Pagination
}) => {
  const [search, setSearch] = useState<CrudFilters>([])

  const queryResult = useList<TData>({
    resource,
    filters: filters.concat(search),

    pagination: pagination ?? {
      pageSize: fetchSize,
    },
  })

  const handleSearch = (value: string) => {
    if (!value) {
      setSearch([])
      return
    }

    if (onSearch) {
      setSearch(onSearch(value))
    } else {
      setSearch([
        {
          field: 'search',
          operator: 'eq',
          value,
        },
      ])
    }
  }

  return {
    queryResult,
    transferProps: {
      rowKey: (item: TData) => item[optionId],
      dataSource: queryResult.data?.data,
      titles: ['Source', 'Selected'],
      render: (item: TData) =>
        !!renderOption ? renderOption(item) : item[optionValue],
      showSearch: !!onSearch,
      onSearch: handleSearch,
    },
  }
}
