import { Form, Input, Switch } from "antd";
import { UseFormReturnType } from 'interfaces/refine'
import { ILayer } from 'interfaces/smartPhoto'
import 'react-quill/dist/quill.snow.css'

export const LayerForm: React.FC<{
  useFormProps: UseFormReturnType<ILayer>
}> = ({ useFormProps }) => {
  const { formProps, queryResult, mutationResult } = useFormProps

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Visible"
        name="is_visible"
        initialValue={true}
        valuePropName="checked">
        <Switch defaultChecked />
      </Form.Item>
    </Form>
  )
}
