import { Form, FormProps, Input, Space, Switch } from "antd";
import { ICategory } from 'interfaces/category'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { CATEGORY_URL } from 'urls'

export const BreakdownForm: React.FC<{
  formProps: FormProps
  queryResult: any
}> = ({ formProps, queryResult }) => {
  const isProduct = Form.useWatch(['product'], formProps.form)

  const onFinish = (values: any) => {
    values.allowed_categories = values.allowed_categories?.map((id: number)=>{
      return {'category': id}
    })
    if(formProps.onFinish) formProps.onFinish(values);
  }
  
  return (
    <Form {...formProps} layout="vertical" 
      onFinish={onFinish}
    >
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Space size="large" align="center">
        <Form.Item label="Structure" name="structure" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Floral" name="floral" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Fabric" name="fabric" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Product" name="product" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Space>
      {
        isProduct && <Form.Item label="Allowed Categories" name="allowed_categories">
        <SelectWithDefault<ICategory>
          mode="multiple"
          useSelectProps={{
            resource: CATEGORY_URL,
            optionLabel: 'name',
            fetchSize: 100,
          }}
        />
        </Form.Item>
      }
      
    </Form>
  )
}
