import { BaseKey, useCreate, useInvalidate } from '@refinedev/core'
import { Button, Form, Input, Modal } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import React from 'react'
import { CUISINE_URL } from 'urls'

export const CloneMenu: React.FC<{
  visible: boolean
  close: () => void
  menuId?: BaseKey
}> = ({ visible, close, menuId }) => {
  const { mutate: clone } = useCreate()
  const invalidate = useInvalidate()

  return (
    <Modal
      visible={visible}
      onCancel={close}
      destroyOnClose
      footer={null}
      title="Clone Package">
      <Form
        preserve={false}
        layout="vertical"
        onFinish={(values) => {
          clone(
            {
              resource: `cms/menus/${menuId}/clone_for_session`,
              values: {
                session_Id: null,
                name: values.name,
                cuisine: values.cuisine,
                description: values.description,
              },
            },
            {
              onSuccess: () => {
                close()
                invalidate({
                  resource: 'cms/menus',
                  invalidates: ['list'],
                })
              },
            },
          )
        }}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
          MenuForm
        </Form.Item>
        <Form.Item label="Cuisine" name="cuisine">
          <SelectWithDefault
            mode="multiple"
            useSelectProps={{ resource: CUISINE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Clone
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
