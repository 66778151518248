import React from 'react'
import { useApiUrl, useCustomMutation } from '@refinedev/core'
import { Button } from 'antd'

const MarkPlanningPending = ({
  selectedRows,
  setSelectedRows,
  refetchSource,
}: {
  selectedRows: any
  setSelectedRows: any
  refetchSource: any
}) => {
  const { mutate } = useCustomMutation<any>()
  const apiUrl = useApiUrl()

  const setPlanningPending = () => {
    mutate(
      {
        url: `${apiUrl}/package_tool/material_sources/mark_planning_pending/`,
        values: {
          ms_ids: selectedRows,
        },
        method: 'post',
        successNotification: (data, values) => {
          return {
            message: 'Planning Pending Marked Successfully',
            type: 'success',
          }
        },
      },
      {
        onSuccess: (data) => {
          setSelectedRows([])
          refetchSource()
        },
      },
    )
  }

  return (
    <div>
      <Button
        onClick={() => {
          setPlanningPending()
        }}>
        Mark Planning Pending
      </Button>
    </div>
  )
}

export default MarkPlanningPending
