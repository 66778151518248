import { Descriptions } from 'antd'
import { PriceField } from 'components/field'
import { ICatering } from 'interfaces/venue'

export const CateringDetails = ({ item }: { item: ICatering }) => {
  return (
    <Descriptions column={4}>
      <Descriptions.Item label="Meal Schedule">
        {item.meal_schedule}
      </Descriptions.Item>
      <Descriptions.Item label="Veg Price">
        <PriceField value={item.veg_price ?? 0} />
      </Descriptions.Item>
      <Descriptions.Item label="Non Veg Price">
        <PriceField value={item.non_veg_price ?? 0} />
      </Descriptions.Item>
    </Descriptions>
  )
}
