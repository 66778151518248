import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BooleanField, CreateButton, ExportButton, ImageField, List, TextField } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Space, Table } from "antd";
import { useExport, useList, useRouterContext } from "@refinedev/core";
import { LogButton } from 'components/button'
import { PriceField } from 'components/field'
import { ICategory } from 'interfaces/category'
import { IProduct } from 'interfaces/product'
import { CATEGORY_URL } from 'urls'
import { useProductTableReturnType } from './List'

export const ProductTable: React.FC<{
  tProps: useProductTableReturnType
}> = ({ tProps }) => {
  const { Link } = useRouterContext()
  const { data: categoriesData, isLoading: categoryIsLoading } =
    useList<ICategory>({
      resource: CATEGORY_URL,

      pagination: {
        pageSize: 100,
      }
    })

  const { triggerExport, isLoading: isExportLoading } = useExport<IProduct>({
    pageSize: 75,

    mapData: (item) => {
      return {
        sku: item.sku,
        name: item.name,
        category: categoriesData?.data.find((c) => c.id === item.category)
          ?.name,
        price: item.price,
        description: item.description,
        stage: item.stage,
        is_internal: item.is_internal,
        is_deleted: item.is_deleted,
        created_at: item.created_at,
        updated_at: item.updated_at,
      }
    },

    filters: tProps.filters,
    sorters: tProps.sorter
  })

  const pageHeaderProps = {
    extra: [
      <LogButton key={'log'} />,
      <Button
        key="customerView"
        onClick={() => window.open('/cms/pricing', '_blank')}>
        <Space>
          <FontAwesomeIcon icon={['fas', 'tags']} />
          Pricing View
        </Space>
      </Button>,
      <Button
        key="customerView"
        icon={<Icons.AppstoreOutlined />}
        onClick={() => window.open('/cms/product-cards', '_blank')}>
        Showcase
      </Button>,
      <Button
        key="portfolio"
        icon={<Icons.WindowsOutlined />}
        onClick={() => window.open('/package_tool/portfolio', '_blank')}>
        Portfolio
      </Button>,
      <ExportButton
        key="export"
        onClick={triggerExport}
        loading={isExportLoading}
      />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List
      headerProps={pageHeaderProps}
      title={`Products (${tProps.tableQueryResult.data?.total})`}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowClassName={(record) => (record.is_deleted ? 'disabled-row' : '')}>
        <Table.Column<IProduct>
          title="Image"
          ellipsis
          render={(_, record) => (
            <ImageField
              value={record.images[0]?.image}
              width={150}
              height={150}
            />
          )}
        />
        <Table.Column dataIndex="sku" title="SKU" ellipsis />
        <Table.Column<IProduct>
          dataIndex="name"
          title="Name"
          ellipsis
          render={(value, record) => (
            <Link to={`edit/${record.id}`}>{value}</Link>
          )}
        />
        <Table.Column
          dataIndex={['category']}
          title="Category"
          ellipsis
          render={(value) => {
            if (categoryIsLoading) {
              return <TextField value="Loading..." />
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.name
                }
              />
            )
          }}
        />

        <Table.Column
          dataIndex="price"
          title="Price"
          render={(value) => <PriceField value={value ?? 0} />}
        />
        <Table.Column
          dataIndex="has_breakdown"
          title="Can Edit"
          ellipsis
          render={(value) => <BooleanField value={value} />}
        />
      </Table>
    </List>
  )
}
