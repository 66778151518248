import { ImageField } from '@refinedev/antd'
import {
  Layout as AntdLayout,
  Button,
  Col,
  Popover,
  Result,
  Row,
  Segmented,
  Space,
  Spin,
  Switch,
  Divider,
  Modal,
  Typography,
} from 'antd'
import {
  Authenticated,
  useGetIdentity,
  useModal,
  useTable,
} from '@refinedev/core'
import { SettingOutlined } from '@ant-design/icons'
import parse from 'html-react-parser'
import update from 'immutability-helper'
import { IProductShowcase } from 'interfaces/product'
import { IStaff } from 'interfaces/staff'
import { useEffect, useState } from 'react'
import { SplitPane } from 'react-collapse-pane'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import './styles.less'
import { PriceField } from 'components/field'
import { EXTERNAL_USER_KEY } from 'authProvider'
import { DishFilter } from 'components/filters/DishFilter'
import { MenuFilter } from 'components/filters/MenuFilter'
import { IDish, IMenu } from 'interfaces/catering'
import { MenuPackage } from 'pages/package_tool/venue/ShowcaseDetail'

const { Text, Title } = Typography

const HIDE_PRICE_KEY = 'hidePrice'
const isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

export const ImageCarousel = ({
  image,
  width,
  height,
  style,
  className,
}: {
  image: string
  width?: number
  height?: number
  style?: any
  className?: string
}) => {
  const [visible, setVisible] = useState(false)

  if (image)
    return (
      <>
        <div className="image-container">
          <ImageField
            value={image}
            preview={{ visible: false }}
            width={width}
            height={height}
            wrapperStyle={style}
            className={className}
            onClick={() => setVisible(true)}
          />
        </div>
      </>
    )
  return <ImageField value={'/product_paleceholder.png'} preview={false} />
}

const DishCard: React.FC<{ item: IDish }> = ({ item }) => {
  const { show, visible, close } = useModal()
  const hidePrice = localStorage.getItem(HIDE_PRICE_KEY) === 'true'

  return (
    <div className="showcase-card">
      <div className="card-cover">
        <ImageCarousel image={item.image} />
      </div>
      <div className="card-content" onClick={show}>
        <div className="card-title-container">
          <Title level={4}>{item.name}</Title>
        </div>
        {!hidePrice && (
          <div className="card-description-container">
            <Text type="secondary">
              <PriceField value={item.price} />
            </Text>
          </div>
        )}
      </div>
      <Modal
        title={
          <>
            <Typography.Title level={4}>{item.name}</Typography.Title>
            {!hidePrice && (
              <>
                <Typography.Text strong>
                  <PriceField value={item.price} />
                </Typography.Text>
                <br />
              </>
            )}
          </>
        }
        className="showcase-modal"
        visible={visible}
        footer={null}
        onCancel={close}
        destroyOnClose>
        {item.image && item.description && (
          <Row>
            <Col span={12}>
              <div className="modal-carousel-container">
                <ImageCarousel image={item.image} className="detail-image" />
              </div>
            </Col>
            <Col span={12}>
              <div className="details-container">
                <Typography.Title level={5}>Description</Typography.Title>
                {parse(item.description ?? '')}
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  )
}

const MenuCard: React.FC<{ item: IMenu }> = ({ item }) => {
  const { show, visible, close } = useModal()
  const hidePrice = localStorage.getItem(HIDE_PRICE_KEY) === 'true'

  return (
    <div className="showcase-card">
      <div className="card-cover">
        <ImageCarousel image={'/product_paleceholder.png'} />
      </div>
      <div className="card-content" onClick={show}>
        <div className="card-title-container">
          <Title level={4}>{item.name}</Title>
        </div>
        {!hidePrice && (
          <div className="card-description-container">
            <Text type="secondary">
              <PriceField value={item.menu_total} />
            </Text>
          </div>
        )}
      </div>
      <Modal
        title={
          <>
            <Typography.Title level={4}>{item.name}</Typography.Title>
            {!hidePrice && (
              <>
                <Typography.Text strong>
                  <PriceField value={item.menu_total} />
                </Typography.Text>
                <br />
              </>
            )}
          </>
        }
        className="showcase-modal"
        visible={visible}
        footer={null}
        onCancel={close}
        destroyOnClose>
        <MenuPackage menuId={item.id} />
      </Modal>
    </div>
  )
}

const CardList: React.FC = () => {
  const [dishList, setDishList] = useState<IDish[]>([])
  const [menuList, setMenuList] = useState<IMenu[]>([])
  const [segmentedValue, setSegmentedValue] = useState('Dish')
  const { data: user } = useGetIdentity<IStaff>()

  const {
    tableQueryResult: queryResult,
    current,
    setCurrent,
    setFilters,
    setSorter,
    pageSize,
  } = useTable<IProductShowcase>({
    resource: segmentedValue === 'Dish' ? 'cms/dishes/' : 'cms/menus/',
    queryOptions: {
      enabled: !!user,
      onSuccess: (data: any) => {
        segmentedValue === 'Dish'
          ? setDishList(update(dishList, { $push: data.data ?? [] }))
          : setMenuList(update(menuList, { $push: data.data ?? [] }))
      },
    },
    filters:
      segmentedValue === 'Menu'
        ? {
            permanent: [
              {
                field: 'is_template',
                operator: 'eq',
                value: true,
              },
            ],
          }
        : {},
    pagination: {
      pageSize: 16,
    },
  })

  useEffect(() => {
    setFilters([])
    setMenuList([])
    setDishList([])
    setCurrent(1)
  }, [segmentedValue, setCurrent])

  const onFinish = () => {
    setMenuList([])
    setDishList([])
  }

  return (
    <AntdLayout className="showcase">
      <AntdLayout.Header className="showcase-header">
        <Segmented
          options={['Menu', 'Dish']}
          defaultValue={'Dish'}
          onChange={(value) => {
            setSegmentedValue(value as string)
          }}
        />
        {isExternal === 'true' ? (
          <Typography.Title level={1}>Catalog</Typography.Title>
        ) : (
          <img src={'/meragi-logo.svg'} alt="Meragi" />
        )}
        <Popover content={<SettingsPopover />} trigger="click">
          <Button
            icon={<SettingOutlined />}
            type="text"
            shape="circle"
            style={{ backgroundColor: 'white', alignContent: 'center' }}
          />
        </Popover>
      </AntdLayout.Header>

      <AntdLayout.Content className="showcase-content">
        <SplitPane
          split="vertical"
          collapse={{ buttonTransition: 'none', collapsedSize: 10 }}
          initialSizes={[20, 80]}>
          <div className="scroll sider">
            {segmentedValue === 'Dish' ? (
              <DishFilter
                setFilters={setFilters}
                setSorter={setSorter}
                setCurrent={setCurrent}
                onFinish={onFinish}
                onUndo={onFinish}
              />
            ) : (
              <MenuFilter
                setFilters={setFilters}
                setSorter={setSorter}
                setCurrent={setCurrent}
                onFinish={onFinish}
                onUndo={onFinish}
              />
            )}
          </div>

          <div
            className="scroll-content-card-list scroll"
            id="infinite-scroll-content-card-list">
            <InfiniteScroll
              hasMore={current * pageSize < (queryResult.data?.total ?? 0)}
              next={() => setCurrent(current + 1)}
              dataLength={
                segmentedValue === 'Dish' ? dishList.length : menuList.length
              }
              scrollThreshold={0.7}
              scrollableTarget="infinite-scroll-content-card-list"
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
              }}
              loader={
                <div className="row h-center">
                  <Spin />
                </div>
              }
              endMessage={
                queryResult.data?.total ? (
                  <Divider plain>You have reached the end 🤐</Divider>
                ) : queryResult.isFetched ? (
                  <Result
                    status="404"
                    title="Oops!"
                    subTitle="We could not find any products with these combinations"
                  />
                ) : (
                  <div className="row h-center">
                    <Spin />
                  </div>
                )
              }>
              <Masonry
                breakpointCols={{
                  default: 4,
                  1100: 3,
                  700: 2,
                  500: 1,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {segmentedValue === 'Dish'
                  ? dishList?.map((dish) => (
                      <DishCard key={dish.id} item={dish} />
                    ))
                  : menuList?.map((menu) => (
                      <MenuCard key={menu.id} item={menu} />
                    ))}
              </Masonry>
            </InfiniteScroll>
          </div>
        </SplitPane>
      </AntdLayout.Content>
    </AntdLayout>
  )
}

export const CateringCardList: React.FC = () => {
  return (
    <Authenticated>
      <CardList />
    </Authenticated>
  )
}

const SettingsPopover: React.FC = () => {
  const isPriceHidden = localStorage.getItem(HIDE_PRICE_KEY) === 'true'
  return (
    <Space>
      Hide Price
      <Switch
        defaultChecked={isPriceHidden}
        onChange={() => {
          localStorage.setItem(HIDE_PRICE_KEY, String(!isPriceHidden))
          window.location.reload()
        }}
      />
    </Space>
  )
}
