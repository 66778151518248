import { Card, notification } from "antd";
import { useForm } from 'components/hooks'
import { ITimeline } from 'interfaces/customer'
import { UseFormReturnType } from 'interfaces/refine'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TIMELINE_URL } from 'urls'
import { TimelineForm } from './Form'

export const CreateTimeline: React.FC<{
  pid: any
}> = ({ pid }) => {
  let timelineForm: UseFormReturnType<ITimeline> = useForm<ITimeline>({
    action: 'create',
    resource: TIMELINE_URL,
    redirect: false,
  })

  useHotkeys('ctrl+s, cmd+s', (e) => {
    e.preventDefault()
    notification.info({
      message: 'Saving',
      duration: 1,
      description: 'Save in progress',
    })
    timelineForm.formProps!.form!.submit()
  })

  return (
    <Card title="Add new">
      <TimelineForm useFormProps={timelineForm} pid={Number(pid)} />
    </Card>
  )
}
