import { Create } from "@refinedev/antd";
import { Authenticated, LayoutWrapper, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { ISmartPhoto } from 'interfaces/smartPhoto'
import { cleanPathName, getBasePathName } from 'utils/common'
import { SmartPhotoForm } from './Form'

export const SmartPhotoCreate: React.FC = () => {
  const { useLocation, useParams } = useRouterContext()
  const { pathname } = useLocation()
  const resource = cleanPathName(getBasePathName(pathname))
  const { venue_id } = useParams<any>()

  const useFormProps = useForm<ISmartPhoto>({
    action: 'create',
    resource: resource,
  })

  return (
    <Authenticated>
      <LayoutWrapper>
        <Create
          title="Create Smart Photo"
          headerProps={{
            breadcrumb: (
              <NestedBreadcrumb
                baseUrl="/package_tool"
                routes={[
                  { name: 'venues', id: venue_id },
                  { name: 'smart_photos' },
                ]}
              />
            ),
          }}
          saveButtonProps={useFormProps.saveButtonProps}
          resource={resource}>
          <SmartPhotoForm useFormProps={useFormProps} />
        </Create>
      </LayoutWrapper>
    </Authenticated>
  );
}
