import { Edit, useForm } from "@refinedev/antd";
import { LayoutWrapper, useNavigation } from "@refinedev/core";
import { IShortlistPackageSection } from 'interfaces/shortlistPackage'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ShortlistPackageSectionForm } from '.'

export const ShortlistPackageSectionEdit: React.FC = () => {
  // @ts-ignore
  const { package_id, id } = useParams()
  const { edit, goBack } = useNavigation()
  const useFormProps = useForm<IShortlistPackageSection>({
    action: 'edit',
    resource: `package_tool/shortlist_packages/${package_id}/sections`,
    redirect: false,
  })

  // set the id for the item to be fetched
  useEffect(() => {
    useFormProps.setId(id)
  }, [])

  useEffect(() => {
    useFormProps.setId(id)
  }, [useFormProps.mutationResult.isLoading])

  return (
    <LayoutWrapper>
      <Edit
        saveButtonProps={useFormProps.saveButtonProps}
        title="Edit Items"
        headerProps={{
          onBack: () => {
            goBack()
          },
          extra: null,
        }}>
        <ShortlistPackageSectionForm useFormProps={useFormProps} />
      </Edit>
    </LayoutWrapper>
  );
}
