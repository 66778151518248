import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as Icons from "@ant-design/icons";
import { useStateWithCallbackLazy } from 'components/hooks'

export interface SortableListProps {
  items: any[]
  getId: (item: any) => string
  renderItem: (item: any) => JSX.Element
  onSortEnd?: (items: any[]) => void
}

export const SortableList = ({
  items: itemsFromProps,
  getId,
  onSortEnd,
  renderItem,
}: SortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const [items, setItems] = useStateWithCallbackLazy(itemsFromProps)

  function handleDragEnd(event: any) {
    const { active, over } = event

    const activeId = getId(active)
    const overId = getId(over)

    if (activeId !== overId) {
      const oldIndex = items.findIndex((item) => getId(item) === activeId)
      const newIndex = items.findIndex((item) => getId(item) === overId)

      const newArray = arrayMove(items, oldIndex, newIndex)
      setItems(newArray, (final: any) => onSortEnd?.(final))
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => renderItem(item))}
      </SortableContext>
    </DndContext>
  )
}

export const SortableItem = (props: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id, disabled: props.disabled })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Icons.HolderOutlined
          {...attributes}
          {...listeners}
          style={{ color: props.disabled ? 'gray' : undefined }}
        />
        {props.children}
      </div>
    </div>
  )
}
