import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  useCan,
  useGetIdentity,
  useMenu,
  useNavigation,
  useRouterContext,
} from '@refinedev/core'
import {
  Layout as AntdLayout,
  Grid,
  Image,
  Menu,
  MenuProps,
  Tooltip,
} from 'antd'
import { EXTERNAL_USER_KEY } from 'authProvider'
import { IStaff } from 'interfaces/staff'
import React, { useState } from 'react'
import {
  ARTIFICIAL_RATIO_URL,
  ATTRIBUTE_URL,
  BEST_FOR_URL,
  BOOKING_REPORT_URL,
  BREAKDOWN_URL,
  CATEGORY_URL,
  CELEBRATION_URL,
  COLOR_URL,
  COMBINATION_URL,
  COURSE_URL,
  CUISINE_URL,
  CUSTOMER_URL,
  DAILY_LEAD_URL,
  DISH_URL,
  FABRIC_TYPE_URL,
  FLOWER_TYPE_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
  GROUP_URL,
  INTERFACE_URL,
  LEAD_URL,
  LOG_URL,
  MATERIAL_SOURCE_URL,
  MATERIAL_URL,
  MENU_URL,
  PACKAGE_URL,
  PAYMENT_TYPE_URL,
  PAYMENT_URL,
  PAYOUT_RULE_URL,
  PAYOUT_URL,
  PIPELINE_DETAILED_REPORT_URL,
  PIPELINE_REPORT_URL,
  PLAN_URL,
  PRICING_ITEM_URL,
  PRODUCT_URL,
  PROJECT_URL,
  PURCHASE_ORDER_URL,
  REGION_URL,
  RFQ_URL,
  SALES_TARGET_URL,
  SALES_TARGET_VIEW_URL,
  SECTION_URL,
  SERVICE_URL,
  SETTINGS_URL,
  SHORTLIST_PACKAGE_URL,
  SOURCE_REPORT_URL,
  STAFF_URL,
  SURCHARGE_URL,
  TAG_URL,
  VENDOR_URL,
  BOM_URL,
  VENUE_URL,
  WAREHOUSE_URL,
  TICKETS_URL,
} from 'urls'

const rootSubmenuKeys = [
  'showcase',
  'cms',
  'packageTool',
  'crm',
  'ops',
  'config',
]
//type items = Required<MenuProps>['items'][number];

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [openKeys, setOpenKeys] = useState([''])
  const { menuItems, selectedKey } = useMenu()
  const { push } = useNavigation()
  const breakpoint = Grid.useBreakpoint()

  const { Link } = useRouterContext()

  const isMobile = !breakpoint.lg

  const getRoute = (name: string) =>
    menuItems.find((item) => item.name === name)?.route

  const { data: user } = useGetIdentity<IStaff>()

  const { data: readStaff } = useCan({
    resource: 'read_staff',
    action: '',
  })

  const { data: accessPayments } = useCan({
    resource: 'access_payments',
    action: '',
  })

  const { data: accessPayouts } = useCan({
    resource: 'access_payouts',
    action: '',
  })
  const { data: veiwMilestone } = useCan({
    resource: 'view_milestone',
    action: '',
  })

  const items = [
    {
      label: 'Showcase',
      key: 'showcase',
      icon: <Icons.AppstoreOutlined style={{ color: '#fff' }} />,
      children: [
        {
          label: <Link to="/cms/product-cards/">Decor Showcase</Link>,
          key: '/cms/product-cards/',
          icon: <Icons.AppstoreOutlined />,
        },
        {
          label: <Link to="/package_tool/portfolio">Portfolio</Link>,
          key: '/package_tool/portfolio',
          icon: <Icons.WindowsOutlined />,
        },
        {
          label: 'Venue',
          key: 'catalog/venue',
          icon: <FontAwesomeIcon icon={['fas', 'building']} />,
          children: [
            {
              label: <Link to="/cms/venue-showcase/">Venue Showcase</Link>,
              key: '/cms/venue-showcase/',
              icon: <Icons.AppstoreOutlined />,
            },
            {
              label: <Link to="/cms/venue-showcase/">Venue Availability</Link>,
              key: '/cms/venue-availability/',
              icon: <Icons.CalendarOutlined />,
            },
          ],
        },
        {
          label: 'Catering Showcase',
          key: '/cms/catering-showcase/',
          icon: <Icons.AppstoreOutlined />,
        },
      ],
    },
    {
      label: 'CMS',
      key: 'cms',
      icon: (
        <FontAwesomeIcon icon={['fas', 'boxes']} style={{ color: '#fff' }} />
      ),
      children: [
        {
          label: <Link to={getRoute(CATEGORY_URL) as string}>Category</Link>,
          key: getRoute(CATEGORY_URL),
          icon: <FontAwesomeIcon icon={['fas', 'list']} />,
        },
        {
          label: <Link to={getRoute(PRODUCT_URL) as string}>Product</Link>,
          key: getRoute(PRODUCT_URL),
          icon: <FontAwesomeIcon icon={['fas', 'box-open']} />,
        },
        {
          label: <Link to="/cms/product-cards/">Showcase</Link>,
          key: '/cms/product-cards/',
          icon: <Icons.AppstoreOutlined />,
        },
        {
          label: <Link to="/package_tool/portfolio">Portfolio</Link>,
          key: '/package_tool/portfolio',
          icon: <Icons.WindowsOutlined />,
        },
        {
          label: 'Catering',
          key: 'cms/catering',
          icon: <FontAwesomeIcon icon={['fas', 'calculator']} />,
          children: [
            {
              label: <Link to={getRoute(CUISINE_URL) as string}>Cuisine</Link>,
              key: getRoute(CUISINE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
            {
              label: <Link to={getRoute(COURSE_URL) as string}>Course</Link>,
              key: getRoute(COURSE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
            {
              label: <Link to={getRoute(DISH_URL) as string}>Dish</Link>,
              key: getRoute(DISH_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
            {
              label: (
                <Link to={getRoute(SURCHARGE_URL) as string}>Surcharge</Link>
              ),
              key: getRoute(SURCHARGE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
            {
              label: <Link to={getRoute(MENU_URL) as string}>Menu</Link>,
              key: getRoute(MENU_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
          ],
        },
        {
          label: <Link to="/cms/pricing">Price Calculator</Link>,
          key: '/cms/pricing',
          icon: <FontAwesomeIcon icon={['fas', 'calculator']} />,
        },
        {
          label: 'Pricing Materials',
          key: 'pricing/materials',
          icon: <FontAwesomeIcon icon={['fas', 'boxes']} />,
          children: [
            {
              label: <Link to={getRoute(COLOR_URL) as string}>Color</Link>,
              key: getRoute(COLOR_URL),
              icon: <FontAwesomeIcon icon={['fas', 'paint-roller']} />,
            },
            {
              label: (
                <Link to={getRoute(FLOWER_TYPE_URL) as string}>
                  Flower Type
                </Link>
              ),
              key: getRoute(FLOWER_TYPE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'fan']} />,
            },
            {
              label: (
                <Link to={getRoute(FOLIAGE_TYPE_URL) as string}>
                  Foliage Type
                </Link>
              ),
              key: getRoute(FOLIAGE_TYPE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'tree']} />,
            },
            {
              label: (
                <Link to={getRoute(FABRIC_TYPE_URL) as string}>
                  Fabric Type
                </Link>
              ),
              key: getRoute(FABRIC_TYPE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'tshirt']} />,
            },
            {
              label: (
                <Link to={getRoute(ARTIFICIAL_RATIO_URL) as string}>
                  Artificial Ratio
                </Link>
              ),
              key: getRoute(ARTIFICIAL_RATIO_URL),
              icon: <FontAwesomeIcon icon={['fas', 'percent']} />,
            },
            {
              label: <Link to={getRoute(FLOWER_URL) as string}>Flower</Link>,
              key: getRoute(FLOWER_URL),
              icon: <FontAwesomeIcon icon={['fas', 'fan']} />,
            },
          ],
        },
        {
          label: 'Pricing',
          key: 'pricing',
          icon: <FontAwesomeIcon icon={['fas', 'tags']} />,
          children: [
            {
              label: (
                <Link to={getRoute(PRICING_ITEM_URL) as string}>Item</Link>
              ),
              key: getRoute(PRICING_ITEM_URL),
              icon: <FontAwesomeIcon icon={['fas', 'square']} />,
            },
            {
              label: (
                <Link to={getRoute(COMBINATION_URL) as string}>
                  Combination
                </Link>
              ),
              key: getRoute(COMBINATION_URL),
              icon: <FontAwesomeIcon icon={['fas', 'object-group']} />,
            },
            {
              label: (
                <Link to={getRoute(INTERFACE_URL) as string}>Interface</Link>
              ),
              key: getRoute(INTERFACE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'object-group']} />,
            },
            {
              label: (
                <Link to={getRoute(BREAKDOWN_URL) as string}>Breakdown</Link>
              ),
              key: getRoute(BREAKDOWN_URL),
              icon: <FontAwesomeIcon icon={['fas', 'object-group']} />,
            },
          ],
        },
      ],
    },
    {
      label: 'Package Tool',
      key: 'packageTool',
      icon: (
        <FontAwesomeIcon icon={['fas', 'toolbox']} style={{ color: '#fff' }} />
      ),
      children: [
        {
          label: (
            <Link to={getRoute(CELEBRATION_URL) as string}>Celebration</Link>
          ),
          key: getRoute(CELEBRATION_URL),
          icon: <FontAwesomeIcon icon={['fas', 'birthday-cake']} />,
        },
        {
          label: <Link to={getRoute(SECTION_URL) as string}>Section</Link>,
          key: getRoute(SECTION_URL),
          icon: <FontAwesomeIcon icon={['fas', 'grip-lines']} />,
        },
        {
          label: <Link to={getRoute(BEST_FOR_URL) as string}>Best For</Link>,
          key: getRoute(BEST_FOR_URL),
          icon: <FontAwesomeIcon icon={['fas', 'cocktail']} />,
        },
        {
          label: <Link to={getRoute(PACKAGE_URL) as string}>Package</Link>,
          key: getRoute(PACKAGE_URL),
          icon: <FontAwesomeIcon icon={['fas', 'cubes']} />,
        },
      ],
    },
    {
      label: <Link to={getRoute(PROJECT_URL) as string}>Projects</Link>,
      key: getRoute(PROJECT_URL) ?? '',
      icon: <Icons.SnippetsFilled style={{ color: '#fff' }} />,
    },
    {
      label: (
        <Link to={getRoute(SHORTLIST_PACKAGE_URL) as string}>Shortlisted</Link>
      ),
      key: getRoute(SHORTLIST_PACKAGE_URL) ?? '',
      icon: (
        <FontAwesomeIcon
          icon={['fas', 'check-double']}
          style={{ color: '#fff' }}
        />
      ),
    },
    {
      label: <Link to={getRoute(CUSTOMER_URL) as string}>Customer</Link>,
      key: getRoute(CUSTOMER_URL) ?? '',
      icon: (
        <FontAwesomeIcon icon={['fas', 'user-tag']} style={{ color: '#fff' }} />
      ),
    },
    {
      label: 'CRM',
      key: 'crm',
      icon: (
        <FontAwesomeIcon
          icon={['fas', 'rupee-sign']}
          style={{ color: '#fff' }}
        />
      ),
      children: [
        {
          label: <Link to={getRoute(SERVICE_URL) as string}>Service</Link>,
          key: getRoute(SERVICE_URL),
          icon: <FontAwesomeIcon icon={['fas', 'taxi']} />,
        },
        {
          label: <Link to={getRoute(LEAD_URL) as string}>Lead</Link>,
          key: getRoute(LEAD_URL),
          icon: <FontAwesomeIcon icon={['fas', 'rupee-sign']} />,
        },
        {
          label: 'Reports',
          key: 'crm-reports',
          icon: <FontAwesomeIcon icon={['fas', 'chart-pie']} />,
          children: [
            {
              label: <Link to={'/' + BOOKING_REPORT_URL}>Bookings</Link>,
              key: '/' + BOOKING_REPORT_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
            {
              label: <Link to={'/' + PIPELINE_REPORT_URL}>Pipeline</Link>,
              key: '/' + PIPELINE_REPORT_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
            {
              label: (
                <Link to={'/' + PIPELINE_DETAILED_REPORT_URL}>
                  Pipeline Detailed
                </Link>
              ),
              key: '/' + PIPELINE_DETAILED_REPORT_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
            {
              label: <Link to={'/' + SOURCE_REPORT_URL}>Source</Link>,
              key: '/' + SOURCE_REPORT_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
            {
              label: <Link to={'/' + DAILY_LEAD_URL}>Daily Leads</Link>,
              key: '/' + DAILY_LEAD_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
            {
              label: <Link to={'/' + SALES_TARGET_URL}>Sales</Link>,
              key: '/' + SALES_TARGET_URL,
              icon: <FontAwesomeIcon icon={['fas', 'table']} />,
            },
          ],
        },
      ],
    },
    {
      label: 'Operations',
      key: 'operations',
      icon: (
        <FontAwesomeIcon
          icon={['fas', 'laptop-house']}
          style={{ color: '#fff' }}
        />
      ),
      children: [
        {
          label: <Link to={getRoute(RFQ_URL) as string}>RFQ</Link>,
          key: getRoute(RFQ_URL),
          icon: <FontAwesomeIcon icon={['fas', 'sticky-note']} />,
        },
        {
          label: (
            <Link to={getRoute(BOM_URL) as string}>BOM</Link>
          ),
          key: getRoute(BOM_URL),
          icon: <FontAwesomeIcon icon={['fas', 'sticky-note']} />,
        },
        {
          label: (
            <Link to={getRoute(MATERIAL_SOURCE_URL) as string}>Tracking</Link>
          ),
          key: getRoute(MATERIAL_SOURCE_URL),
          icon: <FontAwesomeIcon icon={['fas', 'tasks']} />,
        },
        {
          label: <Link to={getRoute(WAREHOUSE_URL) as string}>Warehouse</Link>,
          key: getRoute(WAREHOUSE_URL),
          icon: <FontAwesomeIcon icon={['fas', 'warehouse']} />,
        },
        {
          label: <Link to={getRoute(MATERIAL_URL) as string}>Material</Link>,
          key: getRoute(MATERIAL_URL),
          icon: <FontAwesomeIcon icon={['fas', 'pallet']} />,
        },
        {
          label: (
            <Link to={getRoute(MATERIAL_URL + '_v2') as string}>
              Material V2
            </Link>
          ),
          key: getRoute(MATERIAL_URL + '_v2') ?? '',
          icon: <FontAwesomeIcon icon={['fas', 'pallet']} />,
        },
        {
          label: (
            <Link to={getRoute(PURCHASE_ORDER_URL) as string}>
              Purchase Order
            </Link>
          ),
          key: getRoute(PURCHASE_ORDER_URL),
          icon: <FontAwesomeIcon icon={['fas', 'file-invoice']} />,
        },
        {
          label: <Link to={getRoute(VENDOR_URL) as string}>Vendors</Link>,
          key: getRoute(VENDOR_URL) ?? '',
          icon: <FontAwesomeIcon icon={['fas', 'truck-loading']} />,
        },
        {
          label: (
            <Link to={getRoute(VENDOR_URL + '_v2') as string}>Vendors V2</Link>
          ),
          key: getRoute(VENDOR_URL + '_v2') ?? '',
          icon: <FontAwesomeIcon icon={['fas', 'truck-loading']} />,
        },
      ],
    },
    {
      label: <Link to={getRoute(VENUE_URL) as string}>Venues</Link>,
      key: getRoute(VENUE_URL) ?? '',
      icon: (
        <FontAwesomeIcon icon={['fas', 'building']} style={{ color: '#fff' }} />
      ),
    },
    {
      label: <Link to={getRoute(TICKETS_URL) as string}>Tickets</Link>,
      key: getRoute(TICKETS_URL) ?? '',
      icon: (
        <FontAwesomeIcon icon={['fas', 'list']} style={{ color: '#fff' }} />
      ),
    },
    (accessPayments?.can || accessPayouts?.can) && {
      label: 'Transactions',
      key: 'transaction',
      icon: (
        <FontAwesomeIcon icon={['fas', 'receipt']} style={{ color: '#fff' }} />
      ),
      children: [
        accessPayments?.can && {
          label: <Link to={getRoute(PAYMENT_URL) as string}>Payments</Link>,
          key: getRoute(PAYMENT_URL) ?? '',
          icon: <FontAwesomeIcon icon={['fas', 'money-bill-wave']} />,
        },
        accessPayouts?.can && {
          label: <Link to={getRoute(PAYOUT_URL) as string}>Payouts</Link>,
          key: getRoute(PAYOUT_URL) ?? '',
          icon: <FontAwesomeIcon icon={['fas', 'coins']} />,
        },
      ],
    },
    {
      label: <Link to={getRoute(LOG_URL) as string}>Logs</Link>,
      key: getRoute(LOG_URL) ?? '',
      icon: (
        <FontAwesomeIcon
          icon={['fas', 'clipboard-list']}
          style={{ color: '#fff' }}
        />
      ),
    },
    {
      label: 'Configuration',
      key: 'config',
      icon: <Icons.SettingFilled style={{ color: '#fff' }} />,
      children: [
        {
          label: <Link to={'/' + SALES_TARGET_VIEW_URL}>Sales Target</Link>,
          key: '/' + SALES_TARGET_VIEW_URL,
          icon: <Icons.StockOutlined style={{ fontSize: '20px' }} />,
        },
        {
          label: <Link to={getRoute(REGION_URL) as string}>Region</Link>,
          key: getRoute(REGION_URL),
          icon: <FontAwesomeIcon icon={['fas', 'map-marked-alt']} />,
        },
        {
          label: <Link to={getRoute(PLAN_URL) as string}>Plan</Link>,
          key: getRoute(PLAN_URL),
          icon: <FontAwesomeIcon icon={['fas', 'cube']} />,
        },
        {
          label: <Link to={getRoute(ATTRIBUTE_URL) as string}>Tags</Link>,
          key: getRoute(TAG_URL),
          icon: <FontAwesomeIcon icon={['fas', 'shapes']} />,
        },
        {
          label: <Link to={getRoute(ATTRIBUTE_URL) as string}>Attributes</Link>,
          key: getRoute(ATTRIBUTE_URL),
          icon: <FontAwesomeIcon icon={['fas', 'shapes']} />,
        },
        {
          label: <Link to={getRoute(SETTINGS_URL)}>Settings</Link>,
          key: getRoute(SETTINGS_URL),
          icon: <FontAwesomeIcon icon={['fas', 'cogs']} />,
        },
        veiwMilestone?.can && {
          label: <Link to={getRoute('milestone')}>Milestones</Link>,
          key: 'milestone',
          icon: <FontAwesomeIcon icon={['fas', 'wave-square']} />,
        },
        readStaff?.can && {
          label: 'Staff',
          key: 'staff',
          icon: <FontAwesomeIcon icon={['fas', 'user-cog']} />,
          children: [
            {
              label: <Link to={getRoute(STAFF_URL) as string}>Staff</Link>,
              key: getRoute(STAFF_URL),
              icon: <FontAwesomeIcon icon={['fas', 'user-tie']} />,
            },
            {
              label: <Link to={getRoute(GROUP_URL) as string}>Group</Link>,
              key: getRoute(GROUP_URL),
              icon: <FontAwesomeIcon icon={['fas', 'users']} />,
            },
          ],
        },
        {
          label: 'Banking',
          key: 'banking',
          icon: <FontAwesomeIcon icon={['fas', 'university']} />,
          children: [
            {
              label: (
                <Link to={getRoute(PAYMENT_TYPE_URL) as string}>
                  Payment Types
                </Link>
              ),
              key: getRoute(PAYMENT_TYPE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'credit-card']} />,
            },
            user?.is_superuser && {
              label: (
                <Link to={getRoute(PAYOUT_RULE_URL) as string}>
                  Payout Rules
                </Link>
              ),
              key: getRoute(PAYOUT_RULE_URL),
              icon: <FontAwesomeIcon icon={['fas', 'hand-paper']} />,
            },
          ],
        },
      ],
    },
  ]

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

  if (isExternal === 'true') return <></>

  return (
    <AntdLayout.Sider
      collapsible
      style={{ backgroundColor: '#6633CC' }}
      defaultCollapsed={true}
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 50}>
      {/* @ts-ignore */}
      <Menu
        style={{ backgroundColor: '#6633CC', color: '#fff' }}
        selectedKeys={[selectedKey]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={({ key }) => {
          setCollapsed(true)
          push(key as string)
        }}
        // @ts-ignore
        items={items}
      />
      <Link to={'/bhyve'}>
        <Tooltip title="BHyve">
          <Image
            src={'/bhyve-logo.png'}
            height={50}
            // width={50}
            preview={false}
          />
        </Tooltip>
      </Link>
    </AntdLayout.Sider>
  )
}
