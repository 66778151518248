import { Input, Modal, Select } from 'antd'
import { BaseKey, useCan, useModal, useOne, useUpdate } from '@refinedev/core'
import { useStateWithCallbackLazy } from 'components/hooks'
import { DatePicker } from 'components/input'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IProgressTracker, ITracker } from 'interfaces/crm'
import { useEffect, useState } from 'react'
import { LEAD_URL } from 'urls'

export const ChangeSelect = ({
  leadId,
  changes: changesFromProps = [],
  type = 'status',
}: {
  leadId?: BaseKey
  changes?: IProgressTracker[]
  type?: 'status' | 'stage'
}) => {
  const [changes, setChanges] = useState<IProgressTracker[]>([])
  const [reason, setReason] = useState('')
  const [date, setDate] = useState(dayjs())

  const { data: changeLeadStage } = useCan({
    resource: 'change_crm_lead_stage',
    action: '',
  })

  const [selectedItem, setSelectedItem] = useStateWithCallbackLazy<
    ITracker | undefined
  >()

  const { mutate, isLoading } = useUpdate()

  const { show, close, visible } = useModal()

  const { data: trackerData } = useOne<ITracker[]>({
    resource: LEAD_URL,
    id: `${type}_list`,
  })

  useEffect(() => {
    setChanges(changesFromProps)
  }, [changesFromProps])

  const updateChange = (item?: ITracker) => {
    mutate(
      {
        resource: LEAD_URL,
        id: `${leadId}/set_${type}_change`,
        values: {
          change: (item || selectedItem)?.name,
          reason: reason,
          date: date ?? null,
        },
      },
      {
        onSuccess: (data) => {
          setChanges(data.data as IProgressTracker[])
          close()
        },
      },
    )
  }

  const getItem = (value: string) =>
    trackerData?.data.find((d) => d.name === value)

  const handleOptionChange = (value: any) => {
    const item = getItem(value)
    if (item) {
      setSelectedItem(item, () => {
        if (item?.is_reason_required || item?.is_date_required) {
          show()
        } else {
          updateChange(item)
        }
      })
    }
  }

  const getRecentProgress = (
    change?: IProgressTracker,
  ): IProgressTracker | undefined => {
    return change || changes[0]
  }

  return (
    <>
      <Select
        dropdownMatchSelectWidth={false}
        value={getRecentProgress()?.change}
        options={trackerData?.data.map((t) => ({
          label: t.name,
          value: t.name,
          disabled: type === 'status' && t.name === 'Inactive',
        }))}
        loading={isLoading}
        disabled={type === 'stage' && !changeLeadStage?.can}
        onChange={(value) => handleOptionChange(value)}
      />
      <Modal
        open={visible}
        onCancel={() => {
          setSelectedItem(getItem(changes[0].change), () => {})
          close()
        }}
        title="Extra details"
        destroyOnClose
        onOk={() => updateChange()}>
        {selectedItem?.is_date_required && (
          <DatePicker value={date} onChange={setDate} showTime />
        )}
        {selectedItem?.is_reason_required && (
          <>
            {!selectedItem?.options?.length ? (
              <Input.TextArea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            ) : (
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
                options={selectedItem?.options.map((option: string) => ({
                  label: option,
                  value: option,
                }))}
                value={reason}
                onChange={(value) => setReason(value)}
              />
            )}
          </>
        )}
      </Modal>
    </>
  )
}
