import { file2Base64 } from '@refinedev/core'
import { FormInstance } from 'antd'

/**
 * Sets error messages for form fields.
 *
 * @param {Object} errorData - An object with keys as field names and values as arrays of error messages.
 * @param {FormInstance} [form] - A form instance object. If provided, the corresponding fields
 *  in the form will be updated with the error messages.
 */
export const setErrors = (
  errorData: { [key: string]: string[] },
  form?: FormInstance,
) => {
  Object.entries(errorData).forEach(([field, errors]) => {
    form?.setFields([{ name: field, errors: errors ?? [] }])
  })
}

/**
 * Converts a cover image to a base64 string.
 *
 * @param {any} cover_image - The cover image to be converted.
 * @return {Promise<string>} A promise that resolves to the base64 string representation of the cover image.
 */
export const handleBase64Image = async (cover_image: any): Promise<string> => {
  let finalImage = ''

  if (cover_image) {
    const file = cover_image[cover_image?.length - 1]

    if (file.originFileObj) {
      const base64String = await file2Base64(file)
      finalImage = base64String
    }
  }

  return finalImage
}
