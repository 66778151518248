import { DateField, DateFieldProps } from '@refinedev/antd'
import { Tooltip } from 'antd'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import relativeTime from 'antd/node_modules/dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const DateFieldFromNow = (props: DateFieldProps) => {
  return (
    <Tooltip
      title={<DateField format="LLL" style={{ color: 'white' }} {...props} />}>
      <span>{dayjs(props.value as any).fromNow()}</span>
    </Tooltip>
  )
}
