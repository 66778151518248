import { ImageField, useTableProps } from '@refinedev/antd'
import { useContext } from 'react'
import { PRICING_ITEM_URL, PRODUCT_URL } from 'urls'
import { PricingGridSelect } from './PricingGridSelect'
import { PricingContext } from './index'
import { Component } from './pricingToolTree'

export const ShowItem = ({ item }: { item: Component }) => {
  const value = useContext(PricingContext)
  // const { mutate: patch, isLoading: isUpdating } = useUpdate()

  interface Dictionary {
    [key: string]: {
      tableProps: useTableProps<any, any, any, any>
      onChange: any
      getImageUrl?: (item: any) => string
    }
  }

  const categoryWiseProps: Dictionary = {
    STRUCTURE: {
      onChange: (props: any) => {
        // create a copy of selected item
        let temp_item = Object.assign({}, value.item)
        temp_item.getPrice = item.getPrice
        if (value.item) temp_item.update = value.item?.update

        if (temp_item?.info) {
          temp_item.info.item = props.id
          temp_item.info.item_image_url = props.image
        }
        temp_item.update()

        value.setItem(temp_item)
      },
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'STRUCTURE' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
    },
    PRODUCT: {
      onChange: (props: any) => {
        // create a copy of selected item
        let temp_item = { ...value.item }
        if (temp_item?.info) {
          temp_item.info.item = props.id
          temp_item.info.item_image_url =
            props.images && props.images.length !== 0
              ? props.images[0].image
              : ''
        }

        value.setItem(temp_item)
      },
      tableProps: {
        resource: PRODUCT_URL,
        permanentFilter: [
          { field: 'is_published', operator: 'eq', value: true },
          { field: 'is_internal', operator: 'eq', value: false },
          { field: 'is_deleted', operator: 'eq', value: false },
          // {
          //   field: 'category',
          //   operator: 'in',
          //   value: allowedCategories?.join(','),
          // },
        ],
      },
      getImageUrl: (item: any) => {
        const imageUrl =
          item && item.images && item.images.length !== 0
            ? item.images[0].image
            : ''
        return imageUrl
      },
    },
    FABRIC: {
      onChange: (props: any) => {
        // create a copy of selected item
        let temp_item = Object.assign({}, value.item)
        temp_item.getPrice = item.getPrice
        if (value.item) temp_item.update = value.item?.update
        if (temp_item?.info) {
          temp_item.info.item = props.id
          temp_item.info.item_image_url = props.image
        }
        temp_item.update()
        value.setItem(temp_item)
        console.log(temp_item)
      },
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'FABRIC' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
    },
    FLORAL: {
      onChange: (props: any) => {
        // create a copy of selected item
        let temp_item = Object.assign({}, value.item)
        if (temp_item?.info) {
          temp_item.info.item = props.id
          temp_item.info.item_image_url = props.image
        }
        // temp_item.update()

        value.setItem(temp_item)
      },
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'FLORAL' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
    },
    default: {
      onChange: (props: any) => {
        // create a copy of selected item
        let temp_item = { ...value.item }
        if (temp_item?.info) {
          temp_item.info.item = props.id
          temp_item.info.item_image_url = props.image
        }

        value.setItem(temp_item)
      },
      tableProps: {
        resource: PRICING_ITEM_URL,
        permanentFilter: [
          { field: 'category', operator: 'eq', value: 'STRUCTURE' },
          { field: 'is_visible', operator: 'eq', value: true },
        ],
      },
    },
  }

  return (
    <>
      {value.item && (
        <ImageField
          height={150}
          width={150}
          value={
            value.item.info && value.item.info.item_image_url
              ? value.item.info.item_image_url
              : ''
          }
        />
      )}
    </>
  )
}
