import * as Icons from '@ant-design/icons'
import {
  CreateButton,
  DateField,
  DeleteButton,
  ExportButton,
  FilterDropdown,
  RefreshButton,
  TextField,
  useSelect,
  useTable,
} from '@refinedev/antd'
import {
  Space,
  Table,
  DatePicker,
  Typography,
  Button,
  Popconfirm,
  Input,
  Form,
  Select,
} from 'antd'
import {
  BaseKey,
  CrudFilter,
  LogicalFilter,
  ResourceRouterParams,
  useApiUrl,
  useCan,
  useCustomMutation,
  useExport,
  useInvalidate,
  useList,
  useRouterContext,
} from '@refinedev/core'
import {
  IMaterialSource,
  MaterialAvailability,
  SectionItemDetails,
} from 'interfaces/quote'
import {
  MATERIAL_SOURCE_URL,
  MATERIAL_URL,
  RFQ_URL,
  SECTION_URL,
  SHORTLIST_PACKAGE_DETAILS_URL,
  VENDOR_URL,
  WAREHOUSE_URL,
} from 'urls'
import { MaterialStatusSelect } from '../MaterialStatusSelect'
import { Chat } from './Chat'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import dayjsForExport from 'dayjs'
import { useEffect, useState } from 'react'
import { MaterialSourceModalForm } from 'pages/package_tool/materialSource/Form'
import { BuyMaterialModal } from '../BuyMaterialModal'
import { NotesInput } from './NotesInput'
import AddDistributionCentreModal from './AddDistributionCentreModal'
import { IMaterialCategoryOption } from 'interfaces/material'
import { ISection } from 'interfaces/section'
import { FilterValue } from 'antd/es/table/interface'
import { IPackageDetail } from 'interfaces/shortlistPackage'
import MarkPlanningPending from './MarkPlanningPendingModal'

export const SourceTabs = ({
  disableTracking,
  packageId,
  sections,
}: {
  disableTracking?: boolean
  packageId?: BaseKey
  sections: SectionItemDetails[]
}) => {
  const [disableGenerateSources, setDisableGenerateSources] =
    useState<boolean>(false)

  const {
    tableProps,
    tableQueryResult: { refetch: refetchSources, isFetching: loadingSources },
    filters,
    setFilters,
    searchFormProps,
  } = useTable<IMaterialSource, any, any>({
    resource: `${MATERIAL_SOURCE_URL}/all_events/`,

    pagination: {
      pageSize: 50,
    },

    onSearch: ({ search }) => {
      return [
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
      ]
    },

    filters: {
      permanent: [{ field: 'package_id', operator: 'eq', value: packageId }],
    },
  })
  const { useParams } = useRouterContext()
  const [isEditng, setIsEditing] = useState<boolean>(false)
  const [editRecord, setEditRecord] = useState<BaseKey>()
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const { mutate: createMaterialSource, isLoading: isCreatingMaterialSource } =
    useCustomMutation()
  const apiUrl = useApiUrl()
  const { id: showId } = useParams<ResourceRouterParams>()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const hasSelected = selectedRowKeys.length > 0
  const [sectionIds, setSectionIds] = useState('')
  const invalidate = useInvalidate()
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({})

  const generateMaterialSource = () => {
    createMaterialSource(
      {
        url: `${apiUrl}/package_tool/rfq/${showId}/generate_material_sources_v2/`,
        method: 'post',
        values: {},
      },
      {
        onSuccess: () => {
          refetchSources()
          invalidate({
            resource: `${RFQ_URL}/${packageId}/get_pending_table`,
            invalidates: ['list'],
          })
        },
      },
    )
  }
  const generateInboundTracking = () => {
    createMaterialSource(
      {
        url: `${apiUrl}/package_tool/rfq/${showId}/generate_inbound_material_sources/`,
        method: 'post',
        values: {},
      },
      {
        onSuccess: () => {
          refetchSources()
        },
      },
    )
  }

  const { data: section_list } = useList<any>({
    resource: SHORTLIST_PACKAGE_DETAILS_URL,
    filters: [
      {
        field: 'package',
        operator: 'in',
        value: packageId,
      },
    ],
    queryOptions: { enabled: !!packageId },
  })

  useEffect(() => {
    let event_ids: number[] = []
    section_list?.data.forEach((record) => {
      event_ids.push(record.section as number)
    })
    setSectionIds(event_ids.join(','))
  }, [section_list])

  useEffect(() => {
    if (!!sections) {
      let tempDisable = sections.every(
        (value: SectionItemDetails) =>
          value.details.decor_date_time && value.details.dismantling_date_time,
      )
      setDisableGenerateSources(!tempDisable)
    }
  }, [sections])

  const { triggerExport, isLoading: exportLoading } =
    useExport<IMaterialSource>({
      resource: `${MATERIAL_SOURCE_URL}/all_events/`,
      mapData: (item) => {
        return {
          project_id: item.pid,
          date: `${dayjsForExport(item.date_time).format('LLL')}`,
          material: item.material?.name,
          event: item.package_detail_material?.package_detail.section.name,
          type: item.type,
          source: item.availability,
          warehouse: item.warehouse?.name ?? 'N/A',
          vendor: item.vendor?.name ?? 'N/A',
          quantity: item.qty,
          unit: item.uom,
          status: item.current_status.status ?? '',
        }
      },
      pageSize: 100,
      filters: filters,
    })

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: false,
  }

  const { selectProps: materialSelectProps } =
    useSelect<IMaterialCategoryOption>({
      resource: `${MATERIAL_URL}/material_category_options`,
      optionLabel: 'name',
      optionValue: 'name',
    })

  const { selectProps: eventSelectProps } = useSelect<ISection>({
    resource: SECTION_URL,
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: !!sectionIds,
    },
    filters: [
      {
        field: 'id',
        operator: 'in',
        value: sectionIds,
      },
    ],
  })

  const { data: deleteMaterialSourcePerm } = useCan({
    resource: 'delete_materialsource',
    action: '',
  })

  const { data: updateMaterialSoucePerm } = useCan({
    resource: 'change_materialsource',
    action: '',
  })

  const { data: addMaterialSourcePerm } = useCan({
    resource: 'add_materialsource',
    action: '',
  })

  const { data: modifyTrackingAfterStatusCreationPerm } = useCan({
    // permission to update/delete after status creation
    resource: 'modify_tracking_after_status_creation',
    action: '',
  })

  return (
    <>
      <>
        {/*
            Following logic checks whether all the Package Details have a valid decor_date_time and dismantling_date_time
            If valid decor_date_time or dismantling_date_time is present then only automatic Warehouse and Buy Option is 
            rendered otherwise nothing is rendered
        */}
        {sections && (
          <Space direction="vertical">
            <Space direction="horizontal">
              <Popconfirm
                title="Create Material Source for Warehouse"
                description="It will delete all tracking. Are you sure?"
                key="generateMaterials"
                onConfirm={generateMaterialSource}
                okText="Yes"
                disabled={disableGenerateSources || !addMaterialSourcePerm?.can}
                cancelText="No">
                <Button
                  disabled={
                    disableTracking ||
                    disableGenerateSources ||
                    !addMaterialSourcePerm?.can
                  }>
                  Warehouse
                </Button>
              </Popconfirm>

              {packageId && (
                <BuyMaterialModal
                  packageId={packageId}
                  refetchSources={refetchSources}
                  isDisabled={
                    disableTracking ||
                    disableGenerateSources ||
                    !addMaterialSourcePerm?.can
                  }
                />
              )}
            </Space>
            {disableGenerateSources && (
              <Typography.Text type="danger">
                Please update the Decor and Dismantling Date and Time
              </Typography.Text>
            )}
          </Space>
        )}
      </>
      <Table
        {...tableProps}
        rowSelection={rowSelection}
        loading={loadingSources || isCreatingMaterialSource}
        rowKey={'id'}
        title={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography.Text strong>Material Sources</Typography.Text>
            <Space direction="horizontal">
              <Form
                key="searchForm"
                {...searchFormProps}
                layout="inline"
                size="small">
                <Form.Item name="search">
                  <Input placeholder="Search Material Name" allowClear />
                </Form.Item>
              </Form>
              <RefreshButton onClick={() => refetchSources()} />
              <Button
                key="Create Inbound"
                onClick={generateInboundTracking}
                loading={exportLoading}
                disabled={
                  disableGenerateSources || !addMaterialSourcePerm?.can
                }>
                Create Inbound
              </Button>
              <ExportButton
                key="export"
                onClick={triggerExport}
                loading={exportLoading}
              />
              <Button
                onClick={() => {
                  const newFilters = filters.filter(
                    (value: CrudFilter) =>
                      (value as LogicalFilter).field === 'package_id',
                  )
                  setFilters(newFilters, 'replace')
                  setFilteredInfo({})
                  refetchSources()
                }}>
                Clear Filters
              </Button>
              {hasSelected && (
                <AddDistributionCentreModal
                  selectedRows={selectedRowKeys}
                  setSelectedRows={setSelectedRowKeys}
                  refetchSource={refetchSources}
                />
              )}
              {hasSelected && (
                <MarkPlanningPending
                  selectedRows={selectedRowKeys}
                  setSelectedRows={setSelectedRowKeys}
                  refetchSource={refetchSources}
                />
              )}
            </Space>
          </div>
        )}
        size="small"
        onChange={(pagination, filters, sorters, extra) => {
          let materialCategory = filters.material
          let dateTimeRange = filters.date_time
          delete filters.material
          delete filters.date_time
          setFilteredInfo(filters)
          tableProps?.onChange?.(
            pagination,
            {
              ...filters,
              type__in: filters.type ? (filters.type.join(',') as any) : null,
              warehouse__in: filters.warehouse
                ? (filters.warehouse?.join(',') as any)
                : null,
              material__category__in: materialCategory
                ? (materialCategory.join(',') as any)
                : null,
              vendor__in: filters.vendor
                ? (filters.vendor.join(',') as any)
                : null,
              date_time__range: dateTimeRange
                ? (dateTimeRange
                    .map((value: any) => value.format('YYYY-MM-DD'))
                    .join(',') as any)
                : null,
              availability__in: filters.availability__in
                ? (filters.availability__in.join(',') as any)
                : null,
              section__in: filters[
                'package_detail_material.package_detail.section.name'
              ]
                ? (filters[
                    'package_detail_material.package_detail.section.name'
                  ].join(',') as any)
                : null,
            },
            sorters,
            extra,
          )
        }}>
        <Table.Column
          title="Date"
          dataIndex="date_time"
          filteredValue={filteredInfo.date_time || null}
          filterResetToDefaultFilteredValue
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <DatePicker.RangePicker
                ranges={{
                  Today: [dayjs(), dayjs().add(1, 'day')],
                  'This Month': [
                    dayjs().startOf('month'),
                    dayjs().endOf('month').add(1, 'day'),
                  ],
                  'This Week': [
                    dayjs().startOf('week'),
                    dayjs().endOf('week').add(1, 'day'),
                  ],
                }}
              />
            </FilterDropdown>
          )}
          render={(value) => <DateField value={value} format={'LLL'} />}
        />
        <Table.Column<IMaterialSource>
          title="Material"
          dataIndex={['material']}
          filteredValue={filteredInfo.material || null}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                {...materialSelectProps}
                allowClear
                showSearch={false}
                mode="multiple"
                style={{
                  width: '200px',
                }}
              />
            </FilterDropdown>
          )}
          render={(value, record) => (
            <>
              <TextField value={value.name} />
            </>
          )}
        />
        <Table.Column
          title="Event"
          key="section"
          dataIndex={[
            'package_detail_material',
            'package_detail',
            'section',
            'name',
          ]}
          filteredValue={filteredInfo['section'] || null}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                {...eventSelectProps}
                allowClear
                showSearch={false}
                mode="multiple"
                style={{
                  width: '200px',
                }}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          title="Type"
          dataIndex="type"
          filteredValue={filteredInfo.type || null}
          filterResetToDefaultFilteredValue
          filters={[
            { text: 'To Event', value: 'To Event' },
            { text: 'From Event', value: 'From Event' },
            { text: 'At Event', value: 'At Event' },
          ]}
        />
        <Table.Column
          title="Source"
          filteredValue={filteredInfo.availability || null}
          dataIndex={['availability']}
          filters={[
            {
              text: MaterialAvailability.WAREHOUSE,
              value: MaterialAvailability.WAREHOUSE,
            },
            { text: MaterialAvailability.BUY, value: MaterialAvailability.BUY },
            {
              text: MaterialAvailability.RENT,
              value: MaterialAvailability.RENT,
            },
            {
              text: MaterialAvailability.VENUE,
              value: MaterialAvailability.VENUE,
            },
            {
              text: MaterialAvailability.WAREHOUSE_BUY,
              value: MaterialAvailability.WAREHOUSE_BUY,
            },
          ]}
          render={(value, record: any) => (
            <>
              {value}{' '}
              {record.warehouse_distribution_centre && (
                <>({record.warehouse_distribution_centre})</>
              )}
            </>
          )}
        />
        <Table.Column
          title="Warehouse"
          dataIndex={['warehouse']}
          filteredValue={filteredInfo.warehouse || null}
          filterResetToDefaultFilteredValue
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <SelectWithDefault
                useSelectProps={{
                  resource: WAREHOUSE_URL,
                  optionLabel: 'name',
                }}
                mode="multiple"
                popupMatchSelectWidth
                style={{
                  width: '200px',
                }}
              />
            </FilterDropdown>
          )}
          filterSearch
          render={(value) => value?.name ?? 'N/A'}
        />
        <Table.Column
          title="Vendor"
          dataIndex={['vendor']}
          filteredValue={filteredInfo.vendor || null}
          filterResetToDefaultFilteredValue
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <SelectWithDefault
                useSelectProps={{ resource: VENDOR_URL, optionLabel: 'name' }}
                mode="multiple"
                popupMatchSelectWidth
                style={{
                  width: '200px',
                }}
              />
            </FilterDropdown>
          )}
          render={(value) => value?.name ?? 'N/A'}
        />
        <Table.Column<IMaterialSource>
          title="Quantity"
          dataIndex="qty"
          render={(value, record) => <>{value}</>}
        />
        <Table.Column<IMaterialSource> title="Unit" dataIndex={['uom']} />
        <Table.Column<IMaterialSource>
          title="Status"
          render={(value, record) => (
            <Space>
              <MaterialStatusSelect
                source={record}
                refetchSources={refetchSources}
              />
            </Space>
          )}
        />
        <Table.Column<IMaterialSource>
          title="Actions"
          render={(_, record) => {
            return (
              <>
                {!(
                  record.current_status.status &&
                  !modifyTrackingAfterStatusCreationPerm?.can
                ) && (
                  <>
                    <DeleteButton
                      resource={MATERIAL_SOURCE_URL}
                      recordItemId={record.id as string}
                      size="small"
                      hideText
                      hidden={record.current_status.status !== 'BOM Finalized'}
                      disabled={!deleteMaterialSourcePerm?.can}
                      onSuccess={() => {
                        refetchSources()
                        invalidate({
                          resource: `${RFQ_URL}/${packageId}/get_pending_table`,
                          invalidates: ['list'],
                        })
                      }}
                    />
                    <Button
                      hidden={record.current_status.status !== 'BOM Finalized'}
                      icon={<Icons.EditOutlined />}
                      size="small"
                      onClick={() => {
                        setIsEditing(true)
                        setEditRecord(record.id)
                      }}
                      disabled={!updateMaterialSoucePerm?.can}
                    />
                  </>
                )}
                <NotesInput materialSourceId={record.id} />
              </>
            )
          }}
        />
      </Table>
      {isEditng && packageId && (
        <>
          <MaterialSourceModalForm
            packageId={packageId}
            recordId={editRecord}
            isEditing={isEditng}
            setIsEditing={setIsEditing}
            refetchSources={refetchSources}
          />
        </>
      )}
      {isCreating && packageId && (
        <>
          <MaterialSourceModalForm
            packageId={packageId}
            setIsCreating={setIsCreating}
            refetchSources={refetchSources}
          />
        </>
      )}
    </>
  )
}
