import { Card, Divider, Form, FormProps, Input, InputNumber, Select, Space, Switch } from "antd";
import { GridSelect } from 'components/input/GridSelect'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { FABRIC, FLORAL, STRUCTURE } from 'consts'
import {
  IArtificialRatio,
  IFabricType,
  IFoliageType,
  IPricingItem,
} from 'interfaces/pricing'
import {
  ARTIFICIAL_RATIO_URL,
  FABRIC_TYPE_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
  PRICING_ITEM_URL,
} from 'urls'

export const CombinationForm: React.FC<{
  formProps: FormProps
  queryResult: any
}> = ({ formProps, queryResult }) => {
  const category = Form.useWatch('category', formProps.form)

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Category" name="category">
        <Select
          options={[
            { label: FLORAL, value: FLORAL },
            { label: FABRIC, value: FABRIC },
            { label: STRUCTURE, value: STRUCTURE },
          ]}
        />
      </Form.Item>
      <Form.Item label="Item" name="item">
        <SelectWithDefault<IPricingItem>
          useSelectProps={{ resource: PRICING_ITEM_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item label="Flower" name={'flower'} style={{ flex: 0.7 }}>
        <GridSelect
          useTableProps={{ resource: FLOWER_URL }}
          title="Flowers"
          getName={(item) => `${item?.color.name} ${item?.flower_type.name}`}
          defaultQueryParam="?detailed=true"
        />
      </Form.Item>
      <Form.Item label="Foliage" name="foliage">
        <SelectWithDefault<IFoliageType>
          useSelectProps={{ resource: FOLIAGE_TYPE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item label="Fabric" name="fabric">
        <SelectWithDefault<IFabricType>
          useSelectProps={{ resource: FABRIC_TYPE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item label="Allowed Percentage" name="allowed_artificial_percents">
        <SelectWithDefault<IArtificialRatio>
          mode="multiple"
          useSelectProps={{
            resource: ARTIFICIAL_RATIO_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Space>
        <Form.Item label="UOM" name="uom">
          <Input />
        </Form.Item>
        <Form.Item label="Rate" name="rate">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Markup" name="margin">
          <InputNumber />
        </Form.Item>
      </Space>
      <Card title="Material Settings" size="small">
        {category === 'FLORAL' && <Divider>Real</Divider>}
        <Space>
          <Form.Item name="qty" label="QTY">
            <InputNumber />
          </Form.Item>
          <Form.Item name="material_uom" label="UOM">
            <Input />
          </Form.Item>
          <Form.Item
            name="is_independent"
            label="Is Independent"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Space>
        {category === 'FLORAL' && (
          <>
            <Divider>Artificial</Divider>
            <Space>
              <Form.Item name="artificial_qty" label="QTY">
                <InputNumber />
              </Form.Item>
              <Form.Item name="artificial_material_uom" label="UOM">
                <Input />
              </Form.Item>
              <Form.Item
                name="artificial_is_independent"
                label="Is Independent"
                valuePropName="checked">
                <Switch />
              </Form.Item>
            </Space>
          </>
        )}
      </Card>
      <Form.Item label="Visible" name="is_visible" valuePropName="checked">
        <Switch defaultChecked />
      </Form.Item>
    </Form>
  )
}
