import { useForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { BaseKey, useDelete, useInvalidate } from '@refinedev/core'
import { Button, Form, Input, InputNumber, Popconfirm } from 'antd'
import { ILineItemMaterial, IPackageDetailMaterial } from 'interfaces/quote'
import { useState } from 'react'
import {
  LINE_ITEM_MATERIAL_URL,
  PACKAGE_DETAIL_MATERIAL_URL,
  RFQ_URL,
} from 'urls'

export const MaterialQuantity = ({
  material,
  isLineMaterial,
  refetchMaterials,
  packageId,
  disabledEdit = false,
  disabledDelete = false,
}: {
  material: IPackageDetailMaterial | ILineItemMaterial
  isLineMaterial?: boolean
  refetchMaterials: CallableFunction
  packageId?: BaseKey
  disabledEdit?: boolean
  disabledDelete?: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const invalidate = useInvalidate()
  const { formProps, saveButtonProps } = useForm({
    resource: isLineMaterial
      ? LINE_ITEM_MATERIAL_URL
      : PACKAGE_DETAIL_MATERIAL_URL,
    id: material.id,
    action: 'edit',
    redirect: false,
    onMutationSuccess: () => {
      setIsEditing(false)
      refetchMaterials()
      invalidate({
        resource: `${RFQ_URL}/${packageId}/get_pending_table`,
        invalidates: ['list'],
      })
    },
    queryOptions: {
      enabled: isEditing,
    },
  })

  const { mutate: destroy } = useDelete()

  const handleDelete = () => {
    destroy(
      {
        resource: isLineMaterial
          ? LINE_ITEM_MATERIAL_URL
          : PACKAGE_DETAIL_MATERIAL_URL,
        id: material.id,
      },
      {
        onSuccess: () => {
          refetchMaterials()
        },
      },
    )
  }

  return (
    <>
      {isEditing ? (
        <Form {...formProps} initialValues={{}} size="small">
          <Form.Item
            name="qty"
            initialValue={material.qty}
            rules={[
              {
                required: true,
                message: 'Please input qty!',
              },
            ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="uom"
            initialValue={material.uom}
            rules={[
              {
                required: true,
                message: 'Please input a uom!',
              },
            ]}>
            <Input />
          </Form.Item>
        </Form>
      ) : (
        <>
          {material.qty} {material.uom}
        </>
      )}{' '}
      <Popconfirm
        title="Delete Material"
        description="Sure want to delete this Material?"
        key="save_material_for_product"
        onConfirm={() => handleDelete()}
        okText="Yes"
        cancelText="No">
        <Button
          size="small"
          icon={<Icons.DeleteOutlined />}
          disabled={disabledDelete}
        />
      </Popconfirm>
      <Button
        size="small"
        type="text"
        onClick={() => setIsEditing(!isEditing)}
        icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
        disabled={disabledEdit}
      />
      {isEditing && (
        <Button
          {...saveButtonProps}
          size="small"
          type="text"
          icon={<Icons.SaveOutlined />}
        />
      )}
    </>
  )
}
