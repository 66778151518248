import React, { useState, useEffect, useCallback } from 'react'
import {
  useCreate,
  useGetIdentity,
  useModal,
  useOne,
  useSelect,
} from '@refinedev/core'
import { Drawer, Button, Form, Card, Modal, FormInstance } from 'antd'
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { CRM_UPDATE_QUIZ, LEAD_URL, VENUE_URL } from 'urls'
import { InfoProps, OptionProps, Question, QuestionProps } from './Question'
import { ILeadQuizData } from 'interfaces/crm'
import {
  budgetSplitPercentage,
  CateringOptions,
  daysData,
  events,
  GuestCount,
  guestCounts,
  HotelCost,
  HotelOptions,
  meals,
  MealTypeKeys,
  PaxData,
  PaxInformation,
  TotalBudget,
  venueData,
  venueDecided,
  venueLocationSegments,
} from '../questionInterface'
import { VenueCost, CateringCost } from '../consts'
import { IProductShowcase } from 'interfaces/product'
import { IStaff } from 'interfaces/staff'
import { LeadServices } from './ServicesModal'
import { NotesInput } from './NotesInput'

const { confirm } = Modal

const PreSaleQuiz = ({ lead }: { lead: any }) => {
  const { show, close, visible } = useModal()

  return (
    <div>
      <Button
        size="small"
        type="primary"
        ghost
        icon={<QuestionCircleOutlined />}
        onClick={() => show()}>
        Update Quiz
      </Button>
      {visible && <QuizDrawer close={close} visible={visible} lead={lead} />}
    </div>
  )
}

const QuizDrawer = ({
  close,
  visible,
  lead,
}: {
  close: any
  visible: boolean
  lead: any
}) => {
  const [form] = Form.useForm()
  const [questions, setQuestions] = useState<QuestionProps[]>([])

  useEffect(() => {
    if (visible) {
      setQuestions([])
    }
  }, [visible])

  const handleClose = useCallback(() => {
    if (form.isFieldsTouched()) {
      confirm({
        title: 'Discard Changes?',
        content: 'Are you sure you want to discard your changes?',
        okText: 'Discard',
        okType: 'danger',
        cancelText: 'Continue Editing',
        onOk() {
          setQuestions([])
          form.resetFields()
          close()
        },
      })
    } else {
      setQuestions([])
      form.resetFields()
      close()
    }
  }, [close])

  return (
    <Drawer
      id={lead}
      title="Quiz Drawer"
      placement="right"
      onClose={handleClose}
      open={visible}
      destroyOnClose
      closable={false}
      width={700}
      extra={<Button onClick={handleClose}>Close</Button>}>
      {visible && (
        <LeadFlow
          questions={questions}
          setQuestions={setQuestions}
          form={form}
          close={close}
          lead={lead}
        />
      )}
    </Drawer>
  )
}

const LeadFlow = ({
  questions,
  setQuestions,
  form,
  close,
  lead,
}: {
  questions: QuestionProps[]
  setQuestions: React.Dispatch<React.SetStateAction<QuestionProps[]>>
  form: FormInstance<any>
  close: any
  lead: any
}) => {
  const [venueList, setVenueList] = useState<OptionProps[]>([])
  const { data: user } = useGetIdentity<IStaff>()
  const hotelBudgetSegment: OptionProps[] = [
    {
      title: '3 Star',
      value: '3 Star',
    },
    {
      title: '4 Star',
      value: '4 Star',
    },
    {
      title: '5 Star',
      value: '5 Star',
    },
    {
      title: '5 Star Plus',
      value: '5 Star Plus',
    },
  ]
  const venueBudgetSegment: OptionProps[] = [
    {
      title: '80K-3L',
      value: '80K-3L',
    },
    { title: '3L-7L', value: '3L-7L' },
    {
      title: '7L-13L',
      value: '7L-13L',
    },
    {
      title: 'More than 13L',
      value: 'more than 13L',
    },
  ]
  const leadQuestions: QuestionProps[] = [
    {
      question: 'Have you booked a venue?',
      type: 'radio',
      options: venueDecided,
      answer: '',
    },
  ]

  const vndTypeVenue: QuestionProps[] = [
    {
      question: 'What type of venue do you want?',
      type: 'radio',
      options: venueData,
      answer: '',
    },
  ]

  const vdTypeVenue: QuestionProps[] = [
    {
      question: 'What type of venue you have booked?',
      type: 'radio',
      options: venueData,
      answer: '',
    },
  ]

  const vdVenue: QuestionProps[] = [
    {
      question: 'Finally, what month?',
      type: 'datePicker',
      picker: 'month',
      answer: '',
    },
    {
      question: 'Venue Name',
      type: 'singleSelect',
      options: venueList,
      enableSearch: true,
      answer: '',
    },
    {
      question: 'How many days are you planning?',
      type: 'radio',
      options: daysData,
      answer: '',
    },
    {
      question: 'What events are you planning?',
      type: 'multiSelect',
      options: events,
      answer: '',
    },
    {
      question: 'Number of guests for each meal',
      type: 'multiQuestion',
      repeatable: true,
      repeatCount: 1,
      subQuestions: [
        {
          question: 'Breakfast',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Lunch',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'HighTea/Snacks',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Dinner',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
      ],
      answer: [],
    },
    {
      question: 'Select venue price range',
      type: 'radio',
      options: venueBudgetSegment,
      answer: '',
    },
  ]

  const vdHotel: QuestionProps[] = [
    {
      question: 'Finally, what month?',
      type: 'datePicker',
      picker: 'month',
      answer: '',
    },
    {
      question: 'Venue Name',
      type: 'singleSelect',
      options: venueList,
      enableSearch: true,
      answer: '',
    },
    {
      question: 'How many days are you planning?',
      type: 'radio',
      options: daysData,
      answer: '',
    },
    {
      question: 'What events are you planning?',
      type: 'multiSelect',
      options: events,
      answer: '',
    },
    {
      question: 'Number of guests for each meal',
      type: 'multiQuestion',
      repeatable: true,
      repeatCount: 1,
      subQuestions: [
        {
          question: 'Breakfast',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Lunch',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'HighTea/Snacks',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Dinner',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
      ],
      answer: [],
    },
    {
      question: 'Select venue price range',
      type: 'radio',
      options: hotelBudgetSegment,
      answer: '',
    },
  ]

  const vndVenue: QuestionProps[] = [
    {
      question: 'What area are you looking at?',
      type: 'radio',
      options: venueLocationSegments,
      answer: '',
    },
    {
      question: 'Finally, what month?',
      type: 'datePicker',
      picker: 'month',
      answer: '',
    },
    {
      question: 'How many days are you planning?',
      type: 'radio',
      options: daysData,
      answer: '',
    },
    {
      question: 'What events are you planning?',
      type: 'multiSelect',
      options: events,
      answer: '',
    },
    {
      question: 'Select venue price range',
      type: 'radio',
      options: venueBudgetSegment,
      answer: '',
    },
    {
      question: 'Number of guests for each meal',
      type: 'multiQuestion',
      repeatable: true,
      repeatCount: 1,
      subQuestions: [
        {
          question: 'Breakfast',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Lunch',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'HighTea/Snacks',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Dinner',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
      ],
      answer: [],
    },
    {
      question: 'How many guests require accommodation?',
      type: 'radio',
      options: guestCounts,
      answer: '',
    },
  ]

  const vndHotel: QuestionProps[] = [
    {
      question: 'What area are you looking at?',
      type: 'radio',
      options: venueLocationSegments,
      answer: '',
    },
    {
      question: 'Finally, what month?',
      type: 'datePicker',
      picker: 'month',
      answer: '',
    },
    {
      question: 'How many days are you planning?',
      type: 'radio',
      options: daysData,
      answer: '',
    },
    {
      question: 'What events are you planning?',
      type: 'multiSelect',
      options: events,
      answer: '',
    },
    {
      question: 'Select venue price range',
      type: 'radio',
      options: hotelBudgetSegment,
      answer: '',
    },
    {
      question: 'Number of guests for each meal',
      type: 'multiQuestion',
      repeatable: true,
      repeatCount: 1,
      subQuestions: [
        {
          question: 'Breakfast',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Lunch',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'HighTea/Snacks',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
        {
          question: 'Dinner',
          type: 'radio',
          options: guestCounts,
          answer: '',
          canReset: true,
        },
      ],
      answer: [],
    },
    {
      question: 'How many guests require accommodation?',
      type: 'radio',
      options: guestCounts,
      answer: '',
    },
  ]
  const { mutate: create, isSuccess } = useCreate()
  const [totalBudget, setTotalBudget] = useState<TotalBudget | null>(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [budgetSegment, setBudgetSegment] = useState<OptionProps[]>([])
  const [calBudget, setCalBudget] = useState<any>(false);
  let localBudgetSegment: OptionProps[] = []
  const { queryResult: venues } = useSelect<IProductShowcase>({
    resource: VENUE_URL,
    queryOptions: {
      enabled: !!user,
      onSuccess: useCallback((data) => {
        const formattedData: OptionProps[] = data?.data.map(
          (item: { name: any; id: any }) => ({
            title: item.name,
            value: item.name,
          }),
        )
        setVenueList(formattedData)
        vdHotel[1].options = formattedData
        vdVenue[1].options = formattedData
      }, []),
    },
    onSearch: (value: string) => [
      {
        field: 'search',
        operator: 'eq',
        value: value,
      },
    ],
    // filters: [
    //   {
    //     field: 'is_published',
    //     operator: 'eq',
    //     value: true,
    //   },
    // ],
  })

  const { data: leadQuizData, isFetching: leadQuizLoading } =
    useOne<ILeadQuizData>({
      resource: LEAD_URL,
      id: `${lead}/get_quiz_data`,
    })

  useEffect(() => {
    if (leadQuizData) {
      const jsonData = leadQuizData.data.json_data
      if (jsonData != null) {
        try {
          const parsedData = Array.isArray(jsonData)
            ? jsonData
            : JSON.parse(jsonData)
          setQuestions(
            Object.keys(parsedData).length === 0 ? leadQuestions : parsedData,
          )
        } catch (error) {
          console.error('Error parsing JSON data:', error)
          setQuestions(leadQuestions)
        }
      } else {
        setQuestions(leadQuestions)
      }
    } else {
      setQuestions(leadQuestions)
    }
  }, [leadQuizData])

  // Initialize form values
  useEffect(() => {
    const values = initializeFormValues(questions)
    form.setFieldsValue(values)
  }, [questions, form])

  // Handle dynamic changes in questions based on answers
  useEffect(() => {
    adjustQuestionsBasedOnAnswers(questions, setQuestions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    questions.find((q) => q.question === 'How many days are you planning?')
      ?.answer,
    questions.find((q) => q.question === 'What events are you planning?')
      ?.answer,
  ])

  const getFlowBasedOnVenueBooking = (
    answer: string,
    newQuestions: QuestionProps[],
    index: number,
  ): QuestionProps[] => {

    const isVenueBooked =
      newQuestions.find((q) => q.question === 'Have you booked a venue?')
        ?.answer === 'yes'

    if (index === 0) {
      return answer === 'yes'
        ? [newQuestions[0], ...vdTypeVenue]
        : [newQuestions[0], ...vndTypeVenue]
    }

    if (index === 1) {
      const venueTypeQuestions =
        answer === 'hotel_resort'
          ? isVenueBooked
            ? vdHotel
            : vndHotel
          : isVenueBooked
          ? vdVenue
          : vndVenue

      return [newQuestions[0], newQuestions[1], ...venueTypeQuestions]
    }

    if (index === 4 || index === 5) {
      const eventsQuestion = newQuestions.find(
        (q) => q.question === 'What events are you planning?',
      )
      const daysQuestion = newQuestions.find(
        (q) => q.question === 'How many days are you planning?',
      )

      const eventNames = eventsQuestion?.answer
      const repeatCount = Number(daysQuestion?.answer) || 1

      if (Array.isArray(eventNames)) {
        const eventSubQuestions: QuestionProps[] = eventNames.map((eGC) => ({
          question: eGC,
          type: 'radio',
          options: guestCounts,
          answer: '',
        }))

        const eventGuestPaxQuestion: QuestionProps = {
          question: 'Pax per Events',
          type: 'multiQuestion',
          repeatable: true,
          repeatCount: repeatCount,
          subQuestions: eventSubQuestions,
          answer: [],
        }

        const paxPerEventIndex = newQuestions.findIndex(
          (q) => q.question === 'Pax per Events',
        )

        if (paxPerEventIndex !== -1) {
          const updatedQuestions = [...newQuestions]
          updatedQuestions[paxPerEventIndex] = eventGuestPaxQuestion
          return updatedQuestions
        } else {
          return [...newQuestions, eventGuestPaxQuestion]
        }
      }
    }

    // Remove index checks for 4 and 5, handle adjustments elsewhere
    return newQuestions
  }

  const handleAnswerChange = useCallback((index: number, value: any) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions]
      newQuestions[index].answer = value

      return getFlowBasedOnVenueBooking(value, newQuestions, index)
    })
  }, [])
  //   setQuestions((prevQuestions) => {
  //     const newQuestions = prevQuestions.map((q, i) =>
  //       i === index ? { ...q, answer: value } : q,
  //     )

  //     const updatedQuestions = getFlowBasedOnVenueBooking(
  //       value,
  //       newQuestions,
  //       index,
  //     )

  //     const updatedQuestionText = newQuestions[index].question

  //     if (
  //       updatedQuestionText === 'How many days are you planning?' ||
  //       updatedQuestionText === 'What events are you planning?'
  //     ) {
  //       const daysAnswer = newQuestions.find(
  //         (q) => q.question === 'How many days are you planning?',
  //       )?.answer
  //       const repeatCount = Number(daysAnswer) || 1

  //       const eventsAnswer = newQuestions.find(
  //         (q) => q.question === 'What events are you planning?',
  //       )?.answer

  //       const eventSubQuestions: QuestionProps[] = Array.isArray(eventsAnswer)
  //         ? eventsAnswer.map((eGC) => ({
  //             question: eGC,
  //             type: 'radio',
  //             options: guestCounts,
  //             answer: '',
  //           }))
  //         : []

  //       const initializedAnswers = Array.from({ length: repeatCount }, () =>
  //         Array(eventSubQuestions.length).fill(''),
  //       )

  //       const paxPerEventsQuestionIndex = updatedQuestions.findIndex(
  //         (q) => q.question === 'Pax per Events',
  //       )

  //       const eventGuestPaxQuestion: QuestionProps = {
  //         question: 'Pax per Events',
  //         type: 'multiQuestion',
  //         repeatable: true,
  //         repeatCount: repeatCount,
  //         subQuestions: eventSubQuestions,
  //         answer: initializedAnswers,
  //       }

  //       if (paxPerEventsQuestionIndex !== -1) {
  //         updatedQuestions[paxPerEventsQuestionIndex] = eventGuestPaxQuestion
  //       } else {
  //         updatedQuestions.push(eventGuestPaxQuestion)
  //       }
  //     }

  //     return updatedQuestions
  //   })
  // }, [])

  // const handleAnswerChange = useCallback((index: number, value: any) => {
  //   setQuestions((prevQuestions) => {
  //     const newQuestions = prevQuestions.map((q, i) =>
  //       i === index ? { ...q, answer: value } : q
  //     );

  //     // Check if adjustments are needed
  //     const questionText = newQuestions[index].question;
  //     if (
  //       questionText === 'How many days are you planning?' ||
  //       questionText === 'What events are you planning?'
  //     ) {
  //       adjustQuestionsBasedOnAnswers(newQuestions, setQuestions);
  //     }

  //     return newQuestions;
  //   });
  // }, []);

  const handleSubAnswerChange = (
    questionIndex: number,
    repeatIndex: number,
    subIndex: number,
    value: any,
  ) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions]
      if (newQuestions[questionIndex].type === 'multiQuestion') {
        setCalBudget(!calBudget)
        if (!newQuestions[questionIndex].answer[repeatIndex]) {
          newQuestions[questionIndex].answer[repeatIndex] = []
        }
        newQuestions[questionIndex].answer[repeatIndex][subIndex] = value
        
      }
      return newQuestions
    })
  }

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
    }
    if (
      currentQuestionIndex ===
      questions.findIndex((q) => q.question === 'Pax per Events')
    ) {
      CalculateBudget()
    }
  }

  const handleSubmit = useCallback(() => {
    const quizData = {
      lead,
      json_data: questions,
    }

    create({
      resource: CRM_UPDATE_QUIZ,
      values: { quiz_data: quizData },
    })

    if (isSuccess) {
      form.resetFields()
      close()
    }
  }, [questions, create, isSuccess, form, close, lead])

  useEffect(() => {
    const info: InfoProps[][] = []
    if (budgetSegment.length > 0) {
      info[0] = [
        {
          title: 'Decor - Value Package',
          value:
            totalBudget?.value != null
              ? totalBudget.value * budgetSplitPercentage.decor
              : 0,
        },
        {
          title: 'Photography - Value Package',
          value:
            totalBudget?.value != null
              ? totalBudget.value * budgetSplitPercentage.photography
              : 0,
        },
        {
          title: 'Venue + Catering - Value Pacakge',
          value:
            totalBudget?.value != null
              ? totalBudget.value * budgetSplitPercentage.venueCatering
              : 0,
        },
      ]
      info[1] = [
        {
          title: 'Decor - Delight Package',
          value:
            totalBudget?.delight != null
              ? totalBudget.delight * budgetSplitPercentage.decor
              : 0,
        },
        {
          title: 'Photography - Delight Package',
          value:
            totalBudget?.delight != null
              ? totalBudget.delight * budgetSplitPercentage.photography
              : 0,
        },
        {
          title: 'Venue + Catering - Delight Package',
          value:
            totalBudget?.delight != null
              ? totalBudget.delight * budgetSplitPercentage.venueCatering
              : 0,
        },
      ]
      info[2] = [
        {
          title: 'Decor - Prime Package',
          value:
            totalBudget?.prime != null
              ? totalBudget.prime * budgetSplitPercentage.decor
              : 0,
        },
        {
          title: 'Photography - Prime Package',
          value:
            totalBudget?.prime != null
              ? totalBudget.prime * budgetSplitPercentage.photography
              : 0,
        },
        {
          title: 'Venue + Catering - Prime Package',
          value:
            totalBudget?.prime != null
              ? totalBudget.prime * budgetSplitPercentage.venueCatering
              : 0,
        },
      ]
      const selectCalculatedBudgetIndex = questions.findIndex(
        (q) => q.question === 'Select Calculated Budget',
      )
      if (selectCalculatedBudgetIndex === -1) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          {
            question: 'Select Calculated Budget',
            type: 'radio',
            options: budgetSegment,
            answer: '',
            info: info,
          },
        ])
      } else {
        setQuestions((prevQuestions) => {
          const newQuestions = [...prevQuestions]
          newQuestions[selectCalculatedBudgetIndex].options = budgetSegment
          newQuestions[selectCalculatedBudgetIndex].info = info
          return newQuestions
        })
      }
    }
  }, [totalBudget])

  useEffect(() => {
    const index = questions.findIndex((q) => q.question === 'Pax per Events');
    if (index != -1) {
      CalculateBudget()
    } else {
      return
    }
  }, [calBudget])

  const CalculateBudget = () => {
    let localGuestCount: GuestCount[][] = []
    let localTotalBudget: TotalBudget = {
      value: 0,
      prime: 0,
      delight: 0,
    }

    let cateringTotal: TotalBudget = {
      value: 0,
      prime: 0,
      delight: 0,
    }

    const getCount = (count: any) => {
      switch (count) {
        case '0-50':
          return '25'
        case '50-100':
          return '75'
        case '100-200':
          return '150'
        case '200-500':
          return '350'
        case '500-1000':
          return '750'
        case '1000+':
          return '1200'
        default:
          return '0'
      }
    }

    const guestCount = questions.find(
      (q) => q.question === 'Pax per Events',
    )?.answer

    guestCount.map((guest: any, index: number) => {
      let countGuest: GuestCount[] = []
      guest.map((count: string, index2: number) => {
        countGuest.push({
          day: (index + 1).toString(),
          meal: getMeal(events[index2].value) as MealTypeKeys,
          count: getCount(count),
          guestCountData: count,
        })
      })
      localGuestCount.push(countGuest)
    })

    const paxDataValue = convertGuestCountToPaxData(
      localGuestCount,
      HotelOptions['3 Star'],
      CateringOptions['Budget'],
    )
    const paxDataDelight = convertGuestCountToPaxData(
      localGuestCount,
      HotelOptions['4 Star'],
      CateringOptions['Semi-Premium'],
    )
    const paxDataPrime = convertGuestCountToPaxData(
      localGuestCount,
      HotelOptions['5 Star'],
      CateringOptions['Premium'],
    )

    let days = Number.isNaN(
      parseInt(
        questions.find((q) => q.question === 'How many days are you planning?')
          ?.answer,
        10,
      ),
    )
      ? 8
      : parseInt(
          questions.find(
            (q) => q.question === 'How many days are you planning?',
          )?.answer,
          10,
        )
    if (questions[1].answer != 'hotel_resort') {
      cateringTotal.value =
        calculateTotalCateringCost(paxDataValue) +
        VenueCost.Select['24 Hrs'] * days
      localTotalBudget.value = localTotalBudget.value + cateringTotal.value

      cateringTotal.prime =
        calculateTotalCateringCost(paxDataDelight) +
        VenueCost.Prime['24 Hrs'] * days
      localTotalBudget.prime += cateringTotal.prime

      cateringTotal.delight =
        calculateTotalCateringCost(paxDataPrime) +
        VenueCost.Delight['24 Hrs'] * days
      localTotalBudget.delight += cateringTotal.delight
    } else {
      localTotalBudget.value = calculateTotalv2(
        paxDataValue,
        questions[1].answer === 'hotel_resort' ? 'hotelTotal' : 'cateringTotal',
      )
      localTotalBudget.prime = calculateTotalv2(
        paxDataPrime,
        questions[1].answer === 'hotel_resort' ? 'hotelTotal' : 'cateringTotal',
      )
      localTotalBudget.delight = calculateTotalv2(
        paxDataDelight,
        questions[1].answer === 'hotel_resort' ? 'hotelTotal' : 'cateringTotal',
      )
    }

    localTotalBudget.value =
      localTotalBudget.value / budgetSplitPercentage.venueCatering
    localTotalBudget.delight =
      localTotalBudget.delight / budgetSplitPercentage.venueCatering
    localTotalBudget.prime =
      localTotalBudget.prime / budgetSplitPercentage.venueCatering

    setTotalBudget({
      value: localTotalBudget.value,
      delight: localTotalBudget.delight,
      prime: localTotalBudget.prime,
    })

    localTotalBudget.value =
      localTotalBudget.value -
      localTotalBudget.value * budgetSplitPercentage.miscellaneous
    localTotalBudget.delight =
      localTotalBudget.delight -
      localTotalBudget.delight * budgetSplitPercentage.miscellaneous
    localTotalBudget.prime =
      localTotalBudget.prime -
      localTotalBudget.prime * budgetSplitPercentage.miscellaneous

    localBudgetSegment = [
      {
        title: `Value - ${localTotalBudget.value.toString()}`,
        value: localTotalBudget.value.toString(),
      },
      {
        title: `Delight -${localTotalBudget.delight.toString()}`,
        value: localTotalBudget.delight.toString(),
      },
      {
        title: `Prime - ${localTotalBudget.prime.toString()}`,
        value: localTotalBudget.prime.toString(),
      },
    ]
    setBudgetSegment(localBudgetSegment)
  }

  const getMeal = (eventName: any) => {
    switch (eventName) {
      case 'wedding':
      case 'baby_shower':
      case 'haldi_mehendi':
      case 'engagement':
      case 'sangeet':
        return MealTypeKeys.Lunch
      case 'reception':
        return MealTypeKeys.Dinner
    }
  }

  const calculateTotalv2 = (
    paxData: PaxData,
    totalKey: 'hotelTotal' | 'cateringTotal',
  ): number => {
    return Object.values(paxData)
      .map((dayWiseInformation: { [key: string]: PaxInformation }) => {
        return Object.values(dayWiseInformation).reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue[totalKey],
          0.0,
        )
      })
      .reduce((prev, curr) => prev + curr, 0.0)
  }

  const convertGuestCountToPaxData = (
    guestCounts: GuestCount[][],
    hotelOption: HotelOptions,
    cateringOption: CateringOptions,
  ): PaxData => {
    const paxData: PaxData = {}
    guestCounts.forEach((dayCounts, dayIndex) => {
      const dayKey = `Day ${dayIndex + 1}`
      paxData[dayKey] = {}
      dayCounts.forEach((count) => {
        if (!count.count) {
          return
        }
        paxData[dayKey][count.meal] = {
          mealType: count.meal,
          pax: parseInt(count.count),
          hotelCost: HotelCost[HotelOptions[hotelOption]][count.meal],
          hotelTotal:
            parseInt(count.count) *
            HotelCost[HotelOptions[hotelOption]][count.meal],
          hotelOption: HotelOptions[hotelOption],
          cateringOption: cateringOption,
          cateringCost: CateringCost[cateringOption][count.meal],
          cateringTotal:
            parseInt(count.count) * CateringCost[cateringOption][count.meal],
        }
      })
    })
    return paxData
  }

  const calculateTotalCateringCost = (paxData: PaxData): number => {
    let totalCateringCost = 0
    for (const dayKey in paxData) {
      if (paxData.hasOwnProperty(dayKey)) {
        const day = paxData[dayKey]
        for (const mealKey in day) {
          if (day.hasOwnProperty(mealKey)) {
            totalCateringCost += day[mealKey].cateringTotal
          }
        }
      }
    }
    return totalCateringCost
  }

  return (
    <>
      {leadQuizLoading ? (
        <LoadingOutlined />
      ) : (
        <Form form={form} layout="vertical">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}>
            <div>
              {questions.length === 1 && (
                <Button onClick={handleNextQuestion}>Next</Button>
              )}
            </div>
            <div>{lead && <NotesInput leadId={lead} />}</div>
          </div>
          <Card>
            {questions.map((currentQuestion, index) => {
              if (currentQuestion.question == 'Number of guests for each meal')
                return
              else
                return (
                  <Question
                    key={index}
                    index={index.toString()}
                    question={currentQuestion.question}
                    type={currentQuestion.type}
                    options={currentQuestion.options}
                    enableSearch={currentQuestion.enableSearch}
                    subQuestions={currentQuestion.subQuestions}
                    picker={currentQuestion.picker}
                    answer={currentQuestion.answer}
                    info={currentQuestion.info}
                    onChange={(value: any) => handleAnswerChange(index, value)}
                    onSubChange={(
                      repeatIndex: number,
                      subIndex: number,
                      value: any,
                    ) =>
                      handleSubAnswerChange(index, repeatIndex, subIndex, value)
                    }
                    repeatCount={currentQuestion.repeatCount}
                  />
                )
            })}
          </Card>
          <div
            style={{
              marginTop: 16,
            }}>
            <LeadServices leadId={lead} />
          </div>
          <div>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}

// Utility function to initialize form values
const initializeFormValues = (questions: QuestionProps[]) => {
  const values: { [key: string]: any } = {}

  questions.forEach((item, index) => {
    const key = `${item.question}_${index}`

    if (item.type === 'multiQuestion' && item.repeatable) {
      const repeatCount = item.repeatCount || 1
      for (let ind = 0; ind < repeatCount; ind++) {
        item.subQuestions?.forEach(
          (subItem: QuestionProps, subIndex: number) => {
            const subKey = `${subItem.question}_${ind}_${subIndex}`
            values[subKey] = item.answer?.[ind]?.[subIndex]?.toString() || ''
          },
        )
      }
    } else {
      values[key] = item.answer
    }
  })

  return values
}

// Utility function to adjust questions based on answers

const adjustQuestionsBasedOnAnswers = (
  questions: QuestionProps[],
  setQuestions: React.Dispatch<React.SetStateAction<QuestionProps[]>>,
) => {
  const daysQuestion = questions.find(
    (q) => q.question === 'How many days are you planning?',
  )
  const eventsQuestion = questions.find(
    (q) => q.question === 'What events are you planning?',
  )
  const daysAnswer = Number.isNaN(parseInt(daysQuestion?.answer, 10))
    ? 8
    : parseInt(daysQuestion?.answer, 10)
  const eventNames = eventsQuestion?.answer || []

  const cateringQuestionIndex = questions.findIndex(
    (q) => q.question === 'Number of guests for each meal',
  )

  const paxPerEventsQuestionIndex = questions.findIndex(
    (q) => q.question === 'Pax per Events',
  )

  if (cateringQuestionIndex !== -1) {
    const cateringQuestion = questions[cateringQuestionIndex]

    // Preserve existing answers, adjusting for new repeatCount or subQuestions
    const existingAnswers = cateringQuestion.answer || []
    const updatedAnswers = Array.from({ length: daysAnswer }, (_, dayIndex) => {
      const dayAnswers = existingAnswers[dayIndex] || []
      return (cateringQuestion.subQuestions || []).map(
        (_, subQuestionIndex) => dayAnswers[subQuestionIndex] || '',
      )
    })

    // Update the catering question
    const updatedCateringQuestion = {
      ...cateringQuestion,
      repeatCount: daysAnswer,
      answer: updatedAnswers,
    }

    // Update the questions array
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions]
      newQuestions[cateringQuestionIndex] = updatedCateringQuestion
      return newQuestions
    })
  }

  if (paxPerEventsQuestionIndex !== -1) {
    const existingPaxQuestion = questions[paxPerEventsQuestionIndex]

    // Create new subQuestions based on eventNames
    const eventSubQuestions: QuestionProps[] = eventNames.map((eGC: any) => ({
      question: eGC,
      type: 'radio',
      options: guestCounts,
      answer: '',
      canReset: true,
    }))

    // Preserve existing answers, adjusting for new repeatCount or subQuestions
    const existingAnswers = existingPaxQuestion.answer || []
    const updatedAnswers = Array.from({ length: daysAnswer }, (_, dayIndex) => {
      const dayAnswers = existingAnswers[dayIndex] || []
      return eventSubQuestions.map(
        (_, eventIndex) => dayAnswers[eventIndex] || '',
      )
    })

    // Update the "Pax per Events" question
    const updatedPaxQuestion = {
      ...existingPaxQuestion,
      repeatCount: daysAnswer,
      subQuestions: eventSubQuestions,
      answer: updatedAnswers,
    }

    // Update the questions array
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions]
      newQuestions[paxPerEventsQuestionIndex] = updatedPaxQuestion
      return newQuestions
    })
  }
}

export default PreSaleQuiz
