import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IFlower } from 'interfaces/pricing'
import { FlowerForm } from './Form'

export const FlowerEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IFlower>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <FlowerForm formProps={formProps} queryResult={queryResult} />
    </Edit>
  )
}
