import { Form, Modal } from "antd";
import { SelectWithDefault } from 'components/input/SelectWithDefault'

export const QuoteModal = (props: any) => {
  const {
    formProps,
    modalProps,
    rowSelection: { selectedRowKeys },
    packageId,
  } = props
  return (
    <Modal {...modalProps} destroyOnClose title="Create Quote">
      <Form
        {...formProps}
        layout="vertical"
        preserve={false}
        onFinish={(values) => {
          formProps.onFinish &&
            formProps.onFinish(
              selectedRowKeys.map((id: string) => ({
                ...values,
                package: packageId,
                item: id,
              })),
            )
        }}>
        <Form.Item label="Vendor" name="vendor">
          <SelectWithDefault
            useSelectProps={{
              resource: 'core/vendors',
              optionLabel: 'name',
              optionValue: 'id',
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
