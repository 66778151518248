// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Card, Space, Spin, notification } from "antd";
import { BaseKey } from "@refinedev/core";
import { useForm } from 'components/hooks'
import { ITask } from 'interfaces/customer'
import React, { useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TASK_URL } from 'urls'
import { TaskForm } from './Form'
import { TaskEditContext } from './List'

export const TaskEdit: React.FC<{
  pid: BaseKey
  id: BaseKey
  setIsEditing: (isEditing: boolean) => void
}> = ({ pid, id, setIsEditing }) => {
  const { setIsEditing: setGlobalIsEditing } = useContext(TaskEditContext)

  const taskFormProps = useForm<ITask>({
    action: 'edit',
    resource: TASK_URL,
    id: id,
    redirect: false,
    onMutationSuccess: () => {
      setIsEditing(false)
      setGlobalIsEditing!(false)
    },
  })

  useHotkeys('ctrl+s, cmd+s', (e) => {
    e.preventDefault()
    notification.info({
      message: 'Saving',
      duration: 1,
      description: 'Save in progress',
    })
    taskFormProps.formProps!.form!.submit()
  })

  return (
    <Card
      size="small"
      extra={
        <Space>
          {taskFormProps.formLoading && <Spin size="small" />}
          <Button
            size="small"
            onClick={() => setIsEditing(false)}
            icon={<Icons.CloseOutlined />}
          />
          <Button
            size="small"
            onClick={() => taskFormProps.form.submit()}
            icon={<Icons.SaveOutlined />}
          />
        </Space>
      }>
      <TaskForm
        useFormProps={taskFormProps}
        pid={Number(pid)}
        showSave={false}
      />
    </Card>
  )
}
