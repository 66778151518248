import { BaseKey } from '@refinedev/core'
import { IMaterial } from './material'
import { IStaff } from './staff'
import { IVendor } from './vendor'
import { IWarehouse } from './wms'

export enum PurchaseOrderStatus {
  PENDING_APPROVAL = 'Pending Approval',
  APPROVED = 'Approved',
  PROCESSING = 'Processing',
  PARTIALLY_RECEIVED = 'Partially Received',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  ON_HOLD = 'On Hold',
}

export interface IPurchaseOrder {
  id: BaseKey
  vendor?: BaseKey | IVendor
  warehouse?: BaseKey | IWarehouse
  order_date?: string
  expected_delivery_date?: string
  status: PurchaseOrderStatus
  total_amount?: number
  order_by?: BaseKey
  approved_by?: BaseKey | IStaff
  approval_date?: string
  is_approved?: boolean
  payment_terms?: string
  payment_due_date?: string
  payment_status?: string
}

export interface IPurchaseOrderItem {
  id: BaseKey
  purchase_order?: BaseKey
  material?: number | IMaterial
  quantity_ordered?: number
  quantity_delivered?: number
  unit_price?: number
  subtotal: number
}
