import { ImageField, useModal } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  FormProps,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd'

import {
  BaseKey,
  file2Base64,
  useApiUrl,
  useCan,
  useCreate,
  useDelete,
  useMany,
  useUpdate,
} from '@refinedev/core'
import { AutoGeneratedPasswordInput, ColorPicker, SingleImageUpload } from 'components/input'
import { ICustomer } from 'interfaces/customer'
import { IStaff } from 'interfaces/staff'
import { useEffect, useState } from 'react'
import { REGION_URL, VENDOR_MEDIA, VENDOR_URL } from 'urls'
import { createOptionsFromEnum, generatePassword } from 'utils/common'
import { VendorPaymentsTab } from './PaymentsTab'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import Dragger from 'antd/es/upload/Dragger'
import { REGION_KEY, TOKEN_KEY } from 'authProvider'
import { InboxOutlined } from '@ant-design/icons'

import ReactPlayer from 'react-player'
import { DeleteButton } from 'components/button'
import { VendorMedia } from 'interfaces/vendor'
import { ServicePackageType } from 'interfaces/venue'
import ReactQuill from 'react-quill'

const isImage = (url: string) => {
  const imageExtensions = [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'bmp',
    'webp',
    'tiff',
    'svg',
  ]
  const extension = url.split('.').pop()?.toLowerCase()
  return extension ? imageExtensions.includes(extension) : false
}

export const VendorForm: React.FC<{
  formProps: FormProps
  id?: BaseKey
  queryResult?: any
}> = ({ formProps, id, queryResult }) => {
  const [generatedPassword, setGeneratedPassword] = useState<string>('')
  const [portfolioImageFileList, setPortfolioImageFileListeList] = useState<
    UploadFile[]
  >([])
  const { data: membersData } = useMany<ICustomer>({
    resource: 'auth/users',
    ids: queryResult?.data?.data?.members,
    queryOptions: {
      enabled: !!queryResult?.data?.data?.members.length,
    },
  })
  const apiUrl = useApiUrl()

  const { modalProps, show, close } = useModal()

  const { mutate: vendorPost } = useCreate()
  const authToken = localStorage.getItem(TOKEN_KEY)
  const region = localStorage.getItem(REGION_KEY)
  const { mutate: destroy, isLoading } = useDelete()

  const { mutate: changePassword, isLoading: changePasswordIsLoading } =
    useUpdate<IStaff>()

  const props = {
    name: 'file',
    multiple: true,
    action: `${apiUrl}/${VENDOR_MEDIA}/`, // Your API endpoint for file upload
    data: (file: any) => ({ media: file, vendor: id }),
    headers: {
      Authorization: `Token ${authToken}`,
      'X-Region': region || '',
      'X-Source': 'studio_web',
    },
    beforeUpload: (file: any) => {
      const isMedia =
        file.type.startsWith('image/') ||
        file.type.startsWith('video/') ||
        file.type.startsWith('audio/')
      if (!isMedia) {
        message.error(`${file.name} is not a valid media file`)
        return Upload.LIST_IGNORE // Prevent file from uploading
      }
      return true // Allow the upload
    },
    onChange(info: any) {
      const { status } = info.file
      if (status === 'done') {
        queryResult.refetch()
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  useEffect(() => {
    const existingFiles: UploadFile[] = []
    queryResult?.data?.data?.portfolio_images?.map((image: any) =>
      existingFiles.push({
        uid: image.id.toString(),
        name: 'test.png',
        url: image.image,
        thumbUrl: image.image,
        status: 'done',
      }),
    )
    setPortfolioImageFileListeList(existingFiles)
  }, [queryResult])

  const handleRemoveMember = (member_id: string) => {
    vendorPost(
      {
        resource: `core/vendors/${id}/remove_member`,
        values: {
          member_id,
        },
      },
      { onSuccess: () => queryResult.refetch() },
    )
  }

  const handleDefaultAccount = (bene_id: string) => {
    vendorPost(
      {
        resource: `${VENDOR_URL}/${id}/mark_account_default`,
        values: {
          beneficiary_id: bene_id,
        },
      },
      { onSuccess: () => queryResult.refetch() },
    )
  }

  const { data: payVendor } = useCan({
    resource: 'pay_vendor',
    action: '',
  })
  const [fileList, setFileList] = useState<any[]>([])

  const onFinish = async (values: any) => {
    let coverImage = ''
    let profileImage = ''
    const imageFiles: any[] = []
    const { portfolio_cover_image } = values
    const { profile_image } = values

    // handle portfolio Images
    for (let i = 0; i < portfolioImageFileList?.length; i++) {
      if (portfolioImageFileList[i].originFileObj) {
        const base64String = await file2Base64(portfolioImageFileList[i])
        imageFiles.push({
          image: base64String,
        })
      } else {
        imageFiles.push({
          // @ts-ignore
          id: portfolioImageFileList[i].id ?? portfolioImageFileList[i].uid,
        })
      }
    }

    // handle portfolio Cover Images
    delete values.portfolio_cover_image
    if (portfolio_cover_image) {
      const file = portfolio_cover_image[portfolio_cover_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    // handle Profile Image
    delete values.profile_image
    if (profile_image) {
      const file = profile_image[profile_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        profileImage = base64String
      }
    }

    const finalResponse = { ...values }
    if (coverImage.length) finalResponse['portfolio_cover_image'] = coverImage
    if (profileImage.length) finalResponse['profile_image'] = profileImage

    if (imageFiles.length) finalResponse['portfolio_images'] = imageFiles

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic" key={'basic'}>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Artist Name" name="artist_name">
            <Input />
          </Form.Item>
          <Form.Item label="Cashfree Beneficiary ID" name="beneficiary_id">
            <Input />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone_number">
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="PAN Number" name="pan_number">
            <Input />
          </Form.Item>
          <Form.Item label="Region" name="region">
            <SelectWithDefault
              useSelectProps={{
                resource: REGION_URL,
                optionLabel: 'name',
              }}
            />
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Select
              options={createOptionsFromEnum(ServicePackageType)}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item name="write_up" label="Write Up" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
        </Tabs.TabPane>
        {!!queryResult?.data?.data?.members.length && (
          <Tabs.TabPane tab="Members" key={'members'}>
            <Row>
              {membersData?.data.map((member) => (
                <Col key={member.id} xs={12} sm={8} md={6} lg={6} xl={6}>
                  <Card
                    actions={[
                      <Button
                        key="remove"
                        onClick={() => handleRemoveMember(member.id)}
                        icon={<Icons.DeleteOutlined />}
                        danger
                        size="small">
                        Remove
                      </Button>,
                      <Button
                        key="change password"
                        onClick={() => show()}
                        icon={<Icons.EditOutlined />}
                        size="small">
                        Password
                      </Button>,
                    ]}
                    title={member.username}>
                    <Space>
                      <Typography.Text>{member.first_name}</Typography.Text>
                      <Typography.Text>{member.last_name}</Typography.Text>
                    </Space>
                    <Modal
                      onOk={() => {
                        changePassword(
                          {
                            resource: 'core/vendors',
                            id: `${member.id}/change_password`,
                            values: { password: generatedPassword },
                          },
                          {
                            onSuccess: () => {
                              setGeneratedPassword('')
                              close()
                            },
                          },
                        )
                      }}
                      {...modalProps}
                      confirmLoading={changePasswordIsLoading}>
                      <Space direction="vertical">
                        <Typography.Title level={5}>
                          Generating password for {member.username}
                        </Typography.Title>
                        <AutoGeneratedPasswordInput
                          value={generatedPassword}
                          onChange={setGeneratedPassword}
                          onClick={() =>
                            setGeneratedPassword(generatePassword())
                          }
                        />
                      </Space>
                    </Modal>
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        )}
        {!!queryResult?.data?.data?.beneficiaries.length && (
          <Tabs.TabPane tab="Beneficiaries" key={'beneficiary'}>
            <AntdList
              dataSource={queryResult?.data?.data?.beneficiaries}
              renderItem={(item: any) => (
                <AntdList.Item
                  actions={[
                    <Switch
                      key="default"
                      disabled={item.is_default}
                      checked={item.is_default}
                      onChange={() => handleDefaultAccount(item.id)}
                    />,
                  ]}>
                  <AntdList.Item.Meta
                    title={item.account_type}
                    description={
                      item.account_type === 'vpa' ? (
                        <Typography.Text>
                          VPA: {item.vpa_address}
                        </Typography.Text>
                      ) : (
                        <p>
                          <Typography.Text>
                            Name: {item.account_name}
                          </Typography.Text>
                          <br />
                          <Typography.Text>IFSC: {item.ifsc}</Typography.Text>
                          <br />
                          <Typography.Text>
                            Account no: {item.account_number}
                          </Typography.Text>
                        </p>
                      )
                    }
                  />
                </AntdList.Item>
              )}
            />
          </Tabs.TabPane>
        )}
        {id && !!queryResult?.data?.data && payVendor?.can && (
          <Tabs.TabPane tab="Payments" key={'payments'}>
            <VendorPaymentsTab vendorId={id} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Portfolio" key={'portfolio'}>
          <SingleImageUpload
            label="Cover Image"
            name="portfolio_cover_image"
            initialImageUrl={queryResult?.data?.data?.portfolio_cover_image}
          />
          <SingleImageUpload
            label="Profile Image"
            name="profile_image"
            initialImageUrl={queryResult?.data?.data?.profile_image}
          />
          <Form.Item
              label="Color"
              initialValue="#fff"
              name="color"
              >
            <ColorPicker />
          </Form.Item>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files.
            </p>
          </Dragger>
          <List
            dataSource={queryResult?.data?.data.portfolio_images}
            grid={{ gutter: 16, column: 2 }}
            renderItem={(vendorMedia: VendorMedia) => (
              <List.Item
                actions={[
                  <DeleteButton
                    key="delete"
                    onClick={() => {
                      destroy(
                        { resource: VENDOR_MEDIA, id: vendorMedia.id },
                        { onSuccess: () => queryResult.refetch() },
                      )
                    }}
                  />,
                ]}>
                {vendorMedia.media && isImage(vendorMedia.media) ? (
                  <ImageField value={vendorMedia.media} />
                ) : (
                  vendorMedia.media && (
                    <ReactPlayer
                      pip={false}
                      config={{}}
                      controls
                      url={vendorMedia.media}
                    />
                  )
                )}
              </List.Item>
            )}
          />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
