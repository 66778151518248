import { BaseKey, useCreate, useModal } from '@refinedev/core'
import { Button, Divider, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { MenuForm } from '../menu/Form'
import { MENU_URL } from 'urls'
import { useForm } from 'components/hooks/useForm'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

const AddMenu: React.FC<{
  sessionId: BaseKey
  refetch: any
}> = ({ sessionId, refetch }) => {
  const [selecteMenuId, setSelectedMenuId] = useState<Number>()
  const {
    visible: visibleChangeDescription,
    show: showModal,
    close: closeModal,
  } = useModal()

  const { formProps, form } = useForm<any>({
    action: 'create',
    resource: MENU_URL,
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      form.resetFields()
      closeModal()
    },
  })

  const { mutate: clone } = useCreate()

  return (
    <div>
      <Button onClick={() => showModal()}>Add Menu</Button>
      <Modal
        open={visibleChangeDescription}
        title="Clone or Create Menu"
        onOk={() => {
          if (selecteMenuId) {
            clone(
              {
                resource: `cms/menus/${selecteMenuId}/clone_for_session`,
                values: { session_id: sessionId },
              },
              {
                onSuccess: () => {
                  setSelectedMenuId(undefined)
                  closeModal()
                  refetch()
                },
              },
            )
          } else {
            formProps.form?.submit()
          }
        }}
        onCancel={() => {
          closeModal()
        }}>
        <SelectWithDefault
          size="small"
          placeholder="Event"
          dropdownMatchSelectWidth={false}
          useSelectProps={{
            resource: MENU_URL,
            optionLabel: 'name',
            filters: [{ field: 'is_template', operator: 'eq', value: true }],
          }}
          onChange={(value) => {
            setSelectedMenuId(value)
          }}
        />
        <Divider>OR</Divider>
        <MenuForm
          isTemplate={false}
          formProps={formProps}
          sessionId={sessionId}
        />
      </Modal>
    </div>
  )
}

export default AddMenu
