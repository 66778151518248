import {
  CreateButton,
  ImageField,
  List,
  NumberField,
  TextField,
  useModalForm,
  useSelect,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin, Table } from "antd";
import { useDeleteMany, useList, useMany, useRouterContext } from "@refinedev/core";
import { DeleteButton, LogButton } from 'components/button'
import { ICelebration } from 'interfaces/celebration'
import { ICustomer, IEvent } from 'interfaces/customer'
import { IPackage, IPackageItems } from 'interfaces/package'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import { useState } from 'react'
import { usePackageTableReturnType } from './List'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SERVICE_URL } from 'urls'

function ShortlistPackageModalForm(props: any) {
  const { modalProps, formProps } = props.modalFormProps
  const [selectedCustomer, setSelectedCustomer] = useState<string>()
  const { selectProps: customerSelectProps, queryResult: customerQueryResult } =
    useSelect<ICustomer>({
      resource: 'package_tool/customers/',
      optionLabel: 'username',
      optionValue: 'id',

      onSearch: (value) => [
        {
          field: 'search',
          operator: 'eq',
          value,
        },
      ],

      pagination: {
        mode: "server"
      }
    })

  const { selectProps: eventSelectProps } = useSelect<IEvent>({
    resource: `package_tool/customers/${selectedCustomer}/events`,
    optionLabel: 'name',
    optionValue: 'id',

    queryOptions: {
      enabled: !!selectedCustomer,
    },

    pagination: {
      mode: "server"
    }
  })

  return (
    <Modal {...modalProps} destroyOnClose title="Shortlist Package">
      <Form
        {...formProps}
        preserve={false}
        layout="vertical"
        onFinish={(values) => {
          formProps.onFinish &&
            formProps.onFinish({
              ...values,
              package: props.selectedPackage.id,
            })
        }}>
        <Form.Item name="customer" label="Customer">
          <Select
            {...customerSelectProps}
            options={undefined}
            style={{ width: '100%' }}
            onChange={(value) => setSelectedCustomer(value as any)}>
            {customerQueryResult.data?.data.map((customer) => (
              <Select.Option key={customer.id} value={customer.id}>
                {customer.first_name} {customer.last_name} - (
                {customer.username})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          key="create"
          icon={<Icons.UserAddOutlined />}
          style={{ marginTop: -10, marginBottom: 10 }}
          onClick={() => window.open('/package_tool/customers/create')}>
          Customer
        </Button>
        <Form.Item name="event" label="Project">
          <Select {...eventSelectProps} />
        </Form.Item>
        <Form.Item name="service" label="Service" rules={[{ required: true }]}>
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          initialValue={props.selectedPackage?.name}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const PackageTable: React.FC<{
  tProps: usePackageTableReturnType
}> = ({ tProps }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPackage, setSelectedPackage] = useState<IPackage>()
  const { mutate } = useDeleteMany()

  const { Link } = useRouterContext()

  const { data: celebrationsData, isLoading: celebrationIsLoading } =
    useList<ICelebration>({
      resource: 'package_tool/celebrations',
    })

  const { data: packageProductData, isLoading: packageProductDataIsLoading } =
    useMany<IPackageItems>({
      resource: 'package_tool/packages',
      ids:
        tProps.tableQueryResult.data?.data.map(
          (p) => `${p.id}/get_line_items`,
        ) ?? [],
      queryOptions: {
        enabled: tProps.tableQueryResult.isFetched,
      },
    })

  const modalFormProps = useModalForm<IShortlistPackage>({
    action: 'create',
    resource: 'package_tool/shortlist_packages',
  })

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'package_tool/packages',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <LogButton key={'log'} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small">
        <Table.Column<IPackage>
          title="Image"
          ellipsis
          render={(_, record) => {
            if (packageProductDataIsLoading) return <Spin />

            const packageData = packageProductData?.data.find(
              (p) => p.package === String(record.id),
            )
            return (
              <Row gutter={[0, 16]}>
                {packageData?.items.map((item) => (
                  <Col xs={12} sm={8} md={8} lg={8} xl={8} key={item.id}>
                    <ImageField
                      value={item.product.images[0].image}
                      height={75}
                      width={75}
                    />
                  </Col>
                ))}
              </Row>
            )
          }}
        />
        <Table.Column<IPackage>
          dataIndex="name"
          title="Name"
          ellipsis
          render={(value, record) => (
            <Link to={`edit/${record.id}`}>{value}</Link>
          )}
        />
        <Table.Column
          dataIndex={['celebration']}
          title="Celebration"
          ellipsis
          render={(value) => {
            if (celebrationIsLoading) {
              return <TextField value="Loading..." />
            }

            return (
              <TextField
                value={
                  celebrationsData?.data.find((item) => item.id === value)?.name
                }
              />
            )
          }}
        />
        <Table.Column
          dataIndex="price"
          title="Price"
          render={(value) => (
            <NumberField
              value={value ?? 0}
              options={{
                currency: 'INR',
                currencyDisplay: 'narrowSymbol',
                style: 'currency',
              }}
            />
          )}
        />
        <Table.Column<IPackage>
          title="Action"
          ellipsis
          render={(_, record) => (
            <Space size="small">
              <Button
                icon={<Icons.AppstoreOutlined />}
                size="small"
                onClick={() =>
                  window.open(`/package_tool/moodboard/${record.id}`, '_blank')
                }
              />
              <Button
                icon={<Icons.CopyOutlined />}
                size="small"
                onClick={() => {
                  setSelectedPackage(record)
                  modalFormProps.show()
                }}
              />
            </Space>
          )}
        />
      </Table>
      <ShortlistPackageModalForm
        selectedPackage={selectedPackage}
        modalFormProps={modalFormProps}
      />
    </List>
  )
}
