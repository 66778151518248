import { ImageField } from '@refinedev/antd'
import { ImageProps, Tag } from 'antd'
import './PaddedImageField.less'

declare type FieldProps<T> = {
  value: T
}

declare type ImageFieldProps = FieldProps<string | undefined> &
  ImageProps & {
    imageTitle?: string
    padding?: number
    radius?: number
    tags?: string[]
  }

export const PaddedImageField = (props: ImageFieldProps) => {
  const { padding = 10, radius = 10, tags } = props
  return (
    <div className="img-cont" style={{ padding, borderRadius: radius }}>
      <ImageField className="img" {...props} style={{ borderRadius: radius }} />
      {tags && (
        <div className="tag-cont">
          {tags.map((tag) => (
            <Tag color="rgba(0, 0, 0, 0.49)" key={tag}>
              {tag}
            </Tag>
          ))}
        </div>
      )}
    </div>
  )
}
