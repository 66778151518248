import { useRouterContext } from '@refinedev/core'
import React, { useEffect } from 'react'
const RedirectToFeed: React.FC = () => {
  const { useHistory } = useRouterContext()
  const history = useHistory()

  useEffect(() => {
    history.push('/feed')
  }, [])

  return null // This component does not render anything
}

export default RedirectToFeed
