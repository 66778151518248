import { Form, FormProps, Input } from "antd";
import { file2Base64 } from "@refinedev/core";
import { SingleImageUpload } from 'components/input'
import 'react-quill/dist/quill.snow.css'

export const BestForForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  const onFinish = async (values: any) => {
    let coverImage = ''
    const { cover_image } = values

    // remove the image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.cover_image

    if (cover_image) {
      const file = cover_image[cover_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values, parent: values.parent ?? null }
    if (coverImage.length) finalResponse['cover_image'] = coverImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Image" name="image">
        <SingleImageUpload />
      </Form.Item>
    </Form>
  )
}
