import { Form, FormProps, Input, Transfer } from "antd";
import { useTransfer } from 'components/hooks/useTransfer'
import { IPermission } from 'interfaces/permission'

export const GroupForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  const { transferProps } = useTransfer<IPermission>({
    resource: 'core/permissions',
    fetchSize: 100,
  })

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item
        label="Permissions"
        name="permissions"
        valuePropName="targetKeys">
        <Transfer
          {...transferProps}
          showSearch
          listStyle={{ width: '50%', height: 400 }}
        />
      </Form.Item>
    </Form>
  )
}
