import { Descriptions } from 'antd'
import { PriceField } from 'components/field'
import { IVenueRental } from 'interfaces/venue'

export const VenueRentalDetails = ({ item }: { item: IVenueRental }) => {
  return (
    <Descriptions column={4}>
      <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
      <Descriptions.Item label="From (hr)">{item.from_hr}</Descriptions.Item>
      <Descriptions.Item label="To (hr)">{item.to_hr}</Descriptions.Item>
      <Descriptions.Item label="Price">
        <PriceField value={item.price ?? 0} />
      </Descriptions.Item>
    </Descriptions>
  )
}
