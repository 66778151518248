import { Create } from "@refinedev/antd";
import { CheckAccess } from 'components/checkAccess'
import { useForm } from 'components/hooks/useForm'
import { IStaff } from 'interfaces/staff'
import { StaffForm } from './Form'

export const StaffCreate: React.FC = () => {
  const useFormProps = useForm<IStaff>({ redirect: 'edit' })

  return (
    <CheckAccess resource="write_staff">
      <Create saveButtonProps={useFormProps.saveButtonProps}>
        <StaffForm useFormProps={useFormProps} />
      </Create>
    </CheckAccess>
  )
}
