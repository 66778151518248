import { CreateButton, DeleteButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { IBestFor } from 'interfaces/bestFor'
import { useState } from 'react'
import { BEST_FOR_URL } from 'urls'

export const BestForList: React.FC = () => {
  const { tableProps } = useTable<IBestFor>({
    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'package_tool/best_for',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => edit(BEST_FOR_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis/>
      </Table>
    </List>
  );
}
