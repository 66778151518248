import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { ISection } from 'interfaces/section'
import { SectionForm } from './Form'

export const SectionEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<ISection>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <SectionForm formProps={formProps} />
    </Edit>
  )
}
