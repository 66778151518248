import { hotelPricing, MealInfo } from "./questionInterface";

export const CRM_COLUMNS: Record<
  string,
  { title: string; disabled?: boolean; order: number; visible?: boolean }
> = {
  name: { title: 'Name', disabled: true, order: 1, visible: true },
  phone: { title: 'Phone', disabled: true, order: 2, visible: true },
  pre_sales: { title: 'Pre Sales', order: 3, visible: true },
  relationship_manager: { title: 'RM', order: 4, visible: true },
  consultant: { title: 'Consultant', order: 5, visible: true },
  budget: { title: 'Budget', order: 6, visible: true },
  call_attempt: { title: 'Call Attempt (Pre Sales)', order: 7, visible: true },
  sales_call_attempt: {
    title: 'Call Attempt (Sales)',
    order: 8,
    visible: true,
  },
  stages: { title: 'Stage', order: 9, visible: true },
  services: { title: 'Services', order: 10, visible: true },
  events: { title: 'Events', order: 11, visible: true },
  venue: { title: 'Venue', order: 12, visible: true },
  expected_booking_date: {
    title: 'Booking Probability',
    order: 13,
    visible: true,
  },
  city: { title: 'City', order: 14, visible: true },
  statuses: { title: 'Status', order: 15, visible: true },
  source: { title: 'Source', order: 16, visible: true },
  event_horizon: { title: 'Event Horizon', order: 17, visible: true },
  campaign: { title: 'Campaign', order: 18, visible: true },
  project_value: { title: 'Project Value', order: 19, visible: true },
  booking: { title: 'Booking', order: 20, visible: true },
  created_at: { title: 'Created At', disabled: true, order: 21, visible: true },
}

export const ALWAYS_PRESENT_COLUMNS = ['name', 'phone', 'created_at']

export const lakh = 100000

export const VenueCategoryAttributeId = 74

export const filterString = (attributeValue: number) =>
  `generated_${attributeValue}__in`

export const VenueCatergoryAttributeOptionValue = {
  '5 Star Plus': 570,
  '5 Star': 571,
  '4 Star': 572,
  '3 Star': 573,
  Luxury: 574,
  Prime: 575,
  Delight: 576,
  Select: 577,
}

const luxury: hotelPricing = {
  '24 Hrs': 13 * lakh,
  '48 Hrs': 24 * lakh,
}

const prime: hotelPricing = {
  '24 Hrs': 7 * lakh,
  '48 Hrs': 10 * lakh,
}

const delight: hotelPricing = {
  '24 Hrs': 4 * lakh,
  '48 Hrs': 7.5 * lakh,
}

const select: hotelPricing = {
  '24 Hrs': Number(Number(2.2 * lakh).toPrecision(2)),
  '48 Hrs': 4 * lakh,
}

export const VenueCost = {
  Luxury: luxury,
  Prime: prime,
  Delight: delight,
  Select: select,
}

const premium: MealInfo = {
  Breakfast: 350,
  Lunch: 800,
  'High Tea': 250,
  Dinner: 900,
}

const semiPremium: MealInfo = {
  Breakfast: 250,
  Lunch: 650,
  'High Tea': 150,
  Dinner: 700,
}

const budget: MealInfo = {
  Breakfast: 180,
  Lunch: 450,
  'High Tea': 100,
  Dinner: 500,
}

export const CateringCost = {
  Premium: premium,
  'Semi-Premium': semiPremium,
  Budget: budget,
}
