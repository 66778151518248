import { BaseKey } from '@refinedev/core';
import { Form, FormProps, Input, InputNumber } from 'antd';
import { SingleImageUpload } from 'components/input';

export const VenueSectionForm = (props: {
  formProps: FormProps;
  venueId: BaseKey;
}) => {
  return (
    <Form
      {...props.formProps}
      onFinish={(values) => {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          if (key === 'cover_image') {
            if (values[key]?.[0]?.originFileObj){
              formData.append(key, values[key][0].originFileObj);
            }
          } else {
            formData.append(key, values[key]);
          }
        });
        formData.append('venue', props.venueId.toString());
        props.formProps.onFinish!(formData);
      }}
      layout="vertical"
    >
      <Form.Item
        label="Order"
        name="order_id"
        rules={[{ required: true, message: 'Order is required' }]}
      >
        <InputNumber min={1} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the section name' }]}
      >
        <Input placeholder="Enter section name" />
      </Form.Item>
      <SingleImageUpload
        label="Cover Image"
        initialImageUrl={props.formProps.initialValues?.cover_image}
        name="cover_image"
        buttonText="Cover Image"
      />
      <Form.Item label="Description" name="description">
        <Input.TextArea placeholder="Enter section description" />
      </Form.Item>
    </Form>
  );
};