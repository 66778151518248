import { useSelect } from "@refinedev/antd";
import { Button, Form, Modal, Select } from "antd";
import { useCreate, useInvalidate } from "@refinedev/core";
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICustomer, IEvent } from 'interfaces/customer'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import { useState } from 'react'
import { SERVICE_URL } from 'urls'

export const CloneModal: React.FC<{
  visible: boolean
  close: () => void
  package?: IShortlistPackage
}> = ({ visible, close, package: package_ }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>()

  const { mutate: clone } = useCreate()
  const invalidate = useInvalidate()

  const {
    selectProps: customerSelectProps,
    queryResult: customerQueryResult,
    defaultValueQueryResult: customerDefaultQueryResult,
  } = useSelectWithSearch<ICustomer>({
    resource: 'package_tool/customers',
    optionLabel: 'username',
    optionValue: 'id',
  })

  const { selectProps: eventSelectProps } = useSelect<IEvent>({
    resource: `package_tool/customers/${selectedCustomer}/events`,
    optionLabel: 'name',
    optionValue: 'id',

    queryOptions: {
      enabled: !!selectedCustomer,
    },

    pagination: {
      mode: "server"
    }
  })

  return (
    <Modal
      visible={visible}
      onCancel={close}
      destroyOnClose
      footer={null}
      title="Clone Package">
      <Form
        preserve={false}
        layout="vertical"
        onFinish={(values) => {
          clone(
            {
              resource: `package_tool/shortlist_packages/${package_?.id}/clone`,
              values,
            },
            {
              onSuccess: () => {
                close()
                invalidate({
                  resource: 'package_tool/shortlist_packages',
                  invalidates: ['list'],
                })
              },
            },
          )
        }}>
        <Form.Item
          name="customer"
          label="Customer"
          rules={[{ required: true }]}>
          <Select
            {...customerSelectProps}
            options={undefined}
            onChange={(value) => setSelectedCustomer(value as any)}>
            {customerQueryResult.data?.data
              .concat(customerDefaultQueryResult.data?.data ?? [])
              .map((customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.first_name} {customer.last_name} - (
                  {customer.username})
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="event" label="Project" rules={[{ required: true }]}>
          <Select {...eventSelectProps} />
        </Form.Item>
        <Form.Item
          name="service"
          label="Service"
          rules={[{ required: true }]}
          initialValue={package_?.service}>
          <SelectWithDefault
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
            disabled={package_?.service !== (undefined || null) ? true : false}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Clone
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
