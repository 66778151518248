// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button } from "antd";
import {
  SpecialZoomLevel,
  Viewer,
  ViewerProps,
  Worker,
} from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

export const PDFViewer = (props: ViewerProps) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
      <div
        style={{
          width: 300,
          height: 400,
          position: 'relative',
        }}>
        <Viewer {...props} defaultScale={SpecialZoomLevel.PageFit} />

        <Button
          size="small"
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
          }}
          icon={<Icons.FullscreenOutlined />}
          onClick={() =>
            window.open(props.fileUrl as string, '_blank')?.focus()
          }
        />
      </div>
    </Worker>
  )
}
