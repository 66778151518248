import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IPayoutRule } from 'interfaces/payout'
import { PayoutRuleForm } from './Form'

export const PayoutRuleCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IPayoutRule>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <PayoutRuleForm formProps={formProps} />
    </Create>
  )
}
