import { DeleteButton, EditButton, List, SaveButton, TextField, useEditableTable } from "@refinedev/antd";
import { Button, Form, Input, Space, Table } from "antd";
import { CrudFilters, HttpError } from "@refinedev/core";
import { ColorPicker } from 'components/input'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { IColor } from 'interfaces/pricing'
import { getSearchFormInitialValue } from 'utils/filter'
import { CommonCreateButton } from '../CommonCreateButton'

export const ColorList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    searchFormProps,
    filters,
  } = useEditableTable<IColor, HttpError, { search: string }>({
    onSearch: (params) => {
      const filters: CrudFilters = []
      // @ts-ignore
      const { search } = params
      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })
      return filters
    },
    syncWithLocation: true,
  })
  return (
    <List
      headerButtons={(defaultButton) => (
        <>
          <TableSearchInput
            searchFormProps={searchFormProps}
            intialValues={getSearchFormInitialValue(filters)}
          />
          <CommonCreateButton />
        </>
      )}>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column key="id" dataIndex="id" title="ID" />
          <Table.Column<IColor>
            key="title"
            dataIndex="name"
            title="Name"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IColor>
            key="color"
            dataIndex="hex"
            title="Color"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item
                    name="hex"
                    style={{ margin: 0 }}
                    initialValue="#fff">
                    <ColorPicker />
                  </Form.Item>
                )
              }
              return (
                <div
                  style={{ height: 30, width: 30, backgroundColor: value }}
                />
              )
            }}
          />
          <Table.Column<IColor>
            key="swatchCode"
            dataIndex="swatch_code"
            title="Swatch Code"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="swatch_code">
                    <Input />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IColor>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_text, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record.id)} size="small" />
                  <DeleteButton size="small" />
                </Space>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
