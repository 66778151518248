import { List, PageHeaderProps, useModalForm, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Form, Input, Modal, Select, Table, Radio } from "antd";
import { useRouterContext } from "@refinedev/core";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICustomer, IEvent } from 'interfaces/customer'
import { IProject } from 'interfaces/shortlistPackage'
import { CUSTOMER_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import { REGION_KEY } from 'authProvider'
import { REGION_URL } from 'urls'

export const ProjectList: React.FC = () => {
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IProject, any, any>(
    {
      resource: 'package_tool/shortlist_package/projects',

      onSearch: ({ search, id, is_contributor }) => [
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
        {
          field: 'id',
          operator: 'eq',
          value: id,
        },
        {
          field: "is_contributor",
          operator:"eq",
          value: is_contributor === "Contributor" ? true : null
        }
      ],

      syncWithLocation: true,

      filters: {
        permanent: [
          {
            field: "expand",
            operator: "eq",
            value: "user",
          },
        ]
      }
    },
  )
  const { Link } = useRouterContext()

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
  } = useModalForm<IEvent>({
    action: "create",
    onMutationSuccess: () => { tableQueryResult.refetch() }
  });


  const pageHeaderProps: PageHeaderProps = {
    extra: [
      <Form
        key="searchForm"
        {...searchFormProps}
        layout="inline"
        size="small"
        initialValues={getSearchFormInitialValue(filters, {
          is_contributor: (value: any) =>
            value === "true" ? "Contributor" : "All",
        })}>
        <Form.Item name="is_contributor" initialValue="All">
          <Radio.Group
            onChange={searchFormProps?.form?.submit}
            options={["Contributor", "All"]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item name="id">
          <Input placeholder="PID" allowClear />
        </Form.Item>
        <Form.Item name="search">
          <Input placeholder="Search by Name, Phone" allowClear />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
      </Form>,
      <Button type="primary" key="creatProject" size="small" icon={<Icons.PlusOutlined />} onClick={() => {
        createModalShow();
      }}>
        Create
      </Button>
    ],
  }

  return (
    <>
      <div>
        <List headerProps={pageHeaderProps} createButtonProps={{ size: "small" }}>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="id" title="ID" />
            <Table.Column
              dataIndex="name"
              title="Name"
              ellipsis
              render={(value, record: any) => (
                <Link to={`/package_tool/events/show/${record.id}`}>
                  {value}
                </Link>
              )}
            />
            <Table.Column
              dataIndex={['user', 'username']}
              title="Customer Phone"
            />
            <Table.Column
              dataIndex={['user', 'first_name']}
              title="First Name"
            />
            <Table.Column dataIndex={['user', 'last_name']} title="Last Name" />
          </Table>
        </List>
      </div>
      <Modal {...createModalProps}>
        <Form {...createFormProps} layout="vertical">
          <Form.Item
            label="Project Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Customer"
            name="user"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithDefault<ICustomer>
              useSelectProps={{ resource: CUSTOMER_URL }}
              dropdownMatchSelectWidth={false}
              allowClear

              size="small"
              renderOptions={(staffs) =>
                staffs?.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.first_name} {staff.last_name}
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            initialValue={Number(localStorage.getItem(REGION_KEY))}>
            <SelectWithDefault
              useSelectProps={{
                resource: REGION_URL,
                optionLabel: 'name',
              }}
            /></Form.Item>
        </Form>
      </Modal>
    </>
  )
}
