import { getValueFromEvent } from "@refinedev/antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Form, Upload } from "antd";
import { DragableUploadListItem } from 'components/field'
import update from 'immutability-helper'
import { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const DraggableMultiImageUpload = ({
  files,
  onChange,
  beforeUpload,
  label,
  name,
  uploadProps,
  dragDisabled = false,
}: {
  files: UploadFile[]
  onChange: (value: UploadFile[]) => void
  beforeUpload?: (file: UploadFile, fileList: UploadFile[]) => void
  label?: string
  name?: string
  uploadProps?: any
  dragDisabled?: boolean
}) => {
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = files[dragIndex]
      onChange(
        update(files, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      )
    },
    [files, onChange],
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <Form.Item
        label={label}
        name={name}
        getValueFromEvent={getValueFromEvent}>
        <Upload.Dragger
          name="image"
          multiple
          beforeUpload={(file, fileList) =>
            beforeUpload ? beforeUpload(file, fileList) : false
          }
          fileList={files}
          listType="picture-card"
          onChange={({ fileList: newFileList }) => {
            onChange(newFileList)
          }}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              disable={dragDisabled}
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
          {...uploadProps}>
          <p>Drop Image</p>
        </Upload.Dragger>
      </Form.Item>
    </DndProvider>
  )
}
