import { BaseKey, GetOneResponse, useUpdate } from '@refinedev/core'
import { Form, Input, InputNumber, Select, Spin, Switch } from 'antd'
import { Cities, ILead } from 'interfaces/crm'
import React, { useEffect, useState } from 'react'
import { LEAD_URL, STAFF_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import { EditableField } from './EditableField'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStaff } from 'interfaces/staff'

export const VenueReqProjectDetails: React.FC<{
  leadData?: GetOneResponse<ILead>
  leadId: BaseKey
  leadDataLoading: boolean
  leadRefetch: () => void
}> = ({ leadId, leadData, leadDataLoading, leadRefetch }) => {
  const { mutate: update } = useUpdate()

  const handleToggle = (checked: boolean) => {
    update({
      resource: LEAD_URL,
      values: {
        is_venue_decided: checked,
      },
      id: leadId as BaseKey,
      invalidates: ['detail'],
      successNotification: false,
    })
  }

  const handleCity = (value: string = '') => {
    update({
      resource: LEAD_URL,
      values: {
        city: value,
      },
      id: leadId as BaseKey,
      invalidates: ['detail'],
      successNotification: false,
    })
  }
  return (
    <>
      {leadDataLoading || !!!leadData ? (
        <Spin />
      ) : (
        <Form
          layout="vertical"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '5px',
          }}>
          <Form.Item label="Event Horizon" name="event_horizon">
            <EditableField
              InputField={({ onBlur, defaultValue }) => (
                <Input.TextArea
                  size="small"
                  onBlur={onBlur}
                  defaultValue={defaultValue}
                />
              )}
              fieldName="event_horizon"
              resource={LEAD_URL}
              id={leadId}
              initialValue={leadData?.data?.event_horizon}
            />
          </Form.Item>
          <Form.Item label={'Budget'} name="budget">
            <EditableField
              InputField={({ onBlur, defaultValue }) => (
                <InputNumber
                  size="small"
                  onBlur={onBlur}
                  defaultValue={defaultValue}
                />
              )}
              fieldName="budget"
              resource={LEAD_URL}
              id={leadId}
              initialValue={leadData?.data?.budget}
            />
          </Form.Item>
          <Form.Item label={'Relationship Manager'} name="relationship_manager">
            <EditableField
              InputField={({ onBlur, defaultValue }) => (
                <SelectWithDefault<IStaff>
                  useSelectProps={{ resource: STAFF_URL }}
                  style={{
                    width: '150px',
                  }}
                  allowClear
                  value={defaultValue}
                  onChange={onBlur}
                  size="small"
                  renderOptions={(staffs) =>
                    staffs?.map((staff) => (
                      <Select.Option key={staff.id} value={staff.id}>
                        {staff.first_name} {staff.last_name}
                      </Select.Option>
                    ))
                  }
                />
              )}
              fieldName="relationship_manager"
              resource={LEAD_URL}
              id={leadId}
              initialValue={leadData?.data?.relationship_manager}
            />
          </Form.Item>
          <Form.Item label={'City'} name="city">
            <Select
              mode="tags"
              size="small"
              style={{ minWidth: 150 }}
              popupMatchSelectWidth={false}
              options={createOptionsFromEnum(Cities)}
              defaultValue={(leadData?.data?.city as any) || []}
              onChange={(value: string[]) =>
                handleCity(value[value.length - 1])
              }
            />
          </Form.Item>
          <Form.Item label={'Is Venue Decided'} name="is_venue_decided">
            <CustomSwitch
              defaultChecked={leadData?.data?.is_venue_decided}
              onChange={(checked: boolean) => {
                handleToggle(checked)
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  )
}

const CustomSwitch = (props: any) => {
  const [value, setValue] = useState(props.defaultChecked)

  useEffect(() => {
    setValue(props.defaultChecked)
  }, [props.defaultChecked])

  return (
    <Switch
      checked={value}
      defaultChecked={props.defaultChecked}
      onChange={(checked: boolean) => {
        setValue(checked)
        props.onChange(checked)
      }}
    />
  )
}
