import { QuestionProps } from "./components/Question"

export interface budgetSplit {
  venueCatering: number
  decor: number
  photography: number
  miscellaneous: number
}

export interface hotelPricing {
  '24 Hrs': number
  '48 Hrs': number
}

export interface MealTableData {
  mealType: string
  pax: number
  cost: number
  total: number
  cateringOption?: 'premium' | 'semiPremium' | 'budget'
  day: string
}

export interface MealInfo {
  Breakfast: number
  Lunch: number
  'High Tea': number
  Dinner: number
}

export interface VenueTableData {
  timeDuration: string
  qty: number
  cost: number
  type: string
  total: number
}
export enum MealTypeKeys {
  'Breakfast' = 'Breakfast',
  'Lunch' = 'Lunch',
  'High Tea' = 'High Tea',
  'Dinner' = 'Dinner',
}

export enum CateringOptions {
  'Premium' = 'Premium',
  'Semi-Premium' = 'Semi-Premium',
  'Budget' = 'Budget',
}

export enum VenueOptions {
  'Luxury' = 'Luxury',
  'Prime' = 'Prime',
  'Delight' = 'Delight',
  'Select' = 'Select',
}

export enum HotelOptions {
  '5 Star Plus' = '5 Star Plus',
  '5 Star' = '5 Star',
  '4 Star' = '4 Star',
  '3 Star' = '3 Star',
}

export enum VenueHours {
  '24 Hrs' = '24 Hrs',
  '48 Hrs' = '48 Hrs',
}

export interface PaxInformation {
  mealType: MealTypeKeys
  pax: number
  hotelOption: HotelOptions
  hotelCost: number
  hotelTotal: number
  cateringOption: CateringOptions
  cateringCost: number
  cateringTotal: number
}

export interface VenueInformation {
  venueHours: VenueHours
  qty: number
  cost: number
  type: VenueOptions
  total: number
}
export interface PaxData {
  // the following key depicts the value of the day i.e. Day 1; Day 2 and etc
  [key: string]: {
    // the following key depicts the type of the meal i.e. Breakfast, Lunch, High Tea, Dinner
    [key: string]: PaxInformation
  }
}

interface IImage {
  id: string | number
  image: string
  order: number
}
export interface IVenue {
  id: number | string
  images?: IImage[]
  name: string
  price: number
}


export const budgetSplitPercentage: budgetSplit = {
  venueCatering: 0.5,
  decor: 0.15,
  photography: 0.1,
  miscellaneous: 0.25,
}

const fiveStarDeluxe: MealInfo = {
  Breakfast: 1500,
  Lunch: 4000,
  'High Tea': 1750,
  Dinner: 4500,
}

const fiveStar: MealInfo = {
  Breakfast: 1200,
  Lunch: 2750,
  'High Tea': 1000,
  Dinner: 3000,
}

const fourStar: MealInfo = {
  Breakfast: 850,
  Lunch: 1800,
  'High Tea': 750,
  Dinner: 2000,
}

const threeStar: MealInfo = {
  Breakfast: 550,
  Lunch: 1000,
  'High Tea': 500,
  Dinner: 1250,
}

export const HotelCost = {
  '5 Star Plus': fiveStarDeluxe,
  '5 Star': fiveStar,
  '4 Star': fourStar,
  '3 Star': threeStar,
}

export interface TotalBudget {
  value: number
  prime: number
  delight: number
}

export interface GuestCount {
  day: string
  meal: MealTypeKeys
  count: string
  guestCountData: string
}

export const meals: MealTypeKeys[] = [
  MealTypeKeys['Breakfast'],
  MealTypeKeys['Lunch'],
  MealTypeKeys['High Tea'],
  MealTypeKeys['Dinner'],
]

export const events = [
  { title: 'Wedding', value: 'wedding' },
  { title: 'Reception', value: 'reception' },
  { title: 'Haldi/Mehendi', value: 'haldi_mehendi' },
  { title: 'Sangeet', value: 'sangeet' },
  { title: 'Engagement', value: 'engagement' },
  { title: 'Baby Shower', value: 'baby_shower' },
]

export const guestCounts = [
  { title: '0-50', value: '0-50' },
  { title: '50-100', value: '50-100' },
  { title: '100-200', value: '100-200' },
  { title: '200-500', value: '200-500' },
  { title: '500-1000', value: '500-1000' },
  { title: '1000+', value: '1000+' },
]

export const venueDecided = [
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
  { title: 'No, but we are close to finalising', value: 'close_to_finalising' },
]

export const hotelTypes = [
  {title: '5 Star Plus', value: '5 Star Plus'},
  {title: '5 Star', value: '5 Star'},
  {title: '4 Star', value: '5 Star'}
]

export const resortTypes = [
  {title: '5 Star Plus', value: '5 Star Plus'},
  {title: '5 Star', value: '5 Star'},
  {title: '4 Star', value: '5 Star'}
]

export const venueData = [
  { title: 'Hotel/Resort', value: 'hotel_resort' },
  { title: 'Wedding venue', value: 'wedding_venue' },
  {
    title: 'Banquet Hall & Kalyana Mandapa',
    value: 'banquet_hall_kalyana_mandapa',
  },
]

export const daysData = [
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: 'More than 3', value: 'more_than_3' },
]

export const venueLocationSegments = [
  { title: 'North', value: 'north' },
  { title: 'South', value: 'south' },
  { title: 'Central', value: 'central' },
  { title: 'East', value: 'east' },
  { title: 'West', value: 'west' },
  { title: 'Flexible', value: 'flexible' },
]

export const venueBudgetSegment = [
  { title: '1-2L', value: '1-2L' },
  { title: '2-3L', value: '2-3L' },
  { title: '3-4L', value: '3-4L' },
  { title: 'More than 4L', value: 'more_than_4L' },
]

export const flowBookedVenue: QuestionProps[] = [
  {
    question: 'What type of venue do you want?',
    type: 'radio',
    options: venueData,
    answer: '',
  },
  {
    question: 'How many days are you planning?',
    type: 'radio',
    options: daysData,
    answer: '',
  },
  {
    question: 'What events are you planning?',
    type: 'multiSelect',
    options: events,
    answer: '',
  },
  {
    question: 'Number of guests for each meal',
    type: 'multiQuestion',
    repeatable: true,
    repeatCount: 1,
    subQuestions: [
      {
        question: 'Breakfast',
        type: 'radio',
        options: guestCounts,
        answer: '',
      },
      { question: 'Lunch', type: 'radio', options: guestCounts, answer: '' },
      {
        question: 'HighTea/Snacks',
        type: 'radio',
        options: guestCounts,
        answer: '',
      },
      { question: 'Dinner', type: 'radio', options: guestCounts, answer: '' },
    ],
    answer: [],
  },
  {
    question: 'Select venue price range',
    type: 'radio',
    options: venueBudgetSegment,
    answer: '',
  },
  {
    question: 'Finally, what month?',
    type: 'datePicker',
    picker: 'month',
    answer: '',
  },
]

export const flowNotBookedVenue: QuestionProps[] = [
  {
    question: 'What type of venue do you want?',
    type: 'radio',
    options: venueData,
    answer: '',
  },
  {
    question: 'What area are you looking at?',
    type: 'radio',
    options: venueLocationSegments,
    answer: '',
  },
  {
    question: 'Finally, what month?',
    type: 'datePicker',
    picker: 'month',
    answer: '',
  },
  {
    question: 'How many days are you planning?',
    type: 'radio',
    options: daysData,
    answer: '',
  },
  {
    question: 'What events are you planning?',
    type: 'multiSelect',
    options: events,
    answer: '',
  },
  {
    question: 'Number of guests for each meal',
    type: 'multiQuestion',
    repeatable: true,
    repeatCount: 1,
    subQuestions: [
      {
        question: 'Breakfast',
        type: 'radio',
        options: guestCounts,
        answer: '',
      },
      { question: 'Lunch', type: 'radio', options: guestCounts, answer: '' },
      {
        question: 'HighTea/Snacks',
        type: 'radio',
        options: guestCounts,
        answer: '',
      },
      { question: 'Dinner', type: 'radio', options: guestCounts, answer: '' },
    ],
    answer: [],
  },
  {
    question: 'How many guests require accommodation?',
    type: 'radio',
    options: guestCounts,
    answer: '',
  },
]