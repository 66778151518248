import { Show, TextField, useTable } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { IEvent } from 'interfaces/customer'
import { EventList } from './event'

export const CustomerShow: React.FC = () => {
  const { queryResult, showId } = useShow()

  const sectionTableProps = useTable<IEvent>({
    resource: `package_tool/customers/${showId}/events/`
  })

  return (
    <Show canEdit={true}>
      <TextField value={`Phone Number: ${queryResult.data?.data.username}`} />
      <br />
      <TextField
        value={`Name: ${queryResult.data?.data.first_name} ${queryResult.data?.data.last_name}`}
      />
      <EventList
        customerId={showId}
        tableProps={sectionTableProps.tableProps}
        refetch={sectionTableProps.tableQueryResult.refetch}
      />
    </Show>
  )
}
