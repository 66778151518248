import { IStaff } from 'interfaces/staff'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import qs from 'qs'
import React, { useEffect, useRef } from 'react'

export enum FileType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Other = 'other',
}

/**
 * Generates a random password string using Math.random().
 *
 * @return {string} A string representing the generated password.
 */
export const generatePassword = (): string =>
  Math.random().toString(36).slice(2, 10)

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * Returns an array of unique values extracted from an array of objects,
 * based on a specified key.
 *
 * @param {Array<any>} items - The array of objects.
 * @param {string} key - The key to extract the values from each object.
 * @return {(string | number)[]} - An array of unique values.
 */
export const getUnique = (
  items: Array<any>,
  key: string,
): (string | number)[] => {
  const uniqueValues: (string | number)[] = []
  const seenValues: Set<string | number> = new Set()

  for (const item of items) {
    const value = item[key]
    if (value && !seenValues.has(value)) {
      uniqueValues.push(value)
      seenValues.add(value)
    }
  }

  return uniqueValues
}

export const cleanBool = (value: string) => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}

/**
 * Creates an array of routes based on the properties of the given resource.
 *
 * @param {ResourceProps} resource - The resource to create routes for.
 * @return {Array} An array of routes.
 */
export const createRoute = (resource: {
  name: string
  options?: { route: string; label: string }
  list?: React.FC
  show?: React.FC
  edit?: React.FC
  create?: React.FC
}) => {
  const routes = []
  if (resource.list) {
    routes.push({
      exact: true,
      element: React.createElement(resource.list),
      path: resource.options?.route,
    })
  }
  if (resource.show) {
    routes.push({
      exact: true,
      element: React.createElement(resource.show),
      path: `${resource.options?.route}/show/:id`,
    })
  }
  if (resource.create) {
    routes.push({
      exact: true,
      element: React.createElement(resource.create),
      path: `${resource.options?.route}/create`,
    })
  }
  if (resource.edit) {
    routes.push({
      element: React.createElement(resource.edit),
      path: `${resource.options?.route}/edit/:id`,
    })
  }
  return routes
}

/**
 * Returns a cleaned path name by removing the first character.
 *
 * @param {string} pathName - The path name to be cleaned.
 * @return {string} - The cleaned path name.
 */
export const cleanPathName = (pathName: string) =>
  pathName.substring(1, pathName.length)

/**
 * Returns the base path name from a given path by removing any trailing
 * "/create", "/show" or "/edit".
 *
 * @param {string} pathName - The original path name to process.
 * @returns {string} The base path name.
 */
export const getBasePathName = (pathName: string) =>
  pathName
    .replace(/\/create.*/, '')
    .replace(/\/show.*/, '')
    .replace(/\/edit.*/, '')

/**
 * Replaces underscores and spaces in a string with spaces and capitalizes the first letter.
 *
 * @param {string} str - The input string to be humanized.
 * @return {string} The humanized string.
 */
export function humanize(str: string) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return startCase(m)
    })
}

/**
 * Returns the value obtained by reducing the initial number by the given percentage.
 *
 * @param {number} initial - The initial value to be reduced.
 * @param {number} percent - The percentage by which the initial value should be reduced.
 * @return {number} The result of reducing the initial value by the given percentage.
 */
export const reduceByPercent = (initial: number, percent: number): number =>
  initial - initial * (percent / 100)

/**
 * check if the number is valid, ie it is non NaN
 * @param number number to check
 * @returns 0 if NaN else number
 */
export const checkNumber = (number: number) => (isNaN(number) ? 0 : number)

/**
 * Converts a given `status` string to sentence case.
 *
 * @param {string} status - The input status string.
 * @returns {string} The status string in sentence case.
 */
export const convertStatusName = (status: string): string => {
  return startCase(status.toLowerCase())
}

/**
 * Get status colors for all the payouts statuses
 * @param status
 * @returns
 */
export const getPayoutStatusColor = (status: string) => {
  status = status.toLowerCase()
  switch (status) {
    case 'rejected':
    case 'reversed':
    case 'failed':
      return 'red'
    case 'processing':
    case 'pending':
      return 'orange'
    case 'processed':
    case 'success':
      return 'green'
    default:
      return
  }
}

/**
 * To merge two arrays of objects based on a common value
 */
export const mergeArrays = <
  T1 extends { [key: string]: any },
  T2 extends { [key: string]: any },
>(
  arr1: T1[],
  arr2: T2[],
  key1: string | string[],
  key2: string | string[],
): (T1 & T2)[] =>
  arr1.reduce((acc: any, curr: T1) => {
    // Find the matching object in arr2
    const matchingObject = arr2.find(
      (obj: T2) => (get(obj, key2) as any) === get(curr, key1),
    )

    // If a matching object was found, merge the objects and add to the result array
    if (matchingObject) {
      acc.push({ ...curr, ...matchingObject })
    } else {
      // If no matching object was found, just add the current object from arr1
      acc.push(curr)
    }

    return acc
  }, [])

interface NestedMap {
  [key: string]: any | NestedMap
}

/**
 * Merges two nested maps recursively, with map2 taking priority over map1 in case of conflicts.
 *
 * @param {NestedMap} map1 - The first map to be merged.
 * @param {NestedMap} map2 - The second map to be merged.
 * @returns {NestedMap} A new map with the merged values.
 */
export const mergeMaps = (map1: NestedMap, map2: NestedMap): NestedMap => {
  const mergedMap: NestedMap = {}

  for (let key in map1) {
    if (key in map2) {
      if (typeof map1[key] === 'object' && typeof map2[key] === 'object') {
        mergedMap[key] = mergeMaps(
          map1[key] as NestedMap,
          map2[key] as NestedMap,
        )
      } else {
        mergedMap[key] = map2[key]
      }
    } else {
      mergedMap[key] = map1[key]
    }
  }

  for (let key in map2) {
    if (!(key in map1)) {
      mergedMap[key] = map2[key]
    }
  }

  return mergedMap
}

/**
 * Flatten object to an array of object that includes the key as well
 * @param obj : object to flatten
 * @param keyName : name of the key to which the parent key is to be stored as
 * @returns
 */
export const flattenObj = (
  obj: object,
  keyName: string = 'key',
  valueName: string = 'value',
) => {
  return Object.entries(obj).map(([key, value]) => ({
    [keyName]: key,
    ...value,
  }))
}

/**
 * Returns the full name and username of a staff member, or just the full name if `full` is false.
 *
 * @param {Pick<IStaff, 'first_name' | 'last_name' | 'username'> & Partial<IStaff>} user - An object containing the first name, last name, and username of a staff member.
 * @param {boolean} [full=true] - Whether or not to include the username in the returned string.
 * @return {string} The full name and username (if `full` is true), or just the full name.
 */
export const getUserName = (
  user: Pick<IStaff, 'first_name' | 'last_name' | 'username'> & Partial<IStaff>,
  full: boolean = true,
) =>
  full
    ? `${user.first_name} ${user.last_name} (${user.username})`
    : `${user.first_name} ${user.last_name}`

/**
 * Converts an enum to an array of objects with `value` and `label` properties.
 *
 * @param {object} e - The enum object to convert.
 * @return {Array} An array of objects with `value` and `label` properties.
 */
export const createOptionsFromEnum = <T extends object>(
  e: T,
): Array<{ value: T[keyof T]; label: string }> => {
  return Object.keys(e).map((key) => {
    return { value: e[key as keyof T], label: e[key as keyof T] as any }
  })
}

/**
 * Parses the table parameters from the given URL and returns an object containing the parsed values.
 *
 * @param {string} url - The URL to parse the table parameters from.
 * @return {Object} An object containing the parsed values:
 *   - parsedCurrent: The parsed value of the 'current' parameter, or null if it does not exist or cannot be parsed as a number.
 *   - parsedPageSize: The parsed value of the 'pageSize' parameter, or null if it does not exist or cannot be parsed as a number.
 *   - parsedSorter: The parsed value of the 'sorter' parameter, or an empty array if it does not exist.
 *   - parsedFilters: The parsed value of the 'filters' parameter, or an empty array if it does not exist or cannot be parsed as an object.
 */
export const parseTableParams = (url: string) => {
  const { current, pageSize, sorter, filters } = qs.parse(
    url.substring(1), // remove first ? character
  )

  return {
    parsedCurrent: current && Number(current),
    parsedPageSize: pageSize && Number(pageSize),
    parsedSorter: sorter ?? [],
    parsedFilters: (filters as any) ?? [],
  }
}

/**
 * Returns actual loading value, in case the query option
 * enabled is set to false
 * due to this issue https://github.com/TanStack/query/issues/3584
 * @param isLoading
 * @param status
 * @returns
 */
export const isActualLoading = (
  isLoading: boolean,
  status: string,
): boolean => {
  return isLoading && status !== 'idle'
}

export const getRandomColor = (): string => {
  const minBrightness = 128 // Minimum brightness to ensure contrast with white
  let color = '#'

  while (true) {
    color += Math.floor(Math.random() * 16777215).toString(16) // Generate a random color

    // Calculate brightness of the color
    const brightness =
      parseInt(color.substring(1, 3), 16) * 0.299 +
      parseInt(color.substring(3, 5), 16) * 0.587 +
      parseInt(color.substring(5, 7), 16) * 0.114

    if (brightness >= minBrightness) {
      break // If brightness is sufficient, break out of the loop
    }

    color = '#' // Reset color and try again
  }

  return color
}

/**
 * Returns the file type of a given URL based on its file extension.
 *
 * @param {string} url - The URL of the file to determine its type.
 * @return {FileType} The type of file based on its extension.
 */
export const getFileType = (url: string): FileType => {
  const fileExtension = url.substring(url.lastIndexOf('.') + 1).toLowerCase()

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg']
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv']
  const documentExtensions = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
  ]

  if (imageExtensions.includes(fileExtension)) {
    return FileType.Image
  } else if (videoExtensions.includes(fileExtension)) {
    return FileType.Video
  } else if (documentExtensions.includes(fileExtension)) {
    return FileType.Document
  } else {
    return FileType.Other
  }
}
