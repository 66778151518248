import {
  CreateButton,
  ImageField,
  List,
  useEditableTable,
  DeleteButton as RefineDeleteButton,
} from '@refinedev/antd'
import {
  CrudFilters,
  HttpError,
  useDeleteMany,
  useNavigation,
} from '@refinedev/core'
import {
  Table,
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Typography,
  Card,
  Select,
} from 'antd'
import { ICourse, ICuisine, IDish } from 'interfaces/catering'
import { useState } from 'react'
import { COURSE_URL, CUISINE_URL, DISH_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

const { Title } = Typography

export const DishList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    searchFormProps,
    filters,
  } = useEditableTable<IDish, HttpError, { search: string }>({
    onSearch: (params) => {
      const filters: CrudFilters = []
      // @ts-ignore
      const { search, courses, cuisine } = params

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      if (courses) {
        filters.push({
          field: 'courses',
          operator: 'in',
          value: courses?.join(','),
        })
      }

      if (cuisine) {
        filters.push({
          field: 'cuisines',
          operator: 'in',
          value: cuisine?.join(','),
        })
      }

      return filters
    },
    syncWithLocation: true,
  })

  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: DISH_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && (
        <Button key="delete" onClick={deleteMany}>
          Delete
        </Button>
      ),
      <CreateButton key="create" />,
    ],
  }

  const dishFieldFormatter = {
    courses: (value: any) =>
      !!value ? value?.map((id: any) => Number(id)) : undefined,
    cuisine: (value: any) =>
      !!value ? value?.map((id: any) => Number(id)) : undefined,
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Form
              {...searchFormProps}
              layout="vertical"
              initialValues={getSearchFormInitialValue(
                filters,
                dishFieldFormatter,
              )}>
              <Title level={4}>Filters</Title>
              <Form.Item name="search">
                <Input placeholder="Search..." allowClear autoComplete="off" />
              </Form.Item>
              <Form.Item name="courses" label="Course">
                <SelectWithDefault<ICourse>
                  useSelectProps={{
                    resource: COURSE_URL,
                    optionLabel: 'name',
                    optionValue: 'id',
                  }}
                  mode="multiple"
                  allowClear
                  placeholder="Select Course"
                  renderOptions={(items) =>
                    items?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                />
              </Form.Item>
              <Form.Item name="cuisine" label="Cuisine">
                <SelectWithDefault<ICuisine>
                  useSelectProps={{
                    resource: CUISINE_URL,
                    optionLabel: 'name',
                    optionValue: 'id',
                  }}
                  mode="multiple"
                  allowClear
                  placeholder="Select Cuisines"
                  renderOptions={(items) =>
                    items?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Filter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={18}>
          <Form {...formProps}>
            <Table
              {...tableProps}
              rowKey="id"
              rowSelection={rowSelection}
              size="small"
              onRow={(record) => ({
                onClick: () => edit(DISH_URL, record.id),
              })}>
              <Table.Column<IDish>
                title="Image"
                ellipsis
                render={(_, record) => (
                  <span onClick={(e) => e.stopPropagation()}>
                    {record.image ? (
                      <ImageField value={record.image} width={75} height={75} />
                    ) : (
                      <ImageField
                        value={'/placeholder.svg'}
                        width={75}
                        height={75}
                        style={{ objectFit: 'cover' }}
                      />
                    )}
                  </span>
                )}
              />
              <Table.Column<IDish>
                key="name"
                dataIndex="name"
                title="Name"
                render={(value, record) => {
                  if (isEditing(record.id)) {
                    return (
                      <Form.Item name="name" style={{ margin: 0 }}>
                        <Input />
                      </Form.Item>
                    )
                  }
                  return <span>{value}</span>
                }}
              />
              <Table.Column<IDish>
                key="price"
                dataIndex="price"
                title="Price (₹)"
                render={(value, record) => {
                  if (isEditing(record.id)) {
                    return (
                      <Form.Item name="price" style={{ margin: 0 }}>
                        <Input />
                      </Form.Item>
                    )
                  }
                  return <span>{value}</span>
                }}
              />
              <Table.Column<IDish>
                title="Actions"
                dataIndex="actions"
                key="actions"
                render={(_, record) => {
                  if (isEditing(record.id)) {
                    return (
                      <Space>
                        <Button {...saveButtonProps} size="small">
                          Save
                        </Button>
                        <Button {...cancelButtonProps} size="small">
                          Cancel
                        </Button>
                      </Space>
                    )
                  }
                  return (
                    <Space>
                      <Button {...editButtonProps(record.id)} size="small">
                        Edit
                      </Button>
                      <RefineDeleteButton
                        size="small"
                        resource={DISH_URL}
                        recordItemId={record.id as string}
                      />
                    </Space>
                  )
                }}
              />
            </Table>
          </Form>
        </Col>
      </Row>
    </List>
  )
}
