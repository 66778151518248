import { BaseKey, useCreate, useUpdate } from '@refinedev/core'
import {
  Checkbox,
  Form,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Space,
  Switch,
  Typography,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import {
  IVenueRequirement,
  VenueAreaPreference,
  VenueType,
} from 'interfaces/crm'
import React, { useEffect, useState } from 'react'
import { SECTION_URL, VENUE_REQUIREMENTS_URL, VENUE_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import { NewLeadRequirementForm } from './LeadRequirementForm'

export const VenueReqEventForm: React.FC<{
  isAdding?: boolean
  index: number
  day: number
  leadId?: BaseKey
  setAddVenueReq?: any
  venueReqId?: BaseKey
  refetchVenueReq?: () => void
  leadRefetch?: () => void
  venueData?: IVenueRequirement
}> = ({
  isAdding = false,
  leadId,
  setAddVenueReq,
  venueReqId,
  refetchVenueReq,
  venueData,
  leadRefetch,
  index,
  day,
}) => {
  // Methods to either update or create Venue Req.
  const { mutate: updateVenueReq } = useUpdate()
  const { mutate: createVenueReq } = useCreate()

  // Check whether to show new UI or old UI while update
  const showNewUI = venueData?.events === null

  // Variable to manipulate the events key in the VenueReqForm at the time of adding
  const [events, setEvents] = useState<string>(venueData?.events!)
  const [createVenueReqEvents, setCreateVenueReqEvents] =
    useState<boolean>(false)

  // Options for Venue Types, Regions
  const venueTypes = createOptionsFromEnum(VenueType)
  const venueRegions = createOptionsFromEnum(VenueAreaPreference)

  const getMonth = (value?: string) => {
    if (!value) return ''

    const divided = value.split(' ')
    return divided[divided?.length - 1] || ''
  }

  // Variables to manipulate the dates of the event
  const [datesChanged, setDateChanged] = useState(false)
  const [date, setDate] = useState<number>(
    Number(
      venueData?.dates
        ?.split(' ')
        .filter((val) => Number(val))
        .join(),
    ),
  )
  const [monthYear, setMonthYear] = useState<any>(getMonth(venueData?.dates))

  let months = []
  for (let i = 0; i < 12; i++) {
    months.push(dayjs().add(i, 'month').format('MMM-YY'))
  }

  // Hanlde updates to the form, and patch the database on any change
  const handleUpdates = (fieldName: string, value: any) => {
    if (isAdding && fieldName === 'events') {
      createVenueReq(
        {
          resource: VENUE_REQUIREMENTS_URL,
          values: {
            [fieldName]: value,
            lead: leadId,
            dates: `${date} ${monthYear}`,
          },
        },
        {
          onSuccess: () => {
            setAddVenueReq(false)
            refetchVenueReq!()
            setCreateVenueReqEvents(false)
          },
        },
      )
    } else {
      updateVenueReq(
        {
          resource: VENUE_REQUIREMENTS_URL,
          id: venueReqId as BaseKey,
          values: {
            [fieldName]: value,
          },
          successNotification: false,
        },
        {
          onSuccess: () => {
            if (fieldName === 'venue') {
              leadRefetch!()
            }
            setDateChanged(false)
          },
        },
      )
    }
  }

  // Handle changes to the event date and update the dates <string> field in the backend
  useEffect(() => {
    let tempEventData = `${date} ${monthYear}`
    if ((date || monthYear) && datesChanged && !isAdding) {
      handleUpdates('dates', tempEventData)
    }
  }, [date, monthYear, datesChanged])

  useEffect(() => {
    if (isAdding && events && createVenueReqEvents) {
      handleUpdates('events', events)
    }
  }, [events, createVenueReqEvents])

  return (
    <>
      {showNewUI ? (
        <NewLeadRequirementForm
          venueData={venueData}
          index={index}
          venueReqId={venueReqId}
          leadRefetch={leadRefetch}
          day={day}
        />
      ) : (
        <Form layout="vertical" size="small">
          <Form.Item name="dates">
            <Typography.Text
              style={{
                padding: '2px',
                fontSize: 'small',
              }}
              strong>
              Date
            </Typography.Text>
            <InputNumber
              size="small"
              max={31}
              min={1}
              style={{
                padding: '5px',
              }}
              onChange={(value) => {
                setDate(value as number)
              }}
              onBlur={(e) => {
                setDateChanged(true)
              }}
              defaultValue={Number(
                venueData?.dates
                  ?.split(' ')
                  .filter((val) => Number(val))
                  .join(),
              )}
            />
            <Typography.Text
              style={{
                padding: '2px',
                fontSize: 'small',
              }}
              strong>
              Month and Year
            </Typography.Text>
            <Radio.Group
              buttonStyle="solid"
              style={{
                padding: '5px',
              }}
              onChange={(e: RadioChangeEvent) => {
                setMonthYear(e.target.value)
                setDateChanged(true)
              }}
              defaultValue={getMonth(venueData?.dates)}
              size="small">
              {months.map((value, idx) => (
                <Radio.Button key={idx} value={value}>
                  {value}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Events">
            <SelectWithDefault
              size="small"
              mode="multiple"
              useSelectProps={{
                resource: SECTION_URL,
                optionLabel: 'name',
              }}
              onChange={(value) => {
                if (value) {
                  if (isAdding) {
                    setEvents(value.join())
                  } else {
                    handleUpdates('events', value.join())
                  }
                }
              }}
              defaultValue={
                venueData?.events !== ''
                  ? venueData?.events
                      ?.split(',')
                      ?.map((val: string) => Number(val))
                  : undefined
              }
              allowClear={isAdding}
              onBlur={(e) => {
                if (isAdding) {
                  setCreateVenueReqEvents(true)
                }
              }}
            />
          </Form.Item>
          {!isAdding && (
            <>
              {' '}
              <Space direction="horizontal" size={'large'}>
                <Form.Item label="PAX">
                  <InputNumber
                    min={0}
                    defaultValue={venueData?.pax}
                    onBlur={(e) => {
                      let value = Number(e.target.value)
                      handleUpdates('pax', value)
                    }}
                  />
                </Form.Item>
                <Form.Item label="Venue Budget">
                  <InputNumber
                    min={0}
                    defaultValue={venueData?.venue_budget}
                    onBlur={(e) => {
                      let value = Number(e.target.value)
                      handleUpdates('venue_budget', value)
                    }}
                  />
                </Form.Item>

                <Form.Item label="Nearby Accommodation">
                  <Switch
                    defaultChecked={venueData?.has_nearby_accomodation}
                    onChange={(checked: boolean) => {
                      handleUpdates('has_nearby_accomodation', checked)
                    }}
                  />
                </Form.Item>
                <Form.Item label="Venue">
                  <SelectWithDefault
                    size="small"
                    style={{
                      width: '150px',
                    }}
                    allowClear
                    useSelectProps={{
                      resource: VENUE_URL,
                      optionLabel: 'name',
                    }}
                    onChange={(value) => {
                      handleUpdates('venue', value)
                    }}
                    defaultValue={venueData?.venue}
                  />
                </Form.Item>
              </Space>
              <Form.Item label="Region">
                <Checkbox.Group
                  style={{
                    padding: '5px',
                    flexDirection: 'row',
                  }}
                  defaultValue={venueData?.area_preference?.split(',')}
                  onChange={(checkedValue) => {
                    handleUpdates('area_preference', checkedValue.join(','))
                  }}>
                  {venueRegions.map((value, idx) => (
                    <Checkbox
                      key={idx}
                      value={value.value}
                      children={<>{value.label}</>}
                    />
                  ))}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="Type">
                <Checkbox.Group
                  style={{
                    padding: '5px',
                    flexDirection: 'row',
                  }}
                  defaultValue={venueData?.venue_type?.split(',')}
                  onChange={(checkedValue) => {
                    handleUpdates('venue_type', checkedValue.join(','))
                  }}>
                  {venueTypes.map((value, idx) => (
                    <Checkbox
                      key={idx}
                      value={value.value}
                      children={<>{value.label}</>}
                    />
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </>
          )}
        </Form>
      )}
    </>
  )
}
