import { useOne } from '@refinedev/core'
import { Form, FormProps, InputNumber, Select, Space, Switch } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStaff } from 'interfaces/staff'
import { PAYOUT_RULE_URL, STAFF_URL } from 'urls'

export const PayoutRuleForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  const { data: ruleForData } = useOne<any>({
    resource: PAYOUT_RULE_URL,
    id: 'rule_types',
  })

  return (
    <Form {...formProps} layout="vertical">
      <Space size="large">
        <Form.Item label="Min Amount" name="min_amount">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Max Amount" name="max_amount">
          <InputNumber />
        </Form.Item>
      </Space>
      <Form.Item label="Approvers" name="approvers">
        <SelectWithDefault<IStaff>
          useSelectProps={{
            resource: STAFF_URL,
            fetchSize: 25,
          }}
          mode="multiple"
          renderOptions={(items) =>
            items?.map((item) => (
              <Select.Option key={item.id} value={Number(item.id)}>
                {item.first_name} {item.last_name}
              </Select.Option>
            ))
          }
        />
      </Form.Item>
      <Form.Item name="rule_for" label="Payout Rule Type">
        <Select
          allowClear
          options={ruleForData?.data?.results.map((t: any) => ({
            label: t,
            value: t,
          }))}
        />
      </Form.Item>
      <Form.Item
        name="for_under_collected"
        label="For under collected"
        valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}
