import { Form, Input } from 'antd'
import { ITag } from 'interfaces/attribute'
import { UseFormReturnType } from 'interfaces/refine'

export const TagForm: React.FC<{
  useFormProps: UseFormReturnType<ITag>
}> = ({ useFormProps }) => {
  const { formProps } = useFormProps

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Form>
  )
}
