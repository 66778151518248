import { CalendarOutlined, TableOutlined } from '@ant-design/icons'
import { LayoutWrapper } from '@refinedev/core'
import { Segmented } from 'antd'
import { useState } from 'react'
import { AvailabilityCalendar } from './AvailabilityCalendar'
import { AvailabilityTable } from './AvailabilityTable'

export const AvailabilityPage = ({}) => {
  const [view, setView] = useState('table')

  const getView = () => {
    switch (view) {
      case 'table':
        return <AvailabilityTable />
      case 'calendar':
        return <AvailabilityCalendar />
      default:
        return <AvailabilityCalendar />
    }
  }
  return (
    <LayoutWrapper>
      <Segmented
        options={[
          { label: 'Table', value: 'table', icon: <TableOutlined /> },
          { label: 'Calendar', value: 'calendar', icon: <CalendarOutlined /> },
        ]}
        value={view}
        onChange={(value) => setView(value as string)}
      />
      {getView()}
    </LayoutWrapper>
  )
}
