import { useTable, useTableReturnType } from '@refinedev/antd'
import { useCan } from '@refinedev/core'
import { CheckboxOptionType, Form, Input, Radio, Tooltip } from 'antd'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import capitalize from 'lodash/capitalize'
import { getSearchFormInitialValue } from 'utils/filter'
import { ShortlistPackageTable } from '../shortlistPackage'

export const RFQList: React.FC = () => {
  const tProps = useTable<
    IShortlistPackage,
    any,
    { search: string; status: string; project: number }
  >({
    onSearch: ({ search, status, project }) => {
      return [
        {
          field: 'search',
          operator: 'eq',
          value: search,
        },
        {
          field: 'project',
          operator: 'eq',
          value: project,
        },
        {
          field: 'status',
          operator: 'eq',
          value: status === 'Finalized' ? null : status.toLowerCase(),
        },
      ]
    },

    syncWithLocation: true,
  })

  const { data: lockPayout } = useCan({
    resource: 'can_lock_payout',
    action: '',
  })

  const statusOptions: CheckboxOptionType[] = [
    {
      value: 'Selected',
      label: (
        <Tooltip title="All the Packages which are Selected and are NOT Locked, Finalized or Executed">
          Selected
        </Tooltip>
      ),
    },
    {
      value: 'Finalized',
      label: (
        <Tooltip title="All the Packages which are Finalized and are NOT Locked or Executed">
          Finalized
        </Tooltip>
      ),
    },
    {
      value: 'Locked',
      label: (
        <Tooltip title="All the Packages which are Finalized and Locked and NOT yet Executed">
          Locked
        </Tooltip>
      ),
    },
    {
      value: 'Executed',
      label: (
        <Tooltip title="All the Packages which are Executed">Executed</Tooltip>
      ),
    },
  ]

  const Filter: React.FC<{
    tProps: useTableReturnType<IShortlistPackage, any, any>
  }> = ({ tProps }) => {
    return (
      <Form
        {...tProps.searchFormProps}
        key="search"
        layout="inline"
        initialValues={getSearchFormInitialValue(tProps.filters, {
          status: (value: string) =>
            value === '' ? 'Finalized' : capitalize(value),
        })}>
        <Form.Item name="project">
          <Input.Search
            placeholder="project id"
            onSearch={tProps.searchFormProps?.form?.submit}
            enterButton
            allowClear
          />
        </Form.Item>
        <Form.Item name="status" initialValue="Finalized">
          <Radio.Group
            onChange={tProps.searchFormProps?.form?.submit}
            options={statusOptions}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item name="search">
          <Input.Search
            placeholder="Name, Customer details, Event name"
            onSearch={tProps.searchFormProps?.form?.submit}
            enterButton
            allowClear
          />
        </Form.Item>
      </Form>
    )
  }
  return (
    <ShortlistPackageTable
      tProps={tProps}
      forRFQ
      title={`${capitalize(
        (tProps.searchFormProps?.form?.getFieldValue('status') ??
          'finalized') as string,
      )} Packages`}
      allowOptionalFilter
      OptionalFilter={<Filter tProps={tProps} />}
      lockPayout={lockPayout?.can}
    />
  )
}
