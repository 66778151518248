import { DatePicker as AntDDatePicker } from 'antd'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

export const DatePicker: React.FC<any> = (props) => {
  let { value, format, ...rest } = props

  if (typeof value === 'string' && value) {
    value = dayjs(value, format ?? 'YYYY-MM-DDTHH:mm:ss.ffffffz')
  } else if (!value) {
    value = null
  }

  return <AntDDatePicker {...rest} value={value} />
}
