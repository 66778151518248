import { CreateButton, List, useTable } from '@refinedev/antd'
import { useDeleteMany, useNavigation } from '@refinedev/core'
import { Table } from 'antd'
import { DeleteButton } from 'components/button'
import { ISurcharge } from 'interfaces/catering'
import { useState } from 'react'
import { SURCHARGE_URL } from 'urls'

export const SurchargeList: React.FC = () => {
  const tProps = useTable<ISurcharge>({
    syncWithLocation: true,
    filters: {
      initial: [
        {
          field: 'menu__isnull',
          operator: 'eq',
          value: true,
        },
      ],
    },
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: SURCHARGE_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: (event) => edit(SURCHARGE_URL, record.id),
        })}>
        <Table.Column dataIndex={['name']} title="Name" />
        <Table.Column dataIndex="surcharge_type" title="Type" />
      </Table>
    </List>
  )
}
