import { Button, Form, Modal } from "antd";
import { BaseKey, useApiUrl, useCustomMutation, useModal } from "@refinedev/core";
import { CELEBRATION_URL, SERVICE_URL, SHORTLIST_PACKAGE_URL } from 'urls'
import { SelectWithDefault } from 'components/input/SelectWithDefault'



export const CreatePackageModal = ({ projectId, refetch }: { projectId: BaseKey, refetch: any }) => {
	const { mutate } = useCustomMutation<any>()
	const apiUrl = useApiUrl()

	const [form] = Form.useForm()

	const { visible, show, close } = useModal()

	return (
		<>
			<Button type="primary" style={{ borderRadius: '1px', marginTop: "30px" }} onClick={() => show()}>
				Create package
			</Button>
			<Modal
				title="Create Package"
				open={visible}
				onCancel={close}
				onOk={() => form.submit()}>
				<Form
					form={form}
					layout="vertical"
					onFinish={(values) => {
						mutate(
							{
								url: `${apiUrl}/package_tool/shortlist_package/projects/${projectId}/create_package/`,
								values,
								method: 'post',
							},
							{
								onSuccess: (data) => { close(); refetch(); }
							},
						)
					}}>
					<Form.Item name="service" label="Service">
						<SelectWithDefault
							useSelectProps={{
								resource: SERVICE_URL,
								optionLabel: 'name',
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}