import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IProductType } from 'interfaces/productType'
import { FlowerForm } from './Form'

export const FlowerCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IProductType>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <FlowerForm formProps={formProps} />
    </Create>
  )
}
