import { DeleteButton, EditButton, List, SaveButton, TextField, useEditableTable } from "@refinedev/antd";
import { Button, Form, Input, Space, Table } from "antd";
import { IFlowerType } from 'interfaces/pricing'
import { CommonCreateButton } from '../CommonCreateButton'

export const FlowerTypeList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable<IFlowerType>({ syncWithLocation: true })
  return (
    <List
      headerButtons={(defaultButton) => (
        <>
          <CommonCreateButton />
        </>
      )}>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column key="id" dataIndex="id" title="ID" />
          <Table.Column<IFlowerType>
            key="title"
            dataIndex="name"
            title="Name"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IFlowerType>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_text, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record.id)} size="small" />
                  <DeleteButton size="small" recordItemId={record.id} />
                </Space>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
