import { useModalForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Form, Input, Modal } from "antd";
import { BaseRecord, useResource } from "@refinedev/core";

export const CommonCreateButton = <T extends BaseRecord = BaseRecord>() => {
  const { resource } = useResource()
  const { modalProps, formProps, show } = useModalForm<T>({
    action: 'create',
  })

  return (
    <>
      <Button onClick={() => show()} icon={<Icons.PlusSquareOutlined />}>
        Create
      </Button>
      <Modal {...modalProps} title={`Create ${resource?.options?.label}`}>
        <Form {...formProps} layout="vertical">
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
