import { Authenticated } from "@refinedev/core";
import { ShortlistPackageSectionCreate } from './Create'
import { ShortlistPackageSectionEdit } from './Edit'

export { ShortlistPackageSectionCreate } from './Create'
export { ShortlistPackageSectionEdit } from './Edit'
export { ShortlistPackageSectionForm } from './Form'
export { ShortlistPackageSectionList } from './List'

export const AuthShortlistPackageSectionCreate = () => (
  <Authenticated>
    <ShortlistPackageSectionCreate />
  </Authenticated>
)

export const AuthShortlistPackageSectionEdit = () => (
  <Authenticated>
    <ShortlistPackageSectionEdit />
  </Authenticated>
)
