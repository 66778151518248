import { Edit } from "@refinedev/antd";
import { Spin } from "antd";
import { useForm } from 'components/hooks/useForm'
import { ICelebration } from 'interfaces/celebration'
import { CelebrationForm } from './Form'

export const CelebrationEdit: React.FC = () => {
  const useFormProps = useForm<ICelebration>()

  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      {useFormProps.formLoading ? (
        <Spin />
      ) : (
        <CelebrationForm useFormProps={useFormProps} />
      )}
    </Edit>
  )
}
