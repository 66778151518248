import { Create } from "@refinedev/antd";
import { LayoutWrapper, useNavigation, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { IEvent } from 'interfaces/customer'
import { EventForm } from './Form'

export const EventCreate: React.FC = () => {
  const { useParams } = useRouterContext()
  const { customer_id } = useParams<any>()
  const { show, goBack } = useNavigation()

  const useFormProps = useForm<IEvent>({
    action: 'create',
    resource: `package_tool/customers/${customer_id}/events`,
    onMutationSuccess: () => {
      show('package_tool/customers', customer_id as string)
    },
    redirect: false,
  })

  return (
    <LayoutWrapper>
      <Create
        saveButtonProps={useFormProps.saveButtonProps}
        title="Create Project"
        headerProps={{
          onBack: () => goBack(),
          breadcrumb: <></>,
          extra: null,
        }}>
        <EventForm useFormProps={useFormProps} />
      </Create>
    </LayoutWrapper>
  );
}
