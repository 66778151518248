import { DateField, useForm, useModal, useTable } from '@refinedev/antd'
import { BaseKey } from '@refinedev/core'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd'
import { ModalProps } from 'antd/lib'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { GroupedVenueAvailability, IVenueAvailability } from 'interfaces/venue'
import { useMemo, useState } from 'react'
import { FaQuestion, FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { VENUE_AVAILABILITY_URL, VENUE_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

const getDates = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const allDates = []
  let currentDate = startDate
  const endDateObj = endDate
  while (
    currentDate.isBefore(endDateObj, 'day') ||
    currentDate.isSame(endDateObj, 'day')
  ) {
    allDates.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }

  return allDates
}

export const AvailabilityTable = ({}) => {
  const [finalData, setFinalData] = useState<any>([])

  const thisMonth = [dayjs().startOf('month'), dayjs().endOf('month')]

  const { tableQueryResult, tableProps, searchFormProps, filters } =
    useTable<GroupedVenueAvailability>({
      resource: `${VENUE_AVAILABILITY_URL}/grouped_by_venue`,
      syncWithLocation: true,
      filters: {
        initial: [
          {
            field: 'date',
            operator: 'between',
            value: thisMonth
              .map((value) => value.format('YYYY-MM-DD'))
              .join(','),
          },
        ],
      },
      queryOptions: {
        onSuccess(data) {
          setFinalData([])
          data?.data?.forEach((item) => {
            const finalItem: any = { ...item }
            allDates.forEach((date) => {
              finalItem[date] = item.availabilities.find(
                (avail) => avail.date === date,
              )
            })
            setFinalData((prev: any) => [...prev, finalItem])
          })
        },
      },
      onSearch(data: any) {
        return [
          {
            field: 'date',
            operator: 'between',
            value: data.date
              .map((value: any) => value.format('YYYY-MM-DD'))
              .join(','),
          },
          {
            field: 'venue',
            operator: 'in',
            value: data.venue.join(','),
          },
        ]
      },
    })

  const tableDate = Form.useWatch('date', searchFormProps.form)

  const allDates = useMemo(
    () => (!!tableDate ? getDates(tableDate[0], tableDate[1]) : []),
    [tableDate],
  )
  const columns = useMemo(() => {
    return allDates.map((date) => {
      return {
        title: date,
        dataIndex: date,
        render: (
          data: IVenueAvailability,
          record: GroupedVenueAvailability,
        ) => (
          <AvailabilityDetailCell
            date={date}
            data={data}
            record={record}
            refetch={tableQueryResult.refetch}
          />
        ),
      }
    })
  }, [allDates])

  return (
    <>
      <Form
        {...searchFormProps}
        initialValues={getSearchFormInitialValue(filters, {
          date: (value: any) => value?.map((date: string) => dayjs(date)),
          venue: (value: any) =>
            !!value ? value?.map((id: any) => Number(id)) : undefined,
        })}
        layout="inline">
        <Form.Item name="venue" label="Venue">
          <SelectWithDefault
            useSelectProps={{ resource: VENUE_URL, optionLabel: 'name' }}
            mode="multiple"
            popupMatchSelectWidth={false}
          />
        </Form.Item>
        <Form.Item name={'date'} label="Date Range">
          <DatePicker.RangePicker
            presets={[
              {
                label: 'This Month',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
              },
            ]}
          />
        </Form.Item>
        <Button onClick={() => searchFormProps.form?.submit()} size="small">
          Submit
        </Button>
      </Form>
      <Divider />
      <Table
        {...tableProps}
        dataSource={finalData}
        bordered
        scroll={{ x: 'max-content' }}
        columns={[
          { title: 'Name', dataIndex: 'name', fixed: 'left' },
          ...columns,
        ]}
      />
    </>
  )
}

const AvailabilityDetailCell = (props: {
  date: string
  data?: IVenueAvailability
  record: GroupedVenueAvailability
  refetch?: () => void
}) => {
  const { modalProps, record, show, date, venueId } =
    useAvailabilityDetailModal()

  return (
    <>
      <div
        onClick={() => show(props.record.id, props.date, props.data)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <AvailabilityDataCard data={props.data} />
      </div>
      <AvailabilityDetailModal
        record={record}
        modalProps={modalProps}
        date={date}
        venueId={venueId}
        refetch={props.refetch}
      />
    </>
  )
}

const AvailabilityDataCard = (props: { data?: IVenueAvailability }) => {
  return (
    <Space direction="vertical" size={1} align="center">
      {props.data === undefined ? (
        <FaQuestion color="grey" />
      ) : props.data?.is_available ? (
        <FaThumbsUp color="green" />
      ) : (
        <FaThumbsDown color="red" />
      )}
      {!!props.data?.updated_at && (
        <Typography.Text type="secondary">
          <DateField
            value={props.data?.updated_at}
            format="LLL"
            type="secondary"
            style={{ fontSize: 10 }}
          />
        </Typography.Text>
      )}
    </Space>
  )
}

const AvailabilityDetailModal = (props: {
  record?: IVenueAvailability
  date?: string
  venueId?: BaseKey
  modalProps: ModalProps
  refetch?: () => void
}) => {
  const { formProps, saveButtonProps } = useForm({
    resource: VENUE_AVAILABILITY_URL,
    redirect: false,
    action: props.record === undefined ? 'create' : 'edit',
    id: props.record?.id,
    onMutationSuccess: () => {
      props.refetch && props.refetch()
      props.modalProps.onCancel!({} as any)
    },
  })

  return (
    <Modal
      {...props.modalProps}
      title={`Date: ${props.date}`}
      onOk={(e) => {
        saveButtonProps.onClick()
      }}
      destroyOnClose>
      <Form
        {...formProps}
        preserve={false}
        layout="inline"
        onFinish={(values) =>
          formProps.onFinish &&
          formProps.onFinish({
            ...values,
            venue: props.venueId,
            date: props.date,
          })
        }>
        <Form.Item
          name="is_available"
          label="Available"
          valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="price" label="Price">
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const useAvailabilityDetailModal = () => {
  const [record, setRecord] = useState<IVenueAvailability>()
  const [venueId, setVenueId] = useState<BaseKey>()

  const [date, setDate] = useState<string>()
  const { show, ...useModalProps } = useModal()
  const newShow = (
    workingVenueId: BaseKey,
    workingDate: string,
    item?: IVenueAvailability,
  ) => {
    setRecord(item)
    setDate(workingDate)
    setVenueId(workingVenueId)
    show()
  }

  return { ...useModalProps, show: newShow, record, date, venueId }
}
