import { BaseKey, useCan, useDelete } from '@refinedev/core'
import { List as AntdList, List, Button, Typography } from 'antd'
import { IContributor } from 'interfaces/customer'
import React, { useState, createContext } from 'react'
import { CONTRIBUTOR_URL } from 'urls'
import { useSimpleList, DateField } from '@refinedev/antd'
import { ContributorCreate } from './Create'
import { CreateButton } from '@refinedev/antd'
import * as Icons from '@ant-design/icons'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

export const ContributorCreateContext = createContext<{
  isCreating?: boolean
  setIsCreating?: CallableFunction
}>({})

const ContributorItem: React.FC<{
  item: IContributor
  id: number
  canDelete?: boolean
}> = ({ item, id, canDelete }) => {
  const { mutate: deleteContributor } = useDelete<IContributor>()

  return (
    <List.Item
      actions={[
        canDelete && (
          <Button
            key={id}
            icon={<Icons.DeleteOutlined />}
            title="Delete"
            onClick={() => {
              deleteContributor({
                resource: CONTRIBUTOR_URL,
                id: item.id,
              })
            }}
          />
        ),
      ]}>
      <List.Item.Meta
        avatar={<Icons.UserOutlined />}
        title={`${item.user.first_name} ${item.user.last_name}`}
        description={`Added on : ${dayjs(item.created_at).format('LLL')}`}
      />
    </List.Item>
  )
}

export const ContributorsList: React.FC<{ projectId: BaseKey }> = ({
  projectId,
}) => {
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const canDeleteContributor = useCan({
    resource: 'can_delete_contributor',
    action: '',
  })
  const { listProps, queryResult: contributorData } =
    useSimpleList<IContributor>({
      resource: CONTRIBUTOR_URL,
      filters: {
        permanent: [
          {
            field: 'event',
            operator: 'eq',
            value: projectId,
          },
        ],
      },
      queryOptions: {
        enabled: !!projectId,
      },
    })
  return (
    <>
      <ContributorCreateContext.Provider
        value={{
          isCreating,
          setIsCreating,
        }}>
        <AntdList
          {...listProps}
          rowKey="id"
          size="small"
          loading={contributorData?.isFetching}
          header={
            <>
              <Typography.Text strong>
                Total Contributors ({contributorData?.data?.total ?? 0})
              </Typography.Text>
              {isCreating && <ContributorCreate projectId={projectId} />}
              {!isCreating ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <CreateButton
                    onClick={() => setIsCreating(true)}
                    aria-label="Add Contributor"
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          }
          renderItem={(item: IContributor, idx: number) => {
            return (
              <ContributorItem
                item={item}
                id={idx}
                canDelete={canDeleteContributor?.data?.can}
              />
            )
          }}
        />
      </ContributorCreateContext.Provider>
    </>
  )
}
