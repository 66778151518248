import { DateField, useForm, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  List as AntdList,
  Button,
  Descriptions,
  Divider,
  Select,
  Space,
  Spin,
  Statistic,
  Tag,
  Upload,
} from 'antd'

import { BaseKey, useApiUrl, useCustom, useOne } from '@refinedev/core'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import React, { useContext, useState } from 'react'
import { SESSION_URL, VENUE_URL } from 'urls'
import { ISession } from 'interfaces/catering'
import {
  EditPackageContext,
  EditPackageContextType,
} from 'pages/package_tool/shortlistPackage/Edit'
import { EditSession } from './Edit'
import AddMenu from './AddMenu'
import { MenuComponent, MenuShow } from '../menu/Show'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'

export const SessionDetail: React.FC<{
  sessionId: BaseKey
  refetch: any
  package: string
}> = ({ refetch, package: shortlistPackageId, sessionId }) => {
  const apiUrl = useApiUrl()

  const { editPackageStatus, setEditPackageStatus, isFinalized } = useContext(
    EditPackageContext,
  ) as EditPackageContextType
  const _package = useContext(ShortlistPackageContext)
  const [addMenuItem, setAddMenuItem] = useState<boolean>(false)

  const {
    data: session,
    isLoading,
    refetch: sessionRefetch,
    isRefetching,
  } = useCustom<ISession>({
    url: `${apiUrl}/${SESSION_URL}/${sessionId}`,
    method: 'get',
    config: {
      filters: [
        {
          field: 'expand',
          operator: 'eq',
          value: 'section',
        },
      ],
    },
  })

  const sessionData = session?.data

  const refetchAll = () => {
    refetch()
    sessionRefetch()
  }

  const {
    modalProps: editSessionModalProps,
    formProps: editSessionFormProps,
    show: editSessionModalShow,
  } = useModalForm<ISession>({
    resource: SESSION_URL,
    action: 'edit',
    id: sessionData?.id as BaseKey,
    redirect: false,
    onMutationSuccess: () => {
      refetchAll()
    },
  })

  return (
    <>
      <Spin spinning={isLoading || isRefetching}>
        {sessionData && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space align="baseline" size="large">
                <Statistic
                  title="Session Total"
                  value={
                    sessionData?.session_total
                      ? '₹ ' + sessionData?.session_total
                      : '₹ ' + 0
                  }
                />
              </Space>
              <Space>
                {editPackageStatus && !isFinalized && !_package?.is_cancelled && (
                  <Button
                    icon={<Icons.EditOutlined />}
                    size="small"
                    onClick={() => {
                      editSessionModalShow(sessionData?.id)
                    }}
                  />
                )}
              </Space>
            </div>
            <Divider>Time and Venue</Divider>
            <Descriptions>
              {sessionData.date_not_decided && (
                <Descriptions.Item label="Date">
                  <Tag color="red">Not Decided</Tag>
                </Descriptions.Item>
              )}
              {!sessionData.date_not_decided && (
                <>
                  <Descriptions.Item label="Date and Time">
                    <DateField value={sessionData.date_time} format="LLL" />
                  </Descriptions.Item>
                </>
              )}
              {sessionData.venue_not_decided && (
                <Descriptions.Item label="Venue">
                  <Tag color="red">Not Decided</Tag>
                </Descriptions.Item>
              )}
              {!sessionData.venue_not_decided && (
                <Descriptions.Item label="Venue">
                  <SelectWithDefault
                    disabled
                    bordered={false}
                    size="small"
                    useSelectProps={{
                      resource: VENUE_URL,
                      optionLabel: 'name',
                    }}
                    value={sessionData.venue}
                  />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Pax">
                {sessionData.pax}
              </Descriptions.Item>
              <Descriptions.Item label="Event">
                {sessionData?.section?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Meal Type">
                {sessionData?.meal_type}
              </Descriptions.Item>
            </Descriptions>
            <Divider>Items</Divider>
            {/*  Add new Shortlist Item */}
            {sessionData && editPackageStatus && (
              <EditSession
                modalProps={editSessionModalProps}
                formProps={editSessionFormProps}
                shortlistPackageId={shortlistPackageId}
              />
            )}

            {sessionData.menus.length > 0 ? (
              <MenuComponent
                menuId={sessionData.menus[0].id}
                sessionId={sessionId}
                packageId={sessionData.shortlist_package}
                refetchSession={sessionRefetch}
                pax={sessionData.pax}
              />
            ) : (
              !_package?.is_cancelled && <AddMenu refetch={refetchAll} sessionId={sessionId} />
            )}
          </div>
        )}
      </Spin>
    </>
  )
}
