import { Edit } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { IPlan } from 'interfaces/shortlistPackage'
import { PlanForm } from './Form'

export const PlanEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IPlan>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <PlanForm formProps={formProps} />
    </Edit>
  )
}
