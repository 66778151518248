import { useForm } from "@refinedev/antd";
import { Card, notification } from "antd";
import { BaseKey } from "@refinedev/core";
import { ITask } from 'interfaces/customer'
import { useHotkeys } from 'react-hotkeys-hook'
import { TASK_URL } from 'urls'
import { TaskForm } from './Form'

export const TaskCreate = ({ pid }: { pid: BaseKey }) => {
  let taskFormProps = useForm<ITask>({
    action: 'create',
    resource: TASK_URL,
    redirect: false,
  })

  useHotkeys('ctrl+s, cmd+s', (e) => {
    e.preventDefault()
    notification.info({
      message: 'Saving',
      duration: 1,
      description: 'Save in progress',
    })
    taskFormProps.formProps!.form!.submit()
  })

  return (
    <Card title="Add new" size="small">
      <TaskForm useFormProps={taskFormProps} pid={pid} />
    </Card>
  )
}
