import React, { useState } from 'react'
import { useModal } from '@refinedev/core'
import { useSelect } from '@refinedev/antd'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Upload,
} from 'antd'
import { IEvent } from 'interfaces/customer'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SERVICE_URL } from 'urls'
import { ServicePaymentSplit } from 'interfaces/payment'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'

export const AddPaymentModal: React.FC<{
  useFormProps: any
  project: IEvent
}> = ({ useFormProps, project }) => {
  const { visible, show, close } = useModal()

  function validatePaymentSplitTotal(
    payment_splits: ServicePaymentSplit[] | undefined,
    amount: number,
  ): boolean {
    if (!payment_splits || payment_splits.length === 0) {
      return false
    }

    const totalPaymentSplit = payment_splits.reduce(
      (sum: number, split) => sum + split.split_amount,
      0,
    )
    return totalPaymentSplit === amount
  }

  function AddPaymentForm(props: any) {
    const { formProps } = props.useFormProps

    const { selectProps } = useSelect({
      resource: 'package_tool/payment_types',
      optionValue: 'id',
      optionLabel: 'name',
      pagination: {
        mode: 'server',
      },
    })

    const [form] = Form.useForm();

    return (
      <Card>
        <Form
          form={form}
          {...formProps}
          preserve={false}
          layout="vertical"
          onFinish={(values: any) => {
            const isTotalValid = validatePaymentSplitTotal(
              values.service_payment_splits,
              values.amount,
            )
            if (!isTotalValid) {
              alert(
                'Payment split amount does not sum to total amount or missing service',
              )
              return
            }
            const formData = new FormData();
            formData.append('type', String(values.type));
            formData.append('amount', String(values.amount));
            formData.append('transaction_date_time', values.transaction_date_time.toISOString());
            formData.append('method', values.method);
            formData.append('link_url', values.link_url || '');
            formData.append('generate_link', "false")
            formData.append('event', String(project.id)); 
            formData.append('customer', String(project.user));

            if (values.media) {
              formData.append('media', values.media.file);
            }

            if (values.service_payment_splits) {
              formData.append(
                'service_payment_splits',
                JSON.stringify(values.service_payment_splits),
              );
            }

            formProps?.onFinish && formProps.onFinish(formData); // Pass FormData to submit handler
            close(); // Close modal on successful submission
          }}>
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
              },
            ]}>
            <Select {...selectProps} />
          </Form.Item>
          <Space>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputNumber />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="transaction_date_time"
              label="Transaction Date and time">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
            <Form.Item
              name="method"
              label="Transaction Method"
              initialValue={'Cashfree'}>
              <Select
                options={[
                  { label: 'Cashfree', value: 'Cashfree' },
                  { label: 'Cash', value: 'Cash' },
                  { label: 'Bank Account', value: 'Bank Account' },
                  { label: 'UPI', value: 'UPI' },
                  { label: 'Cheque', value: 'Cheque' },
                ]}
                defaultActiveFirstOption
              />
            </Form.Item>
          </Space>
          <Form.List name="service_payment_splits">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                    }}
                    align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'service']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <SelectWithDefault
                        popupMatchSelectWidth={false}
                        useSelectProps={{
                          resource: SERVICE_URL,
                          optionLabel: 'name',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'split_amount']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}>
                      <InputNumber />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add Service Payment Split
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item name="link_url" label="Link">
            <Input />
          </Form.Item>
          <Form.Item
            name="media"
            label="Media"
            dependencies={['method']}
            rules={[
              ({ getFieldValue }) => ({
                required: ['Bank Account', 'Cheque', 'UPI'].includes(getFieldValue('method')),
                message: 'Media is required when the payment method is Bank Account',
              }),
            ]}
          >
            <Upload
              name="media"
              beforeUpload={() => false}
              listType="text"
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Card>
    )
  }

  return (
    <>
      <Button
        style={{ borderRadius: '1px', marginTop: '30px' }}
        onClick={() => show()}>
        Add Payment
      </Button>
      <Modal
        title="Add Payment"
        open={visible}
        onCancel={() => {
          close()
          useFormProps.form.resetFields()
        }}
        onOk={() => {
          useFormProps.form
            .validateFields()
            .then(() => {
              useFormProps.form.submit()
            })
            .catch(() => {
              // Do nothing, form validation failed
            })
        }}>
        <AddPaymentForm useFormProps={useFormProps} project={project} />
      </Modal>
    </>
  )
}