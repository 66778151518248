import {
  CreateButton,
  EditButton,
  ExportButton,
  List,
  SaveButton,
  TextField,
  useEditableTable,
} from "@refinedev/antd";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Modal,
} from "antd";

import {
  BaseKey,
  HttpError,
  useDeleteMany,
  useExport,
  useNavigation,
  useUpdate,
  useModal,
  useApiUrl,
  useCustomMutation,
} from "@refinedev/core";

import { DeleteButton } from 'components/button'
import { PriceField } from 'components/field'
import { EyeToggle } from 'components/input/EyeToggle'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { BUNDLE, FABRIC, FLORAL, STRUCTURE } from 'consts'
import {
  IColor,
  ICombination,
  ICombinationList,
  IFabricType,
  IFlowerList,
  IFlowerType,
  IFoliageType,
} from 'interfaces/pricing'
import Papa from 'papaparse'
import { useState } from 'react'
import {
  COLOR_URL,
  COMBINATION_URL,
  FABRIC_TYPE_URL,
  FLOWER_TYPE_URL,
  FOLIAGE_TYPE_URL,
} from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import './index.less'

export const CombinationList: React.FC = () => {
  const tProps = useEditableTable<
    ICombinationList,
    HttpError,
    { search: string; category: string }
  >({
    syncWithLocation: true,
    initialPageSize: 100,
    onSearch: (data: any) => [
      { field: 'search', operator: 'eq', value: data.search },
      { field: 'category', operator: 'eq', value: data.category },
      { field: 'flower__color', operator: 'eq', value: data.flower__color },
      {
        field: 'flower__flower_type',
        operator: 'eq',
        value: data.flower__flower_type,
      },
      { field: 'foliage', operator: 'eq', value: data.foliage },
      { field: 'fabric', operator: 'eq', value: data.fabric },
    ],
  })

  const { triggerExport, isLoading } = useExport<ICombinationList>({
    mapData: (item) => ({
      id: item.id,
      item: item.item.name,
      flower: item.flower
        ? `${item.flower?.color.name} ${item.flower?.flower_type.name}`
        : '',
      foliage: item.foliage?.name ?? '',
      fabric: item.fabric?.name ?? '',
      rate: item.rate,
      margin: item.margin,
    }),
    pageSize: 150,
  })

  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()
  const { mutate: update } = useUpdate()
  const { mutate: updateItem } = useUpdate()
  const apiUrl = useApiUrl()
  const { mutate: updateCombinations } = useCustomMutation()

  const { visible, close, show } = useModal()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: COMBINATION_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      !!selectedRowKeys.length && <EditButton onClick={()=>show()}>Update</EditButton>,
      <ExportButton key="export" onClick={triggerExport} loading={isLoading} />,
      <CreateButton key="create" />,
    ],
  }

  const handleToggle = (id: BaseKey) => {
    updateItem({
      resource: COMBINATION_URL,
      id: `${id}/toggle_visibility`,
      values: {},
    })
  }

  const handleChange = ({ file }: any) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        results.data.forEach((d: any) => {
          update({
            resource: COMBINATION_URL,
            id: d.id,
            values: { rate: d.rate, margin: d.margin },
            successNotification: false,
            invalidates: [],
          })
        })
      },
    })
  }

  const onFinish = (values: any) => {
    values['combination_ids'] = selectedRowKeys
    updateCombinations(
      {
        url: `${apiUrl}/cms/combinations/set_rate_price/`,
        values,
        method: 'post',
      },
      {
        onSuccess: (data) =>
        tProps.tableQueryResult?.refetch()
      },
    )
    setSelectedRowKeys([])
    close();
  };

  return (
    <Row gutter={[8, 8]}>
      
      <Modal
        visible={visible}
        onCancel={() => {
          close()
        }}
        footer={null}
        className="combinationModal"
        title={"Update Combination"}
        destroyOnClose>
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item name="rate" label="Rate">
              <InputNumber controls={false} className="modalNumberInput"/>
            </Form.Item>
            <Form.Item name="markup" label="Markup">
              <InputNumber controls={false} className="modalNumberInput"/>
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>

      <Col lg={4} sm={24}>
        <Card title="Filters" size="small">
          <Form
            {...tProps.searchFormProps}
            initialValues={getSearchFormInitialValue(tProps.filters)}
            layout="vertical">
            <Form.Item name="search" label="Search">
              <Input.Search
                allowClear
                onSearch={(value) => tProps?.searchFormProps?.form?.submit()}
              />
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Select
                allowClear
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                options={[
                  { value: STRUCTURE, label: STRUCTURE },
                  { value: FLORAL, label: FLORAL },
                  { value: FABRIC, label: FABRIC },
                ]}
              />
            </Form.Item>
            <Form.Item name="flower__color" label="Color">
              <SelectWithDefault<IColor>
                allowClear
                size="small"
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                useSelectProps={{ resource: COLOR_URL, optionLabel: 'name' }}
              />
            </Form.Item>
            <Form.Item name="flower__flower_type" label="Flower">
              <SelectWithDefault<IFlowerType>
                allowClear
                size="small"
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                useSelectProps={{
                  resource: FLOWER_TYPE_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
            <Form.Item name="foliage" label="Foliage">
              <SelectWithDefault<IFoliageType>
                allowClear
                size="small"
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                useSelectProps={{
                  resource: FOLIAGE_TYPE_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
            <Form.Item name="fabric" label="Fabric">
              <SelectWithDefault<IFabricType>
                allowClear
                size="small"
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                useSelectProps={{
                  resource: FABRIC_TYPE_URL,
                  optionLabel: 'name',
                }}
              />
            </Form.Item>
          </Form>
          <input
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                handleChange({
                  file: event.target.files[0],
                })
              }
            }}
          />
        </Card>
      </Col>
      <Col lg={20} sm={24}>
        <List headerProps={pageHeaderProps}>
          <Form {...tProps.formProps}>
            <Table
              {...tProps.tableProps}
              rowKey="id"
              rowSelection={rowSelection}
              size="small"
              onRow={(record) => ({
                onClick: (event) => edit(COMBINATION_URL, record.id),
              })}>
              <Table.Column dataIndex={['item', 'name']} title="Item" />
              <Table.Column<ICombinationList>
                dataIndex={['flower']}
                title="Flower"
                render={(value: IFlowerList) => (
                  <>
                    {value?.color?.name} {value?.flower_type?.name}
                  </>
                )}
              />
              <Table.Column<ICombinationList>
                dataIndex={['foliage', 'name']}
                title="Foliage"
              />
              <Table.Column<ICombinationList>
                dataIndex={['fabric', 'name']}
                title="Fabric"
              />
              <Table.Column<ICombinationList>
                dataIndex={['category']}
                title="Category"
              />
              <Table.Column<ICombination>
                key="rate"
                dataIndex="rate"
                title="Rate"
                render={(value, record) => {
                  if (tProps.isEditing(record.id)) {
                    return (
                      <div onClick={(e) => e.stopPropagation()}>
                        <Form.Item name="rate" style={{ margin: 0 }}>
                          <InputNumber />
                        </Form.Item>
                      </div>
                    )
                  }
                  return <PriceField value={value ?? 0} />
                }}
              />
              <Table.Column<ICombinationList>
                key="margin"
                dataIndex="margin"
                title="Markup (%)"
                render={(value, record) => {
                  if (tProps.isEditing(record.id)) {
                    return (
                      <div onClick={(e) => e.stopPropagation()}>
                        <Form.Item name="margin" style={{ margin: 0 }}>
                          <InputNumber />
                        </Form.Item>
                      </div>
                    )
                  }
                  return <TextField value={value} />
                }}
              />
              <Table.Column<ICombinationList>
                dataIndex="price"
                title="Price"
                render={(value) => {
                  return <PriceField value={value} />
                }}
              />
              <Table.Column<ICombinationList>
                dataIndex={['is_visible']}
                title="Visible"
                render={(value: boolean, record) => (
                  <div onClick={(e) => e.stopPropagation()}>
                    {tProps.isEditing(record.id) ? (
                      <Space>
                        <SaveButton {...tProps.saveButtonProps} size="small" />
                        <Button {...tProps.cancelButtonProps} size="small">
                          Cancel
                        </Button>
                      </Space>
                    ) : (
                      <Space>
                        <EyeToggle
                          value={value}
                          onChange={() => handleToggle(record.id)}
                        />
                        <EditButton
                          {...tProps.editButtonProps(record.id)}
                          size="small"
                        />
                      </Space>
                    )}
                  </div>
                )}
              />
            </Table>
          </Form>
        </List>
      </Col>
    </Row>
  );
}
