import { Edit } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ISection } from 'interfaces/section'
import { MoodBoardImageForm } from './Form'

export const MoodBoardImageEdit: React.FC = () => {
  const useFormProps = useForm<any>()
  console.log(useFormProps)
  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      <MoodBoardImageForm useFormProps={useFormProps} />
    </Edit>
  )
}
