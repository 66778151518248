import { useTable } from "@refinedev/antd";
import { Col, Row } from "antd";
import { ICustomer } from 'interfaces/customer'
import { CustomerTable } from '.'

export const CustomerList: React.FC = () => {
  const tProps = useTable<ICustomer, any, { search: string }>({
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    syncWithLocation: true
  })

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24}>
        <CustomerTable tProps={tProps} />
      </Col>
    </Row>
  )
}
