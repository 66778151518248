import { List, useModalForm, useTable } from '@refinedev/antd'
import {
  BaseKey,
  useDeleteMany,
  useModal,
  useNavigation,
  useRouterContext,
} from '@refinedev/core'
import { Button, Modal, Table } from 'antd'
import { DeleteButton } from 'components/button'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { IMenu } from 'interfaces/catering'
import { useState } from 'react'
import { MENU_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'
import { MenuForm } from './Form'
import { CopyOutlined } from '@ant-design/icons'
import { CloneMenu } from './CloneMenu'
import Link from 'antd/lib/typography/Link'

export const MenuList: React.FC = () => {
  const [menuId, setMenuId] = useState<BaseKey>()
  const tProps = useTable<IMenu, any, any>({
    syncWithLocation: true,
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],
    filters: {
      permanent: [{ field: 'is_template', operator: 'eq', value: true }],
    },
  })
  const { show } = useNavigation()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const {
    visible: cloneVisible,
    show: showCloneMenuForm,
    close: closeCloneMenuForm,
  } = useModal()

  const {
    modalProps,
    formProps,
    show: showModal,
  } = useModalForm<IMenu>({
    resource: MENU_URL,
    action: 'create',
  })

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: MENU_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      <TableSearchInput
        key="search"
        searchFormProps={tProps.searchFormProps}
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <Button onClick={() => showModal()} key="create" type="primary">
        Create
      </Button>,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small">
        <Table.Column
          dataIndex={['name']}
          title="Name"
          render={(value, record: any) => {
            return (
              <Link onClick={(event: any) => show(MENU_URL, record.id)}>
                {value}
              </Link>
            )
          }}
        />
        <Table.Column
          dataIndex={['is_published']}
          title="Published"
          render={(value) => {
            return <div>{value ? 'Yes' : 'No'}</div>
          }}
        />
        <Table.Column
          dataIndex={['for_consultation_tool']}
          title="For Consultation tool"
          render={(value) => {
            return <div>{value ? 'Yes' : 'No'}</div>
          }}
        />
        <Table.Column
          title="Clone"
          render={(_, record: any) => {
            return (
              <Button
                icon={<CopyOutlined />}
                size="small"
                onClick={() => {
                  console.log(record)
                  setMenuId(record.id)
                  showCloneMenuForm()
                }}
              />
            )
          }}
        />
      </Table>
      <Modal {...modalProps}>
        <MenuForm formProps={formProps} />
      </Modal>
      <CloneMenu
        visible={cloneVisible}
        close={closeCloneMenuForm}
        menuId={menuId}
      />
    </List>
  )
}
