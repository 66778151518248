import { Button, Form, Input, Select, Space, Switch } from "antd";
import { BaseKey } from "@refinedev/core";
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { UseFormReturnType } from 'interfaces/refine'
import { IStaff } from 'interfaces/staff'
import { STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'

export const TimelineForm: React.FC<{
  useFormProps: UseFormReturnType
  pid: BaseKey
  showSave?: boolean
}> = ({ useFormProps, pid, showSave = true }) => {
  const { formProps } = useFormProps

  return (
    <Form
      {...useFormProps.formProps}
      layout="vertical"
      initialValues={{
        ...useFormProps.formProps.initialValues,
        assigned_to: formProps.initialValues?.assigned_to?.id,
      }}
      onFinish={(values) =>
        useFormProps.onFinish &&
        useFormProps.onFinish({ ...values, project: pid })
      }>
      <Form.Item name="text" label="Objective" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>

      <Space>
        <Form.Item name="assigned_to" label="Assigned To">
          <SelectWithDefault<IStaff>
            style={{ width: 300 }}
            useSelectProps={{ resource: STAFF_URL, optionValue: 'id' }}
            renderOptions={(items) =>
              items?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {getUserName(item, false)}
                </Select.Option>
              ))
            }
          />
        </Form.Item>

        <Form.Item name="deadline" label="Deadline">
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          name="is_done"
          label="Done?"
          initialValue={false}
          valuePropName="checked">
          <Switch />
        </Form.Item>
        {showSave && (
          <Button
            size="small"
            type="primary"
            onClick={useFormProps.form.submit}>
            Save
          </Button>
        )}
      </Space>
    </Form>
  )
}
