import { DateField, ImageField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { UploadFile } from 'antd/lib/upload/interface'

import {
  AutoComplete,
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd'

import {
  file2Base64,
  useApiUrl,
  useCan,
  useCustom,
  useList,
} from '@refinedev/core'
import { RowRemoveButton } from 'components/button'
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import { DynamicInput } from 'components/input'
import { DraggableMultiImageUpload } from 'components/input/DraggableMultiImageUpload'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import {
  DRAFT,
  IMAGE,
  INTERNAL,
  REQUIRES_ITEMS,
  REQUIRES_OPTIONS,
} from 'consts'
import { ProductContext } from 'contexts/product'
import parse from 'html-react-parser'
import { IAttribute } from 'interfaces/attribute'
import { ICategory } from 'interfaces/category'
import {
  IInventory,
  IProduct,
  IProductImage,
  IProductOption,
} from 'interfaces/product'
import { UseFormReturnType } from 'interfaces/refine'
import { IStaff } from 'interfaces/staff'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { PLAN_URL, PRODUCT_URL, STAFF_URL } from 'urls'
import { InventoryList } from './formComponents/InventoryList'
import { IPlan } from 'interfaces/shortlistPackage'

const { TabPane } = Tabs

export const ProductForm: React.FC<{
  useFormProps: UseFormReturnType<IProduct>
  isEdit?: boolean
}> = ({ useFormProps, isEdit }) => {
  const { formProps, queryResult, form } = useFormProps
  const apiUrl = useApiUrl()

  // states
  const [selectedCategory, setSelectedCategory] = useState<ICategory>()
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([])
  const [value, setValue] = useState<string>('')
  const [options, setOptions] = useState<any[]>([])

  // useWatch
  const isSpace = Form.useWatch('is_space', form)
  const isExecuted = Form.useWatch('is_executed', form)
  const executedInVenue = Form.useWatch('executed_in_venue', form)
  const {
    selectProps: categorySelectProps,
    queryResult: categoryQueryResult,
    defaultValueQueryResult: defaultCategoryQueryResult,
  } = useSelectWithSearch<ICategory>({
    resource: 'cms/category',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 20,
    defaultValue: queryResult?.data?.data.category,
    defaultValueQueryOptions: {
      enabled: !!queryResult?.data?.data.category,
    },
  })

  const attributeQueryResult = useList<IAttribute>({
    resource: 'core/attributes/',

    pagination: {
      pageSize: 100,
    },
  })

  // get list of products to show in items tab as autocomplete
  const { refetch: refetchProducts } = useList<IProduct>({
    resource: 'cms/product/',

    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const productOptionGroup = data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
        if (productOptionGroup.length > 0) {
          setOptions([
            {
              label: 'Products',
              options: productOptionGroup,
            },
          ])
        }
      },
    },

    filters: [{ field: 'name', operator: 'contains', value }],
  })

  const { data } = useCustom<IProductOption>({
    url: `${apiUrl}/cms/product/`,
    method: 'options',
  })

  const ownerOptions = data?.data.actions?.POST?.owner.choices.map(
    (choice, i) => ({
      key: i,
      label: choice.display_name,
      value: choice.value,
    }),
  )

  const stageOptions = data?.data.actions?.POST?.stage.choices.map(
    (choice, i) => ({
      key: i,
      label: choice.display_name,
      value: choice.value,
    }),
  )

  // on autocomplete value change refetch the products and build the options
  useEffect(() => {
    setOptions([])
    refetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  // permissions
  const { data: accessInventory } = useCan({
    resource: 'access_inventory',
    action: '',
  })

  const { data: writeCatalog } = useCan({
    resource: 'write_catalog',
    action: '',
  })

  const isDeleted = queryResult?.data?.data.is_deleted

  // build the images list in the format required by the antd form
  useEffect(() => {
    const existingFiles: UploadFile[] = []
    queryResult?.data?.data?.images.map((image: IProductImage) =>
      existingFiles.push({
        uid: image.id,
        name: 'test.png',
        url: image.image,
        thumbUrl: image.image,
        status: 'done',
      }),
    )
    setImageFileList(existingFiles)
  }, [queryResult])

  // set the selectedCategory once all the category are fetched
  // this is used to build the attributes
  useEffect(() => {
    if (categoryQueryResult.data?.data) {
      setSelectedCategory(
        categoryQueryResult.data?.data
          .concat(defaultCategoryQueryResult.data?.data ?? [])
          .find((cat) => cat.id === queryResult?.data?.data.category),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryQueryResult.isLoading])

  /**
   * Calculate the profit and margin for the inventory when the values change
   */
  const onValuesChange = (changedValues: any, values: any) => {
    // only calculate the values if inventory value has changed
    if ('inventory' in changedValues) {
      formProps.form?.setFieldsValue({
        inventory: values.inventory.map((item: IInventory, index: number) => {
          const profit = item.price - (item.cost_price ?? 0) // if the cost price is not there , just assume it to be 0
          const margin = +((profit / item.price) * 100).toFixed(2)
          return {
            ...item,
            profit,
            margin,
          }
        }),
      })
    }
  }

  const onFinish = async (values: any) => {
    const imageFiles: { id?: string; image?: string; order: number }[] = []

    // generate a list of map with attribute id and value
    const attributesWithValue = []
    for (let key in values) {
      if (key.match(/^generated_/)) {
        const keySplit = key.split('_')
        const type = keySplit[3]
        let valueOptions:
          | ({ [s: string]: unknown } | ArrayLike<unknown>)[]
          | { option: any }[] = []
        let images = []

        // build option values
        if (REQUIRES_OPTIONS.includes(type)) {
          if (Array.isArray(values[key]))
            valueOptions = values[key].map((value: string) => ({
              option: value,
            }))
          else valueOptions.push({ option: values[key] })
          values[key] = null
        }

        // build images to send back
        if (type === IMAGE && values[key]) {
          for (let imgObj of values[key]) {
            if (imgObj.originFileObj) {
              const base64String = await file2Base64(imgObj)
              images.push({ image: base64String })
            } else {
              images.push({ id: imgObj.id ?? imgObj.uid })
            }
          }
        }

        const finalValue: { [key: string]: any } = {
          attribute: keySplit[2],
          value: String(values[key]),
          value_options: valueOptions,
          images,
        }
        if (keySplit[1] !== 'undefined') finalValue['id'] = keySplit[1]
        // if there is no value or no valueOption selected do not add that attribute
        // to the payload
        if (
          values[key] ||
          (valueOptions.length &&
            Object.keys(valueOptions[0]).length &&
            Object.values(valueOptions[0])[0])
        ) {
          attributesWithValue.push(finalValue)
        }
        delete values[key]
      }
    }

    // handle image values
    for (let i = 0; i < imageFileList?.length; i++) {
      if (imageFileList[i].originFileObj) {
        const base64String = await file2Base64(imageFileList[i])
        imageFiles.push({
          image: base64String,
          order: i,
        })
      } else {
        imageFiles.push({
          // @ts-ignore
          id: imageFileList[i].id ?? imageFileList[i].uid,
          order: i,
        })
      }
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        attributes: attributesWithValue,
        images: imageFiles,
      })
    )
  }

  return (
    <ProductContext.Provider value={queryResult?.data?.data}>
      <Form
        {...formProps}
        layout="vertical"
        // @ts-ignore
        disabled={isDeleted}
        onFinish={onFinish}
        onValuesChange={onValuesChange}>
        <Tabs>
          <TabPane tab="Basic Info" key="1">
            <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" initialValue={''}>
              <ReactQuill theme="snow" readOnly={isDeleted} />
            </Form.Item>
            {!!queryResult?.data?.data.specification && (
              <>
                <Collapse>
                  <Collapse.Panel key="spec" header="Specification">
                    {parse(queryResult?.data?.data.specification ?? '')}
                  </Collapse.Panel>
                </Collapse>
                <Divider />
              </>
            )}
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true }]}>
              <Select
                {...categorySelectProps}
                options={undefined}
                // @ts-ignore
                onSelect={(value: string, option: any) => {
                  setSelectedCategory(
                    categoryQueryResult.data?.data.find(
                      (cat) => cat.id === value,
                    ),
                  )
                }}>
                {categoryQueryResult.data?.data
                  .concat(defaultCategoryQueryResult.data?.data ?? [])
                  .map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      <Space size="large">
                        <ImageField
                          value={category.cover_image}
                          height={75}
                          width={150}
                        />
                        {category.name}
                      </Space>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Plan" name="plan">
              <SelectWithDefault<IPlan>
                mode="multiple"
                useSelectProps={{
                  resource: PLAN_URL,
                  optionLabel: 'name',
                  filters: [
                    { field: 'expand', value: 'service', operator: 'eq' },
                    {
                      field: 'service',
                      value: selectedCategory?.service,
                      operator: 'eq',
                    },
                  ],
                }}
                renderOptions={(plans: any) =>
                  plans?.map((plan: any) => (
                    <Select.Option key={plan.id} value={Number(plan.id)}>
                      {plan.name} {plan.service.name}
                    </Select.Option>
                  ))
                }
              />
            </Form.Item>
            <Form.Item
              label="Description for Consultation Tool"
              name="description_for_consultation"
              initialValue={''}>
              <ReactQuill theme="snow" readOnly={isDeleted} />
            </Form.Item>
          </TabPane>
          <TabPane tab="Settings" key="settings" forceRender>
            <Form.Item label="Owner" name="owner">
              <Select options={ownerOptions} defaultValue={INTERNAL} />
            </Form.Item>
            <Form.Item label="Stage" name="stage">
              <Select options={stageOptions} defaultValue={DRAFT} />
            </Form.Item>
            <Form.Item
              label="Internal Notes"
              name="internal_notes"
              initialValue={''}>
              <ReactQuill theme="snow" readOnly={isDeleted} />
            </Form.Item>
            <Space size="large">
              <Form.Item
                label="Internal"
                name="is_internal"
                valuePropName="checked"
                initialValue={!writeCatalog?.can}
                tooltip={{
                  title:
                    'True if the product is created only for internal purposes. These products will not be shown in the external product catalog',
                }}>
                <Switch disabled={!writeCatalog?.can} />
              </Form.Item>
              <Form.Item
                label="Published"
                name="is_published"
                valuePropName="checked"
                tooltip={{
                  title:
                    'True if the product is to be displayed in the external catalog',
                }}>
                <Switch />
              </Form.Item>
              <Form.Item
                label="Material"
                name="is_material"
                valuePropName="checked"
                tooltip={{
                  title: 'True if the product is also a material',
                }}>
                <Switch />
              </Form.Item>
            </Space>
            <br />
            <Card title="Space settings">
              <Form.Item
                label="Space"
                name="is_space"
                valuePropName="checked"
                tooltip={{
                  title: 'True if the product is a space in a venue',
                }}>
                <Switch />
              </Form.Item>
              {isSpace && (
                <Form.Item label="Venue" name="space_in_venue">
                  <SelectWithDefault
                    useSelectProps={{
                      resource: 'package_tool/venues',
                      optionLabel: 'name',
                    }}
                    allowClear
                  />
                </Form.Item>
              )}
            </Card>
            <br />
            {!isSpace && (
              <Card title="Executed Settings">
                <Form.Item
                  label="Executed"
                  name="is_executed"
                  valuePropName="checked"
                  tooltip={{
                    title: 'True if the product is executed',
                  }}>
                  <Switch />
                </Form.Item>
                {isExecuted && (
                  <>
                    <Form.Item label="Venue" name="executed_in_venue">
                      <SelectWithDefault
                        useSelectProps={{
                          resource: 'package_tool/venues',
                          optionLabel: 'name',
                        }}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label="Space" name="executed_in_space">
                      <SelectWithDefault
                        useSelectProps={{
                          resource: PRODUCT_URL,
                          optionLabel: 'name',
                          filters: [
                            {
                              field: 'space_in_venue',
                              operator: 'eq',
                              value: executedInVenue,
                            },
                          ],
                          queryOptions: {
                            enabled: !!executedInVenue,
                          },
                        }}
                        allowClear
                      />
                    </Form.Item>
                    <Form.Item label="Designer" name="executed_by">
                      <SelectWithDefault<IStaff>
                        useSelectProps={{
                          resource: STAFF_URL,
                          optionLabel: 'username',
                        }}
                        allowClear
                        renderOptions={(items) =>
                          items?.map((staff) => (
                            <Select.Option key={staff.id} value={staff.id}>
                              {staff.first_name} {staff.last_name}
                            </Select.Option>
                          ))
                        }
                      />
                    </Form.Item>
                  </>
                )}
              </Card>
            )}
          </TabPane>
          <TabPane tab="Attributes" key="2" forceRender>
            {selectedCategory?.combined_attributes.map((attribute) => {
              const attr_details = attributeQueryResult.data?.data.find(
                (attr) => attr.id === attribute.attribute,
              )
              if (attr_details)
                return (
                  <DynamicInput
                    key={queryResult?.data?.data?.id}
                    attr_details={attr_details}
                    values={queryResult?.data?.data?.attributes}
                  />
                )
              return null
            })}
          </TabPane>
          <TabPane tab="Media" key="3" forceRender>
            <DraggableMultiImageUpload
              label="Images"
              name="images"
              files={imageFileList}
              onChange={setImageFileList}
            />
            <Form.List name="media">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item {...field} name={[field.name, 'url']}>
                        <Input size="large" placeholder="Video Link" />
                      </Form.Item>
                      <RowRemoveButton onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Video
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </TabPane>
          {REQUIRES_ITEMS.includes(
            selectedCategory?.product_type as string,
          ) && (
            <TabPane tab="Items" key="items" forceRender>
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} align="baseline">
                        <Form.Item
                          {...field}
                          name={[field.name, 'item']}
                          rules={[
                            { required: true, message: 'This is required' },
                          ]}>
                          <AutoComplete
                            style={{ width: '100%', maxWidth: '550px' }}
                            filterOption={false}
                            options={options}
                            onSearch={(value: string) => setValue(value)}>
                            <Input
                              size="large"
                              placeholder="Search products"
                              suffix={<Icons.SearchOutlined />}
                            />
                          </AutoComplete>
                        </Form.Item>
                        <RowRemoveButton onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Item
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </TabPane>
          )}
          <TabPane
            tab="Inventory"
            key="inventory"
            disabled={!accessInventory?.can || !isEdit}>
            <InventoryList />
          </TabPane>
          {isEdit && (
            <TabPane tab="Log" key="log">
              <Space direction="vertical">
                <Card
                  title={
                    <>
                      Created{' '}
                      <DateField
                        value={queryResult?.data?.data.created_at}
                        format="LLL"
                        style={{ fontWeight: 'normal', fontSize: 11 }}
                      />
                    </>
                  }>
                  {queryResult?.data?.data.created_by && (
                    <Card.Meta
                      title={`${queryResult?.data?.data.created_by?.first_name} ${queryResult?.data?.data.created_by?.last_name}`}
                      description={queryResult?.data?.data.created_by?.username}
                    />
                  )}
                </Card>
                <Card
                  title={
                    <>
                      Updated{' '}
                      <DateField
                        value={queryResult?.data?.data.updated_at}
                        format="LLL"
                        style={{ fontWeight: 'normal', fontSize: 11 }}
                      />
                    </>
                  }>
                  <Card.Meta
                    title={`${queryResult?.data?.data.updated_by?.first_name} ${queryResult?.data?.data.updated_by?.last_name}`}
                    description={queryResult?.data?.data.updated_by?.username}
                  />
                </Card>
                {!!queryResult?.data?.data.delete_reason && (
                  <>
                    <Divider />
                    <Typography.Text>
                      Reason For {isDeleted ? 'Delete' : 'Restore'}
                    </Typography.Text>
                    <Typography.Paragraph>
                      {parse(queryResult?.data?.data.delete_reason)}
                    </Typography.Paragraph>
                  </>
                )}
              </Space>
            </TabPane>
          )}
        </Tabs>
      </Form>
    </ProductContext.Provider>
  )
}
