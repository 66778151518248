import { useForm as useFormAntd } from "@refinedev/antd";
import { BaseRecord, HttpError, UseFormProps } from "@refinedev/core";
import { UseFormReturnType } from 'interfaces/refine'
import { useEffect } from 'react'
import { setErrors } from 'utils/form'

/**
 * Extends the useForm from antd to handle error from server
 *
 *
 * @typeParam TData - Result data of the query extends {@link https://refine.dev/docs/api-references/interfaceReferences#baserecord `BaseRecord`}
 * @typeParam TError - Custom error object that extends {@link https://refine.dev/docs/api-references/interfaceReferences#httperror `HttpError`}
 * @typeParam TVariables - Values for params. default `{}`
 *
 *
 */
export const useForm = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
>(
  props: UseFormProps<TData, TError, TVariables> = {},
): UseFormReturnType<TData, TError, TVariables> => {
  const useFormProps = useFormAntd<TData, TError, TVariables>(props)
  useEffect(
    () => {
      if (useFormProps.mutationResult.isError) {
        setErrors(
          useFormProps.mutationResult.error.response.data,
          useFormProps.form,
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useFormProps.mutationResult.isError],
  )

  return useFormProps
}
