import { CreateButton, List, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Space, Table, Tooltip } from "antd";

import {
  Authenticated,
  LayoutWrapper,
  useCreate,
  useDelete,
  useNavigation,
  useRouterContext,
} from "@refinedev/core";

import { ILayer } from 'interfaces/smartPhoto'
import { cleanPathName } from 'utils/common'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { DraggableTable } from 'components/table/DraggableTable'

export const LayerList: React.FC = () => {
  const { push } = useNavigation()
  const { useLocation, useParams } = useRouterContext()
  const location = useLocation()
  const { venue_id, smart_photo_id } = useParams<any>()

  const tProps = useTable<ILayer>({
    resource: cleanPathName(location.pathname),

    pagination: {
      mode: "off"
    }
  })

  const { mutate: reorder } = useCreate()
  const { mutate: deleteItem } = useDelete()

  const handleDelete = (id: string) => {
    deleteItem({
      resource: cleanPathName(location.pathname),
      id,
    })
  }

  const pageHeaderProps = {
    breadcrumb: (
      <NestedBreadcrumb
        baseUrl="/package_tool"
        routes={[
          { name: 'venues', id: venue_id },
          { name: 'smart_photos', id: smart_photo_id },
          { name: 'layers' },
        ]}
      />
    ),
    extra: [
      <CreateButton
        key="create"
        resource={cleanPathName(location.pathname)}
      />,
    ],
  }

  return (
    <Authenticated>
      <LayoutWrapper>
        <List
          title="Layers"
          headerProps={pageHeaderProps}
          resource={cleanPathName(location.pathname)}>
          <DraggableTable
            {...tProps.tableProps}
            onDragEnd={(items: any) => {
              reorder(
                {
                  resource: `${cleanPathName(location.pathname)}/reorder`,
                  values: items,
                },
                { onSuccess: () => tProps.tableQueryResult.refetch() },
              )
            }}
            rowKey="id"
            onRow={(record: any) => ({
              onClick: (event: any) => push(`edit/${record.id}`),
            })}>
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column dataIndex="order" title="Order" />
            <Table.Column
              dataIndex="is_visible"
              title="Visible"
              render={(value) => (value ? 'Yes' : 'No')}
            />
            <Table.Column<ILayer>
              dataIndex="id"
              title="Actions"
              render={(value, record) => (
                <span onClick={(e) => e.stopPropagation()} className="row">
                  <Space>
                    <Tooltip title="Items">
                      <Button
                        icon={<FontAwesomeIcon icon={['fas', 'th']} />}
                        size="small"
                        onClick={() =>
                          window.open(
                            `/package_tool/venues/${venue_id}/smart_photos/${smart_photo_id}/layers/${value}/items`,
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        icon={<Icons.DeleteOutlined />}
                        size="small"
                        onClick={() => handleDelete(value)}
                      />
                    </Tooltip>
                  </Space>
                </span>
              )}
            />
          </DraggableTable>
        </List>
      </LayoutWrapper>
    </Authenticated>
  );
}
