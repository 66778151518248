import { DateField, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Layout as AntdLayout,
  Button,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  Result,
  Select,
  Space,
  Spin,
  Tree,
} from 'antd'

import {
  Authenticated,
  BaseKey,
  useCan,
  useDelete,
  useInfiniteList,
  useList,
} from '@refinedev/core'
import { TreeDataNode } from 'antd'
import { PaddedImageField } from 'components/field'
import { useSelectWithSearch } from 'components/hooks'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStructuredCategory } from 'interfaces/category'
import { IExecutedImage } from 'interfaces/shortlistPackage'
import React, { Key, useEffect, useState } from 'react'
import { SplitPane } from 'react-collapse-pane'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { EXECUTED_IMAGE_URL, SECTION_URL, TAG_URL, VENUE_URL } from 'urls'
import { buildNodes } from 'utils/filter'
import './styles.less'

const ImageMask: React.FC<{
  image: IExecutedImage
  refetchData: any
  canDelete?: boolean
}> = ({ image, refetchData, canDelete }) => {
  const { mutate: destroy } = useDelete()

  const { formProps, modalProps, show, queryResult } = useModalForm({
    resource: EXECUTED_IMAGE_URL,
    id: image.id,
    action: 'edit',
    redirect: false,
  })

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <Modal {...modalProps} title="Edit">
          <Form {...formProps}>
            <Form.Item
              label="Tags"
              name="tag_ids"
              initialValue={queryResult?.data?.data.tags.map((t: any) => t.id)}>
              <SelectWithDefault
                useSelectProps={{ resource: TAG_URL, optionLabel: 'name' }}
                mode="multiple"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {canDelete ? (
        <Space
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}>
          <Button
            key="edit"
            icon={<Icons.EditOutlined />}
            size="small"
            danger
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              show()
            }}
          />
          <Button
            key="remove"
            icon={<Icons.DeleteOutlined />}
            size="small"
            danger
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              destroy(
                {
                  resource: EXECUTED_IMAGE_URL,
                  id: image.id,
                },
                {
                  onSuccess: () => refetchData(),
                },
              )
            }}
          />
        </Space>
      ) : (
        <></>
      )}
      <Descriptions
        labelStyle={{ color: 'white', fontWeight: 'bold' }}
        contentStyle={{ color: 'white' }}
        layout="horizontal"
        size="small"
        column={1}
        style={{
          padding: '14px',
          justifyContent: 'end',
        }}>
        {image.project_id && (
          <Descriptions.Item label={'PID'}>
            {image.project_id}
          </Descriptions.Item>
        )}
        {image?.product_name && (
          <Descriptions.Item label={'Name'}>
            {image.product_name}
          </Descriptions.Item>
        )}
        {image.venue_name && (
          <Descriptions.Item label={'Venue'}>
            {image.venue_name}
          </Descriptions.Item>
        )}
        {image.executed_date_time && (
          <Descriptions.Item label={'Executed Date'}>
            {
              <DateField
                value={image.executed_date_time}
                format="LLL"
                style={{
                  color: 'white',
                }}
              />
            }
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  )
}

export const PortfolioPage = ({}) => {
  const [events, setEvents] = useState<BaseKey[]>([])
  const [venues, setVenues] = useState<BaseKey[]>([])
  const [styles, setStyles] = useState<BaseKey[]>([])
  const [colors, setColors] = useState<BaseKey[]>([])
  const [tags, setTags] = useState<BaseKey[]>([])
  const [projectId, setProjectId] = useState<BaseKey[]>([])
  const [categories, setCategories] = useState<Key[]>([])
  const [categoryNodes, setCategoryNodes] = useState<TreeDataNode[]>([])

  const [expandedKeys, setExpandedKeys] = useState<Key[]>()

  const { data, hasNextPage, fetchNextPage, isFetched, refetch } =
    useInfiniteList<IExecutedImage>({
      resource: EXECUTED_IMAGE_URL,
      config: {
        pagination: {
          pageSize: 30,
        },
        filters: [
          {
            field: 'section',
            operator: 'in',
            value: events.join(','),
          },
          {
            field: 'venue',
            operator: 'in',
            value: venues.join(','),
          },
          {
            field: 'line_item__product__category',
            operator: 'in',
            value: categories.join(','),
          },
          {
            field: 'style_option',
            operator: 'in',
            value: styles.join(','),
          },
          {
            field: 'color',
            operator: 'in',
            value: colors.join(','),
          },
          {
            field: 'tags',
            operator: 'in',
            value: tags.join(','),
          },
          {
            field: 'project',
            operator: 'eq',
            value: projectId,
          },
        ],
      },
    })

  const { data: categoriesData, isLoading: categoryLoading } =
    useList<IStructuredCategory>({
      resource: 'cms/category/structured/',
    })

  const { selectProps: eventSelectProps } = useSelectWithSearch({
    resource: SECTION_URL,
    optionLabel: 'name',
  })

  const { selectProps: venueSelectProps } = useSelectWithSearch({
    resource: VENUE_URL,
    optionLabel: 'name',
  })

  const { selectProps: styleSelectProps } = useSelectWithSearch({
    resource:
      'package_tool/shortlist_package/executed_images/get_style_filters',
    optionLabel: 'name',
    hasPagination: false,
  })

  const { selectProps: colorStyles } = useSelectWithSearch({
    resource:
      'package_tool/shortlist_package/executed_images/get_color_filters',
    optionLabel: 'color',
    optionValue: 'color',
    hasPagination: false,
  })

  const { selectProps: tagSelectProps } = useSelectWithSearch({
    resource: TAG_URL,
    optionLabel: 'name',
    hasPagination: false,
  })

  const { data: canDelete } = useCan({
    resource: 'can_delete_executed_image',
    action: '',
  })

  useEffect(() => {
    window.document.title = 'Meragi | Portfolio'
    // @ts-ignore
    window.document.querySelector('body').style.overflow = 'hidden'
  }, [])

  const allExecutedImages = data?.pages.map((page) => page.data).flat()

  useEffect(() => {
    setCategoryNodes(buildNodes(categoriesData?.data ?? []))
  }, [categoriesData])

  return (
    <Authenticated>
      <AntdLayout>
        <AntdLayout.Header className="header">
          <img src={'/meragi-logo.svg'} alt="Meragi" />

          <div className="row" style={{ width: '50%' }}></div>
        </AntdLayout.Header>
        <AntdLayout.Content className="portfolio-content">
          <SplitPane
            split="vertical"
            collapse={{ buttonTransition: 'none' }}
            initialSizes={[20, 80]}>
            <div className="scroll-hidden sider">
              <Input.Search
                name="search"
                placeholder="Project ID i.e. 5119"
                onChange={(e: any) => {
                  setProjectId(e.target.value)
                }}
                style={{
                  padding: '5px',
                }}
                allowClear
                enterButton
              />
              <Collapse defaultActiveKey={['category']}>
                <Collapse.Panel header="Category" key="category">
                  {categoryLoading ? (
                    <Icons.LoadingOutlined />
                  ) : (
                    <Tree
                      checkable
                      selectable={false}
                      treeData={categoryNodes}
                      expandedKeys={expandedKeys}
                      // checkedKeys={categories}
                      onExpand={(ek) => setExpandedKeys(ek)}
                      onCheck={(checkedKeys) =>
                        setCategories(checkedKeys as Key[])
                      }
                    />
                  )}
                </Collapse.Panel>
                <Collapse.Panel header="Event" key="event">
                  <Select
                    {...eventSelectProps}
                    mode="multiple"
                    dropdownMatchSelectWidth={false}
                    placeholder="Select Events"
                    style={{ width: '100%' }}
                    onChange={(value: any) => setEvents(value)}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Venue" key="venue">
                  <Select
                    {...venueSelectProps}
                    mode="multiple"
                    placeholder="Select Venues"
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={(value: any) => setVenues(value)}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Style" key="style">
                  <Select
                    {...styleSelectProps}
                    mode="multiple"
                    placeholder="Select Styles"
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={(value: any) => setStyles(value)}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Color" key="color">
                  <Select
                    {...colorStyles}
                    mode="multiple"
                    placeholder="Select Styles"
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={(value: any) => setColors(value)}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Tags" key="tag">
                  <Select
                    {...tagSelectProps}
                    mode="multiple"
                    placeholder="Select Tags"
                    popupMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={(value: any) => setTags(value)}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
            <div
              className="scroll-content-card-list scroll"
              id="infinite-scroll-content-card-list">
              <InfiniteScroll
                hasMore={!!hasNextPage}
                next={fetchNextPage}
                dataLength={allExecutedImages?.length ?? 0}
                scrollThreshold={0.7}
                scrollableTarget="infinite-scroll-content-card-list"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'visible',
                }}
                loader={
                  <div className="row h-center">
                    <Spin />
                  </div>
                }
                endMessage={
                  allExecutedImages?.length ? (
                    <p style={{ textAlign: 'center' }}>
                      <Divider>Yay! You have seen it all</Divider>
                    </p>
                  ) : isFetched ? (
                    <Result
                      status="404"
                      title="Oops!"
                      subTitle="We could not find any images with these combinations"
                    />
                  ) : (
                    <div className="row h-center">
                      <Spin />
                    </div>
                  )
                }>
                <Masonry
                  breakpointCols={{
                    default: 4,
                    1100: 4,
                    700: 2,
                    500: 1,
                  }}
                  className="my-masonry-grid"
                  columnClassName="">
                  {allExecutedImages?.map((image: IExecutedImage) => (
                    <div key={image.id}>
                      <PaddedImageField
                        key={image.id}
                        value={image.image}
                        padding={2.5}
                        radius={0}
                        sizes=""
                        tags={image.tags?.map((tag) => tag.name)}
                        preview={{
                          mask: (
                            <ImageMask
                              image={image}
                              refetchData={refetch}
                              canDelete={canDelete?.can}
                            />
                          ),
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
              </InfiniteScroll>
            </div>
          </SplitPane>
        </AntdLayout.Content>
      </AntdLayout>
    </Authenticated>
  )
}
