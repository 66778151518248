import {
  BaseKey,
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider,
  LogicalFilter,
} from '@refinedev/core'
import { AxiosInstance } from 'axios'
import { stringify } from 'query-string'

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'in':
    case 'ne':
    case 'gte':
    case 'lte':
      return `__${operator}`
    case 'between':
      return '__range'
    case 'contains':
      return '__icontains'
  }

  return '' // default "eq"
}

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = []

    sort.map((item) => {
      _sort.push(`${item.order === 'asc' ? '' : '-'}${item.field}`)
    })

    return {
      _sort,
    }
  }

  return
}

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {}
  if (filters) {
    filters.map((crudFilter) => {
      const { field, operator, value } = crudFilter as LogicalFilter
      if (field === 'q') {
        queryFilters[field] = value
        return
      }

      const mappedOperator = mapOperator(operator)
      queryFilters[`${field}${mappedOperator}`] = value
    })
  }

  return queryFilters
}

export type ExtendedDataContext = DataProvider & {
  getManyDetail: DataProvider['getMany']
}

function appendSlashIfRequired(key: string | BaseKey): string {
  let key_string = key.toString()
  if (key_string.endsWith('/')) {
    return key_string
  } else {
    return key_string + '/'
  }
}

const JsonServer = (
  apiUrl: string,
  httpClient: AxiosInstance,
): ExtendedDataContext => ({
  getList: async ({ resource, pagination, filters, sort }) => {
    const url = `${apiUrl}/${appendSlashIfRequired(resource)}`

    // pagination
    const current =
      pagination?.mode === 'server' ? pagination?.current || 1 : undefined
    const pageSize =
      pagination?.mode === 'server' ? pagination?.pageSize || 10 : undefined

    const queryFilters = generateFilter(filters)

    const query: {
      page?: number
      page_size?: number
      ordering?: string
    } = {
      page: current,
      page_size: pageSize,
    }

    const generatedSort = generateSort(sort)
    if (generatedSort) {
      const { _sort } = generatedSort
      query.ordering = _sort.join(',')
    }

    const { data } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`,
    )

    if (pagination?.mode !== 'server' && !data.hasOwnProperty('results')) {
      return {
        data: data,
        total: data.len,
      }
    }

    return {
      data: data.results,
      total: data.count,
    }
  },

  getMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.get(
          `${apiUrl}/${resource}/${appendSlashIfRequired(id)}`,
        )
        return data
      }),
    )

    return {
      data: response,
    }
  },

  getManyDetail: async ({ resource, ids, metaData }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.get(
          `${apiUrl}/${resource}/${id}/${metaData?.subResource}/`,
        )
        return data
      }),
    )

    return { data: response }
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}/`

    const { data } = await httpClient.post(url, variables)

    return {
      data,
    }
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}/`, param)
        return data
      }),
    )

    return { data: response }
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}/`

    const { data } = await httpClient.patch(url, variables)

    return {
      data,
    }
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}/`,
          variables,
        )
        return data
      }),
    )

    return { data: response }
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}/`

    const { data } = await httpClient.get(url)

    return {
      data,
    }
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}/`

    const { data } = await httpClient.delete(url)

    return {
      data,
    }
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`)
        return data
      }),
    )
    return { data: response }
  },

  getApiUrl: () => {
    return apiUrl
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`

    if (sort) {
      const generatedSort = generateSort(sort)
      if (generatedSort) {
        const { _sort } = generatedSort
        const sortQuery = {
          ordering: _sort.join(','),
        }
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters)
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`
    }

    if (headers) {
      // @ts-ignore
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      }
    }

    let axiosResponse
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload)
        break
      case 'delete':
        axiosResponse = await httpClient.delete(url)
        break
      default:
        axiosResponse = await httpClient[method](requestUrl)
        break
    }

    const { data } = axiosResponse

    return Promise.resolve({ data })
  },
})

export default JsonServer
