import { BooleanField, CreateButton, List, useTable } from '@refinedev/antd'
import { useDeleteMany, useGetIdentity, useNavigation } from '@refinedev/core'
import { Table } from 'antd'
import { DeleteButton } from 'components/button'
import { CheckAccess } from 'components/checkAccess'
import { IPayoutRule } from 'interfaces/payout'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import { PAYOUT_RULE_URL } from 'urls'

export const PayoutRuleList: React.FC = () => {
  const tProps = useTable<IPayoutRule>({
    syncWithLocation: true,
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const { data: user } = useGetIdentity<IStaff>()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: PAYOUT_RULE_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <CheckAccess forceRestrict={!user?.is_superuser}>
      <List headerProps={pageHeaderProps}>
        <Table
          {...tProps.tableProps}
          rowKey="id"
          size="small"
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: (event) => edit(PAYOUT_RULE_URL, record.id),
          })}>
          <Table.Column dataIndex="min_amount" title="Min Amount" />
          <Table.Column dataIndex="max_amount" title="Max Amount" />
          <Table.Column
            dataIndex="for_under_collected"
            title="For Under Collected"
            render={(value: boolean) => <BooleanField value={value} />}
          />
          <Table.Column dataIndex="rule_for" title="Rule Type" />
        </Table>
      </List>
    </CheckAccess>
  )
}
