import { Edit, useForm } from "@refinedev/antd";
import { BaseKey, LayoutWrapper, useNavigation, useRouterContext } from "@refinedev/core";
import { IEvent } from 'interfaces/customer'
import { EventForm } from './Form'

export const EventEdit: React.FC = () => {
  const { useParams } = useRouterContext()
  const { id } = useParams<any>()
  const { show, goBack } = useNavigation()

  const useFormProps = useForm<IEvent>({
    action: 'edit',
    resource: `package_tool/shortlist_package/projects`,
    id: id as BaseKey,
    onMutationSuccess: () => {
      show('package_tool/events', id as string)
    },
    redirect: false,
  })

  return (
    <LayoutWrapper>
      <Edit
        saveButtonProps={useFormProps.saveButtonProps}
        title="Edit Project"
        headerProps={{
          onBack: () => {
            goBack()
          },
          breadcrumb: <></>,
          extra: null,
        }}>
        <EventForm useFormProps={useFormProps} />
      </Edit>
    </LayoutWrapper>
  );
}
