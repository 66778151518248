import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PriceField } from 'components/field'
import { useContext, useEffect } from 'react'
import { ItemList } from './ItemList'
import { PricingContext } from './index'
import { Breakdown as BreakdownNode, ItemType } from './pricingToolTree'

export const ItemTypeList = ({
  breakdown,
  setBreakdown,
}: {
  breakdown: BreakdownNode
  setBreakdown: any
}) => {
  const value = useContext(PricingContext)
  useEffect(() => {
    if (value.item?.parent?.parent !== breakdown) {
      // only set new item when newItem breakdown !== selected breakdown
      // Otherwise create a issue when navigating to a particular component
      // from error list.
      let item = breakdown.getChildren()[0]?.getChildren()[0]
      value.setItem(item)
    }
  }, [breakdown])

  return (
    <div className="item-type-list-container">
      {breakdown.getChildren().map((itemType: ItemType) => {
        return (
          <div key={itemType.name} style={{ marginTop: '5px' }}>
            <div className="item-type-header">
              {itemType.name}
              <div>
                <PriceField value={itemType.getPrice()} />
                <Button
                  type="text"
                  onClick={() => {
                    if (value.copiedItem) {
                      let temp_copied_item =
                        localStorage.getItem('temp_copied_item')
                      let copiedNode = itemType.paste(value.copiedItem)
                      copiedNode && value.setItem(copiedNode)
                    }
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={['fas', 'paste']}
                      style={{ color: '#7CB9E8' }}
                    />
                  }
                />
              </div>
            </div>
            <ItemList itemType={itemType} setBreakdown={setBreakdown} />
          </div>
        )
      })}
    </div>
  )
}
