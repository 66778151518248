import { Form, FormProps, Input } from "antd";
import { file2Base64 } from "@refinedev/core";
import { SingleImageUpload } from 'components/input'

export const FoliageTypeForm: React.FC<{
  formProps: FormProps
  queryResult?: any
}> = ({ formProps, queryResult }) => {
  const onFinish = async (values: any) => {
    let coverImage = ''
    const { image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.image

    if (image) {
      const file = image[image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values }
    if (coverImage.length) finalResponse['image'] = coverImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <SingleImageUpload
        label="Image"
        name="image"
        buttonText="Image"
        initialImageUrl={queryResult?.data?.data?.image}
      />
    </Form>
  )
}
