import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IAttribute } from 'interfaces/attribute'
import { AttributeForm } from './Form'

export const AttributeEdit: React.FC = () => {
  const useFormProps = useForm<IAttribute>()

  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      <AttributeForm useFormProps={useFormProps} />
    </Edit>
  )
}
