import { List } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'

import {
  Authenticated,
  LayoutWrapper,
  useCreate,
  useCreateMany,
  useList,
  useModal,
  useUpdate,
  useDelete,
  useCan,
} from '@refinedev/core'

import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ISalesTarget } from 'interfaces/crm'
import { IStaff } from 'interfaces/staff'
import { SALES_TARGET_VIEW_URL, STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

const TargetModal = ({ month }: { month: any }) => {
  const { show, close, visible } = useModal()

  const [form] = Form.useForm()

  const {
    data: salesTargets,
    isFetching: targetsLoading,
    refetch,
  } = useList<ISalesTarget>({
    resource: SALES_TARGET_VIEW_URL,

    filters: [
      {
        field: 'month',
        operator: 'eq',
        value: month.toISOString(),
      },
    ],
  })

  useEffect(() => {
    refetch()
  }, [month, visible])

  const { mutate: update, isLoading: isLoading } = useUpdate()
  const { mutate: create } = useCreate()
  const { mutate: deleteItem } = useDelete()
  const { data: addSalesTarget } = useCan({
    resource: 'can_add_sales_target',
    action: '',
  })

  console.log(addSalesTarget)

  const deleteTarget = (field: any, remove: any) => {
    let record = form.getFieldsValue()['Targets'][field.key]
    if (record?.id) {
      deleteItem(
        {
          resource: SALES_TARGET_VIEW_URL,
          id: record.id,
        },
        {
          onSuccess: () => refetch(),
        },
      )
    } else {
      remove(field.name)
    }
  }

  return (
    <>
      <Button size="small" onClick={show}>
        Add / View Target
      </Button>
      <Modal
        onCancel={() => {
          form.resetFields()
          close()
        }}
        visible={visible}
        title="Events"
        okButtonProps={{ loading: isLoading, disabled: !addSalesTarget?.can }}
        onOk={() => {
          form.submit()
        }}>
        {targetsLoading ? (
          <div className="row h-center v-center">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            preserve={false}
            onFinish={(values) => {
              for (var obj of values['Targets']) {
                if (obj.id) {
                  let search_obj = salesTargets?.data.find(
                    (o) => o.id === obj.id,
                  )
                  if (JSON.stringify(obj) !== JSON.stringify(search_obj)) {
                    update({
                      resource: SALES_TARGET_VIEW_URL,
                      values: { ...obj, date: month },
                      id: obj.id,
                    })
                  }
                } else {
                  create({
                    resource: SALES_TARGET_VIEW_URL,
                    values: { ...obj, date: month },
                  })
                }
              }
            }}>
            <Form.List
              name="Targets"
              initialValue={
                salesTargets?.data.length
                  ? salesTargets?.data.map((target) => ({
                      ...target,
                    }))
                  : [{}]
              }>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.name} gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'relationship_manager']}
                          rules={[{ required: true }]}>
                          <SelectWithDefault<IStaff>
                            useSelectProps={{ resource: STAFF_URL }}
                            placeholder="Select RMs"
                            renderOptions={(items) =>
                              items?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {getUserName(item)}
                                </Select.Option>
                              ))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'target']}
                          rules={[{ required: true }]}>
                          <InputNumber
                            style={{ width: '100%' }}
                            prefix="₹"
                            placeholder="Target in Lakhs"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          onClick={() => {
                            deleteTarget(field, remove)
                          }}>
                          delete
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Target
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        )}
      </Modal>
    </>
  )
}

export const SalesTarget = () => {
  const [month, setMonth] = useState<any>(dayjs())

  return (
    <LayoutWrapper>
      <Authenticated>
        <List title={'Sales Target'}>
          <Space>
            <div style={{ overflowX: 'hidden' }}>
              <DatePicker
                style={{ width: '100%' }}
                format="MMMM, YYYY"
                picker="month"
                placeholder="Select month"
                allowClear={false}
                onChange={(value) => setMonth(value)}
                defaultValue={month}
              />
            </div>
            <TargetModal month={month} />
          </Space>
        </List>
      </Authenticated>
    </LayoutWrapper>
  )
}
