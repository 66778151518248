import React from 'react'
import { useModal } from '@refinedev/core'
import { Button, Modal } from 'antd'
import { CollectPaymentForm } from 'pages/package_tool/shortlistPackage/components/CollectPaymentForm'

export const CollectPaymentModal: React.FC<{
  useFormProps: any
  projectId: Number
}> = ({ useFormProps, projectId }) => {
  const { visible, show, close } = useModal()

  return (
    <>
      <Button
        style={{ borderRadius: '1px', marginTop: '30px' }}
        onClick={() => show()}>
        Collect Payment
      </Button>
      <Modal
        title="Collect Payment"
        open={visible}
        onCancel={() => {
          close()
          useFormProps.form.resetFields()
        }}
        onOk={() => {
          useFormProps.form.submit()
          close()
        }}>
        <CollectPaymentForm useFormProps={useFormProps} projectId={projectId} />
      </Modal>
    </>
  )
}
