import * as Icons from '@ant-design/icons'
import {
  useApiUrl,
  useCreate,
  useCustom,
  useDelete,
  useUpdate,
} from '@refinedev/core'
import { Button, Card, Col, Divider, Input, Row, Select, Space } from 'antd'
import { PriceField } from 'components/field'
import { GridSelect } from 'components/input/GridSelect'
import { ICombinationList } from 'interfaces/pricing'
import { debounce } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import {
  COMBINATION_URL,
  FLORALVALUE_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
} from 'urls'
import { checkNumber, reduceByPercent } from 'utils/common'
import { PricingContext } from './index'
import { IFloralValue } from './pricingToolTree'
import './styles.less'

const FlowerItem = ({
  flowerItem,
  setChangeToggle,
}: {
  flowerItem: IFloralValue
  setChangeToggle: any
}) => {
  const { item, setPrice } = useContext(PricingContext)

  const apiUrl = useApiUrl()
  const [split, setSplit] = useState<number>(flowerItem ? flowerItem.split : 0)
  const [ar, setAr] = useState<number>(
    flowerItem ? flowerItem.artificial_ratio : 0,
  )
  const [flowerId, setFlowerId] = useState<any>(flowerItem.id)

  const { mutate: patch } = useUpdate()
  const { mutate: destroy } = useDelete()
  const { mutate: create } = useCreate()

  const { data: comboData, refetch } = useCustom<ICombinationList>({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: item?.info?.item,
        flower_id: flowerId,
      },
    },
  })

  const getTotal = () => {
    let workingArtificialRatio = checkNumber(ar)
    if (comboData?.data.allowed_artificial_percents?.length === 1) {
      workingArtificialRatio = 0
    }
    return reduceByPercent(
      reduceByPercent(comboData?.data.price as number, workingArtificialRatio),
      100 - split,
    )
  }

  useEffect(() => {
    setFlowerId(flowerItem.flower)
    refetch()
  }, [flowerItem])

  useEffect(() => {
    if (comboData?.data.id) {
      flowerItem.price = checkNumber(getTotal())
      flowerItem.combination = comboData.data.id as number
    }
    setPrice()
  }, [comboData?.data, split, ar])

  const setItemQuantity = debounce((value: any) => {
    patch({
      resource: FLORALVALUE_URL,
      id: flowerItem.id,
      values: value,
      successNotification: false,
    })
  }, 1000)

  const handleSplit = (value: number) => {
    setSplit(value)
    setItemQuantity({ split: value })
    if (item?.info) {
      flowerItem.split = value
    }
    setChangeToggle((prev: boolean) => {
      return !prev
    })
  }

  const handleAr = (value: number) => {
    setAr(value)
    if (item?.info) {
      flowerItem.artificial_ratio = value
    }
    setItemQuantity({ artificial_ratio: value })
    setChangeToggle((prev: boolean) => {
      return !prev
    })
  }

  const deleteFloral = () => {
    item?.removeValue(flowerItem.id)
    setChangeToggle((prev: boolean) => {
      return !prev
    })
    destroy({ resource: FLORALVALUE_URL, id: flowerItem.id })
  }

  return (
    <Card
      size="small"
      className="flower-item-card"
      extra={
        <Space>
          <Button
            // size="x-small"
            ghost={true}
            color="danger"
            onClick={() => deleteFloral()}>
            <Icons.DeleteOutlined />
          </Button>
        </Space>
      }>
      <GridSelect
        useTableProps={{
          resource: FLOWER_URL,
          initialFilter: [
            {
              field: 'item_id',
              operator: 'eq',
              value: item?.info?.item,
            },
          ],
        }}
        value={flowerItem.flower}
        title="Flowers"
        getName={(item) => `${item?.color.name} ${item?.flower_type.name}`}
        defaultQueryParam="?detailed=true"
        onOk={(props: any) => {
          const cloned_flower_item: IFloralValue = Object.assign(
            Object.create(Object.getPrototypeOf(flowerItem)),
            flowerItem,
          )
          item?.removeValue(flowerItem.id)
          destroy({ resource: FLORALVALUE_URL, id: flowerItem.id })
          cloned_flower_item.image = props.image
          cloned_flower_item.flower = props.id
          cloned_flower_item.artificial_ratio = 0
          item?.addValue(cloned_flower_item)

          create(
            {
              resource: FLORALVALUE_URL,
              values: {
                flower: cloned_flower_item.flower,
                breakdown_value: item?.id,
                split: cloned_flower_item.split,
                artificial_ratio: cloned_flower_item.artificial_ratio,
              },
            },
            {
              onSuccess: (data, variables, context) => {
                cloned_flower_item.id = data.data.id as number
                setChangeToggle((prev: boolean) => {
                  return !prev
                })
                setPrice()
              },
            },
          )
          setFlowerId(props.id)
          refetch()
        }}
      />
      <div className="row">
        <Space>
          Price:
          <PriceField value={comboData?.data?.price ?? 0} />
          Total: <PriceField value={getTotal()?.toFixed(2) ?? 0} />
        </Space>
        <Divider />
      </div>
      <Space direction="horizontal">
        {!!comboData?.data.allowed_artificial_percents?.length && (
          <Space direction="vertical">
            AR
            <Select
              dropdownMatchSelectWidth={false}
              options={comboData?.data.allowed_artificial_percents?.map(
                (percent) => ({
                  label: percent.name,
                  value: percent.reduction,
                }),
              )}
              defaultValue={ar}
              onChange={(value) => {
                handleAr(Number(value))
              }}
            />
          </Space>
        )}

        <Space direction="vertical">
          Split
          <Input
            type="number"
            value={split}
            prefix="%"
            onChange={(event) => {
              handleSplit(Number(event.target.value))
            }}
          />
        </Space>
      </Space>
    </Card>
  )
}

const FoliageItem = ({
  foliageItem,
  setChangeToggle,
}: {
  foliageItem: IFloralValue
  setChangeToggle: any
}) => {
  const { item, setPrice } = useContext(PricingContext)
  const [foliageId, setFoliageId] = useState<any>(foliageItem.id)

  const apiUrl = useApiUrl()
  const [split, setSplit] = useState<number>(
    foliageItem ? foliageItem.split : 0,
  )
  const [ar, setAr] = useState<number>(
    foliageItem ? foliageItem.artificial_ratio : 0,
  )

  const { mutate: patch } = useUpdate()
  const { mutate: destroy } = useDelete()
  const { mutate: create } = useCreate()

  const { data: comboData, refetch } = useCustom<ICombinationList>({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: item?.info?.item,
        foliage_id: foliageId,
      },
    },
  })

  const getTotal = () => {
    let workingArtificialRatio = checkNumber(foliageItem.artificial_ratio)
    if (comboData?.data.allowed_artificial_percents?.length === 1) {
      workingArtificialRatio = 0
    }
    return reduceByPercent(
      reduceByPercent(comboData?.data.price as number, workingArtificialRatio),
      100 - split,
    )
  }

  useEffect(() => {
    setFoliageId(foliageItem.foliage)
    refetch()
  }, [foliageItem])

  useEffect(() => {
    let total = 0
    foliageItem.price = checkNumber(getTotal())
    foliageItem.combination = comboData?.data.id as number
    item?.info?.values?.forEach((value: any) => {
      total = total + value.price
    })
    if (item?.info) item.info.price = total
    setPrice()
  }, [comboData?.data, split, ar])

  const setItemQuantity = debounce((value: any) => {
    patch({
      resource: FLORALVALUE_URL,
      id: foliageItem.id,
      values: value,
      successNotification: false,
    })
  }, 1000)

  const handleSplit = (value: number) => {
    setSplit(value)
    setItemQuantity({ split: value })
    if (item?.info) {
      foliageItem.split = value
    }
    setChangeToggle((prev: boolean) => {
      return !prev
    })
  }

  const handleAr = (value: number) => {
    setAr(value)
    if (item?.info) {
      foliageItem.artificial_ratio = value
    }
    setItemQuantity({ artificial_ratio: value })
    setChangeToggle((prev: boolean) => {
      return !prev
    })
  }

  const deleteFloral = () => {
    item?.removeValue(foliageItem.id)
    setChangeToggle((prev: boolean) => {
      return !prev
    })
    destroy({ resource: FLORALVALUE_URL, id: foliageItem.id })
  }

  return (
    <Card
      size="small"
      className="foliage-item-card"
      extra={
        <Space>
          <Button
            // size="x-small"
            ghost={true}
            color="danger"
            onClick={() => deleteFloral()}>
            <Icons.DeleteOutlined />
          </Button>
        </Space>
      }>
      <GridSelect
        useTableProps={{
          resource: FOLIAGE_TYPE_URL,
          initialFilter: [
            {
              field: 'item_id',
              operator: 'eq',
              value: item?.info?.item,
            },
          ],
        }}
        value={foliageItem.foliage}
        title="Foliages"
        onOk={(props: any) => {
          const cloned_foliage_item: IFloralValue = Object.assign(
            Object.create(Object.getPrototypeOf(foliageItem)),
            foliageItem,
          )
          item?.removeValue(foliageItem.id)
          destroy({ resource: FLORALVALUE_URL, id: foliageItem.id })
          cloned_foliage_item.image = props.image
          cloned_foliage_item.foliage = props.id
          cloned_foliage_item.artificial_ratio = 0
          item?.addValue(cloned_foliage_item)

          create(
            {
              resource: FLORALVALUE_URL,
              values: {
                foliage: cloned_foliage_item.foliage,
                breakdown_value: item?.id,
                split: cloned_foliage_item.split,
                artificial_ratio: cloned_foliage_item.artificial_ratio,
              },
            },
            {
              onSuccess: (data, variables, context) => {
                cloned_foliage_item.id = data.data.id as number
                setChangeToggle((prev: boolean) => {
                  return !prev
                })
                setPrice()
              },
            },
          )
          setFoliageId(props.id)
          refetch()
        }}
      />
      <div className="row">
        <Space>
          Price:
          <PriceField value={comboData?.data?.price ?? 0} />
          Total: <PriceField value={getTotal()?.toFixed(2) ?? 0} />
        </Space>
        <Divider />
      </div>
      <Space direction="horizontal">
        {!!comboData?.data.allowed_artificial_percents?.length && (
          <Space direction="vertical">
            AR
            <Select
              dropdownMatchSelectWidth={false}
              options={comboData?.data.allowed_artificial_percents?.map(
                (percent) => ({
                  label: percent.name,
                  value: percent.reduction,
                }),
              )}
              defaultValue={ar}
              onChange={(value) => {
                handleAr(Number(value))
              }}
            />
          </Space>
        )}

        <Space direction="vertical">
          Split
          <Input
            type="number"
            value={split}
            onChange={(event) => {
              handleSplit(Number(event.target.value))
            }}
          />
        </Space>
      </Space>
    </Card>
  )
}

export const FloralItem = ({}) => {
  const { item, setPrice } = useContext(PricingContext)
  const [changeToggle, setChangeToggle] = useState(true)

  useEffect(() => {
    setPrice()
  }, [changeToggle, item])

  return (
    <>
      <Row gutter={8} wrap>
        {item?.info?.values
          ?.filter((value: any) => !!value.flower)
          .map((value: any): any => (
            <Col flex="1 0 33%" key={value.id}>
              {!!value.flower && item && (
                <div style={{ maxWidth: '17.2vw' }}>
                  <FlowerItem
                    flowerItem={value}
                    setChangeToggle={setChangeToggle}
                  />
                </div>
              )}
            </Col>
          ))}
        {item?.info?.values
          ?.filter((value: any) => !!value.foliage)
          .map((value: any): any => (
            <Col flex="1 0 33%" key={value.id}>
              {!!value.foliage && item && (
                <div style={{ maxWidth: '17.2vw' }}>
                  <FoliageItem
                    foliageItem={value}
                    setChangeToggle={setChangeToggle}
                  />
                </div>
              )}
            </Col>
          ))}
      </Row>
    </>
  )
}
