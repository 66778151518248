import { CreateButton, List, useTableReturnType } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useRouterContext } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { ICustomer } from 'interfaces/customer'
import { useState } from 'react'
import { getSearchFormInitialValue } from 'utils/filter'

export const CustomerTable: React.FC<{
  tProps: useTableReturnType<ICustomer, any, any>
}> = ({ tProps }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const { Link } = useRouterContext()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'package_tool/customers',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <TableSearchInput
        searchFormProps={tProps.searchFormProps}
        placeholder="Name, Phone Number"
        key="search"
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small">
        <Table.Column<ICustomer>
          dataIndex="username"
          title="Phone Number"
          ellipsis
          render={(value, record) => (
            <Link to={`show/${record.id}`}>{value}</Link>
          )}
        />
        <Table.Column dataIndex="first_name" title="First Name" ellipsis />
        <Table.Column dataIndex="last_name" title="Last Name" ellipsis />
      </Table>
    </List>
  )
}
