import { BaseKey } from '@refinedev/core'
import { IService } from './crm'
import { IMaterial } from './material'
import { IWarehouse } from './orderManagement'
import {
  IItemDetailed,
  IPackageDetail,
  IShortlistPackage,
} from './shortlistPackage'
import { IStaff } from './staff'
import { IVendor } from './vendor'

export interface IRFQItemDetails {
  item: IItemDetailed
  quotes: IQuoteItemDetailed[]
  has_materials: boolean
  materials: ILineItemMaterial[]
}

export interface IEventDetails {
  id: string
  created_at: string
  updated_at: string
  date_time: string
  dismantling_date_time: string
  pax: number
  venue: {
    line_1: string
    line_2: string
    city: string
    state: string
    pincode: string
    spoc_contact: string
    google_map_link: string
  }
  section: string
  decor_date_time: string
}

export interface IRFQMaterial {
  material: IMaterial
  quotes: IQuoteItemDetailed[]
  total_quantity: number
}

export interface SectionItemDetails {
  section: string
  items: IRFQItemDetails[]
  materials: IRFQMaterial[]
  details: IEventDetails
}

export interface IRFQSectionItem {
  package: IShortlistPackage
  sections: SectionItemDetails[]
  total: number
}

export interface IQuoteItem {
  id: string
  price: number
  parent: string
}

export interface IQuoteItemDetailed {
  id: string
  price: number
  true_price: number
  vendor: IVendor
  is_accepted: boolean
  is_vendor_locked: boolean
  qty: number
  section: BaseKey
}

export interface IQuote {
  id: string
  vendor: string
  event: string
  is_accepted: boolean
  items: IQuoteItem[]
}

export interface IQuoteDetailed {
  id: string
  vendor: string
  event: string
  is_accepted: boolean
  items: IQuoteItemDetailed[]
}

export interface IMetaData {
  discount: number
  percent: number
}

export interface IVendorTotal {
  vendor: IVendor
  total: number
  paid: number
  meta: IMetaData
  quotes_sent: number
  quotes_priced: number
}

export interface IServiceBreakdown {
  service: IService
  vendors: { vendor: IVendor; paid: number }[]
  total: number
  paid: number
}

export interface IVendorSummary {
  vendor: IVendor
  total: number
  paid: number
  meta: IMetaData
  services: { service: IService; total: number }[]
}

export interface IPackageMaterial {
  id: BaseKey
  qty: number
  is_real: boolean
  uom: string
  item: BaseKey
  material: IMaterial
}

export interface IPackageDetailMaterial {
  id: BaseKey
  qty: number
  is_real: boolean
  uom: string
  item: BaseKey
  material: IMaterial
  sources?: IMaterialSource[]
  qty_pending: number
  package_detail: IPackageDetail
  all_dates: string[]
}

export interface ILineItemMaterial {
  id: BaseKey
  qty: number
  is_real: boolean
  uom: string
  item: BaseKey
  material: IMaterial
  sources?: IMaterialSource[]
  add_by_default: boolean
  default_qty: number
}

export interface IEventMaterial {
  event: string
  materials: IPackageDetailMaterial[]
  package_detail_id: number
}

interface IItemMaterial {
  item: string
  materials: ILineItemMaterial[]
}

export interface ILineItemEventMaterial {
  event: string
  line_item_materials: IItemMaterial[]
}

export interface IEventSource {
  event: string
  sources: IMaterialSource[]
}

export enum MaterialSourceType {
  TO_EVENT = 'To Event',
  FROM_EVENT = 'From Event',
  AT_EVENT = 'At Event',
}
export interface IMaterialSource {
  id: BaseKey
  package_detail_material?: IPackageDetailMaterial
  material?: IMaterial
  qty: number
  type: MaterialSourceType
  spoc?: BaseKey
  availability: MaterialAvailability
  vendor?: IVendor
  warehouse?: IWarehouse
  notes?: string
  notes_count?: number
  date_time: string
  current_status: IMaterialStatus
  pid: string
  package: BaseKey
  is_real: boolean
  uom: string
}

export interface IMaterialToBuy {
  id: BaseKey
  package: string
  material: number
  qty_to_purchase: number
  qty_purchased: number
  qty_remaining: number
}
export interface IMaterialSourceNote {
  id: BaseKey
  material_source: BaseKey
  user: IStaff | BaseKey
  text: string
  created_at: string
}

export interface IMaterialStatus {
  id: BaseKey
  source?: number
  status: string
  completed_by?: BaseKey | IStaff
  is_approved: boolean
  approved_by?: BaseKey | IStaff
  approved_at?: string
  deadline?: string
  created_at: string
  updated_at: string
  maker_issue?: string[]
  checker_issue?: string[]
  media_count: number
}

export interface IMaterialStatusMedia {
  id: BaseKey
  status: BaseKey
  image?: string
  video?: string
}

export interface IMaterialComment {
  id: BaseKey
  material: BaseKey
  user: BaseKey
  text: string
  created_at: string
  updated_at: string
  parent?: BaseKey
}

export interface IMaterialCommentStructured {
  id: BaseKey
  material: BaseKey
  user: IStaff
  text: string
  created_at: string
  updated_at: string
  parent?: BaseKey
  replies?: IMaterialCommentStructured[]
}

export enum MaterialAvailability {
  WAREHOUSE = 'Warehouse',
  BUY = 'Buy',
  RENT = 'Rent',
  AT_VENUE = 'At Venue',
  VENUE = 'Venue',
  WAREHOUSE_BUY = 'Warehouse Buy',
}

export enum DistributionCenterType {
  VENUE = "Venue",
  WAREHOUSE = "Warehouse"
}

export enum MaterialStatus {
  BOM_FINALIZED = 'BOM Finalized',
  PROCURED = 'Procured',
  DISPATCHED = 'Dispatched',
  DELIVERED = 'Delivered',
}

export enum MaterialIssues {
  Quantity = 'Quantity',
  Color = 'Color',
  Dimension = 'Dimension',
  Quality = 'Quality',
  Damage = 'Damage',
  WrongItem = 'Wrong item',
  Other = 'Other',
  Delay = 'Delay',
}

export enum ProductIssues {
  Quantity = 'Quantity',
  Color = 'Color',
  Dimension = 'Dimension',
  Quality = 'Quality',
  Damage = 'Damage',
  WrongItem = 'Wrong item',
  Other = 'Other',
  Delay = 'Delay',
  PoorExecution = 'Poor execution',
  WrongExecution = 'Wrong execution',
}
