import { Button, Form, Input, InputNumber, Space, Tabs } from "antd";
import { file2Base64 } from "@refinedev/core";
import { RowRemoveButton } from 'components/button'
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import { SingleImageUpload } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IAttribute } from 'interfaces/attribute'
import { ICelebration } from 'interfaces/celebration'
import { UseFormReturnType } from 'interfaces/refine'
import { useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { setErrors } from 'utils/form'

export const CelebrationForm: React.FC<{
  useFormProps: UseFormReturnType<ICelebration>
}> = ({ useFormProps }) => {
  const { formProps, queryResult, mutationResult } = useFormProps

  const { selectProps: attributeSelectProps } = useSelectWithSearch<IAttribute>(
    {
      resource: 'core/attributes/',
      optionLabel: 'name',
      optionValue: 'id',
    },
  )

  const onFinish = async (values: any) => {
    let coverImage = ''
    const { cover_image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.cover_image

    if (cover_image) {
      const file = cover_image[cover_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values }
    if (coverImage.length) finalResponse['cover_image'] = coverImage
    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  useEffect(() => {
    if (mutationResult.isError) {
      setErrors(mutationResult.error.response.data, formProps.form)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult])

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic Info" key="Basic Info">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
          <SingleImageUpload
            label="Cover Image"
            initialImageUrl={queryResult?.data?.data?.cover_image}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Attributes Info" key="Attributes Info" forceRender>
          <Form.List name="attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ width: '100%' }}
                    align="baseline">
                    <Form.Item {...field} name={[field.name, 'order']}>
                      <InputNumber />
                    </Form.Item>

                    <Form.Item {...field} name={[field.name, 'attribute']}>
                      <SelectWithDefault
                        useSelectProps={{
                          resource: 'core/attributes',
                          optionLabel: 'name',
                          optionValue: 'id',
                        }}
                        dropdownStyle={{ minWidth: '50%' }}
                        style={{ minWidth: '50%' }}
                        placeholder="Select attribute"
                      />
                    </Form.Item>

                    <RowRemoveButton onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Add Attribute
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
