// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Card, notification, Space, Spin } from "antd";
import { BaseKey } from "@refinedev/core";
import { useForm } from 'components/hooks'
import { ITimeline } from 'interfaces/customer'
import { UseFormReturnType } from 'interfaces/refine'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TimelineForm } from './Form'
import { TimelineEditContext } from './Show'

export const UpdateTimeline: React.FC<{
  pid: BaseKey
  id: BaseKey
  setIsEditing: any
}> = ({ pid, id, setIsEditing }) => {
  const { isEditing: isGlobalEditing, setIsEditing: setIsGlobalEditing } =
    React.useContext(TimelineEditContext)

  let updateTimelineForm: UseFormReturnType<ITimeline> = useForm<ITimeline>({
    action: 'edit',
    resource: 'package_tool/timeline/timelines',
    id: Number(id),
    redirect: false,
    onMutationSuccess: (data) => {
      setIsEditing(false)
      setIsGlobalEditing!(false)
    },
  })

  useHotkeys('ctrl+s, cmd+s', (e) => {
    e.preventDefault()
    notification.info({
      message: 'Saving',
      duration: 1,
      description: 'Save in progress',
    })
    updateTimelineForm.formProps!.form!.submit()
  })

  return (
    <Card
      bodyStyle={{ borderColor: 'red' }}
      extra={
        <Space>
          {updateTimelineForm.formLoading && <Spin />}
          <Button
            key="edit"
            icon={<Icons.CloseOutlined />}
            size="small"
            onClick={() => {
              setIsEditing(false)
              setIsGlobalEditing!(false)
            }}
          />
          <Button
            key="save"
            icon={<Icons.SaveOutlined />}
            size="small"
            onClick={() => updateTimelineForm.form.submit()}
          />
        </Space>
      }>
      <TimelineForm
        useFormProps={updateTimelineForm}
        pid={Number(pid)}
        showSave={false}
      />
    </Card>
  )
}
