import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IPaymentType } from 'interfaces/payment'
import { PaymentTypeForm } from './Form'

export const PaymentTypeEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IPaymentType>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <PaymentTypeForm formProps={formProps} />
    </Edit>
  )
}
