import { useContext } from 'react'
import { FabricItem } from './FabricItem'
import { FloralItem } from './FloralItem'
import { PricingContext } from './index'

export const Item = ({}: {}) => {
  const value = useContext(PricingContext)
  return (
    <>
      {value.item && value.item.parent?.name === 'FLORAL' && <FloralItem />}
      {value.item && value.item.parent?.name === 'FABRIC' && <FabricItem />}
    </>
  )
}
