import { Edit, useForm } from "@refinedev/antd";
import { WarehouseForm } from './Form'

export const WarehouseEdit = () => {
  const useFormProps = useForm()
  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      <WarehouseForm useFormProps={useFormProps} />
    </Edit>
  )
}
