import { useSimpleList } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { List as AntdList, Button, Card, Descriptions, Space, Spin } from "antd";
import { BaseKey, useDelete, useUpdate } from "@refinedev/core";
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'
import { ITask } from 'interfaces/customer'
import { createContext, useContext, useState } from 'react'
import { TASK_URL } from 'urls'
import { getUserName } from 'utils/common'
import { TaskCreate } from './Create'
import { TaskEdit } from './Edit'

export const TaskEditContext = createContext<{
  isEditing?: boolean
  setIsEditing?: CallableFunction
}>({})

const TaskDetails = ({ task }: { task: ITask }) => {
  return (
    <Descriptions>
      <Descriptions.Item label="Assigned To">
        {task.assigned_to
          ? getUserName(task.assigned_to, false)
          : 'Not Assigned'}
      </Descriptions.Item>
      <Descriptions.Item label="Deadline">
        {task.deadline ? <DateFieldFromNow value={task.deadline} /> : 'Not Set'}
      </Descriptions.Item>
    </Descriptions>
  )
}

const TaskCard = (props: { task: ITask }) => {
  const { task } = props

  const { isEditing: isGlobalEditing, setIsEditing: setIsGlobalEditing } =
    useContext(TaskEditContext)
  const [isEditing, setIsEditing] = useState(false)

  const { mutate: deleteTask, isLoading: deleteLoading } = useDelete()
  const { mutate: patch, isLoading } = useUpdate()

  return (
    <>
      {isEditing ? (
        <TaskEdit pid={task.project} id={task.id} setIsEditing={setIsEditing} />
      ) : (
        <Card
          size="small"
          title={task.is_done ? <s>{task.text}</s> : task.text}
          actions={[
            <Button
              key="Done"
              loading={isLoading}
              icon={
                task.is_done ? (
                  <Icons.CloseCircleOutlined />
                ) : (
                  <Icons.CheckCircleOutlined />
                )
              }
              onClick={() =>
                patch({
                  resource: TASK_URL,
                  id: task.id,
                  values: { is_done: !task.is_done },
                })
              }>
              {task.is_done ? 'Undo' : 'Mark Done'}
            </Button>,
          ]}
          extra={
            !isGlobalEditing && (
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    setIsEditing(!isEditing)
                    setIsGlobalEditing!(true)
                  }}
                  icon={!isEditing && <Icons.EditOutlined />}
                />
                <Button
                  size="small"
                  loading={deleteLoading}
                  onClick={() => {
                    deleteTask({ resource: TASK_URL, id: task.id })
                  }}
                  icon={<Icons.DeleteOutlined />}
                />
              </Space>
            )
          }>
          <TaskDetails task={task} />
        </Card>
      )}
    </>
  )
}

export const TaskList = ({ pid }: { pid: BaseKey }) => {
  const [isEditing, setIsEditing] = useState(false)

  const //`useSimpleList` does not accept all of Ant Design's `List` component props anymore. You can directly use `List` component instead.,
        { listProps, queryResult: taskData } = useSimpleList<ITask>({
          resource: TASK_URL,
          permanentFilter: [
            {
              field: 'project',
              operator: 'eq',
              value: pid,
            },
            {
              field: 'expand',
              operator: 'eq',
              value: 'event,service',
            },
          ],
        });

  return (
    <div>
      <TaskEditContext.Provider value={{ isEditing, setIsEditing }}>
        <AntdList
          {...listProps}
          rowKey="id"
          size="small"
          header={
            taskData.isFetching && (
              <>
                Syncing <Spin size="small" />
              </>
            )
          }
          footer={!isEditing && <TaskCreate pid={pid} />}
          renderItem={(task) => (
            <>
              <TaskCard task={task} />
              <br />
            </>
          )}
        />
      </TaskEditContext.Provider>
    </div>
  )
}
