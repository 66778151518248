import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import { useEffect, useState } from 'react'
import { useForm as useFormSF } from 'sunflower-antd'
import * as Icons from '@ant-design/icons'
import { BaseKey, useList } from '@refinedev/core'
import { ICuisine } from 'interfaces/catering'
import { CUISINE_URL, DIETARY_PREFERENCE_URL } from 'urls'
const { Title } = Typography

interface MenuFilterProps {
  setFilters: (newFilters: any) => void
  setSorter: (sorter: any) => void
  setCurrent: (page: number) => void
  onFinish?: (values: any) => void
  onUndo?: () => void
}

const CuisineCheckboxGroup: React.FC<{ saveAndSubmit: () => void }> = ({
  saveAndSubmit,
}) => {
  const { data: courses, isLoading } = useList<ICuisine>({
    resource: CUISINE_URL,
  })
  const [search, setSearch] = useState<string>('')

  if (isLoading) {
    return <Icons.LoadingOutlined />
  }
  return (
    <>
      {courses?.data.length && (
        <Input
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          placeholder="Search Cuisines"
          style={{ marginBottom: 5 }}
        />
      )}
      <Form.Item name="cuisine">
        <Checkbox.Group onChange={saveAndSubmit}>
          {courses?.data
            .filter((cuisine) =>
              cuisine.name.toLowerCase().includes(search.toLowerCase()),
            )
            .map((cuisine) => (
              <Checkbox key={cuisine.id} value={cuisine.id}>
                {cuisine.name}
              </Checkbox>
            ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

const DietaryPreferenceCheckboxGroup: React.FC<{
  saveAndSubmit: () => void
}> = ({ saveAndSubmit }) => {
  const { data: dietaryPreference, isLoading } = useList({
    resource: DIETARY_PREFERENCE_URL,
    hasPagination: false,
  })

  if (isLoading) {
    return <Icons.LoadingOutlined />
  }
  return (
    <>
      <Form.Item name="dietary_preference">
        <Checkbox.Group onChange={saveAndSubmit}>
          {dietaryPreference?.data.map((dietary) => (
            <Checkbox key={dietary as unknown as BaseKey} value={dietary}>
              {dietary}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

export const MenuFilter: React.FC<MenuFilterProps> = ({
  setFilters,
  setSorter,
  setCurrent,
  onFinish,
  onUndo,
}) => {
  const [previousFilters, setPreviousFilters] = useState<any[]>([])
  const [showSearch, setShowSearch] = useState(true)
  const [showResetButton, setShowResetButton] = useState(false)
  const [activePanel, setActivePanel] = useState<string | string[]>()
  const [form] = Form.useForm()
  const formSF = useFormSF({ form })

  useEffect(() => {
    resetAll()
  }, [])

  const saveAndSubmit = async () => {
    setPreviousFilters([...previousFilters, form.getFieldsValue(true)])
    setShowResetButton(true)
    form.submit()
  }

  const handleUndo = async () => {
    const newFilters = previousFilters.slice(0, -1)
    setPreviousFilters(newFilters)
    onUndo && onUndo()
    form.setFieldsValue(newFilters[newFilters.length - 1])
    form.submit()
  }

  const resetAll = () => {
    setPreviousFilters([])
    setSorter([])
    setCurrent(1)
    setShowResetButton(false)
    form.resetFields()
    form.submit()
  }

  const toggleShowSearch = () => {
    setShowSearch(!showSearch)
  }

  const onSubmit = async (values: any) => {
    const filterValue = { ...previousFilters[previousFilters.length - 1] }
    setFilters([
      { field: 'search', operator: 'eq', value: filterValue.search },
      {
        field: 'dietary_preferences',
        operator: 'eq',
        value: filterValue.dietary_preference?.join(','),
      },
      {
        field: 'cuisine',
        operator: 'in',
        value: filterValue.cuisine?.join(','),
      },
    ])

    setSorter([
      {
        field: values.sort,
        order: values.sort.startsWith('-') ? 'desc' : 'asc',
      },
    ])
    onFinish && onFinish(filterValue)
    setCurrent(1)
  }

  return (
    <Form layout="vertical" {...formSF.formProps} onFinish={onSubmit}>
      <Row justify="space-between" align="middle">
        <Title level={4} style={{ fontFamily: 'Gilroy', fontWeight: '500' }}>
          Filters
        </Title>
        <Row justify="space-between" style={{ padding: '20px 0' }}>
          <Space direction="horizontal">
            {!!previousFilters.length && (
              <Button
                onClick={handleUndo}
                icon={<Icons.ArrowLeftOutlined />}
                shape="circle"
                style={{
                  background: '#FEF8E9',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              />
            )}
            {showResetButton && (
              <Button
                onClick={resetAll}
                icon={<Icons.SyncOutlined />}
                style={{
                  background: '#FEF8E9',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'none',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  display: 'inline-flex',
                }}
              />
            )}
            <Button
              onClick={toggleShowSearch}
              icon={
                showSearch ? <Icons.SearchOutlined /> : <Icons.CloseOutlined />
              }
              shape="circle"
              style={{
                background: '#F0EAF9',
                borderRadius: '50%',
                overflow: 'hidden',
                border: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                display: 'inline-flex',
              }}
            />
          </Space>
        </Row>
      </Row>
      <Form.Item name="search" hidden={showSearch}>
        <Input.Search
          hidden={showSearch}
          placeholder="Name, SKU"
          onSearch={saveAndSubmit}
          style={{ fontFamily: 'Gilroy' }}
          enterButton
        />
      </Form.Item>
      <Form.Item name="sort" initialValue="-created_at">
        <Select
          className="showcase-sort-select"
          defaultValue="-created_at"
          style={{ width: '100%' }}
          onChange={() => form.submit()}
          dropdownStyle={{ fontFamily: 'Gilroy' }}
          suffixIcon={
            <>
              <Divider
                type="vertical"
                style={{ borderLeft: '1.5px solid grey' }}></Divider>
              <Typography>Sort By</Typography>
            </>
          }
          options={[
            { value: '-created_at', label: 'Newest' },
            { value: 'menu_total', label: 'Price(Low to High)' },
            { value: '-menu_total', label: 'Price(High to Low)' },
          ]}
        />
      </Form.Item>
      <Collapse
        defaultActiveKey={['dietary_preference']}
        onChange={(value) => setActivePanel(value)}
        bordered={false}
        accordion={true}
        expandIconPosition="right"
        style={{
          border: 'none',
          backgroundColor: 'white',
          marginTop: '10px',
        }}>
        <Collapse.Panel header="Dietary Preference" key="dietary_preference">
          <DietaryPreferenceCheckboxGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Cuisines" key="cuisines">
          <CuisineCheckboxGroup saveAndSubmit={saveAndSubmit} />
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}
