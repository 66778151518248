import { List as AntdList, Button, InputNumber, Space } from 'antd'
import { HttpError, useApiUrl, useCustom, useList } from '@refinedev/core'
import { GridSelect } from 'components/input/GridSelect'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { IProduct } from 'interfaces/product'
// import TinderCard from 'react-tinder-card'
import './styles.less'
import React from 'react'
import * as Icons from '@ant-design/icons'

export const PackageGeneration: React.FC = () => {
  // const [selectedItems, setSelectedItems] = useState<any>([])
  const [budget, setBudget] = useState<number>(100000)
  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(100)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const productIdsRef = useRef<number[]>([])

  const apiUrl = useApiUrl()

  const {
    data: packageData,
    isFetching,
    refetch,
  } = useCustom<any>({
    url: `${apiUrl}/package_tool/shortlist_packages/generate_package/`,
    method: 'post',
    config: {
      payload: {
        imageType: 'Product',
        products: productIdsRef.current,
        budget: budget,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const { data: product_data } = useList<IProduct, HttpError>({
    resource: 'cms/product/',
    pagination: {
      current,
      pageSize,
    },
    filters: [
      {
        field: 'is_internal',
        operator: 'eq',
        value: false,
      },
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
      {
        field: 'showcase',
        operator: 'eq',
        value: true,
      },
      {
        field: 'is_published',
        operator: 'eq',
        value: true,
      },
      {
        field: 'category__in',
        operator: 'eq',
        value: 5,
      },
    ],
  })

  useEffect(() => {
    setCurrentIndex(
      product_data?.data?.length ? product_data?.data?.length - 1 : 0,
    )
  }, [product_data])

  useEffect(() => {
    window.document.title = 'Meragi | Showcase'
    // @ts-ignore
    window.document.querySelector('body').style.overflowX = 'hidden'
  }, [])

  const addProductId = (id: number) => {
    productIdsRef.current.push(id)
  }
  const removeProductId = (id: number) => {
    if (productIdsRef.current.includes(id)) {
      productIdsRef.current = productIdsRef.current.filter((num) => num !== id)
    }
  }

  const [lastDirection, setLastDirection] = useState<string | undefined>()
  const currentIndexRef = useRef<number>(currentIndex)
  const childRefs = useMemo<any>(
    () =>
      Array(pageSize)
        .fill(0)
        .map(() => React.createRef()),
    [],
  )

  const updateCurrentIndex = (val: number): void => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack: boolean =
    currentIndex < (product_data?.data?.length ?? 1 - 1)
  const canSwipe: boolean = currentIndex >= 0

  const swiped = (direction: string, product: any, index: number): void => {
    // Function get invoked when the card is swiped left or right.
    if (direction === 'right') addProductId(product.id)
    if (direction === 'left') removeProductId(product.id)
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
  }

  const outOfFrame = (name: string, idx: number): void => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    if (currentIndexRef.current >= idx) {
      childRefs[idx].current?.restoreCard()
    }
  }

  const swipe = async (dir: string): Promise<void> => {
    if (
      product_data?.data?.length &&
      canSwipe &&
      currentIndex < product_data?.data?.length
    ) {
      await childRefs[currentIndex].current?.swipe(dir)
    }
  }

  const goBack = async (): Promise<void> => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current?.restoreCard()
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        Budget:{' '}
        <InputNumber
          value={budget}
          onChange={(value: any) => {
            setBudget(value)
          }}
        />
        <br />
        <Button
          type="primary"
          onClick={() => {
            refetch()
          }}>
          {' '}
          Generate Package
        </Button>
      </div>

      <div className="app">
        <div className="cardContainer">
          {/* {product_data?.data.map((character, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={character.name + character.id}
              onSwipe={(dir) => swiped(dir, character, index)}
              onCardLeftScreen={() => outOfFrame(character.name, index)}>
              <div
                style={{
                  backgroundImage: 'url(' + character.images[0].image + ')',
                }}
                className="card"></div>
              {currentIndex === index && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}>
                  {character.name}
                </div>
              )}
            </TinderCard>
          ))} */}
        </div>
        <div className="buttons">
          <Button
            type="text"
            icon={
              <Icons.CloseOutlined
                style={{ fontSize: '40px', color: 'grey' }}
              />
            }
            style={{
              backgroundColor: !canSwipe ? '#c3c4d3' : undefined,
            }}
            onClick={() => swipe('left')}
          />
          <Button
            type="text"
            icon={
              <Icons.UndoOutlined
                style={{ fontSize: '40px', color: 'orange' }}
              />
            }
            style={{ backgroundColor: !canGoBack ? '#c3c4d3' : undefined }}
            onClick={() => goBack()}
          />
          <Button
            type="text"
            icon={
              <Icons.HeartFilled
                style={{ fontSize: '40px', color: '#EA2027' }}
              />
            }
            style={{
              backgroundColor: !canSwipe ? '#c3c4d3' : undefined,
            }}
            onClick={() => swipe('right')}
          />
        </div>
      </div>
      {isFetching && '....Generating'}
      {packageData && (
        <a
          href={
            'https://www.meragi.studio/package_tool/shortlist_packages/edit/' +
            packageData.data.package_id
          }
          target="_blank">
          New Package Url
        </a>
      )}
      <br />
      {packageData && (
        <pre>
          {JSON.stringify(packageData.data.required_attributes, null, '\t')}
        </pre>
      )}
    </div>
  )
}
