import { file2Base64, useList } from '@refinedev/core'
import { Form, Input } from 'antd'
import { DynamicInput, SingleImageUpload } from 'components/input'
import { REQUIRES_OPTIONS } from 'consts'
import { IAttribute } from 'interfaces/attribute'

export const MoodBoardImageForm: React.FC<{
  useFormProps: any
}> = ({ useFormProps }) => {
  console.log(useFormProps)
  const { formProps, queryResult } = useFormProps
  const attribute_required = [3, 10, 9, 4, 16, 6]

  const attributeQueryResult = useList<IAttribute>({
    resource: 'core/attributes/',
    pagination: {
      pageSize: 100,
    },
  })

  const onFinish = async (values: any) => {
    let latest_image = ''
    const { image } = values
    // generate a list of map with attribute id and value
    const attributesWithValue = []
    for (let key in values) {
      if (key.match(/^generated_/)) {
        const keySplit = key.split('_')
        const type = keySplit[3]
        let valueOptions:
          | ({ [s: string]: unknown } | ArrayLike<unknown>)[]
          | { option: any }[] = []

        // build option values
        if (REQUIRES_OPTIONS.includes(type)) {
          if (Array.isArray(values[key]))
            valueOptions = values[key].map((value: string) => ({
              option: value,
            }))
          else valueOptions.push({ option: values[key] })
          values[key] = null
        }

        const finalValue: { [key: string]: any } = {
          attribute: keySplit[2],
          value: String(values[key]),
          value_options: valueOptions,
          images: [],
        }
        if (keySplit[1] !== 'undefined') finalValue['id'] = keySplit[1]
        // if there is no value or no valueOption selected do not add that attribute
        // to the payload
        if (
          values[key] ||
          (valueOptions.length &&
            Object.keys(valueOptions[0]).length &&
            Object.values(valueOptions[0])[0])
        ) {
          attributesWithValue.push(finalValue)
        }
        delete values[key]
      }
    }

    if (image) {
      const file = image[image?.length - 1]
      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        latest_image = base64String
      }
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        attributes: attributesWithValue,
        image: latest_image,
      })
    )
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <SingleImageUpload
        label="Image"
        name="image"
        buttonText="Image"
        initialImageUrl={queryResult?.data?.data?.image}
      />
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      {attribute_required.map((attribute) => {
        const attr_details = attributeQueryResult.data?.data.find(
          (attr) => attr.id === attribute,
        )
        if (attr_details)
          return (
            <DynamicInput
              key={queryResult?.data?.data?.id}
              attr_details={attr_details}
              values={queryResult?.data?.data?.attributes}
            />
          )
        return null
      })}
    </Form>
  )
}
