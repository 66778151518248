import * as Icons from "@ant-design/icons";
import { Breadcrumb } from 'antd'
import { humanize } from 'utils/common'

export const NestedBreadcrumb = ({
  baseUrl,
  routes,
}: {
  baseUrl: string
  routes: { name: string; id?: string | number }[]
}) => {
  const buildCrumb = () => {
    let built = baseUrl
    const crumbs: any[] = []
    routes.forEach((route, idx) => {
      crumbs.push({
        route: idx < routes.length - 1 ? `${built}/${route.name}` : undefined,
        ...route,
        name: humanize(route.name),
      })
      built += `/${route.name}/${route.id}`
    })
    return crumbs
  }
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="">
        <Icons.HomeOutlined />
      </Breadcrumb.Item>
      {buildCrumb().map((crumb) => (
        <Breadcrumb.Item href={crumb.route} key={crumb.id}>
          <span>{crumb.name}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}
