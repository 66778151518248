import * as Icons from '@ant-design/icons'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InboxOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { ImageField, useForm } from '@refinedev/antd'

import {
  List as AntdList,
  Button,
  Card,
  Collapse,
  Descriptions,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from 'antd'

import {
  useApiUrl,
  useCan,
  useCreate,
  useDelete,
  useModal,
} from '@refinedev/core'

import { PDFViewer, PriceField } from 'components/field'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'
import parse from 'html-react-parser'
import { ChangeType, IAlternateItem, IItem } from 'interfaces/shortlistPackage'
import React, { ReactElement, useContext, useState } from 'react'
import {
  CHANGE_TRACKER_URL,
  LINE_ITEM_IMAGE_URL,
  PRICING_MAIN_URL,
  SHORTLIST_LINE_ITEM_URL,
} from 'urls'
import { EditPackageContext, EditPackageContextType } from '../Edit'
import { AlternateItem } from '../alternateItems'
import { CommentsModal } from '../components/CommentsModal'
import { CreateShortlistLineItem } from './Create'
import { EditShortlistLineItem } from './Edit'

const ShowLineItemDetails = ({
  lineItem,
  editPackageStatus,
  edit,
  setEdit,
  setEditPackageStatus,
  refetch,
  refetchPackage,
}: {
  lineItem: IItem
  editPackageStatus: boolean
  edit: boolean
  setEdit: any
  setEditPackageStatus: any
  refetch: any
  refetchPackage: any
}) => {
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false)
  const [showUpload, setshowUpload] = useState(false)
  const [showReferenceUpload, setShowReferenceUpload] = useState(false)

  const [executedFileList, setExecutedFileList] = useState<any>([])

  const [changeDescription, setChangeDescription] = useState('')

  const _package = useContext(ShortlistPackageContext)
  const { isFinalized } = useContext(
    EditPackageContext,
  ) as EditPackageContextType

  const [referenceFileList, setReferenceFileList] = useState<any>([])
  const { mutate } = useDelete()

  const { mutate: post } = useCreate()

  const { mutate: destroy } = useDelete()

  const apiUrl = useApiUrl()

  const { mutate: createChangeTracker } = useCreate()

  const {
    visible: visibleChangeDescription,
    show: showChangeDescription,
    close: closeChangeDescription,
  } = useModal()

  const handleCreateTracker = () => {
    createChangeTracker(
      {
        resource: CHANGE_TRACKER_URL,
        values: {
          change_type: ChangeType.DELETE,
          line_item: lineItem?.id,
          package: _package?.id,
          description: changeDescription,
        },
      },
      {
        onSuccess: () => {},
      },
    )
  }

  const deleteShortlistItem = (id: string) => {
    lineItem?.id &&
      mutate(
        {
          resource: 'package_tool/shortlist_package/line_items',
          id: id,
        },
        {
          onSuccess: (data, variables, context) => {
            refetch()
            refetchPackage()
          },
        },
      )
  }

  const { data: validateItem } = useCan({
    resource: 'validate_line_item',
    action: '',
  })

  const getActionButtons = () => {
    let finalList: ReactElement[] = []
    if (editPackageStatus && !edit)
      finalList = [
        ...[
          <Button
            onClick={() => {
              setEdit(!edit)
              setEditPackageStatus(false)
            }}
            size="small"
            type="text"
            icon={<EditOutlined />}
            key="edit">
            Edit
          </Button>,
          <Popconfirm
            title="Do you Want to delete this Shortlist Item ?"
            open={openDeleteItemModal}
            onConfirm={() => {
              setOpenDeleteItemModal(false)
              if (isFinalized) {
                showChangeDescription()
              } else {
                deleteShortlistItem(lineItem?.id)
                refetch()
              }
            }}
            key="delete"
            onCancel={() => setOpenDeleteItemModal(false)}
            okText="Yes"
            cancelText="No">
            <Button
              size="small"
              danger
              type="text"
              onClick={() => setOpenDeleteItemModal(true)}
              icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>,
        ],
      ]
    if (editPackageStatus && !edit && !isFinalized)
      finalList = [
        ...finalList,
        ...[
          <Button
            disabled={!validateItem?.can}
            onClick={() => {
              post(
                {
                  resource: `${SHORTLIST_LINE_ITEM_URL}/${lineItem?.id}/toggle_validate_item`,
                  values: {},
                },
                {
                  onSuccess: () => {
                    refetch()
                    refetchPackage()
                  },
                },
              )
            }}
            size="small"
            type="text"
            icon={
              lineItem.is_validated ? (
                <CloseCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            }
            key="validate">
            Validate{' '}
            {!!lineItem.comments_count?.unresolved_count && (
              <Tooltip title="Unresolved Comments">
                <ExclamationCircleFilled style={{ color: 'red' }} />
              </Tooltip>
            )}
          </Button>,
          <CommentsModal
            key="comment"
            lineItem={lineItem}
            refetchLineItem={refetch}
          />,
        ],
      ]
    finalList = [
      ...finalList,
      ...[
        <Button
          onClick={() => {
            setshowUpload(!showUpload)
          }}
          size="small"
          type="text"
          icon={showUpload ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          key="upload">
          Upload Executed
        </Button>,
        <Button
          onClick={() => {
            setShowReferenceUpload(!showReferenceUpload)
          }}
          size="small"
          type="text"
          icon={
            showReferenceUpload ? <EyeInvisibleOutlined /> : <EyeOutlined />
          }
          key="upload">
          Upload Reference
        </Button>,
      ],
    ]
    return finalList
  }

  return (
    <>
      <Modal
        open={visibleChangeDescription}
        title="Request Changes?"
        okButtonProps={{
          disabled: !changeDescription.length,
        }}
        onOk={() => {
          handleCreateTracker()
          closeChangeDescription()
          setChangeDescription('')
        }}
        onCancel={() => {
          closeChangeDescription()
          setChangeDescription('')
        }}>
        <Input.TextArea
          value={changeDescription}
          onChange={(e) => setChangeDescription(e.target.value)}
          placeholder="Add a nice description informing about the changes"
        />
      </Modal>
      <AntdList.Item
        actions= { !_package?.is_cancelled ? [...getActionButtons()] : undefined}
        extra={
          lineItem?.product ? (
            <ImageField
              value={lineItem?.product?.images[0]?.image}
              height={150}
              width={150}
              style={{ borderRadius: '20px' }}
            />
          ) : (
            <PDFViewer fileUrl={lineItem.file as string} />
          )
        }>
        <AntdList.Item.Meta
          title={
            <Space direction="vertical">
              {lineItem.product?.name}
              <Space>
                {lineItem.is_included && <Tag color="green">Included</Tag>}
                {lineItem.is_validated && <Tag color="orange">Validated</Tag>}
              </Space>
            </Space>
          }
        />
        <Descriptions column={4}>
          <Descriptions.Item label="Quantity">
            {lineItem.quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Price">
            <Space>
              <PriceField value={lineItem.price ?? 0.0} />
              {!isFinalized && (
                <Space>
                  <Tooltip key="calculate" overlay="Calculate Price">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() =>
                        window.open(
                          `/${PRICING_MAIN_URL}/?productId=${lineItem.product?.id}&lineItemId=${lineItem.id}`,
                        )
                      }>
                      ₹
                    </Button>
                    <Button
                      size="small"
                      type="default"
                      onClick={() =>
                        window.open(
                          `/${PRICING_MAIN_URL}_v2/?productId=${lineItem.product?.id}&lineItemId=${lineItem.id}`,
                        )
                      }>
                      ₹ v2
                    </Button>
                  </Tooltip>
                  <Tooltip overlay="Refresh Price">
                    <Button
                      size="small"
                      type="dashed"
                      key="refresh"
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        refetch()
                        refetchPackage()
                      }}
                    />
                  </Tooltip>
                </Space>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Total">
            <PriceField value={lineItem?.quantity * lineItem.price} />
          </Descriptions.Item>
          <Descriptions.Item label={'Executed Image Count'}>
            {lineItem?.executed_image_itm_count ?? 0}
          </Descriptions.Item>
        </Descriptions>

        <Card size="small">
          <Tabs
            size="small"
            onChange={(key: string) => {
              if (key === 'referenceImgs') {
                refetch()
              }
            }}>
            <Tabs.TabPane tab="Description" key="description">
              {parse(lineItem.description ?? '')}{' '}
            </Tabs.TabPane>
            {!!lineItem.reference_images.length && (
              <Tabs.TabPane tab="Reference Images" key="referenceImgs">
                {lineItem.reference_images.map((value) => {
                  return (
                    <ImageField
                      value={value.image}
                      height={100}
                      width={100}
                      style={{ borderRadius: '20px', padding: '5px' }}
                      key={`referenceImg`}
                      preview={{
                        mask: (
                          <>
                            {editPackageStatus && (
                              <Button
                                key="remove"
                                icon={<Icons.DeleteOutlined />}
                                size="small"
                                style={{
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                }}
                                danger
                                onClick={(event: React.MouseEvent) => {
                                  event.stopPropagation()
                                  destroy(
                                    {
                                      resource: LINE_ITEM_IMAGE_URL,
                                      id: value.id,
                                    },
                                    {
                                      onSuccess: () => refetch(),
                                    },
                                  )
                                }}
                              />
                            )}
                          </>
                        ),
                      }}
                    />
                  )
                })}
              </Tabs.TabPane>
            )}
          </Tabs>
        </Card>
        {showUpload && (
          <div>
            <Upload.Dragger
              multiple
              action={`${apiUrl}/${SHORTLIST_LINE_ITEM_URL}/${lineItem.id}/upload_executed/`}
              headers={{
                Authorization: `Token ${localStorage.getItem('token')}`,
              }}
              fileList={executedFileList}
              onChange={(info) => {
                setExecutedFileList([...info.fileList])
              }}
              data={async (file) => ({ image: file })}
              itemRender={(originNode, file, fileList) => {
                if (file.status !== 'done') {
                  return (
                    <>
                      {file.name}{' '}
                      {file.status === 'error' ? (
                        <ExclamationCircleOutlined style={{ color: 'red' }} />
                      ) : (
                        <LoadingOutlined />
                      )}
                    </>
                  )
                }
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag files to this area to upload
              </p>
              <p className="ant-upload-text">
                uploaded{' '}
                {
                  executedFileList.filter((file: any) => file.status === 'done')
                    .length
                }
                /{executedFileList.length} files
              </p>
            </Upload.Dragger>
          </div>
        )}
        {showReferenceUpload && (
          <div>
            <Upload.Dragger
              multiple
              action={`${apiUrl}/${SHORTLIST_LINE_ITEM_URL}/${lineItem.id}/add_image/`}
              headers={{
                Authorization: `Token ${localStorage.getItem('token')}`,
              }}
              fileList={referenceFileList}
              onChange={(info) => {
                setReferenceFileList([...info.fileList])
              }}
              data={async (file) => ({ image: file })}
              itemRender={(originNode, file, fileList) => {
                if (file.status !== 'done') {
                  return (
                    <>
                      {file.name}{' '}
                      {file.status === 'error' ? (
                        <ExclamationCircleOutlined style={{ color: 'red' }} />
                      ) : (
                        <LoadingOutlined />
                      )}
                    </>
                  )
                }
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or Drag files to this area to upload reference images
              </p>
              <p className="ant-upload-text">
                Uploaded{' '}
                {
                  referenceFileList.filter(
                    (file: any) => file.status === 'done',
                  ).length
                }
                /{referenceFileList.length} files
              </p>
            </Upload.Dragger>
          </div>
        )}
      </AntdList.Item>
    </>
  )
}

export const ShortlistLineItem: React.FC<{
  lineItem?: IItem
  refetch: any
  refetchPackage: any
}> = ({ lineItem, refetch, refetchPackage }) => {
  const { editPackageStatus, setEditPackageStatus } = useContext(
    EditPackageContext,
  ) as EditPackageContextType
  const _package = useContext(ShortlistPackageContext)
  const [addAlternateItem, setAddAlternateItem] = useState(false)
  const [edit, setEdit] = useState(false)

  const { formProps, saveButtonProps, form } = useForm<IAlternateItem>({
    action: 'create',
    resource: 'package_tool/shortlist_package/alternate_items',
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      refetchPackage()
      form.resetFields()
      setAddAlternateItem(false)
    },
  })

  const {
    formProps: editFormProps,
    saveButtonProps: editSaveButttonProps,
    form: editForm,
    queryResult: editQueryResult,
  } = useForm<IItem>({
    action: 'edit',
    resource: 'package_tool/shortlist_package/line_items',
    redirect: false,
    id: lineItem?.id,
    onMutationSuccess: () => {
      refetch()
      refetchPackage()
      setEditPackageStatus(true)
      form.resetFields()
      setEdit(false)
    },
  })

  return (
    <>
      {lineItem && (
        <div>
          {!edit && (
            <ShowLineItemDetails
              lineItem={lineItem}
              editPackageStatus={editPackageStatus}
              edit={edit}
              setEdit={setEdit}
              setEditPackageStatus={setEditPackageStatus}
              refetch={refetch}
              refetchPackage={refetchPackage}
            />
          )}
          {edit && (
            <EditShortlistLineItem
              form={editForm}
              formProps={editFormProps}
              lineItemId={lineItem.id}
              saveButtonProps={editSaveButttonProps}
              cancelEdit={() => {
                editForm.resetFields()
                setEdit(false)
              }}
              refetch={editQueryResult?.refetch}
            />
          )}
          <Collapse>
            <Collapse.Panel
              header={`Alternates (${lineItem?.alternates?.length})`}
              key="alternates">
              <AntdList
                itemLayout="vertical"
                size="large"
                dataSource={lineItem?.alternates}
                renderItem={(alternateItem) => (
                  <>
                    <AlternateItem
                      alternateItem={alternateItem}
                      key={alternateItem.id}
                      refetch={refetch}
                      refetchPackage={refetchPackage}
                    />
                    <Divider />
                  </>
                )}
              />
              {editPackageStatus && !addAlternateItem && !_package?.is_cancelled && (
                <Button
                  onClick={() => {
                    setAddAlternateItem(!addAlternateItem)
                  }}
                  block
                  type="dashed">
                  Add Product
                </Button>
              )}
              {addAlternateItem && (
                <CreateShortlistLineItem
                  form={form}
                  saveButtonProps={saveButtonProps}
                  formProps={formProps}
                  lineItemId={lineItem?.id}
                  cancelSave={() => {
                    setAddAlternateItem(!addAlternateItem)
                  }}
                />
              )}
            </Collapse.Panel>
          </Collapse>
          <Divider />
        </div>
      )}
    </>
  )
}
