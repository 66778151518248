import { BooleanField } from '@refinedev/antd'
import { Descriptions } from 'antd'
import { PriceField } from 'components/field'
import { INearbyAccommodation, IVenue } from 'interfaces/venue'

export const NearbyAccommodationDetails = ({
  item,
}: {
  item: INearbyAccommodation
}) => {
  return (
    <Descriptions column={4}>
      <Descriptions.Item label="Venue">
        {item.venue_option && (item.venue_option as IVenue).name}
      </Descriptions.Item>
      <Descriptions.Item label="Option Text">
        {item.venue_option_text}
      </Descriptions.Item>
      <Descriptions.Item label="Price">
        <PriceField value={item.price ?? 0} />
      </Descriptions.Item>
      <Descriptions.Item label="Price on Enquiry">
        <BooleanField value={item.price_on_enquiry} />
      </Descriptions.Item>
      <Descriptions.Item label="Time Away (min)">{item.time}</Descriptions.Item>
      <Descriptions.Item label="Distance Away (m)">
        {item.distance}
      </Descriptions.Item>
    </Descriptions>
  )
}
