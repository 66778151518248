import { useGetIdentity } from '@refinedev/core'
import {
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tabs,
  Transfer,
} from 'antd'
import { useTransfer } from 'components/hooks'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IPermission } from 'interfaces/permission'
import { UseFormReturnType } from 'interfaces/refine'
import { IStaff } from 'interfaces/staff'
import { REGION_URL, STAFF_URL } from 'urls'

export const StaffForm: React.FC<{ useFormProps: UseFormReturnType }> = ({
  useFormProps,
}) => {
  const { formProps, id } = useFormProps

  const { data: user } = useGetIdentity<IStaff>()

  const { transferProps } = useTransfer<IPermission>({
    resource: 'core/permissions',
    fetchSize: 100,
  })

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={(values) => {
        return (
          formProps.onFinish &&
          formProps.onFinish({
            ...values,
          })
        )
      }}>
      <Tabs>
        <Tabs.TabPane tab="Basic Info" key={1}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email' }]}>
            <Input disabled={!!id} />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="First Name" name="first_name">
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name="last_name">
            <Input />
          </Form.Item>
          <Form.Item
            label="Consultant"
            name="is_consultant"
            valuePropName="checked">
            <Switch defaultChecked />
          </Form.Item>
          <Form.Item name="manager" label="Manager">
            <SelectWithDefault<IStaff>
              useSelectProps={{ resource: STAFF_URL }}
              renderOptions={(staffs) =>
                staffs?.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.first_name} {staff.last_name}
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Settings"
          key="2"
          forceRender
          disabled={!user?.is_superuser && Number(id) === user?.id}>
          <Form.Item
            label="Active"
            name="is_active"
            valuePropName="checked"
            initialValue={true}
            help="The staff can only login if the they are active">
            <Switch />
          </Form.Item>
          <Divider />
          <Space split={<Divider type="vertical" />}>
            <Form.Item
              label="Super User"
              name="is_superuser"
              valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Staff"
              name="is_staff"
              valuePropName="checked"
              initialValue={true}>
              <Switch />
            </Form.Item>
            <Form.Item
              label="External"
              name="is_external"
              valuePropName="checked"
              initialValue={false}>
              <Switch />
            </Form.Item>
          </Space>
          <Divider />
          <Space split={<Divider type="vertical" />}>
            <Form.Item
              label="Web Access"
              name="web_access"
              valuePropName="checked"
              initialValue={true}>
              <Switch />
            </Form.Item>
            <Form.Item
              label="App Access"
              name="app_access"
              valuePropName="checked"
              initialValue={true}>
              <Switch />
            </Form.Item>
          </Space>
          <Divider />
          <Form.Item label="Groups" name="groups">
            <SelectWithDefault
              useSelectProps={{
                resource: 'core/groups',
                optionLabel: 'name',
              }}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Permissions"
            name="user_permissions"
            valuePropName="targetKeys">
            <Transfer
              {...transferProps}
              showSearch
              listStyle={{ width: '50%', height: 400 }}
            />
          </Form.Item>
          <Form.Item label="Region" name="region">
            <SelectWithDefault
              useSelectProps={{
                resource: REGION_URL,
                optionLabel: 'name',
              }}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
