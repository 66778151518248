import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber, Select, Switch } from 'antd'
import { SurchargeApplyTo, SurchargeType } from 'interfaces/catering'
import { createOptionsFromEnum } from 'utils/common'

export const SurchargeForm: React.FC<{
  formProps: FormProps
  menuId?: BaseKey
}> = ({ formProps, menuId }) => {
  const onFinish = async (values: any) => {
    const finalResponse = {
      ...values,
      menu: menuId,
    }

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Type" name="surcharge_type">
        <Select options={createOptionsFromEnum(SurchargeType)} />
      </Form.Item>
      <Form.Item label="Initial Value" name="value">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Apply To" name="apply_to">
        <Select options={createOptionsFromEnum(SurchargeApplyTo)} />
      </Form.Item>
      <Form.Item
        label="Optional"
        name="is_optional"
        valuePropName="checked"
        tooltip={{
          title:
            'Should this surcharge be added to all the menus automatically',
        }}>
        <Switch defaultChecked />
      </Form.Item>
    </Form>
  )
}
