import { RefreshButton, TextField, useSelect } from '@refinedev/antd'
import { BaseKey, HttpError, useCan, useList } from '@refinedev/core'
import { Button, Select, Space, Switch, Table, Typography } from 'antd'
import { MaterialAvailability } from 'interfaces/quote'
import { MaterialSourceModalForm } from 'pages/package_tool/materialSource/Form'
import React, { useEffect, useState } from 'react'
import { MATERIAL_URL, RFQ_URL } from 'urls'

const PendingTable = ({
  disableTracking,
  packageId,
}: {
  disableTracking: boolean
  packageId?: BaseKey
}) => {
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [materialAvailability, setMaterialAvailability] = useState<string>('')
  const [pendingMaterial, setPendingMaterial] = useState<any>(undefined)
  const [showAllMaterials, setShowAllMaterials] = useState<boolean>(false)
  const [materialCategory, setMaterialCategory] = useState<string[]>([])

  const {
    data: pending_data,
    isFetching,
    refetch,
  } = useList<any, HttpError>({
    resource: `${RFQ_URL}/${packageId}/get_pending_table`,
    filters: [
      { field: 'expand', operator: 'eq', value: 'material' },
      {
        field: 'show_all',
        operator: 'eq',
        value: !!showAllMaterials ? 'true' : null,
      },
      {
        field: 'material_category',
        operator: 'eq',
        value: materialCategory ? materialCategory.join(',') : '',
      },
    ],
    pagination: {
      mode: 'off',
    },
  })

  const { selectProps: materialCategorySelectProps } = useSelect({
    resource: `${MATERIAL_URL}/material_category_options`,
    optionLabel: 'name',
    optionValue: 'name',
  })

  const { data: addMaterialSourcePerm } = useCan({
    resource: 'add_materialsource',
    action: '',
  })

  return (
    <div>
      <Table
        size="small"
        dataSource={pending_data?.data}
        pagination={false}
        rowKey={(record) => record.event}
        loading={isFetching}
        title={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography.Text strong>Pending Material</Typography.Text>
            <Space direction="horizontal">
              <Typography.Text strong>Material Category</Typography.Text>
              <Select
                {...materialCategorySelectProps}
                allowClear
                showSearch={false}
                style={{
                  width: '200px',
                }}
                mode="multiple"
                onChange={(value: any) => setMaterialCategory(value)}
              />
              <Typography.Text>All Materials</Typography.Text>
              <Switch
                title="Show Pending Materials"
                onChange={(checked) => setShowAllMaterials(!checked)}
                defaultChecked
              />
              <Typography.Text>Pending Material</Typography.Text>
              <RefreshButton onClick={() => refetch()} />
              <Button onClick={() => window.open('/material-atp')}>
                ATP Page
              </Button>
            </Space>
          </div>
        )}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <>
              <Table
                dataSource={record.pending_items}
                size="small"
                rowKey={(record) => record.id}
                pagination={false}>
                <Table.Column<any>
                  dataIndex={['package_detail', 'section', 'name']}
                  title="Event"
                  width={'20vw'}
                  render={(value, record) => (
                    <>
                      <TextField value={value} />
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['material', 'name']}
                  title="Material"
                  // width={'20vw'}
                  render={(value, record) => (
                    <>
                      <TextField value={value} />
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['qty_required']}
                  title="Required Qty"
                  render={(value, record) => (
                    <>
                      <TextField value={value} /> {record.uom}
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['qty_warehouse']}
                  title="Warehouse Qty"
                  render={(value, record) => (
                    <>
                      <TextField value={value} />
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['qty_buy']}
                  title="Buy Qty"
                  render={(value, record) => (
                    <>
                      <TextField value={value} />
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['qty_rent']}
                  title="Rent Qty"
                  render={(value, record) => (
                    <>
                      <TextField value={value} />
                    </>
                  )}
                />
                <Table.Column<any>
                  dataIndex={['qty_pending']}
                  title="Pending Qty"
                  render={(value, record) => (
                    <>
                      <TextField
                        value={value}
                        style={{
                          color: record.qty_pending !== 0 ? 'red' : 'green',
                        }}
                      />
                    </>
                  )}
                />
                <Table.Column<any>
                  title="Create"
                  render={(value, record) => (
                    <>
                      <Button
                        onClick={() => {
                          setIsCreating(true)
                          setMaterialAvailability(
                            MaterialAvailability.WAREHOUSE,
                          )
                          setPendingMaterial(record)
                        }}
                        disabled={
                          disableTracking || !addMaterialSourcePerm?.can
                        }>
                        W
                      </Button>
                      <Button
                        onClick={() => {
                          setIsCreating(true)
                          setMaterialAvailability(MaterialAvailability.BUY)
                          setPendingMaterial(record)
                        }}
                        disabled={
                          disableTracking || !addMaterialSourcePerm?.can
                        }>
                        B
                      </Button>
                      <Button
                        onClick={() => {
                          setIsCreating(true)
                          setMaterialAvailability(MaterialAvailability.RENT)
                          setPendingMaterial(record)
                        }}
                        disabled={
                          disableTracking || !addMaterialSourcePerm?.can
                        }>
                        R
                      </Button>
                      <Button
                        onClick={() => {
                          setIsCreating(true)
                          setMaterialAvailability(
                            MaterialAvailability.WAREHOUSE_BUY,
                          )
                          setPendingMaterial(record)
                        }}
                        disabled={
                          disableTracking || !addMaterialSourcePerm?.can
                        }>
                        W_B
                      </Button>
                    </>
                  )}
                />
              </Table>
            </>
          ),
        }}>
        <Table.Column dataIndex={['event']} title="Event" />
      </Table>
      {isCreating && packageId && (
        <>
          <MaterialSourceModalForm
            packageId={packageId}
            setIsCreating={setIsCreating}
            refetchSources={refetch}
            pendingMaterial={pendingMaterial}
            materialAvailablity={materialAvailability}
          />
        </>
      )}
    </div>
  )
}

export default PendingTable
