import { useCreateMany } from '@refinedev/core'
import { Button, Col, Input, Row, Space, Typography } from 'antd'
import { PriceField } from 'components/field'
import { GridSelect } from 'components/input/GridSelect'
import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FLORALVALUE_URL, FLOWER_URL, FOLIAGE_TYPE_URL } from 'urls'
import { checkNumber } from 'utils/common'
import { PricingContext } from './index'
import { Component, IFloralValue, Info } from './pricingToolTree'
import { ShowItem } from './showItem'

export const ItemInfoCanvas = () => {
  const { item, setItem, itemPrice, setPrice } = useContext(PricingContext)
  const [quantity, setQuantity] = useState<number>(
    item && (item.info as Info)?.quantity ? (item.info as Info)?.quantity : 0,
  )

  const [isFloral, setIsFloral] = useState(false)
  const [flowerSplit, setFlowerSplit] = useState(0)
  const [foliageSplit, setFoliageSplit] = useState(0)
  const [openFlowerGrid, setOpenFlowerGrid] = useState<boolean>(false)

  useEffect(() => {
    setQuantity(
      item && (item.info as Info)?.quantity ? (item.info as Info)?.quantity : 0,
    )
  }, [item])

  useEffect(() => {
    if (item?.is_new && item.parent?.name === 'FLORAL') {
      setOpenFlowerGrid(true)
      item.is_new = false
    }
  }, [item])

  const setItemQuantity = debounce(() => {
    item?.update()
  }, 1000)

  const handleQuantity = useCallback(
    (value) => {
      if (item && item.info) {
        item.info.quantity = value
      }
      setQuantity(value)
      setPrice()
      setItemQuantity()
    },
    [item],
  )

  useEffect(() => {
    setIsFloral(false)
    if (item?.parent?.name === 'FLORAL') {
      setIsFloral(true)
      setFlowerSplit(
        (item.info?.values as IFloralValue[])
          .filter((floralItem) => !!floralItem.flower)
          .reduce((partial, flower) => partial + checkNumber(flower.split), 0),
      )
      setFoliageSplit(
        (item.info?.values as IFloralValue[])
          .filter((floralItem) => !!floralItem.foliage)
          .reduce(
            (partial, foliage) => partial + checkNumber(foliage.split),
            0,
          ),
      )
    }
  }, [JSON.stringify(item?.info?.values)])

  return (
    <div>
      <Row className="canvas-top">
        <Col span={8} className="item-image-name">
          {item && <ShowItem item={item} />}
          <Typography.Text strong>{item?.info?.name}</Typography.Text>
        </Col>
        <Col span={8} className="quantity-price-section">
          <span>
            <Typography.Text strong>Item Total:</Typography.Text>{' '}
            <PriceField value={itemPrice} />
          </span>
          <Space direction="vertical" align="center">
            Quantity
            <Input
              type="number"
              value={quantity}
              onChange={(event) => {
                handleQuantity(Number(event.target.value))
              }}
            />
          </Space>
        </Col>
        <Col span={8}>
          {isFloral && (
            <Space direction="vertical" align="center" size="large">
              <SplitInfo
                flowerSplit={flowerSplit}
                foliageSplit={foliageSplit}
              />
              <FloralCreateButtons
                item={item}
                setItem={setItem}
                setOpenFlowerGrid={setOpenFlowerGrid}
                openFlowerGrid={openFlowerGrid}
              />
            </Space>
          )}
        </Col>
      </Row>
    </div>
  )
}

function FloralCreateButtons({
  item,
  setItem,
  openFlowerGrid,
  setOpenFlowerGrid,
}: {
  item?: Component
  setItem: CallableFunction
  openFlowerGrid: boolean
  setOpenFlowerGrid: any
}) {
  const { mutate: createMany } = useCreateMany()

  const createFloral = (floralItems: IFloralValue[]) => {
    createMany(
      {
        resource: FLORALVALUE_URL,
        values: floralItems.map((floralItem) => ({
          flower: floralItem.flower,
          foliage: floralItem.foliage,
          breakdown_value: item?.id,
        })),
      },
      {
        onSuccess: (data) => {
          data.data.forEach((itemData, index) => {
            floralItems[index].id = itemData!.id as number
          })
        },
      },
    )
  }

  return (
    <Space>
      <GridSelect
        onModalCancel={() => {
          setOpenFlowerGrid(false)
        }}
        openModal={openFlowerGrid}
        useTableProps={{
          resource: FLOWER_URL,
          filters: {
            permanent: [
              {
                field: 'item_id',
                operator: 'eq',
                value: item?.info?.item,
              },
            ],
          },
        }}
        multi
        getName={(item) => `${item?.color.name} ${item?.flower_type.name}`}
        onOk={(selectedItems: any[]) => {
          const forCreate = selectedItems.map((selectedItem, index) => {
            let tempItem = item?.clone()
            const value: IFloralValue = {
              id: Math.random(),
              artificial_ratio: 0,
              split: 100,
              combination: 1,
              price: 0,
              image: selectedItem.image,
              flower: selectedItem.id,
            }
            tempItem.addValue(value)
            setItem(tempItem)
            return value
          })
          setOpenFlowerGrid(false)
          createFloral(forCreate)
        }}
        title="Flowers"
        renderTrigger={(show) => (
          <Button type="primary" ghost onClick={() => show()}>
            Add Flower
          </Button>
        )}
      />
      <GridSelect
        useTableProps={{
          resource: FOLIAGE_TYPE_URL,
          filters: {
            permanent: [
              {
                field: 'item_id',
                operator: 'eq',
                value: item?.info?.item,
              },
            ],
          },
        }}
        multi
        onOk={(selectedItems: any[]) => {
          const forCreate = selectedItems.map((selectedItem, index) => {
            let tempItem = item?.clone()
            const value: IFloralValue = {
              id: Math.random(),
              artificial_ratio: 0,
              split: 100,
              combination: 1,
              price: 0,
              image: selectedItem.image,
              foliage: selectedItem.id,
            }
            tempItem.addValue(value)
            setItem(tempItem)
            return value
          })
          createFloral(forCreate)
        }}
        title="Foliages"
        renderTrigger={(show) => (
          <Button type="primary" ghost onClick={() => show()} color="primary">
            Add Foliage
          </Button>
        )}
      />
    </Space>
  )
}

const SplitInfo = ({
  flowerSplit,
  foliageSplit,
}: {
  flowerSplit: number
  foliageSplit: number
}) => {
  return (
    <>
      <div>Flower: {flowerSplit ?? 0}%</div>
      <div>Foliage: {foliageSplit ?? 0}%</div>
      <div>
        <Typography.Text strong>Total Split: </Typography.Text>
        <Typography.Text
          style={{
            color: flowerSplit + foliageSplit !== 100 ? 'red' : '#007FFF',
          }}>
          {checkNumber(flowerSplit + foliageSplit)}/100
        </Typography.Text>
      </div>
    </>
  )
}
