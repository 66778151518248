import { List, useTable } from '@refinedev/antd'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'
import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IStaff } from 'interfaces/staff'
import { SALES_TARGET_URL, STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'

export const SalesTargetReport = () => {
  const { tableProps, searchFormProps } = useTable<any>({
    resource: `${SALES_TARGET_URL}`,

    onSearch: (data: any) => [
      {
        field: 'relationship_manager',
        operator: 'eq',
        value: data.relationship_manager
          ? data.relationship_manager.join(',')
          : undefined,
      },
      { field: 'month', operator: 'eq', value: data.month.toISOString() },
      { field: 'venue', operator: 'eq', value: data.venue },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        { field: 'month', operator: 'eq', value: dayjs().toISOString() },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={'Sales Target'}>
            <Divider type="vertical" />
            <Divider />
            <Form {...searchFormProps} initialValues={{ month: dayjs() }}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name="relationship_manager">
                    <SelectWithDefault<IStaff>
                      mode="multiple"
                      useSelectProps={{ resource: STAFF_URL }}
                      placeholder="Select RMs"
                      renderOptions={(items) =>
                        items?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {getUserName(item)}
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="month">
                    <DatePicker
                      style={{ width: '100%' }}
                      format="MMMM, YYYY"
                      picker="month"
                      placeholder="Select month"
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Button onClick={() => searchFormProps.form?.submit()}>
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                bordered
                scroll={{ y: '64vh' }}
                summary={(pageData) => {
                  let totalDecor = 0
                  let totalCatering = 0
                  let totalTarget = 0
                  let totalPhotography = 0
                  let totalOther = 0
                  let totalPlanning = 0
                  let totalBgmv = 0
                  let totalBookings = 0

                  pageData.forEach(
                    ({
                      is_rm,
                      bgmv,
                      Decor,
                      End_To_End_Planning,
                      Other,
                      Catering,
                      Photography,
                      target,
                      bookings,
                    }) => {
                      totalBgmv += is_rm && bgmv ? bgmv : 0
                      totalTarget += is_rm && target ? target : 0
                      totalDecor += Decor ?? 0
                      totalCatering += Catering ?? 0
                      totalPhotography += Photography ?? 0
                      totalOther += Other ?? 0
                      totalPlanning += End_To_End_Planning ?? 0
                      totalBookings += is_rm && bookings ? bookings : 0
                    },
                  )
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <Typography.Title level={4}>Total</Typography.Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {totalBookings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text strong>{totalBgmv}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {totalTarget}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Typography.Text strong></Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>{totalDecor}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          <Typography.Text strong>
                            {totalCatering}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          <Typography.Text strong>
                            {totalPhotography}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                          <Typography.Text strong>{totalOther}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>
                          <Typography.Text strong>
                            {totalPlanning}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}>
                <Table.Column
                  title="Relationship Manager/Consultant"
                  fixed
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {record.is_rm ? (
                        <Typography.Text strong>
                          {record.first_name} {record.last_name}
                        </Typography.Text>
                      ) : (
                        <Typography.Text>
                          {record.first_name} {record.last_name}
                        </Typography.Text>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="bookings"
                  title="Bookings"
                  fixed
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {record.is_rm ? (
                        <Typography.Text strong>
                          {record.bookings}
                        </Typography.Text>
                      ) : (
                        <Typography.Text>{record.bookings}</Typography.Text>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="bgmv"
                  title="GMV"
                  fixed
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {record.is_rm ? (
                        <Typography.Text strong>
                          {record.bgmv.toFixed(2)}
                        </Typography.Text>
                      ) : (
                        <Typography.Text>
                          {record.bgmv.toFixed(2)}
                        </Typography.Text>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="target"
                  title="Target"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {record.is_rm && (
                        <Typography.Text strong>
                          {record.target}
                        </Typography.Text>
                      )}
                    </>
                  )}
                />
                <Table.Column
                  title="Target %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {record.is_rm &&
                        (
                          ((record.bgmv ?? 0) / (record.target ?? 1)) *
                          100
                        ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="Decor"
                  title="Decor GMV"
                  width={100}
                  render={(_, record: any) => (
                    <>{record.Decor && record.Decor.toFixed(2)}</>
                  )}
                />
                <Table.Column
                  dataIndex="Catering"
                  title="Catering GMV"
                  width={100}
                  render={(_, record: any) => (
                    <>{record.Catering && record.Catering.toFixed(2)}</>
                  )}
                />
                <Table.Column
                  dataIndex="Photography"
                  title="Photography GMV"
                  width={100}
                  render={(_, record: any) => (
                    <>{record.Photography && record.Photography.toFixed(2)}</>
                  )}
                />
                <Table.Column
                  dataIndex="Other"
                  title="Other GMV"
                  width={100}
                  render={(_, record: any) => (
                    <>{record.Other && record.Other.toFixed(2)}</>
                  )}
                />
                <Table.Column
                  dataIndex="End_To_End_Planning"
                  title="Planning GMV"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {record.End_To_End_Planning &&
                        record.End_To_End_Planning.toFixed(2)}
                    </>
                  )}
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
