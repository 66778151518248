import { Create, useForm } from "@refinedev/antd";
import { LeadForm } from './Form'

export const LeadCreate = () => {
  const useFormProps = useForm()
  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <LeadForm formProps={useFormProps.formProps} />
    </Create>
  )
}
