import { DeleteButton, useModalForm } from '@refinedev/antd'
import { BaseKey, useInvalidate } from '@refinedev/core'
import { Button, List, Modal, Skeleton, Typography } from 'antd'
import {
  ISurcharge,
  SurchargeApplyTo,
  SurchargeType,
} from 'interfaces/catering'
import React, { useEffect, useState } from 'react'
import { SURCHARGE_URL } from 'urls'
import { SurchargeForm } from '../surcharge/Form'
import { PriceField } from 'components/field'
import { EditOutlined } from '@ant-design/icons'
const { Text } = Typography

const SurchargeItem: React.FC<{
  menuId?: BaseKey
  surchargeItem: ISurcharge
  refetch: any
  apply_to: SurchargeApplyTo
  packageId?: BaseKey
  isEditable: boolean
}> = ({ menuId, surchargeItem, refetch, apply_to, packageId, isEditable }) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: SURCHARGE_URL,
    action: 'edit',
    id: surchargeItem.id,
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      invalidate({
        resource: 'package_tool/shortlist_packages',
        invalidates: ['detail'],
        id: `${packageId}/?expand=service_package,service_package.service`,
      })
    },
  })

  return (
    <div>
      <Modal {...modalProps}>
        <SurchargeForm formProps={formProps} menuId={menuId} />
      </Modal>
      <List.Item
        actions={ isEditable ? [
          <DeleteButton
            key="delete"
            resource={SURCHARGE_URL}
            recordItemId={surchargeItem.id}
            hideText
            size="small"
            type="text"
            onSuccess={() => {
              refetch()
              invalidate({
                resource: 'package_tool/shortlist_packages',
                invalidates: ['all'],
              })
            }}
          />,
          <Button
            key="edit"
            onClick={() => {
              show(surchargeItem.id)
            }}
            type="text"
            size="small"
            icon={<EditOutlined />}
          />,
        ] : undefined}>
        <List.Item.Meta
          title={`${surchargeItem.name} (${surchargeItem.surcharge_type})`}
        />
        {surchargeItem.surcharge_type === SurchargeType.ABSOLUTE ? (
          <PriceField value={surchargeItem.value} />
        ) : (
          <div>{`${surchargeItem.value} %`}</div>
        )}
      </List.Item>
    </div>
  )
}

const AddSurcharge: React.FC<{
  menuId?: BaseKey
  surcharges: ISurcharge[]
  refetch: any
  apply_to: SurchargeApplyTo
  packageId?: BaseKey
  isEditable: boolean
}> = ({ menuId, surcharges, refetch, apply_to, packageId, isEditable }) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: SURCHARGE_URL,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      refetch()
      invalidate({
        resource: 'package_tool/shortlist_packages',
        invalidates: ['detail'],
        id: `${packageId}/?expand=service_package,service_package.service`,
      })
    },
  })
  formProps.initialValues = { apply_to: apply_to }

  return (
    <div>
      <Modal {...modalProps}>
        <SurchargeForm formProps={formProps} menuId={menuId} />
      </Modal>
      <List
        itemLayout="horizontal"
        dataSource={surcharges}
        renderItem={(item) => (
          <SurchargeItem
            menuId={menuId}
            surchargeItem={item}
            refetch={refetch}
            apply_to={apply_to}
            packageId={packageId}
            isEditable={isEditable}
          />
        )}
      />
      { isEditable && <Button onClick={() => show()} block type="dashed">
        Add Surcharge
      </Button>}
    </div>
  )
}

const ShowSurcharges: React.FC<{
  menuId?: BaseKey
  sessionId?: BaseKey
  surcharges: ISurcharge[]
  pax: number
  refetch: any
  menuTotal: number
  menuSurchargeTotal: number
  overallSurchargeTotal: number
  packageId?: BaseKey
  isEditable: boolean
}> = ({
  menuId,
  surcharges,
  refetch,
  menuTotal,
  pax,
  sessionId,
  packageId,
  menuSurchargeTotal,
  overallSurchargeTotal,
  isEditable
}) => {
  const [menuSurcharges, setMenuSurcharges] = useState<ISurcharge[]>([])
  const [overAllSurcharges, setOverAllSurcharges] = useState<ISurcharge[]>([])
  useEffect(() => {
    const menuTotalSurcharges = surcharges.filter(
      (surcharge) => surcharge.apply_to === SurchargeApplyTo.MENU_TOTAL,
    )
    const menuTotalPrice = menuTotalSurcharges.reduce(
      (total, surcharge) => total + surcharge.value,
      0,
    )
    const overallSurcharges = surcharges.filter(
      (surcharge) => surcharge.apply_to === SurchargeApplyTo.OVERALL_TOTAL,
    )
    setMenuSurcharges(menuTotalSurcharges)
    setOverAllSurcharges(overallSurcharges)
  }, [surcharges])

  return (
    <div>
      {menuSurcharges 
      && (
        <AddSurcharge
          menuId={menuId}
          surcharges={menuSurcharges}
          refetch={refetch}
          apply_to={SurchargeApplyTo.MENU_TOTAL}
          packageId={packageId}
          isEditable={isEditable}
        />
      )
      }
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text strong>{'Menu Total + Surcharges'}</Text>
        <Text strong>
          <PriceField value={menuSurchargeTotal} />
        </Text>
      </div>

      {sessionId && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text strong>{`(Menu Total + Surcharges) * ${pax}`}</Text>
            <Text strong>
              <PriceField value={menuSurchargeTotal * pax} />
            </Text>
          </div>
          <AddSurcharge
            menuId={menuId}
            surcharges={overAllSurcharges}
            refetch={refetch}
            apply_to={SurchargeApplyTo.OVERALL_TOTAL}
            packageId={packageId}
            isEditable={isEditable}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text strong>{'Overal Surcharge Total'}</Text>
            <Text strong>
              <PriceField value={overallSurchargeTotal} />
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShowSurcharges
