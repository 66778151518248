import React, { useEffect, useState } from 'react'
import {
  useGetIdentity,
  useTable,
  useModal as useRefineModal,
  Authenticated,
} from '@refinedev/core'
import { Layout as AntdLayout, Result, Spin, Divider, Typography } from 'antd'
import { ImageField } from '@refinedev/antd'
import update from 'immutability-helper'
import { IProductShowcase } from 'interfaces/product'
import { IStaff } from 'interfaces/staff'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { useNavigate } from 'react-router-dom'
import { EXTERNAL_USER_KEY } from 'authProvider'
import { VENUE_URL } from 'urls'
import { IVenue } from 'interfaces/venue'
import { VenueFilter } from 'components/filters/VenueFilter'
import './styles.less' // Ensure correct path
import { analytics } from 'services/firebase'
import { logEvent } from 'firebase/analytics'
import { SplitPane } from 'react-collapse-pane'

const { Title } = Typography

const HIDE_PRICE_KEY = 'hidePrice'
const isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

export const ImageCarousel = ({
  image,
  width,
  height,
  style,
  className,
}: {
  image: string
  width?: number
  height?: number
  style?: any
  className?: string
}) => {
  const [visible, setVisible] = useState(false)

  if (image)
    return (
      <>
        <div className="image-container">
          <ImageField
            value={image}
            preview={false}
            width={width}
            height={height}
            wrapperStyle={style}
            style={{ borderRadius: '25px' }}
            className={className}
            onClick={() => {
              setVisible(true)
              logEvent(analytics, 'image_click', { image })
            }}
          />
        </div>
      </>
    )
  return (
    <ImageField
      value={'/product_placeholder.png'}
      preview={false}
      style={{ borderRadius: '25px' }}
    />
  )
}

const VenueCard: React.FC<{ item: IVenue }> = ({ item }) => {
  const { show } = useRefineModal()
  const navigate = useNavigate()

  return (
    <div className="showcase-card">
      <div className="card-cover">
        <ImageCarousel image={item.logo} height={250} width={250} />
      </div>
      <div
        className="card-content"
        onClick={() => {
          show()
          logEvent(analytics, 'card_click', { venue: item.name })
        }}>
        <div className="card-title-container">
          <Title
            level={4}
            onClick={() => {
              navigate(`/cms/venue-showcase/detail/${item.id}`)
              logEvent(analytics, 'view_details', { venue: item.name })
            }}
            style={{ cursor: 'pointer' }}>
            {item.name}
          </Title>
        </div>
      </div>
    </div>
  )
}

const CardList: React.FC = () => {
  const [venueList, setVenueList] = useState<IVenue[]>([])
  const { data: user } = useGetIdentity<IStaff>()

  const {
    tableQueryResult: queryResult,
    current,
    setCurrent,
    setFilters,
    setSorters,
    pageSize,
  } = useTable<IProductShowcase>({
    resource: VENUE_URL,
    queryOptions: {
      enabled: !!user,
      onSuccess: (data: any) => {
        setVenueList(update(venueList, { $push: data.data ?? [] }))
      },
    },
    filters: {
      permanent: [
        {
          field: 'is_published',
          operator: 'eq',
          value: true,
        },
      ],
    },
    pagination: {
      pageSize: 16,
    },
  })

  useEffect(() => {
    setFilters([])
    setVenueList([])
    setCurrent(1)
    logEvent(analytics, 'page_load')
  }, [setCurrent])

  const onFinish = () => {
    setVenueList([])
    logEvent(analytics, 'filters_applied')
  }

  return (
    <AntdLayout className="showcase">
      <AntdLayout.Header className="showcase-header">
        {isExternal === 'true' ? (
          <Typography.Title level={1}>Catalog</Typography.Title>
        ) : (
          <a href="/">
            <img src={'/meragi-logo.svg'} alt="Meragi" />
          </a>
        )}
      </AntdLayout.Header>

      <AntdLayout.Content className="showcase-content">
        <SplitPane
          split="vertical"
          collapse={{ buttonTransition: 'none', collapsedSize: 10 }}
          initialSizes={[20, 80]}>
          <div className="scroll sider">
            <VenueFilter
              setFilters={setFilters}
              setSorter={setSorters}
              setCurrent={setCurrent}
              onFinish={onFinish}
              onUndo={onFinish}
            />
          </div>

          <div
            className="scroll-content-card-list scroll"
            id="infinite-scroll-content-card-list">
            <InfiniteScroll
              hasMore={current * pageSize < (queryResult.data?.total ?? 0)}
              next={() => setCurrent(current + 1)}
              dataLength={venueList.length}
              scrollThreshold={0.7}
              scrollableTarget="infinite-scroll-content-card-list"
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
              }}
              loader={
                <div className="row h-center">
                  <Spin />
                </div>
              }
              endMessage={
                queryResult.data?.total ? (
                  <Divider plain>You have reached the end 🤐</Divider>
                ) : queryResult.isFetched ? (
                  <Result
                    status="404"
                    title="Oops!"
                    subTitle="We could not find any products with these combinations"
                  />
                ) : (
                  <div className="row h-center">
                    <Spin />
                  </div>
                )
              }>
              <Masonry
                breakpointCols={{
                  default: 4,
                  1100: 3,
                  700: 2,
                  500: 1,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {venueList?.map((venue) => (
                  <VenueCard key={venue.id} item={venue} />
                ))}
              </Masonry>
            </InfiniteScroll>
          </div>
        </SplitPane>
      </AntdLayout.Content>
    </AntdLayout>
  )
}

export const VenueCardList: React.FC = () => {
  return (
    <Authenticated>
      <CardList />
    </Authenticated>
  )
}
