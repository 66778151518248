import { KeyOutlined, UserOutlined } from '@ant-design/icons'
import { useLogin, useRouterContext, useTranslate } from '@refinedev/core'
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from 'antd'
import React from 'react'
// import {
//   containerStyles,
//   imageContainer,
//   layoutStyles,
//   titleStyles,
//   footerStyle,
// } from "./styles";
import './styles.less'

const { Text, Title } = Typography
export interface ILoginForm {
  email: string
  password: string
  remember: boolean
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>()
  const translate = useTranslate()

  const { mutate: login, isLoading } = useLogin<ILoginForm>()

  const { Link } = useRouterContext()

  const CardTitle = (
    <Title level={3} className="titleStyles">
      {translate('pages.login.title', 'Sign in your account')}
    </Title>
  )

  return (
    <Layout className="layoutStyles">
      <Row
        justify="center"
        style={{
          height: '100%',
          width: '340px',
        }}>
        <Col xs={22}>
          <div className="containerStyles">
            <div className="imageContainer">
              <img
                src={'/logo-vector.svg'}
                alt="Refine Logo"
                style={{ width: '140px', height: '140px', marginTop: '20px' }}
              />
            </div>
            <Card
              title={CardTitle}
              style={{ background: '#FEFDFF', border: 0, width: '100%' }}
              headStyle={{ borderBottom: 0, background: '#FEFDFF' }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values)
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}>
                <Form.Item name="email" rules={[{ required: true }]}>
                  <Input
                    size="large"
                    prefix={<UserOutlined />}
                    placeholder={translate('pages.login.email', 'Email')}
                    style={{
                      borderRadius: 2,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}>
                  <Input
                    type="password"
                    placeholder="Password"
                    size="large"
                    prefix={<KeyOutlined />}
                    style={{
                      borderRadius: 2,
                    }}
                  />
                </Form.Item>

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  style={{
                    background: '#6930C3',
                    border: '#6930C3',
                    borderRadius: 2,
                    marginTop: '12px',
                  }}>
                  {translate('pages.login.signin', 'Sign in')}
                </Button>
                <Link to="/forgot-password">Forgot Password?</Link>
              </Form>
            </Card>
            <div className="footerStyle">
              <Text>Copyright © 2023 Meragi . All Rights Reserved.</Text>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}
