import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateField, Edit, useDrawerForm, useSimpleList } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  ResourceRouterParams,
  useCan,
  useModal,
  useRouterContext,
  useUpdate,
} from '@refinedev/core'
import {
  List as AntList,
  Badge,
  Button,
  Card,
  Descriptions,
  Divider,
  Modal,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd'
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'
import { IChangeTracker, IShortlistPackage } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import { createContext, useContext, useState } from 'react'
import ReactJson from 'react-json-view'
import { CHANGE_TRACKER_URL } from 'urls'
import { getUserName } from 'utils/common'
import { ShortlistPackageDetail } from '.'
import { PackageDrawerForm } from './PackageDrawerForm'
const { Title } = Typography

export type EditPackageContextType = {
  editPackageStatus: boolean
  setEditPackageStatus: (editPackageStatus: boolean) => void
  isFinalized: boolean
}

export const EditPackageContext = createContext<EditPackageContextType | null>(
  null,
)

export const ShortlistPackageEdit: React.FC = () => {
  const { useParams } = useRouterContext()
  const { id: idFromParams } = useParams<ResourceRouterParams>()

  const useFormProps = useForm<IShortlistPackage>({
    id: idFromParams + '/?expand=service_package,service_package.service',
    redirect: false,
  })

  const packageData: IShortlistPackage | undefined =
    useFormProps.queryResult?.data?.data

  const [editPackageStatus, setEditPackageStatus] = useState<boolean>(true)

  const { formProps, drawerProps, show, saveButtonProps, close } =
    useDrawerForm<IShortlistPackage>({
      action: 'edit',
      resource: `package_tool/shortlist_packages`,
      redirect: false,
      id: packageData?.id,
      // warnWhenUnsavedChanges: true,
      onMutationSuccess: () => {
        close()
        useFormProps.queryResult?.refetch()
      },
    })

  const pageHeaderProps = {
    extra: [
      idFromParams && <ChangeTrackerList packageId={idFromParams} />,
      <LogButton key="log" />,
      <Button
        icon={<Icons.ShareAltOutlined />}
        key="Share"
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_WEB_APP_URL}/${useFormProps.id}`,
            '_blank',
          )
        }}>
        Share
      </Button>,
      <Button
        type="primary"
        icon={<Icons.SettingOutlined />}
        key="Setting"
        onClick={() => show(packageData?.id)}>
        Setting
      </Button>,
    ],
  }

  const TitleComponent = () => {
    return (
      <Space direction="horizontal" align="baseline">
        <Title level={5}>Edit Shortlist Package</Title>
        <Tag
          color="orange"
          icon={<FontAwesomeIcon icon={['fas', 'check-double']} />}>
          {packageData?.is_cancelled ? ' Cancelled' : packageData?.is_finalized ? ' Finalized' : ' Draft'}
        </Tag>
        <Tag color="blue" icon={<Icons.ShareAltOutlined />}>
          {packageData?.is_link_active ? 'Active' : 'Not Active'}
        </Tag>
        <Tag color="green" icon={<Icons.MoneyCollectOutlined />}>
          {packageData?.is_individual_price_shown
            ? 'Price is Shown'
            : 'Price is not Shown'}
        </Tag>
      </Space>
    )
  }

  return (
    <EditPackageContext.Provider
      value={{
        editPackageStatus,
        setEditPackageStatus,
        isFinalized: !!packageData?.is_finalized,
      }}>
      <ShortlistPackageContext.Provider
        value={useFormProps.queryResult?.data?.data}>
        <Edit
          title={<TitleComponent />}
          footerButtons={<></>}
          headerProps={pageHeaderProps}>
          {useFormProps.queryResult?.isLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Spin />
            </div>
          ) : (
            <ShortlistPackageDetail useFormProps={useFormProps} />
          )}
        </Edit>
        {packageData && (
          <PackageDrawerForm
            drawerProps={drawerProps}
            saveButtonProps={saveButtonProps}
            formProps={formProps}
            id={packageData?.id}
            customer={packageData.customer}
            initialData={packageData}
          />
        )}
      </ShortlistPackageContext.Provider>
    </EditPackageContext.Provider>
  )
}

const ChangeTrackerList = ({ packageId }: { packageId: string }) => {
  const { listProps, queryResult } = useSimpleList<IChangeTracker>({
    resource: CHANGE_TRACKER_URL,
    filters: {
      permanent: [
        { field: 'package', operator: 'eq', value: packageId },
        { field: 'expand', operator: 'eq', value: 'request_by,approval_by' },
      ],
    },
    sorters: { permanent: [{ field: 'created_at', order: 'desc' }] },
  })

  const _package = useContext(ShortlistPackageContext)

  const { visible, show, close } = useModal()

  const { mutate: approveChange, isLoading: approvalLoading } = useUpdate()

  const { data: approveRequest } = useCan({
    resource: 'approve_change_request',
    action: '',
  })

  const isApprovedOrRejected = (change: IChangeTracker) => {
    if (change.approved) {
      return 'Approved'
    }
    if (change.rejected) {
      return 'Rejected'
    }
  }

  const handleApproval = (change: IChangeTracker, state: string) => {
    approveChange(
      {
        resource: CHANGE_TRACKER_URL,
        id: `${change.id}/approve_or_reject`,
        values: {
          approval: state,
        },
      },
      {
        onSuccess: () => {
          window.location.reload()
        },
      },
    )
  }

  return (
    <>
      {!_package?.is_cancelled && <Badge
        count={
          queryResult.data?.data.filter(
            (item) => !(item.approved || item.rejected),
          ).length
        }>
        <Button onClick={() => show()} icon={<Icons.IssuesCloseOutlined />}>
          Requested Changes
        </Button>
      </Badge>}
      <Modal
        open={visible}
        onCancel={close}
        title={<>Requested Changes ({queryResult.data?.total})</>}>
        <AntList
          {...listProps}
          renderItem={(item) => (
            <Card
              title={
                <>
                  {item.change_type} - {item.product_name}
                </>
              }
              size="small"
              extra={
                !isApprovedOrRejected(item) ? (
                  approveRequest?.can && (
                    <Spin spinning={approvalLoading}>
                      <Space>
                        <Button
                          key="approve"
                          type="primary"
                          onClick={() => handleApproval(item, 'approve')}>
                          Approve
                        </Button>
                        <Button
                          key="reject"
                          type="primary"
                          danger
                          onClick={() => handleApproval(item, 'reject')}>
                          Reject
                        </Button>
                      </Space>
                    </Spin>
                  )
                ) : (
                  <Tag>{isApprovedOrRejected(item)}</Tag>
                )
              }>
              <Space direction="vertical">
                <Descriptions>
                  <Descriptions.Item label="Created at">
                    <DateField value={item.created_at} format="LLL" />
                  </Descriptions.Item>
                  {item.request_by && (
                    <Descriptions.Item label="Requested by">
                      {getUserName(item.request_by as IStaff, false)}
                    </Descriptions.Item>
                  )}
                  {item.approval_at && (
                    <Descriptions.Item label="Approved at">
                      <DateField value={item.approval_at} format="LLL" />
                    </Descriptions.Item>
                  )}
                  {item.approval_by && (
                    <Descriptions.Item label="Approval by">
                      {getUserName(item.approval_by as IStaff, false)}
                    </Descriptions.Item>
                  )}
                </Descriptions>

                <Space>
                  <Typography.Text type="secondary">
                    Description:
                  </Typography.Text>
                  {item.description}
                </Space>
              </Space>
              {item.change && (
                <>
                  <Divider />
                  <ReactJson src={item.change} collapsed />
                </>
              )}
            </Card>
          )}
        />
      </Modal>
    </>
  )
}
