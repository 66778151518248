import { Form, Input, InputNumber } from "antd";
import { file2Base64 } from "@refinedev/core";
import { SingleImageUpload } from 'components/input'
import { UseFormReturnType } from 'interfaces/refine'
import { IItem } from 'interfaces/smartPhoto'
import 'react-quill/dist/quill.snow.css'

export const ItemForm: React.FC<{
  useFormProps: UseFormReturnType<IItem>
}> = ({ useFormProps }) => {
  const { formProps, queryResult } = useFormProps

  const onFinish = async (values: any) => {
    let imageString = ''
    const { image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.image

    if (image) {
      const file = image[image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        imageString = base64String
      }
    }

    const finalResponse = { ...values }
    if (imageString.length) finalResponse['image'] = imageString
    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <SingleImageUpload
        label="Image"
        name="image"
        buttonText="Image"
        initialImageUrl={queryResult?.data?.data?.image}
      />
      <Form.Item label="Price" name="price" rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  )
}
