import { Form, FormProps, Input, Transfer } from "antd";
import { useTransfer } from 'components/hooks'
import { BREAKDOWN_URL } from 'urls'

export const InterfaceForm: React.FC<{
  formProps: FormProps
  queryResult: any
}> = ({ formProps, queryResult }) => {
  const { transferProps } = useTransfer({ resource: BREAKDOWN_URL })

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item
        label="Breakdowns"
        name="breakdowns"
        valuePropName="targetKeys">
        <Transfer
          {...transferProps}
          showSearch
          listStyle={{ width: '50%', height: 400 }}
        />
      </Form.Item>
    </Form>
  )
}
