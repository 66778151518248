import {
  BaseKey,
  HttpError,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useModal,
} from '@refinedev/core'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Spin,
  Switch,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IMaterial } from 'interfaces/material'
import { IMaterialToBuy } from 'interfaces/quote'
import { MATERIAL_URL, RFQ_URL } from 'urls'

export const BuyMaterialModal = ({
  packageId,
  refetchSources,
  isDisabled,
}: {
  packageId: BaseKey
  refetchSources: any
  isDisabled: boolean
}) => {
  const { show, close, visible } = useModal()
  const { mutate } = useCustomMutation<any>()
  const apiUrl = useApiUrl()

  const [form] = Form.useForm()
  const invalidate = useInvalidate()
  const { data: materials_to_buy, isFetching } = useList<
    IMaterialToBuy,
    HttpError
  >({
    resource: `${RFQ_URL}/${packageId}/get_material_list_to_buy`,
    pagination: {
      mode: 'off',
    },
    queryOptions: {
      enabled: visible,
    },
  })

  return (
    <>
      <Button onClick={show} disabled={isDisabled}>
        Buy
      </Button>
      <Modal
        onCancel={() => {
          form.resetFields()
          close()
        }}
        visible={visible}
        title="Material to Buy"
        // okButtonProps={{ loading: isLoading, disabled: !addSalesTarget?.can }}
        onOk={() => {
          form.submit()
          close()
        }}>
        {isFetching ? (
          <div className="row h-center v-center">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            preserve={false}
            layout="vertical"
            onFinish={(values) => {
              mutate(
                {
                  url: `${apiUrl}/package_tool/purchase_package_materials/save_qty_to_purchase/?package_id=${packageId}`,
                  values: values['material'],
                  method: 'post',
                },
                {
                  onSuccess: (data) => {
                    // refetch()
                    refetchSources()
                    invalidate({
                      resource: `${RFQ_URL}/${packageId}/get_pending_table`,
                      invalidates: ['list'],
                    })
                  },
                },
              )
            }}>
            <Form.List
              name="material"
              initialValue={
                materials_to_buy?.data.length
                  ? materials_to_buy?.data.map((target) => ({
                      ...target,
                    }))
                  : [{}]
              }>
              {(fields) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key} gutter={10}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="material"
                          name={[field.name, 'material']}
                          rules={[{ required: true }]}>
                          <SelectWithDefault<IMaterial>
                            disabled={true}
                            useSelectProps={{
                              resource: MATERIAL_URL,
                              optionLabel: 'name',
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          label="UOM"
                          name={[field.name, 'uom']}>
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          label="Qty Remaining"
                          name={[field.name, 'qty_remaining']}>
                          <InputNumber disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          label="For Warehouse"
                          name={[field.name, 'for_warehouse']}>
                          <Switch />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          {...field}
                          label="Qty to purchase"
                          name={[field.name, 'qty_to_purchase']}>
                          <InputNumber />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        )}
      </Modal>
    </>
  )
}
