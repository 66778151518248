import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreateButton, List, useTable } from "@refinedev/antd";
import { Button, Table, Tooltip } from "antd";

import {
  Authenticated,
  LayoutWrapper,
  useDeleteMany,
  useNavigation,
  useRouterContext,
} from "@refinedev/core";

import { DeleteButton } from 'components/button'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { ISmartPhoto } from 'interfaces/smartPhoto'
import { useState } from 'react'
import { cleanPathName } from 'utils/common'

export const SmartPhotoList: React.FC = () => {
  const { push } = useNavigation()
  const { useLocation, useParams } = useRouterContext()
  const location = useLocation()
  const { venue_id } = useParams<any>()

  const tProps = useTable<ISmartPhoto>({
    resource: cleanPathName(location.pathname)
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: cleanPathName(location.pathname),
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    breadcrumb: (
      <NestedBreadcrumb
        baseUrl="/package_tool"
        routes={[{ name: 'venues', id: venue_id }, { name: 'smart_photos' }]}
      />
    ),
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton
        key="create"
        resource={cleanPathName(location.pathname)}
      />,
    ],
  }

  return (
    <Authenticated>
      <LayoutWrapper>
        <List
          title="Smart Photos"
          headerProps={pageHeaderProps}
          resource={cleanPathName(location.pathname)}>
          <Table
            {...tProps.tableProps}
            rowKey="id"
            rowSelection={rowSelection}
            onRow={(record) => ({
              onClick: (event) => push(`edit/${record.id}`),
            })}>
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column<ISmartPhoto>
              dataIndex="id"
              title="Actions"
              render={(value, record) => (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Layers">
                    <Button
                      icon={<FontAwesomeIcon icon={['fas', 'layer-group']} />}
                      size="small"
                      onClick={() =>
                        window.open(
                          `/package_tool/venues/${record.venue}/smart_photos/${value}/layers`,
                        )
                      }
                    />
                  </Tooltip>
                </span>
              )}
            />
          </Table>
        </List>
      </LayoutWrapper>
    </Authenticated>
  );
}
