import { DateField } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Divider,
  Modal,
  Space,
  Tabs,
  Timeline,
  Typography,
} from 'antd'
import {
  BaseKey,
  useDelete,
  useModal,
  useOne,
  useUpdate,
} from '@refinedev/core'
import { DatePicker } from 'components/input'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IProgressTracker } from 'interfaces/crm'
import { useState } from 'react'
import { LEAD_URL, PROGRESS_TRACKER_URL } from 'urls'

function TimelineItem({
  progress: progressFromProps,
  refetch,
}: {
  progress: IProgressTracker
  refetch: CallableFunction
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [progress, setProgress] = useState(progressFromProps)
  const [date, setDate] = useState(dayjs(progressFromProps.date))

  const { mutate: patch } = useUpdate<IProgressTracker>()
  const { mutate: destroy } = useDelete()

  const handleSave = () => {
    patch(
      {
        resource: PROGRESS_TRACKER_URL,
        id: progress.id,
        values: { date },
      },
      {
        onSuccess: (data) => {
          setIsEditing(false)
          setProgress(data.data)
        },
      },
    )
  }
  console.log('progress', progress)
  return (
    <Card
      size="small"
      extra={
        <Space>
          {isEditing && (
            <Button
              key="save"
              icon={<Icons.SaveOutlined />}
              size="small"
              onClick={() => handleSave()}
            />
          )}
          <Button
            key="edit"
            icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
            size="small"
            onClick={() => setIsEditing(!isEditing)}
          />
          <Button
            key="remove"
            icon={<Icons.DeleteOutlined />}
            size="small"
            danger
            onClick={() =>
              destroy(
                { resource: PROGRESS_TRACKER_URL, id: progress.id },
                { onSuccess: () => refetch() },
              )
            }
          />
        </Space>
      }>
      <Typography.Text strong>{progress.change}</Typography.Text>
      <Divider />
      <Card.Meta
        title={
          isEditing ? (
            <DatePicker showTime value={date} onChange={setDate} />
          ) : (
            <p>
              At: <DateField value={progress.date} format="LLL" />
            </p>
          )
        }
        description={
          <p>
            Created at: <DateField value={progress.created_at} format="LLL" />
            {!!progress.reason && (
              <>
                <br />
                <Typography.Text>Reason: {progress.reason}</Typography.Text>
              </>
            )}
          </p>
        }
      />
    </Card>
  )
}

export const InfoModal = ({ leadId }: { leadId?: BaseKey }) => {
  const { visible, show, close } = useModal()

  const { data: stages, refetch: refetchStage } = useOne<IProgressTracker[]>({
    resource: LEAD_URL,
    id: `${leadId}/get_stage_changes`,
    queryOptions: {
      enabled: visible && !!leadId,
    },
  })

  const { data: statuses, refetch: refetchStatus } = useOne<IProgressTracker[]>(
    {
      resource: LEAD_URL,
      id: `${leadId}/get_status_changes`,
      queryOptions: {
        enabled: visible && !!leadId,
      },
    },
  )

  return (
    <>
      <Button icon={<Icons.InfoCircleOutlined />} size="small" onClick={show}>
        Info
      </Button>
      <Modal visible={visible} onCancel={close} footer={null} destroyOnClose>
        <Tabs>
          <Tabs.TabPane tab="Stages" key="stage">
            <Timeline
              mode="left"
              items={stages?.data.map((progress) => {
                return {
                  children: (
                    <TimelineItem
                      key={progress.id}
                      progress={progress}
                      refetch={refetchStage}
                    />
                  ),
                }
              })}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Status" key="status">
            <Timeline
              mode="left"
              items={statuses?.data.map((progress) => ({
                children: (
                  <TimelineItem
                    key={progress.id}
                    progress={progress}
                    refetch={refetchStatus}
                  />
                ),
              }))}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  )
}
