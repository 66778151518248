import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { ICelebration } from 'interfaces/celebration'
import { CelebrationForm } from './Form'

export const CelebrationCreate: React.FC = () => {
  const useFormProps = useForm<ICelebration>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <CelebrationForm useFormProps={useFormProps} />
    </Create>
  )
}
