import { useForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
  List as AntdList,
  Button,
  Card,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Tabs,
} from "antd";

import { BaseKey, file2Base64, useDelete, useList } from "@refinedev/core";
import { SingleImageUpload } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { FABRIC, FLORAL, STRUCTURE } from 'consts'
import { IPricingItem, IPricingItemMaterialExpanded } from 'interfaces/pricing'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import { MATERIAL_URL, PRICING_ITEM_MATERIAL_URL } from 'urls'

function MaterialForm({
  formProps,
  itemId,
}: {
  formProps: FormProps
  itemId: BaseKey
}) {
  return (
    <Form
      {...formProps}
      layout="vertical"
      size="small"
      onFinish={(values) => formProps.onFinish?.({ ...values, item: itemId })}>
      <Form.Item name="material" label="Material">
        <SelectWithDefault
          useSelectProps={{
            resource: MATERIAL_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Space>
        <Form.Item name="qty" label="QTY">
          <InputNumber />
        </Form.Item>
        <Form.Item name="uom" label="UOM">
          <Input />
        </Form.Item>
      </Space>
      <Form.Item
        name="is_independent"
        label="Is Independent"
        valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  )
}

export const MaterialAdd = ({ item }: { item: IPricingItem }) => {
  const { form, formProps, saveButtonProps } = useForm({
    resource: PRICING_ITEM_MATERIAL_URL,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      form.resetFields()
    },
  })

  return (
    <Card
      style={{ width: '25%' }}
      title="Add more"
      size="small"
      actions={[
        <Button {...saveButtonProps} type="primary" key="submit">
          Add
        </Button>,
      ]}>
      <MaterialForm formProps={formProps} itemId={item.id}></MaterialForm>
    </Card>
  )
}

const MaterialShow = ({
  material,
}: {
  material: IPricingItemMaterialExpanded
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const { formProps, saveButtonProps } = useForm({
    resource: PRICING_ITEM_MATERIAL_URL,
    id: material.id,
    action: 'edit',
    redirect: false,
    onMutationSuccess: () => {
      setIsEditing(false)
    },
  })

  const { mutate: destroy, isLoading: destroyLoading } = useDelete()

  return (
    <Card
      style={{
        width: '100%',
      }}
      size="small"
      extra={
        <Space>
          {isEditing && (
            <Button
              {...saveButtonProps}
              type="text"
              size="small"
              key="save"
              icon={<Icons.SaveOutlined />}
            />
          )}
          <Button
            type="text"
            size="small"
            key="edit"
            icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
            onClick={() => setIsEditing(!isEditing)}
          />
          <Button
            type="text"
            size="small"
            key="delete"
            loading={destroyLoading}
            danger
            icon={<Icons.DeleteOutlined />}
            onClick={() =>
              destroy({
                resource: PRICING_ITEM_MATERIAL_URL,
                id: material.id,
              })
            }
          />
        </Space>
      }>
      {isEditing ? (
        <MaterialForm formProps={formProps} itemId={material.item} />
      ) : (
        <Space direction="vertical">
          {material.material.name}
          <Space>
            <span>
              {material.qty} {material.uom}
            </span>
            {!!material.is_independent && 'Independent'}
          </Space>
        </Space>
      )}
    </Card>
  )
}

export const MaterialList = ({ item }: { item: IPricingItem }) => {
  const { data: materials, isLoading } = useList<IPricingItemMaterialExpanded>({
    resource: PRICING_ITEM_MATERIAL_URL,

    queryOptions: {
      enabled: !!item,
    },

    filters: [
      {
        field: 'item',
        operator: 'eq',
        value: item?.id,
      },
      {
        field: 'expand',
        operator: 'eq',
        value: 'material',
      },
    ]
  })

  return (
    <AntdList<IPricingItemMaterialExpanded>
      dataSource={materials?.data}
      rowKey={(material) => material.id}
      footer={!!item && <MaterialAdd item={item} />}
      grid={{ gutter: 16, column: 4 }}
      loading={isLoading}
      renderItem={(material) => (
        <AntdList.Item>
          <MaterialShow material={material} />
        </AntdList.Item>
      )}
    />
  )
}

export const PricingItemForm: React.FC<{
  formProps: FormProps
  queryResult: any
}> = ({ formProps, queryResult }) => {
  const category = Form.useWatch('category', formProps.form)

  const onFinish = async (values: any) => {
    let coverImage = ''
    const { image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.image

    if (image) {
      const file = image[image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        coverImage = base64String
      }
    }

    const finalResponse = { ...values }
    if (coverImage.length) finalResponse['image'] = coverImage

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <Tabs.TabPane tab="Basic" key="basic">
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Select
              options={[
                { label: FLORAL, value: FLORAL },
                { label: FABRIC, value: FABRIC },
                { label: STRUCTURE, value: STRUCTURE },
              ]}
            />
          </Form.Item>
          <Form.Item label="Description" name="help_text" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
          <SingleImageUpload
            label="Image"
            name="image"
            buttonText="Image"
            initialImageUrl={queryResult?.data?.data?.image}
          />
          <Form.Item label="Visible" name="is_visible" valuePropName="checked">
            <Switch defaultChecked />
          </Form.Item>
          <Form.Item
            label="Is Material"
            name="is_material"
            valuePropName="checked">
            <Switch defaultChecked />
          </Form.Item>
          {category === 'FABRIC' && (
            <Form.Item
              label="Fabric Color count"
              name={'fabric_color_count'}
              initialValue={1}>
              <InputNumber placeholder="fabric color count" />
            </Form.Item>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Materials" key="materials">
          <MaterialList item={queryResult?.data?.data} />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
