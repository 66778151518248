import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { ICombination } from 'interfaces/pricing'
import { CombinationForm } from './Form'

export const CombinationCreate: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ICombination>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <CombinationForm formProps={formProps} queryResult={queryResult} />
    </Create>
  )
}
