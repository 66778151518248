import { Form, Input } from "antd";
import { file2Base64 } from "@refinedev/core";
import { SingleImageUpload } from 'components/input'
import { UseFormReturnType } from 'interfaces/refine'
import { ISmartPhoto } from 'interfaces/smartPhoto'
import { useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { setErrors } from 'utils/form'

export const SmartPhotoForm: React.FC<{
  useFormProps: UseFormReturnType<ISmartPhoto>
}> = ({ useFormProps }) => {
  const { formProps, queryResult, mutationResult } = useFormProps

  const onFinish = async (values: any) => {
    let imageString = ''
    const { background_image } = values

    // remove the cover_image key as it holds the s3 url or null
    // we dont want to patch with that
    delete values.background_image

    if (background_image) {
      const file = background_image[background_image?.length - 1]

      if (file.originFileObj) {
        const base64String = await file2Base64(file)
        imageString = base64String
      }
    }

    const finalResponse = { ...values }
    if (imageString.length) finalResponse['background_image'] = imageString
    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  useEffect(() => {
    if (mutationResult.isError) {
      setErrors(mutationResult.error.response.data, formProps.form)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult])

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <SingleImageUpload
        label="Background Image"
        name="background_image"
        buttonText="Image"
        initialImageUrl={queryResult?.data?.data?.background_image}
      />
    </Form>
  )
}
