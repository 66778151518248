import { Table } from "antd";
import { BaseKey, useList, useOne } from "@refinedev/core";
import { PriceField } from 'components/field'
import { IService } from 'interfaces/crm'
import { IVendorSummary } from 'interfaces/quote'
import { RFQ_URL, SERVICE_URL } from 'urls'

export const VendorSummary = ({ packageId }: { packageId?: BaseKey }) => {
  const { data } = useOne<IVendorSummary[]>({
    resource: RFQ_URL,
    id: `${packageId}/get_vendor_summary`,
  })

  const { data: serviceData } = useList<IService>({ resource: SERVICE_URL })

  return (
    <Table
      dataSource={data?.data}
      pagination={false}
      size="small"
      rowKey={(record) => record?.vendor?.id}>
      <Table.Column dataIndex={['vendor', 'id']} title="ID"/>
      <Table.Column dataIndex={['vendor', 'name']} title="Vendor" />
      <Table.Column<IVendorSummary>
        dataIndex="total"
        title="Total"
        ellipsis
        render={(price, record) => (
          <PriceField value={price - (record.meta?.discount ?? 0)} />
        )}
      />
      <Table.Column
        dataIndex="paid"
        title="Paid"
        ellipsis
        render={(price) => <PriceField value={price ?? 0} />}
      />
      {serviceData?.data.map((service) => (
        <Table.Column<IVendorSummary>
          key={service.id}
          title={service.name}
          ellipsis
          render={(_, record) => (
            <PriceField
              value={
                record.services?.find((s) => s.service?.id === service.id)
                  ?.total ?? 0
              }
            />
          )}
        />
      ))}
    </Table>
  )
}
