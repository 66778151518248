import { List, useTable } from '@refinedev/antd'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'
import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IStaff } from 'interfaces/staff'
import { DAILY_LEAD_URL, STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'

export const DailyLead = () => {
  const { tableProps, searchFormProps } = useTable<any>({
    resource: DAILY_LEAD_URL,

    onSearch: (data: any) => [
      {
        field: 'created_at',
        operator: 'between',
        value: data.created_at
          ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
          .join(','),
      },
      { field: 'pre_sales', operator: 'eq', value: data.pre_sales },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        {
          field: 'created_at',
          operator: 'between',
          value: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD'),
          ].join(','),
        },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={'Daily Lead'}>
            <Form {...searchFormProps} initialValues={{ month: dayjs() }}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item name="created_at" label="Created At">
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="pre_sales">
                    <SelectWithDefault<IStaff>
                      useSelectProps={{ resource: STAFF_URL }}
                      size="small"
                      placeholder="Select Pre Sales"
                      renderOptions={(items) =>
                        items?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {getUserName(item, false)}
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button onClick={() => searchFormProps.form?.submit()}>
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                bordered
                scroll={{ y: '64vh' }}
                summary={(pageData) => {
                  let totalCount = 0
                  let totalQualified = 0
                  let totalDisqualified = 0
                  let totalUnprocessed = 0
                  let totalCallAttempts = 0

                  pageData.forEach(
                    ({
                      total,
                      qualified,
                      disqualified,
                      unprocessed,
                      total_call_attempts,
                    }) => {
                      totalCount += total ?? 0
                      totalQualified += qualified ?? 0
                      totalDisqualified += disqualified ?? 0
                      totalUnprocessed += unprocessed ?? 0
                      totalCallAttempts += total_call_attempts ?? 0
                    },
                  )
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          Overall
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text>{totalCount}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text>{totalUnprocessed}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>
                            {((totalUnprocessed / totalCount) * 100).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>{totalCallAttempts}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>
                            {(totalCallAttempts / totalUnprocessed).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text>{totalQualified}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>
                            {((totalQualified / totalCount) * 100).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Typography.Text>{totalDisqualified}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text>
                            {((totalDisqualified / totalCount) * 100).toFixed(
                              2,
                            )}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}>
                <Table.Column dataIndex="day" title="Day" fixed width={50} />
                <Table.Column dataIndex="total" title="Total" width={50} />
                <Table.Column
                  dataIndex="unprocessed"
                  title="Unprocessed"
                  width={50}
                />
                <Table.Column
                  dataIndex="unprocessed"
                  title="Unprocessed %"
                  width={50}
                  render={(unprocessed, record: any) =>
                    (((unprocessed ?? 0) / (record.total ?? 1)) * 100).toFixed(
                      2,
                    )
                  }
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Total Call attempts"
                  width={50}
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Avg Call attempts"
                  width={50}
                  render={(total_call_attempts, record: any) =>
                    (
                      (total_call_attempts ?? 0) / (record.unprocessed ?? 1)
                    ).toFixed(2)
                  }
                />
                <Table.Column
                  dataIndex="qualified"
                  title="Qualified"
                  width={50}
                />
                <Table.Column
                  dataIndex="qualified"
                  title="Qualified %"
                  width={50}
                  render={(qualified, record: any) =>
                    (((qualified ?? 0) / (record.total ?? 1)) * 100).toFixed(2)
                  }
                />
                <Table.Column
                  dataIndex="disqualified"
                  title="Disqualified"
                  width={50}
                />
                <Table.Column
                  dataIndex="disqualified"
                  title="Disqualified %"
                  width={50}
                  render={(disqualified, record: any) =>
                    (((disqualified ?? 0) / (record.total ?? 1)) * 100).toFixed(
                      2,
                    )
                  }
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
