import { ImageField, useSelect } from "@refinedev/antd";
import { Select } from "antd";
import { IProduct } from 'interfaces/product'
import unionWith from 'lodash/unionWith'
import { useEffect } from 'react'
import './styles.css'

export const ProductSelect: React.FC<{
  value?: any
  onChange?: (value: any) => void
  setProduct?: (product?: IProduct) => void
  disabled?: boolean
}> = ({ value, onChange, setProduct, disabled }, ref) => {
  const {
    selectProps: productSelectProps,
    queryResult: productQueryResult,
    defaultValueQueryResult: selectedProductQueryResult,
  } = useSelect<IProduct>({
    resource: `cms/product`,
    optionLabel: 'name',
    optionValue: 'id',

    defaultValueQueryOptions: {
      enabled: !!value,
    },

    defaultValue: [value as any],

    onSearch: (value) => [
      {
        field: 'search',
        operator: 'eq',
        value,
      },
    ],

    pagination: {
      mode: "server"
    }
  })

  const unionProductQueryResult = unionWith(
    productQueryResult.data?.data,
    selectedProductQueryResult.data?.data,
    (left, right) => left.id === right.id,
  )

  useEffect(() => {
    const product = unionProductQueryResult.find(
      (p) => Number(p.id) === (value as any),
    )
    setProduct && setProduct(product)
  }, [value, unionProductQueryResult])

  return (
    <Select
      {...productSelectProps}
      placeholder="Select product"
      style={{
        minWidth: 400,
      }}
      disabled={disabled}
      options={undefined}
      value={value}
      onChange={onChange}>
      {unionProductQueryResult.map((product) => (
        <Select.Option key={product.id} value={product.id} ref={ref}>
          <div className={`select-body ${product.is_deleted ? 'deleted' : ''}`}>
            <ImageField value={product.images[0]?.image} height={75} />
            <p>{product.name}</p>
            <p>₹{product.price}</p>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}
