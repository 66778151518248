import { Edit } from "@refinedev/antd";
import { Spin } from "antd";
import { useForm } from 'components/hooks/useForm'
import { ICustomer } from 'interfaces/customer'
import { CustomerForm } from './Form'

export const CustomerEdit: React.FC = () => {
  const useFormProps = useForm<ICustomer>()

  return (
    <>
      <Edit saveButtonProps={useFormProps.saveButtonProps} canDelete>
        {useFormProps.formLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spin />
          </div>
        ) : (
          <>
            <CustomerForm useFormProps={useFormProps} />
          </>
        )}
      </Edit>
    </>
  )
}
