import { UseFormReturnType } from '@refinedev/antd'
import { Button, Form, Select, Space } from 'antd'
import { BaseKey } from '@refinedev/core'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IContributor } from 'interfaces/customer'
import { IStaff } from 'interfaces/staff'
import { STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'
import { useContext } from 'react'
import { ContributorCreateContext } from './List'

export const ContributorForm: React.FC<{
  useFormProps: UseFormReturnType<IContributor, any, any>
  projectId: BaseKey
}> = ({ useFormProps, projectId }) => {
  const { formProps } = useFormProps
  const { setIsCreating } = useContext(ContributorCreateContext)
  return (
    <Form
      {...formProps}
      layout="inline"
      size="small"
      onFinish={(values) => {
        formProps.onFinish &&
          formProps.onFinish({ ...values, event: projectId })
        setIsCreating!(false)
      }}>
      <Space>
        <Form.Item
          name="user"
          label="User"
          rules={[
            {
              required: true,
            },
          ]}>
          <SelectWithDefault<IStaff>
            style={{ width: 300 }}
            useSelectProps={{ resource: STAFF_URL, optionValue: 'id' }}
            renderOptions={(items) =>
              items?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {getUserName(item, false)}
                </Select.Option>
              ))
            }
          />
        </Form.Item>
        <Button
          size="small"
          onClick={() => {
            setIsCreating!(false)
          }}>
          Cancel
        </Button>
        <Button size="small" type="primary" onClick={useFormProps.form.submit}>
          Save
        </Button>
      </Space>
    </Form>
  )
}
