import { Button, Card, Form, Input, Select, Space, Switch } from 'antd'
import { useApiUrl, useCustom } from '@refinedev/core'
import { RowRemoveButton } from 'components/button'
import { ColorPicker } from 'components/input'
import { COLOR, REQUIRES_OPTIONS } from 'consts'
import { IAttribute, IOption } from 'interfaces/attribute'
import { Choice } from 'interfaces/base'
import { UseFormReturnType } from 'interfaces/refine'
import { useEffect, useState } from 'react'

export const AttributeForm: React.FC<{
  useFormProps: UseFormReturnType<IAttribute>
}> = ({ useFormProps }) => {
  const { formProps, queryResult } = useFormProps
  const apiUrl = useApiUrl()
  const [selectedType, setSelectedType] = useState(
    queryResult?.data?.data.attribute_type ?? '',
  )

  const { data } = useCustom<IOption>({
    url: `${apiUrl}/core/attributes/`,
    method: 'options',
  })

  const options = data?.data.actions?.POST?.attribute_type.choices.map(
    (choice: Choice, i) => ({
      key: i,
      label: choice.display_name,
      value: choice.value,
    }),
  )

  useEffect(() => {
    setSelectedType(queryResult?.data?.data.attribute_type as string)
  }, [queryResult])

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Type"
        name="attribute_type"
        rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder="Select a type"
          options={options}
          onChange={(value) => setSelectedType(value)}
        />
      </Form.Item>
      {REQUIRES_OPTIONS.includes(selectedType as string) && (
        <Card title="Options">
          <Form.List name="options">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <p key={field.key}>
                    <Space align="start">
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        rules={[{ required: true }]}>
                        <Input placeholder="Name" />
                      </Form.Item>
                      {selectedType === COLOR && (
                        <Form.Item
                          {...field}
                          name={[field.name, 'value']}
                          initialValue="#fff">
                          <ColorPicker />
                        </Form.Item>
                      )}
                      <RowRemoveButton onClick={() => remove(field.name)} />
                    </Space>
                  </p>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Add Option
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>
      )}
      <Form.Item
        name="show_left"
        valuePropName="checked"
        label="Show Left"
        tooltip={{
          title:
            'True if you want to show the attribute on the left side of the Product Info Pop Up Window in Showcase View',
        }}>
        <Switch />
      </Form.Item>
    </Form>
  )
}
