import React from 'react'
import { useApiUrl, useCustomMutation, useModal } from '@refinedev/core'
import { Button, Form, Modal } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { DistributionCenterType } from 'interfaces/quote'
import { WAREHOUSE_URL } from 'urls'

const AddDistributionCentreModal = ({
  selectedRows,
  setSelectedRows,
  refetchSource,
}: {
  selectedRows: any
  setSelectedRows: any
  refetchSource: any
}) => {
  const { show, close, visible } = useModal()
  const { mutate } = useCustomMutation<any>()
  const apiUrl = useApiUrl()

  const [form] = Form.useForm()
  return (
    <div>
      <Button onClick={show}>Add Distribution Centre</Button>
      <Modal
        onCancel={() => {
          form.resetFields()
          close()
        }}
        visible={visible}
        title="Select Warehouse for Distribution Center"
        onOk={() => {
          form.submit()
          close()
        }}>
        <Form
          form={form}
          preserve={false}
          layout="vertical"
          onFinish={(values) => {
            mutate(
              {
                url: `${apiUrl}/package_tool/material_sources/add_distribution_center/`,
                values: {
                  warehouse: values['warehouse'],
                  type: DistributionCenterType.WAREHOUSE,
                  ms_ids: selectedRows,
                },
                method: 'post',
                successNotification: (data, values) => {
                  return {
                    message: 'Distribution Centre assigned Successfully',
                    type: 'success',
                  }
                },
              },
              {
                onSuccess: (data) => {
                  setSelectedRows([])
                  refetchSource()
                },
              },
            )
          }}>
          <Form.Item name={'warehouse'} label={'Warehouse'}>
            <SelectWithDefault
              style={{
                width: '400px',
              }}
              useSelectProps={{
                resource: WAREHOUSE_URL,
                optionLabel: 'name',
              }}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddDistributionCentreModal
