import { BaseKey } from '@refinedev/core'
import { ISection } from './section'

export interface ICuisine {
  id: BaseKey
  name: string
}

export interface ICourse {
  id: BaseKey
  name: string
}

export interface IDish {
  id: BaseKey
  name: string
  image: string
  description: string
  price: number
  cuisines: ICuisine[]
}

export enum SurchargeType {
  PERCENTAGE = 'percentage',
  ABSOLUTE = 'absolute',
}

export enum SurchargeApplyTo {
  MENU_TOTAL = 'menu_total',
  OVERALL_TOTAL = 'overall_total',
}

export interface ISurcharge {
  id: BaseKey
  name: string
  surcharge_type: SurchargeType
  initial_value: number
  apply_to: SurchargeApplyTo
  is_optional: boolean
  value: number
}

export interface IMenu {
  id: BaseKey
  name: string
  cuisine: BaseKey | ICuisine[]
  description: string
  is_template: boolean
  is_published: boolean
  session: BaseKey | ISession
  is_included: boolean
  menu_total: number
  menu_surcharge_amount: number
  overall_surcharge_amount: number
  surcharges: ISurcharge[]
  dietary_preferences: string[]
  meal_type: string
  min_quantity: number
  max_quantity: number
  plan_price: number
}

export interface IMenuCourse {
  id: BaseKey
  menu: BaseKey
  course: BaseKey | ICourse
  display_name: string
  order: number
  min_quantity: number
  max_quantity: number
}

export interface ICourseDish {
  id: BaseKey
  course: BaseKey | IMenuCourse
  dish: BaseKey | IDish
  display_name: string
  description: string
  price: number
}

export interface IMenuSurcharge {
  id: BaseKey
  menu: IMenu
  surcharge: ISurcharge
  value: number
}

export interface ISession {
  id: BaseKey
  shortlist_package: BaseKey
  name: string
  venue: BaseKey
  pax: number
  venue_not_decided: boolean
  date_time: Date
  date_not_decided: boolean
  order: number
  session_total: number
  menus: IMenu[]
  section: ISection
  meal_type: string
}
