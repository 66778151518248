import { useModal, useTable } from '@refinedev/antd'
import { Badge, Calendar, Modal, Spin } from 'antd'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IDateGroupedVenueAvailability } from 'interfaces/venue'
import { VENUE_AVAILABILITY_URL } from 'urls'

export const AvailabilityCalendar = () => {
  const thisMonth = [dayjs().startOf('month'), dayjs().endOf('month')]

  const { tableQueryResult } = useTable<IDateGroupedVenueAvailability>({
    resource: `${VENUE_AVAILABILITY_URL}/grouped_by_date`,
    syncWithLocation: true,
    pagination: { mode: 'off' },
    filters: {
      initial: [
        {
          field: 'date',
          operator: 'between',
          value: thisMonth.map((value) => value.format('YYYY-MM-DD')).join(','),
        },
      ],
      permanent: [
        {
          field: 'is_available',
          operator: 'eq',
          value: true,
        },
      ],
    },
  })

  const dateCellRender = (date: dayjs.Dayjs) => {
    let data: IDateGroupedVenueAvailability | undefined

    data = tableQueryResult?.data?.data?.find(
      (item: any) => item.date === date.format('YYYY-MM-DD'),
    )
    return !!data ? <CalendarCell data={data} /> : <></>
  }

  const cellRender = (current: any, info: any) => {
    if (info.type === 'date') return dateCellRender(current)
    return info.originNode
  }

  return (
    <>
      <Spin spinning={tableQueryResult.isLoading}>
        <Calendar cellRender={cellRender} />
      </Spin>
    </>
  )
}

const CalendarCell = ({ data }: { data: IDateGroupedVenueAvailability }) => {
  const { modalProps, show } = useModal()
  return (
    <>
      <div onClick={() => show()} style={{ height: '100%' }}>
        {data.venues.map((item) => (
          <li key={item.name}>
            <Badge status="success" text={item.name} />
          </li>
        ))}
      </div>
      <Modal
        {...modalProps}
        title={`Available on ${data.date}`}
        footer={null}
        maskClosable>
        {data.venues.map((item) => (
          <li key={item.name}>
            <Badge status="success" text={<>{item.name}</>} />
          </li>
        ))}
      </Modal>
    </>
  )
}
