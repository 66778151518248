import { Card, Form, InputNumber } from "antd";
import { BaseKey, useOne } from "@refinedev/core";
import { GridSelect } from 'components/input/GridSelect'
import { BreakdownProductContext } from 'contexts/breakdownProduct'
import { IProduct } from 'interfaces/product'
import { useContext, useEffect } from 'react'
import { PRODUCT_URL } from 'urls'
import { PriceComponent } from './PriceComponent'
import { TotalComponent } from './TotalComponent'

export const ProductCombo = ({ handleTotal, form, field, listName }: any) => {
  const productId = Form.useWatch([listName, field.name, 'sub_product'], form)

  const allowedCategories = useContext(BreakdownProductContext)

  const qty = Form.useWatch([listName, field.name, 'quantity'], form)

  const { data: comboData, isLoading: productIsLoading } = useOne<IProduct>({
    resource: PRODUCT_URL,
    id: productId as BaseKey,
    queryOptions: {
      enabled: !!productId,
    },
  })

  const getTotal = () => {
    const price = comboData?.data.price ?? 0
    return price * qty
  }

  useEffect(() => {
    const values = form.getFieldsValue(true)[listName]
    values[field.name].total = getTotal()
    form.setFieldsValue({ ...values })
    form.submit()
  }, [comboData?.data.id, qty])

  return (
    <Card size="small">
      <Form.Item {...field} label="Product" name={[field.name, 'sub_product']}>
        <GridSelect
          useTableProps={{
            resource: PRODUCT_URL,
            permanentFilter: [
              { field: 'is_published', operator: 'eq', value: true },
              { field: 'is_internal', operator: 'eq', value: false },
              { field: 'is_deleted', operator: 'eq', value: false },
              {
                field: 'category',
                operator: 'in',
                value: allowedCategories?.join(','),
              },
            ],
          }}
          title="Products"
          getImageUrl={(item) => {
            const imageUrl =
              item && item.images && item.images.length !== 0
                ? item.images[0].image
                : ''
            return imageUrl
          }}
          getPrice={(item) => {
            const price = item && item.price ? item.price : 0
            return price
          }}
        />
      </Form.Item>

      <PriceComponent value={comboData?.data?.price ?? 0} />

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Form.Item {...field} name={[field.name, 'quantity']} initialValue={1}>
          <InputNumber placeholder="QTY" />
        </Form.Item>

        <Form.Item {...field} name={[field.name, 'total']} initialValue={0}>
          <TotalComponent />
        </Form.Item>
      </div>
    </Card>
  )
}
