import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageField, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Alert,
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'

import {
  BaseKey,
  CanReturnType,
  useApiUrl,
  useCan,
  useCreate,
  useCustomMutation,
  useDelete,
  useDeleteMany,
  useHandleNotification,
  useInvalidate,
} from '@refinedev/core'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import parse from 'html-react-parser'
import {
  ILineItemMaterial,
  IPackageDetailMaterial,
  IQuoteItemDetailed,
  IRFQItemDetails,
  IRFQMaterial,
} from 'interfaces/quote'
import { IItemDetailed } from 'interfaces/shortlistPackage'
import { useState } from 'react'
import { LINE_ITEM_MATERIAL_URL, QUOTE_URL, RFQ_URL } from 'urls'
import { Chat } from './Chat'
import { AddMaterial } from './AddMaterial'
import { MaterialQuantity } from './MaterialQuantity'
import Paragraph from 'antd/es/typography/Paragraph'

function AssignQuoteModal({
  formProps,
  modalProps,
  item,
  material,
  packageId,
  sectionId,
}: {
  formProps: any
  modalProps: any
  item?: IItemDetailed
  material?: IRFQMaterial
  packageId: BaseKey
  sectionId: BaseKey
}) {
  const getMaxQuantityAllowed = () =>
    !material?.quotes.length
      ? material?.total_quantity
      : Number(material?.total_quantity) -
        Number(
          material?.quotes.reduce(
            (previousTotal, quote) => previousTotal + quote.qty,
            0,
          ),
        )

  return (
    <Modal {...modalProps} destroyOnClose title="Create Quote">
      <Form
        {...formProps}
        layout="vertical"
        preserve={false}
        onFinish={(values) => {
          formProps.onFinish &&
            formProps.onFinish({
              ...values,
              package: packageId,
              item: item?.id,
              material: material?.material.id,
              section: sectionId,
            })
        }}>
        <Form.Item label="Vendor" name="vendor" rules={[{ required: true }]}>
          <SelectWithDefault
            useSelectProps={{
              resource: 'core/vendors',
              optionLabel: 'name',
              optionValue: 'id',
            }}
          />
        </Form.Item>
        <Form.Item label="Price" name="price" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        {!!material && (
          <Form.Item
            label="Quantity"
            name="qty"
            rules={[{ required: true }]}
            help={`Max QTY: ${getMaxQuantityAllowed()}`}>
            <InputNumber<number> max={getMaxQuantityAllowed()} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

const MaterialQuote = ({
  quote,
  refetchDetails,
}: {
  quote: IQuoteItemDetailed
  refetchDetails: CallableFunction
}) => {
  const { formProps, modalProps, show } = useModalForm({
    resource: QUOTE_URL,
    action: 'edit',
    redirect: false,
    onMutationSuccess: () => refetchDetails(),
  })

  const { mutate: deleteQuote } = useDelete()

  return (
    <Row>
      <Col span={20}>
        {quote.vendor.name}({quote.qty}): <PriceField value={quote.price} />{' '}
      </Col>
      <Col span={4}>
        <Space>
          <Button
            icon={<Icons.EditOutlined />}
            size="small"
            onClick={() => show(quote.id)}
          />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() =>
              deleteQuote(
                {
                  resource: QUOTE_URL,
                  id: quote.id,
                },
                { onSuccess: () => refetchDetails() },
              )
            }>
            <Button icon={<Icons.DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Space>
      </Col>
      <Modal {...modalProps} title="Edit Quote">
        <Form {...formProps} layout="vertical">
          <Form.Item label="Quantity" name="qty">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Price" name="price">
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
}

const MaterialAtpQuantity = ({ line_item_id }: any) => {
  const { mutate } = useCustomMutation<any>()
  const apiUrl = useApiUrl()

  const [materialAtp, setMaterialAtp] = useState<any>('')
  console.log(materialAtp)
  return (
    <div className={`${line_item_id}-atp-button-div`}>
      <Button
        className={`${line_item_id}-atp-button`}
        size="small"
        onClick={() => {
          mutate(
            {
              url: `${apiUrl}/package_tool/line_item_materials/${line_item_id}/material_in_use/`,
              values: {},
              method: 'post',
            },
            {
              onSuccess: (data) => {
                console.log(data.data['available qty'])
                setMaterialAtp(data.data['available qty'].toString())
              },
            },
          )
        }}
        icon={<FontAwesomeIcon icon={['fas', 'warehouse']} />}
      />{' '}
      {materialAtp}
    </div>
  )
}

const LineItemMaterialsTable: React.FC<{
  materials: ILineItemMaterial[]
  productRecord: any
  packageId: BaseKey | undefined
  refetchDetails: CallableFunction
  addLineItemMaterialPerm: CanReturnType | undefined
  updateLineItemMaterialPerm: CanReturnType | undefined
  deleteLineItemMaterialPerm: CanReturnType | undefined
  selectedCopyPasteProduct: string | undefined
  setMaterialsCopied: (a: boolean) => void
  setCopyPasteProduct: (variables: any) => void
  materialsCopied: boolean
  selectedMaterials: any[]
  setSelectedMaterials: (a: any[]) => void
}> = ({
  materials,
  productRecord,
  packageId,
  refetchDetails,
  addLineItemMaterialPerm,
  updateLineItemMaterialPerm,
  deleteLineItemMaterialPerm,
  selectedCopyPasteProduct,
  setMaterialsCopied,
  setCopyPasteProduct,
  materialsCopied,
  selectedMaterials,
  setSelectedMaterials,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const apiUrl = useApiUrl()

  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    // If LineItemsMaterials' for any product are selected or not
    if (selectedCopyPasteProduct && !!selectedRows.length) {
      // Selected LineItemMaterials' should of the same product
      if (
        selectedRows.every((record) => record.item === selectedCopyPasteProduct)
      ) {
        // Assign Material ids for the selected rows
        setSelectedMaterials(selectedRows.map((value) => value.material.id))
        setSelectedRowKeys(selectedRowKeys)
      }
      // If Selected LineItemMaterials' are of different Product then alert the user
      else if (
        selectedRows.every((record) => record.item !== selectedCopyPasteProduct)
      ) {
        // Alert the user, if the user is trying to select materials of 2 different LineItem/Product
        alert('Please select materials of the same product.')
      }
    } else {
      setCopyPasteProduct(selectedRows[0]?.item ?? '')
      setSelectedRowKeys(selectedRowKeys)
      setSelectedMaterials(selectedRows.map((value) => value.material.id))
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const { mutate } = useDeleteMany()
  const { mutate: mutateMaterials } = useCustomMutation<any>()

  const invalidate = useInvalidate()

  const deleteManyLineItemMaterial = () => {
    mutate(
      {
        resource: LINE_ITEM_MATERIAL_URL,
        ids: selectedRowKeys,
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([])
          setCopyPasteProduct('')
          setSelectedMaterials([])
          setIsLoading(false)
          refetchDetails()
        },
      },
    )
  }

  const pasteMaterials = (copied_line_item: string, item: IItemDetailed) => {
    mutateMaterials(
      {
        url: `${apiUrl}/package_tool/rfq/${packageId}/copy_materials/`,
        values: {
          product_id: item.product.id,
          section: item.section,
          materials: selectedMaterials,
          selected_line_item: copied_line_item,
          target_line_item: item.id,
        },
        method: 'post',
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([])
          setCopyPasteProduct(copied_line_item)
          refetchDetails()
          invalidate({
            resource: `${RFQ_URL}/${packageId}/get_pending_table`,
            invalidates: ['list'],
          })
        },
      },
    )
  }

  const save_materials_for_product = (item: IItemDetailed) => {
    mutateMaterials(
      {
        url: `${apiUrl}/package_tool/rfq/${packageId}/save_materials_for_product/`,
        values: {
          line_item: item.id,
        },
        method: 'post',
        successNotification: () => {
          return {
            message: 'Materials Saved for the Product',
            type: 'success',
          }
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `${RFQ_URL}/${packageId}/get_pending_table`,
            invalidates: ['list'],
          })
          setIsLoading(false)
        },
        onError() {
          setIsLoading(false)
        },
      },
    )
  }

  const save_materials_to_line_item = (item: IItemDetailed) => {
    mutateMaterials(
      {
        url: `${apiUrl}/package_tool/rfq/${packageId}/save_materials_to_line_item/`,
        values: {
          line_item: item.id,
        },
        method: 'post',
      },
      {
        onSuccess: () => {
          refetchDetails()
          setIsLoading(false)
        },
        onError() {
          setIsLoading(false)
        },
      },
    )
  }

  return (
    <>
      <Table
        dataSource={materials}
        style={{ marginLeft: '-20px' }}
        size="large"
        rowKey={(record) => record.id}
        loading={isLoading}
        rowSelection={rowSelection}
        pagination={false}>
        <Table.Column<IPackageDetailMaterial>
          dataIndex={['material']}
          title={
            <>
              Materials{' '}
              {!!selectedRowKeys.length && (
                <>
                  <Popconfirm
                    title="Delete Material"
                    description="Sure want to delete all the selected materials?"
                    key="delete_materials_for_product"
                    onConfirm={() => deleteManyLineItemMaterial()}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      icon={<Icons.DeleteOutlined />}
                      size="small"
                      disabled={!deleteLineItemMaterialPerm?.can}
                    />
                  </Popconfirm>
                  <Button
                    icon={<Icons.CloseOutlined />}
                    size="small"
                    onClick={() => {
                      setCopyPasteProduct('')
                      setSelectedMaterials([])
                      setSelectedRowKeys([])
                      setMaterialsCopied(false)
                    }}
                  />
                </>
              )}
            </>
          }
          width={200}
          ellipsis={false}
          render={(value, record) => (
            <>
              <Typography.Text>{value?.name}</Typography.Text>
            </>
          )}
        />
        <Table.Column<IPackageDetailMaterial>
          dataIndex="qty"
          width={200}
          title={
            <div>
              Qty{'  '}
              {selectedCopyPasteProduct === productRecord.item.id && (
                <Icons.CopyFilled
                  style={{
                    fontSize: '15px',
                    marginLeft: '5px',
                    color: 'grey',
                  }}
                  onClick={() => {
                    setMaterialsCopied(true)
                  }}
                />
              )}
              {materialsCopied && selectedCopyPasteProduct && (
                <Button
                  size="small"
                  onClick={() => {
                    pasteMaterials(selectedCopyPasteProduct, productRecord.item)
                  }}
                  icon={<FontAwesomeIcon icon={['fas', 'paste']} />}
                />
              )}
              <Popconfirm
                title="Fetch Materials for this Product"
                description="It will fill the materials by removing the old!!"
                key="save_material_for_line_item"
                onConfirm={() => {
                  save_materials_to_line_item(productRecord.item)
                  setIsLoading(true)
                }}
                okText="Yes"
                cancelText="No">
                <Icons.SwapOutlined />
              </Popconfirm>
              <Popconfirm
                title="Save Material for this Product"
                description="It will save below material for this product"
                key="save_material_for_product"
                onConfirm={() => {
                  save_materials_for_product(productRecord.item)
                  setIsLoading(true)
                }}
                okText="Yes"
                cancelText="No">
                <Icons.SaveFilled
                  style={{
                    fontSize: '15px',
                    marginLeft: '5px',
                  }}
                />
              </Popconfirm>
            </div>
          }
          render={(value, record) => (
            <MaterialQuantity
              material={record}
              isLineMaterial={true}
              refetchMaterials={refetchDetails}
              packageId={packageId}
              disabledDelete={!deleteLineItemMaterialPerm?.can}
              disabledEdit={!updateLineItemMaterialPerm?.can}
            />
          )}
        />
        <Table.Column<IPackageDetailMaterial>
          dataIndex="id"
          width={200}
          title="ATP"
          render={(value, record) => (
            <MaterialAtpQuantity line_item_id={value} />
          )}
        />
      </Table>
      <AddMaterial
        lineItem={Number(productRecord.item.id)}
        refetchMaterials={refetchDetails}
        packageId={packageId}
        disabled={!addLineItemMaterialPerm?.can}
      />
    </>
  )
}

export const ItemRFQTable: React.FC<{
  items: IRFQItemDetails[]
  materials: IRFQMaterial[]
  rowSelection: any
  refetchDetails: CallableFunction
  disabled?: boolean
  canSelectVendor?: boolean
  percent?: number
  packageId?: BaseKey
  refetchVendorTotal?: CallableFunction
  sectionId: BaseKey
  selectedCopyPasteProduct: string | undefined
  setCopyPasteProduct: any
  selectedMaterials: BaseKey[]
  setSelectedMaterials: any
  materialsCopied: boolean
  setMaterialsCopied: any
}> = ({
  items,
  materials,
  rowSelection,
  refetchDetails,
  disabled,
  percent,
  packageId,
  refetchVendorTotal,
  canSelectVendor = false,
  sectionId,
  selectedCopyPasteProduct,
  setCopyPasteProduct,
  selectedMaterials,
  setSelectedMaterials,
  materialsCopied,
  setMaterialsCopied,
}) => {
  const { mutate: acceptVendor } = useCreate()
  const { mutate: changeStatus } = useCreate()
  const handelNotification = useHandleNotification()

  const [selectedItem, setSelectedItem] = useState<IItemDetailed | undefined>()
  const [selectedMaterial, setSelectedMaterial] = useState<
    IRFQMaterial | undefined
  >()

  const { modalProps, formProps, show } = useModalForm({
    action: 'create',
    resource: 'package_tool/quotes/assign_quote',
    redirect: false,
    onMutationSuccess: () => {
      refetchDetails()
      refetchVendorTotal?.()
    },
  })

  const handleVendorChange = (id: string) => {
    acceptVendor(
      {
        resource: `package_tool/quotes/${id}/accept`,
        values: {},
      },
      {
        onSuccess: () => refetchDetails(),
        onError: (error) => {
          handelNotification({
            key: `package_tool/quotes/${id}/accept`,
            type: 'error',
            description: 'Failed',
            message: error.response.data.detail,
          } as any)
        },
      },
    ) as any
  }

  const handleStatusChange = (id: string, status: string) => {
    changeStatus(
      {
        resource: `package_tool/shortlist_package/line_items/${id}/change_status`,
        values: { status },
      },
      { onSettled: () => refetchDetails() },
    )
  }

  const itemsWithoutMaterials = items.filter((i) => !i.has_materials)

  const { data: addLineItemMaterialPerm } = useCan({
    resource: 'add_lineitemmaterial',
    action: '',
  })

  const { data: updateLineItemMaterialPerm } = useCan({
    resource: 'change_lineitemmaterial',
    action: '',
  })

  const { data: deleteLineItemMaterialPerm } = useCan({
    resource: 'delete_lineitemmaterial',
    action: '',
  })

  return (
    <>
      {!!materials.length && (
        <Table
          dataSource={materials}
          rowKey={(record) => record.material.id}
          size="small"
          pagination={false}>
          <Table.Column
            width={400}
            dataIndex={['material', 'name']}
            title="Name"
            ellipsis
          />

          <Table.Column dataIndex={['total_quantity']} title="QTY" />
          <Table.Column<IRFQMaterial>
            dataIndex={['quotes']}
            title="Vendors"
            render={(quotes: IQuoteItemDetailed[]) => (
              <Space direction="vertical">
                {quotes?.map((quote) => (
                  <MaterialQuote
                    key={quote.id}
                    quote={quote}
                    refetchDetails={refetchDetails}
                  />
                ))}
              </Space>
            )}
          />
          <Table.Column<IRFQMaterial>
            title="Actions"
            render={(_, record) => (
              <Space>
                <Tooltip title="Assign Quote">
                  <Button
                    icon={<FontAwesomeIcon icon={['fas', 'stream']} />}
                    size="small"
                    disabled={
                      Number(record.total_quantity) ===
                      (record.quotes?.reduce(
                        (previousTotal: number, quote): number =>
                          previousTotal + quote.qty,
                        0,
                      ) as unknown)
                    }
                    onClick={() => {
                      setSelectedMaterial(record)
                      setSelectedItem(undefined)
                      show()
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      )}
      {!!itemsWithoutMaterials.length && (
        <Table
          dataSource={itemsWithoutMaterials}
          rowKey={(record) => record.item.id}
          rowSelection={!disabled && rowSelection}
          size="small"
          pagination={false}>
          <Table.Column<IRFQItemDetails>
            title="Image"
            width={550}
            render={(_, record) => {
              return (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <Paragraph>{record.item.product?.name}</Paragraph>
                  </Col>
                  {record.item.product?.images.map((image) => (
                    <Col span={24} key={image.id}>
                      <ImageField
                        value={image.image}
                        alt="product"
                        style={{ height: 75, width: 75 }}
                      />
                    </Col>
                  ))}
                  <Col span={24} className="rfq-table-description">
                    {parse(record.item.description ?? '')}
                  </Col>
                </Row>
              )
            }}
          />
          {
            <Table.Column<IRFQItemDetails>
              dataIndex="materials"
              title="Materials"
              width={500}
              render={(value: ILineItemMaterial[], record) => (
                <div style={{ width: '100%' }}>
                  <LineItemMaterialsTable
                    materials={value}
                    productRecord={record}
                    refetchDetails={refetchDetails}
                    selectedCopyPasteProduct={selectedCopyPasteProduct}
                    packageId={packageId}
                    addLineItemMaterialPerm={addLineItemMaterialPerm}
                    updateLineItemMaterialPerm={updateLineItemMaterialPerm}
                    deleteLineItemMaterialPerm={deleteLineItemMaterialPerm}
                    setCopyPasteProduct={setCopyPasteProduct}
                    selectedMaterials={selectedMaterials}
                    setSelectedMaterials={setSelectedMaterials}
                    materialsCopied={materialsCopied}
                    setMaterialsCopied={setMaterialsCopied}
                  />
                </div>
              )}
            />
          }

          <Table.Column
            dataIndex={['item', 'quantity']}
            width={100}
            title="Qty"
          />

          <Table.Column
            dataIndex={['item', 'price']}
            width={100}
            title="Price"
            ellipsis
            render={(value) => (
              <PriceField
                value={
                  (value ?? 0) * (1 - (isNaN(percent ?? 0) ? 0 : percent ?? 0))
                }
              />
            )}
          />

          {!disabled && (
            <Table.Column<IRFQItemDetails>
              dataIndex="quotes"
              width={200}
              title="Vendors sent"
              ellipsis
              render={(value: IQuoteItemDetailed[]) =>
                value.map((quote) => (
                  <div key={quote.vendor.name}>
                    <Typography.Text
                      style={{
                        color: quote.is_vendor_locked ? 'orange' : 'black',
                      }}>
                      - {quote.vendor.name} {<br />} (
                      {!!(quote.true_price || quote.price) ? (
                        <PriceField value={quote.true_price || quote.price} />
                      ) : (
                        'Not Set'
                      )}
                      )
                    </Typography.Text>
                  </div>
                ))
              }
            />
          )}
          {canSelectVendor && (
            <Table.Column<IRFQItemDetails>
              dataIndex="quotes"
              width={200}
              title="Vendor selected"
              render={(value: IQuoteItemDetailed[], record) => (
                <Space>
                  <Select
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    onChange={handleVendorChange}
                    disabled={disabled}
                    defaultValue={value.find((q) => q.is_accepted)?.id}>
                    {value.map((quote) => (
                      <Select.Option value={quote.id} key={quote.id}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          {quote.vendor.name}{' '}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>

                  <Tooltip title="Assign Quote">
                    <Button
                      icon={<FontAwesomeIcon icon={['fas', 'stream']} />}
                      size="small"
                      onClick={() => {
                        setSelectedItem(record.item)
                        setSelectedMaterial(undefined)
                        show()
                      }}
                    />
                  </Tooltip>
                </Space>
              )}
            />
          )}
          {disabled && (
            <Table.Column<IRFQItemDetails>
              title="Status"
              dataIndex={'item'}
              width={125}
              fixed="right"
              ellipsis
              render={(item: IItemDetailed) => (
                <Space>
                  <Select
                    style={{ width: '100%' }}
                    defaultValue={
                      item.status_updates.length
                        ? item.status_updates[0].status
                        : ''
                    }
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => handleStatusChange(item.id, value)}>
                    <Select.Option value="INSTALLED">Installed</Select.Option>
                    <Select.Option value="APPROVED">Approved</Select.Option>
                  </Select>
                  <Chat item={item} />
                </Space>
              )}
            />
          )}
        </Table>
      )}
      <AssignQuoteModal
        modalProps={modalProps}
        formProps={formProps}
        item={selectedItem}
        material={selectedMaterial}
        packageId={packageId as BaseKey}
        sectionId={sectionId}
      />
    </>
  )
}
