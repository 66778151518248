import { Grid } from "antd";
import { TitleProps, useRouterContext } from "@refinedev/core";
import React from 'react'

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { Link } = useRouterContext()

  const breakpoint = Grid.useBreakpoint()
  var hide = false;
  if (breakpoint.lg || breakpoint.md){ 
    hide = false
  } else if (breakpoint.sm || breakpoint.xs){ 
    hide = true;
  }

  return (
    <Link to="/">
      {!hide ? (
        <img
          src={'/logo.svg'}
          alt="Meragi"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '64px',
            width: '200px',
          }}
        />
      ) : (
        <img
          src={'/logo-icon.svg'}
          alt="Meragi"
          style={{
            width: "80px",
            height: "64px",
          }}
        />
      )}
    </Link>
  )
}
