import { List, useTable } from '@refinedev/antd'

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd'

import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { IStaff } from 'interfaces/staff'
import { useState } from 'react'
import { PIPELINE_REPORT_URL, STAFF_URL } from 'urls'
import { getUserName } from 'utils/common'
import SaveFilterButton, { reportFilterFieldFormatters } from './SaveFilter'
import { ReportNames } from 'interfaces/crm'
import { getSearchFormInitialValue } from 'utils/filter'

export const PipelineReport = () => {
  const [cohort, setCohort] = useState(true)
  const [venue, setVenue] = useState(true)

  const { tableProps, searchFormProps, setFilters, filters } = useTable<any>({
    resource: `${PIPELINE_REPORT_URL}${cohort ? '' : '_effort'}`,
    syncWithLocation: true,
    onSearch: (data: any) => [
      {
        field: 'relationship_manager',
        operator: 'eq',
        value: data.relationship_manager
          ? data.relationship_manager.join(',')
          : undefined,
      },
      {
        field: 'created_at',
        operator: 'between',
        value: data.created_at
          ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
          .join(','),
      },
      { field: 'venue', operator: 'eq', value: data.venue },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        {
          field: 'created_at',
          operator: 'between',
          value: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD'),
          ].join(','),
        },
        { field: 'venue', operator: 'eq', value: null },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={'Pipeline View'}>
            <Space>
              Effort
              <Switch checked={cohort} onChange={() => setCohort(!cohort)} />
              Cohort
            </Space>
            <Divider type="vertical" />
            <Divider />
            <Form
              {...searchFormProps}
              initialValues={getSearchFormInitialValue(
                filters,
                reportFilterFieldFormatters,
              )}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name="relationship_manager">
                    <SelectWithDefault<IStaff>
                      mode="multiple"
                      useSelectProps={{ resource: STAFF_URL }}
                      placeholder="Select RMs"
                      renderOptions={(items) =>
                        items?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {getUserName(item)}
                          </Select.Option>
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="created_at" label="Created At">
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="venue">
                    <Radio.Group
                      defaultValue={null}
                      buttonStyle="solid"
                      size="small">
                      <Radio.Button value={null}>All</Radio.Button>
                      <Radio.Button value={true}>Venue Decided</Radio.Button>
                      <Radio.Button value={false}>
                        Venue Not Decided
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SaveFilterButton
                    report_name={ReportNames.PIPELINE}
                    report_type={cohort ? 'Cohort' : 'Effort'}
                    searchFormProps={searchFormProps}
                    setFilters={setFilters}
                    report_url={'/' + PIPELINE_REPORT_URL}
                  />
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                bordered
                scroll={{ y: '64vh' }}
                summary={(pageData) => {
                  let totalQualified = 0
                  let totalProposalPresented = 0
                  let totalBookings = 0

                  let totalVenueQualified = 0
                  let totalCallAttempts = 0
                  let totalVenueInactive = 0
                  let totalVenuePip = 0
                  let totalVenueNotProspect = 0
                  let totalVenuePipValue = 0
                  let totalVenuePp = 0
                  let totalVenuePpValue = 0
                  let totalVenueBgmv = 0
                  let totalVenueBookings = 0
                  let totalActivePackageValue = 0
                  let totalPPCount = 0
                  let totalMeetings = 0
                  let totalPhysicalMeetings = 0

                  pageData.forEach(
                    ({
                      total_qualified,
                      venue_inactive,
                      total_proposal_presented,
                      venue_pip,
                      venue_not_prospect,
                      venue_pip_value,
                      total_bookings,
                      venue_bgmv,
                      venue_pp,
                      venue_pp_value,
                      venue_qualified,
                      total_call_attempts,
                      venue_bookings,
                      total_active_package_value,
                      active_pp_count,
                      total_meetings,
                      total_physical_meetings,
                    }) => {
                      totalQualified += total_qualified ?? 0
                      totalVenueInactive += venue_inactive ?? 0
                      totalProposalPresented += total_proposal_presented ?? 0
                      totalVenuePip += venue_pip ?? 0
                      totalVenueNotProspect += venue_not_prospect ?? 0
                      totalVenuePipValue += venue_pip_value ?? 0
                      totalBookings += total_bookings ?? 0
                      totalVenueBgmv += venue_bgmv ?? 0
                      totalActivePackageValue += total_active_package_value ?? 0
                      totalPPCount += active_pp_count ?? 0
                      totalVenuePp += venue_pp ?? 0
                      totalVenuePpValue += venue_pp_value ?? 0
                      totalVenueBookings += venue_bookings ?? 0
                      totalVenueQualified += venue_qualified ?? 0
                      totalCallAttempts += total_call_attempts ?? 0
                      totalMeetings += total_meetings ?? 0
                      totalPhysicalMeetings += total_physical_meetings ?? 0
                    },
                  )
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <Typography.Title level={4}>Total</Typography.Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Typography.Text strong>
                            {totalQualified}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text strong>
                            {totalProposalPresented}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {totalBookings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {((totalBookings / totalQualified) * 100).toFixed(
                              2,
                            )}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {totalVenueQualified}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography.Text strong>
                            {(
                              (totalVenueQualified / totalQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {totalCallAttempts}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {(totalCallAttempts / totalVenueQualified).toFixed(
                              2,
                            )}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text strong>
                            {totalVenueInactive}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Typography.Text strong>
                            {(
                              (totalVenueInactive / totalVenueQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalVenueNotProspect}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalVenuePip}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {(
                              (totalVenuePip / totalVenueQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalVenuePipValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalVenuePp}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {(
                              (totalVenuePp / totalVenueQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalVenuePpValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalVenueBookings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {(
                              (totalVenueBookings / totalVenueQualified) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {(
                              (totalVenueBookings / totalVenuePp) *
                              100
                            ).toFixed(2)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalVenueBgmv} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            <PriceField value={totalActivePackageValue} />
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalPPCount}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalMeetings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Typography.Text strong>
                            {totalPhysicalMeetings}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}>
                <Table.Column
                  dataIndex="relationship_manager"
                  title="Relationship Manager"
                  fixed
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {record.relationship_manager__first_name}{' '}
                      {record.relationship_manager__last_name}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="total_qualified"
                  title="Total Qualified"
                  fixed
                  width={100}
                />
                <Table.Column
                  dataIndex="total_proposal_presented"
                  title="Total Proposal Presented"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_bookings"
                  title="Total Booking"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_bookings"
                  title="Booking/QL %"
                  width={100}
                  render={(totalBookings, record: any) => (
                    <>
                      {(
                        ((totalBookings ?? 0) / (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="venue_qualified"
                  title="Qualified"
                  width={100}
                />
                <Table.Column
                  title="Qualified %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_qualified ?? 0) /
                          (record.total_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Call Attempts"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_call_attempts"
                  title="Avg Call Attempts"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        (record.total_call_attempts ?? 0) /
                        (record.venue_qualified ?? 1)
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="venue_inactive"
                  title="Inactive"
                  width={100}
                />
                <Table.Column
                  title="Inactive %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_inactive ?? 0) /
                          (record.venue_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="venue_not_prospect"
                  title="Not Prospect"
                  width={100}
                />
                <Table.Column
                  dataIndex="venue_pip"
                  title="Prospect"
                  width={100}
                />
                <Table.Column
                  title="Prospect %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_pip ?? 0) /
                          (record.venue_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="venue_pip_value"
                  title="Prospect value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column dataIndex="venue_pp" title="PP" width={100} />
                <Table.Column
                  title="PP %"
                  width={100}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_pp ?? 0) /
                          (record.venue_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="venue_pp_value"
                  title="PP value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="venue_bookings"
                  title="Bookings"
                  width={200}
                />
                <Table.Column
                  title="Booking/QL %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_bookings ?? 0) /
                          (record.venue_qualified ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />
                <Table.Column
                  title="Booking/PP %"
                  width={200}
                  render={(_, record: any) => (
                    <>
                      {(
                        ((record.venue_bookings ?? 0) /
                          (record.venue_pp ?? 1)) *
                        100
                      ).toFixed(2)}
                    </>
                  )}
                />

                <Table.Column
                  dataIndex="venue_bgmv"
                  title="BGMV"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="total_active_package_value"
                  title="Active PP value"
                  width={200}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="active_pp_count"
                  title="Active PP count"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_meetings"
                  title="Total Meetings"
                  width={100}
                />
                <Table.Column
                  dataIndex="total_physical_meetings"
                  title="Physical Meetings"
                  width={100}
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
