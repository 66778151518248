import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DateField,
  RefreshButton,
  Show,
  TextField,
  useForm,
  useModal,
  useModalForm,
} from '@refinedev/antd'
import { CSVLink } from 'react-csv'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Segmented,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
  Select,
} from 'antd'

import {
  BaseKey,
  useCan,
  useCreate,
  useMany,
  useOne,
  useRouterContext,
  useShow,
  useUpdate,
} from '@refinedev/core'
import { Affix, ModalProps, Spin, Switch } from 'antd'
import { PriceField } from 'components/field'
import {
  IEventMaterial,
  IRFQSectionItem,
  IVendorTotal,
  SectionItemDetails,
} from 'interfaces/quote'
import { IPayoutSummary } from 'interfaces/shortlistPackage'
import { useEffect, useState } from 'react'
import {
  RFQ_URL,
  SHORTLIST_PACKAGE_DETAILS_URL,
  SHORTLIST_PACKAGE_URL,
} from 'urls'
import { QuoteModal } from './components'
import { ItemRFQTable } from './components/ItemRFQTable'
import { ReportsTab } from './components/ReportsTab'
import { SourceTabs } from './components/source/SourceTabs'
import './styles.less'
import PendingTable from './components/PendingTable'
import { DatePicker } from 'components/input'

const EditableDateTimePicker: React.FC<{
  value: string
  name: string
  packageDetailId: BaseKey
  tooltipName: string
}> = ({ value, name, packageDetailId, tooltipName }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { formProps, form } = useForm({
    action: 'edit',
    resource: SHORTLIST_PACKAGE_DETAILS_URL,
    id: packageDetailId,
    redirect: false,
    invalidates: ['all'],
  })

  return (
    <Form {...formProps}>
      {isEditing ? (
        <Space direction="horizontal">
          <Form.Item name={name} initialValue={value}>
            <DatePicker
              size="small"
              showTime={{ use12Hours: true }}
              format="YYYY-MM-DD hh:mm a"
            />
          </Form.Item>
          <Button
            size="small"
            icon={<Icons.SaveOutlined />}
            onClick={() => {
              form.submit()
              setIsEditing(!isEditing)
            }}
          />
          <Button
            size="small"
            icon={<Icons.CloseOutlined />}
            onClick={() => {
              setIsEditing(!isEditing)
            }}
          />
        </Space>
      ) : (
        <Space direction="horizontal">
          <DateField value={value} format="LLL" />
          <Tooltip title={`Edit ${tooltipName}`}>
            <Button
              size="small"
              icon={<Icons.EditOutlined />}
              onClick={() => {
                setIsEditing(!isEditing)
              }}
            />
          </Tooltip>
        </Space>
      )}
    </Form>
  )
}

const ItemRFQList = (props: any) => {
  const [selectedCopyPasteProduct, setCopyPasteProduct] = useState<
    string | undefined
  >()
  const [selectedMaterials, setSelectedMaterials] = useState<BaseKey[]>([])
  const [materialsCopied, setMaterialsCopied] = useState<boolean>(false)

  const { data: stageData } = useOne<any[]>({
    resource: SHORTLIST_PACKAGE_DETAILS_URL,
    id: 'stage_list',
  })
  const { mutate } = useUpdate()

  const handlePackageDetailsStageChange = (
    stage: string,
    packageDetailId: BaseKey,
  ) => {
    mutate(
      {
        resource: SHORTLIST_PACKAGE_DETAILS_URL,
        id: `${packageDetailId}/set_package_details_stage`,
        values: {
          stage: stage,
        },
      },
      {
        onSuccess: (data) => {
          props.queryResult.refetch()
        },
      },
    )
  }

  return (
    <Table<SectionItemDetails>
      loading={props.queryResult.isLoading}
      pagination={false}
      rowKey="section"
      size="small"
      scroll={{
        x: 1500,
      }}
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 30,
        expandedRowRender: (record) => (
          <ItemRFQTable
            items={record.items}
            materials={record.materials}
            rowSelection={props.rowSelection}
            refetchDetails={props.queryResult.refetch}
            percent={props.discountPercentage}
            disabled={props.rfqLocked}
            canSelectVendor={!!props.selectVendor?.can}
            packageId={props.showId}
            refetchVendorTotal={props.refetchVendorTotal}
            sectionId={record.section}
            selectedCopyPasteProduct={selectedCopyPasteProduct}
            setCopyPasteProduct={setCopyPasteProduct}
            selectedMaterials={selectedMaterials}
            setSelectedMaterials={setSelectedMaterials}
            materialsCopied={materialsCopied}
            setMaterialsCopied={setMaterialsCopied}
          />
        ),
        expandRowByClick: false,
      }}
      dataSource={props.queryResult?.data?.data.sections}>
      <Table.Column
        dataIndex={'section'}
        title="Event"
        ellipsis
        render={(value) =>
          props.sectionData?.data.find((s: any) => String(s.id) === value)?.name
        }
      />
      <Table.Column
        title="Stage"
        ellipsis
        dataIndex={['details']}
        render={(rowValue) => (
          <Select
            popupMatchSelectWidth={false}
            onChange={(value) =>
              handlePackageDetailsStageChange(value, rowValue.id)
            }
            placeholder="Stage"
            options={stageData?.data.map((t: any) => ({
              label: t,
              value: t,
            }))}
            value={rowValue?.stages?.[0]?.stage}
          />
        )}
      />
      <Table.Column
        dataIndex={['details', 'date_time']}
        title="Time"
        ellipsis
        render={(value) => <DateField value={value} format="LLL" />}
      />
      <Table.Column
        dataIndex={['details', 'decor_date_time']}
        title="Decor Start Date and Time"
        ellipsis
        render={(value, record: any, index) => (
          <>
            <EditableDateTimePicker
              name="decor_date_time"
              value={value}
              packageDetailId={record.details.id}
              tooltipName="Decor Date and Time"
            />
          </>
        )}
      />
      <Table.Column
        dataIndex={['details', 'dismantling_start_date_time']}
        title="Dismantling Start Date and Time"
        ellipsis
        render={(value, record: any, index) => (
          <>
            <EditableDateTimePicker
              name="dismantling_start_date_time"
              value={value}
              packageDetailId={record.details.id}
              tooltipName="Dismantling Start Time"
            />
          </>
        )}
      />
      <Table.Column
        dataIndex={['details', 'dismantling_date_time']}
        title="Dismantling End Date and Time"
        ellipsis
        render={(value, record: any, index) => (
          <>
            <EditableDateTimePicker
              name="dismantling_date_time"
              value={value}
              packageDetailId={record.details.id}
              tooltipName="Dismantling End Time"
            />
          </>
        )}
      />
      <Table.Column
        dataIndex={['details', 'venue', 'name']}
        title="Venue"
        ellipsis
      />
    </Table>
  )
}

const PayoutModal = ({
  payoutModalProps,
  packageId,
}: {
  payoutModalProps: ModalProps
  rowSelection: any
  packageId: BaseKey
}) => {
  const [payoutValues, setPayoutValues] = useState<{
    [key: string]: { [key: string]: number | null }
  }>({})

  const { data, isLoading, refetch } = useOne<IPayoutSummary[]>({
    resource: SHORTLIST_PACKAGE_URL,
    id: `${packageId}/get_payout_summary`,
    queryOptions: {
      enabled: payoutModalProps.open,
    },
  })

  const { mutate: post, isLoading: postIsLoading } = useCreate()

  return (
    <Modal
      {...payoutModalProps}
      destroyOnClose
      onOk={() => {
        post(
          {
            resource: `${SHORTLIST_PACKAGE_URL}/${packageId}/payout_vendor`,
            values: payoutValues,
          },
          { onSuccess: () => refetch() },
        )
      }}
      okButtonProps={{ loading: postIsLoading }}
      onCancel={(e) => {
        setPayoutValues({})
        payoutModalProps.onCancel && payoutModalProps.onCancel(e)
      }}>
      {isLoading ? (
        <div className="row h-center v-center">
          <Spin />
        </div>
      ) : (
        data?.data
          .filter((service) => !!service.vendors.length)
          .map((service) => (
            <div key={service.service}>
              <Card>
                <Card.Meta
                  title={
                    <>
                      {service.service} (
                      <PriceField value={service.paid ?? 0} />/
                      <PriceField value={service.total ?? 0} />)
                      <Divider />
                    </>
                  }
                />
                <Row gutter={[8, 8]}>
                  {service.vendors.map((vendor) => (
                    <Col key={vendor.vendor.id} span={6}>
                      <Card
                        actions={[
                          <div key={vendor.vendor.id}>
                            Pay:{' '}
                            <InputNumber<number>
                              max={vendor.pending}
                              prefix="₹"
                              disabled={vendor.pending < 1}
                              value={
                                payoutValues[service.service]
                                  ? payoutValues[service.service][
                                      vendor.vendor.id
                                    ]
                                  : 0
                              }
                              onChange={(value) =>
                                setPayoutValues({
                                  ...payoutValues,
                                  [service.service]: {
                                    ...payoutValues[service.service],
                                    [vendor.vendor.id]: value,
                                  },
                                })
                              }
                            />
                          </div>,
                        ]}>
                        <Card.Meta
                          title={vendor.vendor.name}
                          description={
                            <Space direction="vertical">
                              <div>
                                Account:{' '}
                                {vendor?.fund_account?.account_number ??
                                  vendor?.fund_account?.vpa_address}
                              </div>
                              <div>
                                Total: <PriceField value={vendor.total} />
                              </div>
                              <div>
                                Paid: <PriceField value={vendor.paid} />
                              </div>
                              <div>
                                Pending: <PriceField value={vendor.pending} />
                              </div>
                            </Space>
                          }
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
              <Divider />
            </div>
          ))
      )}
    </Modal>
  )
}

export const RFQShow: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [rfqLocked, setRfqLocked] = useState<boolean>()
  const [isExecuted, setIsExecuted] = useState<boolean>()
  const { showId, queryResult } = useShow<IRFQSectionItem>()
  const { Link } = useRouterContext()
  const [discountPercentage, setDiscountPercentage] = useState<number>()
  const [disableTracking, SetDisableTracking] = useState<boolean>(false)

  const { modalProps, formProps, show } = useModalForm({
    resource: `package_tool/quotes/?package_id=${showId}`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      queryResult.refetch()
      refetchVendorTotal()
    },
  })

  const { modalProps: payoutModalProps, show: payoutModalShow } = useModal()

  const { data: sectionData } = useMany({
    resource: 'package_tool/sections',
    ids:
      queryResult.data?.data.sections.map((section) => section.section) ?? [],
    queryOptions: {
      enabled: queryResult.isFetched,
    },
  })

  const {
    data: materials,
    refetch: refetchMaterials,
    isFetching: materialsFetching,
  } = useOne<IEventMaterial[]>({
    resource: `${RFQ_URL}`,
    id: `${showId}/get_event_materials`,
  })

  const { mutate: toggleRfqLocked } = useCreate()
  const { mutate: generateMaterials, isLoading: generateMaterialsIsLoading } =
    useCreate()
  const { mutate: toggleExecuted } = useCreate()
  const { data: vendorTotalData, refetch: refetchVendorTotal } = useOne<
    IVendorTotal[]
  >({
    resource: 'package_tool/rfq',
    id: `${showId}/get_vendor_total`,
  })

  const { data: selectVendor } = useCan({
    resource: 'select_rfq_vendor',
    action: '',
  })

  const { data: lockPayout } = useCan({
    resource: 'can_lock_payout',
    action: '',
  })

  const { data: toggleInhouse } = useCan({
    resource: 'change_inhouse',
    action: '',
  })

  const { mutate: updatePackage } = useUpdate()

  const { data: payVendor } = useCan({ resource: 'pay_vendor', action: '' })
  const { data: generateMaterial } = useCan({
    resource: 'can_generate_material',
    action: '',
  })

  useEffect(() => {
    setRfqLocked(queryResult.data?.data.package.rfq_locked)
    setIsExecuted(queryResult.data?.data.package.is_executed)
    setDiscountPercentage(
      (queryResult.data?.data.package.discount_amount ?? 0) /
        (queryResult.data?.data.package.price ?? 0),
    )
    if (queryResult.data?.data.package.dispatch_warehouse === null) {
      SetDisableTracking(true)
    }
  }, [queryResult.isFetching])
  const handleStateChange = (value: string) => {
    switch (value) {
      case 'locked':
      case 'unlocked':
        toggleRfqLocked(
          {
            resource: `package_tool/rfq/${queryResult.data?.data.package.id}/toggle_rfq_locked`,
            values: { value: value === 'locked' ? true : false },
          },
          {
            onSuccess: (data) => {
              queryResult.refetch()
            },
          },
        )
        break
      case 'executed':
        toggleExecuted(
          {
            resource: `package_tool/rfq/${queryResult.data?.data.package.id}/toggle_is_executed`,
            values: {},
          },
          {
            onSuccess: (data) => {
              queryResult.refetch()
            },
          },
        )
        break
    }
  }

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const pageHeaderProps = {
    extra: [
      <Space key="inhouseToggle">
        <Typography.Text strong>Inhouse</Typography.Text>{' '}
        <Switch
          checked={queryResult.data?.data.package.is_inhouse}
          onClick={(value: boolean) =>
            updatePackage(
              {
                resource: SHORTLIST_PACKAGE_URL,
                id: `${
                  queryResult.data?.data.package.id as BaseKey
                }/change_inhouse`,
                values: { is_inhouse: value },
              },
              {
                onSuccess: (data) => {
                  queryResult.refetch()
                },
              },
            )
          }
          disabled={!toggleInhouse?.can}
        />
        <Typography.Text strong>Payout Lock</Typography.Text>{' '}
        <Switch
          checked={queryResult.data?.data.package.is_payout_locked}
          onClick={(value: boolean) =>
            updatePackage(
              {
                resource: SHORTLIST_PACKAGE_URL,
                id: `${
                  queryResult.data?.data.package.id as BaseKey
                }/change_payout`,
                values: { is_payout_locked: value },
              },
              {
                onSuccess: (data) => {
                  queryResult.refetch()
                },
              },
            )
          }
          disabled={!lockPayout?.can}
        />
      </Space>,
      generateMaterial?.can && (
        <Popconfirm
          title="Generate Materials"
          description="Are you sure you want to generate materials?"
          key="generateMaterials"
          onConfirm={() =>
            generateMaterials(
              {
                resource: `${RFQ_URL}/${showId}/generate_materials`,
                values: {},
              },
              {
                onSuccess: (data) => {
                  queryResult.refetch()
                },
              },
            )
          }
          okText="Yes"
          cancelText="No">
          <Button loading={generateMaterialsIsLoading}>
            Generate Materials
          </Button>
        </Popconfirm>
      ),
      payVendor?.can && (
        <Button
          key="payout"
          onClick={() => payoutModalShow()}
          disabled={queryResult.data?.data.package.is_payout_locked}>
          <Space>
            <FontAwesomeIcon icon={['fas', 'coins']} /> Payout
          </Space>
        </Button>
      ),
      <Button
        key="reports"
        icon={<Icons.FileDoneOutlined />}
        onClick={() => window.open(`/${RFQ_URL}/reports/${showId}`)}>
        Reports
      </Button>,
      <RefreshButton
        key="refresh"
        onClick={() => {
          queryResult.refetch()
          refetchVendorTotal()
        }}
      />,
      <Segmented
        key="lockPackage"
        onChange={(value: any) => handleStateChange(value as string)}
        value={isExecuted ? 'executed' : rfqLocked ? 'locked' : 'unlocked'}
        options={[
          { label: 'Unlocked', value: 'unlocked' },
          { label: 'Locked', value: 'locked' },
          { label: 'Executed', value: 'executed' },
        ]}
      />,
    ],
  }

  return (
    <Show
      title={`Package: ${queryResult.data?.data.package.name}`}
      headerProps={pageHeaderProps}>
      <ReportsTab
        queryResult={queryResult}
        vendorTotalData={vendorTotalData}
        refetchVendorTotal={refetchVendorTotal}
        selectVendor={selectVendor}
      />
      <Tabs>
        <Tabs.TabPane key="item_list" tab="Products">
          <ItemRFQList
            rfqLocked={rfqLocked}
            showId={showId}
            queryResult={queryResult}
            discountPercentage={discountPercentage}
            sectionData={sectionData}
            refetchVendorTotal={refetchVendorTotal}
            selectVendor={selectVendor}
            rowSelection={rowSelection}
          />
        </Tabs.TabPane>
        {/* 
          We create BOM for all packages; irrespective of whether the package is inhouse or not
          So, the code block will never be rendered; i.e. Commented the code
        */}
        {/* {queryResult.data?.data.package.is_inhouse && (
          <Tabs.TabPane key="key" tab="Items">
            <Table
              loading={queryResult.isLoading}
              pagination={false}
              rowKey="section"
              size="small"
              scroll={{
                x: 1300,
              }}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (record) => (
                  <ItemTable
                    items={record.items}
                    rowSelection={rowSelection}
                    refetchDetails={queryResult.refetch}
                    percent={discountPercentage}
                    disabled={rfqLocked}
                    canSelectVendor={!!selectVendor?.can}
                    packageId={showId}
                    refetchVendorTotal={refetchVendorTotal}
                  />
                ),
                expandRowByClick: true,
              }}
              dataSource={queryResult?.data?.data.sections}>
              <Table.Column
                dataIndex={'section'}
                title="Event"
                ellipsis
                render={(value) =>
                  sectionData?.data.find((s: any) => String(s.id) === value)
                    ?.name
                }
              />
              <Table.Column
                dataIndex={['details', 'date_time']}
                title="Time"
                ellipsis
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column
                dataIndex={['details', 'venue', 'name']}
                title="Venue"
                ellipsis
              />
              <Table.Column dataIndex={['details', 'pax']} title="Pax" />
              <Table.Column
                dataIndex={['details', 'dismantling_date_time']}
                title="Dismantling Time"
                ellipsis
                render={(value) => <DateField value={value} format="LLL" />}
              />
            </Table>
          </Tabs.TabPane>
        )} */}
        {!!materials?.data.length && (
          <>
            <Tabs.TabPane key="pending" tab="Pending">
              <PendingTable
                disableTracking={disableTracking}
                packageId={showId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tracking">
              {queryResult.data?.data.sections && (
                <SourceTabs
                  disableTracking={disableTracking}
                  packageId={showId}
                  sections={queryResult?.data?.data.sections}
                />
              )}
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
      <QuoteModal
        modalProps={modalProps}
        formProps={formProps}
        rowSelection={rowSelection}
        packageId={showId}
      />
      <PayoutModal
        payoutModalProps={payoutModalProps}
        rowSelection={rowSelection}
        packageId={showId as BaseKey}
      />
      {!!selectedRowKeys.length && (
        <Affix
          offsetBottom={200}
          style={{ position: 'absolute', bottom: 200, left: -15 }}>
          <Space direction="vertical">
            <Tooltip title="Create quote">
              <Button
                type="primary"
                shape="circle"
                className="floating-btn"
                onClick={() => show()}
                icon={<FontAwesomeIcon icon={['fas', 'plus-square']} />}
              />
            </Tooltip>
          </Space>
        </Affix>
      )}
    </Show>
  )
}
