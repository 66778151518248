import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, Space } from "antd";
import CopyToClipboard from 'react-copy-to-clipboard'

export const AutoGeneratedPasswordInput: React.FC<{
  label?: string
  name?: string
  value: string
  onClick: () => void
  onChange?: (value: string) => void
}> = ({ label, name, value, onClick, onChange }) => {
  return (
    <Form.Item label={label} name={name}>
      <Input value={value} onChange={(e) => onChange?.(e.target.value)} />
      <Space>
        <CopyToClipboard text={value}>
          <Button>
            <FontAwesomeIcon icon={['fas', 'copy']} />
          </Button>
        </CopyToClipboard>
        <Button type="text" danger onClick={onClick}>
          Generate Password
        </Button>
      </Space>
    </Form.Item>
  )
}
