import { Form, Input, Space, Switch } from "antd";
import { REGION_KEY } from 'authProvider'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICustomer } from 'interfaces/customer'
import { UseFormReturnType } from 'interfaces/refine'
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { REGION_URL } from 'urls'

export const CustomerForm: React.FC<{
  useFormProps: UseFormReturnType<ICustomer>
}> = ({ useFormProps }) => {
  const { formProps } = useFormProps

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Phone Number"
        name="username"
        rules={[
          {
            required: true,
            pattern: new RegExp(/^\+?[\d]+$/),
            message: 'Can only contain + and numbers',
          },
        ]}>
        <Input />
      </Form.Item>
      <Space style={{ display: 'flex', flexDirection: 'row' }}>
        <Form.Item label="First Name" name="first_name">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input />
        </Form.Item>
      </Space>
      <Form.Item
        label="Region"
        name="region_id"
        initialValue={Number(localStorage.getItem(REGION_KEY))}>
        <SelectWithDefault
          useSelectProps={{
            resource: REGION_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
      <Form.Item
        label="Is Active"
        name="is_active"
        valuePropName="checked"
        initialValue={true}>
        <Switch />
      </Form.Item>
    </Form>
  );
}
