import { Descriptions } from 'antd'
import { IServicePackage } from 'interfaces/venue'

export const ServicePackageDetails = ({ item }: { item: IServicePackage }) => {
  return (
    <>
      <Descriptions column={4}>
        <Descriptions.Item label="Package Type">
          {item.package_type}
        </Descriptions.Item>
        <Descriptions.Item label="Service">{item.service}</Descriptions.Item>
        <Descriptions.Item label="Package">{item.package}</Descriptions.Item>
      </Descriptions>
    </>
  )
}
