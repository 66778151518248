import { CloseCircleFilled, EditOutlined } from '@ant-design/icons'
import {
  DeleteButton,
  ImageField,
  SaveButton,
  useForm,
  useModalForm,
} from '@refinedev/antd'
import {
  BaseKey,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
  useResource,
} from '@refinedev/core'
import {
  List as AntdList,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd'
import { FormProps } from 'antd/lib'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import {
  ICourse,
  ICourseDish,
  ICuisine,
  IDish,
  IMenu,
  IMenuCourse,
} from 'interfaces/catering'
import { useContext, useState } from 'react'
import {
  COURSE_DISH_URL,
  COURSE_URL,
  DISH_URL,
  MENU_COURSE_URL,
  MENU_URL,
  SESSION_URL,
} from 'urls'
import { MenuForm } from './Form'
import ShowSurcharges from './AddSurcharge'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'

const { Title, Text, Link } = Typography

const MenuDetails = (props: { menu: IMenu }) => {
  const [isEditing, setIsEditing] = useState(false)

  const invalidate = useInvalidate()

  const { formProps, saveButtonProps } = useForm({
    resource: MENU_URL,
    id: props.menu.id,
    redirect: false,
    action: 'edit',
    onMutationSuccess: () => {
      invalidate({
        resource: MENU_URL,
        invalidates: ['detail'],
        id: `${props.menu.id}/?expand=cuisine&`,
      })
      setIsEditing(false)
    },
  })
  const _package = useContext(ShortlistPackageContext)
  return (
    <Card
      size="small"
      title="Details"
      extra={
        <Space>
          {isEditing && (
            <SaveButton hideText size="small" {...saveButtonProps} />
          )}
          { !_package?.is_cancelled && <Button
            key={'edit'}
            size="small"
            icon={isEditing ? <CloseCircleFilled /> : <EditOutlined />}
            onClick={() => setIsEditing(!isEditing)}
          />}
          { !_package?.is_cancelled && <DeleteButton
            hideText
            size="small"
            resource={MENU_URL}
            recordItemId={props.menu.id}
            onSuccess={() => {
              if (props.menu.session) {
                invalidate({
                  resource: 'package_tool/shortlist_packages',
                  invalidates: ['all'],
                })
              }
            }}
          />}
        </Space>
      }>
      {isEditing ? (
        <MenuForm
          formProps={formProps}
          sessionId={props.menu.session as BaseKey}
        />
      ) : (
        <Descriptions>
          <Descriptions.Item label="Name">{props.menu.name}</Descriptions.Item>
          <Descriptions.Item label="Description">
            {props.menu.description}
          </Descriptions.Item>
          <Descriptions.Item label="Cuisine">
            {(props.menu?.cuisine as ICuisine[])
              .map((cuisine: ICuisine) => cuisine.name)
              .join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="Dietrary Preferences">
            {(props.menu?.dietary_preferences)
              .map((diet_preference) => diet_preference)
              .join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="Meal Type">
            {props.menu?.meal_type}
          </Descriptions.Item>
          <Space>
            <Descriptions>
              <Descriptions.Item label="Max Dishes">
                {props.menu?.max_quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Plan Price">
                {props.menu?.plan_price}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Descriptions>
      )}
    </Card>
  )
}

export const MenuComponent: React.FC<{
  menuId?: BaseKey
  refetchSession?: any
  sessionId?: BaseKey
  packageId?: BaseKey
  pax?: number
}> = ({ menuId, refetchSession, sessionId, packageId, pax }) => {
  const { id: resourceId } = useResource()
  const id = menuId || resourceId
  const { data, isLoading, refetch } = useOne<IMenu>({
    resource: MENU_URL,
    id: `${id}/?expand=cuisine&`,
  })
  const menu = data?.data
  const { mutate } = useCustomMutation()
  const apiUrl = useApiUrl()
  const _package = useContext(ShortlistPackageContext)

  const { data: courseData, isLoading: coursesLoading } = useList<IMenuCourse>({
    resource: MENU_COURSE_URL,
    pagination: { mode: 'off' },
    filters: [
      { field: 'menu', operator: 'eq', value: id },
      { field: 'expand', operator: 'eq', value: 'course' },
    ],
    queryOptions: {
      enabled: !!menu,
    },
  })

  const refetchAll = () => {
    refetch()
    refetchSession()
  }
  return !!menu ? (
    <Card
      title="Menu Builder"
      extra={
        !menuId && (
          <Switch
            key="toggle executed"
            checked={menu.is_published}
            checkedChildren="Published"
            unCheckedChildren="Not Published"
            onChange={() => {
              mutate(
                {
                  url: `${apiUrl}/cms/menus/${resourceId}/`,
                  method: 'patch',
                  values: {
                    is_published: !menu.is_published,
                  },
                },
                {
                  onSuccess: (data, variables, context) => {
                    refetch()
                  },
                },
              )
            }}
          />
        )
      }>
      <MenuDetails menu={menu}></MenuDetails>
      <Card>
        {!!courseData?.data &&
          courseData.data.map((course) => (
            <MenuCourseDetail
              key={course.id}
              menu={menu}
              course={course}
              packageId={packageId}
            />
          ))}
        <MenuCourseAdd menu={menu} />
      </Card>
      <ShowSurcharges
        menuId={id}
        pax={pax ? pax : 0}
        surcharges={data.data.surcharges}
        refetch={refetchAll}
        menuTotal={data.data.menu_total}
        menuSurchargeTotal={data.data.menu_surcharge_amount}
        overallSurchargeTotal={data.data.overall_surcharge_amount}
        sessionId={sessionId}
        packageId={packageId}
        isEditable={!_package?.is_cancelled}
      />
    </Card>
  ) : (
    <>No Menu</>
  )
}

export const MenuShow: React.FC<{}> = ({}) => {
  return <MenuComponent />
}

const MenuCourseDetail = (props: {
  course: IMenuCourse
  menu: IMenu
  packageId?: BaseKey
}) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: MENU_COURSE_URL,
    action: 'edit',
    id: props.course.id,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({ resource: MENU_COURSE_URL, invalidates: ['list'] })
    },
  })

  const {
    data: dishesData,
    isLoading: isDishesLoading,
    refetch: refetchDishes,
  } = useList<ICourseDish>({
    resource: COURSE_DISH_URL,
    pagination: { mode: 'off' },
    filters: [
      { field: 'course', operator: 'eq', value: props.course.id },
      { field: 'expand', operator: 'eq', value: 'dish' },
    ],
  })
  const _package = useContext(ShortlistPackageContext)
  return (
    <div>
      <Card
        title={
          <Space>
            <Typography.Text strong>
              {props.course.display_name ??
                (props.course.course as ICourse).name}
            </Typography.Text>
            <Divider type="vertical" />
            <Typography.Text strong>
              {'min dishes: ' + props.course.min_quantity}
            </Typography.Text>
            <Divider type="vertical" />
            <Typography.Text strong>
              {'max dishes: ' + props.course.max_quantity}
            </Typography.Text>
          </Space>
        }
        size="small"
        extra={
          <Space>
            { !_package?.is_cancelled && <DeleteButton
              hideText
              size="small"
              resource={MENU_COURSE_URL}
              recordItemId={props.course.id}
              onSuccess={() => {
                invalidate({ resource: MENU_COURSE_URL, invalidates: ['list'] })
                invalidate({
                  resource: MENU_URL,
                  invalidates: ['detail'],
                  id: `${props.course.menu}/?expand=cuisine&`,
                })
                if (props.packageId) {
                  invalidate({
                    resource: 'package_tool/shortlist_packages',
                    invalidates: ['all'],
                  })
                }
              }}
            />}
            { !_package?.is_cancelled && <Button
              key={'edit'}
              size="small"
              icon={<EditOutlined />}
              onClick={() => show(props.course.id)}
            />}
          </Space>
        }>
        <AntdList
          loading={isDishesLoading}
          dataSource={dishesData?.data}
          renderItem={(dish) => (
            <CourseDishDetail
              dish={dish}
              course={props.course}
              refetch={refetchDishes}
            />
          )}
        />
        <CourseDishAdd
          course={props.course}
          refetch={refetchDishes}
          packageId={props.packageId}
        />
      </Card>
      <Modal {...modalProps} destroyOnClose>
        <MenuCourseForm menu={props.menu} formProps={formProps} />
      </Modal>
    </div>
  )
}

const CourseDishDetail = (props: {
  dish: ICourseDish
  course: IMenuCourse
  refetch: CallableFunction
}) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: COURSE_DISH_URL,
    action: 'edit',
    redirect: false,
    id: props.dish.id,
    onMutationSuccess: () => {
      props.refetch()
      invalidate({
        resource: MENU_COURSE_URL,
        invalidates: ['detail'],
        id: props.course.menu,
      })
      invalidate({
        resource: 'package_tool/shortlist_packages',
        invalidates: ['all'],
      })
    },
  })
  const _package = useContext(ShortlistPackageContext)
  return (
    <AntdList.Item
      actions={ _package?.is_cancelled ? undefined : [
        <DeleteButton
          key="delete"
          resource={COURSE_DISH_URL}
          recordItemId={props.dish.id}
          hideText
          size="small"
          type="text"
          onSuccess={() => {
            invalidate({
              resource: MENU_COURSE_URL,
              invalidates: ['detail'],
              id: props.course.menu,
            })
            invalidate({
              resource: 'package_tool/shortlist_packages',
              invalidates: ['all'],
            })
          }}
        />,
        <Button
          key="edit"
          onClick={() => {
            show(props.dish.id)
          }}
          type="text"
          size="small"
          icon={<EditOutlined />}
        />,
      ]}>
      <AntdList.Item.Meta
        avatar={
          <ImageField
            value={(props.dish.dish as IDish)?.image}
            height={75}
            width={75}
          />
        }
        title={
          <Space>
            <span>
              {props.dish.display_name ?? (props.dish.dish as IDish).name}
            </span>{' '}
            <PriceField value={props.dish.price} />
          </Space>
        }
        description={props.dish.description}
      />
      <Modal {...modalProps} destroyOnClose>
        <CourseDishForm course={props.course} formProps={formProps} isEdit />
      </Modal>
    </AntdList.Item>
  )
}

const CourseDishAdd = (props: {
  course: IMenuCourse
  refetch: CallableFunction
  packageId?: BaseKey
}) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: COURSE_DISH_URL,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      props.refetch()
      invalidate({
        resource: MENU_URL,
        invalidates: ['detail'],
        id: `${props.course.menu}/?expand=cuisine&`,
      })
      if (props.packageId) {
        invalidate({
          resource: 'package_tool/shortlist_packages',
          invalidates: ['all'],
        })
      }
    },
  })
  const _package = useContext(ShortlistPackageContext)
  return (
    <>
      <Modal {...modalProps}>
        <CourseDishForm course={props.course} formProps={formProps} />
      </Modal>
      {!_package?.is_cancelled && <Button onClick={() => show()} block>
        Add Dish
      </Button>}
    </>
  )
}

const CourseDishForm = (props: {
  formProps: FormProps
  course: IMenuCourse
  isEdit?: boolean
}) => {
  const { isEdit = false } = props
  const onFinish = (values: any) => {
    props.formProps.onFinish?.({ ...values, course: props.course.id })
  }
  return (
    <Form {...props.formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item name="dish" label="Dish">
        <SelectWithDefault
          useSelectProps={{ resource: DISH_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="display_name" label="Display Name">
        <Input />
      </Form.Item>
      {isEdit && (
        <>
          <Form.Item name="price" label="Price">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
        </>
      )}
    </Form>
  )
}

const MenuCourseAdd = (props: { menu: IMenu }) => {
  const invalidate = useInvalidate()
  const { modalProps, formProps, show } = useModalForm({
    resource: MENU_COURSE_URL,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: MENU_COURSE_URL,
        invalidates: ['detail'],
        id: props.menu.id,
      })
    },
  })
  const _package = useContext(ShortlistPackageContext)
  return (
    <>
      <Modal {...modalProps}>
        <MenuCourseForm menu={props.menu} formProps={formProps} />
      </Modal>
      {!_package?.is_cancelled && <Button onClick={() => show()} block type="dashed">
        Add Course
      </Button>}
    </>
  )
}

const MenuCourseForm = (props: { formProps: FormProps; menu: IMenu }) => {
  const onFinish = (values: any) => {
    props.formProps.onFinish?.({ ...values, menu: props.menu.id })
  }
  return (
    <Form {...props.formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item name="course" label="Course">
        <SelectWithDefault
          useSelectProps={{ resource: COURSE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="display_name" label="Display Name">
        <Input />
      </Form.Item>
      <Form.Item name="order" label="Order">
        <Input />
      </Form.Item>
      <Space>
        <Form.Item label="Min Dishes" name={'min_quantity'}>
          <InputNumber<number> defaultValue={0} />
        </Form.Item>
        <Form.Item label="Max Dishes" name={'max_quantity'}>
          <InputNumber<number> defaultValue={0} />
        </Form.Item>
      </Space>
    </Form>
  )
}
