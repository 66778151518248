import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { BaseKey, useCreate, useInvalidate } from '@refinedev/core'
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IMaterial } from 'interfaces/material'
import { useState } from 'react'
import { MATERIAL_URL, LINE_ITEM_MATERIAL_URL, RFQ_URL } from 'urls'

export const AddMaterial: React.FC<{
  lineItem: number
  refetchMaterials: any
  packageId?: BaseKey
  disabled?: boolean
}> = ({ lineItem, refetchMaterials, packageId, disabled = false }) => {
  const [showAddMaterial, setShowAddMaterial] = useState<boolean>(false)
  const [material, setMaterial] = useState<any>()
  const { mutate: create } = useCreate()
  const [form] = Form.useForm()
  const invalidate = useInvalidate()
  const onFinish = (value: object) => {
    create(
      {
        resource: LINE_ITEM_MATERIAL_URL,
        values: {
          ...value,
          item: lineItem,
        },
      },
      {
        onSuccess: () => {
          refetchMaterials()
          form.resetFields()
          setShowAddMaterial(false)
          invalidate({
            resource: `${RFQ_URL}/${packageId}/get_pending_table`,
            invalidates: ['list'],
          })
        },
      },
    )
  }
  return (
    <>
      {showAddMaterial ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ qty: 1 }}
          onFinish={onFinish}>
          <Row gutter={[16, 16]} align="middle" style={{ width: '100%' }}>
            <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                label="Material"
                name="material"
                rules={[
                  { required: true, message: 'Please input a material!' },
                ]}
                style={{ flex: 1 }}>
                <SelectWithDefault<IMaterial>
                  popupMatchSelectWidth={false}
                  useSelectProps={{
                    resource: MATERIAL_URL,
                    filters: [
                      { field: 'is_deleted', operator: 'eq', value: 'false' },
                    ],
                    optionLabel: 'name',
                    pagination: { pageSize: 30 },
                  }}
                  renderOptions={(items) =>
                    items?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        <div
                          onClick={() => {
                            setMaterial(item)
                            form.setFieldValue('uom', item.uom)
                          }}>
                          {item.name}
                        </div>
                      </Select.Option>
                    ))
                  }
                />
              </Form.Item>
              <Button
                icon={<SaveOutlined />}
                htmlType="submit"
                style={{ marginLeft: 8 }}
              />
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  form.resetFields()
                  setShowAddMaterial(false)
                }}
                style={{ marginLeft: 8 }}
              />
            </Col>
            {material && (
              <>
                <Col span={12}>
                  <Form.Item name="qty" label="Qty">
                    <InputNumber defaultValue={1} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="uom" label="UOM">
                    <Input value={material.uom} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      ) : (
        <Button
          icon={<PlusOutlined />}
          block
          onClick={() => setShowAddMaterial(true)}
          disabled={disabled}
        />
      )}
    </>
  )
}
