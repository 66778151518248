import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IQuote } from 'interfaces/quote'
import { QuoteForm } from '.'

export const QuoteCreate: React.FC = () => {
  const useFormProps = useForm<IQuote>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <QuoteForm useFormProps={useFormProps} />
    </Create>
  )
}
