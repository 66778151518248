import { Create } from "@refinedev/antd";
import { Button } from "antd";
import { useForm } from 'components/hooks/useForm'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import { ShortlistPackageForm } from '.'

export const ShortlistPackageCreate: React.FC = () => {
  const useFormProps = useForm<IShortlistPackage>()

  return (
    <Create
      saveButtonProps={useFormProps.saveButtonProps}
      headerProps={{
        extra: [
          <Button
            key="create"
            onClick={() => window.open('/package_tool/customers/create')}>
            Create Customer
          </Button>,
        ],
      }}>
      <ShortlistPackageForm useFormProps={useFormProps} />
    </Create>
  );
}
