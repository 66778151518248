import { LayoutWrapper } from '@refinedev/core'

export const BhyvePage: React.FC = () => {
  return (
    <LayoutWrapper>
      <div style={{ width: '100%', height: '90vh' }}>
        <embed src="https://meragi.bhyve.io/" width="100%" height="100%" />
      </div>
    </LayoutWrapper>
  )
}
