import * as Icons from '@ant-design/icons'
import { useModal } from '@refinedev/antd'
import { Button, Modal } from 'antd'
import { LeadContext } from 'contexts/lead'
import React, { useContext } from 'react'
import { NewVenueRequirementForm } from './components/NewVenueRequirementForm'

export const NewVenueRequirement: React.FC<{}> = ({}) => {
  const { show, modalProps, close } = useModal({})

  const { lead } = useContext(LeadContext)

  return (
    <>
      <Button onClick={() => show()} size="small" icon={<Icons.BookOutlined />}>
        Details
      </Button>
      <Modal
        {...modalProps}
        style={{
          position: 'static',
          maxHeight: '90vh',
          overflow: 'clip',
        }}
        footer={false}
        destroyOnClose>
        {lead && <NewVenueRequirementForm leadName={lead.name} lead={lead} />}
      </Modal>
    </>
  )
}
