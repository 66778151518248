import { useCheckboxGroup } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Checkbox, Collapse, Form, Input, Radio, Space, Tabs } from "antd";
import { CrudFilters, CrudSorting } from "@refinedev/core";
import { PriceRange } from 'components/input'
import update from 'immutability-helper'
import { IFilter } from 'interfaces/attribute'
import { ICelebration } from 'interfaces/celebration'
import { IPackage } from 'interfaces/package'
import { useState } from 'react'
import { useForm as useFormSF } from 'sunflower-antd'
import { buildOptions, generateFilterName, getFilters } from 'utils/filter'

const FilterOptions = (props: {
  filter: IFilter
  saveAndSubmit: () => void
}) => {
  const [search, setSearch] = useState<string>('')
  return (
    <div>
      {props.filter?.options && props.filter.options?.length > 5 && (
        <Input
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          placeholder="Search"
          style={{ marginBottom: 5 }}
        />
      )}
      <Form.Item name={generateFilterName(props.filter)}>
        <Checkbox.Group onChange={props.saveAndSubmit}>
          {buildOptions(
            props.filter?.options?.filter((op) =>
              op.name.toLowerCase().includes(search.toLowerCase()),
            ),
          )}
        </Checkbox.Group>
      </Form.Item>
    </div>
  )
}

export const PackageFilter: React.FC<{
  setFilters: (newFilters: CrudFilters) => void
  setSorter: (sorter: CrudSorting) => void
  setCurrent: (page: number) => void
  filterData?: IFilter[]
  filterLoading?: boolean
  packageData?: IPackage[]
  onFinish?: (values: any) => void
  onUndo?: () => void
}> = ({
  setFilters,
  setSorter,
  setCurrent,
  filterData,
  filterLoading,
  packageData,
  onFinish,
  onUndo,
}) => {
  const [previousFilters, setPreviousFilters] = useState<any>([])

  const [form] = Form.useForm()

  const formSF = useFormSF({
    form: form,
  })

  const saveAndSubmit = async () => {
    setPreviousFilters(
      update(previousFilters, { $push: [form.getFieldsValue(true)] }),
    )
    form.submit()
  }

  const handleUndo = async () => {
    setPreviousFilters(
      update(previousFilters, { $splice: [[previousFilters.length - 1, 1]] }),
    )
    onUndo && onUndo()
    form.submit()
  }

  const resetAll = () => {
    setPreviousFilters([])
    setSorter([])
    setCurrent(1)
    form.resetFields()
    form.submit()
  }

  const onSubmit = async (value: any) => {
    const filterValue = { ...previousFilters[previousFilters.length - 1] }
    // get the new filter data from the stack and apply the filters
    setFilters(
      getFilters(
        [
          {
            field: 'name',
            operator: 'contains',
            value: filterValue.name,
          },
          {
            field: 'celebration',
            operator: 'in',
            value: filterValue.celebration?.join(',') ?? '',
          },
          {
            field: 'section',
            operator: 'in',
            value: filterValue.section?.join(',') ?? '',
          },
          {
            field: 'best_for',
            operator: 'in',
            value: filterValue.best_for?.join(',') ?? '',
          },
        ],
        filterValue,
        filterData,
      ),
    )
    setSorter([{ field: value.sort, order: 'asc' }])

    onFinish && onFinish(filterValue)

    // reset the form fields and then set the fields from the previousFilters stack
    formSF.form.resetFields()
    formSF.form.setFieldsValue({ ...filterValue, sort: value.sort })

    setCurrent(1)
  }

  const { checkboxGroupProps, queryResult } = useCheckboxGroup<ICelebration>({
    resource: 'package_tool/celebrations/',
    optionValue: 'id',
    optionLabel: 'name',
    fetchSize: 100,
  })

  const {
    checkboxGroupProps: sectionCheckboxGroupProps,
    queryResult: sectionQueryResult,
  } = useCheckboxGroup<ICelebration>({
    resource: 'package_tool/sections/',
    optionValue: 'id',
    optionLabel: 'name',
    fetchSize: 100,
  })

  const {
    checkboxGroupProps: bestForCheckboxGroupProps,
    queryResult: bestForQueryResult,
  } = useCheckboxGroup<ICelebration>({
    resource: 'package_tool/best_for/',
    optionValue: 'id',
    optionLabel: 'name',
    fetchSize: 100,
  })

  const OperationsSlot = {
    right: (
      <Space direction="horizontal">
        {!!previousFilters.length && (
          <Button
            onClick={handleUndo}
            size="small"
            type="primary"
            icon={<Icons.ArrowLeftOutlined />}
          />
        )}
        <Button
          onClick={resetAll}
          size="small"
          type="primary"
          icon={<Icons.RedoOutlined />}
        />
      </Space>
    ),
  }

  return (
    <Form layout="vertical" {...formSF.formProps} onFinish={onSubmit}>
      <Form.Item name="name">
        <Space size="large">
          <Input
            placeholder="Name"
            prefix={<Icons.SearchOutlined />}
            allowClear
          />
          <Button
            type="primary"
            shape="circle"
            onClick={saveAndSubmit}
            icon={<Icons.SearchOutlined />}
          />
        </Space>
      </Form.Item>
      <Tabs tabBarExtraContent={OperationsSlot}>
        <Tabs.TabPane tab="Filter" key="filter">
          <Collapse defaultActiveKey={['celebration']}>
            <Collapse.Panel header="Celebration" key="celebration">
              {queryResult.isLoading ? (
                <Icons.LoadingOutlined />
              ) : (
                <Form.Item name="celebration">
                  <Checkbox.Group
                    {...checkboxGroupProps}
                    onChange={saveAndSubmit}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Occasion" key="section">
              {sectionQueryResult.isLoading ? (
                <Icons.LoadingOutlined />
              ) : (
                <Form.Item name="section">
                  <Checkbox.Group
                    {...sectionCheckboxGroupProps}
                    onChange={saveAndSubmit}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Best For" key="bestFor">
              {bestForQueryResult.isLoading ? (
                <Icons.LoadingOutlined />
              ) : (
                <Form.Item name="best_for">
                  <Checkbox.Group
                    {...bestForCheckboxGroupProps}
                    onChange={saveAndSubmit}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Price" key="price">
              {queryResult.isLoading ? (
                <Icons.LoadingOutlined />
              ) : (
                <Form.Item name="price">
                  <PriceRange
                    max={Math.max.apply(
                      Math,
                      packageData?.map((pack) => pack.price) ?? [],
                    )}
                    min={Math.min.apply(
                      Math,
                      packageData?.map((pack) => pack.price) ?? [],
                    )}
                    onSubmit={saveAndSubmit}
                  />
                </Form.Item>
              )}
            </Collapse.Panel>
            {filterLoading ? (
              <Icons.LoadingOutlined />
            ) : (
              filterData?.map((filter) => {
                return (
                  <Collapse.Panel
                    header={filter.attribute.name}
                    key={filter.attribute.name}>
                    {filterLoading ? (
                      <Icons.LoadingOutlined />
                    ) : (
                      <FilterOptions
                        saveAndSubmit={saveAndSubmit}
                        filter={filter}
                      />
                    )}
                  </Collapse.Panel>
                )
              })
            )}
          </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sort" key="sort">
          <Collapse defaultActiveKey={['sort']}>
            <Collapse.Panel key="sort" header="Sort By">
              <Form.Item name="sort" initialValue="-create_at">
                <Radio.Group onChange={() => form.submit()}>
                  <Space direction="vertical">
                    <Radio value="-created_at">Newest</Radio>
                    <Radio value="price">Price(Low to High)</Radio>
                    <Radio value="-price">Price(High to Low)</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Tabs.TabPane>
      </Tabs>
      {!!previousFilters.length && (
        <Form.Item>
          <Button onClick={handleUndo} type="primary">
            Undo
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}
