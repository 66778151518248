import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IFoliageType } from 'interfaces/pricing'
import { FoliageTypeForm } from './Form'

export const FoliageTypeEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IFoliageType>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <FoliageTypeForm formProps={formProps} queryResult={queryResult} />
    </Edit>
  )
}
