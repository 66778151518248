import { BaseRecord, HttpError, UseSelectProps } from '@refinedev/core'
import { Select, SelectProps } from 'antd'
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import unionBy from 'lodash/unionBy'

export const SelectWithDefault = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>(
  props: SelectProps & {
    useSelectProps: UseSelectProps<TData, TData, TError>
  } & {
    allowOnlyCreate?: boolean
    renderOptions?: (item?: TData[]) => any
    defaultQueryParam?: string
  },
): any => {
  const useSelectPropsReturn = useSelectWithSearch<TData, TError>({
    ...props.useSelectProps,
    defaultValue: !!props.defaultQueryParam
      ? `${props.value ?? props.defaultValue}/${props.defaultQueryParam}`
      : props.value ?? props.defaultValue,
    defaultValueQueryOptions: {
      enabled: !!props.value || !!props.defaultValue,
    },
  })
  const {
    allowOnlyCreate = false,
    renderOptions,
    useSelectProps,
    ...restProps
  } = props

  return !!renderOptions ? (
    <Select
      style={{ width: '100%' }}
      {...restProps}
      {...useSelectPropsReturn.selectProps}
      options={undefined}
      disabled={(allowOnlyCreate && !!restProps.value) || restProps.disabled}>
      {renderOptions(
        unionBy(
          useSelectPropsReturn.queryResult.data?.data,
          useSelectPropsReturn.defaultValueQueryResult.data?.data,
          'id',
        ),
      )}
    </Select>
  ) : (
    <Select
      style={{ width: '100%' }}
      {...restProps}
      {...useSelectPropsReturn.selectProps}
      disabled={(allowOnlyCreate && !!restProps.value) || restProps.disabled}
    />
  )
}
