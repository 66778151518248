import { Comment } from '@ant-design/compatible'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useSimpleList } from '@refinedev/antd'
import { BaseKey, useCreate, useModal } from '@refinedev/core'
import {
  List as AntdList,
  Badge,
  Button,
  Form,
  Modal,
  Space,
  Typography,
} from 'antd'
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import relativeTime from 'antd/node_modules/dayjs/plugin/relativeTime'
import parse from 'html-react-parser'
import { IPackageDetailsNote } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { SHORTLIST_PACKAGE_DETAILS_NOTE_URL } from 'urls'

dayjs.extend(relativeTime)

export const NotesInput = ({
  packageDetailId,
  count = 0,
  withLabel = false,
}: {
  packageDetailId?: BaseKey
  count?: number
  withLabel?: boolean
}) => {
  const [value, setValue] = useState('')

  const { show, visible, close } = useModal()

  const { listProps, queryResult: notes } = useSimpleList<IPackageDetailsNote>({
    resource: SHORTLIST_PACKAGE_DETAILS_NOTE_URL,
    filters: {
      permanent: [
        { field: 'package_details', operator: 'eq', value: packageDetailId },
        { field: 'expand', operator: 'eq', value: 'user' },
      ],
    },
    sorters: { permanent: [{ field: 'created_at', order: 'desc' }] },
    queryOptions: {
      enabled: visible,
    },
  })

  useEffect(() => {
    if (visible) notes.refetch()
  }, [visible])

  const { mutate: create, isLoading } = useCreate()

  const finalNotes = notes?.data
  return (
    <>
      <Space>
        <Badge count={notes.data?.total ?? count} size="small">
          <Button
            icon={<FontAwesomeIcon icon={['far', 'sticky-note']} />}
            onClick={show}>
            Notes
          </Button>
        </Badge>
        {!!finalNotes?.length && (
          <span>
            Last note was{' '}
            <Typography.Text strong>
              <DateFieldFromNow
                value={finalNotes[finalNotes.length - 1]?.created_at}
              />
            </Typography.Text>
          </span>
        )}
      </Space>
      <Modal
        onCancel={close}
        open={visible}
        title={`Notes (${notes?.data?.total})`}
        footer={null}>
        <AntdList
          {...listProps}
          className="comment-list"
          itemLayout="horizontal"
          locale={{ emptyText: 'No notes' }}
          renderItem={(item) => (
            <li>
              <Comment
                author={
                  !!item.user
                    ? `${(item.user as IStaff).first_name} ${
                        (item.user as IStaff).last_name
                      }`
                    : ''
                }
                content={parse(item.text || '')}
                datetime={<DateFieldFromNow value={item.created_at} />}
              />
            </li>
          )}
        />
        <Comment
          author={'New Note'}
          content={
            <>
              <Form.Item>
                <ReactQuill
                  theme="snow"
                  defaultValue={value ?? ' '}
                  value={value ?? ' '}
                  onChange={setValue}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  onClick={() => {
                    create(
                      {
                        resource: SHORTLIST_PACKAGE_DETAILS_NOTE_URL,
                        values: {
                          package_details: packageDetailId,
                          text: value,
                        },
                      },
                      {
                        onSuccess: () => {
                          setValue('')
                          notes.refetch()
                        },
                      },
                    )
                  }}
                  disabled={!value}
                  type="primary">
                  Add Note
                </Button>
              </Form.Item>
            </>
          }
        />
      </Modal>
    </>
  )
}
