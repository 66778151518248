import React, { useState } from 'react';
import { Button, Input, InputNumber, DatePicker } from 'antd';
import * as Icons from '@ant-design/icons';
import { BaseKey, useUpdate } from '@refinedev/core';
import dayjs from 'dayjs';

interface EditableCellProps {
  value: any;
  inputType: 'text' | 'number' | 'date';
  url: string;
  recordId: BaseKey;
  field: string;
  onSuccess?: () => void;
  dateTimeFormat?: string;  // Optional date-time format
}

export const EditableCell: React.FC<EditableCellProps> = ({
  value,
  inputType,
  url,
  recordId,
  field,
  onSuccess,
  dateTimeFormat = 'YYYY-MM-DD HH:mm:ss',  // Default format if not provided
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value);
  const { mutate: updateMutate } = useUpdate();

  const handleSave = () => {
    updateMutate(
      {
        resource: url,
        id: recordId,
        values: {
          [field]: inputValue,
        },
      },
      {
        onSuccess: () => {
          setEditing(false);
          onSuccess && onSuccess();
        },
      },
    );
  };

  const renderInput = () => {
    switch (inputType) {
      case 'number':
        return (
          <InputNumber
            value={inputValue}
            onChange={(value) => setInputValue(value)}
            formatter={(value) => `₹${value}`}
            parser={(value) => value?.replace('₹', '') as any}
          />
        );
      case 'date':
        return (
          <DatePicker
            showTime
            value={inputValue ? dayjs(inputValue, dateTimeFormat) : null}
            format={dateTimeFormat}
            onChange={(date) => setInputValue(date ? date.format(dateTimeFormat) : '')}
          />
        );
      default:
        return (
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        );
    }
  };

  return editing ? (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {renderInput()}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          type="primary"
          icon={<Icons.SaveOutlined />}
          onClick={handleSave}
          style={{ marginLeft: 8 }}
        />
        <Button
          icon={<Icons.CloseOutlined />}
          onClick={() => setEditing(false)}
          style={{ marginLeft: 8 }}
        />
      </div>
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>
        {inputType === 'number' 
          ? `₹${value?.toLocaleString('en-IN')}`
          : inputType === 'date' && dateTimeFormat && value 
            ? dayjs(value).format(dateTimeFormat)
            : value
        }
      </span>
      <Button
        icon={<Icons.EditOutlined />}
        onClick={() => {
          setEditing(true);
          setInputValue(value);
        }}
        style={{ marginLeft: 8 }}
      />
    </div>
  );
};