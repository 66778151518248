import { Authenticated } from "@refinedev/core";
import { EventCreate } from './Create'
import { EventEdit } from './Edit'
import { EventShow } from './Show'

export { EventCreate } from './Create'
export { EventEdit } from './Edit'
export { EventForm } from './Form'
export { EventList } from './List'
export { EventShow } from './Show'

export const AuthEventCreate = () => (
  <Authenticated>
    <EventCreate />
  </Authenticated>
)

export const AuthEventEdit = () => (
  <Authenticated>
    <EventEdit />
  </Authenticated>
)

export const AuthEventShow = () => (
  <Authenticated>
    <EventShow />
  </Authenticated>
)