import { Avatar, Card, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { IKeyLocation, LocationType } from 'interfaces/venue'
import React from 'react'
import { FaBuilding } from 'react-icons/fa'
import { IoAirplane, IoLocation, IoTrain } from 'react-icons/io5'
const { Text } = Typography

const getKeyLocationIcon = (locationType: LocationType) => {
  switch (locationType) {
    case LocationType.AIRPORT:
      return <IoAirplane />
    case LocationType.RAILWAY_STATION:
      return <IoTrain />
    case LocationType.CITY:
      return <FaBuilding />
    default:
      return <FaBuilding />
  }
}

export const cleanTimeAndDistance = (time?: number, distance?: number) => {
  if (time) {
    return `${time} mins`
  }
  if (distance) {
    return `${distance} m`
  }
  return ''
}

export const VenueAddressCard: React.FC<{
  line_1?: string
  line_2?: string
  city?: string
  state?: string
  pincode?: string
}> = ({ line_1, line_2, city, pincode, state }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
      }}>
      <Card
        bordered={false}
        size="small"
        style={{
          padding: '5px',
          boxShadow: 'none',
          width: '100%',
          backgroundColor: '#fffaf0',
          marginBottom: '12px',
          border: '1px solid #f68e24'
        }}>
        <Meta
          title="Address"
          style={{ marginBottom: '4px' }}
          avatar={
            <Avatar
              size="small"
              style={{ backgroundColor: '#fffaf0', color: '#000' }}
              icon={<IoLocation />}
            />
          }
        />
        {line_1 && <Text>{line_1},</Text>}
        {line_2 && <Text>{line_2},</Text>}
        {(city || state) && <Text>{`${city}, ${state}`}</Text>}
        {pincode && <Text> - {pincode}</Text>}
      </Card>
    </div>
  )
}

export const VenueKeyLocationCard: React.FC<{ keyLocation: IKeyLocation }> = ({
  keyLocation,
}) => {
  return (
    <Card
      key={keyLocation.id}
      size="small"
      style={{
        padding: '5px',
        boxShadow: 'none',
        backgroundColor: '#fffaf0',
        marginBottom: '12px',
        border: '1px solid #f68e24'
      }}
      bordered={false}>
      <Meta
        title={keyLocation.location_name}
        style={{ marginBottom: '4px' }}
        avatar={
          <Avatar
            size="small"
            style={{ backgroundColor: '#fffaf0', color: '#000' }}
            icon={getKeyLocationIcon(keyLocation.location_type)}
          />
        }
      />
      {cleanTimeAndDistance(keyLocation.time, keyLocation.distance)} away from
      the {keyLocation.location_name}
    </Card>
  )
}
