import { Edit } from "@refinedev/antd";
import { CheckAccess } from 'components/checkAccess'
import { useForm } from 'components/hooks/useForm'
import { IGroup } from 'interfaces/permission'
import { GroupForm } from './Form'

export const GroupEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IGroup>()

  return (
    <CheckAccess resource="write_staff">
      <Edit saveButtonProps={saveButtonProps}>
        <GroupForm formProps={formProps} />
      </Edit>
    </CheckAccess>
  )
}
