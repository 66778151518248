import { Edit } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ITag } from 'interfaces/attribute'
import { TagForm } from './Form'

export const TagEdit: React.FC = () => {
  const useFormProps = useForm<ITag>()

  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      <TagForm useFormProps={useFormProps} />
    </Edit>
  )
}
