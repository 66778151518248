import { Checkbox, Space } from "antd";
import { CrudFilters } from "@refinedev/core";
import { TreeDataNode } from 'antd'
import { IAttributeOption, IFilter } from 'interfaces/attribute'
import { IStructuredCategory } from 'interfaces/category'
import qs from 'qs'

/**
 * Build the category tree nodes, add venue nodes at the end
 * @param categories Structured categories
 */
export const buildNodes = (
  categories: IStructuredCategory[],
): TreeDataNode[] => {
  let finalNodes = []
  for (const cat of categories) {
    if (!cat.hide_from_filter) {
      const temp: TreeDataNode = {
        key: String(cat.id),
        title: cat.name,
        isLeaf: !cat.children,
        children: cat.children ? buildNodes(cat.children) : undefined,
      }
      finalNodes.push(temp)
    }
  }
  return finalNodes
}

/**
 * Build the checkbox options required for filters
 * In case of Color add the color value before the label
 */
export const buildOptions = (options?: IAttributeOption[]) =>
  options?.map((option) => (
    <Checkbox key={option.id} value={option.id} style={{ marginLeft: 0 }}>
      <Space>
        {option.value && (
          <div
            style={{ width: 10, height: 10, background: option.value }}></div>
        )}
        {option.name}
      </Space>
    </Checkbox>
  ))

/**
 * Generate the names of all the filters based on the attribute
 */
export const generateFilterName = (filter: IFilter): string =>
  `generated_${filter.attribute.id}`

export const generateFilterQuery = (
  url: string,
  filters?: CrudFilters,
  enabled?: boolean,
): any => {
  return {
    url,
    method: 'get',
    config: {
      filters: filters,
    },
    queryOptions: {
      enabled,
    },
  }
}

/**
 * Generates a list of filters for a CRUD operation, including default filters, filters based on URL parameters, and filters based on additional data.
 *
 * @param {CrudFilters} defaultFilters - The default filters to apply.
 * @param {any} params - The URL parameters to use to generate filters.
 * @param {IFilter[]} [filterData] - Additional filter data to use to generate filters.
 * @return {CrudFilters} The list of generated filters.
 */
export const getFilters = (
  defaultFilters: CrudFilters,
  params: any,
  filterData?: IFilter[],
) => {
  const filters: CrudFilters = defaultFilters.concat([
    {
      field: 'price__range',
      operator: 'eq',
      value: params.price?.join(',') ?? '',
    },
  ])
  if (Array.isArray(filterData))
    filterData.forEach((filter) => {
      const filterName = generateFilterName(filter)
      if (filterName in params) {
        filters.push({
          field: filterName,
          operator: 'in',
          value: params[filterName]?.join(',') ?? '',
        })
      }
    })
  else if (params && typeof params === 'object')
    Object.entries(params).forEach(([key, value]: any) => {
      filters.push({
        field: key,
        operator: 'in',
        value: value?.join(',') ?? '',
      })
    })

  return filters
}

/**
 * Returns the initial value for the search form given an array of filters and an optional formatter.
 *
 * @param {CrudFilters} [filters] - An array of filters.
 * @param {any} [formatter] - An optional formatter object.
 * @return {object} The initial value object for the search form.
 */
export const getSearchFormInitialValue = (
  filters?: CrudFilters,
  formatter?: any,
) => {
  const values: { [key: string]: any } = {}

  if (!filters) return values
  filters.forEach((filter) => {
    let value: any = filter.value

    if (
      (filter.operator === 'between' || filter.operator === 'in') &&
      value.length
    ) {
      value = qs.parse(`filter=${value}`, {
        comma: true,
        charset: 'iso-8859-1',
      }).filter
      if (!Array.isArray(value)) value = [value]
    }
    if (
      (value !== undefined || value !== null) &&
      typeof value === 'string' &&
      !!value.length &&
      !isNaN(value as any)
    )
      value = Number(value)

    if ('field' in filter) {
      if (formatter && formatter.hasOwnProperty(filter.field))
        value = formatter[filter.field](value)

      values[filter.field] = value
    }
  })
  return values
}
