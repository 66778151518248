import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks'
import { ILead } from 'interfaces/crm'
import { LeadForm } from './Form'

export const LeadEdit: React.FC = () => {
  const useFormProps = useForm<ILead>({ redirect: 'edit' })
  return (
    <Edit saveButtonProps={useFormProps.saveButtonProps}>
      <LeadForm
        formProps={useFormProps.formProps}
        id={useFormProps.id}
        data={useFormProps.queryResult?.data?.data}
      />
    </Edit>
  )
}
