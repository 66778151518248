import { Edit, ListButton, RefreshButton, useModalForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Form, Input, Modal, Select } from "antd";
import { useCan } from "@refinedev/core";
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import { AutoGeneratedPasswordInput } from 'components/input'
import { IVendor } from 'interfaces/vendor'
import { useState } from 'react'
import { generatePassword } from 'utils/common'
import { VendorForm } from './Form'

export const VendorEdit: React.FC = () => {
  const { formProps, saveButtonProps, id, queryResult } = useForm<IVendor>()

  const [generatedPswd, setPassword] = useState<string>('')
  const handlePassword = () => setPassword(generatePassword())

  const {
    formProps: addMemberFormProps,
    modalProps,
    show,
  } = useModalForm({
    resource: `core/vendors/${id as string}/add_member`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => queryResult?.refetch(),
  })

  const {
    formProps: addAccountFormProps,
    modalProps: accountModalProps,
    show: accountShow,
  } = useModalForm({
    resource: `core/vendors/${id as string}/add_account`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => queryResult?.refetch(),
  })

  const { data: addAccount } = useCan({
    resource: 'can_add_beneficiary',
    action: '',
  })

  const accountType = Form.useWatch('account_type', addAccountFormProps.form)

  const pageHeaderProps = {
    extra: [
      <LogButton key="log" />,
      <Button
        key={'member'}
        onClick={() => show()}
        icon={<Icons.UserAddOutlined />}>
        Add Member
      </Button>,
      addAccount?.can && (
        <Button
          key={'account'}
          onClick={() => accountShow()}
          icon={<Icons.UserAddOutlined />}>
          Add Account
        </Button>
      ),
      <ListButton key={'list'} />,
      <RefreshButton key="refresh" />,
    ],
  }

  return (
    <Edit saveButtonProps={saveButtonProps} headerProps={pageHeaderProps}>
      {!!id && <>Contact ID: {queryResult?.data?.data?.contact_id}</>}
      <VendorForm formProps={formProps} queryResult={queryResult} id={id} />
      <Modal {...modalProps} destroyOnClose>
        <Form
          {...addMemberFormProps}
          layout="vertical"
          onFinish={(values) => {
            return (
              addMemberFormProps.onFinish &&
              addMemberFormProps.onFinish({
                ...values,
                password: generatedPswd,
              })
            )
          }}>
          <Form.Item
            name="username"
            label="Phone Number"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="first_name" label="First Name">
            <Input />
          </Form.Item>
          <Form.Item name="last_name" label="Last Name">
            <Input />
          </Form.Item>
          <AutoGeneratedPasswordInput
            label="Password"
            name="password"
            value={generatedPswd}
            onChange={setPassword}
            onClick={handlePassword}
          />
        </Form>
      </Modal>
      <Modal {...accountModalProps} destroyOnClose>
        <Form {...addAccountFormProps} layout="vertical">
          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[{ required: true }]}>
            <Select
              options={[
                { label: 'VPA', value: 'vpa' },
                { label: 'Bank Account', value: 'bank_account' },
              ]}
            />
          </Form.Item>
          {accountType === 'vpa' && (
            <Form.Item
              name="vpa_address"
              label="VPA Address"
              rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          )}
          {accountType === 'bank_account' && (
            <>
              <Form.Item
                name="account_name"
                label="Name"
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="account_number"
                label="Account Number"
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="ifsc" label="IFSC" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </Edit>
  );
}
