import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { ErrorComponent, notificationProvider } from '@refinedev/antd'
import { HttpError, Refine } from '@refinedev/core'
import routerProvider from '@refinedev/react-router-v6/legacy'
import { ConfigProvider } from 'antd'

import '@meragi/meragi-design/lib/bundle.css'
import 'App.less'
import { FlutterView } from 'FlutterView'
import { accessControlProvider } from 'accessProvider'
import 'antd/dist/reset.css'
import { REGION_KEY, authProvider } from 'authProvider'
import axios from 'axios'
import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider,
  Title,
} from 'components/layout'
import drfDataProvider, { ExtendedDataContext } from 'drfDataProvider'
import { ForgotPassword } from 'pages/ForgotPassword'
import { AttributeCreate, AttributeEdit, AttributeList } from 'pages/attribute'
import { BhyvePage } from 'pages/bhyve'
import { CategoryCreate, CategoryEdit, CategoryList } from 'pages/category'
import { CourseList } from 'pages/catering/course/List'
import { CuisineList } from 'pages/catering/cuisine/List'
import { DishCreate } from 'pages/catering/dish/Create'
import { DishEdit } from 'pages/catering/dish/Edit'
import { DishList } from 'pages/catering/dish/List'
import { MenuList } from 'pages/catering/menu/List'
import { MenuShow } from 'pages/catering/menu/Show'
import { SurchargeCreate } from 'pages/catering/surcharge/Create'
import { SurchargeEdit } from 'pages/catering/surcharge/Edit'
import { SurchargeList } from 'pages/catering/surcharge/List'
import { LeadEdit, LeadList } from 'pages/crm/lead'
import { LeadCreate } from 'pages/crm/lead/Create'
import { BookingsReport } from 'pages/crm/reports/Bookings'
import { DailyLead } from 'pages/crm/reports/DailyLead'
import { PipelineReport } from 'pages/crm/reports/Pipeline'
import { PipelineDetailedReport } from 'pages/crm/reports/PipelineDetailed'
import { SalesTargetReport } from 'pages/crm/reports/SalesTarget'
import { SourceReport } from 'pages/crm/reports/Source'
import { ServiceCreate, ServiceEdit, ServiceList } from 'pages/crm/service'
import { CustomerCreate, CustomerEdit, CustomerList } from 'pages/customer'
import { CustomerShow } from 'pages/customer/Show'
import {
  AuthEventCreate,
  AuthEventEdit,
  AuthEventShow,
} from 'pages/customer/event'
import { Dashboard } from 'pages/dashboard'
import { EditSettings } from 'pages/globalSettings/Edit'
import { GroupCreate, GroupEdit, GroupList } from 'pages/group'
import { Login } from 'pages/login'
import { LogsList } from 'pages/logs'
import { MaterialsCreate, MaterialsEdit, MaterialsList } from 'pages/materials'
import MaterialAtp from 'pages/materials/MaterialAtp'
import {
  BestForCreate,
  BestForEdit,
  BestForList,
} from 'pages/package_tool/bestFor'
import {
  CelebrationCreate,
  CelebrationEdit,
  CelebrationList,
} from 'pages/package_tool/celebration'
import { AuthenticatedMoodBoardView } from 'pages/package_tool/misc/MoodBoardView'
import {
  PackageCreate,
  PackageEdit,
  PackageList,
} from 'pages/package_tool/package'
import {
  AuthPackageSectionCreate,
  AuthPackageSectionEdit,
} from 'pages/package_tool/package/section'
import { PackageGeneration } from 'pages/package_tool/package_generation'
import {
  CreatePackage,
  MoodBoardImageCreate,
  MoodBoardImageEdit,
  MoodBoardImageList,
} from 'pages/package_tool/package_generation/MoodBoardImage'
import { PaymentList } from 'pages/package_tool/payments'
import { PaymentShow } from 'pages/package_tool/payments/Show'
import { PayoutList, PayoutShow } from 'pages/package_tool/payouts'
import { PortfolioPage } from 'pages/package_tool/portfolio'
import { AssignSourceShow, RFQList, RFQShow } from 'pages/package_tool/rfq'
import { Reports as RFQReports } from 'pages/package_tool/rfq/Reports'
import { QuoteCreate, QuoteEdit } from 'pages/package_tool/rfq/quotes'
import {
  SectionCreate,
  SectionEdit,
  SectionList,
} from 'pages/package_tool/section'
import {
  ShortlistPackageCreate,
  ShortlistPackageEdit,
  ShortlistPackageList,
} from 'pages/package_tool/shortlistPackage'
import { ProjectList } from 'pages/package_tool/shortlistPackage/project'
import {
  AuthShortlistPackageSectionCreate,
  AuthShortlistPackageSectionEdit,
} from 'pages/package_tool/shortlistPackage/section'
import { VenueCreate, VenueEdit, VenueList } from 'pages/package_tool/venue'
import { VenueShowcase } from 'pages/package_tool/venue/Showcase'
import { VenueShowcaseDetail } from 'pages/package_tool/venue/ShowcaseDetail'
import { VenueBot } from 'pages/package_tool/venue/VenueBot'
import { AvailabilityPage } from 'pages/package_tool/venue/availability'
import {
  SmartPhotoCreate,
  SmartPhotoEdit,
  SmartPhotoList,
} from 'pages/package_tool/venue/smartPhoto'
import {
  LayerCreate,
  LayerEdit,
  LayerList,
} from 'pages/package_tool/venue/smartPhoto/layers'
import {
  ItemCreate,
  ItemEdit,
  ItemList,
} from 'pages/package_tool/venue/smartPhoto/layers/items'
import {
  PaymentTypeCreate,
  PaymentTypeEdit,
  PaymentTypeList,
} from 'pages/paymentType'
import {
  PayoutRuleCreate,
  PayoutRuleEdit,
  PayoutRuleList,
} from 'pages/payoutRule'
import { PricingPage } from 'pages/pricing'
import { ArtificialRatioList } from 'pages/pricing/artificialRatio/List'
import {
  BreakdownCreate,
  BreakdownEdit,
  BreakdownList,
} from 'pages/pricing/breakdown'
import { ColorList } from 'pages/pricing/color/List'
import { CombinationCreate } from 'pages/pricing/combination/Create'
import { CombinationEdit } from 'pages/pricing/combination/Edit'
import { CombinationList } from 'pages/pricing/combination/List'
import { FabricTypeCreate, FabricTypeEdit } from 'pages/pricing/fabricType'
import { FabricTypeList } from 'pages/pricing/fabricType/List'
import { FlowerCreate, FlowerEdit, FlowerList } from 'pages/pricing/flower'
import { FlowerTypeList } from 'pages/pricing/flowerType/List'
import { FoliageTypeCreate, FoliageTypeEdit } from 'pages/pricing/foliageType'
import { FoliageTypeList } from 'pages/pricing/foliageType/List'
import {
  InterfaceCreate,
  InterfaceEdit,
  InterfaceList,
} from 'pages/pricing/interface'
import {
  PricingItemCreate,
  PricingItemEdit,
  PricingItemList,
} from 'pages/pricing/item'
import { PricingToolPage } from 'pages/pricing_tool'
import { ProductCreate, ProductEdit, ProductList } from 'pages/product'
import { AuthenticatedCardList } from 'pages/product/cardList'
import { CateringCardList } from 'pages/catering/cardList'
import { PurchaseOrderCreate } from 'pages/purchaseOrder/Create'
import { PurchaseOrderShow } from 'pages/purchaseOrder/Show'
import { RegionCreate, RegionEdit, RegionList } from 'pages/region'
import { SalesTarget } from 'pages/sales/SalesTarget'
import { StaffCreate, StaffEdit, StaffList } from 'pages/staff'
import { TagCreate, TagEdit, TagList } from 'pages/tag'
import { VendorCreate, VendorEdit, VendorList } from 'pages/vendor'
import { WarehouseCreate, WarehouseEdit, WarehouseList } from 'pages/warehouse'
import React, { createContext, useContext, useEffect } from 'react'
import 'react-checkbox-tree/src/less/react-checkbox-tree.less'
import { createRoute } from 'utils/common'
import {
  ARTIFICIAL_RATIO_URL,
  ATTRIBUTE_URL,
  BEST_FOR_URL,
  BOOKING_REPORT_URL,
  BREAKDOWN_URL,
  CATEGORY_URL,
  CELEBRATION_URL,
  CHATS_URL,
  COLOR_URL,
  COMBINATION_URL,
  COURSE_URL,
  CUISINE_URL,
  CUSTOMER_URL,
  DAILY_LEAD_URL,
  DISH_URL,
  FABRIC_TYPE_URL,
  FLOWER_TYPE_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
  GROUP_URL,
  INTERFACE_URL,
  LEAD_URL,
  LOG_URL,
  MATERIAL_SOURCE_URL,
  MATERIAL_URL,
  MAX_MATERIAL_SOURCE,
  MENU_URL,
  MOODBOARDIMAGES_URL,
  PACKAGE_URL,
  PAYMENT_TYPE_URL,
  PAYMENT_URL,
  PAYOUT_RULE_URL,
  PAYOUT_URL,
  PIPELINE_DETAILED_REPORT_URL,
  PIPELINE_REPORT_URL,
  PLAN_URL,
  PRICING_ITEM_URL,
  PRODUCT_URL,
  PROJECT_URL,
  PURCHASE_ORDER_URL,
  QUOTE_URL,
  REGION_URL,
  RFQ_URL,
  SALES_TARGET_URL,
  SALES_TARGET_VIEW_URL,
  SECTION_URL,
  SERVICE_URL,
  SETTINGS_URL,
  SHORTLIST_PACKAGE_URL,
  SOURCE_REPORT_URL,
  STAFF_URL,
  SURCHARGE_URL,
  TAG_URL,
  VENDOR_URL,
  VENUE_URL,
  BOM_URL,
  WAREHOUSE_URL,
  TICKETS_URL,
} from './urls'
import { VenueCardList } from 'pages/package_tool/venue/cardList'
import { VenueDetail } from 'pages/package_tool/venue/cardList/venueDetail'
import { PlanCreate, PlanEdit, PlanList } from 'pages/plan'
import MilestoneManager from 'pages/package_tool/shortlistPackage/milestone/Milestone'
import RedirectToFeed from 'RedirectToFeed'

library.add(fas as any, far as any)

const axiosInstance = axios.create()
const authenticationProvider = authProvider(axiosInstance)
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message:
        error.response?.data?.message ||
        error.response?.data?.detail ||
        JSON.stringify(error.response?.data),
      statusCode: error.response?.status,
    }
    if (error.response?.status === 401) {
      authenticationProvider.logout({})
      window.location.reload()
    }

    return Promise.reject(customError)
  },
)

const API_URL = process.env.REACT_APP_API_URL
const EXTERNAL_API_URL = process.env.REACT_APP_EXTERNAL_API_URL
const dataProvider = {
  default: drfDataProvider(API_URL as string, axiosInstance),
  external: drfDataProvider(EXTERNAL_API_URL as string, axiosInstance),
}

export const DataContext = React.createContext<ExtendedDataContext>(
  drfDataProvider(API_URL as string, axiosInstance) as ExtendedDataContext,
)

const HttpClientContext = createContext(axiosInstance)
export const useHttpClient = () => useContext(HttpClientContext)

function App() {
  useEffect(() => {
    axiosInstance.interceptors.request.use((config: any) => {
      const region = localStorage.getItem(REGION_KEY)
      if (config.headers) {
        config.headers['X-Region'] = region || 1
        config.headers['X-Source'] = 'studio_web'
      }
      return config
    })
  }, [])
  return (
    <HttpClientContext.Provider value={axiosInstance}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#6633CC',
          },
          components: {
            Layout: { colorBgHeader: '' },
            Menu: {
              colorItemTextHover: '#FFCC33',
              colorSubItemBg: '#4921a6',
              colorItemBgActive: 'white',
              colorItemBgSelectedHorizontal: '#4921a6',
              colorItemTextHoverHorizontal: '#FFCC33',
              colorItemTextSelectedHorizontal: '#4921a6',
              colorItemBgHover: '#4921a6',
              colorItemTextSelected: '#FFCC33',
            },
          },
        }}>
        <Refine
          dataProvider={dataProvider}
          authProvider={authenticationProvider}
          accessControlProvider={accessControlProvider}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          DashboardPage={RedirectToFeed}
          LoginPage={Login}
          options={{
            reactQuery: {
              devtoolConfig: false,
            },
            disableTelemetry: true,
          }}
          legacyRouterProvider={{
            ...routerProvider,
            routes: [
              {
                exact: true,
                element: <BhyvePage />,
                path: '/bhyve',
              },
              {
                exact: true,
                element: <ForgotPassword />,
                path: '/forgot-password',
              },
              {
                exact: true,
                element: <AuthenticatedCardList />,
                path: '/cms/product-cards',
              },
              {
                exact: true,
                element: <CateringCardList />,
                path: '/cms/catering-showcase',
              },
              {
                exact: true,
                element: <VenueCardList />,
                path: '/cms/venue-showcase',
              },
              {
                exact: true,
                element: <VenueDetail />,
                path: '/cms/venue-showcase/detail/:id',
              },
              {
                exact: true,
                element: <AvailabilityPage />,
                path: '/cms/venue-availability',
              },
              {
                exact: true,
                element: <PipelineReport />,
                path: '/' + PIPELINE_REPORT_URL,
              },
              {
                exact: true,
                element: <PipelineDetailedReport />,
                path: '/' + PIPELINE_DETAILED_REPORT_URL,
              },
              {
                exact: true,
                element: <SourceReport />,
                path: '/' + SOURCE_REPORT_URL,
              },
              {
                exact: true,
                element: <DailyLead />,
                path: '/' + DAILY_LEAD_URL,
              },
              {
                exact: true,
                element: <BookingsReport />,
                path: '/' + BOOKING_REPORT_URL,
              },
              {
                exact: true,
                element: <SalesTargetReport />,
                path: '/' + SALES_TARGET_URL,
              },
              {
                exact: true,
                element: <SalesTarget />,
                path: '/' + SALES_TARGET_VIEW_URL,
              },
              {
                exact: true,
                element: <AuthPackageSectionCreate />,
                path: '/package_tool/packages/edit/:package_id/sections/create',
              },
              {
                exact: true,
                element: <AuthPackageSectionEdit />,
                path: '/package_tool/packages/edit/:package_id/sections/edit/:id',
              },
              {
                exact: true,
                element: <AuthEventCreate />,
                path: '/package_tool/customers/edit/:customer_id/events/create',
              },
              {
                exact: true,
                element: <AuthEventEdit />,
                path: '/package_tool/events/edit/:id',
              },
              {
                exact: true,
                element: <AuthEventShow />,
                path: '/package_tool/events/show/:id',
              },
              {
                exact: true,
                element: <AuthShortlistPackageSectionCreate />,
                path: '/package_tool/shortlist_packages/edit/:package_id/sections/create',
              },
              {
                exact: true,
                element: <AuthShortlistPackageSectionEdit />,
                path: '/package_tool/shortlist_packages/edit/:package_id/sections/edit/:id',
              },
              {
                exact: true,
                element: <AuthenticatedMoodBoardView />,
                path: '/package_tool/moodboard/:id',
              },
              {
                exact: true,
                element: <PricingPage />,
                path: '/cms/pricing',
              },
              {
                exact: true,
                element: <PricingToolPage />,
                path: '/cms/pricing_v2',
              },
              {
                exact: true,
                element: <PortfolioPage />,
                path: '/package_tool/portfolio',
              },
              {
                exact: true,
                element: <RFQReports />,
                path: `${RFQ_URL}/reports/:id`,
              },
              {
                exact: true,
                element: <MilestoneManager />,
                path: 'milestone',
              },
              {
                exact: true,
                element: <PackageGeneration />,
                path: '/generate-package',
              },
              {
                exact: true,
                element: <CreatePackage />,
                path: '/generate-package-v2',
              },
              {
                exact: true,
                element: <MaterialAtp />,
                path: '/material-atp',
              },
              {
                exact: true,
                element: <VenueBot />,
                path: '/venue-bot',
              },
              {
                exact: true,
                element: <AssignSourceShow />,
                path: `${MAX_MATERIAL_SOURCE}/show/:id`,
              },
              ...createRoute({
                name: 'core/smart_photos',
                list: SmartPhotoList,
                create: SmartPhotoCreate,
                edit: SmartPhotoEdit,
                options: {
                  label: 'Smart Photo',
                  route: '/package_tool/venues/:venue_id/smart_photos',
                },
              }),
              ...createRoute({
                name: 'core/layers',
                list: LayerList,
                create: LayerCreate,
                edit: LayerEdit,
                options: {
                  label: 'Layer',
                  route:
                    '/package_tool/venues/:venue_id/smart_photos/:smart_photo_id/layers',
                },
              }),
              ...createRoute({
                name: 'core/items',
                list: ItemList,
                create: ItemCreate,
                edit: ItemEdit,
                options: {
                  label: 'Layer',
                  route:
                    '/package_tool/venues/:venue_id/smart_photos/:smart_photo_id/layers/:layer_id/items',
                },
              }),
            ],
          }}
          resources={[
            {
              name: ATTRIBUTE_URL,
              list: AttributeList,
              edit: AttributeEdit,
              create: AttributeCreate,
              canDelete: true,
              meta: { label: 'Attributes' },
            },
            {
              name: CHATS_URL,
              list: FlutterView,
            },

            {
              name: BOM_URL,
              list: FlutterView,
            },
            {
              name: TICKETS_URL,
              list: FlutterView,
            },
            {
              name: 'feed',
              list: FlutterView,
            },
            {
              name: TAG_URL,
              list: TagList,
              edit: TagEdit,
              create: TagCreate,
              canDelete: true,
              meta: { label: 'Tags' },
            },
            {
              name: CATEGORY_URL,
              list: CategoryList,
              create: CategoryCreate,
              edit: CategoryEdit,
              canDelete: true,
              meta: { label: 'Category' },
            },
            {
              name: PRODUCT_URL,
              list: ProductList,
              edit: ProductEdit,
              create: ProductCreate,
              meta: { label: 'Product' },
            },
            {
              name: REGION_URL,
              list: RegionList,
              create: RegionCreate,
              edit: RegionEdit,
              meta: { label: 'Region' },
            },
            {
              name: PLAN_URL,
              list: PlanList,
              create: PlanCreate,
              edit: PlanEdit,
              meta: { label: 'Plans' },
            },
            {
              name: STAFF_URL,
              list: StaffList,
              create: StaffCreate,
              edit: StaffEdit,
              meta: { label: 'Staff' },
            },
            {
              name: VENDOR_URL,
              list: VendorList,
              create: VendorCreate,
              edit: VendorEdit,
              meta: { label: 'Vendor' },
            },
            {
              name: VENDOR_URL + '_v2',
              list: FlutterView,
              meta: { label: 'Vendor' },
            },
            {
              name: CELEBRATION_URL,
              list: CelebrationList,
              create: CelebrationCreate,
              edit: CelebrationEdit,
              meta: { label: 'Celebration' },
            },
            {
              name: SECTION_URL,
              list: SectionList,
              create: SectionCreate,
              edit: SectionEdit,
              meta: { label: 'Section' },
            },
            {
              name: PACKAGE_URL,
              list: PackageList,
              create: PackageCreate,
              edit: PackageEdit,
              meta: { label: 'Package' },
            },
            {
              name: CUSTOMER_URL,
              list: CustomerList,
              create: CustomerCreate,
              edit: CustomerEdit,
              show: CustomerShow,
              meta: { label: 'Customer' },
            },
            {
              name: SHORTLIST_PACKAGE_URL,
              list: ShortlistPackageList,
              create: ShortlistPackageCreate,
              edit: ShortlistPackageEdit,
              meta: { label: 'Shortlisted Package' },
            },
            {
              name: PROJECT_URL,
              list: ProjectList,
              meta: { label: 'Project' },
            },
            {
              name: QUOTE_URL,
              create: QuoteCreate,
              edit: QuoteEdit,
            },
            {
              name: RFQ_URL,
              list: RFQList,
              show: RFQShow,
              meta: { label: 'RFQ' },
            },
            {
              name: PAYMENT_TYPE_URL,
              list: PaymentTypeList,
              create: PaymentTypeCreate,
              edit: PaymentTypeEdit,
              meta: { label: 'Payment Type' },
            },
            {
              name: BEST_FOR_URL,
              list: BestForList,
              create: BestForCreate,
              edit: BestForEdit,
              canDelete: true,
              meta: { label: 'Best For' },
            },
            {
              name: VENUE_URL,
              list: VenueList,
              create: VenueCreate,
              edit: VenueEdit,
              canDelete: true,
              meta: { label: 'Venue' },
            },
            {
              name: PAYMENT_URL,
              list: PaymentList,
              show: PaymentShow,
              meta: { label: 'Payments' },
            },
            {
              name: PAYOUT_URL,
              list: PayoutList,
              show: PayoutShow,
              meta: { label: 'Payouts' },
            },
            {
              name: GROUP_URL,
              list: GroupList,
              create: GroupCreate,
              edit: GroupEdit,
              canDelete: true,
              meta: { label: 'Group' },
            },
            {
              name: LOG_URL,
              list: LogsList,
              meta: { label: 'Logs' },
            }, // pricing
            {
              name: COLOR_URL,
              list: ColorList,
              meta: { label: 'Colors' },
            },
            {
              name: FLOWER_TYPE_URL,
              list: FlowerTypeList,
              meta: { label: 'Flower Types' },
            },
            {
              name: FOLIAGE_TYPE_URL,
              list: FoliageTypeList,
              create: FoliageTypeCreate,
              edit: FoliageTypeEdit,
              meta: { label: 'Foliage Types' },
            },
            {
              name: FABRIC_TYPE_URL,
              list: FabricTypeList,
              edit: FabricTypeEdit,
              create: FabricTypeCreate,
              meta: { label: 'Fabric Types' },
            },
            {
              name: ARTIFICIAL_RATIO_URL,
              list: ArtificialRatioList,
              meta: { label: 'Artificial Ratio' },
            },
            {
              name: FLOWER_URL,
              list: FlowerList,
              create: FlowerCreate,
              edit: FlowerEdit,
              meta: { label: 'Flower' },
            },
            {
              name: PRICING_ITEM_URL,
              list: PricingItemList,
              create: PricingItemCreate,
              edit: PricingItemEdit,
              meta: { label: 'Items' },
            },
            {
              name: COMBINATION_URL,
              list: CombinationList,
              create: CombinationCreate,
              edit: CombinationEdit,
              meta: { label: 'Combinations' },
            },
            {
              name: INTERFACE_URL,
              list: InterfaceList,
              create: InterfaceCreate,
              edit: InterfaceEdit,
              meta: { label: 'Interface' },
            },
            {
              name: BREAKDOWN_URL,
              list: BreakdownList,
              create: BreakdownCreate,
              edit: BreakdownEdit,
              meta: { label: 'Breakdown' },
            },
            {
              name: SETTINGS_URL,
              list: EditSettings,
              meta: { label: 'Settings' },
            },
            {
              name: SERVICE_URL,
              list: ServiceList,
              create: ServiceCreate,
              edit: ServiceEdit,
              meta: { label: 'Service' },
            },
            {
              name: LEAD_URL,
              list: LeadList,
              edit: LeadEdit,
              create: LeadCreate,
              meta: { label: 'Lead' },
            },
            {
              name: PAYOUT_RULE_URL,
              list: PayoutRuleList,
              edit: PayoutRuleEdit,
              create: PayoutRuleCreate,
              meta: { label: 'Payout Rule' },
            },
            {
              name: MATERIAL_URL,
              list: MaterialsList,
              create: MaterialsCreate,
              edit: MaterialsEdit,
              meta: { label: 'Material' },
            },
            {
              name: MATERIAL_URL + '_v2',
              list: FlutterView,
              meta: { label: 'Material' },
            },
            {
              name: WAREHOUSE_URL,
              list: WarehouseList,
              create: WarehouseCreate,
              edit: WarehouseEdit,
              meta: { label: 'Warehouse' },
            },
            {
              name: MATERIAL_SOURCE_URL,
              list: FlutterView,
              meta: { label: 'Tracking' },
            },
            {
              name: MOODBOARDIMAGES_URL,
              list: MoodBoardImageList,
              edit: MoodBoardImageEdit,
              create: MoodBoardImageCreate,
              meta: { label: 'MoodBoard Images' },
            },
            {
              name: PURCHASE_ORDER_URL,
              list: FlutterView,
              create: PurchaseOrderCreate,
              show: PurchaseOrderShow,
              meta: { label: 'Purchase Orders' },
            },
            // Catering
            {
              name: CUISINE_URL,
              list: CuisineList,
              meta: { label: 'Cuisines' },
            },
            {
              name: COURSE_URL,
              list: CourseList,
              meta: { label: 'Courses' },
            },
            {
              name: DISH_URL,
              list: DishList,
              create: DishCreate,
              edit: DishEdit,
              meta: { label: 'Dishes' },
            },
            {
              name: SURCHARGE_URL,
              list: SurchargeList,
              create: SurchargeCreate,
              edit: SurchargeEdit,
              meta: { label: 'Surcharges' },
            },
            {
              name: MENU_URL,
              list: MenuList,
              show: MenuShow,
              meta: { label: 'Menus' },
            },
          ]}
          notificationProvider={notificationProvider}
          catchAll={<ErrorComponent />}></Refine>
      </ConfigProvider>
    </HttpClientContext.Provider>
  )
}

export default App
