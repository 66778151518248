import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreateButton, List, PageHeaderProps, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Space, Table, Tooltip } from "antd";
import { BaseKey, useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton, LogButton } from 'components/button'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { IVenue } from 'interfaces/venue'
import { useState } from 'react'
import { VENUE_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const VenueList: React.FC = () => {
  const tProps = useTable<IVenue, any, { search: string }>({
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: 'package_tool/venues',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps: PageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <LogButton key="log" />,
      <TableSearchInput
        searchFormProps={tProps.searchFormProps}
        key="search"
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => edit(VENUE_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
        <Table.Column<IVenue>
          dataIndex="id"
          title="Actions"
          ellipsis
          render={(value: BaseKey, record) => (
            <Space align="end" onClick={(e) => e.stopPropagation()}>
              <Tooltip title="Smart Photo">
                <Button
                  icon={<FontAwesomeIcon icon={['fas', 'image']} />}
                  size="small"
                  onClick={() =>
                    window.open(`/package_tool/venues/${value}/smart_photos`)
                  }
                />
              </Tooltip>
              <Tooltip title="Venue Portal">
                <Button
                  icon={<Icons.ShareAltOutlined />}
                  size="small"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_VENUE_URL}/${record.slug}`,
                    )
                  }
                />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
}
