import { Create, useForm } from '@refinedev/antd'
import { Form, Space } from 'antd'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IVendor } from 'interfaces/vendor'
import { VENDOR_URL, WAREHOUSE_URL } from 'urls'

export const PurchaseOrderCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm({ redirect: 'show' })
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) =>
          formProps.onFinish?.({
            ...values,
            order_date: values.order_date?.format('YYYY-MM-DD'),
            expected_delivery_date:
              values.expected_delivery_date?.format('YYYY-MM-DD'),
          })
        }>
        <Space>
          <Form.Item name="order_date" label="Order Date">
            <DatePicker format="YYYY-MM-DD" mode="date" />
          </Form.Item>
          <Form.Item
            name="expected_delivery_date"
            label="Expected Delivery Date">
            <DatePicker format="YYYY-MM-DD" mode="date" />
          </Form.Item>
        </Space>
        <Form.Item name="vendor" label="Vendor">
          <SelectWithDefault<IVendor>
            useSelectProps={{ resource: VENDOR_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item name="warehouse" label="Warehouse">
          <SelectWithDefault<IVendor>
            useSelectProps={{ resource: WAREHOUSE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
