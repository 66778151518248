import { Table } from "antd";
import { BaseKey, GetOneResponse } from "@refinedev/core";
import { PriceField } from 'components/field'
import { IVendorTotal } from 'interfaces/quote'

export const VendorTotals: React.FC<{
  vendorTotalData?: GetOneResponse<IVendorTotal[]>
  refetch: CallableFunction
  packageID?: BaseKey
  packageQueryResult: any
}> = (props) => {
  return (
    <>
      <Table
        dataSource={props.vendorTotalData ? props.vendorTotalData.data : []}
        size="small"
        bordered
        pagination={false}>
        <Table.Column<IVendorTotal>
          dataIndex={['vendor', 'name']}
          title="Vendor"
          render={(name: string, record) => (
            <>
              {name} ({record.quotes_priced}/{record.quotes_sent})
            </>
          )}
        />
        <Table.Column
          dataIndex={['total']}
          title="Total"
          render={(value) => <PriceField value={value ?? 0} />}
        />
        <Table.Column
          dataIndex={['meta', 'discount']}
          title="Discount"
          render={(value) => <PriceField value={value ?? 0} />}
        />
        <Table.Column<IVendorTotal>
          dataIndex={['meta', 'discount']}
          title="Effective Total"
          render={(value, record) => (
            <PriceField value={(record.total ?? 0) - (value ?? 0)} />
          )}
        />
      </Table>
    </>
  )
}
