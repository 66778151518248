import { useSelect } from '@refinedev/antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { Form, Input, Select, Tabs, Transfer } from 'antd'
import { file2Base64, useList } from '@refinedev/core'
import { useTransfer } from 'components/hooks/useTransfer'
import { DynamicInput } from 'components/input'
import { DraggableMultiImageUpload } from 'components/input/DraggableMultiImageUpload'
import { REQUIRES_OPTIONS } from 'consts'
import { IAttribute } from 'interfaces/attribute'
import { IBestFor } from 'interfaces/bestFor'
import { ICelebration } from 'interfaces/celebration'
import { IPackage, IPackageImage } from 'interfaces/package'
import { UseFormReturnType } from 'interfaces/refine'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const { TabPane } = Tabs

export const PackageForm: React.FC<{
  useFormProps: UseFormReturnType<IPackage>
}> = ({ useFormProps }) => {
  const { formProps, queryResult } = useFormProps

  const [selectedCelebration, setSelectedCelebration] = useState<ICelebration>()

  const [imageFileList, setImageFileList] = useState<UploadFile[]>([])

  const {
    selectProps: celebrationSelectProps,
    queryResult: celebrationQueryResult,
  } = useSelect<ICelebration>({
    resource: 'package_tool/celebrations/',
    optionLabel: 'name',
    optionValue: 'id',

    pagination: {
      mode: 'server',
    },
  })

  const attributeQueryResult = useList<IAttribute>({
    resource: 'core/attributes/',

    pagination: {
      pageSize: 100,
    },
  })

  const bestForeQueryResult = useTransfer<IBestFor>({
    resource: 'package_tool/best_for',
  })

  // build the images list in the format required by the antd form
  useEffect(() => {
    const existingFiles: UploadFile[] = []
    queryResult?.data?.data?.images.map((image: IPackageImage) =>
      existingFiles.push({
        uid: image.id,
        name: 'test.png',
        url: image.image,
        thumbUrl: image.image,
        status: 'done',
      }),
    )
    setImageFileList(existingFiles)
  }, [queryResult])

  // set the selectedCelebration once all the celebration are fetched
  // this is used to build the attributes
  useEffect(() => {
    if (celebrationQueryResult.data?.data) {
      setSelectedCelebration(
        celebrationQueryResult.data?.data.find(
          (cel) => cel.id === queryResult?.data?.data.celebration,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [celebrationQueryResult.isLoading])

  const onFinish = async (values: any) => {
    const imageFiles: { id?: string; image?: string; order: number }[] = []

    // generate a list of map with attribute id and value
    const attributesWithValue = []
    for (let key in values) {
      if (key.match(/^generated_/)) {
        const keySplit = key.split('_')
        const type = keySplit[3]
        let valueOptions: { option: any }[] = []
        if (REQUIRES_OPTIONS.includes(type)) {
          if (Array.isArray(values[key]))
            valueOptions = values[key].map((value: string) => ({
              option: value,
            }))
          else valueOptions.push({ option: values[key] })
          values[key] = null
        }
        const finalValue: { [key: string]: any } = {
          attribute: keySplit[2],
          value: String(values[key]),
          value_options: valueOptions,
        }
        if (keySplit[1] !== 'undefined') finalValue['id'] = keySplit[1]
        attributesWithValue.push(finalValue)
        delete values[key]
      }
    }

    // handle image values
    for (let i = 0; i < imageFileList?.length; i++) {
      if (imageFileList[i].originFileObj) {
        const base64String = await file2Base64(imageFileList[i])
        imageFiles.push({
          image: base64String,
          order: i,
        })
      } else {
        imageFiles.push({
          // @ts-ignore
          id: imageFileList[i].id ?? imageFileList[i].uid,
          order: i,
        })
      }
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        attributes: attributesWithValue,
        images: imageFiles,
      })
    )
  }

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Tabs>
        <TabPane tab="Basic Info" key="1">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item
            label="Celebration"
            name="celebration"
            rules={[{ required: true }]}>
            <Select
              {...celebrationSelectProps} // @ts-ignore
              onSelect={(value: string, option: any) => {
                setSelectedCelebration(
                  celebrationQueryResult.data?.data.find(
                    (cel) => cel.id === value,
                  ),
                )
              }}
            />
          </Form.Item>
          <Form.Item
            label="Best For"
            name="best_for"
            valuePropName="targetKeys">
            <Transfer {...bestForeQueryResult.transferProps} />
          </Form.Item>
        </TabPane>

        <TabPane tab="Attributes" key="2">
          {selectedCelebration?.attributes.map((attribute) => {
            const attr_details = attributeQueryResult.data?.data.find(
              (attr) => attr.id === attribute.attribute,
            )
            if (attr_details)
              return (
                <DynamicInput
                  key={queryResult?.data?.data?.id}
                  attr_details={attr_details}
                  values={queryResult?.data?.data?.attributes}
                />
              )
            return null
          })}
        </TabPane>
        <TabPane tab="Media" key="3">
          <DraggableMultiImageUpload
            label="Images"
            name="images"
            files={imageFileList}
            onChange={setImageFileList}
          />
        </TabPane>
      </Tabs>
    </Form>
  )
}
