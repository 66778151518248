import { UseFormReturnType } from "@refinedev/antd";
import { Form, Input } from "antd";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { REGION_URL } from 'urls'

export const WarehouseForm = ({
  useFormProps,
}: {
  useFormProps: UseFormReturnType
}) => {
  return (
    <Form {...useFormProps.formProps} layout="vertical">
      <Form.Item name="region" label="Region">
        <SelectWithDefault
          useSelectProps={{ resource: REGION_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="address" label="Address">
        <Input.TextArea />
      </Form.Item>
    </Form>
  )
}
