import { Button, Popconfirm } from "antd";

export const DeleteButton = ({
  onClick,
  title = "Are you sure you want to delete?",
}: {
  onClick: () => any;
  title?: string;
}) => (
  <Popconfirm title={title} onConfirm={onClick}>
    <Button danger>Delete</Button>
  </Popconfirm>
);
