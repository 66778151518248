import { CreateButton, DeleteButton, List } from "@refinedev/antd";
import { Table, TableProps } from "antd";
import { BaseKey, useDeleteMany, useNavigation } from "@refinedev/core";
import { IBasicSection } from 'interfaces/package'
import { useState } from 'react'

export const PackageSectionList: React.FC<{
  packageId?: BaseKey
  tableProps: TableProps<IBasicSection>
}> = ({ packageId, tableProps }) => {
  const { push } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate(
      {
        resource: `package_tool/packages/${packageId}/sections`,
        ids: selectedRowKeys,
      },
      {
        onSuccess: () => {
          window.location.reload()
        },
      },
    )
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton
        key="create"
        resource={`package_tool/packages/edit/${packageId}/sections`}
      />,
    ],
  }

  return (
    <List title="Sections" headerProps={pageHeaderProps}>
      <Table
        {...tableProps}
        rowKey="section__id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) =>
            push(`edit/${packageId}/sections/edit/${record.section__id}`),
        })}>
        <Table.Column<IBasicSection> dataIndex="section__name" title="Name" ellipsis/>
      </Table>
    </List>
  );
}
