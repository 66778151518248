import { Create, useForm } from "@refinedev/antd";
import { WarehouseForm } from './Form'

export const WarehouseCreate = () => {
  const useFormProps = useForm()
  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <WarehouseForm useFormProps={useFormProps} />
    </Create>
  )
}
