import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Table } from "antd";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useEffect, useState } from 'react'

function DraggableWrapper(props: any) {
  const { children, ...restProps } = props
  /**
   * 'children[1]` is `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return children[1] instanceof Array ? (
    <SortableContext
      items={children[1].map((child) => child.key)}
      strategy={verticalListSortingStrategy}
      {...restProps}>
      <tbody {...restProps}>
        {
          // This invokes `Table.components.body.row` for each element of `children`.
          children
        }
      </tbody>
    </SortableContext>
  ) : (
    <tbody {...restProps}>{children}</tbody>
  )
}

function DraggableRow(props: any) {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: props['data-row-key'],
  })
  const { children, ...restProps } = props
  /**
   * 'children[1]` is a row of `dataSource`
   * Check if `children[1]` is an array
   * because antd gives 'No Data' element when `dataSource` is an empty array
   */
  return children instanceof Array ? (
    <tr ref={setNodeRef} {...attributes} {...restProps}>
      {children.map((child) => {
        const { children, key, ...restProps } = child
        return key === 'dragHandle' ? (
          <td {...listeners} {...restProps}>
            {child}
          </td>
        ) : (
          <td {...restProps}>{child}</td>
        )
      })}
    </tr>
  ) : (
    <tr {...restProps}>{children}</tr>
  )
}

export const DraggableTable = (props: any) => {
  const [activeId, setActiveId] = useState(null)

  const {
    children,
    dataSource: dataSourceFromProps,
    onDragEnd,
    onDragStart,
    ...restProps
  } = props

  const [dataSource, setDataSource] = useState<any[]>()

  useEffect(() => {
    setDataSource(dataSourceFromProps)
  }, [dataSourceFromProps])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragStart(event: any) {
    const { active } = event
    setActiveId(active.id)
    onDragStart && onDragStart()
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      setDataSource((items: any) => {
        // In this example, find an item, where `item.key` === `useSortable.id`.
        const oldIndex = items.findIndex((item: any) => item.id === active.id)
        const newIndex = items.findIndex((item: any) => item.id === over.id)
        const newItems = arrayMove(items, oldIndex, newIndex)
        onDragEnd && onDragEnd(newItems)
        return newItems
      })
    }
    // Stop overlay.
    setActiveId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}>
      <Table
        {...restProps}
        dataSource={dataSource}
        components={{
          body: {
            wrapper: DraggableWrapper,
            row: DraggableRow,
          },
        }}>
        <Table.Column key="dragHandle" render={() => <Icons.MenuOutlined />} />
        {children}
      </Table>
      {/* Render overlay component. */}
      {dataSource && (
        <DragOverlay>
          {activeId ? (
            <Table
              pagination={false}
              dataSource={[
                dataSource.find((data: any) => data.id === activeId),
              ]}
              showHeader={false}>
              <Table.Column
                key="dragHandle"
                render={() => <Icons.MenuOutlined />}
              />
              {children}
            </Table>
          ) : null}
        </DragOverlay>
      )}
    </DndContext>
  )
}
