import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, Select } from 'antd'
import { LocationType } from 'interfaces/venue'
import { createOptionsFromEnum } from 'utils/common'

export const KeyLocationForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={(values) =>
        props.formProps.onFinish!({
          ...values,
          venue: props.venueId,
        })
      }>
      <Form.Item name="location_type" label="Location Type">
        <Select options={createOptionsFromEnum(LocationType)} />
      </Form.Item>
      <Form.Item name="location_name" label="Location Name">
        <Input />
      </Form.Item>
      <Form.Item name="time" label="Time">
        <Input />
      </Form.Item>
      <Form.Item name="distance" label="Distance">
        <Input />
      </Form.Item>
    </Form>
  )
}
