// import { Button, Col, Row, List, ListItem } from '@meragi/meragi-design'
import { Button, List } from 'antd'

import { Breakdown, Breakdown as BreakdownNode } from './pricingToolTree'

export const BreakdownList = ({
  breakdowns,
  selectedBreakdown,
  setSelectedBreakdown,
}: {
  breakdowns: Breakdown[] // TODO:- change to BreakdownNode
  selectedBreakdown: BreakdownNode
  setSelectedBreakdown: any
}) => {
  return (
    <div className="breakdown-list">
      <List
        dataSource={breakdowns}
        renderItem={(breakdown: BreakdownNode) => (
          <Button
            block
            type={
              selectedBreakdown.name === breakdown.name ? 'primary' : 'default'
            }
            style={{ borderRadius: 0 }}
            onClick={() => setSelectedBreakdown(breakdown)}>
            {breakdown.name}
          </Button>
        )}
      />
    </div>
  )
}
