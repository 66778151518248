import { Table } from "antd";
import { BaseKey, useOne } from "@refinedev/core";
import { PriceField } from 'components/field'
import { IServiceBreakdown } from 'interfaces/quote'
import { RFQ_URL } from 'urls'

export const ServiceTotal = ({ packageId }: { packageId?: BaseKey }) => {
  const { data } = useOne<IServiceBreakdown[]>({
    resource: RFQ_URL,
    id: `${packageId}/get_service_breakdown`,
  })
  return (
    <Table
      dataSource={data?.data}
      pagination={false}
      size="small"
      rowKey={(record) => record?.service?.id}
      expandable={{
        expandedRowRender: (record) => (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Table
              dataSource={record.vendors}
              size="small"
              pagination={false}
              bordered>
              <Table.Column dataIndex={['vendor', 'name']} title="Vendor" ellipsis/>
              <Table.Column
                dataIndex={'paid'}
                title="Paid"
                ellipsis
                render={(price) => <PriceField value={price} />}
              />
            </Table>
          </div>
        ),
      }}>
      <Table.Column dataIndex={['service', 'name']} title="Service" ellipsis/>
      <Table.Column
        dataIndex="total"
        title="Total"
        ellipsis
        render={(price) => <PriceField value={price} />}
      />
      <Table.Column
        dataIndex="paid"
        title="Paid"
        ellipsis
        render={(price) => <PriceField value={price} />}
      />
    </Table>
  )
}
