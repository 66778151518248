import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { ICategory } from 'interfaces/category'
import { CategoryForm } from './Form'

export const CategoryCreate: React.FC = () => {
  const useFormProps = useForm<ICategory>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <CategoryForm useFormProps={useFormProps} />
    </Create>
  )
}
