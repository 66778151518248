// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { RcFile } from "antd/lib/upload/interface";

import {
  Affix,
  Layout as AntdLayout,
  Button,
  Col,
  Collapse,
  Image,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
  Upload,
} from "antd";

import { Authenticated, BaseKey, useList, useOne, useRouterContext, useUpdate } from "@refinedev/core";
import { PriceField } from 'components/field'
import { FABRIC, FLORAL, PRODUCT, STRUCTURE } from 'consts'
import { BreakdownProductContext } from 'contexts/breakdownProduct'
import { IBreakdownValue, IInterfaceDetailed } from 'interfaces/pricing'
import { IProduct } from 'interfaces/product'
import queryString from 'query-string'
import React, { useState } from 'react'
import { INTERFACE_URL, PRODUCT_URL, SHORTLIST_LINE_ITEM_URL } from 'urls'
import { ComboWrapper } from './components/ComboWrapper'
import { FabricCombo } from './components/FabricCombo'
import { FloralCombo } from './components/FloralCombo'
import { ProductCombo } from './components/ProductCombo'
import { StructureCombo } from './components/StructureCombo'
import './styles.less'

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const PricingPage: React.FC = () => {
  const [total, setTotal] = useState(0)
  const [response, setResponse] = useState({})
  const [imageUrl, setImageUrl] = useState<string>()
  const [selectedInterface, setSelectedInterface] =
    useState<IInterfaceDetailed>()

  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { productId, lineItemId } = queryString.parse(location.search)

  const { mutate: saveBreakdown } = useUpdate()

  const handleChange = (info: any) => {
    getBase64(info.file.originFileObj as RcFile, (url) => {
      setImageUrl(url)
    })
  }

  const uploadButton = (
    <div>
      <Icons.PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const cleanResponse = (res: any) => {
    const result: any[] = []
    Object.entries(res).forEach(([breakdownId, specificCombo]: any) =>
      Object.values(specificCombo).forEach((comboList: any) => {
        if (Array.isArray(comboList))
          comboList?.forEach((combo: any) => {
            delete combo.category
            combo['values'] = combo.flowers
            if (
              combo.hasOwnProperty('foliages') &&
              Array.isArray(combo.foliages)
            ) {
              combo['values'] = [...(combo.flowers ?? []), ...combo.foliages]
            }
            result.push({ breakdown: breakdownId, ...combo })
          })
      }),
    )
    return result
  }

  const { data: interfaceData, isLoading: interfaceLoading } =
    useList<IInterfaceDetailed>({
      resource: `${INTERFACE_URL}/detailed`,
      queryOptions: {
        onSuccess: (data) => {
          data.total && setSelectedInterface(data.data[0])
        },
      },
    })

  const { isLoading: productInterfaceLoading } = useOne<IInterfaceDetailed>({
    resource: PRODUCT_URL,
    id: `${productId}/get_interface`,
    queryOptions: {
      enabled: !!productId,
      onSuccess: (data) => setSelectedInterface(data.data),
    },
  })

  const { data: productData, isLoading: productIsLoading } = useOne<IProduct>({
    resource: PRODUCT_URL,
    id: productId as BaseKey,
    queryOptions: {
      enabled: !!productId,
    },
  })

  const { data: lineItemBreakdownData, isLoading: lineItemBreakdownLoading } =
    useOne<IBreakdownValue[]>({
      resource: SHORTLIST_LINE_ITEM_URL,
      id: `${lineItemId}/get_breakdown`,
      queryOptions: {
        enabled: !!lineItemId,
      },
    })

  const { data: productBreakdownData, isLoading: productBreakdownLoading } =
    useOne<IBreakdownValue[]>({
      resource: PRODUCT_URL,
      id: `${productId}/get_breakdown`,
      queryOptions: {
        enabled: !!productId,
      },
    })

  const handleBreakdownSave = () => {
    const id = lineItemId ?? productId
    const resource = lineItemId ? SHORTLIST_LINE_ITEM_URL : PRODUCT_URL
    saveBreakdown({
      resource,
      id: `${id}/set_breakdown`,
      values: cleanResponse(response),
    })
  }

  const breakdownDataToUse = lineItemBreakdownData?.data.length
    ? lineItemBreakdownData
    : productBreakdownData

  const isLoading = () =>
    interfaceLoading ||
    (!!lineItemId && lineItemBreakdownLoading) ||
    (!!productId &&
      (productBreakdownLoading || productInterfaceLoading || productIsLoading))

  return (
    <Authenticated>
      <AntdLayout>
        <AntdLayout.Header className="pricing-header">
          <img src={'/meragi-logo.svg'} alt="Meragi" />
          <Space align="baseline">
            <Typography.Title level={5}>Interface</Typography.Title>
            <Select
              options={interfaceData?.data.map((int) => ({
                label: int.name,
                value: int.id,
              }))}
              value={selectedInterface?.id}
              disabled={!!productId}
              onChange={(value: BaseKey) =>
                setSelectedInterface(
                  interfaceData?.data.find((int) => int.id === value),
                )
              }
            />
          </Space>
        </AntdLayout.Header>
        <AntdLayout.Content className="pricing-content">
          {isLoading() ? (
            <div className="spinner">
              <Spin />
            </div>
          ) : (
            <Row gutter={16}>
              <Col span={8}>
                <>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleChange}>
                    {!productData?.data && uploadButton}
                  </Upload>
                  <Affix offsetTop={70}>
                    <Image
                      src={
                        imageUrl ||
                        (!!productData?.data.images.length
                          ? productData?.data.images[0].image
                          : '')
                      }
                      style={{
                        maxHeight: '70vh',
                      }}
                    />
                    <Typography.Title level={4}>
                      Total: <PriceField value={total} />
                    </Typography.Title>
                    {!!productId && (
                      <Button
                        onClick={() => handleBreakdownSave()}
                        type="primary"
                        icon={<Icons.SaveOutlined />}>
                        Save
                      </Button>
                    )}
                  </Affix>
                </>
              </Col>
              <Col span={16}>
                <Tabs>
                  {selectedInterface?.breakdowns.map((breakdown) => (
                    <Tabs.TabPane
                      tab={breakdown.name}
                      key={breakdown.id}
                      forceRender>
                      <Collapse>
                        {breakdown.structure && (
                          <Collapse.Panel
                            header="Structure"
                            key="structure"
                            forceRender>
                            <ComboWrapper
                              FormComponent={StructureCombo}
                              listName="structureCombo"
                              handleOverallTotal={setTotal}
                              handleOverallResponse={setResponse}
                              breakdownId={breakdown.id}
                              initialValue={breakdownDataToUse?.data.filter(
                                (value) =>
                                  value.breakdown === breakdown.id &&
                                  value.category === STRUCTURE,
                              )}
                            />
                          </Collapse.Panel>
                        )}
                        {breakdown.floral && (
                          <Collapse.Panel
                            header="Floral"
                            key="floral"
                            forceRender>
                            <ComboWrapper
                              FormComponent={FloralCombo}
                              listName="floralCombo"
                              handleOverallTotal={setTotal}
                              handleOverallResponse={setResponse}
                              breakdownId={breakdown.id}
                              initialValue={breakdownDataToUse?.data.filter(
                                (value) =>
                                  value.breakdown === breakdown.id &&
                                  value.category === FLORAL,
                              )}
                            />
                          </Collapse.Panel>
                        )}
                        {breakdown.fabric && (
                          <Collapse.Panel
                            header="Fabric"
                            key="fabric"
                            forceRender>
                            <ComboWrapper
                              FormComponent={FabricCombo}
                              listName="fabricCombo"
                              handleOverallTotal={setTotal}
                              handleOverallResponse={setResponse}
                              breakdownId={breakdown.id}
                              initialValue={breakdownDataToUse?.data.filter(
                                (value) =>
                                  value.breakdown === breakdown.id &&
                                  value.category === FABRIC,
                              )}
                            />
                          </Collapse.Panel>
                        )}
                        {breakdown.product && (
                          <Collapse.Panel
                            header="Product"
                            key="product"
                            forceRender>
                            <BreakdownProductContext.Provider
                              value={breakdown.allowed_categories?.map(
                                (item) => item.category,
                              )}>
                              <ComboWrapper
                                FormComponent={ProductCombo}
                                listName="productCombo"
                                handleOverallTotal={setTotal}
                                handleOverallResponse={setResponse}
                                breakdownId={breakdown.id}
                                initialValue={breakdownDataToUse?.data.filter(
                                  (value) =>
                                    value.breakdown === breakdown.id &&
                                    value.category === PRODUCT,
                                )}
                              />
                            </BreakdownProductContext.Provider>
                          </Collapse.Panel>
                        )}
                      </Collapse>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Col>
            </Row>
          )}
        </AntdLayout.Content>
      </AntdLayout>
    </Authenticated>
  )
}
