import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ISection } from 'interfaces/section'
import { MoodBoardImageForm } from './Form'

export const MoodBoardImageCreate: React.FC = () => {
  const useFormProps = useForm<any>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <MoodBoardImageForm useFormProps={useFormProps} />
    </Create>
  )
}
