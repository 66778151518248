import * as Icons from "@ant-design/icons";
import { useEffect, useState } from 'react'

export const EyeToggle = ({
  value,
  onChange,
}: {
  value?: boolean
  onChange?: (value: boolean) => void
}) => {
  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <div
      onClick={() => {
        setChecked(!checked)
        onChange && onChange(!checked)
      }}
      style={{ cursor: 'pointer' }}>
      {checked ? (
        <Icons.EyeOutlined style={{ color: 'green' }} />
      ) : (
        <Icons.EyeInvisibleOutlined style={{ color: 'red' }} />
      )}
    </div>
  )
}
