import { Card, Form, InputNumber, Select, Space, Button } from "antd";
import { useApiUrl, useCustom } from "@refinedev/core";
import { GridSelect } from 'components/input/GridSelect'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IColor } from 'interfaces/pricing'
import { useEffect, useState } from 'react'
import {
  COLOR_URL,
  COMBINATION_URL,
  FABRIC_TYPE_URL,
  PRICING_ITEM_URL,
} from 'urls'
import { TotalComponent } from './TotalComponent'
import { PriceComponent } from './PriceComponent'

export const FabricCombo = ({ handleTotal, form, field, listName }: any) => {
  const apiUrl = useApiUrl()

  const itemId = Form.useWatch([listName, field.name, 'item'], form)

  const qty = Form.useWatch([listName, field.name, 'quantity'], form)
  const colors = Form.useWatch([listName, field.name, 'fabric_breakdown_values'], form)
  const fabricId = Form.useWatch([listName, field.name, 'fabric'], form)
  const [initialfabricColorArray, setInitialfabricColorArray] = useState<any[]>([])


  const { data: comboData, refetch: getCombo } = useCustom({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: itemId,
        fabric_id: fabricId,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const getTotal = () => {
    const price = comboData?.data.price ?? 0
    return price * qty
  }

  useEffect(() => {
    getCombo()
  }, [itemId, fabricId])


  useEffect(()=>{
    let count = comboData?.data?.item?.fabric_color_count;
    let initial_array: Array<any> = []
    for (let index = 0; index < count; index++) {      
      initial_array = [...initial_array, { color: null }]
    }
    setInitialfabricColorArray(initial_array)
    
    const values = form.getFieldsValue(true)[listName]
    // set fabric_breakdown_value = initial array if any item id selectd
    if(!values[field.name].id){
      values[field.name].fabric_breakdown_values = initial_array
      form.setFieldsValue({ ...values })
    }
    else{
      if(count && count !== values[field.name].fabric_breakdown_values.length){
        values[field.name].fabric_breakdown_values = initial_array
        form.setFieldsValue({ ...values })
      }
    }
  },[comboData])

  useEffect(() => {
    const values = form.getFieldsValue(true)[listName]
    values[field.name].total = getTotal()
    let new_color_values= values[field.name].fabric_breakdown_values?.map((value:object)=>{
      return {...value, fabric:fabricId}
    })
    values[field.name].fabric_breakdown_values = new_color_values
    values[field.name].combination = comboData?.data.id
    form.setFieldsValue({ ...values })
    form.submit()
  }, [comboData?.data.id, qty, colors])


  return (
    <Card size="small">
      <Form.Item {...field} label="Item" name={[field.name, 'item']}>
        <GridSelect
          useTableProps={{
            resource: PRICING_ITEM_URL,
            permanentFilter: [
              { field: 'category', operator: 'eq', value: 'FABRIC' },
              { field: 'is_visible', operator: 'eq', value: true },
            ],
          }}
          title="Fabric Items"
        />
      </Form.Item>
      <Form.Item
        {...field}
        label="Fabric"
        name={[field.name, 'fabric']}
        style={{ flex: 0.7 }}>
        <GridSelect
          useTableProps={{
            resource: FABRIC_TYPE_URL,
            initialFilter: [
              {
                field: 'item_id',
                operator: 'eq',
                value: itemId,
              },
            ],
          }}
          title="Fabrics"
        />
      </Form.Item>
      
      <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
      {
        initialfabricColorArray.length > 0 && 
        <Form.List {...field} name={[field.name,'fabric_breakdown_values']} 
          initialValue={initialfabricColorArray}
        >
        {(fields, { add, remove }) => {
          return (
          <Space>
            {fields.map((field) => (
              <Form.Item {...field} label="Color" name={[field.name, 'color']} key={field.key} rules={[{ required: true, message: '' }]}>
                <SelectWithDefault<IColor>
                  dropdownMatchSelectWidth={false}
                  useSelectProps={{ resource: COLOR_URL }}
                  renderOptions={(colors) =>
                    colors?.map((color) => (
                      <Select.Option key={color.id} value={color.id}>
                        <Space>
                          <div
                            style={{
                              height: 25,
                              width: 25,
                              backgroundColor: color.hex,
                            }}
                          />
                          {color.name}
                        </Space>
                      </Select.Option>
                    ))
                  }
                />
              </Form.Item>
            ))
            } 
          </Space>
        )}}
      </Form.List>
      }
      <PriceComponent value={comboData?.data?.price ?? 0} />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Form.Item
          {...field}
          name={[field.name, 'quantity']}
          label={`QTY ${
            comboData?.data.uom ? '(' + comboData?.data.uom + ')' : ''
          }`}
          initialValue={1}>
          <InputNumber placeholder="QTY" />
        </Form.Item>

        <Form.Item {...field} name={[field.name, 'total']} initialValue={0}>
          <TotalComponent />
        </Form.Item>
      </div>
    </Card>
  )
}
