// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button } from "antd";
const { MinusOutlined } = Icons;
export const RowRemoveButton = ({
  onClick,
  title = "Remove",
}: {
  onClick: () => any;
  title?: string;
}) => (
  <Button onClick={onClick} shape="circle" danger size="small">
    <MinusOutlined />
  </Button>
);
