import React from 'react'
import {
  DateField,
  PageHeaderProps,
  RefreshButton,
  Show,
  TextField,
} from '@refinedev/antd'
import { Button, Table, Typography } from 'antd'
import {
  Authenticated,
  BaseKey,
  LayoutWrapper,
  ResourceRouterParams,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useOne,
  useRouterContext,
} from '@refinedev/core'
import { IPackageDetailMaterial } from 'interfaces/quote'
import { RFQ_URL } from 'urls'
import { MaxMaterialSourceColumn } from './SourceCards'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'

export interface IGenerateMaterialSource {
  material: BaseKey
  uom: string
}

export const AssignSourceShow: React.FC<{}> = ({}) => {
  const { useParams } = useRouterContext()
  const { id: showId } = useParams<ResourceRouterParams>()
  const { goBack, show } = useNavigation()

  const {
    data: distinctMaterials,
    isFetching: distinctMaterialsFetching,
    refetch: refetchDistinctMaterials,
  } = useOne<IPackageDetailMaterial[]>({
    resource: RFQ_URL,
    id: `${showId}/get_distinct_materials_max_qty`,
  })

  const apiUrl = useApiUrl()

  const { mutate: automateSources, isLoading: isAutomatingSources } =
    useCustomMutation()

  const handleAutomateSources = () => {
    const materials = distinctMaterials?.data?.map(
      (value: IPackageDetailMaterial) => {
        return { uom: value.uom, material: value?.material.id }
      },
    )
    automateSources(
      {
        url: `${apiUrl}/package_tool/rfq/${showId}/generate_material_sources/`,
        method: 'post',
        values: materials as IGenerateMaterialSource[],
      },
      {
        onSuccess: () => show(RFQ_URL, showId as BaseKey),
      },
    )
  }

  const pageHeaders: PageHeaderProps = {
    onBack: () => {
      goBack()
    },
    breadcrumb: <></>,
    extra: (
      <>
        <Button onClick={handleAutomateSources} loading={isAutomatingSources}>
          Automate Sources
        </Button>
        <RefreshButton onClick={() => refetchDistinctMaterials()} />
      </>
    ),
  }

  return (
    <Authenticated>
      <LayoutWrapper>
        <Show
          title={`Assign Sources - ${showId ?? 'Not set'}`}
          headerProps={pageHeaders}>
          <Table
            dataSource={distinctMaterials?.data}
            size="small"
            pagination={false}
            loading={distinctMaterialsFetching}>
            <Table.Column<IPackageDetailMaterial>
              dataIndex={['material', 'name']}
              title="Material"
              width={'20vw'}
              render={(value, record) => (
                <>
                  <TextField value={value} />
                </>
              )}
            />
            <Table.Column<IPackageDetailMaterial>
              dataIndex="qty"
              title="Qty"
              width={'5vw'}
            />
            <Table.Column<IPackageDetailMaterial>
              dataIndex={['uom']}
              title="Unit"
              width={'5vw'}
            />
            <Table.Column<IPackageDetailMaterial>
              title="Dates"
              width={'10vw'}
              dataIndex={['package_detail', 'date_time']}
              render={(value, record: IPackageDetailMaterial) => {
                return (
                  <>
                    {record.all_dates.map((date) => {
                      if (dayjs(date).toString() === dayjs(value).toString())
                        return (
                          <DateField
                            value={date}
                            strong
                            format="LLL"
                            key={'max_date'}
                            ellipsis
                          />
                        )
                      return (
                        <DateField
                          value={date}
                          format="LLL"
                          key={'date'}
                          ellipsis
                        />
                      )
                    })}
                  </>
                )
              }}
            />
            <Table.Column<IPackageDetailMaterial>
              title="Assign Sources"
              render={(value, record) => {
                return (
                  <MaxMaterialSourceColumn
                    materialId={record?.material?.id}
                    uom={record.uom}
                    packageId={showId}
                  />
                )
              }}
            />
          </Table>
        </Show>
      </LayoutWrapper>
    </Authenticated>
  )
}
