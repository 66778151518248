// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Form, Modal, Tooltip } from "antd";
import { BaseKey, useApiUrl, useCustomMutation, useModal, useNavigation } from "@refinedev/core";
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ILead } from 'interfaces/crm'
import { LEAD_URL, SERVICE_URL, SHORTLIST_PACKAGE_URL } from 'urls'

export const CreatePackageModal = ({ leadId }: { leadId: BaseKey }) => {
  const { mutate } = useCustomMutation<ILead>()
  const apiUrl = useApiUrl()
  const { edit } = useNavigation()

  const [form] = Form.useForm()

  const { visible, show, close } = useModal()

  return (
    <>
      <Tooltip title="Create Package">
        <Button
          size="small"
          onClick={() => show()}
          icon={<Icons.PlusOutlined />}>
          Package{' '}
        </Button>
      </Tooltip>
      <Modal
        title="Create Package"
        visible={visible}
        onCancel={close}
        onOk={() => form.submit()}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            mutate(
              {
                url: `${apiUrl}/${LEAD_URL}/${leadId}/create_package/`,
                values,
                method: 'post',
              },
              {
                onSuccess: (data) =>
                  edit(SHORTLIST_PACKAGE_URL, data.data.package),
              },
            )
          }}>
          <Form.Item name="service_id" label="Service">
            <SelectWithDefault
              useSelectProps={{
                resource: SERVICE_URL,
                optionLabel: 'name',
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
