import { EXTERNAL_USER_KEY, PERMISSIONS_KEY } from 'authProvider'
import { PERMISSIONS } from 'consts'

export interface CanParams {
  resource: string
  action?: string
  params?: any
}

export interface CanReturnType {
  can: boolean
  reason?: string
}

export const accessControlProvider: any = {
  can: ({ resource, action, params }: CanParams): Promise<CanReturnType> => {
    let isExternal = localStorage.getItem(EXTERNAL_USER_KEY)

    if (!!resource && isExternal === 'true')
      return Promise.resolve({ can: false })

    if (PERMISSIONS.includes(resource)) {
      const permissions = localStorage.getItem(PERMISSIONS_KEY)?.split(',')
      return Promise.resolve({
        can: permissions?.includes(resource) ?? false,
      })
    } else return Promise.resolve({ can: true })
  },
}
