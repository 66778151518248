import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton, LogButton } from 'components/button'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { IVendor } from 'interfaces/vendor'
import { useState } from 'react'
import { VENDOR_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const VendorList: React.FC = () => {
  const tProps = useTable<IVendor, any, { search: string }>({
    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: VENDOR_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && (
        <DeleteButton key="delete" onClick={deleteMany} />
      ),
      <TableSearchInput
        key="search"
        searchFormProps={tProps.searchFormProps}
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <LogButton key="log" />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: (event) => edit(VENDOR_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
      </Table>
    </List>
  );
}
