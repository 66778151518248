// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Space, Spin, Typography } from 'antd'

import {
  Authenticated,
  LayoutWrapper,
  ResourceRouterParams,
  useCan,
  useNavigation,
  useOne,
  useRouterContext,
} from '@refinedev/core'

import { IRFQSectionItem, IVendorTotal } from 'interfaces/quote'
import { RFQ_URL } from 'urls'
import { ReportsTab } from './components/ReportsTab'

export const Reports = () => {
  const { useParams } = useRouterContext()
  const { id } = useParams<ResourceRouterParams>()
  const { goBack } = useNavigation()

  const queryResult = useOne<IRFQSectionItem>({
    resource: RFQ_URL,
    id: id as string,
  })

  const { data: vendorTotalData, refetch: refetchVendorTotal } = useOne<
    IVendorTotal[]
  >({
    resource: 'package_tool/rfq',
    id: `${queryResult?.data?.data.package.id}/get_vendor_total`,
  })

  const { data: selectVendor } = useCan({
    resource: 'select_rfq_vendor',
    action: '',
  })

  return (
    <Authenticated>
      <LayoutWrapper>
        <Typography.Title level={4}>
          <Space size="large">
            <Icons.ArrowLeftOutlined
              style={{ fontSize: 15 }}
              onClick={() => goBack()}
            />
            <div>Package: {queryResult.data?.data.package.name}</div>
          </Space>
        </Typography.Title>
        {!queryResult.isFetching ? (
          <ReportsTab
            queryResult={queryResult}
            vendorTotalData={vendorTotalData}
            refetchVendorTotal={refetchVendorTotal}
            selectVendor={selectVendor}
          />
        ) : (
          <Spin />
        )}
      </LayoutWrapper>
    </Authenticated>
  )
}
