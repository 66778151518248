import { BaseKey, useModal, useOne, useUpdate } from '@refinedev/core'
import { Button, Flex, Modal, Select, Tag } from 'antd'
import { useState } from 'react'
import { LEAD_URL } from 'urls'

export const TagEditModal = ({
  leadId,
  tags,
}: {
  leadId: BaseKey
  tags?: string[]
}) => {
  const { visible, show, close } = useModal()
  const [selectedTags, setSelectedTags] = useState<string[]>(tags || [])
  const { mutate: patch, isLoading: isUpdating } = useUpdate()

  const handleSave = () => {
    console.log('Selected Tags: ', selectedTags)
    patch({
      resource: LEAD_URL,
      id: leadId,
      values: { tags: selectedTags },
    })
    close()
  }

  const { data: lead_tags } = useOne<string[]>({
    resource: LEAD_URL,
    id: 'get_tags',
  })

  return (
    <>
      <Flex gap="4px 0" wrap>
        {tags?.map((tag: any) => (
          <Tag color="magenta" key={tag}>
            {tag}
          </Tag>
        ))}
        <Button size="small" type="primary" ghost onClick={() => show()}>
          Add Tag
        </Button>
      </Flex>
      <Modal
        visible={visible}
        onCancel={close}
        footer={
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        }
        destroyOnClose>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Add tags"
          options={lead_tags?.data?.map((tag: any) => ({
            label: tag,
            value: tag,
          }))}
          value={selectedTags}
          onChange={setSelectedTags}
          tokenSeparators={[',']}
        />
      </Modal>
    </>
  )
}
