import { useMany, useOne } from '@refinedev/core'
import { Col, Row, Typography } from 'antd'
import { IPackageItems } from 'interfaces/package'
import { IShortlistPackageItems } from 'interfaces/shortlistPackage'
import groupBy from 'lodash/groupBy'
import { ImageCarousel } from 'pages/product/cardList'

export const MoodBoard: React.FC<{
  id: string
  shortlisted?: boolean
}> = ({ id, shortlisted = false }) => {
  // data: IShortlistPackageItems | IPackageItems
  const url = shortlisted
    ? 'package_tool/shortlist_packages'
    : 'package_tool/packages'
  const { data: packageProductData, isLoading: packageProductDataIsLoading } =
    useOne<IPackageItems | IShortlistPackageItems>({
      resource: url,
      id: `${id}/get_line_items`,
    })

  const sectionGroup = groupBy(packageProductData?.data.items, 'section')

  const { data: sectionData } = useMany({
    resource: 'package_tool/sections',
    ids: Object.keys(sectionGroup),
    queryOptions: {
      enabled: !!sectionGroup,
    },
  })

  return (
    <>
      {Object.entries(sectionGroup).map(([section, items]) => (
        <div key={section}>
          <Typography.Text>
            {sectionData?.data.find((s) => String(s.id) === section)?.name}
          </Typography.Text>
          <Row gutter={16}>
            {items.map((item) => (
              <Col key={item.id} span={4}>
                <ImageCarousel
                  images={item.product.images}
                  width={150}
                  height={150}
                />
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </>
  )
}
