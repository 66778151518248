import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelect } from '@refinedev/antd'
import { useApiUrl, useCan, useCustomMutation } from '@refinedev/core'
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import { ServicePaymentSplit } from 'interfaces/payment'
import { UseFormReturnType } from 'interfaces/refine'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import React, { useEffect, useState } from 'react'
import { SERVICE_URL } from 'urls'
import { setErrors } from 'utils/form'

export const CollectPaymentForm: React.FC<{
  useFormProps: UseFormReturnType
  projectId: Number | String | undefined
  shortlistPackage?: IShortlistPackage | undefined
}> = ({ useFormProps, shortlistPackage, projectId }) => {
  const [showCustomAmount, setShowCustomAmount] = useState(false)
  const [responseAmountToBePaid, setResponseAmountToBePaid] =
    useState<number>(0)
  const [amountToBePaid, setAmountToBePaid] = useState<number>(0)
  const { mutate: fetchAmount } = useCustomMutation<any>()
  const { mutationResult, formProps } = useFormProps
  const apiUrl = useApiUrl()

  const { selectProps } = useSelect({
    resource: 'package_tool/payment_types',
    optionValue: 'id',
    optionLabel: 'name',

    pagination: {
      mode: 'server',
    },
  })

  function validatePaymentSplitTotal(
    payment_splits: ServicePaymentSplit[] | undefined,
    amount: number,
  ): boolean {
    if (!payment_splits || payment_splits.length === 0) {
      return false
    }

    const totalPaymentSplit = payment_splits.reduce(
      (sum: number, split) => sum + split.split_amount,
      0,
    )
    return totalPaymentSplit === amount
  }
  const getAmount = (payment_type_id: any) => {
    fetchAmount(
      {
        url: `${apiUrl}/package_tool/payments/get_amount_to_pay/`,
        values: {
          event_id: projectId,
          type_id: payment_type_id,
        },
        method: 'post',
      },
      {
        onSuccess: (data) => {
          const res_amount = data.data['amount_to_be_paid']
          setResponseAmountToBePaid(res_amount)
          setAmountToBePaid(res_amount)
          formProps.form?.setFieldValue(
            'amount',
            data.data['amount_to_be_paid'],
          )
        },
      },
    )
  }

  const { data: addCustomAmount } = useCan({
    resource: 'add_custom_amount',
    action: '',
  })

  const noteDefaultValue: string = `PID:
Package value:
Discounted package value:
Services:`

  // handle errors thrown by the server and show them for respective fields
  useEffect(() => {
    if (mutationResult.isError) {
      setErrors(mutationResult.error.response.data, formProps.form)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult])

  return (
    <Card>
      <Form
        {...formProps}
        preserve={false}
        layout="vertical"
        onFinish={(values: any) => {
          const isTotalValid = validatePaymentSplitTotal(
            values.service_payment_splits,
            values.amount,
          )
          if (!isTotalValid) {
            alert(
              'payement split amount does not sum to total amount or missing service',
            )
            return
          }

          values.package = shortlistPackage?.id
          if (projectId) {
            values.event = projectId
          } else {
            values.event = shortlistPackage?.event.id
          }

          values.link_expiry_time = dayjs()
            .add(values.link_expiry_time * 24, 'hour')
            .unix()
          formProps?.onFinish && formProps.onFinish({ ...values })
        }}>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select {...selectProps} onChange={getAmount} />
        </Form.Item>
        <Space size={'large'}>
          <Form.Item name="amount" label="Amount">
            <InputNumber disabled={!showCustomAmount} value={amountToBePaid} />
          </Form.Item>
          <div>
            <Switch
              title="Custom Amount"
              disabled={!addCustomAmount?.can}
              checked={showCustomAmount}
              onChange={() => {
                setShowCustomAmount(!showCustomAmount)
                formProps.form?.setFieldValue('amount', responseAmountToBePaid)
              }}
            />{' '}
            Custom Amount
          </div>
        </Space>
        <Form.List name="service_payment_splits">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    // marginBottom: 8,
                  }}
                  align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'service']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <SelectWithDefault
                      popupMatchSelectWidth={false}
                      useSelectProps={{
                        resource: SERVICE_URL,
                        optionLabel: 'name',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'split_amount']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <InputNumber />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}>
                  Add Service Payment Split
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="note"
          label="Note"
          help="This will be used to generate the purpose for the payment link"
          rules={[{ required: true }]}>
          <Input.TextArea
            maxLength={250}
            showCount
            autoSize
            defaultValue={noteDefaultValue}
          />
        </Form.Item>

        <Form.Item
          name="link_expiry_time"
          label="Link Expiry Time"
          initialValue={30}>
          <Select>
            <Select.Option value={0.5}>12 hours</Select.Option>
            <Select.Option value={1}>1 Day</Select.Option>
            <Select.Option value={2}>2 Days</Select.Option>
            <Select.Option value={7}>7 Days</Select.Option>
            <Select.Option value={30}>30 Days</Select.Option>
          </Select>
        </Form.Item>

        <Space size={'large'}>
          {!projectId && (
            <div>
              <Button type="primary" htmlType="submit">
                Collect
              </Button>
            </div>
          )}
        </Space>
      </Form>
    </Card>
  )
}
