import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { DeleteButton, useModalForm, useSimpleList } from '@refinedev/antd'
import { BaseRecord, Pagination, useTableProps } from '@refinedev/core'
import { List as AntdList, Button, Modal } from 'antd'
import { FormProps } from 'antd/lib'

export type InlineCRUDProps<T> = {
  name?: string
  resource: string
  pagination?: Pagination
  filters?: useTableProps<T, any, any>['filters']
  renderItem: (item: T) => JSX.Element
  renderCreateForm?: (formProps: FormProps) => JSX.Element
  renderEditForm?: (formProps: FormProps) => JSX.Element
}

export const InlineCRUD = <T extends BaseRecord = BaseRecord>({
  name,
  resource,
  pagination,
  filters,
  renderItem,
  renderCreateForm,
  renderEditForm,
}: InlineCRUDProps<T>) => {
  const finalPagination: Pagination = pagination || { mode: 'off' }

  const { listProps } = useSimpleList<T>({
    resource,
    pagination: finalPagination,
    filters,
  })
  const { formProps, modalProps, show } = useModalForm<T>({
    resource,
    action: 'create',
    redirect: false,
  })
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm<T>({
    resource,
    action: 'edit',
    redirect: false,
  })

  const getActions = (item: T) => {
    const actions = []
    if (renderCreateForm)
      actions.push(
        <Button
          key="edit"
          onClick={() => editShow(item.id)}
          icon={<EditOutlined />}
          size="small"
        />,
      )

    actions.push(
      <DeleteButton
        key="delete"
        recordItemId={item.id}
        resource={resource}
        hideText
        size="small"
      />,
    )
    return actions
  }

  return (
    <>
      <AntdList<T>
        {...listProps}
        renderItem={(item: T) => (
          <AntdList.Item actions={getActions(item)}>
            {renderItem(item)}
          </AntdList.Item>
        )}
      />
      {!!renderCreateForm && (
        <Button
          onClick={() => show()}
          block
          type="dashed"
          icon={<PlusOutlined />}>
          Add More
        </Button>
      )}
      {!!renderCreateForm && (
        <Modal {...modalProps} title={`Add ${name || ''}`}>
          {renderCreateForm(formProps)}
        </Modal>
      )}
      {!!renderEditForm && (
        <Modal {...editModalProps} title={`Edit ${name || ''}`}>
          {renderEditForm(editFormProps)}
        </Modal>
      )}
    </>
  )
}
