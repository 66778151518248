import { EyeOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons'
import { useForgotPassword, useUpdatePassword } from '@refinedev/core'
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from 'antd'
import React, { useState } from 'react'

const { Text, Title } = Typography
export interface IForgotPasswordForm {
  email: string
}

export interface IUpdatePasswordForm {
  email: string
  password: string
  otp: string
}

export const ForgotPassword: React.FC = () => {
  const [isOtpSent, setIsOtpSent] = useState(false)

  const [form] = Form.useForm<IForgotPasswordForm | IUpdatePasswordForm>()

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<IForgotPasswordForm>()

  const { mutate: updatePassword, isLoading: isLoadingChange } =
    useUpdatePassword<IUpdatePasswordForm>()

  const CardTitle = (
    <Title level={3} className="titleStyles">
      {'Forgot Password?'}
    </Title>
  )

  return (
    <Layout className="layoutStyles">
      <Row
        justify="center"
        style={{
          height: '100%',
          width: '340px',
        }}>
        <Col xs={22}>
          <div className="containerStyles">
            <div className="imageContainer">
              <img
                src={'/logo-vector.svg'}
                alt="Refine Logo"
                style={{ width: '140px', height: '140px', marginTop: '20px' }}
              />
            </div>
            <Card
              title={CardTitle}
              style={{ background: '#FEFDFF', border: 0, width: '100%' }}
              headStyle={{ borderBottom: 0, background: '#FEFDFF' }}>
              <Form
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  isOtpSent
                    ? updatePassword(values as IUpdatePasswordForm)
                    : forgotPassword(values, {
                        onSuccess: () => {
                          setIsOtpSent(true)
                        },
                      })
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}>
                <Form.Item name="email" rules={[{ required: true }]}>
                  <Input
                    size="large"
                    prefix={<UserOutlined />}
                    placeholder="Email"
                    style={{
                      borderRadius: 2,
                    }}
                  />
                </Form.Item>

                {isOtpSent && (
                  <>
                    <Form.Item name="otp" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        prefix={<KeyOutlined />}
                        placeholder="OTP"
                        style={{
                          borderRadius: 2,
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        prefix={<EyeOutlined />}
                        placeholder="Password"
                        style={{
                          borderRadius: 2,
                        }}
                      />
                    </Form.Item>
                  </>
                )}
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading || isLoadingChange}
                  block
                  style={{
                    background: '#6930C3',
                    border: '#6930C3',
                    borderRadius: 2,
                    marginTop: '12px',
                  }}>
                  Submit
                </Button>
                {isOtpSent && (
                  <Typography.Text type="success">
                    OTP sent to your registered email id
                  </Typography.Text>
                )}
              </Form>
            </Card>
            <div className="footerStyle">
              <Text>Copyright © 2023 Meragi . All Rights Reseved.</Text>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}
