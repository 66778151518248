import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IQuote } from 'interfaces/quote'
import { QuoteForm } from '.'

export const QuoteEdit: React.FC = () => {
  const useFormProps = useForm<IQuote>({
    redirect: 'edit',
  })

  return <>
    <Edit
      saveButtonProps={useFormProps.saveButtonProps}
      canDelete
      headerProps={{ title: 'Edit Quotes', extra: null }}>
      <>
        <QuoteForm useFormProps={useFormProps} />
      </>
    </Edit>
  </>;
}
