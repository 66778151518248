import { useTableReturnType } from "@refinedev/antd";
import { Col, Grid, Row } from "antd";
import { useApiUrl, useCustom, useTable } from "@refinedev/core";
import { PackageFilter } from 'components/filters/PackageFilter'
import { IFilter } from 'interfaces/attribute'
import { IPackage } from 'interfaces/package'
import { generateFilterQuery } from 'utils/filter'
import { PackageTable } from '.'

export type usePackageTableReturnType = Omit<
  useTableReturnType<IPackage, any>,
  'searchFormProps'
>

export const PackageList: React.FC = () => {
  const apiUrl = useApiUrl()
  const coreTableProps = useTable<IPackage>({
    filters: {
      defaultBehavior: 'replace'
    }
  })

  const breakpoint = Grid.useBreakpoint()

  /**
   * handle the pagination changes from table
   * @param pagination
   */
  const onChange = (pagination: any) => {
    coreTableProps.setCurrent(pagination.current)
    coreTableProps.setPageSize(pagination.pageSize)
  }

  const tProps: usePackageTableReturnType = {
    ...coreTableProps,
    tableProps: {
      dataSource: coreTableProps.tableQueryResult.data?.data,
      loading: !coreTableProps.tableQueryResult.isFetched,
      onChange,
      pagination: {
        pageSize: coreTableProps.pageSize,
        current: coreTableProps.current,
        simple: !breakpoint.sm,
        position: !breakpoint.sm ? ['bottomCenter'] : ['bottomRight'],
        total: coreTableProps.tableQueryResult.data?.total,
      },
    },
  }

  const { data: filterData, isLoading: isFilterLoading } = useCustom<IFilter[]>(
    generateFilterQuery(
      `${apiUrl}/package_tool/packages/get_filters/`,
      tProps.filters,
      !!tProps?.tableQueryResult.data?.data.length,
    ),
  )

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <PackageFilter
          packageData={tProps.tableQueryResult.data?.data}
          filterData={filterData?.data}
          filterLoading={isFilterLoading}
          setFilters={coreTableProps.setFilters}
          setSorter={coreTableProps.setSorter}
          setCurrent={coreTableProps.setCurrent}
        />
      </Col>
      <Col lg={18}>
        <PackageTable tProps={tProps} />
      </Col>
    </Row>
  )
}
