import { Create } from '@refinedev/antd'
import { file2Base64, useCreate, useModal } from '@refinedev/core'
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Typography,
  Upload,
  notification,
} from 'antd'
import { ProductSelect } from 'components/input/productSelect'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'
import { IProduct } from 'interfaces/product'
import { ChangeType } from 'interfaces/shortlistPackage'
import React, { useContext, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import ReactQuill from 'react-quill'
import { CHANGE_TRACKER_URL } from 'urls'
import { EditPackageContext, EditPackageContextType } from '../Edit'
const { Text } = Typography

export const CreateShortlistLineItem: React.FC<{
  form: any
  formProps: any
  saveButtonProps: any
  packageDetailId?: number
  lineItemId?: string
  cancelSave: any
}> = ({
  form,
  formProps,
  packageDetailId,
  saveButtonProps,
  cancelSave,
  lineItemId,
}) => {
  const _package = useContext(ShortlistPackageContext)

  const [product, setProduct] = useState<IProduct>()
  const [price, setPrice] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [isFileReq, setIsFileReq] = useState<boolean>(
    _package?.service_package.service.name === 'Catering',
  )
  const [changeDescription, setChangeDescription] = useState('')

  const { isFinalized } = useContext(
    EditPackageContext,
  ) as EditPackageContextType

  const { mutate: createChangeTracker } = useCreate()

  const {
    visible: visibleChangeDescription,
    show: showChangeDescription,
    close: closeChangeDescription,
  } = useModal()

  const handleCreateTracker = (values: any) => {
    createChangeTracker(
      {
        resource: CHANGE_TRACKER_URL,
        values: {
          change_type: ChangeType.CREATE,
          package: _package?.id,
          change: {
            product_id: values.product,
            quantity: values.quantity,
            price: values.price,
            description: values.description,
            package_detail_id: packageDetailId,
          },
          description: changeDescription,
        },
      },
      {
        onSuccess: () => {
          cancelSave()
        },
      },
    )
  }

  const onFinish = async (values: any) => {
    if (packageDetailId) {
      values['package_detail'] = packageDetailId
    }
    if (lineItemId) {
      values['item'] = lineItemId
    }

    if (values.file && typeof values.file !== 'string') {
      const convertedFile = await file2Base64(values.file)
      values['file'] = convertedFile
    }

    return formProps.onFinish && formProps.onFinish(values)
  }
  const quantity = Form.useWatch('quantity', form)

  useHotkeys('ctrl+s, cmd+s', (e) => {
    if (isFinalized) {
      showChangeDescription()
    } else {
      e.preventDefault()
      notification.info({
        message: 'Saving',
        duration: 1,
        description: 'Save in progress',
      })
      formProps.form.submit()
    }
  })

  useEffect(() => {
    const calculatedPrice = price * quantity
    if (product !== undefined && quantity > 0) {
      setTotal(calculatedPrice)
    }
  }, [price, quantity, product])

  useEffect(() => {
    form.setFieldsValue({
      price: product?.price,
      description: product?.description ? product?.description : '',
    })
    product?.price &&
      setPrice((old) => {
        return product?.price
      })
  }, [product])

  const getFile = ({ file }: any) => {
    setIsFileReq(true)
    form.setFieldsValue({
      product: null,
    })
    return file
  }

  return (
    <Card>
      <Create
        goBack={false}
        breadcrumb={null}
        title={'Add product'}
        footerButtons={() => {
          return (
            <>
              <Button onClick={cancelSave}>Cancel</Button>{' '}
              <Button
                {...saveButtonProps}
                onClick={() => {
                  if (isFinalized) {
                    showChangeDescription()
                  } else {
                    formProps.form.submit()
                  }
                }}>
                Save
              </Button>
            </>
          )
        }}>
        <Form {...formProps} layout="vertical" onFinish={onFinish}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Space direction="vertical" align="center">
              <Form.Item
                name={'file'}
                label="File"
                getValueFromEvent={getFile}
                rules={[
                  {
                    required: isFileReq,
                    message: 'This is Required',
                  },
                ]}>
                <Upload accept=".pdf" showUploadList={isFileReq} maxCount={1}>
                  <Button>Upload PDF</Button>{' '}
                </Upload>
              </Form.Item>
              <Typography.Text strong>OR</Typography.Text>
              <Form.Item
                name={'product'}
                label="Product"
                rules={[
                  {
                    required: !isFileReq,
                    message: 'This is required',
                  },
                ]}>
                <ProductSelect
                  setProduct={setProduct}
                  onChange={() => {
                    setIsFileReq(false)
                    form.setFieldsValue({ file: null })
                  }}
                />
              </Form.Item>
            </Space>
            <Form.Item
              name={'quantity'}
              label="Quantity"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <InputNumber<number> />
            </Form.Item>
            <Form.Item
              name={'price'}
              label="Price"
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <InputNumber<number>
                onChange={(value: any) => setPrice(value)}
                prefix="₹"
              />
            </Form.Item>
            <Text>Total: ₹{total}</Text>
          </div>
          <div>
            <Form.Item
              name={'description'}
              label="Description"
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <ReactQuill theme="snow" />
            </Form.Item>
          </div>
        </Form>
      </Create>
      <Modal
        open={visibleChangeDescription}
        title="Request Changes?"
        okButtonProps={{
          disabled: !changeDescription.length,
        }}
        onOk={() => {
          handleCreateTracker(formProps.form.getFieldsValue(true))
          closeChangeDescription()
          setChangeDescription('')
        }}
        onCancel={() => {
          closeChangeDescription()
          setChangeDescription('')
        }}>
        <Input.TextArea
          value={changeDescription}
          onChange={(e) => setChangeDescription(e.target.value)}
          placeholder="Add a nice description informing about the changes"
        />
      </Modal>
    </Card>
  )
}
