import { PhoneOutlined } from '@ant-design/icons'
import { useModal, useSimpleList } from '@refinedev/antd'
import { List as AList, Button, Descriptions, Modal } from 'antd'
import { DateFieldFromNow } from 'components/field'
import { ICallLog, ILead } from 'interfaces/crm'
import { IStaff } from 'interfaces/staff'
import ReactPlayer from 'react-player'
import { CALL_LOG_URL } from 'urls'
import { getUserName } from 'utils/common'

export const CallLogModal = ({ lead }: { lead: ILead }) => {
  const { modalProps, show } = useModal()
  const { listProps } = useSimpleList<ICallLog, any, any, ICallLog>({
    resource: CALL_LOG_URL,
    sorters: { permanent: [{ field: 'created_at', order: 'desc' }] },
    filters: {
      permanent: [
        { field: 'lead', operator: 'eq', value: lead.id },
        { field: 'expand', operator: 'eq', value: 'user' },
      ],
    },
    queryOptions: {
      enabled: modalProps.open,
    },
  })

  return (
    <>
      <Button size="small" icon={<PhoneOutlined />} onClick={() => show()} />
      <Modal {...modalProps} title="Calls" footer={null}>
        <AList
          {...listProps}
          renderItem={(item) => (
            <AList.Item>
              <AList.Item.Meta
                title={<DateFieldFromNow value={item.created_at} />}
                description={
                  <Descriptions>
                    <Descriptions.Item label="Caller">
                      {getUserName(item.user as IStaff, false)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {item.call_status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Duration">
                      {item.duration}s
                    </Descriptions.Item>
                    {!!item.recording && (
                      <Descriptions.Item label="Recording">
                        <ReactPlayer
                          url={item.recording}
                          height={20}
                          pip={false}
                          config={{}}
                          controls
                        />
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                }
              />
            </AList.Item>
          )}
        />
      </Modal>
    </>
  )
}
