import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IBreakdown } from 'interfaces/pricing'
import { BreakdownForm } from './Form'

export const BreakdownCreate: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IBreakdown>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <BreakdownForm formProps={formProps} queryResult={queryResult} />
    </Create>
  )
}
