import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Select,
  Layout,
  FloatButton,
  Switch,
  notification,
} from 'antd'
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveFilled,
} from '@ant-design/icons'

import { SelectWithDefault } from 'components/input/SelectWithDefault'
import {
  EVENT_HORIZON_OPTION_URL,
  MIELSTONE_URL,
  SERVICE_URL,
  TARGET_DATE_OPTION_URL,
} from 'urls'
import {
  useList,
  BaseKey,
  useCustomMutation,
  useApiUrl,
  useDelete,
  useCan,
} from '@refinedev/core'
import { createOptionsFromEnum } from 'utils/common'
import { AssigneePref, DatePref } from 'interfaces/shortlistPackage'
import { FaPlus } from 'react-icons/fa'

const { Option } = Select

interface TargetDateOptions {
  id?: BaseKey
  event_horizon: string
  event_date_offset: number
  is_before_event: boolean
  booking_date_offset: number
  is_before_booking: boolean
  date_preference: string
  event_date_preference: string
}

interface Milestone {
  id?: BaseKey
  name: string
  order?: number
  status: string
  target_dates: TargetDateOptions[]
  service?: number
  is_template?: boolean
  is_internal?: boolean
}

export const MilestoneManager: React.FC = () => {
  const [milestones, setMilestones] = useState<Milestone[]>([])
  const [selectedService, setSelectedService] = useState<string | null>(null)
  const { mutate: bulkCreateUpdate } = useCustomMutation()
  const { mutate: destroy } = useDelete()
  const apiUrl = useApiUrl()
  const { data: modifyMilestone } = useCan({
    resource: 'modify_milestone',
    action: '',
  })
  const { data, isLoading, refetch } = useList<any>({
    resource: MIELSTONE_URL,
    config: {
      pagination: {
        pageSize: 1000,
      },
      filters: [
        { field: 'is_template', operator: 'eq', value: true },
        { field: 'service', operator: 'eq', value: selectedService },
      ],
    },
  })
  useEffect(() => {
    if (data) {
      setMilestones(data.data) // Populate the milestones with fetched data
    }
  }, [data])

  const addMilestone = () => {
    const newMilestone: Milestone = {
      name: '',
      status: '',
      target_dates: [],
      is_template: true,
      is_internal: true,
    }
    setMilestones([...milestones, newMilestone])
  }

  const updateMilestone = (index: number, updatedMilestone: Milestone) => {
    const newMilestones = [...milestones]
    newMilestones[index] = updatedMilestone
    setMilestones(newMilestones)
  }

  const removeMilestone = (index: number) => {
    const milestoneToRemove = milestones[index]
    if (milestoneToRemove.id) {
      destroy(
        {
          resource: MIELSTONE_URL,
          id: milestoneToRemove.id,
        },
        { onSuccess: () => refetch() },
      )
    }
    const newMilestones = milestones.filter((_, i) => i !== index)
    setMilestones(newMilestones)
  }
  const bulkSave = () => {
    bulkCreateUpdate(
      {
        url: `${apiUrl}/package_tool/milestones/bulk_create_update/`,
        method: 'post',
        values: { milestones: milestones },
      },
      {
        onSuccess: (data, variables, context) => {
          notification.success({
            message: 'Saved Successfully',
            duration: 1.5,
          })
          refetch()
        },
      },
    )
  }

  return (
    <Layout>
      <div style={{ margin: '20px' }}>
        <label>Filter by Service:</label>

        <SelectWithDefault
          popupMatchSelectWidth={false}
          allowClear
          useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          onChange={(value) => setSelectedService(value)} // Update the selected service
        />
      </div>
      {milestones.map((milestone, index) => (
        <Card
          key={milestone.id}
          style={{ marginBottom: 20, marginLeft: 20, marginRight: 20 }}>
          <MilestoneForm
            milestone={milestone}
            onUpdate={(updatedMilestone) =>
              updateMilestone(index, updatedMilestone)
            }
            onRemove={() => removeMilestone(index)}
            refetch={refetch}
            hasModifyMilestonePermission={modifyMilestone?.can || false}
          />
        </Card>
      ))}
      <FloatButton
        icon={<SaveFilled />}
        type="primary"
        onClick={bulkSave}
        style={{ insetInlineEnd: 24 }}
      />
      <FloatButton
        icon={<FaPlus />}
        tooltip={'Add Milestone'}
        type="primary"
        onClick={addMilestone}
        style={{ insetInlineEnd: 94 }}
      />
    </Layout>
  )
}

const MilestoneForm: React.FC<{
  milestone: any
  onUpdate: (milestone: any) => void
  onRemove: () => void
  refetch: () => void
  hasModifyMilestonePermission: boolean
}> = ({
  milestone,
  onUpdate,
  onRemove,
  refetch,
  hasModifyMilestonePermission,
}) => {
  const [form] = Form.useForm()
  const { mutate: destroy } = useDelete()
  const onFieldsChange = () => {
    const updatedMilestone = form.getFieldsValue(true)
    onUpdate(updatedMilestone)
  }
  return (
    <Form
      form={form}
      initialValues={milestone}
      onFieldsChange={onFieldsChange}
      disabled={!hasModifyMilestonePermission}>
      <Space key="timeline-form" align="baseline">
        <Form.Item
          name="name"
          label="Milestone Name"
          rules={[{ required: true, message: 'Missing Milestone Name' }]}>
          <Input placeholder="Enter milestone name" />
        </Form.Item>
        <Form.Item name="service" label="Service">
          <SelectWithDefault
            popupMatchSelectWidth={false}
            allowClear
            useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Form.Item
          name={['assigned_to_pref']}
          label="Assign to"
          rules={[{ required: true, message: 'Missing date preference' }]}>
          <Select
            options={createOptionsFromEnum(AssigneePref)}
            popupMatchSelectWidth={false}
          />
        </Form.Item>{' '}
        <Form.Item name="is_internal" label="Is internal milestone">
          <Switch defaultChecked={milestone.is_internal} />
        </Form.Item>
      </Space>

      {/* Target Date Options */}
      <Form.List name="target_dates">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{ display: 'flex', marginBottom: 8 }}
                align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'event_horizon']}
                  label="Date Horizon"
                  rules={[{ required: true, message: 'Missing date horizon' }]}>
                  <SelectWithDefault
                    popupMatchSelectWidth={false}
                    useSelectProps={{
                      resource: EVENT_HORIZON_OPTION_URL,
                      optionLabel: 'name',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'event_date_preference']}
                  label="Event Preference"
                  rules={[
                    { required: true, message: 'Missing date preference' },
                  ]}>
                  <Select
                    options={createOptionsFromEnum(DatePref)}
                    popupMatchSelectWidth={false}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'event_date_offset']}
                  label="Event Offset"
                  rules={[
                    { required: true, message: 'Missing event date offset' },
                  ]}>
                  <Input
                    type="number"
                    placeholder="Enter event date offset"
                    style={{ width: '60px' }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'is_before_event']}
                  label="Before Event">
                  <Select placeholder="Select">
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'booking_date_offset']}
                  label="Booking Offset"
                  rules={[
                    { required: true, message: 'Missing booking date offset' },
                  ]}>
                  <Input
                    type="number"
                    placeholder="Enter booking date offset"
                    style={{ width: '60px' }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'is_before_booking']}
                  label="Before Booking">
                  <Select placeholder="Select">
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'date_preference']}
                  label="Date Preference"
                  rules={[
                    { required: true, message: 'Missing date preference' },
                  ]}>
                  <Select
                    options={createOptionsFromEnum(DatePref)}
                    popupMatchSelectWidth={false}
                  />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => {
                    const targetDateId = form.getFieldValue([
                      'target_dates',
                      name,
                      'id',
                    ])
                    if (targetDateId) {
                      // If target date has an id, make API call to delete it
                      destroy(
                        {
                          resource: TARGET_DATE_OPTION_URL,
                          id: targetDateId,
                        },
                        { onSuccess: () => refetch() },
                      )
                    }
                    // Remove the form field from the UI
                    remove(name)
                  }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Add Target Date Option
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Button type="primary" danger onClick={onRemove}>
        Remove Milestone
      </Button>
    </Form>
  )
}

export default MilestoneManager
