import {
  DateField,
  Edit,
  useModal,
  useModalForm,
  useSimpleList,
} from '@refinedev/antd'
import { IResourceComponentsProps, useCan } from '@refinedev/core'
import {
  List as AList,
  Button,
  Card,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tabs,
} from 'antd'
import { useForm } from 'components/hooks'
import {
  IStockAdjustment,
  IStockItem,
  IStockItemChangeLog,
  IWarehouse,
  StockAdjustmentType,
} from 'interfaces/wms'
import {
  STOCK_ADJUSTMENT_URL,
  STOCK_ITEM_CHANGE_LOG_URL,
  STOCK_ITEM_URL,
} from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import { MaterialForm } from './Form'
import { IMaterial } from 'interfaces/material'

export const MaterialsEdit: React.FC<IResourceComponentsProps> = () => {
  const useFormProps = useForm<IMaterial>()

  const { listProps } = useSimpleList<IStockItem>({
    resource: STOCK_ITEM_URL,
    filters: {
      permanent: [
        { field: 'material', operator: 'eq', value: useFormProps.id },
        { field: 'expand', operator: 'eq', value: 'warehouse' },
      ],
    },
    pagination: { mode: 'off' },
  })

  const { data: updateMaterialPerm } = useCan({
    resource: 'change_material',
    action: '',
  })
  return (
    <>
      <Edit saveButtonProps={useFormProps.saveButtonProps}>
        <Tabs>
          <Tabs.TabPane tab="Basic" key="basic">
            <MaterialForm
              formProps={useFormProps.formProps}
              queryResult={useFormProps.queryResult}
              disabled={!updateMaterialPerm?.can}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Stock" key="stock">
            <AList
              {...listProps}
              grid={{ gutter: 16, column: 4 }}
              renderItem={(item) => (
                <AList.Item>
                  <StockItemCard item={item} />
                </AList.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </Edit>
    </>
  )
}

const StockItemCard = ({ item }: { item: IStockItem }) => {
  return (
    <Card
      size="small"
      title={(item.warehouse as IWarehouse).name}
      actions={[
        <StockItemChangeLogModal key="log" item={item} />,
        <StockAdjustmentModal key="adjustments" item={item} />,
      ]}>
      Item at hand: {item.quantity_on_hand}
    </Card>
  )
}

const StockAdjustmentModal = ({ item }: { item: IStockItem }) => {
  const { modalProps, show } = useModal()

  const {
    modalProps: SAModelProps,
    show: SAModalShow,
    formProps,
  } = useModalForm({
    redirect: false,
    resource: STOCK_ADJUSTMENT_URL,
    action: 'create',
  })

  const { listProps } = useSimpleList<IStockAdjustment>({
    resource: STOCK_ADJUSTMENT_URL,
    filters: {
      permanent: [{ field: 'stock_item', operator: 'eq', value: item.id }],
    },
    queryOptions: {
      enabled: modalProps.open,
    },
  })

  return (
    <>
      <Button onClick={() => show()}>Adjustments</Button>
      <Modal {...SAModelProps} title="Adjustment Stocks" zIndex={1001}>
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values) =>
            formProps.onFinish?.({ ...values, stock_item: item.id })
          }>
          <Form.Item
            name="adjustment_type"
            label="Type"
            rules={[{ required: true }]}>
            <Select
              options={createOptionsFromEnum(StockAdjustmentType)}
              popupMatchSelectWidth
            />
          </Form.Item>
          <Form.Item
            name="quantity_adjusted"
            label="Quantity to be Adjusted"
            rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        {...modalProps}
        title="Stock Adjustments"
        footer={<Button onClick={() => SAModalShow()}>Adjust Stock</Button>}>
        <AList
          {...listProps}
          size="small"
          renderItem={(item) => (
            <Card size="small" title={item.adjustment_type}>
              <Descriptions size="small">
                <Descriptions.Item label="Change Date">
                  <DateField value={item.created_at} format="LLL" />
                </Descriptions.Item>
                <Descriptions.Item label="Quantity Adjusted">
                  {item.quantity_adjusted}
                </Descriptions.Item>
                <Descriptions.Item label="Reason">
                  {item.reason}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        />
      </Modal>
    </>
  )
}

const StockItemChangeLogModal = ({ item }: { item: IStockItem }) => {
  const { modalProps, show } = useModal()

  const { listProps } = useSimpleList<IStockItemChangeLog>({
    resource: STOCK_ITEM_CHANGE_LOG_URL,
    filters: {
      permanent: [{ field: 'stock_item', operator: 'eq', value: item.id }],
    },
    queryOptions: {
      enabled: modalProps.open,
    },
  })

  return (
    <>
      <Button onClick={() => show()}>Logs</Button>
      <Modal {...modalProps} title="Change Logs" footer={null}>
        <AList
          {...listProps}
          size="small"
          renderItem={(item) => (
            <Card size="small" title={item.change_reason}>
              <Descriptions size="small">
                <Descriptions.Item label="Change Date">
                  <DateField value={item.created_at} format="LLL" />
                </Descriptions.Item>
                <Descriptions.Item label="Quantity Before">
                  {item.quantity_before}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity After">
                  {item.quantity_after}
                </Descriptions.Item>
                {item.description && (
                  <Descriptions.Item label="Description">
                    {item.description}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Card>
          )}
        />
      </Modal>
    </>
  )
}
