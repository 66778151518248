import { CreateButton, List, useTable, useTableReturnType } from "@refinedev/antd";
import { Table, Form, Radio } from "antd";
import { useCan, useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { ISection } from 'interfaces/section'
import { useState } from 'react'
import { SECTION_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const SectionList: React.FC = () => {
  const tProps = useTable<ISection, any, { is_active: string }>({
    onSearch: ({ is_active }) => {
      return [
        {
          field: 'is_active',
          operator: 'eq',
          value:
            is_active === 'All' ? null : is_active === 'Active' ? true : false,
        },
      ]
    },

    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const { data: addSectionPerm } = useCan({
    resource: 'can_add_package_section',
    action: '',
  })

  const { data: updateSectionPerm } = useCan({
    resource: 'can_update_package_section',
    action: '',
  })

  const { data: deleteSectionPerm } = useCan({
    resource: 'can_delete_package_section',
    action: '',
  })

  const deleteMany = () => {
    mutate({
      resource: 'package_tool/sections',
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const Filter: React.FC<{
    tProps: useTableReturnType<ISection, any, any>
  }> = ({ tProps }) => {
    return (
      <Form
        {...tProps.searchFormProps}
        key="search"
        layout="inline"
        initialValues={getSearchFormInitialValue(tProps.filters, {
          is_active: (value: string) =>
            value === 'true' ? 'Active' : 'Inactive',
        })}>
        <Form.Item name="is_active" initialValue="All">
          <Radio.Group
            onChange={tProps.searchFormProps?.form?.submit}
            options={['Active', 'All', 'Inactive']}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </Form>
    )
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && deleteSectionPerm?.can && (
        <DeleteButton onClick={deleteMany} />
      ),
      <Filter tProps={tProps} key="filter" />,
      addSectionPerm?.can && <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => {
            if (updateSectionPerm?.can) edit(SECTION_URL, record.id)
          },
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
      </Table>
    </List>
  )
}
