import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useCan, useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton, LogButton } from 'components/button'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { ICategory } from 'interfaces/category'
import { useState } from 'react'
import { CATEGORY_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const CategoryList: React.FC = () => {
  const tProps = useTable<ICategory, any, { search: string }>({
    resource: 'cms/category/structured',
    syncWithLocation: true,

    onSearch: ({ search }) => [
      {
        field: 'search',
        operator: 'eq',
        value: search,
      },
    ],

    pagination: {
      pageSize: 1000
    }
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: CATEGORY_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const { data: writeCatalog } = useCan({
    resource: 'write_catalog',
    action: '',
  })

  const pageHeaderProps = {
    extra: [
      writeCatalog?.can && !!selectedRowKeys.length && (
        <DeleteButton key="delete" onClick={deleteMany} />
      ),
      <LogButton key="log" />,
      <TableSearchInput
        searchFormProps={tProps.searchFormProps}
        key="search"
        intialValues={getSearchFormInitialValue(tProps.filters)}
      />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => edit(CATEGORY_URL, record.id),
        })}
        indentSize={50}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
        <Table.Column dataIndex="order" title="Order" ellipsis />
      </Table>
    </List>
  );
}
