import { Result } from "antd";
import { CanAccess } from "@refinedev/core";

export const CheckAccess: React.FC<{
  resource?: string
  action?: string
  forceRestrict?: boolean
  children?: any
}> = ({ resource, action = '', forceRestrict, children }) => {
  return (
    <CanAccess
      resource={resource as string}
      action={action}
      fallback={
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      }>
      {forceRestrict ? (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      ) : (
        children
      )}
    </CanAccess>
  )
}
