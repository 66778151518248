import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateField, useTable } from "@refinedev/antd";
import { Button, Modal, Space, Table } from "antd";
import { BaseKey, ResourceRouterParams, useModal, useRouterContext } from "@refinedev/core";
import { CONTENT_TYPE } from 'consts'
import { ILogDetailed } from 'interfaces/base'
import { IStaff } from 'interfaces/staff'
import capitalize from 'lodash/capitalize'
import ReactJson from 'react-json-view'
import { LOG_URL, SHORTLIST_PACKAGE_URL } from '../../urls'

export const LogButton: React.FC<{ resource?: string; id?: BaseKey }> = ({
  resource,
  id,
}) => {
  const { visible, show, close } = useModal()

  const { useParams } = useRouterContext()
  const { resource: routeResourceName, id: idFromRoute } =
    useParams<ResourceRouterParams>()

  // Seperate log endpoint for a shortlist package containing logs for package, events/packageDetails/ line items and alternate products.
  const log_url = (routeResourceName === SHORTLIST_PACKAGE_URL && idFromRoute) ? `${SHORTLIST_PACKAGE_URL}/${idFromRoute}/get_logs` : LOG_URL
  const { tableProps } = useTable<ILogDetailed>({
    resource: log_url,
    queryOptions: { enabled: visible },

    filters: {
      initial: [
        {
          field: 'content_type__model',
          operator: 'eq',
          value: CONTENT_TYPE[resource || routeResourceName]?.model,
        },
        {
          field: 'object_id',
          operator: 'eq',
          value: id || idFromRoute,
        },
      ]
    }
  })
  return (
    <>
      <Button onClick={() => show()}>
        <Space>
          <FontAwesomeIcon icon={['fas', 'clipboard-list']} />
          Logs
        </Space>
      </Button>
      <Modal
        visible={visible}
        onCancel={close}
        title="Logs"
        destroyOnClose
        footer={null}>
        <Table
          {...tableProps}
          rowKey="id"
          size="small"
          expandable={{
            expandedRowRender: (record) => (
              <ReactJson src={record.content} collapsed />
            ),
          }}>
          <Table.Column dataIndex={['user', 'username']} title="Phone" />
          <Table.Column<ILogDetailed>
            dataIndex="user"
            title="Name"
            render={(value: IStaff) =>
              `${value?.first_name} ${value?.last_name}`
            }
          />
          <Table.Column
            dataIndex="action"
            title="Action"
            render={(value) => capitalize(value)}
          />
          <Table.Column dataIndex="object_id" title="Object ID" />
          <Table.Column
            dataIndex={['content_type', 'name']}
            title="Object Type"
            render={(value) => capitalize(value)}
          />
          <Table.Column<ILogDetailed>
            dataIndex="created_at"
            title="Date Time"
            render={(value) => <DateField value={value} format="LLL" />}
          />
        </Table>
      </Modal>
    </>
  )
}
