import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IPackage } from 'interfaces/package'
import { PackageForm } from './Form'

export const PackageCreate: React.FC = () => {
  const useFormProps = useForm<IPackage>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <PackageForm useFormProps={useFormProps} />
    </Create>
  )
}
