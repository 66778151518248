import { Form, FormProps, Input, Switch } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const SectionForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" initialValue={''}>
        <ReactQuill theme="snow" />
      </Form.Item>
      <Form.Item label="Status" name="is_active" valuePropName="checked">
        <Switch checkedChildren={'Active'} unCheckedChildren={'Inactive'} />
      </Form.Item>
    </Form>
  )
}
