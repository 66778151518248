import { List, useTable, CreateButton, ImageField } from '@refinedev/antd'
import { Table } from 'antd'
import { useDeleteMany, useNavigation } from '@refinedev/core'
import { DeleteButton } from 'components/button'
import { useState } from 'react'
import { MOODBOARDIMAGES_URL } from 'urls'

export const MoodBoardImageList: React.FC = () => {
  const tProps = useTable<any, any, { is_active: string }>({
    syncWithLocation: true,
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate: deleteItems } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    deleteItems({
      resource: MOODBOARDIMAGES_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: (event) => {
            edit(MOODBOARDIMAGES_URL, record.id)
          },
        })}>
        <Table.Column
          title="Image"
          ellipsis
          render={(_, record: any) => (
            <ImageField value={record.image} width={150} height={150} />
          )}
        />
        <Table.Column dataIndex="name" title="Name" ellipsis />
      </Table>
    </List>
  )
}
