import { useSelect } from "@refinedev/antd";
import { Button, Divider, Form, Input, Select, Switch, Tabs } from "antd";
import { useCan } from "@refinedev/core";
import { useSelectWithSearch } from 'components/hooks/useSelectWithSearch'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ICustomer, IEvent } from 'interfaces/customer'
import { UseFormReturnType } from 'interfaces/refine'
import { IShortlistPackage } from 'interfaces/shortlistPackage'
import { IStaff } from 'interfaces/staff'
import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { STAFF_URL } from 'urls'
// import { ShortlistPackageSectionForm } from './section'
import { ShortlistPackageDetail } from "."

export const ShortlistPackageForm: React.FC<{
  useFormProps: UseFormReturnType<IShortlistPackage>
  eventId?: string
}> = ({ useFormProps, eventId }) => {
  const { formProps, id, queryResult } = useFormProps
  const [activeTab, setActiveTab] = useState('basic')

  const [selectedCustomer, setSelectedCustomer] = useState<string>()

  const {
    selectProps: customerSelectProps,
    queryResult: customerQueryResult,
    defaultValueQueryResult: customerDefaultQueryResult,
  } = useSelectWithSearch<ICustomer>({
    resource: 'package_tool/customers',
    optionLabel: 'username',
    optionValue: 'id',
    queryOptions: {
      enabled: !eventId,
    },
    defaultValue: queryResult?.data?.data.customer.id,
    defaultValueQueryOptions: {
      enabled: !!queryResult?.isFetched,
    },
  })

  const { selectProps: eventSelectProps } = useSelect<IEvent>({
    resource: `package_tool/customers/${selectedCustomer || queryResult?.data?.data.customer
      }/events`,

    optionLabel: 'name',
    optionValue: 'id',

    queryOptions: {
      enabled: !!selectedCustomer || !!queryResult?.data?.data.customer,
    },

    defaultValue: queryResult?.data?.data.event.id,

    defaultValueQueryOptions: {
      enabled: !!queryResult?.data?.data.customer,
    },

    pagination: {
      mode: "server"
    }
  })

  const { data: changeConsultant } = useCan({
    resource: 'change_consultant',
    action: '',
  })

  const { data: finalizePackage } = useCan({
    resource: 'finalize_package',
    action: '',
  })

  const onFinish = (values: any) => {
    if (eventId) values['event'] = eventId
    useFormProps.formProps.onFinish &&
      useFormProps.formProps.onFinish({ ...values })
  }

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane tab="Basic Details" key="basic" forceRender>
          {!eventId && (
            <>
              <Form.Item
                name="customer"
                label="Customer"
                rules={[{ required: true }]}>
                <Select
                  {...customerSelectProps}
                  options={undefined}
                  onChange={(value) => setSelectedCustomer(value as any)}>
                  {customerQueryResult.data?.data
                    .concat(customerDefaultQueryResult.data?.data ?? [])
                    .map((customer) => (
                      <Select.Option key={customer.id} value={customer.id}>
                        {customer.first_name} {customer.last_name} - (
                        {customer.username})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="event"
                label="Event"
                rules={[{ required: true }]}>
                <Select {...eventSelectProps} />
              </Form.Item>
            </>
          )}
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Discount Amount" name="discount_amount">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" initialValue={''}>
            <ReactQuill theme="snow" />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Settings" key="settings" forceRender>
          <Form.Item name="consultant" label="Consultant">
            <SelectWithDefault<IStaff>
              useSelectProps={{ resource: STAFF_URL }}
              disabled={!changeConsultant?.can}
              renderOptions={(staffs) =>
                staffs?.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.first_name} {staff.last_name} - ({staff.username})
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
          <Form.Item name="project_manager" label="Project Manager">
            <SelectWithDefault<IStaff>
              useSelectProps={{ resource: STAFF_URL }}
              disabled={!finalizePackage?.can}
              renderOptions={(staffs) =>
                staffs?.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.first_name} {staff.last_name} - ({staff.username})
                  </Select.Option>
                ))
              }
            />
          </Form.Item>
          <Form.Item
            label="Is Finalized"
            name="is_finalized"
            valuePropName="checked">
            <Switch disabled={!finalizePackage?.can} />
          </Form.Item>
          <Form.Item
            label="Is Link Active"
            name="is_link_active"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Is individual item price shown"
            name="is_individual_price_shown"
            valuePropName="checked"
            initialValue={true}>
            <Switch />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}
