import { CreateButton, List, useTable } from "@refinedev/antd";
import { Card, Col, Form, Image, Input, Row, Select, Table } from "antd";
import { BaseKey, HttpError, useDeleteMany, useNavigation, useUpdate } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { EyeToggle } from 'components/input/EyeToggle'
import { FABRIC, FLORAL, STRUCTURE } from 'consts'
import { IPricingItem } from 'interfaces/pricing'
import { useState } from 'react'
import { PRICING_ITEM_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const PricingItemList: React.FC = () => {
  const tProps = useTable<
    IPricingItem,
    HttpError,
    { search: string; category: string }
  >({
    onSearch: (data) => [
      { field: 'search', operator: 'eq', value: data.search },
      { field: 'category', operator: 'eq', value: data.category },
    ],

    syncWithLocation: true,

    pagination: {
      pageSize: 100
    }
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const { mutate: updateItem } = useUpdate()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: PRICING_ITEM_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  const handleToggle = (id: BaseKey) => {
    updateItem({
      resource: PRICING_ITEM_URL,
      id: `${id}/toggle_visibility`,
      values: {},
    })
  }

  return (
    <Row gutter={[8, 8]}>
      <Col lg={4} sm={24}>
        <Card title="Filters" size="small">
          <Form
            {...tProps.searchFormProps}
            layout="vertical"
            initialValues={getSearchFormInitialValue(tProps.filters)}>
            <Form.Item name="search" label="Search">
              <Input.Search
                allowClear
                onSearch={(value) => tProps?.searchFormProps?.form?.submit()}
              />
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Select
                allowClear
                onChange={() => tProps?.searchFormProps?.form?.submit()}
                options={[
                  { value: STRUCTURE, label: STRUCTURE },
                  { value: FLORAL, label: FLORAL },
                  { value: FABRIC, label: FABRIC },
                ]}
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col lg={20} sm={24}>
        <List headerProps={pageHeaderProps}>
          <Table
            {...tProps.tableProps}
            rowKey="id"
            rowSelection={rowSelection}
            size="small"
            onRow={(record) => ({
              onClick: (event) => edit(PRICING_ITEM_URL, record.id),
            })}>
            <Table.Column
              dataIndex={['image']}
              title="Image"
              render={(value: string) => (
                <div onClick={(e) => e.stopPropagation()}>
                  <Image src={value} height={75} width={75} />
                </div>
              )}
            />
            <Table.Column dataIndex={['name']} title="Name" />
            <Table.Column dataIndex={['category']} title="Category" />
            <Table.Column<IPricingItem>
              dataIndex={['is_visible']}
              title="Visible"
              render={(value: boolean, record) => (
                <div onClick={(e) => e.stopPropagation()}>
                  <EyeToggle
                    value={value}
                    onChange={() => handleToggle(record.id)}
                  />
                </div>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
}
