import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { IDish } from 'interfaces/catering'
import { DishForm } from './Form'

export const DishCreate: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IDish>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <DishForm formProps={formProps} queryResult={queryResult} />
    </Create>
  )
}
