import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Space, notification } from 'antd'
import { BaseKey, useUpdate } from '@refinedev/core'
import { PriceField } from 'components/field'

const TDS_Amount: React.FC<{
  tds_amount: number
  payout_id: BaseKey
}> = ({ tds_amount, payout_id }) => {
  const [tdsAmount, setTdsAmount] = useState(tds_amount)
  const [edit, setEdit] = useState<boolean>(false)
  const { mutate: patch, isLoading: isUpdating } = useUpdate()

  useEffect(() => {
    setTdsAmount(tds_amount)
  }, [tds_amount])

  const handleSave = () => {
    patch({
      resource: 'package_tool/payouts',
      id: payout_id,
      values: { tds_amount: tdsAmount },
    })
    setEdit(false)
  }

  const handleCancel = () => {
    setTdsAmount(tds_amount)
    setEdit(false)
  }

  return (
    <div>
      {edit ? (
        <Form layout="inline" onFinish={handleSave}>
          <Form.Item
            label="TDS Amount"
            rules={[
              { required: true, message: 'Please input the TDS amount!' },
            ]}>
            <Input
              value={tdsAmount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTdsAmount(Number(e.target.value))
              }
              placeholder="Enter TDS Amount"
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button htmlType="button" onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Form layout="inline">
            <Form.Item label="TDS Amount">
              <Space>
                <PriceField value={tdsAmount} />
                <Button type="link" onClick={() => setEdit(true)}>
                  Edit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  )
}

export default TDS_Amount
