import {
  BaseRecord,
  GetManyResponse,
  HttpError,
  LiveModeProps,
  MetaDataQuery,
  OpenNotificationParams,
  useCheckError,
  useHandleNotification,
  useResourceSubscription,
  useTranslate,
} from "@refinedev/core";
import {
  QueryObserverResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { DataContext } from 'App'
import { ExtendedDataContext } from 'drfDataProvider'
import { useContext } from 'react'

export type UseManyProps<TData, TError> = {
  resource: string
  ids: string[]
  queryOptions?: UseQueryOptions<GetManyResponse<TData>, TError>
  successNotification?: OpenNotificationParams | false
  errorNotification?: OpenNotificationParams | false
  metaData?: MetaDataQuery
} & LiveModeProps

/**
 *  @deprecated The method should not be used, kept for reference purposes only
 *
 * `useMany` is a modified version of `react-query`'s {@link https://react-query.tanstack.com/guides/queries `useQuery`} used for retrieving multiple items from a `resource`.
 *
 * It uses `getMany` method as query function from the `dataProvider` which is passed to `<Refine>`.
 *
 * @see {@link https://refine.dev/docs/core/hooks/data/useMany} for more details.
 *
 * @typeParam TData - Result data of the query extends {@link https://refine.dev/docs/core/interfaceReferences#baserecord `BaseRecord`}
 * @typeParam TError - Custom error object that extends {@link https://refine.dev/docs/core/interfaceReferences#httperror `HttpError`}
 *
 */
export const useManyDetail = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
>({
  resource,
  ids,
  queryOptions,
  successNotification,
  errorNotification,
  metaData,
  liveMode,
  onLiveEvent,
  liveParams,
}: UseManyProps<TData, TError>): QueryObserverResult<
  GetManyResponse<TData>
> => {
  const { getManyDetail } = useContext<ExtendedDataContext>(DataContext)
  const translate = useTranslate()
  const { mutate: checkError } = useCheckError({
    v3LegacyAuthProviderCompatible: true
  })
  const handleNotification = useHandleNotification()

  const isEnabled =
    queryOptions?.enabled === undefined || queryOptions?.enabled === true

  useResourceSubscription({
    resource,
    types: ['*'],
    params: {
      ids: ids ? ids?.map(String) : [],
      ...liveParams,
      subscriptionType: 'useMany',
    },
    channel: `resources/${resource}`,
    enabled: isEnabled,
    liveMode,
    onLiveEvent,
  })

  const queryResponse = useQuery<GetManyResponse<TData>, TError>(
    [`resource/getManyDetail/${resource}`, { ids, ...metaData }],
    () => getManyDetail!<TData>({ resource, ids, meta: metaData }),
    {
      ...queryOptions,
      onSuccess: (data: GetManyResponse<TData>) => {
        queryOptions?.onSuccess?.(data)
        handleNotification(successNotification)
      },
      onError: (err: TError) => {
        checkError(err)
        queryOptions?.onError?.(err)

        handleNotification(errorNotification, {
          key: `${ids[0]}-${resource}-getMany-notification`,
          message: translate(
            'notifications.error',
            { statusCode: err.statusCode },
            `Error (status code: ${err.statusCode})`,
          ),
          description: err.message,
          type: 'error',
        })
      },
    },
  )

  return queryResponse
}
