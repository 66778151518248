import { Edit } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { useCan, useCreate, useModal } from '@refinedev/core'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  Typography,
  notification,
} from 'antd'
import { ProductSelect } from 'components/input/productSelect'
import { ShortlistPackageContext } from 'contexts/shortlistPackage'
import { IProduct } from 'interfaces/product'
import { ChangeType } from 'interfaces/shortlistPackage'
import { useContext, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import ReactQuill from 'react-quill'
import { CHANGE_TRACKER_URL } from 'urls'
import { EditPackageContext, EditPackageContextType } from '../Edit'

const { Text } = Typography

export const EditShortlistLineItem: React.FC<{
  formProps: any
  saveButtonProps: any
  lineItemId?: string
  cancelEdit: any
  isAlternate?: boolean
  form: any
  refetch?: any
}> = ({
  formProps,
  lineItemId,
  saveButtonProps,
  cancelEdit,
  form,
  isAlternate = false,
  refetch,
}) => {
  const [product, setProduct] = useState<IProduct>()
  const [price, setPrice] = useState<number>(0)
  const [total, setTotal] = useState<number>()
  const [quantity, setQuantity] = useState<number>(0)

  const [changeDescription, setChangeDescription] = useState('')

  const {
    visible: visibleChangeDescription,
    show: showChangeDescription,
    close: closeChangeDescription,
  } = useModal()

  const _package = useContext(ShortlistPackageContext)

  const { setEditPackageStatus, isFinalized } = useContext(
    EditPackageContext,
  ) as EditPackageContextType

  const { mutate: createChangeTracker } = useCreate()

  const formPrice = Form.useWatch('price', form)

  useEffect(() => {
    const calculatedPrice = price * quantity
    if (
      (product !== undefined || formProps.initialValues['file']) &&
      quantity > 0
    ) {
      setTotal(calculatedPrice)
    }
  }, [price, quantity, product, formPrice])

  useEffect(() => {
    if (product) {
      if (product?.id !== formProps.initialValues['product']) {
        form.setFieldsValue({
          price: product?.price,
          description: product?.description ? product?.description : '',
        })
      } else {
        form.setFieldsValue({
          price: formProps.initialValues['price'],
          description: formProps.initialValues['description'],
        })
      }
      product?.price &&
        setPrice((old) => {
          return product?.price
        })
    }
  }, [product])

  useEffect(() => {
    setPrice(formPrice)
  }, [formPrice])

  useEffect(() => {
    setPrice(formProps.initialValues['price'])
    setTotal(
      formProps.initialValues['quantity'] * formProps.initialValues['price'],
    )
    setQuantity(formProps.initialValues['quantity'])
  }, [])

  const handleCreateTracker = (values: any) => {
    createChangeTracker(
      {
        resource: CHANGE_TRACKER_URL,
        values: {
          change_type: ChangeType.EDIT,
          line_item: lineItemId,
          package: _package?.id,
          change: {
            product: values.product,
            quantity: values.quantity,
            price: values.price,
            description: values.description,
          },
          description: changeDescription,
        },
      },
      {
        onSuccess: () => {
          cancelEdit()
          setEditPackageStatus(true)
        },
      },
    )
  }

  useHotkeys('ctrl+s, cmd+s', (e) => {
    if (!isFinalized) {
      e.preventDefault()
      notification.info({
        message: 'Saving',
        duration: 1,
        description: 'Save in progress',
      })
      formProps.form.submit()
    } else showChangeDescription()
  })

  const { data: validateItem } = useCan({
    resource: 'validate_line_item',
    action: '',
  })

  return (
    <div>
      <Edit
        goBack={false}
        breadcrumb={null}
        headerButtons={
          <>
            <Button
              size="small"
              onClick={() => {
                cancelEdit()
                setEditPackageStatus(true)
              }}
              icon={<Icons.CloseOutlined />}
            />
            <Button
              size="small"
              {...saveButtonProps}
              icon={<Icons.SaveOutlined />}
              onClick={() => {
                if (isFinalized) showChangeDescription()
                else formProps.form.submit()
              }}
            />
          </>
        }
        contentProps={{
          style: {
            backgroundColor: '#E9DDD4',
          },
        }}
        footerButtons={<></>}
        title="Edit Shortlist Item">
        <Form {...formProps} layout="vertical">
          {/* Order column does not exist in Alternate Table */}
          {!isAlternate && (
            <div
              style={{
                display: 'flex',
              }}>
              <Form.Item name={['order']}>
                <Input prefix="Order: " />
              </Form.Item>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {_package?.service_package.service.name !== 'Catering' && (
              <Form.Item
                name={'product'}
                label="Product"
                rules={[
                  {
                    required: true,
                    message: 'This is required',
                  },
                ]}>
                <ProductSelect setProduct={setProduct} />
              </Form.Item>
            )}
            <Form.Item
              name={'quantity'}
              label="Quantity"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <InputNumber<number>
                onChange={(value: any) => setQuantity(value)}
              />
            </Form.Item>
            <Form.Item
              name={'price'}
              label="Price"
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <InputNumber<number>
                prefix="₹"
                onChange={(value: any) => setPrice(value)}
              />
            </Form.Item>
            {!!formProps.initialValues['pricing_tool_price'] && (
              <Form.Item name="pricing_tool_price" label="Pricing Tool Price">
                <InputNumber<number> prefix="₹" disabled />
              </Form.Item>
            )}
            {isAlternate && (
              <Form.Item
                name={'price'}
                label="Price"
                rules={[
                  {
                    required: true,
                    message: 'This is required',
                  },
                ]}>
                <InputNumber<number>
                  onChange={(value: any) => setPrice(value)}
                  prefix="₹"
                />
              </Form.Item>
            )}
            <Text>Total: ₹{total}</Text>
          </div>
          <div>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'This is required',
                },
              ]}>
              <ReactQuill theme="snow" />
            </Form.Item>
          </div>
          {!isAlternate && (
            <div>
              <Form.Item
                label="Is Included"
                name="is_included"
                valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                label="Is Validated"
                name="is_validated"
                valuePropName="checked">
                <Switch disabled={!validateItem?.can} />
              </Form.Item>
            </div>
          )}
        </Form>
      </Edit>
      <Modal
        open={visibleChangeDescription}
        title="Request Changes?"
        okButtonProps={{
          disabled: !changeDescription.length,
        }}
        onOk={() => {
          handleCreateTracker(formProps.form.getFieldsValue(true))
          closeChangeDescription()
          setChangeDescription('')
        }}
        onCancel={() => {
          closeChangeDescription()
          setChangeDescription('')
        }}>
        <Input.TextArea
          value={changeDescription}
          onChange={(e) => setChangeDescription(e.target.value)}
          placeholder="Add a nice description informing about the changes"
        />
      </Modal>
    </div>
  )
}
