import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Card, Divider, Form, InputNumber, Select, Space, Typography } from "antd";
import { BaseKey, useApiUrl, useCustom } from "@refinedev/core";
import { GridSelect } from 'components/input/GridSelect'
import { ICombinationList } from 'interfaces/pricing'
import { useEffect, useState } from 'react'
import {
  COMBINATION_URL,
  FLOWER_URL,
  FOLIAGE_TYPE_URL,
  PRICING_ITEM_URL,
} from 'urls'
import { checkNumber, reduceByPercent } from 'utils/common'
import { PriceComponent } from './PriceComponent'
import { TotalComponent } from './TotalComponent'

const FlowerItem = ({
  flowerField,
  comboField,
  listName,
  itemId,
  remove,
  form,
  handleTotal,
}: {
  flowerField: any
  comboField: any
  listName: string
  itemId?: BaseKey
  remove: (name: any) => void
  form: any
  handleTotal: any
}) => {
  const apiUrl = useApiUrl()

  const [total, setTotal] = useState(0)

  const flowerId = Form.useWatch(
    [listName, comboField.name, 'flowers', flowerField.name, 'flower'],
    form,
  )

  const artificialRatio: number = Form.useWatch(
    [
      listName,
      comboField.name,
      'flowers',
      flowerField.name,
      'artificial_ratio',
    ],
    form,
  )

  const split = Form.useWatch(
    [listName, comboField.name, 'flowers', flowerField.name, 'split'],
    form,
  )

  const { data: comboData, refetch: getCombo } = useCustom<ICombinationList>({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: itemId,
        flower_id: flowerId,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const getTotal = () => {
    let workingArtificialRatio = checkNumber(artificialRatio)
    if (comboData?.data.allowed_artificial_percents?.length === 1) {
      workingArtificialRatio = 0
    }
    return reduceByPercent(
      reduceByPercent(comboData?.data.price as number, workingArtificialRatio),
      100 - split,
    )
  }

  useEffect(() => {
    // call get combos on the last flower id, if it is valid
    if (flowerId) {
      getCombo()
    }
  }, [itemId, flowerId])

  useEffect(() => {
    handleTotal((previousOverallTotal: number) => {
      return (
        checkNumber(previousOverallTotal) -
        checkNumber(total) +
        checkNumber(getTotal())
      )
    })
    const values = form.getFieldsValue(true)[listName][comboField.name]['flowers'][ flowerField.name]
    values.combination = comboData?.data.id
    setTotal(checkNumber(getTotal()))
    form.submit()
  }, [comboData?.data.id, artificialRatio, split])

  return (
    <Card
      size="small"
      extra={
        <Icons.DeleteOutlined
          key="remove"
          onClick={() => {
            remove(flowerField.name)
            handleTotal((previousOverallTotal: number) => {
              return checkNumber(previousOverallTotal) - checkNumber(total)
            })
            form.submit()
          }}
        />
      }>
      <Form.Item
        {...flowerField}
        label="Flower"
        name={[flowerField.name, 'flower']}
        noStyle
        style={{
          flex: 0.7,
        }}>
        <GridSelect
          useTableProps={{
            resource: FLOWER_URL,
            initialFilter: [
              {
                field: 'item_id',
                operator: 'eq',
                value: itemId,
              },
            ],
          }}
          title="Flowers"
          getName={(item) => `${item?.color.name} ${item?.flower_type.name}`}
          defaultQueryParam="?detailed=true"
        />
      </Form.Item>
      <div className="row">
      <PriceComponent value={comboData?.data?.price ?? 0} />
        <Divider type="vertical" />
        Total: {getTotal()?.toFixed(2) ?? 0}
      </div>
      <div className="row">
        {!!comboData?.data.allowed_artificial_percents?.length && (
          <Space direction="vertical" size={0.1}>
            AR
            <Form.Item
              {...flowerField}
              noStyle
              label="Artificial Ratio"
              name={[flowerField.name, 'artificial_ratio']}
              initialValue={
                comboData?.data.allowed_artificial_percents[0].reduction
              }>
              <Select
                options={comboData?.data.allowed_artificial_percents?.map(
                  (percent) => ({
                    label: percent.name,
                    value: percent.reduction,
                  }),
                )}
                defaultActiveFirstOption
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
          </Space>
        )}

        <Space direction="vertical" size={0.1}>
          Split
          <Form.Item
            {...flowerField}
            noStyle
            label="Split"
            name={[flowerField.name, 'split']}
            initialValue={100}>
            <InputNumber prefix="%" step={5} />
          </Form.Item>
        </Space>
      </div>
    </Card>
  )
}

const FoliageItem = ({
  foliageField,
  comboField,
  listName,
  itemId,
  remove,
  form,
  handleTotal,
}: {
  foliageField: any
  comboField: any
  listName: string
  itemId?: BaseKey
  remove: (name: any) => void
  form: any
  handleTotal: any
}) => {
  const apiUrl = useApiUrl()

  const [total, setTotal] = useState(0)

  const foliageId = Form.useWatch(
    [listName, comboField.name, 'foliages', foliageField.name, 'foliage'],
    form,
  )

  const artificialRatio: number = Form.useWatch(
    [
      listName,
      comboField.name,
      'foliages',
      foliageField.name,
      'artificial_ratio',
    ],
    form,
  )

  const split = Form.useWatch(
    [listName, comboField.name, 'foliages', foliageField.name, 'split'],
    form,
  )

  const { data: comboData, refetch: getCombo } = useCustom<ICombinationList>({
    url: `${apiUrl}/${COMBINATION_URL}/get_combo`,
    method: 'get',
    config: {
      query: {
        item_id: itemId,
        foliage_id: foliageId,
      },
    },
    queryOptions: {
      enabled: false,
    },
  })

  const getTotal = () => {
    let workingArtificialRatio = checkNumber(artificialRatio)
    if (comboData?.data.allowed_artificial_percents?.length === 1) {
      workingArtificialRatio = 0
    }
    return reduceByPercent(
      reduceByPercent(comboData?.data.price as number, workingArtificialRatio),
      100 - split,
    )
  }

  useEffect(() => {
    // call get combos on the last flower id, if it is valid
    if (foliageId) {
      getCombo()
    }
  }, [itemId, foliageId])

  useEffect(() => {
    handleTotal((previousOverallTotal: number) => {
      return (
        checkNumber(previousOverallTotal) -
        checkNumber(total) +
        checkNumber(getTotal())
      )
    })
    const values = form.getFieldsValue(true)[listName][comboField.name]['foliages'][foliageField.name]
    values.combination = comboData?.data.id
    setTotal(checkNumber(getTotal()))
    form.submit()
  }, [comboData?.data.id, artificialRatio, split])

  return (
    <Card
      size="small"
      extra={
        <Icons.DeleteOutlined
          key="remove"
          onClick={() => {
            remove(foliageField.name)
            handleTotal((previousOverallTotal: number) => {
              return checkNumber(previousOverallTotal) - checkNumber(total)
            })
            form.submit()
          }}
        />
      }>
      <Form.Item
        {...foliageField}
        label="Foliage"
        name={[foliageField.name, 'foliage']}
        noStyle
        style={{
          flex: 0.7,
        }}>
        <GridSelect
          useTableProps={{
            resource: FOLIAGE_TYPE_URL,
            initialFilter: [
              {
                field: 'item_id',
                operator: 'eq',
                value: itemId,
              },
            ],
          }}
          title="Foliages"
        />
      </Form.Item>
      <div className="row">
        <PriceComponent value={comboData?.data?.price ?? 0} />
        <Divider type="vertical" />
        Total: {getTotal()?.toFixed(2) ?? 0}
      </div>
      <div className="row">
        {!!comboData?.data.allowed_artificial_percents?.length && (
          <Space direction="vertical" size={0.1}>
            AR
            <Form.Item
              {...foliageField}
              noStyle
              label="Artificial Ratio"
              name={[foliageField.name, 'artificial_ratio']}
              initialValue={0}>
              <Select
                options={[
                  comboData?.data.allowed_artificial_percents?.map(
                    (percent) => ({
                      label: percent.name,
                      value: percent.reduction,
                    }),
                  ),
                ]}
                defaultActiveFirstOption
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
          </Space>
        )}
        <Space direction="vertical" size={0.1}>
          Split
          <Form.Item
            {...foliageField}
            noStyle
            label="Split"
            name={[foliageField.name, 'split']}
            initialValue={100}>
            <InputNumber prefix="%" step={5} />
          </Form.Item>
        </Space>
      </div>
    </Card>
  )
}

export const FloralCombo = ({ handleTotal, form, field, listName }: any) => {
  const itemId = Form.useWatch([listName, field.name, 'item'], form)

  const qty = Form.useWatch([listName, field.name, 'quantity'], form)

  const [flowerTotal, setFlowerTotal] = useState(0)
  const [foliageTotal, setFoliageTotal] = useState(0)

  const [flowerSplit, setFlowerSplit] = useState(0)
  const [foliageSplit, setFoliageSplit] = useState(0)
  const [splitTotal, setSplitTotal] = useState(0)

  useEffect(() => {
    const values = form.getFieldsValue(true)[listName]

    const total = checkNumber(flowerTotal) + checkNumber(foliageTotal)
    values[field.name].total = total * qty
    form.setFieldsValue({ ...values })
    form.submit()
  }, [flowerTotal, foliageTotal, qty])

  useEffect(() => {
    const values = form.getFieldsValue(true)[listName][field.name]
    const totalFlowerSplit = values?.flowers?.reduce(
      (prev: any, cur: any) => checkNumber(prev) + checkNumber(cur.split),
      0,
    )
    const totalFoliageSplit = values?.foliages?.reduce(
      (prev: any, cur: any) => checkNumber(prev) + checkNumber(cur.split),
      0,
    )
    setFlowerSplit(totalFlowerSplit)
    setFoliageSplit(totalFoliageSplit)
    setSplitTotal((totalFlowerSplit ?? 0) + (totalFoliageSplit ?? 0))
  }, [form.getFieldsValue(true)[listName][field.name]])

  return (
    <Card size="small">
      <div className="row">
        <Space direction="vertical">
          <Form.Item {...field} label="Item" name={[field.name, 'item']}>
            <GridSelect
              useTableProps={{
                resource: PRICING_ITEM_URL,
                permanentFilter: [
                  { field: 'category', operator: 'eq', value: 'FLORAL' },
                  { field: 'is_visible', operator: 'eq', value: true },
                ],
              }}
              title="Floral Items"
            />
          </Form.Item>
          <Space>
            <div>
              <FontAwesomeIcon icon={['fas', 'fan']} /> {flowerSplit ?? 0}%
            </div>
            <div>
              <FontAwesomeIcon icon={['fas', 'leaf']} /> {foliageSplit ?? 0}%
            </div>
          </Space>
          <div>
            <FontAwesomeIcon icon={['fas', 'equals']} />{' '}
            <Typography.Text
              style={{
                color: splitTotal !== 100 ? 'red' : undefined,
              }}>
              {checkNumber(splitTotal)}
            </Typography.Text>
            /100
          </div>
        </Space>
        <div
          style={{
            display: 'inline',
            whiteSpace: 'nowrap',
            width: '80%',
            overflowX: 'scroll',
          }}>
          <Form.List name={[field.name, 'flowers']}>
            {(fields, { add, remove }) => (
              <Space>
                {fields.map((flowerField) => (
                  <FlowerItem
                    key={flowerField.key}
                    flowerField={flowerField}
                    listName={listName}
                    comboField={field}
                    itemId={itemId}
                    remove={remove}
                    form={form}
                    handleTotal={setFlowerTotal}
                  />
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Flower
                </Button>
              </Space>
            )}
          </Form.List>
          <Form.List name={[field.name, 'foliages']}>
            {(fields, { add, remove }) => (
              <Space>
                {fields.map((foliageField) => (
                  <FoliageItem
                    key={foliageField.key}
                    foliageField={foliageField}
                    listName={listName}
                    comboField={field}
                    itemId={itemId}
                    remove={remove}
                    form={form}
                    handleTotal={setFoliageTotal}
                  />
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Foliage
                </Button>
              </Space>
            )}
          </Form.List>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Space>
          <Form.Item
            {...field}
            name={[field.name, 'quantity']}
            label={`QTY`}
            initialValue={1}>
            <InputNumber placeholder="QTY" />
          </Form.Item>
        </Space>
        <Form.Item {...field} name={[field.name, 'total']} initialValue={0}>
          <TotalComponent />
        </Form.Item>
      </div>
    </Card>
  )
}
