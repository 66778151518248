import {
  Card,
  Button,
  InputNumber,
  Form,
  Select,
  Descriptions,
  Row,
  Col,
  Spin,
} from 'antd'
import {
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import React, { useState } from 'react'
import { MAX_MATERIAL_SOURCE, VENDOR_URL, WAREHOUSE_URL } from 'urls'
import { DeleteButton, SaveButton, useForm, useSelect } from '@refinedev/antd'
import { BaseKey, useList } from '@refinedev/core'
import {
  IMaxMaterialSource,
  IMaxMaterialSourceForm,
} from 'interfaces/maxmaterialsource'
import { IVendor } from 'interfaces/vendor'

export const MaxMaterialSourceCard: React.FC<{
  maxMaterialSource: IMaxMaterialSource
  refetchMaxMaterialSource: () => void
}> = ({ maxMaterialSource, refetchMaxMaterialSource }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { formProps, saveButtonProps } = useForm<IMaxMaterialSourceForm>({
    resource: MAX_MATERIAL_SOURCE,
    id: maxMaterialSource.id,
    redirect: false,
    action: 'edit',
    invalidates: ['detail'],
    onMutationSuccess: () => refetchMaxMaterialSource(),
  })

  const { selectProps: warehouseSelectProps } = useSelect({
    resource: WAREHOUSE_URL,
    optionLabel: 'name',
  })

  const { selectProps: vendorSelectProps } = useSelect({
    resource: VENDOR_URL,
    optionLabel: 'name',
  })

  const handlePatch = (values: {}) => {
    const finalResponse = {
      ...values,
      package: maxMaterialSource.package,
      material: maxMaterialSource.material,
    }
    setIsEditing(false)
    return formProps?.onFinish && formProps?.onFinish(finalResponse)
  }

  return (
    <Card
      size="small"
      title={maxMaterialSource?.type}
      extra={
        <>
          {isEditing && (
            <Button size="small" icon={<SaveOutlined />} {...saveButtonProps} />
          )}
          <Button
            size="small"
            icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
            onClick={() => setIsEditing(!isEditing)}
          />
          <DeleteButton
            resource={MAX_MATERIAL_SOURCE}
            recordItemId={maxMaterialSource.id}
            icon={<DeleteOutlined />}
            size="small"
            invalidates={['detail']}
            onSuccess={refetchMaxMaterialSource}
            hideText
          />
        </>
      }>
      <Form {...formProps} onFinish={handlePatch} size="small">
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="PID">
            {maxMaterialSource.project ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="Quantity">
            {isEditing ? (
              <Form.Item name="qty" initialValue={maxMaterialSource?.qty}>
                <InputNumber precision={2} />
              </Form.Item>
            ) : (
              <>{maxMaterialSource?.qty}</>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              maxMaterialSource?.type === 'Warehouse' ? 'Warehouse' : 'Vendor'
            }>
            {isEditing ? (
              <Form.Item
                name={
                  maxMaterialSource?.type === 'Warehouse'
                    ? 'warehouse'
                    : 'vendor'
                }
                initialValue={
                  maxMaterialSource?.type === 'Warehouse'
                    ? maxMaterialSource?.warehouse?.id
                    : maxMaterialSource?.vendor?.id
                }>
                {maxMaterialSource?.type === 'Warehouse' ? (
                  <Select
                    {...warehouseSelectProps}
                    style={{
                      width: '200px',
                    }}
                  />
                ) : (
                  <Select
                    {...vendorSelectProps}
                    style={{
                      width: '200px',
                    }}
                  />
                )}
              </Form.Item>
            ) : (
              <>
                {maxMaterialSource.type === 'Warehouse' ? (
                  <>{maxMaterialSource?.warehouse?.name}</>
                ) : (
                  <>{maxMaterialSource?.vendor?.name} </>
                )}
              </>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </Card>
  )
}

export const CreateMaxMaterialSource: React.FC<{
  showId: BaseKey
  materialId: BaseKey
  refetchMaxMaterialSource: () => void
  uom: string
}> = ({ showId, materialId, refetchMaxMaterialSource, uom }) => {
  const [isFromWarehouse, setIsFromWarehouse] = useState<boolean>(true)

  const { formProps, saveButtonProps } = useForm<IMaxMaterialSourceForm>({
    resource: MAX_MATERIAL_SOURCE,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => refetchMaxMaterialSource(),
    invalidates: ['detail'],
  })

  const { queryResult: vendorData, selectProps: vendorSelectProps } =
    useSelect<IVendor>({
      resource: VENDOR_URL,
      optionLabel: 'name',
      onSearch: (value) => [
        {
          field: 'search',
          operator: 'eq',
          value: value,
        },
      ],
    })

  const { queryResult: warehouseData, selectProps: warehouseSelectProps } =
    useSelect({
      resource: WAREHOUSE_URL,
      optionLabel: 'name',
      onSearch: (value: string) => [
        {
          field: 'search',
          operator: 'eq',
          value: value,
        },
      ],
    })

  const onFinish = (values: {}) => {
    console.table({ ...values, package: showId, material: materialId })
    const finalResponse = {
      ...values,
      package: showId,
      material: materialId,
      uom: uom,
    }

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  return (
    <>
      {showId && (
        <Card title={'Add new Material Source'}>
          <Form {...formProps} onFinish={onFinish} layout="inline">
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={'Warehouse'}>
              <Select
                onChange={(value: string) =>
                  setIsFromWarehouse(value === 'Warehouse')
                }
                size="small">
                <Select.Option value={'Warehouse'}>Warehouse</Select.Option>
                <Select.Option value={'Buy'}>Buy</Select.Option>
                <Select.Option value={'Rent'}>Rent</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="qty"
              label="Quantity"
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputNumber precision={2} />
            </Form.Item>
            <Form.Item
              name={isFromWarehouse ? 'warehouse' : 'vendor'}
              label={isFromWarehouse ? 'Warehouse' : 'Vendor'}>
              {isFromWarehouse ? (
                <Select
                  {...warehouseSelectProps}
                  style={{
                    width: '200px',
                  }}
                  size="small"
                />
              ) : (
                <Select
                  {...vendorSelectProps}
                  style={{
                    width: '200px',
                  }}
                  size="small"
                />
              )}
            </Form.Item>
            <SaveButton {...saveButtonProps} />
          </Form>
        </Card>
      )}
    </>
  )
}

export const MaxMaterialSourceColumn: React.FC<{
  materialId: BaseKey
  packageId?: BaseKey
  uom: string
}> = ({ materialId, packageId, uom }) => {
  const {
    data: maxMaterialSourceData,
    isFetching: maxMaterialSourceFetching,
    refetch: maxMaterialSourceRefetch,
  } = useList<IMaxMaterialSource>({
    resource: MAX_MATERIAL_SOURCE,
    filters: [
      {
        field: 'material',
        operator: 'eq',
        value: materialId,
      },
      {
        field: 'package',
        operator: 'eq',
        value: packageId,
      },
      {
        field: 'expand',
        operator: 'eq',
        value: 'vendor,warehouse',
      },
      { field: 'uom', operator: 'eq', value: uom },
    ],
  })

  return (
    <>
      <Row gutter={[8, 8]} wrap>
        {maxMaterialSourceFetching ? (
          <Spin />
        ) : (
          <>
            {maxMaterialSourceData?.data &&
              maxMaterialSourceData.data?.map(
                (record: IMaxMaterialSource, idx: number) => {
                  return (
                    <Col key="maxMaterialSource">
                      <MaxMaterialSourceCard
                        maxMaterialSource={record}
                        refetchMaxMaterialSource={maxMaterialSourceRefetch}
                      />
                    </Col>
                  )
                },
              )}
            {maxMaterialSourceData?.total! > 0 ? (
              <>
                {maxMaterialSourceData?.data![0].current_qty! <
                maxMaterialSourceData?.data![0].max_qty_req! ? (
                  <>
                    <CreateMaxMaterialSource
                      showId={packageId ?? 0}
                      materialId={materialId}
                      uom={uom}
                      refetchMaxMaterialSource={maxMaterialSourceRefetch}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <CreateMaxMaterialSource
                  showId={packageId ?? 0}
                  materialId={materialId}
                  uom={uom}
                  refetchMaxMaterialSource={maxMaterialSourceRefetch}
                />
              </>
            )}
          </>
        )}
      </Row>
    </>
  )
}
