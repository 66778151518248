import { BaseKey, useUpdate } from '@refinedev/core'
import { Space } from 'antd'
import React, { useState } from 'react'

export const EditableField: React.FC<{
  InputField: React.FC<{
    disabled?: boolean
    onBlur: (e: any) => void
    defaultValue: any
  }>
  resource: string
  fieldName: string
  initialValue: any
  id: BaseKey
  refetch?: () => void
}> = ({ InputField, resource, fieldName, initialValue, id, refetch }) => {
  const [value, setValue] = useState<any>(initialValue)

  const { mutate } = useUpdate()

  const handleChange = (e: any) => {
    // Extract value that will be used to update the backend i.e. e can be either object or number
    let tempValue = null
    if (e) {
      tempValue = typeof e !== 'object' ? e : e.target.value
    }
    setValue(tempValue)
    mutate({
      resource: resource,
      id: id,
      values: {
        [fieldName]: tempValue,
      },
      invalidates: ['detail'],
      successNotification: false,
    })
  }

  return (
    <Space direction="horizontal">
      <InputField onBlur={handleChange} defaultValue={value} />
    </Space>
  )
}
