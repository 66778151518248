import { Card, Collapse, Button, List, Typography, Divider, InputNumber, Modal, Form } from 'antd';
import { DeleteButton, useSimpleList } from '@refinedev/antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player/file';
import { VENUE_SECTION_MEDIA_URL } from 'urls';
import { useApiUrl } from '@refinedev/core';
import { TOKEN_KEY, REGION_KEY } from 'authProvider';
import { IoRefresh } from 'react-icons/io5';
import { useState, useEffect } from 'react';

export const VenueSectionDetails = ({ section }: { section: any }) => {
  const apiUrl = useApiUrl();
  const authToken = localStorage.getItem(TOKEN_KEY);
  const region = localStorage.getItem(REGION_KEY);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingMediaItem, setEditingMediaItem] = useState<any | null>(null);
  const [form] = Form.useForm();

  // Fetch media items related to the section
  const { listProps, queryResult } = useSimpleList({
    resource: VENUE_SECTION_MEDIA_URL,
    filters: {
      permanent: [{ field: 'venue_section', operator: 'eq', value: section.id }],
    },
  });

  // Maintain an order counter to ensure unique order_id during simultaneous uploads
  let orderCounter = 0;

  // Calculate the next order_id for each new media
  const calculateOrderId = () => {
    const currentMediaCount = queryResult.data?.data.length || 0;
    orderCounter += 1;
    return currentMediaCount + orderCounter;
  };

  // Properties for the Dragger component
  const uploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/*,video/*',
    action: `${apiUrl}/${VENUE_SECTION_MEDIA_URL}/`,
    headers: {
      Authorization: `Token ${authToken}`,
      'X-Region': region || '',
    },
    data: (file: any) => ({
      media: file,
      venue_section: section.id,
      order_id: calculateOrderId(),
    }),
    onChange: (info: any) => {
      if (info.file.status === 'done') {
        queryResult.refetch();
      }
    },
  };

  useEffect(() => {
    if (editingMediaItem) {
      form.setFieldsValue({ order_id: editingMediaItem.order_id });
    }
  }, [editingMediaItem, form]);

  const isImage = (url: string) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = url.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(extension || '');
  };

  const handleEditOrder = (mediaItem: any) => {
    setEditingMediaItem(mediaItem);
    setIsEditModalVisible(true);
  };

  const handleSaveOrder = (values: any) => {
    // Call API to update order_id for the media item
    fetch(`${apiUrl}/${VENUE_SECTION_MEDIA_URL}/${editingMediaItem?.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({ order_id: values.order_id }),
    }).then(() => {
      queryResult.refetch();
      setIsEditModalVisible(false);
    });
  };

  return (
    <Collapse>
      <Collapse.Panel header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {section.cover_image && (
              <img
                src={section.cover_image}
                alt="Cover"
                style={{ width: '100px', height: '100px', marginRight: '16px', objectFit: 'contain', borderRadius: '4px' }}
              />
            )}
            <Typography.Text>{section.name}</Typography.Text>
          </div>
        } key={section.id} style={{ marginBottom: '16px' }}>
        <div style={{ marginBottom: '24px' }}>
          <Typography.Title level={5}>Upload Media (Images/Videos)</Typography.Title>
          <Dragger {...uploadProps} style={{ padding: '16px', borderRadius: '8px' }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for single or bulk file uploads. Please do not upload restricted or company-sensitive data.
            </p>
          </Dragger>
        </div>

        <Collapse ghost style={{ marginBottom: '24px' }}>
          <Collapse.Panel
            header={
              <Typography.Title level={5} style={{ margin: 0 }}>
                Uploaded Files ({queryResult.data?.data.filter((item) => item.media).length || 0})
              </Typography.Title>
            }
            key="uploaded_files"
            extra={
              <Button
                icon={<IoRefresh />}
                onClick={(e) => {
                  e.stopPropagation();
                  queryResult.refetch();
                }}
              />
            }
          >
            <List
              {...listProps}
              grid={{ gutter: 24, column: 3 }}
              renderItem={(mediaItem) => (
                <Card
                  hoverable
                  style={{ width: '100%', marginBottom: '24px', borderRadius: '8px', overflow: 'hidden' }}
                  cover={
                    isImage(mediaItem.media) ? (
                      <img src={mediaItem.media} alt="Media" style={{ width: '100%', height: '200px', objectFit: 'contain' }} />
                    ) : (
                      <ReactPlayer url={mediaItem.media} controls width="100%" height="200px" />
                    )
                  }
                  actions={[
                    <Button key="edit" onClick={() => handleEditOrder(mediaItem)}>
                      Edit Order
                    </Button>,
                    <DeleteButton
                      key={mediaItem.id}
                      resource={VENUE_SECTION_MEDIA_URL}
                      recordItemId={mediaItem.id}
                      onSuccess={() => queryResult.refetch()}
                    />,
                  ]}
                />
              )}
            />
          </Collapse.Panel>
        </Collapse>

        <Modal
          title="Edit Media Order"
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleSaveOrder}
            layout="vertical"
          >
            <Form.Item
              label="Order ID"
              name="order_id"
              rules={[{ required: true, message: 'Please enter the order ID' }]}
            >
              <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Collapse.Panel>
    </Collapse>
  );
};