import { UseFormReturnType } from '@refinedev/antd'
import { BaseKey } from '@refinedev/core'
import {
  Collapse,
  Form,
  FormProps,
  Input,
  InputNumber,
  Switch,
  Space,
  Select,
} from 'antd'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IVenue, MealScheduleType } from 'interfaces/venue'
import React from 'react'
import { SECTION_URL, VENUE_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'

export const SessionForm: React.FC<{
  formProps: FormProps
  shortlistPackageId: BaseKey
}> = ({ formProps, shortlistPackageId }) => {
  const onFinish = async (values: any) => {
    values['shortlist_package'] = shortlistPackageId
    return formProps.onFinish && formProps.onFinish(values)
  }

  const venueNotDecided = Form.useWatch('venue_not_decided', formProps.form)

  return (
    <Form size="small" layout="vertical" {...formProps} onFinish={onFinish}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="section" label="Event">
        <SelectWithDefault
          useSelectProps={{
            resource: SECTION_URL,
            optionLabel: 'name',
          }}
          placeholder="Event"
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item label="Meal Type" name={'meal_type'}>
        <Select options={createOptionsFromEnum(MealScheduleType)} />
      </Form.Item>
      <Space size="small" direction="horizontal">
        <Form.Item name="pax" label="Pax" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="order" label="Order" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
      </Space>
      <Collapse style={{ marginBottom: 25 }}>
        <Collapse.Panel header="Time and Venue" key="timenvenue" forceRender>
          <Form.Item
            name={'date_not_decided'}
            valuePropName="checked"
            label="Date not decided">
            <Switch />
          </Form.Item>
          <Form.Item
            name="date_time"
            label="Date and Time"
            rules={[
              {
                required: true,
                message: 'Event Date and Time is required field',
              },
            ]}>
            <DatePicker
              mode="date"
              showTime={{ use12Hours: true }}
              format={'YYYY-MM-DD hh:mm a'}
            />
          </Form.Item>
          <Form.Item
            name="venue_not_decided"
            label="Venue not Decided"
            valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            name="venue"
            label="Venue"
            rules={[{ required: !venueNotDecided }]}>
            <SelectWithDefault<IVenue>
              useSelectProps={{
                resource: VENUE_URL,
                optionLabel: 'name',
                optionValue: 'id',
              }}
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}
