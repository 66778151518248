import { NumberField } from '@refinedev/antd'
import { CSSProperties } from 'react'

export const PriceField: React.FC<{
  value: string | number
  style?: CSSProperties
  showDecimal?: boolean
  className?: string
}> = ({ value, style, showDecimal = true,className }) => {
  return (
    <NumberField
      value={value}
      locale={'en-IN'}
      style={style}
      className={className}
      options={{
        currency: 'INR',
        currencyDisplay: 'narrowSymbol',
        style: 'currency',
        minimumFractionDigits: showDecimal ? 2 : 0,
      }}
    />
  )
}
