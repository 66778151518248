import { Form, Input } from "antd";
import { REGION_KEY } from 'authProvider'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { UseFormReturnType } from 'interfaces/refine'
import { REGION_URL } from 'urls'

export const EventForm: React.FC<{
  useFormProps: UseFormReturnType
}> = ({ useFormProps }) => {
  return (
    <>
      <Form {...useFormProps.formProps} layout="vertical">
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          initialValue={Number(localStorage.getItem(REGION_KEY))}>
          <SelectWithDefault
            useSelectProps={{
              resource: REGION_URL,
              optionValue: 'id',
              optionLabel: 'name',
            }}
          />
        </Form.Item>
        <Form.Item name="project_id" label="Project ID">
          <Input />
        </Form.Item>
      </Form>
    </>
  )
}
