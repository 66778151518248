import { Form, FormProps, Input } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { SECTION_URL, SERVICE_URL } from 'urls'

export const PlanForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Budget" name="budget">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Order" name="order">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Max Product Count" name="max_product_count">
        <Input type="number" />
      </Form.Item>
      <Form.Item label="Service" name="service">
        <SelectWithDefault
          useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item label="Events" name="sections">
        <SelectWithDefault
          mode="multiple"
          useSelectProps={{ resource: SECTION_URL, optionLabel: 'name' }}
        />
      </Form.Item>
    </Form>
  )
}
