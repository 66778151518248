import {
  DeleteButton,
  EditButton,
  List,
  SaveButton,
  TextField,
  useEditableTable,
  useModalForm,
} from "@refinedev/antd";

import { Button, Form, Input, InputNumber, Modal, Space, Table } from "antd";
import { IArtificialRatio } from 'interfaces/pricing'

export const ArtificialRatioList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable<IArtificialRatio>({ syncWithLocation: true })

  const {
    modalProps,
    formProps: modalFormProps,
    show,
  } = useModalForm<IArtificialRatio>({
    action: 'create',
  })

  return (
    <List
      createButtonProps={{
        onClick: () => {
          show()
        },
      }}>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column key="id" dataIndex="id" title="ID" ellipsis />
          <Table.Column<IArtificialRatio>
            key="title"
            dataIndex="name"
            title="Name"
            ellipsis
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IArtificialRatio>
            key="reduction"
            dataIndex="reduction"
            title="Reduction"
            ellipsis
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="reduction" style={{ margin: 0 }}>
                    <InputNumber />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IArtificialRatio>
            key="value"
            dataIndex="value"
            title="Value"
            ellipsis
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="value" style={{ margin: 0 }}>
                    <InputNumber />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<IArtificialRatio>
            title="Actions"
            dataIndex="actions"
            key="actions"
            ellipsis
            render={(_text, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record.id)} size="small" />
                  <DeleteButton size="small" />
                </Space>
              )
            }}
          />
        </Table>
      </Form>
      <Modal {...modalProps}>
        <Form {...modalFormProps} layout="vertical">
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Reduction" name="reduction">
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </List>
  )
}
