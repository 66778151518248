import {
  CreateButton,
  ExportButton,
  List,
  TextField,
  useModalForm,
} from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Button, Form, Input, Modal, Space, Spin, Table, Tooltip } from 'antd'

import {
  BaseKey,
  useApiUrl,
  useCustomMutation,
  useExport,
  useMany,
  useModal,
  useRouterContext,
} from '@refinedev/core'

import { LogButton } from 'components/button'
import { PaddedImageField, PriceField } from 'components/field'
import { ICustomer } from 'interfaces/customer'
import { IProductImage } from 'interfaces/product'
import { UseFormReturnType } from 'interfaces/refine'
import { IServicePackage, IShortlistPackage } from 'interfaces/shortlistPackage'
import truncate from 'lodash/truncate'
import React, { useState } from 'react'
import StackGrid from 'react-stack-grid'
import { getUnique } from 'utils/common'
import { getSearchFormInitialValue } from 'utils/filter'
import { ShortlistPackageForm } from '.'
import { CloneModal } from './components/CloneModal'

export const ShortlistPackageTable: React.FC<{
  tProps: any
  eventId?: string
  forRFQ?: boolean
  title?: string
  allowOptionalFilter?: boolean
  OptionalFilter?: React.ReactElement
  lockPayout?: boolean
}> = ({
  tProps,
  eventId,
  title,
  forRFQ = false,
  allowOptionalFilter = false,
  OptionalFilter,
  lockPayout,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPackage, setSelectedPackage] = useState<IShortlistPackage>()

  const { Link } = useRouterContext()
  const apiUrl = useApiUrl()

  const {
    data: shortlistPackageImageData,
    isLoading: shortlistPackageImageDataIsLoading,
  } = useMany<{ package: string; images: IProductImage[] }>({
    resource: 'package_tool/shortlist_packages',
    ids:
      tProps.tableQueryResult.data?.data.map(
        (p: any) => `${p.id}/get_images`,
      ) ?? [],
    queryOptions: {
      enabled: tProps.tableQueryResult.isFetched && !forRFQ,
    },
  })

  const { triggerExport, isLoading: exportLoading } =
    useExport<IShortlistPackage>({
      mapData: (item) => {
        return {
          project_id: item?.event,
          price: item?.price,
          discount: item?.discount_amount,
          effective_total: item?.price - item?.discount_amount,
          is_selected: item?.is_selected,
          is_finalized: item?.is_finalized,
          is_executed: item?.is_executed,
        }
      },

      pageSize: 100,
      filters: tProps.filters,
      sorters: tProps.sorter,
    })

  const { data: customerData, isLoading: isCustomerLoading } =
    useMany<ICustomer>({
      resource: 'package_tool/customers',
      ids: getUnique(tProps.tableQueryResult.data?.data ?? [], 'customer'),
      queryOptions: {
        enabled: !!tProps.tableQueryResult.data?.data?.length,
      },
    })

  const { data: consultantData } = useMany<ICustomer>({
    resource: 'core/staff',
    ids: getUnique(tProps.tableQueryResult.data?.data ?? [], 'consultant'),
    queryOptions: {
      enabled: !!tProps.tableQueryResult.data?.data?.length,
    },
  })

  const { modalProps, formProps, show, queryResult, mutationResult } =
    useModalForm<IShortlistPackage>({
      action: 'create',
      resource: 'package_tool/shortlist_packages',
    })

  const {
    visible: cloneVisible,
    show: showClone,
    close: closeClone,
  } = useModal()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const { mutate: updatePackagePayout, isLoading: isLoadingPayout } =
    useCustomMutation<IShortlistPackage>()

  const onClickPayout = (event: React.MouseEvent) => {
    updatePackagePayout(
      {
        url: `${apiUrl}/package_tool/shortlist_packages/lock_package_payout/` as string,
        method: 'post',
        values: {
          package_ids: selectedRowKeys as BaseKey[],
        },
        successNotification: () => {
          return {
            message: 'Successfully updated payout of packages',
            type: 'success',
          }
        },
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([])
        },
      },
    )
  }

  const pageHeaderProps = {
    breadcrumb: <></>,
    extra: [
      <Space key="all" align="center" direction="horizontal">
        {forRFQ && !!selectedRowKeys.length && lockPayout ? (
          <Button
            type="default"
            size="small"
            icon={<Icons.LockFilled />}
            onClick={onClickPayout}
            loading={isLoadingPayout}>
            Lock Payout
          </Button>
        ) : null}
        {(!eventId || forRFQ) &&
          (allowOptionalFilter ? (
            <Space direction="horizontal" align="center">
              {OptionalFilter}
            </Space>
          ) : (
            <Form
              {...tProps.searchFormProps}
              initialValues={getSearchFormInitialValue(tProps.filters)}
              key="search">
              <Form.Item name="search" noStyle>
                <Input.Search
                  placeholder="Name, Customer details, Event Name"
                  onSearch={() => tProps.searchFormProps?.form?.submit()}
                  enterButton
                />
              </Form.Item>
            </Form>
          ))}
        <ExportButton onClick={triggerExport} loading={exportLoading} />
        <LogButton key="log" />
        {!forRFQ && (
          <CreateButton
            key="create"
            onClick={!!eventId ? () => show() : undefined}
          />
        )}
      </Space>,
    ],
  }

  return (
    <List title={title} headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        size="small"
        rowSelection={rowSelection}
        tableLayout="fixed">
        {!forRFQ && (
          <Table.Column<IShortlistPackage>
            title="Image"
            width={300}
            ellipsis
            render={(_, record) => {
              if (shortlistPackageImageDataIsLoading) return <Spin />

              const shortlistPackageData = shortlistPackageImageData?.data.find(
                (p) => p.package === String(record.id),
              )
              return (
                <StackGrid columnWidth={'33.33%'}>
                  {shortlistPackageData?.images.map(
                    (image: any, index: number) => (
                      <PaddedImageField
                        key={index}
                        value={image.image}
                        padding={0}
                      />
                    ),
                  )}
                </StackGrid>
              )
            }}
          />
        )}
        <Table.Column dataIndex="event" title="Project ID" ellipsis />
        <Table.Column<IShortlistPackage>
          dataIndex="name"
          title="Name"
          ellipsis={true}
          render={(value, record) => (
            <Tooltip
              title={value}
              placement="topLeft"
              trigger={value?.length > 20 ? 'hover' : 'focus'}>
              <Link
                style={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                }}
                to={
                  forRFQ
                    ? `/package_tool/rfq/show/${record.id}`
                    : `/package_tool/shortlist_packages/edit/${record.id}`
                }>
                {truncate(value, { length: 20 })}
              </Link>
            </Tooltip>
          )}
        />
        {forRFQ && (
          <>
            <Table.Column<IShortlistPackage>
              dataIndex="service_package"
              title="service"
              render={(value: IServicePackage) => {
                return value?.service?.name
              }}
            />
          </>
        )}
        <Table.Column<IShortlistPackage>
          dataIndex="customer"
          title="Customer"
          ellipsis
          render={(value) => {
            if (isCustomerLoading) return <Spin />
            const cus = customerData?.data.find((c) => c.id === value)
            return `${cus?.first_name} ${cus?.last_name} (${cus?.username})`
          }}
        />
        <Table.Column
          dataIndex="price"
          title="Price"
          render={(value) => <PriceField value={value ?? 0} />}
        />
        <Table.Column
          dataIndex="discount_amount"
          title="Discount"
          render={(value) => <PriceField value={value ?? 0} />}
        />
        {!forRFQ && (
          <>
            <Table.Column<IShortlistPackage>
              dataIndex="is_finalized"
              title="Finalized"
              ellipsis
              render={(value) => <TextField value={value ? 'Yes' : 'No'} />}
            />
            <Table.Column<IShortlistPackage>
              title="Action"
              ellipsis
              render={(_, record) => (
                <Space size="small">
                  <Button
                    icon={<Icons.ShareAltOutlined />}
                    size="small"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_WEB_APP_URL}/${record.id}`,
                        '_blank',
                      )
                    }}
                  />
                  <Button
                    icon={<Icons.CopyOutlined />}
                    size="small"
                    onClick={() => {
                      setSelectedPackage(record)
                      showClone()
                    }}
                  />
                </Space>
              )}
            />
          </>
        )}
        {forRFQ && (
          <>
            <Table.Column<IShortlistPackage>
              dataIndex="discount_amount"
              title="Effective Price"
              render={(value, record) => (
                <PriceField value={record.price - value ?? 0} />
              )}
            />
            <Table.Column<IShortlistPackage>
              dataIndex="consultant"
              title="Consultant"
              ellipsis
              render={(value) => {
                const cus = consultantData?.data.find((c) => c.id === value)
                return cus
                  ? `${cus?.first_name} ${cus?.last_name} (${cus?.username})`
                  : 'Not Assigned'
              }}
            />
          </>
        )}
      </Table>
      <Modal {...modalProps}>
        <ShortlistPackageForm
          useFormProps={
            {
              formProps,
              queryResult,
              mutationResult,
            } as UseFormReturnType<IShortlistPackage>
          }
          eventId={eventId}
        />
      </Modal>
      <CloneModal
        visible={cloneVisible}
        close={closeClone}
        package={selectedPackage}
      />
    </List>
  )
}
