import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Select } from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { ServicePackageType } from 'interfaces/venue'
import { SERVICE_URL, SHORTLIST_PACKAGE_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'
import { handleBase64Image } from 'utils/form'

export const ServicePackageForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <Form
      {...props.formProps}
      layout="vertical"
      onFinish={async (values) => {
        const finalImage = await handleBase64Image(values.cover_image)
        const finalResponse = { ...values, venue: props.venueId }
        if (finalImage.length) finalResponse['thumbnail'] = finalImage

        props.formProps.onFinish!(finalResponse)
      }}>
      <Form.Item name="package_type" label="Package Type">
        <Select options={createOptionsFromEnum(ServicePackageType)} />
      </Form.Item>
      <Form.Item name="service" label="Service">
        <SelectWithDefault
          useSelectProps={{ resource: SERVICE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="package" label="Package">
        <SelectWithDefault
          useSelectProps={{
            resource: SHORTLIST_PACKAGE_URL,
            optionLabel: 'name',
          }}
        />
      </Form.Item>
    </Form>
  )
}
