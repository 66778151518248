import { Edit } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IBestFor } from 'interfaces/bestFor'
import { BestForForm } from './Form'

export const BestForEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IBestFor>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <BestForForm formProps={formProps} />
    </Edit>
  )
}
