import { BaseKey } from '@refinedev/core'
import { Form, FormProps, Input, InputNumber, Select, Switch } from 'antd'
import { MediaType } from 'interfaces/venue'
import { createOptionsFromEnum } from 'utils/common'

export const MediaSectionForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <>
      <Form
        {...props.formProps}
        onFinish={(values) => {
          props.formProps.onFinish!({ ...values, venue: props.venueId })
        }}
        layout="vertical">
        <Form.Item label="Order" name="order">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Name" name={'name'}>
          <Input />
        </Form.Item>
        <Form.Item label="Media Type" name={'media_type'}>
          <Select options={createOptionsFromEnum(MediaType)} />
        </Form.Item>
        <Form.Item
          label="Money Shot?"
          name={'money_shot'}
          valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </>
  )
}
