import { Button, Card, Collapse, Form, InputNumber, Tabs, Typography } from "antd";
import { RowRemoveButton } from 'components/button'
import { ProductSelect } from 'components/input/productSelect'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IProduct } from 'interfaces/product'
import { UseFormReturnType } from 'interfaces/refine'
import { useEffect, useRef, useState } from 'react'
import { usePrevious } from 'utils/common'

const { TabPane } = Tabs
const { Text, Title } = Typography

function ItemForm(props: any) {
  const [product, setProduct] = useState<IProduct>()
  const [quantity, setQuantity] = useState(1)
  const [total, setTotal] = useState<number>(0)

  const quantityRef = useRef(null)

  const prevTotal = usePrevious(total)

  useEffect(() => {
    setQuantity((quantityRef?.current as any)?.value)
  }, [quantityRef])

  useEffect(() => {
    const price = (product?.price ?? 0) * quantity
    if (product !== undefined && quantity > 0) {
      setTotal(price)
    }
  }, [product, quantity])

  useEffect(() => {
    if (prevTotal !== total && total > 0) {
      props.handleTotal((prevSectionTotal: number) => {
        return prevSectionTotal - (prevTotal ?? 0) + total
      })
    }
  }, [total])

  return (
    <Card style={{ marginBottom: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Form.Item
          {...props.field}
          key={`${props.field.name}-${props.field.key}`}
          name={[props.field.name, 'product']}
          label="Product"
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}>
          <ProductSelect setProduct={setProduct} />
        </Form.Item>
        <Form.Item
          {...props.field}
          name={[props.field.name, 'quantity']}
          label="Quantity"
          initialValue={1}
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}>
          <InputNumber<number>
            ref={quantityRef}
            onChange={(value: any) => setQuantity(value)}
          />
        </Form.Item>
        <Text>Total: ₹{total}</Text>
        <RowRemoveButton
          onClick={() => {
            props.handleTotal(
              (prevSectionTotal: number) => prevSectionTotal - total,
            )
            props.remove(props.field.name)
          }}
        />
      </div>
      <Collapse>
        <Collapse.Panel header="Alternates" key="zxcv">
          <Form.List name={[props.field.name, 'alternates']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div
                    key={field.key}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'product']}
                      label="Product">
                      <ProductSelect />
                    </Form.Item>
                    <RowRemoveButton
                      onClick={() => {
                        remove(field.name)
                      }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Add Product
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}

export const PackageSectionForm: React.FC<{
  useFormProps: UseFormReturnType
}> = ({ useFormProps }) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(0)
  }, [useFormProps.formLoading])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography.Title level={4} key="total">
          Total: ₹{total}
        </Typography.Title>
      </div>
      <Form {...useFormProps.formProps} layout="vertical">
        <Tabs>
          <TabPane key="basic" tab="Basic">
            <Form.Item label="Section" name="section">
              <SelectWithDefault
                useSelectProps={{
                  resource: `package_tool/sections`,
                  optionLabel: 'name',
                  optionValue: 'id',
                }}
              />
            </Form.Item>
          </TabPane>
          <TabPane key="items" tab="Items" forceRender>
            <Form.List name="items">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field) => (
                      <ItemForm
                        key={`${field.name}-${field.key}`}
                        field={field}
                        remove={remove}
                        queryResult={useFormProps.queryResult}
                        handleTotal={setTotal}
                      />
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Item
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
          </TabPane>
        </Tabs>
      </Form>
    </>
  )
}
