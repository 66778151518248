import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { IAttribute } from 'interfaces/attribute'
import { AttributeForm } from './Form'

export const AttributeCreate: React.FC = () => {
  const useFormProps = useForm<IAttribute>()

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <AttributeForm useFormProps={useFormProps} />
    </Create>
  )
}
