import {
  DateField,
  DeleteButton,
  useModal,
  useSimpleList,
} from '@refinedev/antd'
import {
  BaseKey,
  useApiUrl,
  useCan,
  useCreate,
  useList,
  useUpdate,
} from '@refinedev/core'
import {
  Badge,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Timeline,
  Typography,
  Upload,
  UploadFile,
} from 'antd'
import {
  IMaterialSource,
  IMaterialStatus,
  IMaterialStatusMedia,
  MaterialIssues,
  MaterialStatus,
} from 'interfaces/quote'
import { IStaff } from 'interfaces/staff'
import debounce from 'lodash/debounce'
import ReactPlayer from 'react-player/file'
import {
  MATERIAL_SOURCE_URL,
  MATERIAL_STATUS_MEDIA_URL,
  MATERIAL_STATUS_URL,
} from 'urls'
import { createOptionsFromEnum, getUserName } from 'utils/common'
import { useEffect, useState } from 'react'
import * as Icons from '@ant-design/icons'
import { PaddedImageField } from 'components/field'

export const ImageDeleteMask: React.FC<{
  resource: string
  id?: BaseKey
  refetch: () => void
}> = ({ resource, id, refetch }) => {
  return (
    <div
      onClick={(e: any) => e.stopPropagation()}
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
      }}>
      <DeleteButton
        hideText
        resource={resource}
        recordItemId={id}
        onSuccess={() => refetch()}
      />
    </div>
  )
}
const StatusMedia = ({ status }: { status: IMaterialStatus }) => {
  const { modalProps, show } = useModal()
  const apiUrl = useApiUrl()
  const { listProps, queryResult } = useSimpleList<IMaterialStatusMedia>({
    resource: MATERIAL_STATUS_MEDIA_URL,

    queryOptions: {
      enabled: modalProps.open,
    },
    pagination: {
      // Hardcoded pageSize to 12 as we can show 12 images in one Modal
      pageSize: 12,
    },
    filters: {
      permanent: [
        {
          field: 'material_status',
          operator: 'eq',
          value: status.id,
        },
      ],
    },
  })

  const { data: media, refetch: imagesRefetch } = queryResult

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [refreshImages, setRefreshImages] = useState<boolean>(false)

  useEffect(() => {
    if (refreshImages) {
      imagesRefetch()
      setRefreshImages(false)
    }
  }, [refreshImages])
  return (
    <>
      <Button onClick={() => show()} type="text" size="small">
        Info
      </Button>
      <Modal {...modalProps} title={'Media'} destroyOnClose footer={null}>
        <Space
          direction="vertical"
          style={{
            display: 'flex',
          }}>
          <List {...listProps}>
            <Row>
              {media?.data.map((media) => (
                <Col key={media.id} span={6}>
                  {media.image && (
                    <PaddedImageField
                      key={media.id}
                      value={media.image}
                      padding={2.5}
                      radius={0}
                      preview={{
                        mask: (
                          <ImageDeleteMask
                            resource={MATERIAL_STATUS_MEDIA_URL}
                            id={media.id}
                            refetch={imagesRefetch}
                          />
                        ),
                      }}
                      height={150}
                    />
                  )}
                  {media.video && (
                    <ReactPlayer
                      url={media.video}
                      height={150}
                      width={'100%'}
                      pip={false}
                      config={{}}
                      controls
                    />
                  )}
                </Col>
              ))}
            </Row>
          </List>

          <Upload.Dragger
            multiple
            action={`${apiUrl}/${MATERIAL_STATUS_MEDIA_URL}/`}
            headers={{
              Authorization: `Token ${localStorage.getItem('token')}`,
            }}
            fileList={fileList}
            onChange={(info) => {
              setFileList([...info.fileList])
              if (info.file.status === 'done') {
                setRefreshImages(true)
              }
            }}
            data={async (file) => ({
              image: file,
              material_status: status.id,
            })}
            itemRender={(originNode, file, fileList) => {
              if (file.status !== 'done') {
                return (
                  <>
                    {file.name}{' '}
                    {file.status === 'error' ? (
                      <Icons.ExclamationCircleOutlined
                        style={{ color: 'red' }}
                      />
                    ) : (
                      <Icons.LoadingOutlined />
                    )}
                  </>
                )
              }
            }}>
            <p className="ant-upload-drag-icon">
              <Icons.InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag files to this area to upload
            </p>
            <p className="ant-upload-text">
              uploaded{' '}
              {fileList.filter((file: any) => file.status === 'done').length}/
              {fileList.length} files
            </p>
          </Upload.Dragger>
        </Space>
      </Modal>
    </>
  )
}

const ApproveButton = ({
  status,
  refetchStatuses,
  refetchMaterials,
}: {
  status: IMaterialStatus
  refetchStatuses: CallableFunction
  refetchMaterials: CallableFunction
}) => {
  const { mutate: postApprove, isLoading: approveLoading } = useCreate()

  return (
    <Button
      key="approve"
      type="text"
      size="small"
      loading={approveLoading}
      disabled={status.is_approved || !!status.checker_issue?.length}
      onClick={() =>
        postApprove(
          {
            resource: `${MATERIAL_STATUS_URL}/${status.id}/approve`,
            values: {},
          },
          {
            onSuccess: () => {
              refetchStatuses()
              refetchMaterials()
            },
          },
        )
      }>
      Approve
    </Button>
  )
}

const IssueSelect = ({
  status,
  issueType,
}: {
  status?: IMaterialStatus
  issueType: string
}) => {
  const { mutate: patch, isLoading } = useUpdate()

  const handleChange = (value: string) => {
    patch({
      resource: MATERIAL_STATUS_URL,
      id: status!.id,
      values: {
        [issueType]: value,
      },
    })
  }

  if (!status) {
    return null
  }

  return (
    <Select
      options={createOptionsFromEnum(MaterialIssues)}
      dropdownMatchSelectWidth={false}
      placeholder="Issues"
      // @ts-ignore
      defaultValue={status[issueType] ?? []}
      onChange={debounce(handleChange, 300)}
      loading={isLoading}
      mode="multiple"
      style={{ width: 120 }}
    />
  )
}

export const MaterialStatusSelect = ({
  source,
  refetchSources,
}: {
  source: IMaterialSource
  refetchSources: CallableFunction
}) => {
  const { modalProps, show } = useModal()
  const {
    data: statuses,
    refetch: refetchStatuses,
    isLoading: statusesLoading,
    isRefetching: statusesRefetching,
  } = useList<IMaterialStatus>({
    resource: MATERIAL_STATUS_URL,

    queryOptions: {
      enabled: modalProps.open,
    },

    filters: [
      { field: 'source', operator: 'eq', value: source.id },
      {
        field: 'expand',
        operator: 'eq',
        value: 'completed_by,approved_by',
      },
    ],

    pagination: {
      mode: 'off',
    },
  })
  const { mutate: post, isLoading: postLoading } = useCreate()

  const { data: deleteMaterialStatus } = useCan({
    resource: 'can_delete_material_status',
    action: '',
  })

  const onChange = (value: string) => {
    post(
      {
        resource: `${MATERIAL_SOURCE_URL}/${source.id}/set_status`,
        values: {
          status: value,
        },
      },
      {
        onSuccess: () => {
          refetchStatuses()
          refetchSources()
        },
      },
    )
  }

  const getActions = (status: IMaterialStatus) => {
    const baseActions = [
      <StatusMedia status={status} key="info" />,
      <ApproveButton
        key="approve"
        status={status}
        refetchStatuses={refetchStatuses}
        refetchMaterials={refetchSources}
      />,
      <IssueSelect key="issue" status={status} issueType="checker_issue" />,
    ]

    if (deleteMaterialStatus?.can) {
      baseActions.push(
        <DeleteButton
          resource={MATERIAL_STATUS_URL}
          recordItemId={status.id}
          type="text"
          hideText
          size="small"
          onSuccess={() => refetchSources()}
        />,
      )
    }

    return baseActions
  }

  return (
    <>
      <Space>
        <Badge dot={!!source.current_status.media_count} showZero size="small">
          <Typography.Text
            type={
              source.current_status?.checker_issue
                ? 'danger'
                : source.current_status.is_approved
                ? 'success'
                : 'warning'
            }>
            {source?.current_status?.status}
          </Typography.Text>
        </Badge>
        <Button onClick={() => show()} size="small">
          Update
        </Button>
      </Space>
      <Modal
        {...modalProps}
        destroyOnClose
        footer={false}
        closable={false}
        title={
          <>
            <Select
              defaultValue={source?.current_status?.status}
              options={createOptionsFromEnum(MaterialStatus)}
              dropdownMatchSelectWidth={false}
              onChange={onChange}
              loading={postLoading}
              disabled={
                !!statuses?.total
                  ? !statuses.data[0].is_approved &&
                    !statuses.data[0].checker_issue?.length
                  : false
              }
            />
            <IssueSelect status={statuses?.data[0]} issueType="maker_issue" />
          </>
        }>
        <Spin spinning={statusesLoading || statusesRefetching}>
          <Timeline
            mode="left"
            items={statuses?.data?.map((status) => ({
              key: status.id,
              label: <DateField value={status.created_at} format="LLL" />,
              color: status.is_approved ? 'green' : 'red',
              children: (
                <Card
                  title={status.status}
                  size="small"
                  actions={getActions(status)}>
                  {getUserName(status.completed_by as IStaff, false)} <br />
                  {status.is_approved ? (
                    <>
                      Approved by{' '}
                      {getUserName(status.approved_by as IStaff, false)} at{' '}
                      <DateField value={status.approved_at} format="LLL" />
                    </>
                  ) : (
                    'Not Approved'
                  )}
                </Card>
              ),
            }))}
          />
        </Spin>
      </Modal>
    </>
  )
}
