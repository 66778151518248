import { Form, FormProps, Input, InputNumber, Switch } from "antd";

export const PaymentTypeForm: React.FC<{ formProps: FormProps }> = ({
  formProps,
}) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item label="Flat Price" name="flat_price">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Percentage" name="percentage">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Order" name="order">
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Skip External"
        name="skip_external"
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
    </Form>
  )
}
