import { Create } from "@refinedev/antd";
import { useForm } from 'components/hooks/useForm'
import { ICustomer } from 'interfaces/customer'
import { CustomerForm } from './Form'

export const CustomerCreate: React.FC = () => {
  const useFormProps = useForm<ICustomer>({
    redirect: 'show',
  })

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <CustomerForm useFormProps={useFormProps} />
    </Create>
  )
}
