import React from 'react'

import { Modal } from 'antd'
import { SessionForm } from './Form'
type Props = {}

export const CreateSession: React.FC<{
  formProps: any
  modalProps: any
  shortlistPackageId: string
}> = ({ formProps, modalProps, shortlistPackageId }) => {
  return (
    <div>
      <Modal
        {...modalProps}
        destroyOnClose
        onCancel={() => {
          formProps.form.resetFields()
          modalProps.onCancel()
        }}>
        <SessionForm
          formProps={formProps}
          shortlistPackageId={shortlistPackageId}
        />
      </Modal>
    </div>
  )
}
