import { BaseKey, useOne } from '@refinedev/core'
import {
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from 'antd'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { MealScheduleType } from 'interfaces/venue'
import { CUISINE_URL, MENU_URL } from 'urls'
import { createOptionsFromEnum } from 'utils/common'

export const MenuForm: React.FC<{
  formProps: FormProps
  queryResult?: any
  isTemplate?: boolean
  sessionId?: BaseKey
}> = ({ formProps, queryResult, isTemplate = true, sessionId }) => {
  const onFinish = async (values: any) => {
    const finalResponse = {
      ...values,
      is_template: isTemplate,
      session: sessionId,
    }

    return formProps.onFinish && formProps.onFinish(finalResponse)
  }

  const { data: dietary_preferences } = useOne<any>({
    resource: MENU_URL,
    id: 'dietary_preferences',
  })

  console.log(sessionId)

  return (
    <Form {...formProps} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
        MenuForm
      </Form.Item>
      <Form.Item label="Cuisine" name="cuisine">
        <SelectWithDefault
          mode="multiple"
          useSelectProps={{ resource: CUISINE_URL, optionLabel: 'name' }}
        />
      </Form.Item>
      <Form.Item name="dietary_preferences" label="Dietary Preference">
        <Select
          allowClear
          dropdownMatchSelectWidth={false}
          options={dietary_preferences?.data.results.map((t: any) => ({
            label: t,
            value: t,
          }))}
          mode="multiple"
          />
        </Form.Item>
        <Form.Item label="Meal Type" name={'meal_type'}>
          <Select options={createOptionsFromEnum(MealScheduleType)} />
        </Form.Item>
      <Space>
        <Form.Item label="Max Dishes" name={'max_quantity'}>
          <InputNumber<number> defaultValue={0} />
        </Form.Item>
        <Form.Item label="Plan Price" name={'plan_price'}>
          <InputNumber<number> defaultValue={0} />
        </Form.Item>
      </Space>

      {!sessionId && (
        <Form.Item label="For Consultation Tool" name={'for_consultation_tool'}>
          <Switch />
        </Form.Item>
      )}
    </Form>
  )
}
