import {
  BaseKey,
  useApiUrl,
  useCustom,
  useLink,
  useCreate,
} from '@refinedev/core'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Space,
  Spin,
  Divider,
  InputNumber,
  Descriptions,
} from 'antd'
import React, { useState } from 'react'
import { RFQ_URL, SHORTLIST_PACKAGE_URL, VENDOR_URL } from 'urls'
import * as Icons from '@ant-design/icons'
import { IVendor, IVendorPaymentList } from 'interfaces/vendor'
import { PriceField } from 'components/field'

const ProjectPaymentInfo: React.FC<{
  packagePaymentInfo: IVendorPaymentList
  vendorId: BaseKey
  refetchPayments: any
}> = ({ packagePaymentInfo, vendorId, refetchPayments }) => {
  const [payoutValues, setPayoutValues] = useState<{
    [key: string]: { [key: string]: number | null }
  }>({})

  const Link = useLink()

  const { mutate: post, isLoading: postIsLoading } = useCreate()

  return (
    <>
      <div
        key={packagePaymentInfo.package_id}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
        <Card
          actions={[
            <Button
              key="payVendor"
              onClick={() => {
                post(
                  {
                    resource: `${SHORTLIST_PACKAGE_URL}/${packagePaymentInfo.package_id}/payout_vendor`,
                    values: payoutValues,
                  },
                  { onSuccess: () => refetchPayments() },
                )
              }}>
              Pay
            </Button>,
          ]}>
          <Card.Meta
            title={
              <>
                <Link to={`/${RFQ_URL}/show/${packagePaymentInfo.package_id}`}>
                  {packagePaymentInfo.project_id}
                </Link>
                <Divider />
              </>
            }
          />
          {packagePaymentInfo.payments.map((servicePayment) => {
            return (
              <Col key={servicePayment.service} span={8}>
                <Card
                  key="servicePayment"
                  actions={[
                    <div key={servicePayment.service}>
                      Pay:{' '}
                      <InputNumber<number>
                        max={servicePayment.pending_amount}
                        min={0}
                        prefix="₹"
                        disabled={servicePayment.pending_amount < 1}
                        value={
                          payoutValues[servicePayment.service]
                            ? payoutValues[servicePayment.service][vendorId]
                            : 0
                        }
                        onChange={(value) =>
                          setPayoutValues({
                            ...payoutValues,
                            [servicePayment.service]: {
                              ...payoutValues[servicePayment.service],
                              [vendorId]: value,
                            },
                          })
                        }
                      />
                    </div>,
                  ]}>
                  <Card.Meta
                    title={
                      <>
                        {servicePayment.service} (
                        <PriceField value={servicePayment.paid ?? 0} />/
                        <PriceField value={servicePayment.total ?? 0} />)
                        <Divider />
                      </>
                    }
                    description={
                      <Space direction="vertical">
                        <Descriptions column={1}>
                          <Descriptions.Item label="Account Name">
                            {packagePaymentInfo.fund_account?.account_number ??
                              packagePaymentInfo.fund_account?.vpa_address}
                          </Descriptions.Item>
                          <Descriptions.Item label="Total">
                            <PriceField value={servicePayment.total} />
                          </Descriptions.Item>
                          <Descriptions.Item label="Paid">
                            <PriceField value={servicePayment.paid} />
                          </Descriptions.Item>
                          <Descriptions.Item label="Pending">
                            <PriceField value={servicePayment.pending_amount} />
                          </Descriptions.Item>
                        </Descriptions>
                      </Space>
                    }
                  />
                </Card>
              </Col>
            )
          })}
        </Card>
      </div>
    </>
  )
}

export const VendorPaymentsTab: React.FC<{
  vendorId: BaseKey
  vendorData?: IVendor
}> = ({ vendorId, vendorData }) => {
  const [projectId, setProjectId] = useState<string>('')
  const [fetchPayments, setFetchPayments] = useState<boolean>(false)

  const apiUrl = useApiUrl()

  const { data, isLoading, refetch } = useCustom<IVendorPaymentList[]>({
    url: `${apiUrl}/${VENDOR_URL}/${vendorId}/vendor_payments/`,
    method: 'get',
    config: {
      query: {
        project_id: projectId,
      },
    },
    queryOptions: {
      enabled: fetchPayments,
      onSuccess: () => setFetchPayments(false),
    },
  })

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Form layout="inline" size="small">
          <Form.Item name="project_id" label="Project IDs">
            <Input
              placeholder="1234,5667"
              onChange={(e) => {
                setProjectId(e.target.value)
              }}
            />
          </Form.Item>
        </Form>

        <Button
          size="small"
          type="primary"
          onClick={() => {
            setFetchPayments(true)
          }}
          icon={<Icons.ArrowRightOutlined />}>
          Fetch Payments
        </Button>
        <Button
          size="small"
          onClick={() => {
            setFetchPayments(true)
          }}
          icon={<Icons.ReloadOutlined />}>
          Refresh
        </Button>
      </Space>
      <Divider />
      <Space direction="vertical">
        {isLoading && fetchPayments && <Spin />}
        {data &&
          data.data?.map((paymentData) => {
            return (
              <ProjectPaymentInfo
                key="projectPaymentCard"
                vendorId={vendorId}
                packagePaymentInfo={paymentData}
                refetchPayments={refetch}
              />
            )
          })}
      </Space>
    </Space>
  )
}
