import { Affix, Button, Divider, Input, Select, Typography } from 'antd'
import capitalize from 'lodash/capitalize'
import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'

const CHAT_URL = process.env.REACT_APP_VENUE_BOT_URL ?? ''

const ChatMessage = ({ role, content }: { role: string; content: string }) => {
  return (
    <div>
      <p>
        <Typography.Title level={5}>{capitalize(role)}:</Typography.Title>
        <Markdown>{content}</Markdown>
        <Divider />
      </p>
    </div>
  )
}

export const VenueBot = () => {
  const [messages, setMessages] = useState<any[]>([])
  const [answer, setAnswer] = useState('')
  const [message, setMessage] = useState('')
  const [streamEnded, setStreamEnded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState<string>('anthropic.claude-v2')

  const sendMessage = async () => {
    setLoading(true)
    const content = message
    setMessage('')
    setMessages((prev) => [...prev, { content, role: 'user' }])
    const response = await fetch(CHAT_URL, {
      method: 'post',
      body: JSON.stringify({ message: content, chat_history: messages, model }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })

    const reader = response.body?.getReader()
    const decoder = new TextDecoder()
    const loopRunner = true

    while (loopRunner) {
      // Here we start reading the stream, until its done.
      if (reader) {
        const { value, done } = await reader.read()
        if (done) {
          setStreamEnded(true)
          break
        }
        const decodedChunk = decoder.decode(value, { stream: true })
        setAnswer((answer) => answer + decodedChunk)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    if (streamEnded) {
      setMessages((prev) => [...prev, { content: answer, role: 'assistant' }])
      setAnswer('')
      setStreamEnded(false)
    }
  }, [streamEnded, answer])

  return (
    <div>
      <Affix>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '10px 10px 0px 10',
            border: '1px solid #f0f0f0',
          }}>
          <h2>Venue Bot</h2>
          <Select
            options={[
              { label: 'Claude Instant', value: 'anthropic.claude-instant-v1' },
              { label: 'Claude v1.3', value: 'anthropic.claude-v1' },
              { label: 'Claude v2', value: 'anthropic.claude-v2' },
              { label: 'Claude v2.1', value: 'anthropic.claude-v2:1' },
              { label: 'Llama 2', value: 'meta.llama2-70b-chat-v1' },
            ]}
            value={model}
            onChange={(value) => setModel(value)}
            popupMatchSelectWidth={false}
          />
        </div>
      </Affix>
      <div style={{ padding: 20 }}>
        <div
          style={{
            padding: 20,
            height: '75vh',
            overflow: 'auto',
            border: '1px solid #f0f0f0',
          }}>
          {messages.map((m, index) => (
            <ChatMessage key={index} role={m.role} content={m.content} />
          ))}
          {!!answer.length && <ChatMessage role="assistant" content={answer} />}
        </div>
        <div style={{ padding: '20px 20px 0 20px' }}>
          <Input.TextArea
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onPressEnter={() => sendMessage()}
          />
          <Button
            onClick={() => sendMessage()}
            disabled={loading}
            loading={loading}>
            Submit
          </Button>
          <Button
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(messages),
            )}`}
            download={`chat_${model}.json`}>
            Save Conversation
          </Button>
        </div>
      </div>
    </div>
  )
}
