import { Create } from '@refinedev/antd'
import { IResourceComponentsProps, useCan } from '@refinedev/core'
import { useForm } from 'components/hooks'
import { MaterialForm } from './Form'
import { IMaterial } from 'interfaces/material'

export const MaterialsCreate: React.FC<IResourceComponentsProps> = () => {
  const useFormProps = useForm<IMaterial>()

  const { data: addMaterialPerm } = useCan({
    resource: 'add_material',
    action: '',
  })

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <MaterialForm
        formProps={useFormProps.formProps}
        disabled={!addMaterialPerm?.can}
      />
    </Create>
  )
}
