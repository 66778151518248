import { Descriptions, Image, List } from 'antd'
import { PriceField } from 'components/field'
import { IOverallPackage } from 'interfaces/venue'

export const OverallPackageDetails = ({ item }: { item: IOverallPackage }) => {
  return (
    <>
      <List.Item.Meta
        avatar={<Image src={item.thumbnail} height={80} width={80} />}
      />
      <Descriptions column={4}>
        <Descriptions.Item label="Package Type">
          {item.package_type}
        </Descriptions.Item>
        <Descriptions.Item label="Subheading">
          {item.subheading}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          <PriceField value={item.total ?? 0} />
        </Descriptions.Item>
        <Descriptions.Item label="Venue Price">
          <PriceField value={item.venue_price ?? 0} />
        </Descriptions.Item>
        <Descriptions.Item label="Catering Price">
          <PriceField value={item.catering_price ?? 0} />
        </Descriptions.Item>
        <Descriptions.Item label="Decor Price">
          <PriceField value={item.decor_price ?? 0} />
        </Descriptions.Item>
        <Descriptions.Item label="Photography Price">
          <PriceField value={item.photography_price ?? 0} />
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
