// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { UploadFile } from "antd/lib/upload/interface";

import {
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from "antd";

import { useApiUrl, useCan, useDelete, useList } from "@refinedev/core";
import { RowRemoveButton } from 'components/button'
import { PriceField } from 'components/field'
import { DatePicker, DraggableMultiImageUpload } from 'components/input'
import { ProductSelect } from 'components/input/productSelect'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import parse from 'html-react-parser'
import { IProduct } from 'interfaces/product'
import { UseFormReturnType } from 'interfaces/refine'
import {
  IItem,
  ILineItemImage,
  IShortlistPackage,
} from 'interfaces/shortlistPackage'
import { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { PRICING_MAIN_URL } from 'urls'
import { usePrevious } from 'utils/common'

const { Text } = Typography

function BaseItemForm(props: any) {
  const [product, setProduct] = useState<IProduct>()
  const [quantity, setQuantity] = useState(1)
  const [price, setPrice] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [isIncluded, setIsIncluded] = useState(true)
  const prevProduct = usePrevious(product)

  const quantityRef = useRef(null)
  const priceRef = useRef(null)
  const descriptionRef = useRef(null)
  const isIncludedRef = useRef(null)

  const item = Form.useWatch<any>(
    ['sections', props.sectionField?.name, 'items', props.field.name],
    props.form,
  )

  const { data: validateItem } = useCan({
    resource: 'validate_line_item',
    action: '',
  })

  /**
   * set quantity to the value of quantity input
   */
  useEffect(() => {
    setQuantity((quantityRef?.current as any)?.value)
  }, [quantityRef])

  /**
   * set price to the value of price input
   */
  useEffect(() => {
    setPrice((priceRef?.current as any)?.value)
  }, [priceRef?.current])

  /**
   * set description to the value of description input
   */
  useEffect(() => {
    setPrice((descriptionRef?.current as any)?.value)
  }, [descriptionRef])

  useEffect(() => {
    // - if the prevProduct is undefined, it means that this is the initial render of component
    //      in that case do not change the price to product price, it should be the server response
    // - if the priceRef value doesn't exist, it means that this is new a component, and price should
    //      be set from selected product price
    // set the price only if prevProduct and product are different, as they could be same due to some
    // re-renders
    if (
      (prevProduct || !(priceRef?.current as any)?.value) &&
      prevProduct?.id !== product?.id
    ) {
      const { items } = props.form.getFieldsValue(true).sections[props.section]
      const finalArray =
        props.parentField !== undefined
          ? items[props.parentField.name]?.alternates
          : items
      if (finalArray?.length) {
        Object.assign(finalArray[props.field.name], {
          price: product?.price ?? 0,
        })
        setPrice(product?.price ?? 0)
        props.form.setFieldsValue({ ...items })
      }
    }

    // similar to the price, the description needs to be added from the product
    if (
      (prevProduct || !(descriptionRef?.current as any)?.props?.value) &&
      prevProduct?.id !== product?.id
    ) {
      const { items } = props.form.getFieldsValue(true).sections[props.section]
      const finalArray =
        props.parentField !== undefined
          ? items[props.parentField.name]?.alternates
          : items
      if (finalArray?.length) {
        Object.assign(finalArray[props.field.name], {
          description: product?.description,
        })
        props.form.setFieldsValue({ ...items })
      }
    }
  }, [product])

  /**
   * calculate and set the total price for the line item when the price, quantity or product
   * changes
   */
  useEffect(() => {
    const calculatedPrice = price * quantity
    if (product !== undefined && quantity > 0) {
      setTotal(calculatedPrice)
    }
  }, [price, quantity, product])

  /**
   * set isIncluded to the value of isIncluded input
   */
  useEffect(() => {
    const checked =
      (isIncludedRef?.current as any)?.getAttribute('aria-checked') === 'true'
    setIsIncluded(checked)
  }, [isIncludedRef])

  /**
   * Calculate the overall total, when the line item total changes
   * 1) Subtract the prevTotal, this gives us the overall total without this line item
   * 2) Add the new total
   */
  useEffect(() => {
    if (props.parentField === undefined) {
      const { items } =
        props.form.getFieldsValue(true).sections[props.section] || {}
      const calculatedTotal = items?.reduce(
        (previousTotal: number, item: IItem) =>
          previousTotal + (item.is_included ? item.price * item.quantity : 0),
        0,
      )
      props.handleTotal(calculatedTotal)
    }
  }, [total, isIncluded])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Form.Item
          {...props.field}
          key={`${props.field.name}-${props.field.key}`}
          name={[props.field.name, 'product']}
          label="Product"
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}>
          <ProductSelect setProduct={setProduct} disabled={props.disabled} />
        </Form.Item>
        <Form.Item
          {...props.field}
          name={[props.field.name, 'quantity']}
          label="Quantity"
          initialValue={1}
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}>
          <InputNumber<number>
            ref={quantityRef}
            onChange={(value: number | null) => !!value && setQuantity(value)}
            disabled={props.disabled}
          />
        </Form.Item>
        <Card
          size="small"
          actions={
            !props.isAlternate &&
              !!product?.has_breakdown &&
              item.id &&
              !props.form.isFieldTouched([
                'sections',
                props.sectionField?.name,
                'items',
                props.field.name,
                'product',
              ])
              ? [
                <Tooltip key="calculate" overlay="Calculate Price">
                  <Button
                    size="small"
                    onClick={() =>
                      window.open(
                        `/${PRICING_MAIN_URL}/?productId=${product.id}&lineItemId=${item.id}`,
                      )
                    }>
                    ₹
                  </Button>
                </Tooltip>,
                <Button
                  size="small"
                  key="refresh"
                  icon={<Icons.ReloadOutlined />}
                  onClick={() => props.queryResult.refetch()}
                />,
              ]
              : undefined
          }>
          <Form.Item
            name={[props.field.name, 'price']}
            label="Price"
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}>
            <InputNumber<number>
              prefix="₹"
              ref={priceRef}
              onChange={(value: number | null) => !!value && setPrice(value)}
              disabled={props.disabled}
            />
          </Form.Item>
        </Card>
        <Text>Total: ₹{total}</Text>
        {!props.disabled && (
          <RowRemoveButton
            onClick={() => {
              props.handleTotal &&
                props.handleTotal(
                  (prevSectionTotal: number) => prevSectionTotal - props.total,
                )
              props.remove(props.field.name)
            }}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{ flex: 0.95 }}>
          <Form.Item
            name={[props.field.name, 'description']}
            label="Description"
            rules={[
              {
                required: true,
                message: 'This is required',
              },
            ]}
            initialValue={''}>
            <ReactQuill
              theme="snow"
              ref={descriptionRef}
              readOnly={props.disabled}
            />
          </Form.Item>
          {!!item?.specification && (
            <>
              <Collapse>
                <Collapse.Panel header="Specification" key="specification">
                  {parse(item.specification)}
                </Collapse.Panel>
              </Collapse>
              <Divider />
            </>
          )}
        </div>
        {props.parentField === undefined && (
          <Space direction="vertical">
            <Form.Item
              name={[props.field.name, 'is_included']}
              label="Included"
              valuePropName="checked"
              initialValue={true}>
              <Switch
                ref={isIncludedRef}
                onChange={setIsIncluded}
                disabled={props.disabled}
              />
            </Form.Item>
            <Form.Item
              name={[props.field.name, 'is_validated']}
              label="Validated"
              valuePropName="checked"
              initialValue={false}>
              <Switch disabled={props.disabled || !validateItem?.can} />
            </Form.Item>
          </Space>
        )}
      </div>
    </div>
  )
}

function ItemForm(props: any) {
  const [imageFileList, setImageFileList] = useState<UploadFile[]>([])
  const [venueImageFileList, setVenueImageFileList] = useState<UploadFile[]>([])
  const apiUrl = useApiUrl()
  const itemId =
    props.form.getFieldsValue(true)?.sections[props.section]?.items[
      props.field.name
    ]?.id

  const { refetch: fetchItemImage } = useList<ILineItemImage>({
    resource: `package_tool/shortlist_package/line_items/${itemId}/get_images/`,
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const existingFiles: UploadFile[] = []
        data?.data.map((image) =>
          existingFiles.push({
            uid: image.id,
            name: 'test.png',
            url: image.image,
            thumbUrl: image.image,
            status: 'done',
          }),
        )
        setImageFileList(existingFiles)
      },
    },
  })

  const { refetch: fetchItemVenueImage } = useList<ILineItemImage>({
    resource: `package_tool/shortlist_package/line_items/${itemId}/get_venue_images/`,
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const existingFiles: UploadFile[] = []
        data?.data.map((image) =>
          existingFiles.push({
            uid: image.id,
            name: 'test.png',
            url: image.image,
            thumbUrl: image.image,
            status: 'done',
          }),
        )
        setVenueImageFileList(existingFiles)
      },
    },
  })

  const { mutate: deleteImage } = useDelete()

  return (
    <Card style={{ marginBottom: 20 }}>
      <Tabs
        tabBarExtraContent={{
          right: (
            <Form.Item
              {...props.field}
              noStyle
              key={`${props.field.name}-${props.field.key}`}
              name={[props.field.name, 'order']}
              initialValue={`${props.section + 1}${props.field.key + 1}`}>
              <Input prefix="Order: " />
            </Form.Item>
          ),
        }}
        onTabClick={(key) => {
          switch (key) {
            case 'reference':
              fetchItemImage()
              break
            case 'venueReference':
              fetchItemVenueImage()
              break
          }
        }}>
        <Tabs.TabPane tab="Product Info" key="info">
          <BaseItemForm
            sectionField={props.sectionField}
            field={props.field}
            handleTotal={props.handleTotal}
            remove={props.remove}
            form={props.form}
            disabled={props.disabled}
            section={props.section}
            queryResult={props.queryResult}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Reference Image" key="reference" disabled={!itemId}>
          <Form>
            <DraggableMultiImageUpload
              label="Images"
              name="images"
              files={imageFileList}
              onChange={setImageFileList}
              beforeUpload={(file) => file}
              dragDisabled={true}
              uploadProps={{
                action: `${apiUrl}/package_tool/shortlist_package/line_items/${itemId}/add_image/`,
                onRemove: (file: any) => {
                  deleteImage({
                    resource: `package_tool/shortlist_package/line_items/${itemId}/delete_image`,
                    id: file.uid,
                  })
                },
              }}
            />
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Venue Reference Image"
          key="venueReference"
          disabled={!itemId}>
          <Form>
            <DraggableMultiImageUpload
              label="Images"
              name="images"
              files={venueImageFileList}
              onChange={setVenueImageFileList}
              beforeUpload={(file) => file}
              dragDisabled={true}
              uploadProps={{
                action: `${apiUrl}/package_tool/shortlist_package/line_items/${itemId}/add_venue_image/`,
                onRemove: (file: any) => {
                  deleteImage({
                    resource: `package_tool/shortlist_package/line_items/${itemId}/delete_image`,
                    id: file.uid,
                  })
                },
              }}
            />
          </Form>
        </Tabs.TabPane>
      </Tabs>
      <Collapse>
        <Collapse.Panel header="Alternates" key="zxcv">
          <Form.List name={[props.field.name, 'alternates']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <BaseItemForm
                    key={`Alternate${field.key}`}
                    field={field}
                    remove={remove}
                    form={props.form}
                    parentField={props.field}
                    disabled={props.disabled}
                    section={props.section}
                    isAlternate
                  />
                ))}
                {!props.disabled && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Product
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}

export const ShortlistPackageSectionForm: React.FC<{
  useFormProps: UseFormReturnType
  packageData?: IShortlistPackage
  field?: any
  remove?: CallableFunction
}> = ({ useFormProps, packageData, field, remove }) => {
  const [total, setTotal] = useState(0)

  /**
   * Set the total to 0 when the form is done loading
   * Total will be calculated from the individual line items
   * This is to prevent multiple calculation on re-renders
   */
  useEffect(() => {
    setTotal(0)
  }, [useFormProps.formLoading])

  const dateNotDecided = Form.useWatch<boolean>(
    ['sections', field?.name, 'details', 'date_not_decided'],
    useFormProps.form,
  )

  const venueNotDecided = Form.useWatch<boolean>(
    ['sections', field?.name, 'details', 'venue_not_decided'],
    useFormProps.form,
  )

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography.Title level={4} key="total">
          Event Total: <PriceField value={total} />
        </Typography.Title>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{ flex: 0.95 }}>
          <Form.Item
            {...field}
            key={`section${field?.name}-${field?.key}`}
            name={[field?.name, 'section']}
            rules={[{ required: true }]}
            label="Event">
            <SelectWithDefault
              useSelectProps={{
                resource: `package_tool/sections`,
                optionLabel: 'name',
                optionValue: 'id',
                fetchSize: 100,
              }}
              allowOnlyCreate={false}
              disabled={packageData?.is_finalized}
            />
          </Form.Item>
        </div>
        {!packageData?.is_finalized && (
          <RowRemoveButton
            onClick={() => {
              remove?.(field.name)
            }}
          />
        )}
      </div>
      <Collapse style={{ marginBottom: 25 }}>
        <Collapse.Panel header="Time and Venue" key="timenvenue" forceRender>
          <Space direction="horizontal" size="large">
            <Form.Item
              {...field}
              key={`date_time${field?.name}-${field?.key}`}
              name={[field?.name, 'details', 'date_time']}
              rules={[
                {
                  required: !dateNotDecided,
                },
              ]}
              label="Date and Time">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
            <Form.Item
              {...field}
              key={`dismantling_date_time${field?.name}-${field?.key}`}
              name={[field?.name, 'details', 'dismantling_date_time']}
              label="Dismantling End Date and Time">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
            <Form.Item
              {...field}
              key={`decor_date_time${field?.name}-${field?.key}`}
              name={[field?.name, 'details', 'decor_date_time']}
              label="Decor Start Date and Time">
              <DatePicker
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          </Space>
          <Form.Item
            {...field}
            key={`date_not_decided${field?.name}-${field?.key}`}
            name={[field?.name, 'details', 'date_not_decided']}
            valuePropName="checked"
            label="Date not decided">
            <Switch />
          </Form.Item>
          <Form.Item
            {...field}
            key={`venue${field?.name}-${field?.key}`}
            name={[field?.name, 'details', 'venue']}
            rules={[
              {
                required: !venueNotDecided,
              },
            ]}
            label="Venue">
            <SelectWithDefault
              useSelectProps={{
                resource: `package_tool/venues`,
                optionLabel: 'name',
                optionValue: 'id',
              }}
            />
          </Form.Item>
          <Form.Item
            {...field}
            key={`venue_not_decided${field?.name}-${field?.key}`}
            name={[field?.name, 'details', 'venue_not_decided']}
            valuePropName="checked"
            label="Venue not decided">
            <Switch />
          </Form.Item>
          <Form.Item
            {...field}
            key={`pax${field?.name}-${field?.key}`}
            name={[field?.name, 'details', 'pax']}
            label="Pax">
            <InputNumber />
          </Form.Item>
          <Form.Item
            {...field}
            key={`spoc_contact${field?.name}-${field?.key}`}
            name={[field?.name, 'details', 'spoc_contact']}
            label="SPOC Contact">
            <Input />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>

      <Form.List name={[field?.name, 'items']}>
        {(itemFields, { add, remove }) => {
          return (
            <>
              {itemFields.map((itemField) => (
                <ItemForm
                  key={`${itemField.name}-${itemField.key}`}
                  sectionField={field}
                  field={itemField}
                  remove={remove}
                  queryResult={useFormProps.queryResult}
                  handleTotal={setTotal}
                  form={useFormProps.formProps.form}
                  disabled={packageData?.is_finalized}
                  section={field.key}
                />
              ))}
              {!packageData?.is_finalized && (
                <>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Item
                    </Button>
                  </Form.Item>
                </>
              )}
            </>
          )
        }}
      </Form.List>
    </>
  )
}
