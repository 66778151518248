import { Space, Typography, Select, Button, Descriptions } from 'antd'
import { BaseKey, useCan, useRouterContext, useUpdate } from '@refinedev/core'
import { PriceField } from 'components/field'
import { SHORTLIST_PACKAGE_URL, STAFF_URL, WAREHOUSE_URL } from 'urls'
import { getUserName } from 'utils/common'
import React, { useState } from 'react'
import * as Icons from '@ant-design/icons'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IStaff } from 'interfaces/staff'

export const EditStaffDropdown: React.FC<{
  staffUser: IStaff
  packageId: BaseKey
  fieldName: string
  canEdit?: boolean
}> = ({ staffUser, packageId, fieldName, canEdit }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [userId, setUserId] = useState<BaseKey | null>(staffUser?.id)

  const { mutate } = useUpdate()

  const handleChange = (value: any) => {
    mutate(
      {
        resource: SHORTLIST_PACKAGE_URL,
        id: packageId,
        values: {
          [fieldName]: value ?? null,
        },
        invalidates: ['all'],
      },
      {
        onSuccess: () => setIsEditing(!isEditing),
      },
    )
  }

  return (
    <>
      {isEditing ? (
        <Space direction="horizontal">
          <SelectWithDefault<IStaff>
            useSelectProps={{ resource: STAFF_URL }}
            allowClear
            value={userId}
            onChange={(value) => setUserId(value)}
            onClear={() => setUserId(null)}
            size="small"
            style={{
              width: '150px',
            }}
            renderOptions={(staffs) =>
              staffs?.map((staff) => (
                <Select.Option key={staff.id} value={staff.id}>
                  {staff.first_name} {staff.last_name}
                </Select.Option>
              ))
            }
          />
          {'  '}
          <Button
            size="small"
            icon={<Icons.CloseOutlined />}
            onClick={() => {
              setIsEditing(!isEditing)
            }}
          />
          {'  '}
          <Button
            size="small"
            icon={<Icons.SaveOutlined />}
            onClick={() => {
              handleChange(userId)
              setIsEditing(!isEditing)
            }}
          />
        </Space>
      ) : (
        <>
          <Typography.Text>
            {staffUser && getUserName(staffUser, false)}
          </Typography.Text>
          {canEdit && (
            <>
              {'  '}
              <Button
                size="small"
                icon={<Icons.EditOutlined />}
                onClick={() => {
                  setIsEditing(!isEditing)
                }}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export const EditWarehouse: React.FC<{
  warehouse_id: BaseKey
  packageId: BaseKey
  fieldName: string
  canEdit?: boolean
}> = ({ warehouse_id, packageId, fieldName, canEdit }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { mutate } = useUpdate()

  const handleChange = (value: any) => {
    mutate(
      {
        resource: SHORTLIST_PACKAGE_URL,
        id: packageId,
        values: {
          [fieldName]: value ?? null,
        },
        invalidates: ['all'],
      },
      {
        onSuccess: () => setIsEditing(!isEditing),
      },
    )
  }
  return (
    <>
      <SelectWithDefault
        useSelectProps={{
          resource: WAREHOUSE_URL,
          optionLabel: 'name',
        }}
        allowClear
        value={warehouse_id}
        onChange={(value) => handleChange(value)}
        size="small"
        style={{
          width: '150px',
        }}
        renderOptions={(warehouses) =>
          warehouses?.map((warehouse: any) => (
            <Select.Option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </Select.Option>
          ))
        }
      />
    </>
  )
}

export const PackageTotals = (props: any) => {
  const costPrice = props.queryResult.data?.data.total ?? 0
  const effectiveTotal =
    (props.queryResult.data?.data.package.price ?? 0) -
    (props.queryResult.data?.data.package.discount_amount ?? 0)
  const margin = ((effectiveTotal - costPrice) / effectiveTotal) * 100

  const { Link } = useRouterContext()

  const { data: canUpdateManagers } = useCan({
    resource: 'update_package_manager',
    action: '',
  })

  return (
    <Space direction="vertical">
      <Typography.Text>
        PID:{' '}
        <Link
          to={`/${SHORTLIST_PACKAGE_URL}/edit/${props.queryResult.data?.data.package.id}`}
          target="_blank">
          {props.queryResult.data?.data.package.event ?? ''}
        </Link>
      </Typography.Text>
      <Typography.Text>
        Package Total:{' '}
        <PriceField value={props.queryResult.data?.data.package.price ?? 0} />
      </Typography.Text>
      <Typography.Text>
        Discount:{' '}
        <PriceField
          value={props.queryResult.data?.data.package.discount_amount ?? 0}
        />
      </Typography.Text>
      <Typography.Text>
        Effective Total (Package Total - Discount):{' '}
        <PriceField value={effectiveTotal} />
      </Typography.Text>
      <Typography.Text>
        Amount Collected{' '}
        <PriceField
          value={props.queryResult.data?.data.amount_collected ?? 0}
        />
      </Typography.Text>
      {props.showCostPrice && (
        <>
          <Typography.Text>
            Cost Price: <PriceField value={costPrice} />
          </Typography.Text>
          <Typography.Text>
            Margin: <Typography.Text>{margin.toFixed(2)}%</Typography.Text>
          </Typography.Text>
        </>
      )}
      <Descriptions column={2} size="small" title="Manager And Executives">
        <Descriptions.Item label="Project Executive">
          <EditStaffDropdown
            fieldName="project_executive"
            staffUser={props.queryResult?.data?.data.package.project_executive}
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Project Manager">
          <EditStaffDropdown
            fieldName="project_manager"
            staffUser={props.queryResult?.data?.data.package.project_manager}
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Dismantle Manager">
          <EditStaffDropdown
            fieldName="dismantle_manager"
            staffUser={props.queryResult?.data?.data.package.dismantle_manager}
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Warehouse Executive">
          <EditStaffDropdown
            fieldName="warehouse_executive"
            staffUser={
              props.queryResult?.data?.data.package.warehouse_executive
            }
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Planning Executive">
          <EditStaffDropdown
            fieldName="planning_executive"
            staffUser={props.queryResult?.data?.data.package.planning_executive}
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Procurement Executive">
          <EditStaffDropdown
            fieldName="procurement_executive"
            staffUser={
              props.queryResult?.data?.data.package.procurement_executive
            }
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Sourcing Executive">
          <EditStaffDropdown
            fieldName="sourcing_executive"
            staffUser={props.queryResult?.data?.data.package.sourcing_executive}
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Warehouse">
          <EditWarehouse
            fieldName="dispatch_warehouse"
            warehouse_id={
              props.queryResult?.data?.data.package.dispatch_warehouse
            }
            packageId={props.queryResult?.data?.data.package.id}
            canEdit={canUpdateManagers?.can}
          />
        </Descriptions.Item>
      </Descriptions>
    </Space>
  )
}
