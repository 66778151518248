import { Descriptions } from 'antd'
import { IKeyLocation } from 'interfaces/venue'

export const KeyLocationDetails = ({ item }: { item: IKeyLocation }) => {
  return (
    <Descriptions column={4}>
      <Descriptions.Item label="Location Type">
        {item.location_type}
      </Descriptions.Item>
      <Descriptions.Item label="Location Name">
        {item.location_name}
      </Descriptions.Item>
      <Descriptions.Item label="Time">{item.time}</Descriptions.Item>
      <Descriptions.Item label="Distance">{item.distance}</Descriptions.Item>
    </Descriptions>
  )
}
