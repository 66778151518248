import { Edit } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { IDish } from 'interfaces/catering'
import { DishForm } from './Form'

export const DishEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IDish>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <DishForm formProps={formProps} queryResult={queryResult} />
    </Edit>
  )
}
