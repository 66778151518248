import { DateField, List, useTable } from '@refinedev/antd'
import { Button, Col, DatePicker, Form, Row, Table, Typography } from 'antd'
import { Authenticated, LayoutWrapper } from '@refinedev/core'
import { PriceField } from 'components/field'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { BOOKING_REPORT_URL } from 'urls'

export const BookingsReport = () => {
  const { tableProps, searchFormProps, tableQueryResult } = useTable<any>({
    resource: BOOKING_REPORT_URL,

    onSearch: (data: any) => [
      {
        field: 'booking_date',
        operator: 'between',
        value: data.booking_date
          ?.map((date: dayjs.Dayjs) => date.format('YYYY-MM-DD'))
          .join(','),
      },
    ],

    pagination: {
      mode: 'off',
    },

    filters: {
      initial: [
        {
          field: 'booking_date',
          operator: 'between',
          value: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD'),
          ].join(','),
        },
      ],
    },
  })

  return (
    <LayoutWrapper>
      <Authenticated>
        <div style={{ overflowX: 'hidden' }}>
          <List title={`Bookings (${tableQueryResult.data?.total})`}>
            <Form
              {...searchFormProps}
              initialValues={{
                booking_date: [
                  dayjs().startOf('month'),
                  dayjs().endOf('month'),
                ],
              }}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item name="booking_date" label="booking dates">
                    <DatePicker.RangePicker
                      ranges={{
                        Today: [dayjs(), dayjs()],
                        'This Month': [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                        'This Week': [
                          dayjs().startOf('week'),
                          dayjs().endOf('week'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button onClick={() => searchFormProps.form?.submit()}>
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
            <div style={{ overflowX: 'scroll' }}>
              <Table
                {...tableProps}
                size="small"
                title={() => (
                  <Typography.Title level={5}>
                    Total Value:{' '}
                    <PriceField
                      value={
                        tableQueryResult.data?.data.reduce(
                          (prev, curr) => prev + (curr.total ?? 0.0),
                          0.0,
                        ) ?? 0.0
                      }
                    />
                  </Typography.Title>
                )}
                bordered
                scroll={{ y: '64vh' }}>
                <Table.Column
                  dataIndex="date"
                  title="Booking Date"
                  width={50}
                  render={(value) => (
                    <DateField value={value ?? 0} format="LL" />
                  )}
                />
                <Table.Column
                  dataIndex="lead__name"
                  title="Customer"
                  width={100}
                />
                <Table.Column
                  dataIndex="rm_first_name"
                  title="Relationship Manager"
                  width={100}
                  render={(first_name, record: any) => (
                    <>
                      {first_name} {record.rm_last_name}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="consultant_first_name"
                  title="Consultant"
                  width={100}
                  render={(first_name, record: any) => (
                    <>
                      {first_name} {record.consultant_last_name}
                    </>
                  )}
                />
                <Table.Column
                  dataIndex="total"
                  title="Booking Value"
                  fixed
                  width={100}
                  render={(value) => <PriceField value={value ?? 0} />}
                />
                <Table.Column
                  dataIndex="lead__project__id"
                  width={30}
                  title="PID"
                  render={(value) => <>{value}</>}
                />
              </Table>
            </div>
          </List>
        </div>
      </Authenticated>
    </LayoutWrapper>
  )
}
