import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  DateField,
  DeleteButton,
  Show,
  useForm,
  useSimpleList,
} from '@refinedev/antd'
import { useOne, useResource, useUpdate } from '@refinedev/core'
import {
  List as AList,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Space,
  Spin,
  Typography,
} from 'antd'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import {
  IPurchaseOrder,
  IPurchaseOrderItem,
  PurchaseOrderStatus,
} from 'interfaces/ims'
import { IMaterial } from 'interfaces/material'
import { IStaff } from 'interfaces/staff'
import { IVendor } from 'interfaces/vendor'
import { IWarehouse } from 'interfaces/wms'
import { useMemo, useState } from 'react'
import { MATERIAL_URL, PURCHASE_ORDER_ITEM_URL, PURCHASE_ORDER_URL } from 'urls'
import { getUserName } from 'utils/common'

export const PurchaseOrderItemEdit = ({
  item,
  isOpen,
  refetchPO,
}: {
  item: IPurchaseOrderItem
  isOpen: boolean
  refetchPO: CallableFunction
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { formProps, saveButtonProps } = useForm({
    resource: PURCHASE_ORDER_ITEM_URL,
    id: item.id,
    redirect: false,
    action: 'edit',
    onMutationSuccess: () => {
      setIsEditing(false)
      refetchPO()
    },
  })

  return (
    <>
      <Card
        title={(item.material as IMaterial).name}
        size="small"
        extra={
          isOpen && (
            <Space>
              {isEditing && (
                <Button
                  {...saveButtonProps}
                  size="small"
                  icon={<SaveOutlined />}
                />
              )}
              <Button
                size="small"
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={() => setIsEditing(!isEditing)}
              />
              <DeleteButton
                resource={PURCHASE_ORDER_ITEM_URL}
                recordItemId={item.id}
                icon={<DeleteOutlined />}
                size="small"
                hideText
              />
            </Space>
          )
        }>
        <Form {...formProps} size="small">
          <Descriptions size="small">
            <Descriptions.Item label="Quantity Ordered">
              {isEditing ? (
                <Form.Item name="quantity_ordered" noStyle>
                  <InputNumber />
                </Form.Item>
              ) : (
                item.quantity_ordered
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Unit Price">
              {isEditing ? (
                <Form.Item name="unit_price" noStyle>
                  <InputNumber />
                </Form.Item>
              ) : (
                <PriceField value={item.unit_price ?? 0} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Subtotal">
              <PriceField value={item.subtotal} />
            </Descriptions.Item>
            <Descriptions.Item label="Quantity Delivered">
              {item.quantity_delivered}
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </Card>
    </>
  )
}

export const PurchaseOrderItemCreate = ({
  purchaseOrder,
  refetchPO,
}: {
  purchaseOrder: IPurchaseOrder
  refetchPO: CallableFunction
}) => {
  const { formProps, saveButtonProps } = useForm({
    resource: PURCHASE_ORDER_ITEM_URL,
    redirect: false,
    onMutationSuccess: () => {
      refetchPO()
    },
  })

  return (
    <Card title="Add Item" size="small">
      <Form
        {...formProps}
        layout="vertical"
        size="small"
        onFinish={(values) =>
          formProps.onFinish?.({ ...values, purchase_order: purchaseOrder.id })
        }>
        <Form.Item name="material" label="Material">
          <SelectWithDefault
            useSelectProps={{ resource: MATERIAL_URL, optionLabel: 'name' }}
          />
        </Form.Item>
        <Space>
          <Form.Item name="quantity_ordered" label="Quantity Ordered">
            <InputNumber />
          </Form.Item>
          <Form.Item name="unit_price" label="Unit Price">
            <InputNumber />
          </Form.Item>
          <Button {...saveButtonProps} type="primary">
            Save
          </Button>
        </Space>
      </Form>
    </Card>
  )
}

export const PurchaseOrderShow = () => {
  const { id } = useResource()
  const {
    data: po,
    isLoading: poLoading,
    refetch: refetchPO,
  } = useOne<IPurchaseOrder>({
    resource: PURCHASE_ORDER_URL,
    id: `${id}/?expand=vendor,warehouse,approved_by&`,
  })

  const { listProps, queryResult } = useSimpleList<IPurchaseOrderItem>({
    resource: PURCHASE_ORDER_ITEM_URL,
    filters: {
      permanent: [
        { field: 'ordering', operator: 'eq', value: '-id' },
        { field: 'purchase_order', operator: 'eq', value: id },
        { field: 'expand', operator: 'eq', value: 'material' },
      ],
    },
    pagination: {
      pageSize: 10,
    },
  })

  const isOpen = useMemo(
    () => po?.data?.status === PurchaseOrderStatus.PENDING_APPROVAL,
    [po],
  )

  const { mutate: updateStatus, isLoading: statusLoading } = useUpdate()

  const handleStatusUpdate = (status: PurchaseOrderStatus) => {
    updateStatus(
      {
        resource: PURCHASE_ORDER_URL,
        id: po!.data!.id,
        values: {
          status,
        },
      },
      {
        onSuccess: () => {
          refetchPO()
        },
      },
    )
  }

  const handleApproveOrReject = (
    status: PurchaseOrderStatus.APPROVED | PurchaseOrderStatus.REJECTED,
  ) => {
    updateStatus(
      {
        resource: PURCHASE_ORDER_URL,
        id: `${po!.data!.id}/mark_${status.toLowerCase()}`,
        values: {},
      },
      {
        onSuccess: () => {
          refetchPO()
        },
      },
    )
  }

  const statusButtons = () => {
    switch (po?.data.status) {
      case PurchaseOrderStatus.PENDING_APPROVAL:
        return [
          <Button
            key="approve"
            type="primary"
            onClick={() => handleApproveOrReject(PurchaseOrderStatus.APPROVED)}>
            Approve
          </Button>,
          <Button
            key={'reject'}
            type="primary"
            danger
            onClick={() => handleApproveOrReject(PurchaseOrderStatus.REJECTED)}>
            Reject
          </Button>,
        ]
      case PurchaseOrderStatus.APPROVED:
        return [
          <Button
            key="processing"
            type="primary"
            onClick={() => handleStatusUpdate(PurchaseOrderStatus.PROCESSING)}>
            Processing
          </Button>,
        ]
      case PurchaseOrderStatus.PROCESSING:
        return [
          <Button
            key="cancelled"
            onClick={() => handleStatusUpdate(PurchaseOrderStatus.CANCELLED)}>
            Cancelled
          </Button>,
          <Button
            key="on-hold"
            onClick={() => handleStatusUpdate(PurchaseOrderStatus.ON_HOLD)}>
            On Hold
          </Button>,
        ]
      default:
        return []
    }
  }

  return (
    <Show title={`Purchase Order (${id})`}>
      <Spin spinning={poLoading}>
        <Card
          title={
            <Spin spinning={statusLoading}>
              <Typography.Title level={4}>{po?.data.status}</Typography.Title>
            </Spin>
          }
          actions={statusButtons()}>
          <Descriptions>
            <Descriptions.Item label="Order Date">
              <DateField value={po?.data.order_date} format="LL" />
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Date">
              <DateField value={po?.data.expected_delivery_date} format="LL" />
            </Descriptions.Item>
            <Descriptions.Item label="Vendor">
              {(po?.data.vendor as IVendor)?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Warehouse">
              {(po?.data.warehouse as IWarehouse)?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">
              <PriceField value={po?.data.total_amount ?? 0.0} />
            </Descriptions.Item>
            {!!po?.data.approved_by && (
              <Descriptions.Item label="Approver">
                {getUserName(po?.data.approved_by as IStaff, false)}
                (<DateField value={po?.data.approval_date} format="LLL" />)
              </Descriptions.Item>
            )}
          </Descriptions>
        </Card>
        <Divider>Items ({queryResult?.data?.total})</Divider>
        {!!po?.data && (
          <AList
            {...listProps}
            locale={{ emptyText: 'No Items' }}
            renderItem={(item) => (
              <PurchaseOrderItemEdit
                item={item}
                isOpen={isOpen}
                refetchPO={refetchPO}
              />
            )}
            footer={
              <>
                <Divider />
                {isOpen && (
                  <PurchaseOrderItemCreate
                    purchaseOrder={po.data}
                    refetchPO={refetchPO}
                  />
                )}
              </>
            }
          />
        )}
      </Spin>
    </Show>
  )
}
