// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Card, Descriptions, Space, Spin, Timeline } from "antd";
import { BaseKey, useList, useUpdate } from "@refinedev/core";
import { DateFieldFromNow } from 'components/field/DateFieldFromNow'
import { ITimelineExpanded } from 'interfaces/customer'
import React, { createContext, useState } from 'react'
import { TIMELINE_URL } from 'urls'
import { getUserName } from 'utils/common'
import { CreateTimeline } from './Create'
import { UpdateTimeline } from './Edit'

export const TimelineEditContext = createContext<{
  isEditing?: boolean
  setIsEditing?: CallableFunction
}>({})

export const TimelineShow: React.FC<{ pid: BaseKey }> = ({ pid }) => {
  const [isEditing, setIsEditing] = useState(false)

  const { data: timelineData, isLoading: timelineIsLoading } =
    useList<ITimelineExpanded>({
      resource: TIMELINE_URL,

      filters: [
        {
          field: 'project',
          operator: 'eq',
          value: pid,
        },
      ]
    })

  const items: any = timelineData?.data?.map((timeline, index) => {
    return {
      children: <TimelineCard timeline={timeline} />,
      color: timeline.is_done ? 'green' : 'red',
    }
  })

  return (
    <>
      {timelineIsLoading ? (
        <Spin />
      ) : (
        <TimelineEditContext.Provider value={{ isEditing, setIsEditing }}>
          <Timeline
            items={[
              ...items,
              !isEditing && {
                children: <CreateTimeline pid={Number(pid)} />,
                color: 'gray',
              },
            ]}
          />
        </TimelineEditContext.Provider>
      )}
    </>
  )
}

const TimelineCard: React.FC<{
  timeline: ITimelineExpanded
}> = ({ timeline }) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const { isEditing: isGlobalEditing, setIsEditing: setIsGlobalEditing } =
    React.useContext(TimelineEditContext)

  const { mutate: patch, isLoading } = useUpdate()

  return isEditing ? (
    <UpdateTimeline
      pid={timeline.project}
      id={timeline.id}
      setIsEditing={setIsEditing}
    />
  ) : (
    <Card
      size="small"
      title={timeline.text}
      actions={[
        <Button
          key="Done"
          loading={isLoading}
          icon={
            timeline.is_done ? (
              <Icons.CloseCircleOutlined />
            ) : (
              <Icons.CheckCircleOutlined />
            )
          }
          onClick={() =>
            patch({
              resource: TIMELINE_URL,
              id: timeline.id,
              values: { is_done: !timeline.is_done },
            })
          }>
          {timeline.is_done ? 'Undo' : 'Mark Done'}
        </Button>,
      ]}
      extra={
        <Space>
          {!isGlobalEditing && (
            <Button
              key="edit"
              icon={
                isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />
              }
              size="small"
              onClick={() => {
                setIsEditing(true)
                setIsGlobalEditing!(true)
              }}
            />
          )}
        </Space>
      }>
      <Descriptions>
        {timeline.assigned_to && (
          <Descriptions.Item label="Assigned To">
            {getUserName(timeline.assigned_to, false)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Deadline">
          {!!timeline.deadline ? (
            <DateFieldFromNow value={timeline.deadline} />
          ) : (
            'Not Set'
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
