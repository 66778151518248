import { CreateButton, List, useTable } from '@refinedev/antd'
import { Table } from 'antd'
import { useDeleteMany, useNavigation } from '@refinedev/core'
import { DeleteButton } from 'components/button'
import { useState } from 'react'
import { PLAN_URL } from 'urls'
import { IPlan } from 'interfaces/shortlistPackage'

export const PlanList: React.FC = () => {
  const tProps = useTable<IPlan>({
    syncWithLocation: true,
    filters: {
      initial: [
        {
          field: 'expand',
          operator: 'eq',
          value: 'service',
        },
      ],
    },
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: PLAN_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <List headerProps={pageHeaderProps}>
      <Table
        {...tProps.tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        onRow={(record) => ({
          onClick: (event) => edit(PLAN_URL, record.id),
        })}>
        <Table.Column dataIndex="name" title="Name" ellipsis />
        <Table.Column dataIndex="budget" title="Budget" ellipsis />
        <Table.Column dataIndex="order" title="Order" ellipsis />
        <Table.Column
          dataIndex={['service', 'name']}
          title="Service"
          ellipsis
        />
      </Table>
    </List>
  )
}
