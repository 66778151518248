import * as Icons from '@ant-design/icons'
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { useContext } from 'react'
import { PricingContext } from './index'
import { Component, ItemType } from './pricingToolTree'
import './styles.less'

export const ItemList = ({
  itemType,
  setBreakdown,
}: {
  itemType: ItemType
  setBreakdown: any
}) => {
  const value = useContext(PricingContext)

  const removeItem = (item: Component) => {
    let parent = item.parent
    item.destroy()
    if (parent?.getChildren()[0]) {
      value.setItem(parent?.getChildren()[0])
    }
    value.setPrice()
  }

  return (
    <div className="item-list">
      {itemType.getChildren().map((item: Component) => {
        const errors = item.validateChecks()
        return (
          <div
            key={item.name}
            style={{ display: 'flex', alignItems: 'center' }}>
            <div
              // block
              // ghost
              // type={
              //   value.item &&
              //   value.item.name === item.name &&
              //   value.item.id === item.id
              //     ? 'primary'
              //     : 'default'
              // }
              // className="floating-btn"
              // type="default"
              className={`item-button ${
                value.item &&
                value.item.name === item.name &&
                value.item.id === item.id
                  ? 'selected'
                  : ''
              }`}
              key={item.name + item.id.toString()}
              onClick={() => {
                value.setItem(item)
              }}>
              <span
                className={`item-name ${
                  value.item &&
                  value.item.name === item.name &&
                  value.item.id === item.id
                    ? 'selected'
                    : ''
                }`}>
                {item.info?.name}
              </span>
              <div className="item-action-buttons">
                <DeleteOutlined
                  style={{ color: '#fd5c63' }}
                  onClick={() => {
                    removeItem(item)
                  }}
                />
                <CopyOutlined
                  style={{ color: '#7CB9E8' }}
                  onClick={() => {
                    value.setCopiedItem(item)
                  }}
                />
              </div>
            </div>
            {errors.length !== 0 && (
              <Popover content={<div>{errors}</div>}>
                {' '}
                <Icons.WarningOutlined
                  style={{ color: 'red', margin: '10px' }}
                />
              </Popover>
            )}
          </div>
        )
      })}
    </div>
  )
}
