import { BaseKey } from '@refinedev/core'
import { Form, FormProps, InputNumber, Select } from 'antd'
import { MealScheduleType } from 'interfaces/venue'
import { createOptionsFromEnum } from 'utils/common'

export const CateringForm = (props: {
  formProps: FormProps
  venueId: BaseKey
}) => {
  return (
    <>
      <Form
        {...props.formProps}
        onFinish={(values) => {
          props.formProps.onFinish!({ ...values, venue: props.venueId })
        }}
        layout="vertical">
        <Form.Item
          label="Meal Schedule"
          name={'meal_schedule'}
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}>
          <Select options={createOptionsFromEnum(MealScheduleType)} />
        </Form.Item>
        <Form.Item label="Veg Price" name={'veg_price'}>
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="Non Veg Price" name={'non_veg_price'}>
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </>
  )
}
