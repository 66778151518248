import { BaseKey } from '@refinedev/core'
import { IMaterial } from './material'

export enum StockItemChangeReason {
  STOCK_ADJUSTMENT = 'Stock Adjustment',
  PURCHASE_ORDER = 'Purchase Order',
  STOCK_MOVEMENT_INBOUND = 'Stock Movement - Inbound',
  STOCK_MOVEMENT_OUTBOUND = 'Stock Movement - Outbound',
  STOCK_AUDIT = 'Stock Audit',
  OTHER = 'Other',
}

export enum StockAdjustmentType {
  ADDITION = 'Addition',
  SUBTRACTION = 'Subtraction',
}

export interface IWarehouse {
  id: BaseKey
  region: BaseKey
  name: string
  address?: string
}

export interface IStockItem {
  id: BaseKey
  material: BaseKey | IMaterial
  warehouse: BaseKey | IWarehouse
  quantity_on_hand: number
  last_calculated_date?: string
}

export interface IStockItemChangeLog {
  id: BaseKey
  stock_item: BaseKey
  change_reason: StockItemChangeReason
  quantity_before: number
  quantity_after: number
  description?: string
  created_at: string
}

export interface IStockAdjustment {
  id: BaseKey
  stock_item: BaseKey
  adjustment_type: string
  quantity_adjusted: number
  reason: string
  created_at: string
}
