import { Col, Row, Tabs } from 'antd'
import { PackageTotals } from './PackageTotals'
import { ServiceTotal } from './ServiceTotals'
import { VendorSummary } from './VendorSummary'
import { VendorTotals } from './VendorTotals'

export function ReportsTab(props: any) {
  return (
    <Tabs>
      <Tabs.TabPane tab="Basic" key="basic">
        <Row>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <PackageTotals
              queryResult={props.queryResult}
              showCostPrice={props.selectVendor?.can}
            />
          </Col>
          {props.selectVendor?.can && (
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <VendorTotals
                vendorTotalData={props.vendorTotalData}
                refetch={props.refetchVendorTotal}
                packageID={props.queryResult?.data?.data?.package.id}
                packageQueryResult={props.queryResult}
              />
            </Col>
          )}
        </Row>
      </Tabs.TabPane>
      {props.selectVendor?.can && (
        <>
          <Tabs.TabPane tab="Service Breakdown" key="sb">
            <ServiceTotal
              packageId={props.queryResult?.data?.data?.package.id}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Vendor Summary" key="vs">
            <VendorSummary
              packageId={props.queryResult?.data?.data?.package.id}
            />
          </Tabs.TabPane>
        </>
      )}
    </Tabs>
  )
}
