import { List, useModalForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Button, Form, Input, Modal, Select, Table, TableProps } from "antd";
import { BaseKey, useNavigation, useRouterContext } from "@refinedev/core";
import { REGION_KEY } from 'authProvider'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { IEvent } from 'interfaces/customer'
import { useState } from 'react'
import { PROJECT_URL, REGION_URL } from 'urls'

export const EventList: React.FC<{
  customerId?: BaseKey
  tableProps: TableProps<IEvent>
  refetch: any
}> = ({ customerId, tableProps, refetch }) => {
  const { push } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { Link } = useRouterContext()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
  } = useModalForm<IEvent>({
    resource: PROJECT_URL,
    redirect: false,
    action: "create",

    onMutationSuccess: () => { refetch() }
  });

  return (
    <List title="Projects" canCreate={false}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button type="primary" onClick={() => {
            createModalShow();
          }}>Create</Button>
        </>
      )}
    >
      <Table
        {...tableProps}
        rowKey="id"
        rowSelection={rowSelection}
        size="small">
        <Table.Column dataIndex="id" title="Project ID" ellipsis />
        <Table.Column<IEvent>
          dataIndex="name"
          title="Name"
          ellipsis
          render={(value, record) => (
            <Link to={`/package_tool/events/show/${record.id}`}>{value}</Link>
          )}
        />
        <Table.Column
          dataIndex="id"
          title="Action"
          ellipsis
          render={(value) => (
            <Button
              key="edit"
              icon={<Icons.EditOutlined />}
              size="small"
              onClick={() =>
                push(
                  `/package_tool/customers/edit/${customerId}/events/edit/${value}`,
                )
              }
            />
          )}
        />
      </Table>
      <Modal {...createModalProps}>
        <Form {...createFormProps} layout="vertical"
          onFinish={(values: any) => {
            values = { ...values, user: customerId }
            createFormProps.onFinish && createFormProps.onFinish(values)
          }}
        >
          <Form.Item
            label="Project Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            initialValue={Number(localStorage.getItem(REGION_KEY))}>
            <SelectWithDefault
              useSelectProps={{
                resource: REGION_URL,
                optionLabel: 'name',
              }}
            /></Form.Item>
        </Form>
      </Modal>
    </List>
  )
}
