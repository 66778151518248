import {
  DeleteButton,
  EditButton,
  List,
  SaveButton,
  TextField,
  useEditableTable,
} from '@refinedev/antd'
import { CrudFilters, HttpError } from '@refinedev/core'
import { Button, Form, Input, Space, Table } from 'antd'
import { TableSearchInput } from 'components/input/TableSearchInput'
import { ICourse } from 'interfaces/catering'
import { CommonCreateButton } from 'pages/pricing/CommonCreateButton'
import { COURSE_URL } from 'urls'
import { getSearchFormInitialValue } from 'utils/filter'

export const CourseList: React.FC = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    searchFormProps,
    filters,
  } = useEditableTable<ICourse, HttpError, { search: string }>({
    onSearch: (params) => {
      const filters: CrudFilters = []
      // @ts-ignore
      const { search } = params
      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })
      return filters
    },
    syncWithLocation: true,
  })
  return (
    <List
      headerButtons={(defaultButton) => (
        <>
          <TableSearchInput
            searchFormProps={searchFormProps}
            intialValues={getSearchFormInitialValue(filters)}
          />
          <CommonCreateButton />
        </>
      )}>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column key="id" dataIndex="id" title="ID" />
          <Table.Column<ICourse>
            key="title"
            dataIndex="name"
            title="Name"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                )
              }
              return <TextField value={value} />
            }}
          />
          <Table.Column<ICourse>
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_text, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      Cancel
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record.id)} size="small" />
                  <DeleteButton
                    size="small"
                    resource={COURSE_URL}
                    recordItemId={record.id}
                  />
                </Space>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
