import { Create } from "@refinedev/antd";
import { CheckAccess } from 'components/checkAccess'
import { useForm } from 'components/hooks/useForm'
import { IGroup } from 'interfaces/permission'
import { GroupForm } from './Form'

export const GroupCreate: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IGroup>()

  return (
    <CheckAccess resource="write_staff">
      <Create saveButtonProps={saveButtonProps}>
        <GroupForm formProps={formProps} />
      </Create>
    </CheckAccess>
  )
}
