import { Edit } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { ISurcharge } from 'interfaces/catering'
import { SurchargeForm } from './Form'

export const SurchargeEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ISurcharge>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <SurchargeForm formProps={formProps} />
    </Edit>
  )
}
