import { DateField, useModalForm } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
} from 'antd'

import {
  BaseKey,
  useCan,
  useDelete,
  useModal,
  useOne,
  useUpdate,
} from '@refinedev/core'
import { RowRemoveButton } from 'components/button'
import { PriceField } from 'components/field'
import { SelectWithDefault } from 'components/input/SelectWithDefault'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import {
  IBooking,
  IBookingService,
  IBookingSummary,
  IService,
} from 'interfaces/crm'
import { useEffect, useState } from 'react'
import { BOOKING_SERVICE_URL, BOOKING_URL, LEAD_URL, SERVICE_URL } from 'urls'

export function DateFieldInput({
  id,
  date,
  canUpdate,
}: {
  id: BaseKey
  date: any
  canUpdate?: boolean
}) {
  const [value, setValue] = useState<dayjs.Dayjs>(dayjs(date))
  const [isEditing, setIsEditing] = useState(false)

  const { mutate: patch } = useUpdate()

  const handleSave = () => {
    patch(
      {
        resource: BOOKING_URL,
        id,
        values: {
          date: value.format('YYYY-MM-DD'),
        },
      },
      { onSuccess: () => setIsEditing(false) },
    )
  }

  return (
    <>
      {isEditing ? (
        <DatePicker
          value={value}
          onChange={(value) => setValue(value as any)}
        />
      ) : (
        <>
          <DateField value={value as any} format="LL" />
        </>
      )}
      <Space size="small">
        {isEditing && (
          <Button
            key="save"
            type="text"
            icon={<Icons.SaveOutlined />}
            size="small"
            onClick={() => handleSave()}
          />
        )}
        <Button
          key="edit"
          type="text"
          disabled={!canUpdate}
          icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
          size="small"
          onClick={() => setIsEditing(!isEditing)}
        />
      </Space>
    </>
  )
}

function ServiceItem({
  service,
  canUpdate,
}: {
  service: IBookingService
  canUpdate?: boolean
}) {
  const [value, setValue] = useState(service.amount)
  const [isEditing, setIsEditing] = useState(false)

  const { mutate: patch } = useUpdate()

  const handleSave = () => {
    patch(
      {
        resource: BOOKING_SERVICE_URL,
        id: service.id,
        values: {
          amount: value,
        },
      },
      { onSuccess: () => setIsEditing(false) },
    )
  }

  return (
    <div>
      -{' '}
      <Space>
        {(service.service as IService).name}
        {isEditing ? (
          <InputNumber
            value={value}
            onChange={(value: any) => setValue(value)}
          />
        ) : (
          <PriceField value={value} />
        )}
      </Space>
      <Space size="small">
        {isEditing && (
          <Button
            key="save"
            type="text"
            icon={<Icons.SaveOutlined />}
            size="small"
            onClick={() => handleSave()}
          />
        )}
        <Button
          key="edit"
          type="text"
          disabled={!canUpdate}
          icon={isEditing ? <Icons.CloseOutlined /> : <Icons.EditOutlined />}
          size="small"
          onClick={() => {
            setIsEditing(!isEditing)
            setValue(service.amount)
          }}
        />
      </Space>
    </div>
  )
}

export const BookingModal = ({
  leadId,
  withLabel = false,
}: {
  leadId: BaseKey
  withLabel?: boolean
}) => {
  const { show, close, visible } = useModal()

  const {
    formProps,
    modalProps,
    show: addBookingShow,
  } = useModalForm({
    resource: `${LEAD_URL}/${leadId}/add_booking`,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => refetch(),
  })

  const {
    data: bookings,
    isFetching: bookingsLoading,
    refetch,
  } = useOne<IBooking[]>({
    resource: LEAD_URL,
    id: `${leadId}/get_bookings`,
    queryOptions: { enabled: visible },
  })

  const {
    data: bookingSummary,
    refetch: refetchSummary,
    isLoading: summaryLoading,
  } = useOne<IBookingSummary[]>({
    resource: LEAD_URL,
    id: `${leadId}/get_bookings_summary`,
  })

  const { mutate: destroy } = useDelete()

  useEffect(() => {
    refetchSummary()
  }, [visible])

  const { data: addBookingPerm } = useCan({
    resource: 'add_booking',
    action: '',
  })

  const { data: updateBookingPerm } = useCan({
    resource: 'change_booking',
    action: '',
  })

  const { data: deleteBookingPerm } = useCan({
    resource: 'delete_booking',
    action: '',
  })

  return (
    <Space>
      {!withLabel && (
        <Space direction="vertical" align="center">
          {summaryLoading ? (
            <Spin />
          ) : (
            bookingSummary?.data &&
            bookingSummary?.data.map((summary) => (
              <span key={summary.month}>
                {dayjs()
                  .month(summary.month - 1)
                  .format('MMM')}
                :
                <PriceField value={summary.total} />
              </span>
            ))
          )}
        </Space>
      )}
      <Button icon={<Icons.EditOutlined />} size="small" onClick={show}>
        {withLabel && 'Bookings'}
      </Button>
      <Modal
        onCancel={close}
        open={visible}
        title={<>Bookings {bookingsLoading && <Spin size="small" />}</>}
        footer={null}
        destroyOnClose>
        {bookings?.data.map((booking) => (
          <div key={booking.id}>
            <Card
              size="small"
              title={
                <DateFieldInput
                  id={booking.id}
                  date={booking.date}
                  canUpdate={updateBookingPerm?.can}
                />
              }
              extra={
                <>
                  {deleteBookingPerm?.can && (
                    <Button
                      icon={<Icons.DeleteOutlined />}
                      danger
                      size="small"
                      onClick={() =>
                        destroy(
                          {
                            resource: BOOKING_URL,
                            id: booking.id,
                          },
                          { onSuccess: () => refetch() },
                        )
                      }
                    />
                  )}
                </>
              }>
              {booking.services.map((service: any) => (
                <ServiceItem
                  key={(service.service as IService).id}
                  service={service}
                  canUpdate={updateBookingPerm?.can}
                />
              ))}
            </Card>
            <Divider />
          </div>
        ))}
        <Button
          icon={<Icons.PlusOutlined />}
          type="primary"
          disabled={!!!addBookingPerm?.can}
          onClick={() => addBookingShow()}>
          Booking
        </Button>
        <Modal {...modalProps} title="Add Booking">
          <Form
            {...formProps}
            onFinish={(values) =>
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                date: dayjs((values as any).date).format('YYYY-MM-DD'),
              })
            }>
            <Form.Item name={'date'} label="Date" initialValue={dayjs()}>
              <DatePicker />
            </Form.Item>
            <Form.List name="services" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row gutter={16} key={field.name}>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'service']}
                          label="Service"
                          rules={[{ required: true }]}>
                          <SelectWithDefault
                            style={{ width: '100%' }}
                            dropdownMatchSelectWidth={false}
                            size="small"
                            useSelectProps={{
                              resource: SERVICE_URL,
                              optionLabel: 'name',
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'amount']}
                          label="Amount"
                          rules={[{ required: true }]}>
                          <InputNumber style={{ width: '100%' }} prefix="₹" />
                        </Form.Item>
                      </Col>
                      <RowRemoveButton onClick={() => remove(field.name)} />
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Service
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </Modal>
    </Space>
  )
}
