import { CreateButton, List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import { useDeleteMany, useNavigation } from "@refinedev/core";
import { DeleteButton } from 'components/button'
import { CheckAccess } from 'components/checkAccess'
import { IGroup } from 'interfaces/permission'
import { useState } from 'react'
import { GROUP_URL } from 'urls'

export const GroupList: React.FC = () => {
  const tProps = useTable<IGroup>({
    syncWithLocation: true
  })
  const { edit } = useNavigation()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { mutate } = useDeleteMany()

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const deleteMany = () => {
    mutate({
      resource: GROUP_URL,
      ids: selectedRowKeys,
    })
    setSelectedRowKeys([])
  }

  const pageHeaderProps = {
    extra: [
      !!selectedRowKeys.length && <DeleteButton onClick={deleteMany} />,
      <CreateButton key="create" />,
    ],
  }

  return (
    <CheckAccess resource="write_staff">
      <List headerProps={pageHeaderProps}>
        <Table
          {...tProps.tableProps}
          rowKey="id"
          size='small'
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: (event) => edit(GROUP_URL, record.id),
          })}>
          <Table.Column dataIndex="name" title="Name" ellipsis/>
        </Table>
      </List>
    </CheckAccess>
  );
}
