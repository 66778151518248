import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Edit, PageHeaderProps, RefreshButton } from "@refinedev/antd";
import { Button, Spin } from "antd";
import { Authenticated, LayoutWrapper, useRouterContext } from "@refinedev/core";
import { useForm } from 'components/hooks/useForm'
import { NestedBreadcrumb } from 'components/layout/breadcrumb'
import { ISmartPhoto } from 'interfaces/smartPhoto'
import { cleanPathName, getBasePathName } from 'utils/common'
import { SmartPhotoForm } from './Form'

export const SmartPhotoEdit: React.FC = () => {
  const { useLocation, useParams } = useRouterContext()
  const { pathname } = useLocation()
  const resource = cleanPathName(getBasePathName(pathname))
  const { id, venue_id } = useParams<any>()

  const useFormProps = useForm<ISmartPhoto>({
    action: 'edit',
    resource: resource,
    id,
  })

  console.log('useFromProps', useFormProps)

  const pageHeaderProps: PageHeaderProps = {
    breadcrumb: (
      <NestedBreadcrumb
        baseUrl="/package_tool"
        routes={[{ name: 'venues', id: venue_id }, { name: 'smart_photos' }]}
      />
    ),
    extra: [
      <Button
        key="layers"
        icon={<FontAwesomeIcon icon={['fas', 'layer-group']} />}
        onClick={() =>
          window.open(
            `/package_tool/venues/${venue_id}/smart_photos/${id}/layers`,
          )
        }>
        Layers
      </Button>,
      <RefreshButton key="refresh" />,
    ],
  }

  return (
    <Authenticated>
      <LayoutWrapper>
        <Edit
          saveButtonProps={useFormProps.saveButtonProps}
          headerProps={pageHeaderProps}
          title="Edit Smart Photo">
          {useFormProps.formLoading ? (
            <Spin />
          ) : (
            <SmartPhotoForm useFormProps={useFormProps} />
          )}
        </Edit>
      </LayoutWrapper>
    </Authenticated>
  );
}
