import {
  Breakdown,
  Component,
  IFabricColorValue,
  IFloralValue,
  Info,
  ItemType,
  Root,
  TreeNode,
} from './pricingToolTree'

export const itemTypeValues = ['PRODUCT', 'FLORAL', 'FABRIC', 'STRUCTURE']

const addInfo = (
  node: TreeNode,
  obj: any,
  itemType: string,
  createItem: any,
) => {
  let infoNode = new Info(obj.item_name, obj.id, obj.combo.item)
  // console.log('infoObj', obj)
  node.info = infoNode
  infoNode.item = obj.item
  infoNode.quantity = obj.quantity
  infoNode.sub_product = obj.sub_product
  infoNode.breakdown_id = obj.breakdown_id
  infoNode.item_image_url = obj.combo.item.image
  infoNode.create = createItem
  infoNode.price = obj.price
  if (itemType === 'FABRIC') {
    // set fabric id and fabric image
    if (obj.combo.fabric) {
      infoNode.fabric = obj.combo.fabric.id
      infoNode.fabric_image = obj.combo.fabric.image
    }

    infoNode.price = obj.combo.price
    obj['values'].forEach((element: any) => {
      let fabricColorValues: IFabricColorValue = {
        id: element.id,
        fabric: element.fabric,
        color: element.color,
      }
      infoNode.values?.push(fabricColorValues)
    })
  } else if (itemType === 'FLORAL') {
    obj['values'].forEach((element: any) => {
      let floralValues: IFloralValue = {
        id: element.id,
        flower: element.flower,
        foliage: element.foliage,
        split: element.split,
        foliage_ratio: element.foliage_ratio,
        artificial_ratio: element.artificial_ratio,
        image: element.image,
        combination: element.combination,
        price: element.price,
      }
      infoNode.values?.push(floralValues)
    })
  }
}

function addBreakdown(
  root: TreeNode,
  obj: any,
  id: number,
  deleteItem: any,
  createItem: any,
) {
  let breakdown = new Breakdown(obj.name, id)
  breakdown.info = obj.allowed_item_type
  let item_types = []
  if (breakdown.info)
    for (const [key, value] of Object.entries(breakdown.info)) {
      if (value) {
        item_types.push(key.toUpperCase())
      }
    }
  root.addChild(breakdown)
  item_types.forEach((itemType) => {
    if (obj[itemType].length !== 0) {
      let itemTypeNode = new ItemType(itemType, 0)
      breakdown.addChild(itemTypeNode)
      obj[itemType].forEach((element: any) => {
        let component = new Component(
          itemType + element.id.toString(),
          element.id,
        )
        component.delete = deleteItem
        component.create = createItem
        addInfo(component, element, itemType, createItem)
        itemTypeNode.addChild(component)
      })
    } else {
      let itemTypeNode = new ItemType(itemType, 0)
      breakdown.addChild(itemTypeNode)
    }
  })
}

export default function create_pricing_tool_tree(
  productId: number,
  data: any,
  deleteItem: any,
  createItem: any,
  lineItemId?: number,
  alternateItemId?: number,
) {
  const root = new Root('root')

  root.id = productId
  let infoNode = new Info('rootInfo', 1)
  infoNode.lineItem = lineItemId
  infoNode.alternateItem = alternateItemId
  root.info = infoNode

  for (let o in data) {
    addBreakdown(root, data[o], Number(o), deleteItem, createItem)
  }

  return root
}

export {}
